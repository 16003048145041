import React, { useEffect, useState } from "react";

import Tabs, { tabsClasses } from "@mui/material/Tabs";

const PageTab = props => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const setKeyFunction = () => setKey(1);
    window.addEventListener("load", setKeyFunction);
    return () => window.removeEventListener("load", setKeyFunction);
  }, []);

  return (
    <Tabs
      key={key}
      {...props}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="visible arrows tabs example"
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          "&.Mui-disabled": { opacity: 0.3 }
        }
      }}
      indicatorColor="white"
    />
  );
};

export default PageTab;
