import React from "react";

import { prop } from "ramda";
// export const renderCustomDot = (payload, cx, cy, value, data, color) => {
//   if (payload?.[value] !== undefined && payload?.[value] !== null) {
//     return <circle r="4" cx={cx} cy={cy} fill={color} stroke="" strokeWidth="1.5" />;
//   }
// };

export function renderCustomDot(payload, cx, cy, key, data, color) {
  if (!payload[key] && payload[key] !== 0) {
    return null;
  }

  return <circle cx={cx} cy={cy} r={4} fill={color} stroke="#fff" strokeWidth={2} />;
}

export const formatPercentAxisValue = value => {
  const percent = value * 100;
  if (percent === 0) {
    return "0%";
  } else if (percent > 10 || percent < -10) {
    return `${percent.toFixed(0)}%`;
  } else {
    return `${percent.toFixed(1)}%`;
  }
};

export const getMaxMinValue = data => {
  const arr = [];
  data?.forEach(el => {
    if (!isNaN(el.currentPress * 100)) arr.push(el.currentPress * 100);
    if (!isNaN(el.prevPress * 100)) arr.push(el.prevPress * 100);
    if (!isNaN(el.proceeds_trend * 100)) arr.push(el.proceeds_trend * 100);
  });

  return {
    max: Math.max(...arr),
    min: Math.min(...arr),
  };
};

export const renderDataForPieChart = (keyServiceStructureCircle, dateTo) => {
  let sum =
    prop("commission", keyServiceStructureCircle) +
    prop("delivery", keyServiceStructureCircle) +
    prop("penalty", keyServiceStructureCircle) +
    prop("ride", keyServiceStructureCircle) +
    prop("acceptance_value", keyServiceStructureCircle) +
    prop("paid_storage_value", keyServiceStructureCircle) +
    prop("acquiring_fee", keyServiceStructureCircle) +
    prop("advert_value", keyServiceStructureCircle);

  const data = [
    {
      office_name: "Комиссия",
      value:
        keyServiceStructureCircle.commission !== null
          ? keyServiceStructureCircle.commission
          : "Нет данных",
      precent: (keyServiceStructureCircle.commission / sum) * 100,
      text: "Сумма фактически начисленной комиссии маркетплейса, включающей вознаграждение комиссионера, а также издержки по эквайрингу, по перевозке, за выдачу и возврат товаров на ПВЗ",
      color: "#00C85A",
    },
    {
      office_name: "Доставка",
      value:
        keyServiceStructureCircle.delivery !== null
          ? keyServiceStructureCircle.delivery
          : "Нет данных",
      precent: (keyServiceStructureCircle.delivery / sum) * 100,
      text: "Сумма затрат на логистику без учета покатушек (в пределах границ периода)",
      color: "#0000FF",
    },
    {
      office_name: "Покатушки",
      value:
        keyServiceStructureCircle.ride !== null ? keyServiceStructureCircle.ride : "Нет данных",
      precent: (keyServiceStructureCircle.ride / sum) * 100,
      text: "Сумма затрат на логистику в части товаров, отмененных  покупателями (в пределах границ периода)",
      color: "#FFA500",
    },
    {
      office_name: "Приёмка",
      value:
        keyServiceStructureCircle.acceptance_value !== null
          ? keyServiceStructureCircle.acceptance_value
          : "Нет данных",
      precent: (keyServiceStructureCircle.acceptance_value / sum) * 100,
      color: "#d3d3d3",
    },
    {
      office_name: "Штрафы",
      value:
        keyServiceStructureCircle.penalty !== null
          ? keyServiceStructureCircle.penalty
          : "Нет данных",
      precent: (keyServiceStructureCircle.penalty / sum) * 100,
      color: "#D44CA9",
    },
    {
      office_name: "Хранение",
      value:
        keyServiceStructureCircle.paid_storage_value !== null
          ? keyServiceStructureCircle.paid_storage_value
          : "Нет данных",
      precent: (keyServiceStructureCircle.paid_storage_value / sum) * 100,
      color: "#9400D3",
    },
    {
      office_name: "Продвижение",
      value:
        keyServiceStructureCircle.advert_value !== null
          ? keyServiceStructureCircle.advert_value
          : "Нет данных",
      precent: (keyServiceStructureCircle.advert_value / sum) * 100,
      color: "#ffd700",
    },
  ];

  let checkMonth = false;
  if (dateTo instanceof Date) {
    checkMonth = new Date(dateTo.getTime() - 24 * 60 * 60 * 1000) >= new Date(2024, 3, 1);

    if (checkMonth) {
      data.splice(1, 0, {
        office_name: "Эквайринг",
        value:
          keyServiceStructureCircle.non_commission_acquiring_fee !== null
            ? keyServiceStructureCircle.non_commission_acquiring_fee
            : "Нет данных",
        precent: (keyServiceStructureCircle.non_commission_acquiring_fee / sum) * 100,
        color: "#00dcff",
      });
    }
  }

  return { data: data, checkMonth: checkMonth };
};
