import React from 'react'

import { Checkbox } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { handleCheckBoxSelect } from 'root/store/categoryOptimization/categoryOptimization'

const CheckBox_ = ({ value }) => {
  const dispatch = useDispatch()
  const checkBoxSelect = useSelector(store => store.categoryOptimization.allIds)

  const onChange = e => dispatch(handleCheckBoxSelect({checked: e.target.checked, value}))
  
  return (
    <Checkbox
      size="small"
      color="primary"
      checked={checkBoxSelect?.includes(value)}
      onChange={onChange}
    />
  )
}

export default CheckBox_