import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNotificationGroups } from "root/api/analytics/notification-groups";

export const getNotificationGroup = createAsyncThunk(
  "/getNotificationGroups",
  async ({ cabinet_id }, thunkAPI) => {
    try {
      const response = await getNotificationGroups({ cabinet_id });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error");
    }
  }
);
