import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "root/js/api";

const WBAPI = new API();

export const SalesWidgets = createAsyncThunk(
  "sales/widgets/forecast",
  async ({
    subjectsId,
    categoryId,
    brandIds,
    articulIds,
    keys,
    barcodeIds,
    period,
    isWareHouseSeasonality,
    isInWayStocks,
    isWareHouseSpikes,
    isGrowth,
    isTend,
    isCompanyPlan
  }) => {
    try {
      const key = keys.join();
      const brandsId = brandIds.join();
      const aritucl = JSON.stringify(articulIds);
      const category = categoryId.join();
      const subject = subjectsId.join();
      const barcode = barcodeIds.join();
      const response = await WBAPI.salesWidgets({
        key,
        barcode,
        brandsId,
        aritucl,
        category,
        subject,
        period,
        isWareHouseSeasonality,
        isInWayStocks,
        isWareHouseSpikes,
        isGrowth,
        isTend,
        isCompanyPlan
      });

      return response;
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const SalesDynamic = createAsyncThunk(
  "sales/dynamic/forecast",
  async ({
    subjectsId,
    categoryId,
    brandIds,
    articulIds,
    keys,
    barcodeIds,
    period,
    isWareHouseSeasonality,
    isInWayStocks,
    isWareHouseSpikes,
    isGrowth,
    isTend,
    isCompanyPlan
  }) => {
    try {
      const key = keys.join();
      const brandsId = brandIds.join();
      const aritucl = JSON.stringify(articulIds);
      const category = categoryId.join();
      const subject = subjectsId.join();
      const barcode = barcodeIds.join();
      const response = await WBAPI.salesDynamic({
        key,
        barcode,
        brandsId,
        aritucl,
        category,
        subject,
        period,
        isWareHouseSeasonality,
        isInWayStocks,
        isWareHouseSpikes,
        isGrowth,
        isTend,
        isCompanyPlan
      });

      return response;
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const SalesTable = createAsyncThunk(
  "sales/table/forecast",
  async ({
    subjectsId,
    categoryId,
    brandIds,
    articulIds,
    keys,
    barcodeIds,
    period,
    isWareHouseSeasonality,
    isInWayStocks,
    isWareHouseSpikes,
    isGrowth,
    isTend,
    isCompanyPlan,
    group_by,
    sort,
    q,
    limit,
    offset,
    product_id__icontains
  }) => {
    try {
      const key = keys.join();
      const brandsId = brandIds.join();
      const aritucl = JSON.stringify(articulIds);
      const category = categoryId.join();
      const subject = subjectsId.join();
      const barcode = barcodeIds.join();
      const response = await WBAPI.salesTable({
        key,
        barcode,
        brandsId,
        aritucl,
        category,
        subject,
        period,
        isWareHouseSeasonality,
        isInWayStocks,
        isWareHouseSpikes,
        isGrowth,
        isTend,
        isCompanyPlan,
        group_by,
        sort,
        q,
        limit,
        offset,
        product_id__icontains
      });

      return response;
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const GetPlanFactWidgets = createAsyncThunk(
  "get/planFact/widgets",
  async ({ keys, month }, thunkAPI) => {
    const key = keys.join();
    try {
      const response = await WBAPI.getPlanFactWidgets({
        key,
        month
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const GetPlanFactMonthDynamic = createAsyncThunk(
  "get/planFact/monthDynamic",
  async ({ keys }, thunkAPI) => {
    const key = keys.join();
    try {
      const response = await WBAPI.getPlanFactMonthDynamic({
        key
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const GetPlanFactByCategory = createAsyncThunk(
  "get/planFact/categories",
  async ({ keys, month }, thunkAPI) => {
    const key = keys.join();
    try {
      const response = await WBAPI.getPlanFactByCategory({
        key,
        month
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);
