import React, { useRef } from "react";

import classNames from "classnames";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { getMinPointSize } from "root/js/utils";

import styles from "./index.module.scss";

const DiagramBarChart = props => {
  const {
    data,
    width,
    height,
    barKey,
    xAxisKey,
    CustomTooltip,
    CustomYAxisTick,
    CustomXAxisTick,
    ticksValue,
    customStyle,
    economicStyle,
    hasMinBarHeight,
    barSize
  } = props;
  const diagramRef = useRef(null);

  return (
    <ResponsiveContainer className={!customStyle ? styles.xScrollable : ""}>
      <div
        ref={diagramRef}
        className={classNames(
          styles.container,
          customStyle ? styles.smallPadding : economicStyle ? styles.economicStyle : ""
        )}>
        <BarChart
          width={width ? width : 480}
          height={height ? height : 320}
          data={data}
          maxBarSize={barSize | 16}
          layout="vertical">
          <XAxis type="number" allowDataOverflowtrue tick={CustomXAxisTick} ticks={ticksValue} />
          <YAxis tick={CustomYAxisTick} dataKey={xAxisKey} type="category" />
          {CustomTooltip && <Tooltip cursor={false} content={<CustomTooltip />} />}
          <ReferenceLine x={0} stroke="#EB255D" strokeDasharray="10 10" />

          <Bar
            dataKey={barKey}
            minPointSize={hasMinBarHeight ? getMinPointSize(diagramRef, "xAxis", "width") : 0}>
            {data.map((entry, index) => {
              if (hasMinBarHeight) {
                if (entry.value) {
                  return <Cell key={`cell-${index}`} fill={entry.value < 0 ? "#EB255D" : "#0FB682"} />;
                } else {
                  return <Cell key={`cell-${index}`} fill="transparent" />;
                }
              } else {
                return <Cell key={`cell-${index}`} fill={entry.value < 0 ? "#EB255D" : "#0FB682"} />;
              }
            })}
          </Bar>
        </BarChart>
      </div>
    </ResponsiveContainer>
  );
};

export default DiagramBarChart;
