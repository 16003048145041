import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Search, SelectAll } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import { isEmpty } from "ramda";
import { queryAPI } from "root/api/queryAPI";
import { WBapi } from "root/services/WBapi";

import Button_ from "root/components/Button";
import IconButton_ from "root/components/IconButton";
import LinearLoading from "root/components/LinearLoading";
import PageTitle from "root/components/PageTitle";
import RightDrawer from "root/components/RightDrawer";
// TableSort основной элемент, будем полагаться на его стили
import paginationStyles from "root/components/TableSort/pagination.module.scss";
import TextField_ from "root/components/TextField";

import selectorImage from "root/img/logo/empty_selector_list.png";

import { calcOffset, isMobile } from "root/js/utils";

import styles from "./index.module.scss";

// variables
const perPage = 100;

// ---------------------------------------------------------------------
const _SelectorDrawer = ({
  title = "Выберите элементы",
  isOpen,
  onClose,
  checkedItems,
  setCheckedItems,
  saveChanges,
  apiMethodName,
  fetchParams,
  frontSearch,
  getCorrectDataAndTotal,
  haveSearch,
  isNewQueryAPI,
  setDefaultCheckedItems,
  maxSelectableItems,
  listItemRender,
}) => {
  //
  const [correctData, setCorrectData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  //
  const isAllChecked = useMemo(() => {
    return correctData.every(item => checkedItems.find(checkedItem => checkedItem.id === item.id));
  }, [correctData, checkedItems]);
  const checkAllLabel = isAllChecked ? "Снять выделение" : "Выбрать все";
  const toggleCheckAll = () => {
    if (isAllChecked) setCheckedItems([]);
    else
      setCheckedItems(prev => {
        const newCheckedItems = correctData.filter(
          item => !prev.find(prevItem => prevItem.id === item.id)
        );
        return [...prev, ...newCheckedItems];
      });
  };
  const [search, setSearch] = useState("");
  const setSearchParam = useCallback(v => !frontSearch && setSearch(v), [frontSearch]);
  const [searchText, setSearchText] = useState("");
  const filteredData = useMemo(
    () =>
      frontSearch
        ? correctData.filter(item => {
            const s = searchText.toLowerCase();
            return item.name.toLowerCase().includes(s) || ("" + item.id).includes(s);
          })
        : correctData,
    [correctData, searchText]
  );
  // ---------------------------------- FETCH LOGIC
  const currentAPI = useMemo(() => (isNewQueryAPI ? queryAPI : WBapi), [isNewQueryAPI]);
  const params = useMemo(
    () => ({
      ...fetchParams,
      limit: perPage,
      offset: calcOffset(page, perPage),
      search: search.trim() || undefined,
    }),
    [fetchParams, perPage, page, search]
  );
  const {
    data = {},
    isLoading: isFirstLoading,
    isFetching,
  } = currentAPI[apiMethodName](isNewQueryAPI ? params : { params });
  const isLoading = useMemo(() => isFirstLoading || isFetching, [isFirstLoading, isFetching]);
  useEffect(() => {
    if (!isEmpty(data)) {
      const [newData, newCount] = getCorrectDataAndTotal(data);
      setCorrectData(newData);
      setTotal(newCount);
    }
  }, [data]);
  //
  useEffect(() => {
    if (isLoading) return () => {};
    const timerId = setTimeout(() => setSearchParam(searchText), 1500);
    return () => clearTimeout(timerId);
  }, [searchText]);
  //
  useEffect(() => {
    setDefaultCheckedItems();
  }, []);
  //
  return (
    <RightDrawer {...{ isOpen, onClose }} classes={{ inner: styles.drawerInner }}>
      {/* ------------------------ LEFT SIDE -------------------------- */}
      <Box className={styles.leftSide}>
        <Box py={1} px={2} className={styles.drawerHeader}>
          <Box
            height={60}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={1}>
            <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>{title}</Typography>
            {maxSelectableItems === Infinity && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllChecked}
                    onChange={toggleCheckAll}
                    color="primary"
                    icon={<SelectAll />}
                  />
                }
                label={checkAllLabel}
              />
            )}
          </Box>
          {haveSearch && (
            <Box py={1} px={2} display={"flex"} alignItems={"center"} height={60}>
              <TextField_ value={searchText} onChange={e => setSearchText(e.target.value)} />
              <IconButton_ variant="filled" onClick={() => setSearchParam(searchText)}>
                <Search />
              </IconButton_>
            </Box>
          )}
        </Box>

        <Box className={styles.drawerContentWrapper}>
          {isLoading && <LinearLoading />}
          <Content
            {...{
              checkedItems,
              setCheckedItems,
              total,
              page,
              setPage,
              perPage,
              maxSelectableItems,
              listItemRender,
            }}
            itemsList={filteredData}
          />
        </Box>
        <Box className={styles.drawerFooter}>
          <Button_ color="secondary" variant="filled" onClick={saveChanges}>
            Применить
          </Button_>
          <Button_ onClick={onClose}>Отмена</Button_>
        </Box>
      </Box>
      {/* ------------------------ RIGHT SIDE -------------------------- */}
      <Box
        className={styles.rightSide}
        sx={{ backgroundImage: checkedItems.length ? "" : `url(${selectorImage})` }}>
        <Box display={"flex"} alignItems={"center"} p={2}>
          <Typography>Выбрано {checkedItems.length} шт.</Typography>
        </Box>
        <Box className={styles.rightSideContent}>
          {checkedItems.map(item => (
            <Chip
              variant="outlined"
              color="primary"
              key={item.id}
              label={item.name || item.id || "Прочие бренды без названия"}
              onDelete={() =>
                setCheckedItems(prev => prev.filter(checkedItem => checkedItem.id !== item.id))
              }
            />
          ))}
        </Box>
        <Box className={styles.drawerFooter}></Box>
      </Box>
    </RightDrawer>
  );
};

const Content = ({
  itemsList,
  checkedItems,
  setCheckedItems,
  total,
  page,
  setPage,
  perPage,
  maxSelectableItems,
  listItemRender,
}) => {
  return (
    <>
      {itemsList.length ? (
        <Box mb={total > itemsList.length ? 5 : 0}>
          {itemsList.map(item => (
            <ListItem
              key={item.id + Math.random()}
              {...{ item, checkedItems, setCheckedItems, maxSelectableItems, listItemRender }}
            />
          ))}
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"90%"}>
          <Box>
            <Typography variant="subtitle2">Нет данных</Typography>
          </Box>
        </Box>
      )}

      {total > itemsList.length && (
        <Box p={1} className={styles.drawerContentPagination}>
          <Pagination
            count={Math.ceil(total / perPage)}
            size={isMobile ? "small" : "medium"}
            variant="outlined"
            shape="rounded"
            classes={{
              ul: styles.flexWrapNowrap,
            }}
            onChange={(_, p) => setPage(p)}
            page={page}
            renderItem={item => <PaginationItem classes={paginationStyles} {...item} />}
          />
        </Box>
      )}
    </>
  );
};
//
const defaultListItemRender = data => <Typography>{data.name || "Без наименования"}</Typography>;
// -------------------------------------------------------------------------------------------
const ListItem = ({
  item,
  checkedItems,
  setCheckedItems,
  maxSelectableItems,
  listItemRender = defaultListItemRender,
}) => {
  const toggleCheckedItems = useCallback(
    checkingItem => {
      // Проверяем, достигнуто ли максимальное количество выбираемых элементов
      if (
        checkedItems.length >= maxSelectableItems &&
        !checkedItems.find(item => item.id === checkingItem.id)
      ) {
        return; // Если достигнуто, ничего не делаем
      }
      if (!checkedItems.find(item => item.id === checkingItem.id))
        setCheckedItems(prev => [...prev, checkingItem]);
      else setCheckedItems(prev => prev.filter(item => item.id !== checkingItem.id));
    },
    [checkedItems, maxSelectableItems] // Добавляем maxSelectableItems в зависимости
  );
  const isDisabled =
    checkedItems.length >= maxSelectableItems &&
    !checkedItems.find(checkedItem => checkedItem.id === item.id);

  return (
    <Box
      className={styles.drawerContentItem}
      onClick={() => !isDisabled && toggleCheckedItems(item)} // Не вызываем toggleCheckedItems, если элемент отключен
      sx={{ cursor: isDisabled ? "not-allowed" : "pointer", opacity: isDisabled ? 0.5 : 1 }}>
      <Checkbox
        checked={!!checkedItems.find(checkedItem => checkedItem.id === item.id)}
        disabled={isDisabled}
      />
      {listItemRender(item)}
    </Box>
  );
};

export default _SelectorDrawer;
