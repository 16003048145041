import React from "react";

import Partner from "root/icons/Partner";


const iframePages = {
  type: "section",
  onlyInDev: true,
  title: "iFrame для расширы",
  icon: Partner,
  children: [
    {
      title: "Сводка",
      url: `/test/product-chart/:id`,
      component: React.lazy(() => import("root/iframes/ProductChartById")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Категории",
      url: `/test/keywords-chart/:id`,
      component: React.lazy(() => import("root/iframes/KeywordsChartById")),
      onlyInDev: true,
      exact: false,
      
    },
    {
      title: "Запросы",
      url: `/test/keywords-table/:id`,
      component: React.lazy(() => import("root/iframes/KeywordsTableById")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Динамика частотности запроса",
      url: `/test/request-analyzer/:keyword`,
      component: React.lazy(() => import("root/iframes/RequestAnalizerByKeyword")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Расширение запроса в динамике",
      url: `/test/wordstats/:keyword`,
      component: React.lazy(() => import("root/iframes/WordstatByKeyword")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Проверка кластера",
      url: `/test/clusterer/:keyword`,
      component: React.lazy(() => import("root/iframes/ClustererByKeyword")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Геопозиция",
      url: `/test/geo-size-location/:product`,
      component: React.lazy(() => import("root/iframes/GeoSizeLocationById")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Исходящий трафик",
      url: `/test/outside-traffic/:id`,
      component: React.lazy(() => import("root/iframes/Outer")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Сводная информация по запросу",
      url: `/test/keys-finder/:keyword`,
      component: React.lazy(() => import("root/iframes/KeysFinderByKeyword")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Позиции",
      url: `/test/position/:product_id/:phrase`,
      component: React.lazy(() => import("root/iframes/PositionById")),
      onlyInDev: true,
      exact: false,
    },
    {
      title: "Страница приветствия",
      url: "/iframe/welcome-to-extension",
      component: React.lazy(() => import("root/iframes/WelcomePageForExtension/index")),
      onlyInDev: true,
      exact: true,
    },
    {
      title: "Отписка от новостей",
      url: "/iframe/unsub-from-newsletters",
      component: React.lazy(() => import("root/iframes/WelcomePageForExtension/unsubPage")),
      onlyInDev: true,
      exact: true,
    },
  ],
};

export default iframePages