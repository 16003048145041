import React, { Suspense, useEffect } from "react";
import { useState } from "react";

import Paper from "@mui/material/Paper";
import cn from "classnames";
import { prop } from "ramda";
import { batch, useDispatch, useSelector } from "react-redux";
import { REQUESTS_DEBOUNCE_TIME } from "root/js/constants";
import {
  RiteReturnDiagrama,
  RiteReturnsWidgets,
  RiterReturnDynamicProduct,
  RiterReturnOffices,
} from "root/store/financialLogic/action";
import { useDebouncedCallback } from "use-debounce";

import API from "root/js/api";

import CustomPieChart from "root/components/CustomPieChart/CustomPieChart";
import CardManyProcents from "root/components/global/cards/card-many-procents";
import MiniCardPrecents from "root/components/global/cards/mini-card-precents";
import TabSelect from "root/components/global/page-tab/nav-bar-select";
import LinearLoading from "root/components/LinearLoading";
import TabPanel from "root/components/TabPanel";
import { deliveryReturnRides, returnTables } from "root/pages/financial-logic/info";

import useNavigationMemory from "root/hooks/useNavigationMemory";

import { getLastWeek } from "../../../helpers";
import ReturnDiagrama from "../pages/return-diagrama";
import ReturnDiagramaProduct from "../pages/return-diagrama-product";

import styles from "./index.module.scss";

const ReturnsRides = ({ setIsDisabledWeek }) => {
  const dispatch = useDispatch();
  const WBAPI = new API();
  const lastWeek = getLastWeek();
  const [tab, setTab] = useState(0);

  const [table, setTable] = useState(4);

  const [isLoadingOffices, setIsLoadingOffices] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingDiagram, setIsLoadingDiagram] = useState(true);
  const [isLoadingWidgets, setIsLoadingWidgets] = useState(true);

  const { changeParams } = useNavigationMemory();

  const {
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    dateFrom,
    dateTo,
    subjectsId,
    riteReturnsWidgets,
    isLoadingReturnWidgets,
    isLoadingReturnsDiagram,
    isLoadingReturnOffices,
    isLoadingRiterReturnDynamicProduct,
    currencyName,
    riterReturnOffices,
    riteReturnsDiagram,
    riterReturnDynamicProduct,
  } = useSelector(state => state.financeLogic);

  const onChageTable = table => {
    setTable(table);
  };

  const checkWeek = async () => {
    try {
      const res = await WBAPI.riteReturnTables({
        key: keys.join(),
        value: "category_id",
        dateFrom: lastWeek.dateFrom,
        dateTo: lastWeek.dateTo,
      });
      res?.count > 0 ? setIsDisabledWeek?.(false) : setIsDisabledWeek?.(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (keys?.length > 0) checkWeek();
  }, [keys, window.location.pathname]);

  useEffect(() => {
    changeParams("table", table);
  }, [table]);
  const onChageTab = tab => {
    setTab(tab);
  };
  const value = tab === 0 ? "ride" : "return_amount";

  const fetchDataDebounce = useDebouncedCallback(async () => {
    batch(() => {
      dispatch(
        RiteReturnDiagrama({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          value,
          subjectsId,
        })
      );
      dispatch(
        RiterReturnOffices({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          value,
          subjectsId,
        })
      );
    });
    setIsLoadingOffices(false);
    setIsLoadingDiagram(false);
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0 && dateFrom) {
      setIsLoadingOffices(true);
      setIsLoadingDiagram(true);
      fetchDataDebounce.callback();
    }
  }, [
    keys,
    dateFrom,
    categoryId.join(""),
    brandIds.join(""),
    articulIds.join(""),
    value,
    subjectsId.join(""),
    tab,
    barcodeIds.join(""),
  ]);

  const fetchProductDebounce = useDebouncedCallback(async () => {
    dispatch(
      RiterReturnDynamicProduct({
        keys,
        dateFrom,
        dateTo,
        categoryId,
        brandIds,
        articulIds,
        barcodeIds,
        value,
        subjectsId,
      })
    );
    setIsLoadingProducts(false);
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0 && dateFrom) {
      setIsLoadingProducts(true);
      fetchProductDebounce.callback();
    }
  }, [
    keys,
    dateFrom,
    categoryId.join(""),
    brandIds.join(""),
    value,
    subjectsId.join(""),
    tab,
    barcodeIds.join(""),
  ]);

  const fetchWidgetsDebounce = useDebouncedCallback(async () => {
    dispatch(
      RiteReturnsWidgets({
        keys,
        dateFrom,
        dateTo,
        categoryId,
        brandIds,
        articulIds,
        barcodeIds,
        subjectsId,
      })
    );
    setIsLoadingWidgets(false);
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0 && dateFrom) {
      setIsLoadingWidgets(true);
      fetchWidgetsDebounce.callback();
    }
  }, [
    keys,
    dateFrom,
    categoryId.join(""),
    brandIds.join(""),
    articulIds.join(""),
    subjectsId.join(""),
    barcodeIds.join(""),
  ]);
  return (
    <section id="returnAndRides" className={styles.returnsRides}>
      <div className={styles.content}>
        {isLoadingReturnWidgets || isLoadingWidgets ? (
          <div className={cn(styles.box1, styles.boxLoader, styles.box1Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box1}>
            <CardManyProcents
              trip="Доля стоимости прямых доставок от общей стоимости логистики"
              titleValue="Сумма"
              subject="шт"
              titleQuanity="Количество"
              currency={currencyName}
              title="Доставка к клиенту"
              transformRotateIncrement="rotate(0deg)"
              transformRotateDicrement="rotate(90deg)"
              borderIncrement="4px solid #0FB682"
              borderDicrement="4px solid #EB255D"
              colorIncrement="#0FB682"
              colorDicrement="#EB255D"
              deliveryQuanity={
                prop("delivery_to_quantity", prop("current", riteReturnsWidgets)) || 0
              }
              deliveryQuanityPrev={prop("delivery_to_quantity", prop("prev", riteReturnsWidgets))}
              deliveryValue={prop("delivery_to_value", prop("current", riteReturnsWidgets)) || 0}
              deliveryValuePrev={prop("delivery_to_value", prop("prev", riteReturnsWidgets)) || 0}
              deliverySharePrev={
                prop("delivery_to_value_share", prop("current", riteReturnsWidgets)) || 0
              }
              deliverySharePrevTitle="Доля от всех доставок"
              italicStyle={true}
            />
          </div>
        )}

        {isLoadingReturnWidgets || isLoadingWidgets ? (
          <div className={cn(styles.box2, styles.boxLoader, styles.box2Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box2}>
            <CardManyProcents
              trip="Доля количества доставок от клиента при возврате к количеству доставок к клиенту при продаже"
              trip1="Доля количества доставок от клиента при отмене к количеству доставок к клиенту при продаже"
              titleValue="При отмене"
              subject="шт"
              titleQuanity="При возврате"
              currency="шт"
              transformRotateIncrement="rotate(90deg)"
              transformRotateDicrement="rotate(0deg)"
              transformRotateIncrementSecond="rotate(0deg)"
              transformRotateDicrementSecond="rotate(-268deg)"
              borderIncrement="4px solid #EB255D"
              borderDicrement="4px solid #0FB682"
              colorIncrement="#EB255D"
              colorDicrement="#0FB682"
              title="Доставка от клиента"
              deliveryQuanity={
                prop("delivery_from_return", prop("current", riteReturnsWidgets)) || 0
              }
              deliveryQuanityPrev={
                prop("delivery_from_return", prop("prev", riteReturnsWidgets)) || 0
              }
              deliveryValue={prop("delivery_from_cancel", prop("current", riteReturnsWidgets)) || 0}
              deliveryValuePrev={
                prop("delivery_from_cancel", prop("prev", riteReturnsWidgets)) || 0
              }
              deliverySharePrev={
                prop("delivery_from_cancel_share", prop("current", riteReturnsWidgets)) || 0
              }
              deliveryShare={
                prop("delivery_from_return_share", prop("current", riteReturnsWidgets)) || 0
              }
              deliveryShareTitle="Доля от прямых доставок"
              deliverySharePrevTitle="Доля от прямых доставок"
              italicStyle={true}
            />
          </div>
        )}

        {isLoadingReturnWidgets || isLoadingWidgets ? (
          <div className={cn(styles.box3, styles.boxLoader, styles.box3Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box3}>
            <MiniCardPrecents
              title="Логистика возвратов"
              valuet={currencyName}
              transformRotateIncrement="rotate(90deg)"
              transformRotateDicrement="rotate(0deg)"
              borderIncrement="4px solid #EB255D"
              borderDicrement="4px solid #0FB682"
              colorIncrement="#EB255D"
              colorDicrement="#0FB682"
              trip="Доля стоимости обратных доставок при возврате к стоимости доставки к клиенту при продаже"
              deliver={prop("delivery_from_value", prop("current", riteReturnsWidgets)) || 0}
              deliverPrev={prop("delivery_from_value", prop("prev", riteReturnsWidgets)) || 0}
              precent={prop("delivery_from_value_share", prop("current", riteReturnsWidgets)) || 0}
              deliverySharePrevTitle="Доля от прямых доставок"
              italicStyle={true}
            />
          </div>
        )}

        {isLoadingReturnWidgets || isLoadingWidgets ? (
          <div className={cn(styles.box4, styles.boxLoader, styles.box4Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box4}>
            <MiniCardPrecents
              title="Покатушки"
              valuet={currencyName}
              transformRotateIncrement="rotate(0deg)"
              transformRotateDicrement="rotate(-268deg)"
              borderIncrement="4px solid #EB255D"
              borderDicrement="4px solid #0FB682"
              colorIncrement="#EB255D"
              colorDicrement="#0FB682"
              trip="Доля стоимости прямых и обратных доставок при отмене к стоимости доставки к клиенту при продаже"
              deliver={prop("ride", prop("current", riteReturnsWidgets)) || 0}
              deliverPrev={prop("ride", prop("prev", riteReturnsWidgets)) || 0}
              precent={prop("ride_share", prop("current", riteReturnsWidgets)) || 0}
              deliverySharePrevTitle="Доля от прямых доставок"
              italicStyle={true}
            />
          </div>
        )}

        {isLoadingReturnWidgets || isLoadingWidgets ? (
          <div className={cn(styles.box5, styles.fullWidth, styles.boxLoader, styles.box5Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box5}>
            <TabSelect data={deliveryReturnRides} onChange={onChageTab} tab={tab} />
          </div>
        )}

        {isLoadingReturnOffices || !riterReturnOffices || isLoadingOffices ? (
          <div className={cn(styles.box6, styles.boxLoader, styles.box6Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <>
            <div className={styles.box6}>
              <CustomPieChart
                tab={value}
                colors={[
                  "#1E85FF",
                  "#FCBC00",
                  "#D3D3D3",
                  "#B983EF",
                  "#00008B",
                  "#CF3AA0",
                  "#68B818",
                ]}
                data={riterReturnOffices}
                currencyName={currencyName}
                precentInHint={true}
                title={
                  value === "ride"
                    ? "Стоимость доставок нереализованных товаров в разрезе складов"
                    : "Количество доставок нереализованных товаров в разрезе складов"
                }
              />
            </div>
          </>
        )}

        {isLoadingRiterReturnDynamicProduct || !riterReturnDynamicProduct || isLoadingProducts ? (
          <div className={cn(styles.box7, styles.fullWidth, styles.boxLoader, styles.box7Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box7}>
            <ReturnDiagramaProduct
              riterReturnDynamicProduct={riterReturnDynamicProduct}
              tab={value}
            />
          </div>
        )}

        {isLoadingReturnsDiagram || !riteReturnsDiagram || isLoadingDiagram ? (
          <div className={cn(styles.box8, styles.boxLoader, styles.box8Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box8}>
            <ReturnDiagrama riteReturnsDiagram={riteReturnsDiagram} tab={value} />
          </div>
        )}
      </div>

      <div className={styles.tableWrap}>
        <Paper elevation={0}>
          {returnTables.map((item, index) => (
            <Suspense key={item.keyIndex} fallback={<></>}>
              <TabPanel value={table} index={index}>
                <item.component onTabChange={onChageTable} tabValue={table} />
              </TabPanel>
            </Suspense>
          ))}
        </Paper>
      </div>

      {!isLoadingReturnOffices &&
        !isLoadingRiterReturnDynamicProduct &&
        !isLoadingReturnsDiagram &&
        !isLoadingOffices &&
        !isLoadingDiagram &&
        riterReturnOffices?.length === 0 &&
        riterReturnDynamicProduct?.length === 0 &&
        riteReturnsDiagram?.length !== 0 &&
        riteReturnsDiagram?.every(el => !el.ride_share && !el.unsold_products_delivery) && (
          <div className={styles.noDataMessage}>
            <div className={styles.box}>
              <div className={styles.text}>
                Для заданных параметров фильтрации возвраты и покатушки отсутствуют
              </div>
            </div>
          </div>
        )}
    </section>
  );
};

export default ReturnsRides;
