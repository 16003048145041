import React, { useCallback, useMemo, useState } from "react";

import { Box, Checkbox, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTrendRequestsSelector } from "root/store/trendRequests";

import styles from "./index.module.scss";

//
const ratings = [
  { title: "Лучшие", value: 5, id: 5 },
  { title: "Хорошие", value: 4, id: 4 },
  { title: "Средние", value: 3, id: 3 },
  { title: "Плохие", value: 2, id: 2 },
  // { title: "Ужасные", value: 1, id: 1 },
];
const reduxName = "rating_range__in";
// --------------------------------------------------------
const TrendRequestsRatingSelector = () => {
  const dispatch = useDispatch();
  const selectedRatings = useSelector(s => s.trendRequests.selectors[reduxName]);
  //
  const changeSelectedRatings = useCallback(value => {
    dispatch(setTrendRequestsSelector({ name: reduxName, value }));
  }, []);
  //
  const addRating = useCallback(
    id => changeSelectedRatings([...selectedRatings, id]),
    [selectedRatings, changeSelectedRatings]
  );
  const removeRating = useCallback(
    id => changeSelectedRatings(selectedRatings.filter(item => item !== id)),
    [selectedRatings, changeSelectedRatings]
  );
  const toggleRatings = useCallback(
    id => (selectedRatings.includes(id) ? removeRating(id) : addRating(id)),
    [selectedRatings, addRating, removeRating]
  );
  //
  return (
    <Box my={3}>
      <Typography variant="h5">Рейтинг качества ниши</Typography>
      <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"} gap={"1rem 2rem"}>
        {ratings.map(item => (
          <Box
            key={item.value}
            display={"flex"}
            gap={0.5}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={() => toggleRatings(item.id)}>
            <Checkbox
              checked={selectedRatings.includes(item.id)}
              // onChange={() => toggleRatings(item.id)}
            />
            <Box display={"flex"} flexDirection={"column"} alignItems={"start"} gap={"0 6px"}>
              <Typography>{item.title}</Typography>
              <TrendRequestsCircleRating count={item.value} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const TrendRequestsCircleRating = ({ count }) => {
  const currentColor = useMemo(() => {
    switch (count) {
      case 5:
        return styles.best;
      case 4:
        return styles.great;
      case 3:
        return styles.good;
      case 2:
        return styles.middle;
      case 1:
        return styles.bad;
    }
  }, [count]);
  return (
    <Box className={styles.circleRatingBox}>
      {[1, 2, 3, 4, 5].map(item => (
        <Box key={item} className={count >= item ? currentColor : ""}></Box>
      ))}
    </Box>
  );
};

export default TrendRequestsRatingSelector;
