import request from "root/js/request";

export const getAnalyticsBrandsByContent = async ({
  imt_id,
  cabinet_id__in,
  subject_id__in,
  category_id__in,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/brands/by-content/",
    method: "get",
    params: {
      imt_id,
      cabinet_id__in,
      subject_id__in,
      category_id__in,
      ...rest,
    },
  });
  return response;
};
