import React from "react";

import { prop } from "ramda";
import { useSelector } from "react-redux";

import ChildCard from "root/components/global/cards/card-child";
import CardTwoProcents from "root/components/global/cards/card-two-procents";

import styles from "./index.module.scss";

const CardIndicators = () => {
  const { keyIndicatorsWidgets, currencyName } = useSelector(state => state.financeLogic);

  return (
    <div className={styles.cardIndicatorsContainer}>
      <div className={styles.cardsContainer}>
        <div className={styles.cardItem}>
          <CardTwoProcents
            title="Продажи"
            valueTitle="Сумма"
            borderIncrement="4px solid #00C85A"
            borderDicrement="4px solid red"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(90deg)"
            colorIncrement="#00C85A"
            colorDicrement="red"
            valueTrip="Фактическая стоимость (с учетом всех скидок) проведенной операции по продаже товаров маркетплейсом покупателям"
            valueValuet={currencyName}
            value={prop("sale_value", prop("current", keyIndicatorsWidgets)) || 0}
            secondValueTitle="Количество"
            secondValueValuet="шт"
            secondValue={prop("sale_quantity", prop("current", keyIndicatorsWidgets)) || 0}
            resultPrecent={
              (prop("sale_value", prop("current", keyIndicatorsWidgets)) -
                prop("sale_value", prop("prev", keyIndicatorsWidgets))) /
                prop("sale_value", prop("prev", keyIndicatorsWidgets)) || 0
            }
            secondResultPrecent={
              (prop("sale_quantity", prop("current", keyIndicatorsWidgets)) -
                prop("sale_quantity", prop("prev", keyIndicatorsWidgets))) /
                prop("sale_quantity", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />

          <ChildCard
            title="Средний чек"
            borderIncrement="4px solid #00C85A"
            borderDicrement="4px solid red"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(90deg)"
            secondValue="%"
            colorIncrement="#00C85A"
            colorDicrement="red"
            trip="Отношение суммы продаж к количеству продаж"
            valuet={currencyName}
            value={prop("sale_avg_bill", prop("current", keyIndicatorsWidgets))?.toFixed(0) || 0}
            precent={
              (prop("sale_avg_bill", prop("current", keyIndicatorsWidgets)) -
                prop("sale_avg_bill", prop("prev", keyIndicatorsWidgets))) /
                prop("sale_avg_bill", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />
        </div>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.cardItem}>
          <CardTwoProcents
            title="Возвраты"
            valueTitle="Сумма"
            valueTrip="Фактическая стоимость проведенной операции по возврату товаров
					покупателями маркетплейсу"
            valueValuet={currencyName}
            borderIncrement="4px solid red"
            borderDicrement="4px solid #00C85A"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(-268deg)"
            colorIncrement="red"
            colorDicrement="#00C85A"
            value={prop("return_value", prop("current", keyIndicatorsWidgets)) || 0}
            resultPrecent={
              (prop("return_value", prop("current", keyIndicatorsWidgets)) -
                prop("return_value", prop("prev", keyIndicatorsWidgets))) /
                prop("return_value", prop("prev", keyIndicatorsWidgets)) || 0
            }
            secondValueTitle="Количество"
            secondValueValuet="шт"
            secondValue={prop("return_quantity", prop("current", keyIndicatorsWidgets)) || 0}
            secondResultPrecent={
              (prop("return_quantity", prop("current", keyIndicatorsWidgets)) -
                prop("return_quantity", prop("prev", keyIndicatorsWidgets))) /
                prop("return_quantity", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />

          <ChildCard
            title="Доля возвратов"
            trip="Отношение количества возвратов к количеству продаж (в пределах границ
						периода)"
            valuet="%"
            borderIncrement="4px solid red"
            borderDicrement="4px solid #00C85A"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(-268deg)"
            colorIncrement="red"
            secondValue="п.п."
            colorDicrement="#00C85A"
            value={prop("return_share", prop("current", keyIndicatorsWidgets)) || 0}
            precent={
              prop("return_share", prop("current", keyIndicatorsWidgets)) -
                prop("return_share", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />
        </div>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.cardItem}>
          <CardTwoProcents
            title="Затраты на WB"
            valueTitle="Комиссия"
            valueTrip="Сумма фактически начисленной комиссии маркетплейса, включающей
					вознаграждение комиссионера, в т.ч. НДС"
            valueValuet={currencyName}
            borderIncrement="4px solid red"
            borderDicrement="4px solid #00C85A"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(-268deg)"
            colorIncrement="red"
            colorDicrement="#00C85A"
            value={prop("commission", prop("current", keyIndicatorsWidgets)) || 0}
            resultPrecent={
              (prop("commission", prop("current", keyIndicatorsWidgets)) -
                prop("commission", prop("prev", keyIndicatorsWidgets))) /
                prop("commission", prop("prev", keyIndicatorsWidgets)) || 0
            }
            secondValueTitle="Услуги WB"
            secondValueValuet={currencyName}
            secondValue={prop("wb_service", prop("current", keyIndicatorsWidgets)) || 0}
            secondResultPrecent={
              (prop("wb_service", prop("current", keyIndicatorsWidgets)) -
                prop("wb_service", prop("prev", keyIndicatorsWidgets))) /
                prop("wb_service", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />

          <ChildCard
            borderIncrement="4px solid red"
            borderDicrement="4px solid #00C85A"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(-268deg)"
            colorIncrement="red"
            colorDicrement="#00C85A"
            title="кВВ + СПП"
            secondValue="п.п."
            trip="Фактический размер коэффициента вознаграждения и скидки WB (согласно принципу п.
						12.5. оферты)"
            valuet="%"
            value={prop("commission_percent", prop("current", keyIndicatorsWidgets)) || 0}
            precent={
              prop("commission_percent", prop("current", keyIndicatorsWidgets)) -
                prop("commission_percent", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />
        </div>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.cardItem}>
          <CardTwoProcents
            title="Проблемные статьи"
            valueTitle="Штрафы"
            valueValuet={currencyName}
            value={prop("penalty", prop("current", keyIndicatorsWidgets)) || 0}
            resultPrecent={
              (prop("penalty", prop("current", keyIndicatorsWidgets)) -
                prop("penalty", prop("prev", keyIndicatorsWidgets))) /
                prop("penalty", prop("prev", keyIndicatorsWidgets)) || 0
            }
            borderIncrement="4px solid red"
            borderDicrement="4px solid #00C85A"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(-268deg)"
            colorIncrement="red"
            colorDicrement="#00C85A"
            secondValueTitle="Покатушки"
            secondValueValuet={currencyName}
            secondValueTrip="Сумма затрат на логистику в части товаров, отмененных
					покупателями (в пределах границ периода)"
            secondValue={prop("ride", prop("current", keyIndicatorsWidgets)) || 0}
            secondResultPrecent={
              (prop("ride", prop("current", keyIndicatorsWidgets)) -
                prop("ride", prop("prev", keyIndicatorsWidgets))) /
                prop("ride", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />

          <ChildCard
            title="Доля отмен"
            trip="Отношение количества отмен к количеству продаж (в пределах границ периода)"
            valuet="%"
            secondValue="п.п."
            borderIncrement="4px solid red"
            borderDicrement="4px solid #00C85A"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(-268deg)"
            colorIncrement="red"
            colorDicrement="#00C85A"
            value={prop("cancel_share", prop("current", keyIndicatorsWidgets)) || 0}
            precent={
              prop("cancel_share", prop("current", keyIndicatorsWidgets)) -
                prop("cancel_share", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />
        </div>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.cardItem}>
          <CardTwoProcents
            title="Доходы"
            valueTitle="Выручка"
            valueValuet={currencyName}
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(90deg)"
            borderIncrement="4px solid #00C85A"
            borderDicrement="4px solid red"
            colorIncrement="#00C85A"
            colorDicrement="red"
            value={prop("proceeds", prop("current", keyIndicatorsWidgets)) || 0}
            resultPrecent={
              (prop("proceeds", prop("current", keyIndicatorsWidgets)) -
                prop("proceeds", prop("prev", keyIndicatorsWidgets))) /
                prop("proceeds", prop("prev", keyIndicatorsWidgets)) || 0
            }
            secondValueTitle="Поступление"
            secondValueValuet={currencyName}
            secondValueTrip="Сумма, причитающаяся к перечислению"
            secondValue={prop("admission", prop("current", keyIndicatorsWidgets)) || 0}
            secondResultPrecent={
              (prop("admission", prop("current", keyIndicatorsWidgets)) -
                prop("admission", prop("prev", keyIndicatorsWidgets))) /
                prop("admission", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />

          <ChildCard
            title="Доля затрат на WB"
            trip="Отношение затрат на WB к выручке"
            valuet="%"
            secondValue="п.п."
            borderIncrement="4px solid red"
            borderDicrement="4px solid #00C85A"
            transformRotateIncrement="rotate(0deg)"
            transformRotateDicrement="rotate(-268deg)"
            colorIncrement="red"
            colorDicrement="#00C85A"
            value={prop("wb_service_share", prop("current", keyIndicatorsWidgets)) || 0}
            precent={
              prop("wb_service_share", prop("current", keyIndicatorsWidgets)) -
                prop("wb_service_share", prop("prev", keyIndicatorsWidgets)) || 0
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CardIndicators;
