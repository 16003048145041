import React, { useCallback, useEffect, useMemo, useState } from "react";

import CustomSelectorBox from "../utils/CustomSelectorBox";

import SelectorDrawer from "./SelectorDrawer";

// variables
const defaultGetCorrectDataAndTotal = (response = { count: 0, results: [] }) => {
  return [response.results, response.count];
};
// ------------------------------------------------------------------------------------
const SelectorWithDrawer = ({
  checkedItems: defaultCheckedItems = [],
  setCheckedItems: setCheckedItemsGlobal = () => {},
  emptySelectorText = "Выберите элементы",
  drawerTitle = emptySelectorText,
  noEmptySelectorText = `Элементов выбрано - (${defaultCheckedItems.length})`,
  apiMethodName = "useGetSomeData",
  fetchParams = {},
  isNewQueryAPI = false,
  // getCorrectDataAndTotal должна принять response и вернуть массив, первый элемент которого это
  // массив из объектов со свойствами id и/или name, а второй значение count для пагинации
  getCorrectDataAndTotal = defaultGetCorrectDataAndTotal,
  haveSearch = false,
  frontSearch, // делает поиск фронтовым, только для случаев когда нет пагинации
  maxSelectableItems = 100, // Максимум который можно выбрать
  listItemRender, // Отвечает за содержимое каждого пункта списка после чекбокса
  disabled = false,
}) => {
  //
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openDrawer = useCallback(() => setIsDrawerOpen(true), []);
  const closeDrawer = useCallback(() => setIsDrawerOpen(false), []);
  //
  const [checkedItems, setCheckedItems] = useState(defaultCheckedItems);
  const haveItems = useMemo(() => !!defaultCheckedItems.length, [defaultCheckedItems]);
  const saveChanges = useCallback(() => {
    setCheckedItemsGlobal(checkedItems);
    setIsDrawerOpen(false);
  }, [checkedItems]);
  const resetCheckedItems = useCallback(e => {
    e.stopPropagation();
    setCheckedItems([]);
    setCheckedItemsGlobal([]);
  }, []);
  //
  const selectorText = useMemo(
    () => (haveItems ? noEmptySelectorText : emptySelectorText),
    [haveItems, noEmptySelectorText, emptySelectorText]
  );
  //
  const setDefaultCheckedItems = useCallback(
    () => setCheckedItems(defaultCheckedItems),
    [defaultCheckedItems]
  );
  useEffect(() => {
    if (!haveItems) setCheckedItems([]);
  }, [defaultCheckedItems]);

  return (
    <>
      <CustomSelectorBox {...{ haveItems, openDrawer, resetCheckedItems, selectorText }} />
      {isDrawerOpen && !disabled && (
        <SelectorDrawer
          {...{
            checkedItems,
            setCheckedItems,
            saveChanges,
            apiMethodName,
            fetchParams,
            getCorrectDataAndTotal,
            haveSearch,
            frontSearch,
            isNewQueryAPI,
            setDefaultCheckedItems,
            maxSelectableItems,
            listItemRender,
          }}
          title={drawerTitle}
          isOpen={isDrawerOpen}
          onClose={closeDrawer}
        />
      )}
    </>
  );
};

export default SelectorWithDrawer;
