import React from "react";

import ProductInTableContent from "./ProductInTableContent";

// -------------------------------------------------------------
const ProductInTable = ({ article: trueArticle, articule, id, ...rest }) => {
  const { text, name } = rest;
  // Подобная двойственность связана с тем, что кто-то ошибся в английском написании
  // артикула, а потом кто-то решил поправить, теперь одно и то же свойство принимается под
  // разными переменными, тут я их объединил
  const article = trueArticle || articule || id;
  if (!article) return "Товар не найден";
  const correctText = text || name;
  if (!correctText || !rest.image) return article;
  return <ProductInTableContent {...{ ...rest, article, text: correctText }} />;
};

export default ProductInTable;
