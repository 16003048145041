import request from "root/js/request";

export const getAnalyticsForecastingAdditionalDeliveryItems = async ({
  wb_api_key_ids,
  additional_delivery_id,
  additional_delivery_id__in,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/forecasting/additional_delivery_items/",
    method: "get",
    params: {
      wb_api_key_ids,
      additional_delivery_id,
      additional_delivery_id__in,
      ...rest,
    },
  });
  return response.data;
};
