import request from "root/js/request";

export const getAnaliticsAdvertTableProductCostNullBarcodes = async ({
  cabinet_id__in,
  product_cost__isnull, // не обязательный
  group_by, // не обязательный
  ...rest
}) => {
  const response = await request({
    url: "analytics/advert_table/product_cost_null_barcodes/",
    params: {
      cabinet_id__in,
      product_cost__isnull,
      group_by,
      ...rest,
    },
  });
  return response.data;
};
