import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { getAbcEconomicLogicLorenzeCurve } from "./analytics/abc_analysis_lorenz_curve";
import { getAbcEconomicLogicPies } from "./analytics/abc_analysis_pie_data";
import { getAbcEconomicLogicTable } from "./analytics/abc_analysis_table";
import { getAbcEconomicLogicTopProducts } from "./analytics/abc_analysis_top_products";
import { getAbcEconomicLogicWidgets } from "./analytics/abc_analysis_widgets";
import { getAdvertAutoToPup, postAdvertAutoToPup } from "./analytics/advert-autotopup";
import { getAdvertAutoToPupSetting } from "./analytics/advert-autotopup/advert_id";
import { getAdvertsByDates } from "./analytics/advert_adverts_by_dates";
import { getAdvertsContentCardBrands } from "./analytics/advert_content_card_brands";
import { getAdvertsContentCardCategories } from "./analytics/advert_content_card_categories";
import { getAdvertsContentCardProductIds } from "./analytics/advert_content_card_product_ids";
import { getAdvertsContentCardSubjects } from "./analytics/advert_content_card_subjects";
import { getAdvertsContentCardSupplierArticle } from "./analytics/advert_content_card_supplier_articles";
import { getAnalyticsAdvertDynamic } from "./analytics/advert_dynamic";
import { getAnalyticsAdvertDynamicGMRelated } from "./analytics/advert_dynamic/gm_related";
import { getHeatMapAsTable } from "./analytics/advert_heat_map/as_table";
import { getAdvertHistory } from "./analytics/advert_history";
import { getAdvertKeywordsByKeywordsTable } from "./analytics/advert_keywords_by_keywords";
import { getAdvertTable } from "./analytics/advert_table";
import { getAnaliticsAdvertTableProductCostNullBarcodes } from "./analytics/advert_table/product_cost_null_barcodes";
import { getAdvertTop } from "./analytics/advert_top";
import { getAdvertWidgets } from "./analytics/advert_widgets";
import { getAnalyticsAdverts } from "./analytics/adverts";
import { getAdvertsUpdateList, postAdvertsUpdateList } from "./analytics/adverts/update_list.js";
import { getAnalyticsBarcodesSearch } from "./analytics/barcodes/search";
import { getAnalyticsBrandsByContent } from "./analytics/brands/by-content";
import { getAnalyticsBrandsByRRDates } from "./analytics/brands/by-rr-dates";
import {
  getCabinetAdvertSettings,
  postCabinetAdvertSettings,
} from "./analytics/cabinet-advert-settings";
import { getCabinetBalances } from "./analytics/cabinet_balances";
import { getUpdateCabinetBalances } from "./analytics/cabinet_balances/update";
import { getCapitalizationDynamic } from "./analytics/capitalization_dynamic";
import { getAnalyticsCapitalizationTable } from "./analytics/capitalization_table";
import {
  deleteCartKillerRule,
  getCartKillerRules,
  patchUpdateCartKillerRuleStatus,
  postCreateCartKillerRule,
  putUpdateCartKillerRule,
} from "./analytics/cart-killer";
import { getCartKillerBrands } from "./analytics/cart-killer/brands";
import { getCartKillerDaily } from "./analytics/cart-killer/daily";
import { getCartKillerHistory } from "./analytics/cart-killer/history";
import { getCartKillerProducts } from "./analytics/cart-killer/products";
import { getCartKillerSubjects } from "./analytics/cart-killer/subjects";
import { getCartKillerWidgets } from "./analytics/cart-killer/widgets";
import { getAnalyticsCategories } from "./analytics/categories";
import { getAnalyticsCategoriesByContent } from "./analytics/categories/by-content";
import { getAnalyticsCategoriesByRRDates } from "./analytics/categories/by-rr-dates";
import { getCommissionsAndDiscountsByCategories } from "./analytics/commission_discount_by_categories";
import { getCommissionTopProducts } from "./analytics/commission_discount_top";
import { getCommissionDiscountTopDetails } from "./analytics/commission_discount_top_details";
import { getAnaliticsCommissionDynamicV2 } from "./analytics/commission_dynamic_v2";
import { getCommissionsAndDiscountsTable } from "./analytics/commission_table";
import { getCommissionsAndDiscountsWidgetsV2 } from "./analytics/commission_widgets_v2";
import { getAnalyticsForecastingAdditionalDeliveries } from "./analytics/forecasting/additional_deliveries";
import { getAnalyticsForecastingAdditionalDeliveryItems } from "./analytics/forecasting/additional_delivery_items";
import { getWarehousesSupplyDynamic } from "./analytics/forecasting/warehouse_supply_dynamic";
import { getWarehousesSupplyTable } from "./analytics/forecasting/warehouse_supply_table";
import { getWarehousesSupplyWidgets } from "./analytics/forecasting/warehouse_supply_widgets";
import { getGlobalEvents } from "./analytics/global_events";
import {
  addAnalyticsGluing,
  deleteAnalyticsGluing,
  getAnalyticsGluing,
  getAnalyticsGluingActive,
  getAnalyticsGluingProducts,
} from "./analytics/gluing";
import {
  getAnalyticsKeyIndicatorsDynamic,
  getKeyIndicatorsDynamic,
} from "./analytics/key_indicators_dynamic";
import { getKeyIndicatorsTable } from "./analytics/key_indicators_table";
import { getKeyIndicatorsWBServicesStructure } from "./analytics/key_indicators_wb_services_structure";
import { getKeyIndicatorsWidgets } from "./analytics/key_indicators_widgets";
import { getNotificationGroups } from "./analytics/notification-groups";
import { getNotificationSubscription } from "./analytics/notification-subscriptions";
import { getOrderAndSales } from "./analytics/order_and_sales";
import { getPaidStorageBarcodes } from "./analytics/paid_storage_barcodes";
import { getPaidStorageBrands } from "./analytics/paid_storage_brands";
import { getStorageAndAcceptanceDiagram } from "./analytics/paid_storage_dynamic";
import { getStorageAndAcceptanceOfficesCircle } from "./analytics/paid_storage_offices";
import { getPaidStorageProductId } from "./analytics/paid_storage_product_id";
import { getPaidStorageSubjects } from "./analytics/paid_storage_subject";
import { getPaidStorageSupplierArticle } from "./analytics/paid_storage_supplier_article";
import { getStorageAndAcceptanceTable } from "./analytics/paid_storage_table";
import { getStorageAndAcceptanceTopProducts } from "./analytics/paid_storage_top_products";
import { getStorageAndAcceptanceWarehouseCoefsCircles } from "./analytics/paid_storage_warehouse_coefs";
import { getStorageAndAcceptanceWarehouseCoefsTable } from "./analytics/paid_storage_warehouse_coefs_table";
import { getStorageAndAcceptanceWarehouseDetailsTable } from "./analytics/paid_storage_warehouse_detalization_table";
import { getStorageAndAcceptanceWidgets } from "./analytics/paid_storage_widgets";
import { getFinesAndCompensationDynamic } from "./analytics/penalty_additionals_dynamic";
import { getFinesAndCompensationOffices } from "./analytics/penalty_additionals_offices";
import { getFinesAndCompensationTypes } from "./analytics/penalty_additionals_penalty_types";
import { getFinesAndCompensationTypesProducts } from "./analytics/penalty_additionals_penalty_types_products";
import { getFinesAndCompensationTable } from "./analytics/penalty_additionals_table";
import { getFinesAndCompensationTopProducts } from "./analytics/penalty_additionals_top_products";
import { getFinesAndCompensationWidgets } from "./analytics/penalty_additionals_widgets";
import { getProductCost } from "./analytics/product_cost";
import { getAnalyticsProductCostV2 } from "./analytics/product_cost_v2";
import { getAnalyticsProductsForSelectorsAdvert } from "./analytics/products-for-selectors/advert";
import { getAnalyticsProductsForSelectorsPaidStorage } from "./analytics/products-for-selectors/paid-storage";
import { getAnalyticsProductsForSelectorsRR } from "./analytics/products-for-selectors/rr";
import { getAnalyticsProductsGluing } from "./analytics/products_gluing";
import { getAnalyticsProductsJoint } from "./analytics/products_joint/";
import { getAnalyticsProductsSearch } from "./analytics/products_search/";
import { getPromotions } from "./analytics/promotion";
import { getPromotionsProducts } from "./analytics/promotion/promotion_product";
import { getRefundsAndTransfersDiagram } from "./analytics/ride_returns_dynamic";
import { getRefundsAndTransfersOffices } from "./analytics/ride_returns_offices";
import { getRefundsAndTransfersTables } from "./analytics/ride_returns_table";
import { getRefundsAndTransfersTopProduct } from "./analytics/ride_returns_top_products";
import { getRefundsAndTransfersWidgets } from "./analytics/ride_returns_widgets";
import { getSettings } from "./analytics/settings";
import { getSettingsNds } from "./analytics/settings_nds";
import { postCreateShares } from "./analytics/shares";
import { putShares } from "./analytics/shares";
import { managementProductsInShares } from "./analytics/shares/product_management";
import { getSharesProductArticle } from "./analytics/shares/products_article";
import { getSharesProductCategories } from "./analytics/shares/products_category";
import { getSharesProductSubjects } from "./analytics/shares/products_subject";
import { getShareById } from "./analytics/shares/share_by_id";
import {
  deleteSharesLevel,
  postSharesLevel,
  putSharesLevels,
} from "./analytics/shares/shares_level";
import { getSharesList } from "./analytics/shares/shares_list";
import { postSharesUpload } from "./analytics/shares/shares_upload";
import { getSharesProducts } from "./analytics/shares_products";
import { postDownloadCostExample } from "./analytics/shares_products/export";
import { postUploadCost } from "./analytics/shares_products/upload_cost";
import { postPromotionsProductsUpload } from "./analytics/shares_products/upload_excel";
import { getSharesSummary } from "./analytics/shares_summary";
import { getSharesTable } from "./analytics/shares_table";
import { getSharesTableExport } from "./analytics/shares_table/export";
import { getAnalyticsSubjectsByContent } from "./analytics/subjects/by-content";
import { getAnalyticsSubjectsByRRDates } from "./analytics/subjects/by-rr-dates";
import { getTaxRates } from "./analytics/tax_rates";
import { getTop10Products } from "./analytics/top_ten_products";
import { getTradeEconomyDynamic } from "./analytics/trade_economy_dynamic";
import { getTradeEconomyTable } from "./analytics/trade_economy_table";
import { getTradeEconomyWidgets } from "./analytics/trade_economy_widgets";
import { getUnitEconomyTable } from "./analytics/unit_economy_table";
import { getUnitEconomyTableExport } from "./analytics/unit_economy_table/export";
import { getMPCabinets } from "./company/mp_cabinets";
import { postCompanyV2PurchaseCalc } from "./company/v2/purchase_calc";
import { getWBCabinets } from "./company/wb_cabinets";
import { getWBtokens } from "./company/wb_cabinets/tokens";
import { getMarksBrands } from "./monitoring/marks/brands";
import { getMarksCategories } from "./monitoring/marks/categories";
import { getMarksProducts } from "./monitoring/marks/products";
import { getMarksSellers } from "./monitoring/marks/sellers";
import { GetMoySkladTokenById } from "./moysklad/tokens";
import { getAbcLorenzeCurveOzon } from "./ozon_analytics/abc_analysis/lorenz_curve";
import { getAbcPiesOzon } from "./ozon_analytics/abc_analysis/pie_data";
import { getAbcTableOzon } from "./ozon_analytics/abc_analysis/table";
import { abcTableOzonDownload } from "./ozon_analytics/abc_analysis/table/export";
import { getAbcTopProductsOzon } from "./ozon_analytics/abc_analysis/top_products";
import { getAbcAnalysisWidgetsOzon } from "./ozon_analytics/abc_analysis/widgets";
import { getPostingEconomyDiagramOzon } from "./ozon_analytics/posting_economy_diagram";
import { getEconomyOfShipmentsDynamicOzon } from "./ozon_analytics/posting_economy_dynamic";
import { getEconomyOfShipmentsOzonTable } from "./ozon_analytics/posting_economy_table";
import { getPostingEconomyWidgetsOzon } from "./ozon_analytics/posting_economy_widgets";
import { getUnitEconomyMultiTableOzon } from "./ozon_analytics/unit_economy_multi_table";
import { unitEconomyMultiTableOzonDownload } from "./ozon_analytics/unit_economy_multi_table/export";
import { getUnitEconomySingleTableOzon } from "./ozon_analytics/unit_economy_single_table";
import { unitEconomySingleTableOzonDownload } from "./ozon_analytics/unit_economy_single_table/export";
import { getKeywordsPosition } from "./parsers/keywords-position";
import { getKeywordsPositionData } from "./parsers/keywords-position/data";
import { exportKeywordsPositionRunId } from "./parsers/keywords-position/export";
import { getKeywordsPositionLimits } from "./parsers/keywords-position/limits";
import { postKeywordsPositionReloadRun } from "./parsers/keywords-position/reload_run";
import { getKeywordsPositionRunId } from "./parsers/keywords-position/run_id";
import { getGroupsKeywordsCluster } from "./parsers/keywords_cluster/groups";
import { getSocialBloggers } from "./parsers/social/bloggers";
import { getSocialBloggersBloggers } from "./parsers/social/bloggers/bloggers";
import { getSocialBloggersBrands } from "./parsers/social/bloggers/brands";
import { getSocialBloggersCount } from "./parsers/social/bloggers/count";
import { getSocialBloggersProducts } from "./parsers/social/bloggers/products";
import { getSocialBloggersRating } from "./parsers/social/bloggers/rating";
import { getSocialBloggersSubjects } from "./parsers/social/bloggers/subjects";
import { getSocialRating } from "./parsers/social/rating";
import { getParsersSupplierArticleAdvertsUsageHistory } from "./parsers/supplier_article_adverts/usage_history";
import { getTariffPlans } from "./tariff/plans";
import { getWBDynamicBrandsSummary } from "./wb_dynamic/brands_summary";
import { getWBDynamicBrandsSummaryCount } from "./wb_dynamic/brands_summary/count";
import { getBrandsTrend } from "./wb_dynamic/brands_trend";
import { getWBDynamicBrandsTrendGrouped } from "./wb_dynamic/brands_trend/grouped";
import { getWBDynamicCategoriesSummary } from "./wb_dynamic/categories_summary";
import { getWBDynamicCategoriesSummaryCount } from "./wb_dynamic/categories_summary/count";
import { getCategoriesTrend } from "./wb_dynamic/categories_trend";
import { getCategoriesTrendGrouped } from "./wb_dynamic/categories_trend/grouped";
import { getWBDynamicGeneralCategories } from "./wb_dynamic/general_categories";
import { getWBDynamicKeywordsStat } from "./wb_dynamic/keywords_stat";
import { getWBDynamicKeywordsStatByIDDailyDynamic } from "./wb_dynamic/keywords_stat/byId/daily_dynamic";
import { getKeywordsSummaryV2 } from "./wb_dynamic/keywords_summary_v2";
import { getKeywordsSummaryV2Count } from "./wb_dynamic/keywords_summary_v2/count";
import { getKeywordsSummaryV2Subjects } from "./wb_dynamic/keywords_summary_v2/subjects";
import { getProductsDynamicById } from "./wb_dynamic/products/byId";
import { getWBDynamicProductsSummary } from "./wb_dynamic/products_summary";
import { getWBDynamicProductsSummaryCount } from "./wb_dynamic/products_summary/count";
import { getProductsTrend } from "./wb_dynamic/products_trend";
import { getWBDynamicProductsTrendGrouped } from "./wb_dynamic/products_trend/grouped";
import { getWBDynamicSellersSummary } from "./wb_dynamic/sellers_summary";
import { getWBDynamicSellersSummaryCount } from "./wb_dynamic/sellers_summary/count";
import { getSellersTrend } from "./wb_dynamic/sellers_trend";
import { getWBDynamicSellersTrendGrouped } from "./wb_dynamic/sellers_trend/grouped";

// - api function list
const apiFunctions = {
  getAbcEconomicLogicLorenzeCurve,
  getAbcEconomicLogicPies,
  getAbcEconomicLogicTable,
  getAbcEconomicLogicTopProducts,
  getAbcEconomicLogicWidgets,
  getAnalyticsAdvertDynamic,
  getAdvertTable,
  getAdvertTop,
  getAdvertWidgets,
  getAdvertsByDates,
  getAdvertsContentCardBrands,
  getAdvertsContentCardCategories,
  getAdvertsContentCardProductIds,
  getAdvertsContentCardSubjects,
  getAdvertsContentCardSupplierArticle,
  getAnalyticsCapitalizationTable,
  getAnalyticsForecastingAdditionalDeliveries,
  getAnalyticsForecastingAdditionalDeliveryItems,
  getAnalyticsProductsForSelectorsAdvert,
  getAnalyticsProductsForSelectorsPaidStorage,
  getHeatMapAsTable,
  getAdvertKeywordsByKeywordsTable,
  getAnaliticsAdvertTableProductCostNullBarcodes,
  getCapitalizationDynamic,
  getAnalyticsCategories,
  getAnalyticsCategoriesByContent,
  getAnalyticsCategoriesByRRDates,
  getCommissionsAndDiscountsTable,
  getCommissionsAndDiscountsByCategories,
  getCommissionTopProducts,
  getCommissionDiscountTopDetails,
  getAnaliticsCommissionDynamicV2,
  getCommissionsAndDiscountsWidgetsV2,
  getAnalyticsBarcodesSearch,
  getAnalyticsBrandsByContent,
  getAnalyticsBrandsByRRDates,
  getAnalyticsSubjectsByContent,
  getAnalyticsSubjectsByRRDates,
  getTaxRates,
  getAnalyticsProductCostV2,
  getTariffPlans,
  postCompanyV2PurchaseCalc,
  getMPCabinets,
  getTop10Products,
  getAnalyticsProductsForSelectorsRR,
  getAnalyticsProductsGluing,
  getAnalyticsProductsJoint,
  getAnalyticsProductsSearch,
  getGlobalEvents,
  addAnalyticsGluing,
  getAnalyticsGluing,
  getAnalyticsGluingActive,
  getAnalyticsGluingProducts,
  deleteAnalyticsGluing,
  getKeyIndicatorsDynamic,
  getSharesProducts,
  getKeyIndicatorsTable,
  getKeyIndicatorsWBServicesStructure,
  getKeyIndicatorsWidgets,
  getNotificationGroups,
  getNotificationSubscription,
  getOrderAndSales,
  getPaidStorageBarcodes,
  getPaidStorageBrands,
  getPaidStorageProductId,
  getPaidStorageSubjects,
  getPaidStorageSupplierArticle,
  getProductsTrend,
  getStorageAndAcceptanceDiagram,
  getStorageAndAcceptanceOfficesCircle,
  getStorageAndAcceptanceTable,
  getStorageAndAcceptanceTopProducts,
  getStorageAndAcceptanceWarehouseCoefsCircles,
  getStorageAndAcceptanceWarehouseCoefsTable,
  getStorageAndAcceptanceWarehouseDetailsTable,
  getStorageAndAcceptanceWidgets,
  getFinesAndCompensationDynamic,
  getFinesAndCompensationOffices,
  getFinesAndCompensationTypes,
  getFinesAndCompensationTypesProducts,
  getFinesAndCompensationTable,
  getFinesAndCompensationTopProducts,
  getFinesAndCompensationWidgets,
  getAbcAnalysisWidgetsOzon,
  getWBCabinets,
  getWBtokens,
  GetMoySkladTokenById,
  getSettings,
  getSettingsNds,
  getEconomyOfShipmentsOzonTable,
  getPostingEconomyDiagramOzon,
  getEconomyOfShipmentsDynamicOzon,
  getPostingEconomyWidgetsOzon,
  getUnitEconomyMultiTableOzon,
  unitEconomyMultiTableOzonDownload,
  getUnitEconomySingleTableOzon,
  unitEconomySingleTableOzonDownload,
  getAbcLorenzeCurveOzon,
  getAbcPiesOzon,
  getAbcTableOzon,
  abcTableOzonDownload,
  getAbcTopProductsOzon,
  getSocialBloggers,
  getSocialBloggersBloggers,
  getSocialBloggersBrands,
  getSocialBloggersCount,
  getSocialBloggersProducts,
  getSocialBloggersRating,
  getSocialBloggersSubjects,
  getSocialRating,
  getKeywordsSummaryV2,
  getKeywordsSummaryV2Count,
  getKeywordsSummaryV2Subjects,
  getKeywordsPosition,
  getKeywordsPositionData,
  getKeywordsPositionLimits,
  postKeywordsPositionReloadRun,
  getKeywordsPositionRunId,
  getGroupsKeywordsCluster,
  exportKeywordsPositionRunId,
  getProductsDynamicById,
  getWBDynamicProductsTrendGrouped,
  getAnalyticsAdverts,
  getAdvertAutoToPup,
  postAdvertAutoToPup,
  getCabinetAdvertSettings,
  postCabinetAdvertSettings,
  getCabinetBalances,
  getUpdateCabinetBalances,
  getAdvertAutoToPupSetting,
  getPromotions,
  getSharesProductCategories,
  postCreateShares,
  getSharesList,
  getShareById,
  getAdvertHistory,
  postUploadCost,
  postDownloadCostExample,
  putShares,
  getPromotionsProducts,
  getRefundsAndTransfersDiagram,
  getRefundsAndTransfersOffices,
  getRefundsAndTransfersTables,
  getRefundsAndTransfersTopProduct,
  getRefundsAndTransfersWidgets,
  deleteSharesLevel,
  postSharesLevel,
  putSharesLevels,
  postPromotionsProductsUpload,
  getSharesTable,
  getSharesProductSubjects,
  getSharesSummary,
  postAdvertsUpdateList,
  getAdvertsUpdateList,
  getUnitEconomyTable,
  getUnitEconomyTableExport,
  getTradeEconomyWidgets,
  getTradeEconomyTable,
  getTradeEconomyDynamic,
  getWarehousesSupplyDynamic,
  getWarehousesSupplyTable,
  getWarehousesSupplyWidgets,
  getCategoriesTrend,
  getBrandsTrend,
  getSellersTrend,
  getMarksBrands,
  getMarksCategories,
  getMarksProducts,
  getMarksSellers,
  postSharesUpload,
  getSharesTableExport,
  getSharesProductArticle,
  getWBDynamicGeneralCategories,
  getWBDynamicProductsSummary,
  getWBDynamicProductsSummaryCount,
  getWBDynamicBrandsSummary,
  getWBDynamicBrandsSummaryCount,
  getWBDynamicCategoriesSummary,
  getWBDynamicCategoriesSummaryCount,
  getWBDynamicSellersSummary,
  getWBDynamicSellersSummaryCount,
  managementProductsInShares,
  getWBDynamicBrandsTrendGrouped,
  getWBDynamicSellersTrendGrouped,
  getCategoriesTrendGrouped,
  getProductCost,
  getParsersSupplierArticleAdvertsUsageHistory,
  getAnalyticsKeyIndicatorsDynamic,
  getAnalyticsAdvertDynamicGMRelated,
  getWBDynamicKeywordsStat,
  getWBDynamicKeywordsStatByIDDailyDynamic,
};

// --------------------------------------------------------------------
export const queryAPI = createApi({
  reducerPath: "queryAPI",
  baseQuery: async ({ apiMethod, params }) => {
    try {
      const data = await apiMethod(params);
      return { data };
    } catch (error) {
      return { error };
    }
  },
  tagTypes: ["UserData"],
  endpoints: build => {
    const functionsObject = {};
    for (const key in apiFunctions) {
      functionsObject[key] = build.query({
        query: params => ({
          apiMethod: apiFunctions[key],
          params,
        }),
        keepUnusedDataFor: 600,
        providesTags: () => [key],
      });
    }
    return functionsObject;
  },
});
