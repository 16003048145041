import request from "root/js/request";

export const getKeywordsSummaryV2 = async ({
  keyword_id__exact,
  name__icontains,

  create_date,

  subjects, // Мочалка,Полка
  subject_ids, // 2025529,2025522
  search, // <Платье>;<Платье2>
  stop_words, // <Платье>,<Платье2>
  stop_subjects, // <Платья>,<Мочалки>
  stop_subject_ids, // <subject_id1>,<subject_id1>

  rating_range__in,

  subject_percent_sales__gte,
  subject_percent_sales__lte,
  // is_seasonality,
  is_seasonality__exact,
  is_seasonality__isnull,
  is_trend,
  list_month_is_seasonality__contains,
  list_month_is_up_trend__contains,
  list_month_is_dow_trend__contains,

  products__gte,
  products__lte,
  frequency_per_product30__gte,
  frequency_per_product30__lte,

  first_week30__gte,
  first_week30__lte,
  first_week60__gte,
  first_week60__lte,
  first_week90__gte,
  first_week90__lte,
  last_week30__gte,
  last_week30__lte,
  last_week60__gte,
  last_week60__lte,
  last_week90__gte,
  last_week90__lte,

  diff_frequency30__gte,
  diff_frequency30__lte,
  diff_frequency60__gte,
  diff_frequency60__lte,
  diff_frequency90__gte,
  diff_frequency90__lte,
  diff_percent30__gte,
  diff_percent30__lte,
  diff_percent60__gte,
  diff_percent60__lte,
  diff_percent90__gte,
  diff_percent90__lte,

  days30__lte,
  days30__gte,
  days60__lte,
  days60__gte,
  days90__lte,
  days90__gte,

  rating__gte,
  rating__lte,
  proceeds__gte,
  proceeds__lte,
  lost_proceeds__gte,
  lost_proceeds__lte,
  percent_lost_proceeds__gte,
  percent_lost_proceeds__lte,
  orders__gte,
  orders__lte,
  lost_orders__gte,
  lost_orders__lte,
  percent_lost_orders__gte,
  percent_lost_orders__lte,
  median_price__gte,
  median_price__lte,
  avg_price__gte,
  avg_price__lte,
  demand_coefficient_product__gte,
  demand_coefficient_product__lte,
  potential_orders__gte,
  potential_orders__lte,
  potential_proceeds__gte,
  potential_proceeds__lte,
  avg_reviews_top100__gte,
  avg_reviews_top100__lte,
  avg_speed_reviews_top100__gte,
  avg_speed_reviews_top100__lte,
  percent_proceeds_top30__gte,
  percent_proceeds_top30__lte,
  avg_proceeds_product__gte,
  avg_proceeds_product__lte,
  avg_proceeds_product_sales__gte,
  avg_proceeds_product_sales__lte,
  count_products__gte,
  count_products__lte,
  count_products_sales__gte,
  count_products_sales__lte,
  percent_products_sales__gte,
  percent_products_sales__lte,
  count_products_advertising__gte,
  count_products_advertising__lte,
  percent_products_advertising__gte,
  percent_products_advertising__lte,
  count_products_external_traffic__gte,
  count_products_external_traffic__lte,
  percent_products_external_traffic__gte,
  percent_products_external_traffic__lte,
  count_sellers__gte,
  count_sellers__lte,
  count_sellers_sales__gte,
  count_sellers_sales__lte,
  percent_sellers_sales__gte,
  percent_sellers_sales__lte,
  percent_count_products_advertising__gte,
  percent_count_products_advertising__lte,

  subject_fbo__gte,
  subject_fbo__lte,
  subject_fbs_to_fbo__gte,
  subject_fbs_to_fbo__lte,
  subject_fbs_self_deliver__gte,
  subject_fbs_self_deliver__lte,
  subject_fbs_self_deliver_express__gte,
  subject_fbs_self_deliver_express__lte,

  ordering,
  limit,
  offset,
}) => {
  const response = await request({
    url: `/wb_dynamic/keywords_summary_v2/`,
    method: "GET",
    params: {
      keyword_id__exact,
      name__icontains,

      create_date,

      subjects, // Мочалка,Полка
      subject_ids, // 2025529,2025522
      search, // <Платье>;<Платье2>
      stop_words, // <Платье>,<Платье2>
      stop_subjects, // <Платья>,<Мочалки>
      stop_subject_ids, // <subject_id1>,<subject_id1>

      rating_range__in,

      subject_percent_sales__gte,
      subject_percent_sales__lte,
      is_seasonality: is_seasonality__exact,
      is_seasonality__isnull,
      is_trend__exact: is_trend,
      list_month_is_seasonality__contains,
      list_month_is_up_trend__contains,
      list_month_is_dow_trend__contains,

      products__gte,
      products__lte,
      frequency_per_product30__gte,
      frequency_per_product30__lte,

      first_week30__gte,
      first_week30__lte,
      first_week60__gte,
      first_week60__lte,
      first_week90__gte,
      first_week90__lte,
      last_week30__gte,
      last_week30__lte,
      last_week60__gte,
      last_week60__lte,
      last_week90__gte,
      last_week90__lte,

      diff_frequency30__gte,
      diff_frequency30__lte,
      diff_frequency60__gte,
      diff_frequency60__lte,
      diff_frequency90__gte,
      diff_frequency90__lte,
      diff_percent30__gte,
      diff_percent30__lte,
      diff_percent60__gte,
      diff_percent60__lte,
      diff_percent90__gte,
      diff_percent90__lte,

      days30__lte,
      days30__gte,
      days60__lte,
      days60__gte,
      days90__lte,
      days90__gte,

      rating__gte,
      rating__lte,
      proceeds__gte,
      proceeds__lte,
      lost_proceeds__gte,
      lost_proceeds__lte,
      percent_lost_proceeds__gte,
      percent_lost_proceeds__lte,
      orders__gte,
      orders__lte,
      lost_orders__gte,
      lost_orders__lte,
      percent_lost_orders__gte,
      percent_lost_orders__lte,
      median_price__gte,
      median_price__lte,
      avg_price__gte,
      avg_price__lte,
      demand_coefficient_product__gte,
      demand_coefficient_product__lte,
      potential_orders__gte,
      potential_orders__lte,
      potential_proceeds__gte,
      potential_proceeds__lte,
      avg_reviews_top100__gte,
      avg_reviews_top100__lte,
      avg_speed_reviews_top100__gte,
      avg_speed_reviews_top100__lte,
      percent_proceeds_top30__gte,
      percent_proceeds_top30__lte,
      avg_proceeds_product__gte,
      avg_proceeds_product__lte,
      avg_proceeds_product_sales__gte,
      avg_proceeds_product_sales__lte,
      count_products__gte,
      count_products__lte,
      count_products_sales__gte,
      count_products_sales__lte,
      percent_products_sales__gte,
      percent_products_sales__lte,
      count_products_advertising__gte,
      count_products_advertising__lte,
      percent_products_advertising__gte,
      percent_products_advertising__lte,
      count_products_external_traffic__gte,
      count_products_external_traffic__lte,
      percent_products_external_traffic__gte,
      percent_products_external_traffic__lte,
      count_sellers__gte,
      count_sellers__lte,
      count_sellers_sales__gte,
      count_sellers_sales__lte,
      percent_sellers_sales__gte,
      percent_sellers_sales__lte,
      percent_count_products_advertising__gte,
      percent_count_products_advertising__lte,

      subject_fbo__gte,
      subject_fbo__lte,
      subject_fbs_to_fbo__gte,
      subject_fbs_to_fbo__lte,
      subject_fbs_self_deliver__gte,
      subject_fbs_self_deliver__lte,
      subject_fbs_self_deliver_express__gte,
      subject_fbs_self_deliver_express__lte,

      ordering,
      limit,
      offset,
    },
  });
  return response.data;
};
