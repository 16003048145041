import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<rect
			x="3.75"
			y="3.75"
			width="16.5"
			height="16.5"
			rx="1.25"
			stroke={props.stroke}
			strokeWidth="1.5"
			strokeLinejoin="round"
			strokeDasharray="2 2"
			fill="white"
		/>
		<path
			fill={props.stroke}
			d="M9.99414 14.377L6.42871 10.8281L6.40039 10.8066L4.99805 12.209L9.99414 17.209L19.002 8.20898L17.584 6.79102L9.99414 14.377Z"
		/>
	</Template>
))
