import request from "root/js/request";

export const getSocialBloggers = async ({
  source__in,
  account_name_hash__in,
  brand_id__in,
  product_id__in,
  item__in,
  subject_id__in = item__in,
  frequency_avg__gte,
  frequency_avg__lte,
  price_avg__gte,
  price_avg__lte,
  er_avg__gte,
  er_avg__lte,
  subscriber_count__gte,
  subscriber_count__lte,
  posts_count__gte,
  posts_count__lte,
  valueble_posts_percent__gte,
  valueble_posts_percent__lte,
  like_avg__gte,
  like_avg__lte,
  comment_avg__gte,
  comment_avg__lte,
  proceeds_avg__gte,
  proceeds_avg__lte,
  orders_percent_avg__gte,
  orders_percent_avg__lte,
  orders_avg__gte,
  orders_avg__lte,
  err_avg__gte,
  err_avg__lte,
  view_avg__gte,
  view_avg__lte,
  // new
  orders_diff_avg__gte,
  orders_diff_avg__lte,
  proceeds_diff_avg__gte,
  proceeds_diff_avg__lte,
  orders_diff_sum__gte,
  orders_diff_sum__lte,
  proceeds_diff_sum__gte,
  proceeds_diff_sum__lte,
  proceeds_percent_avg__gte,
  proceeds_percent_avg__lte,
  //
  ordering,
  limit,
  offset,
  search,
}) => {
  const response = await request({
    url: `parsers/social/bloggers/`,
    method: "get",
    params: {
      source__in,
      account_name_hash__in,
      brand_id__in,
      product_id__in,
      subject_id__in: subject_id__in || item__in,
      frequency_avg__gte,
      frequency_avg__lte,
      proceeds_avg__gte,
      proceeds_avg__lte,
      orders_avg__gte,
      orders_avg__lte,
      price_avg__gte,
      price_avg__lte,
      orders_percent_avg__gte,
      orders_percent_avg__lte,
      err_avg__gte,
      err_avg__lte,
      er_avg__gte,
      er_avg__lte,
      like_avg__gte,
      like_avg__lte,
      view_avg__gte,
      view_avg__lte,
      subscriber_count__gte,
      subscriber_count__lte,
      comment_avg__gte,
      comment_avg__lte,
      posts_count__gte,
      posts_count__lte,
      valueble_posts_percent__gte,
      valueble_posts_percent__lte,
      // new
      orders_diff_avg__gte,
      orders_diff_avg__lte,
      proceeds_diff_avg__gte,
      proceeds_diff_avg__lte,
      orders_diff_sum__gte,
      orders_diff_sum__lte,
      proceeds_diff_sum__gte,
      proceeds_diff_sum__lte,
      proceeds_percent_avg__gte,
      proceeds_percent_avg__lte,
      //
      ordering,
      limit,
      offset,
      search,
    },
  });
  return response.data;
};
