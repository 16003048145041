import { createSlice } from "@reduxjs/toolkit";

import { getTop10Products, Top10LogicKey } from "./actions";

const transformDataTop = (data, quantityKey) => {
  return data.map(item => ({
    product: {
      product_id: item?.product_id,
      product_name: item?.product_name,
      product_image: item?.product_image,
    },
    [quantityKey]: item[quantityKey],
    marketplace: "Wildberries",
    proceeds: item?.proceeds,
  }));
};

const top10ProductsSlice = createSlice({
  name: "top10Products",
  initialState: {
    apiKeys: [],
    keys: [],
    currencyName: "",
    salesTop: [],
    marginTop: [],
    ransomTop: [],
    romiTop: [],
    loading: true,
    error: null,
  },
  reducers: {
    addKeysTop10: (state, action) => {
      state.keys = action.payload;
      const currency = state.apiKeys.filter(item => state.keys.includes(item.id));

      let elem = currency.map(elem => elem.currency_name);

      state.currencyName = elem[elem.length - 1];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTop10Products.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Top10LogicKey.fulfilled, (state, action) => {
        state.apiKeys = action.payload;
      })
      .addCase(getTop10Products.fulfilled, (state, action) => {
        state.loading = false;

        state.salesTop = transformDataTop(action.payload.sales_top || [], "sale_quantity");
        state.marginTop = transformDataTop(action.payload.margin_top || [], "gross_margin");
        state.ransomTop = transformDataTop(action.payload.ransom_top || [], "ransom_percent");
        state.romiTop = transformDataTop(action.payload.romi_top || [], "romi");
      })
      .addCase(getTop10Products.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { addKeysTop10 } = top10ProductsSlice.actions;
export default top10ProductsSlice.reducer;
