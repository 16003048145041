import React, { forwardRef } from "react";

import Template from "./utils/Template";

export default forwardRef((props, ref) => (
  <Template ref={ref} tags={["none"]} {...props}>
    <path d="M12 21C11.7669 21 11.5433 20.9074 11.3785 20.7426C11.2137 20.5777 11.1211 20.3542 11.1211 20.1211V3.87891C11.1211 3.64581 11.2137 3.42225 11.3785 3.25743C11.5433 3.0926 11.7669 3 12 3C12.2331 3 12.4567 3.0926 12.6215 3.25743C12.7863 3.42225 12.8789 3.64581 12.8789 3.87891V20.1211C12.8789 20.3542 12.7863 20.5777 12.6215 20.7426C12.4567 20.9074 12.2331 21 12 21Z" />
    <path d="M20.1211 12.8789H3.87891C3.64581 12.8789 3.42225 12.7863 3.25743 12.6215C3.0926 12.4567 3 12.2331 3 12C3 11.7669 3.0926 11.5433 3.25743 11.3785C3.42225 11.2137 3.64581 11.1211 3.87891 11.1211H20.1211C20.3542 11.1211 20.5777 11.2137 20.7426 11.3785C20.9074 11.5433 21 11.7669 21 12C21 12.2331 20.9074 12.4567 20.7426 12.6215C20.5777 12.7863 20.3542 12.8789 20.1211 12.8789Z" />
  </Template>
));
