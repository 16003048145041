import request from "root/js/request";

const getAdvertTable = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  advert_id__in,
  brand_id__in,
  category_id__in,
  subject_id__in,
  product_id__in,
  group_by = "advert_id",
  limit,
  offset,
  q,
  order_by,
  status__in,
  type__in,
  advert_id__icontains,
  views__gte,
  views__lte,
  clicks__gte,
  clicks__lte,
  atbs__gte,
  atbs__lte,
  sum__gte,
  sum__lte,
  sum_price__gte,
  sum_price__lte,
  orders__gte,
  orders__lte,
  ctr__gte,
  ctr__lte,
  cpc__gte,
  cpc__lte,
  cpm__gte,
  cpm__lte,
  cpa__gte,
  cpa__lte,
  frq__gte,
  frq__lte,
  car__gte,
  car__lte,
  gross_margin__gte,
  gross_margin__lte,
}) => {
  const response = await request({
    url: "/analytics/advert_table/",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      advert_id__in,
      brand_id__in,
      category_id__in,
      subject_id__in,
      product_id__in,
      group_by,
      limit,
      offset,
      q,
      order_by,
      status__in,
      type__in,
      advert_id__icontains,
      views__gte,
      views__lte,
      clicks__gte,
      clicks__lte,
      atbs__gte,
      atbs__lte,
      sum__gte,
      sum__lte,
      sum_price__gte,
      sum_price__lte,
      orders__gte,
      orders__lte,
      ctr__gte,
      ctr__lte,
      cpc__gte,
      cpc__lte,
      cpm__gte,
      cpm__lte,
      cpa__gte,
      cpa__lte,
      frq__gte,
      frq__lte,
      car__gte,
      car__lte,
      gross_margin__gte,
      gross_margin__lte,
    },
  });
  return response.data;
};

export { getAdvertTable };
