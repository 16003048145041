import request from "root/js/request";

export const getWBDynamicBrandsTrendGrouped = async ({
  period,
  date_from,
  date_to,
  brand_ids,
  collection_id,
  limit = 10,
  offset = 0,
  ordering,
  extra_fields,
  predict_trend, // просто чтобы не повторять в коде вне запроса синтаксическую ошибку
  predict_trand = predict_trend,
  ...rest
}) => {
  const response = await request({
    url: "/wb_dynamic/brands_trend/grouped/",
    method: "get",
    params: {
      period,
      date_from,
      date_to,
      brand_ids,
      collection_id,
      limit,
      offset,
      ordering,
      locf_dynamic: false,
      extra_fields,
      predict_trand,
      ...rest,
    },
  });

  return response.data;
};
