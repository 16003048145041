import React, { useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import classNames from "classnames";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import Button from "root/components/Button";
import DataNotLoaded from "root/components/Plugs/DataNotLoaded";
import TableImage from "root/components/TableImage";
import Tooltip_ from "root/components/Tooltip";

import Trips from "../../tips";

import { numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

import XlsIcon from "root/icons/Xls";

const DiagramBarChards = props => {
  const {
    width,
    height,
    maxHeight,
    title,
    titleTrip,
    data,
    rechartsRef,
    CustomTooltip,
    value,
    name,
    tab,
    compaireText,
    positionEnd,
    color,
    valuet,
    ticksValue,
    leftYAxisWidth,
    rightYAxisWidth,
    leftYAxisIsClickable,
    customExtendedData,
    secondValuet,
    dataColorCell,
    currentProduct,
    products,
    setTooltipData,
    tooltipData,
    setCurrentProduct,
    setExternalInd,
    externalInd,
    handleTypeClick,
    promotionHeight,
    isCommission,
    hasMinBarHeight,
    hasProductLink,
    isAcceptance,
    interval = "preserveEnd",
    exportFunction,
    isExporting,
    styleWripper,
    isDataNotLoaded = false,
  } = props;

  const [state, setState] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [hoveredId, setHoveredId] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(1450));

  const diagramRef = useRef(null);
  const axisWidth =
    diagramRef?.current?.querySelector(".recharts-cartesian-grid")?.getBoundingClientRect().width *
    0.01;

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = anchor => {
    if (customExtendedData) {
      const date = customExtendedData.find(elem => elem.type_name === anchor);

      if (date) {
        return (
          <Box className={styles.diagramInfoDecription}>
            <Typography className={styles.title}>{date.type_name}</Typography>

            {date.info &&
              date.info.map((elem, index) => (
                <Box key={index} className={styles.itemBlock}>
                  <TableImage
                    src={elem.product_thumbnail}
                    alt="Продукт"
                    className={styles.infoImage}
                  />
                  <Box className={styles.textContainer}>
                    <Typography className={styles.textItem}>
                      Артикул: <span className={styles.bold}>{elem.product_id}</span>
                    </Typography>
                    <Typography className={styles.textItem}>
                      Артикул поставщика:{" "}
                      <span className={styles.bold}>{elem.supplier_article || "нет данных"}</span>
                    </Typography>
                    <Typography className={`${styles.textItem}`}>
                      Наименование:{" "}
                      <span className={styles.bold}>{elem.product_name || "нет данных"}</span>
                    </Typography>
                    <Typography className={styles.penaltyInfo} display={"inline"} color={"#798097"}>
                      {" "}
                      {tab === compaireText ? "Сумма штрафа: " : "Количество штрафов: "}
                      <span className={styles.bold}>
                        {tab === compaireText
                          ? elem.penalty !== null && elem.penalty
                            ? `${numberWithSpaces(elem.penalty.toFixed(0))} ${valuet}`
                            : "нет данных"
                          : elem.penalty_quantity !== null && elem.penalty_quantity
                          ? `${numberWithSpaces(elem.penalty_quantity)} ${secondValuet}`
                          : "нет данных"}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        );
      }
    }
  };

  const CustomYAxisTick = ({ x, y, payload }) => {
    const tickColor = hoveredId === payload.value ? "#E12CA9" : "#262626";
    const product = data?.find(el => el.product_id === payload.value);

    return (
      <g
        transform={`translate(${x},${y})`}
        onMouseEnter={() => setHoveredId(payload.value)}
        onMouseLeave={() => setHoveredId(null)}>
        {hasProductLink ? (
          <Tooltip_ title={"Посмотреть на Wildberries"}>
            <a
              href={`https://www.wildberries.ru/catalog/${payload.value}/detail.aspx`}
              target="_blanck">
              <foreignObject x={-60} y={-30} width={40} height={40}>
                <TableImage
                  src={product?.product_thumbnail}
                  alt="Продукт"
                  className={styles.image}
                />
              </foreignObject>
            </a>
          </Tooltip_>
        ) : (
          <text
            x={0}
            y={-10}
            dy={16}
            textAnchor="end"
            fill={tickColor}
            className={leftYAxisIsClickable ? styles.isLink : ""}>
            {leftYAxisIsClickable ? (
              <a onClick={toggleDrawer(payload.value, true)}>
                {payload.value.length > 50 ? `${payload.value.slice(0, 50)}...` : payload.value}
              </a>
            ) : (
              payload.value
            )}
          </text>
        )}

        <Drawer
          anchor={"right"}
          open={state[payload.value]}
          onClose={toggleDrawer(payload.value, false)}
          sx={{
            "& .MuiDrawer-paper": {
              justifyContent: "flex-start",
            },
          }}>
          <Box
            px={1}
            py={1}
            display="flex"
            flexDirection={"column"}
            justifyContent="start"
            alignItems="end"
            className={styles.closeIconBlock}>
            <Box py={1} display="flex" justifyContent="end" className={styles.closeIconBlock}>
              <IconButton
                onClick={toggleDrawer(payload.value, false)}
                sx={{
                  zIndex: 12,
                }}>
                <CloseIcon fill="gray" />
              </IconButton>
            </Box>
            {exportFunction ? (
              <Box display={"flex"} justifyContent="end">
                <Button
                  className={styles.btnExport}
                  variant="outlined"
                  endIcon={<XlsIcon />}
                  iconSize={20}
                  isLoading={isExporting}
                  disabled={isExporting || data.length === 0}
                  loadingText="Отчет формируется..."
                  onClick={() => exportFunction(selectedType)}>
                  Скачать список
                </Button>
              </Box>
            ) : null}
          </Box>
          {list(payload.value)}
        </Drawer>
      </g>
    );
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleCellMouseEnter = index => {
    setActiveIndex(index);
  };

  const handleCellMouseLeave = () => {
    setActiveIndex(null);
  };

  const handleItemClick = payload => {
    if (!state[payload.value]) {
      if (handleTypeClick) handleTypeClick(payload.value);
      if (exportFunction && handleTypeClick) setSelectedType(payload.value);
    }
  };

  const handleBarClick = payload => {
    if (handleTypeClick) {
      handleTypeClick(payload.type_name);
      setState({ ...state, [payload.type_name]: open });
      if (exportFunction) setSelectedType(payload.type_name);
    }
  };

  let sortedData = [];
  if (tab === "penalty_quantity" && leftYAxisIsClickable) {
    sortedData = [...data].sort((a, b) => b.penalty_quantity - a.penalty_quantity);
  }

  return (
    <Box
      ref={diagramRef}
      className={classNames(
        positionEnd ? styles.finesContainer : styles.container,
        promotionHeight ? styles.promotionContainer : ""
      )}>
      <Box className={styles.alignedTitle}>
        <Typography sx={{ fontSize: 18, fontWeight: 500, color: "#43455C" }}>{title}</Typography>
        {titleTrip && <Trips text={titleTrip} />}
      </Box>
      <Box
        className={classNames(
          positionEnd
            ? styles.finesScrollableBlock
            : isCommission
            ? styles.commissionScrollableBlock
            : styles.scrollableBlock,
          maxHeight ? styles.customScrollableHeight : "",
          promotionHeight ? styles.promotionHeight : "",
          isAcceptance ? styles.promotionAcceptance : ""
        )}>
        {!isDataNotLoaded ? (
          <Box className={styles.positionCenter} style={styleWripper}>
            <ResponsiveContainer
              width={isDesktop ? width : styleWripper ? width : "100%"}
              height={height | "100%"}
              ref={rechartsRef}
              debounce={height}>
              <BarChart
                layout="vertical"
                width={Number(width)}
                height={height ? height : 500}
                data={
                  tab === "penalty_quantity" && leftYAxisIsClickable && sortedData
                    ? sortedData
                    : data
                }
                onMouseLeave={() => {
                  setCurrentProduct && setCurrentProduct(null);
                  setExternalInd && setExternalInd(null);
                }}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
                style={{ position: "inherit", width: "100%" }}>
                <CartesianGrid stroke="#f5f5f5" />

                <YAxis
                  yAxisId={0}
                  width={leftYAxisWidth ? leftYAxisWidth : 80}
                  dataKey={name}
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={value => value}
                  // label={<CustomYAxisLabel />}
                  tick={CustomYAxisTick}
                  onMouseEnter={value => {
                    setTooltipData && setTooltipData(value);
                    setSelectedProduct(value.value);
                    setCurrentProduct && setCurrentProduct(value.value);
                    products &&
                      setActiveIndex(products.findIndex(el => el.product_id === value.value));
                    setExternalInd &&
                      products &&
                      setExternalInd(products.findIndex(el => el.product_id === value.value));
                  }}
                  onMouseLeave={() => {
                    setTooltipData && setTooltipData(null);
                    setSelectedProduct(null);
                    setActiveIndex(null);
                    setCurrentProduct && setCurrentProduct(null);
                    setExternalInd && setExternalInd(null);
                  }}
                  onClick={handleItemClick}
                  interval={interval}
                />
                <YAxis
                  orientation="right"
                  yAxisId={1}
                  width={rightYAxisWidth ? rightYAxisWidth : 80}
                  dataKey={value}
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: "#798097" }}
                  tickFormatter={value =>
                    tab && tab === compaireText
                      ? value && `${numberWithSpaces(value.toFixed(0))} ${valuet}`
                      : promotionHeight
                      ? value && `${numberWithSpaces((value * 100).toFixed(1))} ${secondValuet}`
                      : value && `${numberWithSpaces(value.toFixed(0))} ${secondValuet}`
                  }
                  interval={interval}
                />
                <XAxis hide={true} type="number" name="stature" ticks={ticksValue} />

                <Tooltip
                  position={{ x: 160 }}
                  cursor={false}
                  wrapperStyle={{
                    pointerEvents: "auto",
                    position: "absolute",
                    visibility:
                      activeIndex || activeIndex === 0 || currentProduct ? "visible" : "hidden",
                  }}
                  content={CustomTooltip && <CustomTooltip data={tooltipData} />}
                />

                <Bar
                  dataKey={value}
                  barSize={hasProductLink ? 16 : 20}
                  fill={color ? color : "#413ea0"}
                  isAnimationActive
                  tick={CustomYAxisTick}
                  onClick={handleBarClick}
                  cursor="pointer"
                  minPointSize={hasMinBarHeight ? axisWidth : 0}>
                  {dataColorCell ||
                    data?.map((d, idx) => {
                      if (hasMinBarHeight) {
                        if (d[value]) {
                          return (
                            <Cell
                              key={idx}
                              onMouseOver={() => {
                                handleCellMouseEnter(idx);
                                setSelectedProduct(d.product_id);
                                setHoveredId(d[name]);
                              }}
                              onMouseLeave={() => {
                                handleCellMouseLeave();
                                setSelectedProduct(null);
                                setHoveredId(null);
                              }}
                              fill={hoveredId === d[name] ? "#E12CA9" : color}
                            />
                          );
                        } else {
                          return (
                            <Cell
                              key={idx}
                              onMouseOver={() => {
                                handleCellMouseEnter(idx);
                                setSelectedProduct(d.product_id);
                              }}
                              onMouseLeave={() => {
                                handleCellMouseLeave();
                                setSelectedProduct(null);
                              }}
                              fill="transparent"
                            />
                          );
                        }
                      } else {
                        return (
                          <Cell
                            key={idx}
                            onMouseOver={() => {
                              handleCellMouseEnter(idx);
                              setSelectedProduct(d.product_id);
                            }}
                            onMouseLeave={() => {
                              handleCellMouseLeave();
                              setSelectedProduct(null);
                            }}
                            fill={
                              idx === activeIndex || idx === externalInd
                                ? "#E12CA9"
                                : color
                                ? color
                                : `rgb(${(255 * (idx + 5)) / 20}, ${
                                    (255 * (idx + 5)) / 20
                                  }, ${230} )`
                            }
                          />
                        );
                      }
                    })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Box position={"relative"} height={"300px"}>
            <DataNotLoaded />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DiagramBarChards;
