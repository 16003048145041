import React, { useLayoutEffect, useState } from "react";

import Tabs from "@mui/material/Tabs";

import styles from "./index.module.scss";

const Tabs_ = props => {
  const [key, setKey] = useState(0);
  useLayoutEffect(() => {
    const setKeyFunction = () => setKey(1);
    window.addEventListener("load", setKeyFunction);
    return () => window.removeEventListener("load", setKeyFunction);
  }, []);

  return (
    <Tabs
      key={key}
      {...props}
      classes={{
        root: props.withoutborder ? styles.customNoBorder : styles.root,
        indicator: styles.indicator
      }}
      variant="scrollable"
      allowScrollButtonsMobile
      TabScrollButtonProps={{
        classes: {
          disabled: styles.disabled,
          root: styles.scrollButtonRoot
        }
      }}
    />
  );
};

export default Tabs_;
