import request from "root/js/request";

export const exportKeywordsPositionRunId = async ({ run_id }) => {
  const response = await request({
    url: `/parsers/keywords_position/export/`,
    method: "get",
    params: {
      run_id,
    },
  });
  return response;
};
