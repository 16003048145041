import React from "react";

import classNames from "classnames";


import Square from "../../icons/square";
import Triangle from "../../icons/triangle";
import Trips from "../../tips";

import { numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";
const CardManyProcents = props => {
  const {
    title,
    titleQuanity,
    deliveryQuanity,
    deliveryQuanityPrev,
    deliveryShare,
    deliveryShareTitle,
    titleValue,
    deliveryValue,
    deliveryValuePrev,
    deliverySharePrev,
    deliverySharePrevTitle,
    currency,
    subject,
    trip,
    trip1,
    borderIncrement,
    borderDicrement,
    colorIncrement,
    colorDicrement,
    transformRotateIncrement,
    transformRotateDicrement,
    italicStyle,
    transformRotateIncrementSecond,
    transformRotateDicrementSecond
  } = props;

  const quanityPrecent = (deliveryQuanity - deliveryQuanityPrev) / deliveryQuanityPrev || 0;
  const valuePrecent = (deliveryValue - deliveryValuePrev) / deliveryValuePrev || 0;

  return (
    <div
      className={styles.cardContainer}
      style={{
        borderTop:
          (quanityPrecent === Infinity || quanityPrecent === -Infinity || quanityPrecent === 0) &&
            (valuePrecent === Infinity || valuePrecent === -Infinity || valuePrecent === 0)
            ? "4px solid #FFA500"
            : quanityPrecent < 0 && valuePrecent < 0
              ? borderDicrement
              : borderIncrement
      }}>
      <h4> {title} </h4>
      <div className={styles.deliverBox}>
        <span> {titleQuanity}</span>

        {subject === "шт" ? (
          <span className={styles.subjects}>
            {numberWithSpaces(deliveryQuanity)} {subject}
          </span>
        ) : (
          <span className={styles.subjects}>
            {numberWithSpaces(deliveryQuanity.toFixed(0))} {subject}
          </span>
        )}
      </div>
      <div className={styles.iconQuanity}>
        <span
          style={{
            color:
              quanityPrecent === Infinity || quanityPrecent === -Infinity || quanityPrecent === 0
                ? "#FFA500"
                : quanityPrecent < 0
                  ? colorDicrement
                  : colorIncrement,
            fontSize: "12px"
          }}
          className={styles.precent}>
          {quanityPrecent === Infinity || quanityPrecent === -Infinity
            ? "-"
            : `${(quanityPrecent * 100).toFixed(1)}%`}
        </span>
        {quanityPrecent === 0 || quanityPrecent === Infinity || quanityPrecent === -Infinity ? (
          <Square />
        ) : (
          <Triangle
            result={quanityPrecent}
            colorIncrement={colorIncrement}
            colorDicrement={colorDicrement}
            transformRotateIncrement={transformRotateIncrement}
            transformRotateDicrement={transformRotateDicrement}
          />
        )}
      </div>

      <div className={styles.deliveryPrecent}>
        {deliveryShareTitle && (
          <span className={classNames(styles.precentTitle, italicStyle && styles.italic)}>
            {deliveryShareTitle}
            {trip && <Trips text={trip} />}
          </span>
        )}
        {deliveryShare && (
          <span className={classNames(styles.precent, italicStyle && styles.italic)}>
            {(deliveryShare * 100).toFixed(1)}%{" "}
          </span>
        )}
      </div>

      <div className={styles.deliverBox}>
        <span> {titleValue}</span>
        {currency === "шт" ? (
          <span className={styles.subjects}>
            {deliveryValue} {currency}
          </span>
        ) : (
          <span className={styles.subjects}>
            {numberWithSpaces(deliveryValue.toFixed(0))} {currency}
          </span>
        )}
      </div>

      <div className={styles.iconQuanity}>
        <span
          style={{
            color:
              valuePrecent === Infinity || valuePrecent === -Infinity || valuePrecent === 0
                ? "#FFA500"
                : valuePrecent < 0
                  ? colorDicrement
                  : colorIncrement,
            fontSize: "13px"
          }}
          className={styles.precent}>
          {valuePrecent === Infinity || valuePrecent === -Infinity
            ? "-"
            : `${(valuePrecent * 100).toFixed(1)}%`}
        </span>
        {valuePrecent === 0 || valuePrecent === Infinity || valuePrecent === -Infinity ? (
          <Square />
        ) : (
          <Triangle
            result={valuePrecent}
            colorIncrement={colorIncrement}
            colorDicrement={colorDicrement}
            transformRotateIncrement={
              transformRotateIncrementSecond
                ? transformRotateIncrementSecond
                : transformRotateIncrement
            }
            transformRotateDicrement={
              transformRotateDicrementSecond
                ? transformRotateDicrementSecond
                : transformRotateDicrement
            }
          />
        )}
      </div>

      <div className={styles.deliveryPrecent}>
        {deliverySharePrevTitle && (
          <span className={classNames(styles.precentTitle, italicStyle && styles.italic)}>
            {deliverySharePrevTitle}
            {trip && <Trips text={trip} />}
          </span>
        )}
        <span className={classNames(styles.precent, italicStyle && styles.italic)}>
          {deliverySharePrev === Infinity || deliverySharePrev === -Infinity
            ? 0
            : `${(deliverySharePrev * 100).toFixed(1)}%`}
        </span>
      </div>
    </div>
  );
};

export default CardManyProcents;
