import request from "root/js/request";

const getFinesAndCompensationDynamic = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  category_id__in,
  brand_id__in,
  supplier_article__in,
  barcode__in,
  subject_id__in,
  product_id__in,
  type_name,
  value,
}) => {
  const names = type_name.map(elem => elem.type_name);
  const promises = type_name.map(elem =>
    request({
      url: "analytics/penalty_additionals_dynamic/",
      method: "get",
      params: {
        wb_api_key_ids,
        date_from,
        date_to,
        category_id__in,
        brand_id__in,
        supplier_article__in,
        barcode__in,
        subject_id__in,
        product_id__in,
        type_name: elem.type_name,
        value,
      },
    })
  );

  const responses = await Promise.all(promises);

  const result = names.map((name, index) => ({
    type_name: name,
    dynamic: responses[index].data,
  }));
  return result;
};

export { getFinesAndCompensationDynamic };
