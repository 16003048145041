import React, { useEffect } from "react";

import { OutlinedInput } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { promotionMediaFilter } from "root/store/financialLogic/financialLogicSlice";

import TooltipUI from "root/components/Tooltip";
import { promotionMediaCompanyFilterHeaderCheckbox } from "root/pages/financial-logic/info";

import PromotionMediaFilterCheckbox from "../promotion-media-filter-checkbox";
import PromotionMediaFilterCompany from "../promotion-media-filter-company";

import styles from "./index.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PromotionMediaHeader = ({ setShowPositionChart, noDataCondition }) => {
  const dispatch = useDispatch();
  const { status } = useSelector(state => state.financeLogic);

  const handleChange = ({ id, checked }) => {
    if (checked) {
      const newStatus = [...status, id];
      dispatch(
        promotionMediaFilter({
          name: "status",
          value: newStatus,
        })
      );
    } else {
      if (status?.length > 1) {
        const newStatus = status.filter(elem => elem !== id);
        dispatch(
          promotionMediaFilter({
            name: "status",
            value: newStatus,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (status?.length === 0) {
      dispatch(
        promotionMediaFilter({
          name: "status",
          value: [9, 11, 7],
        })
      );
    }
  }, [status]);

  const formatedStatuses = promotionMediaCompanyFilterHeaderCheckbox
    .filter(item => {
      if (status.includes(item.id)) {
        return item;
      }
    })
    .map(i => i.title);

  return (
    <div className={styles.promotionMediaHeader}>
      <div className={styles.statusesBox}>
        <TooltipUI
          title={
            formatedStatuses.length && (
              <div>
                {formatedStatuses.map((elem, index) => {
                  return (
                    <div key={index}>
                      <span>{elem.length > 25 ? `${elem.slice(0, 25)}...` : elem}</span>
                    </div>
                  );
                })}
              </div>
            )
          }>
          <FormControl sx={{ m: 1, width: 300 }} size="small" className={styles.customHeight}>
            <InputLabel>Статусы кампаний</InputLabel>
            <Select
              className={styles.customHeight}
              multiple
              value={formatedStatuses}
              input={<OutlinedInput label="Статусы кампаний" />}
              renderValue={() => formatedStatuses.join(", ")}
              MenuProps={MenuProps}
              disabled={
                noDataCondition &&
                status.length === promotionMediaCompanyFilterHeaderCheckbox.length
              }>
              {promotionMediaCompanyFilterHeaderCheckbox.map(elem => (
                <MenuItem key={elem.id} value={elem.title}>
                  <Checkbox
                    checked={status.includes(elem.id)}
                    disabled={noDataCondition && status.includes(elem.id)}
                    onChange={e => handleChange({ id: elem.id, checked: e.target.checked })}
                  />
                  <ListItemText primary={elem.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TooltipUI>
        <PromotionMediaFilterCompany noDataCondition={noDataCondition} />
      </div>
      <PromotionMediaFilterCheckbox
        setShowPositionChart={setShowPositionChart}
        noDataCondition={noDataCondition}
      />
    </div>
  );
};

export default PromotionMediaHeader;
