import { createSlice } from "@reduxjs/toolkit";
import { uniq } from "ramda";

import { checkAction, checkAllAction, clearCheckBox, getDataIds, setParams, setWords } from "./actions";

const initialState = {
  words: "",
  params: {},
  page: 1,
  checkBoxes: {
    1: {
      checkedAll: false,
      ids: [],
    },
  },
  ids: {
    1: [],
  },
  allCheckBoxes: [],
};

export const trendRequestsOld = createSlice({
  name: "trend-requests",
  initialState,
  reducers: {
    // clearCheckBox(state) {
    //   state = {
    //     ...state,
    //     page: 1,
    //     checkBoxes: {
    //       1: {
    //         checkedAll: false,
    //         ids: [],
    //       },
    //     },
    //     ids: {
    //       1: [],
    //     },
    //     allCheckBoxes: [],
    //   }
    //   // console.log("state", state);
    // },
  },
  extraReducers: builder => {


    builder.addCase(clearCheckBox.fulfilled, (state) => {
      state.allCheckBoxes = []
      state.page = 1
      state.checkBoxes = {
        1: {
          checkedAll: false,
          ids: [],
        },
      }
      state.ids = {
        1: [],
      }
    });
    // !------------setWords
    builder.addCase(setWords.fulfilled, (state, { payload }) => {
      state.words = payload;
    });
    builder.addCase(setParams.fulfilled, (state, { payload }) => {
      state.params = payload;
    });

    // !------------checkbox
    //!--ids
    builder.addCase(getDataIds.fulfilled, (state, { payload }) => {
      const { page = 1, ids = [] } = payload;
      state.ids[page] = ids;
      state.page = page;
    });
    //!--checkAll
    builder.addCase(checkAllAction.fulfilled, (state, { payload }) => {
      const { page = 1, bool = true, ids } = payload;

      state.checkBoxes[page] = {
        ...(state.checkBoxes[page] ?? {}),
        checkedAll: bool,
        // ids: bool ? ids : []
      };
      // console.log({ ids });
      if (bool) {
        state.checkBoxes[page].ids = ids;
      } else {
        state.checkBoxes[page].ids = [];
      }

      if (bool) {
        state.allCheckBoxes = uniq(state.allCheckBoxes.concat(ids));
      } else {
        ids.forEach(id => {
          state.allCheckBoxes = state.allCheckBoxes.filter(value => value !== id);
        });
      }
    });
    builder.addCase(checkAction.fulfilled, (state, { payload }) => {
      const { value, bool = true } = payload;
      if (!state?.checkBoxes?.[state.page]) {
        state.checkBoxes = {
          ...state.checkBoxes,
          [state.page]: {
            checkedAll: false,
            ids: []
          }
        }
      }
      state.checkBoxes[state.page].checkedAll = false;
      if (!state?.checkBoxes?.[state.page]) {
        state.checkBoxes[state.page] = {
          ids: [],
          checkedAll: false,
        };
      }
      if (bool) {
        state.checkBoxes[state.page].ids.push(value);
        state.allCheckBoxes.push(value);
      } else {
        state.checkBoxes[state.page].ids = state.checkBoxes[state.page].ids.filter(id => id !== value);
        state.allCheckBoxes = state.allCheckBoxes.filter(id => id !== value);
      }
    });
  },
});

// export const { clearCheckBox } = trendRequestsOld.actions;

export default trendRequestsOld.reducer;
