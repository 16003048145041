import { createSlice } from "@reduxjs/toolkit"
import { uniq } from "ramda"

const initialState = {
	page: 1,
	checkBoxes: {
		"1_1": {
			checkedAll: false,
			ids: []
		},

	},
	ids: {
		"1_1": []
	},
	allCheckBoxes: {
		1: []
	}
}

const bloggerSlice = createSlice({
	name: "blogger",
	initialState,
	reducers: {
		// !------------checkbox
		//!--ids
		getDataIds(state, { payload }) {
			const { marketplace_page = 1, data = [] } = payload
			const ids = data.map(({ id }) => id)
			state.ids[marketplace_page] = ids
			state.page = marketplace_page?.split("_")?.[1] ?? 1
		},
		//!--checkAll
		checkAllAction(state, { payload }) {
			const { marketplace_page = "1_1", bool = true, ids } = payload

			state.checkBoxes[marketplace_page] = {
				...(state.checkBoxes[marketplace_page] ?? {}),
				checkedAll: bool,
			}
			if (bool) {

				state.checkBoxes[marketplace_page].ids = ids
			} else {
				state.checkBoxes[marketplace_page].ids = []
			}

			if (!state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1]) {
				state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1] = []
			}

			if (bool) {
				state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1] = uniq(state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1].concat(ids.filter(id => id)))
			} else {
				ids.forEach(id => {
					state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1] = state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1].filter(value => value !== id)
				})
			}
		},
		checkAction(state, { payload }) {
			const { value, marketplace_page = 1, bool = true } = payload

			if (!state?.checkBoxes?.[marketplace_page]) {
				state.checkBoxes[marketplace_page] = {
					ids: [],
					checkedAll: false
				}
			} else {
				state.checkBoxes[marketplace_page].checkedAll = false
			}

			if (!state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1]) {
				state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1] = []
			}

			if (bool) {
				state.checkBoxes[marketplace_page].ids.push(value)
				state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1].push(value)
			} else {
				state.checkBoxes[marketplace_page].ids = state.checkBoxes[marketplace_page].ids.filter(id => id !== value)
				state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1] = state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1].filter(id => id !== value)
			}
		},
		removeRunId(state, { payload }) {
			const { marketplace_page = "1_1", runIds } = payload
			state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1] = state.allCheckBoxes[marketplace_page?.split("_")?.[0] ?? 1].filter(item => !runIds.includes(item))
		}
	},
})

export const {
	removeRunId,
	setPrevForm,
	getDataIds,
	checkAllAction,
	checkAction
} = bloggerSlice.actions

export default bloggerSlice.reducer
