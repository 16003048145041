import request from "root/js/request";

export const getUnitEconomyTableExport = async ({
  date_to,
  date_from,
  group_by,
  order_by,
  q,
  limit = 1000,
  product_cost__isnull,
  gross_margin__lt,
  gross_margin_to_realization_price_share__gt,
  gross_margin_to_realization_price_share__lt,
  gross_margin_to_cost_share__lt,
  gross_margin_to_cost_share__gt,
  categories,
  subject_id__in,
  brands,
  product_id__icontains,
  supplier_article,
  wb_api_key_ids,
}) => {
  const response = await request({
    url: "/analytics/unit_economy_table/export/",
    method: "get",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      group_by,
      limit,
      q,
      product_cost__isnull,
      order_by,
      gross_margin__lt,
      gross_margin_to_realization_price_share__gt,
      gross_margin_to_realization_price_share__lt,
      gross_margin_to_cost_share__lt,
      gross_margin_to_cost_share__gt,
      category_id__in: categories,
      subject_id__in,
      brand_id__in: brands,
      product_id__icontains,
      supplier_article,
    },
  });
  return response.data;
};
