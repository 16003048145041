import React from "react";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";

import { accessByTariffs } from "../../accessByTariffs";

import autofeedbacksSection from "./sections/autofeedbacks";
import competitorAnalysisSections from "./sections/competitorAnalysis";
import externalAnalysisSection from "./sections/externalAnalysis";
import growthLeadersSection from "./sections/growthLeaders";
import iframePages from "./sections/iframesForTest";
import internalAnalyticsSection from "./sections/internalAnalytics";
import monitoringSection from "./sections/monitoring";
import nichesAndTrendsSection from "./sections/nichesAndTrends";
import promotionSection from "./sections/promotion";
import salesAnalyticsSection from "./sections/salesAnalytics";
// import salesAnalyticsWBSection from "./sections/salesAnalyticsWB";
import seoSection from "./sections/seo";

import ChromeIcon from "root/icons/Chrome";
import HunterTelegramIcon from "root/icons/HunterTelegram";
import LinkToWBIcon from "root/icons/LinkToWB";
import LogOutIcon from "root/icons/LogOut";
import Partner from "root/icons/Partner";
import YouTubeIcon from "root/icons/YouTube";
//
// Свойства для фильтрации
// onlyInDev - страница доступна только в компилируемой версии (дев01 и дев03 уже собранные)
// onlyForAdmin - страница доступна только админам
// noProd - страница доступна везде кроме продакшн версии
//
// const isDev = process.env.NODE_ENV === "development";
// -----------------------------------------------------------
const drawerPages = [
  competitorAnalysisSections, // "Анализ конкурентов"
  growthLeadersSection,
  nichesAndTrendsSection,
  externalAnalysisSection,
  promotionSection,
  seoSection,
  monitoringSection,
  // salesAnalyticsWBSection,
  salesAnalyticsSection,
  autofeedbacksSection,
  internalAnalyticsSection,
  {
    type: "sectionItem",
    title: "Подключения",
    icon: LinkToWBIcon,
    url: "/wb-cabinets",
    component: React.lazy(() => import("root/pages/connections/App")),
    exact: false,
  },
  {
    type: "sectionItem",
    title: "Браузерное расширение",
    icon: ChromeIcon,
    url: "/cabinet?tab=2",
    component: React.lazy(() => import("root/pages/cabinet/App")),
    exact: false,
  },
  {
    type: "sectionItem",
    title: "Телеграм бот",
    icon: HunterTelegramIcon,
    url: "/cabinet?tab=3",
    component: React.lazy(() => import("root/pages/cabinet/App")),
    exact: false,
  },
  {
    type: "sectionItem",
    title: "Мой профиль",
    icon: AccountCircleOutlinedIcon,
    url: "/cabinet?tab=0",
    component: React.lazy(() => import("root/pages/cabinet/App")),
  },
  {
    type: "sectionItem",
    title: "Оплата сервиса",
    icon: ErrorOutlineIcon,
    url: "/tariff",
    component: React.lazy(() => import("root/pages/tariff/App")),
  },
  {
    type: "sectionItem",
    title: "Партнерская программа",
    icon: Partner,
    url: "/partner",
    component: React.lazy(() => import("root/pages/partner/App")),
  },
  {
    type: "sectionItem",
    title: "Полезные материалы",
    icon: YouTubeIcon,
    url: "/useful",
    component: React.lazy(() => import("root/pages/useful/App")),
  },
  {
    type: "sectionItem",
    title: "Выход",
    icon: LogOutIcon,
    url: "/logout",
    component: React.lazy(() => import("root/pages/sign/components/SignOut")),
    exact: false,
  },
  {
    type: "sectionItem",
    onlyInDev: true,
    title: "Иконки",
    icon: WidgetsOutlinedIcon,
    url: "/icons",
    component: React.lazy(() => import("root/icons/utils/preview")),
    exact: true,
  },
  iframePages,
]
  // // Фильтруем секции, которые должны быть только на деве
  // .filter(item => {
  //   if (isDev) return true;
  //   return !item.onlyInDev;
  // })
  .map(item => {
    if (!item.children) return item;
    return {
      ...item,
      // title: getTitle(item),
      children: item.children
        // Фильтруем дочерние страницы, которые должны быть только на деве
        // .filter(child => isDev || !child.onlyInDev)
        .map(child => ({
          ...child,
          // Добавляем плашки new и beta для секций
          // title: getTitle(child),
          // Добавляем доступы по тарифам по страницам секций
          tariffIds: accessByTariffs[child.url],
        })),
    };
  });

export default drawerPages;
