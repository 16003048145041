import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

import IALineChartLocf from "root/components/Charts/IALineChartLocf";
import { prepareDataDiagram, propFields } from "root/components/Charts/IALineChartLocf/helpers";
import PagesTab from "root/components/global/pages-tabs";

import useNavigationMemory from "root/hooks/useNavigationMemory";

const KeyIndicatorsDiagram = () => {
  const { dynamicRevenue } = useSelector(state => state.financeLogic);
  const [diagramTab, setDiagramTab] = useState(0);
  const [isCheckOne, setIsCheckOne] = useState(true);
  const [isCheckTwo, setIsCheckTwo] = useState(true);
  const [isTrend, setIsTrend] = useState(false);
  const [isEvents, setIsEvents] = useState(true);
  const { changeParams } = useNavigationMemory();

  useEffect(() => {
    changeParams("diagramTab", diagramTab);
  }, [diagramTab]);

  const onChangeTab = tab => {
    setDiagramTab(tab);
  };

  const diagrams = [
    {
      keyIndex: 0,
      title: "Выручка",
      component: () => {
        const proceedsData = dynamicRevenue?.map(elem =>
          prepareDataDiagram(elem, propFields.proceeds, propFields.proceeds_trend)
        );
        return generateComponentDiagram(proceedsData, "руб");
      },
    },
    {
      keyIndex: 1,
      title: "Кол‐во реализованного товара",
      component: () => {
        const realizationQuantity = dynamicRevenue?.map(elem =>
          prepareDataDiagram(
            elem,
            propFields.realization_quantity,
            propFields.realization_quantity_trend
          )
        );
        return generateComponentDiagram(realizationQuantity, "шт");
      },
    },
    {
      keyIndex: 2,
      title: "Доля возвратов",
      trip: "Отношение количества возвратов к количеству продаж (в пределах границ периода)",
      component: () => {
        const returnRate = dynamicRevenue?.map(elem =>
          prepareDataDiagram(elem, propFields.return_share, propFields.return_share_trend)
        );
        return generateComponentDiagram(returnRate, "%");
      },
    },
    {
      keyIndex: 3,
      title: "Доля отмен",
      trip: "Отношение количества отмен к количеству продаж (в пределах границ периода)",
      component: () => {
        const cancellationRate = dynamicRevenue?.map(elem =>
          prepareDataDiagram(elem, propFields.cancel_share, propFields.cancel_share_trend)
        );
        return generateComponentDiagram(cancellationRate, "%");
      },
    },
    {
      keyIndex: 4,
      title: "Доля затрат на WB",
      trip: "Отношение затрат на WB к выручке",
      component: () => {
        const wbServiceRate = dynamicRevenue?.map(elem =>
          prepareDataDiagram(elem, propFields.wb_service_share, propFields.wb_service_share_trend)
        );
        return generateComponentDiagram(wbServiceRate, "%");
      },
    },
  ];
  const generateComponentDiagram = (data, currencyName) => {
    return (
      <IALineChartLocf
        data={data}
        currencyName={currencyName}
        currencyNameTwo={currencyName}
        isCheckOne={isCheckOne}
        isCheckTwo={isCheckTwo}
        isTrend={isTrend}
        isEvents={isEvents}
        setIsCheckOne={setIsCheckOne}
        setIsCheckTwo={setIsCheckTwo}
        setIsTrend={setIsTrend}
        setIsEvents={setIsEvents}
        labelOne={"Текущий период"}
        labelTwo={"Предыдущий период"}
        checkOne={true}
        checkTwo={true}
        trend={true}
        colorOne={"#68b818"}
        colorTwo={"#1e85ff"}
        eventsData={dynamicRevenue}
        events={diagramTab === 0 ? true : false}
      />
    );
  };

  return (
    <Paper
      elevation={0}
      style={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}>
      <PagesTab tab={diagramTab} onChange={onChangeTab} pages={diagrams} />
    </Paper>
  );
};

export default KeyIndicatorsDiagram;
