import React from "react";

import { isNil, prop } from "ramda";

import { getAxisDate, getDomain } from "root/js/utils";

export const BOARD_YAXIS_VALUE = 1000;

export const calculateOffset = value => {
  const baseOffset = 15; // Базовый сдвиг
  const multiplier = 10; // Множитель для увеличения сдвига
  const maxOffset = 50; // Максимальный сдвиг

  const offset = baseOffset + multiplier * Math.log10(value);

  return Math.min(offset, maxOffset);
};

// Проверка наличия значения
export const hasValidValue = value =>
  !isNil(value) && value !== false && value !== 0 && value !== "";

// Функция для отрисовки кастомной точки
export function renderCustomDot(payload, cx, cy, key, data, color) {
  if (!payload[key] && payload[key] !== 0) {
    return null;
  }

  return <circle cx={cx} cy={cy} r={4} fill={color} stroke="#fff" strokeWidth={2} />;
}

export const renderDot = (payload, cx, cy, field, data, color) => {
  return renderCustomDot(payload, cx, cy, field, data, color);
};

// Функция для проверки отрицательных значений на оси
export const checkZeroAxis = data => {
  return data?.some(
    el =>
      el.checkOnePress < 0 ||
      el.checkTwoPress < 0 ||
      el.checkThreePress < 0 ||
      el.checkFourPress < 0 ||
      el.proceeds_trend < 0
  );
};

// Универсальная функция для расчета домена по указанным полям
export const getYaxisDomainGeneric = (data, fields) => {
  const arr = [];
  const padding = 0.1;

  data.forEach(el => {
    fields.forEach(field => {
      const value = el[field];
      if (value !== undefined && value !== null && !isNaN(value) && typeof value === "number") {
        arr.push(value);
      } else {
        // Из-за игнорирования пустых значений домен строился неверно и графики выходили из своей оси Y
        arr.push(0);
      }
    });
  });

  if (arr.length === 0) return [0, 1];

  const min = Math.min(...arr);
  const max = Math.max(...arr);

  // Добавляем динамический отступ
  const adjustedMin = isFinite(min) ? min - min * padding : 0;
  const adjustedMax = isFinite(max) ? max + max * padding : 1;

  // return getDomain(0, adjustedMax);
  return getDomain(adjustedMin, adjustedMax);
  // return [min, max];
};

// Используем универсальную функцию для всех доменов
export const getYaxisDomain = data =>
  getYaxisDomainGeneric(data, [
    "checkOnePress",
    "checkTwoPress",
    "checkThreePress",
    "checkFourPress",
  ]);

export const getYaxisDomainRight = data => getYaxisDomainGeneric(data, ["checkTwoPress"]);

export const getYaxisDomainTwoRight = data => getYaxisDomainGeneric(data, ["checkThreePress"]);

export const getYaxisDomainTwoLeft = data => getYaxisDomainGeneric(data, ["checkFourPress"]);

// Функция для сравнения месяца и дня
export function compareMonthAndDay(date, dateFrom, dateTo) {
  if (date >= dateFrom && date <= dateTo) {
    return true;
  } else {
    return false;
  }
}

// Подготовка данных для диаграмм, где текущий период, предыдущий и тренд
export const prepareDataDiagram = (elem, fields, trend) => ({
  checkOnePress: !isNil(prop(fields, prop("current", elem))) && prop(fields, prop("current", elem)),
  checkTwoPress: !isNil(prop(fields, prop("prev", elem))) && prop(fields, prop("prev", elem)),
  date: `${new Date(prop("currentDate", elem)).getDate()} ${new Date(
    prop("currentDate", elem)
  ).toLocaleString("ru-RU", { month: "short" })}`,
  dateTick: getAxisDate(elem.currentDate),
  proceeds_trend: prop(trend, prop("current", elem)),
  // zeroAxis: 0,
  eventName: "",
  // fakeYAxis: 0,
});

// Поля диаграмм
export const propFields = {
  //---Ключевые показатели---
  proceeds: "proceeds",
  proceeds_trend: "proceeds_trend",
  realization_quantity: "realization_quantity",
  realization_quantity_trend: "realization_quantity_trend",
  cancel_share: "cancel_share",
  cancel_share_trend: "cancel_share_trend",
  return_share: "return_share",
  return_share_trend: "return_share_trend",
  wb_service_share: "wb_service_share",
  wb_service_share_trend: "wb_service_share_trend",
  //---Экономика торговли---
  gross_margin: "gross_margin",
  gross_margin_to_realization_value_share: "gross_margin_to_realization_value_share",
  gross_margin_to_cost_share: "gross_margin_to_cost_share",
  //---Капитализация стока---
  stocks_value_by_cost: "stocks_value_by_cost",
  stocks_value_by_cost_trend: "stocks_value_by_cost_trend",
  stocks_value_by_price: "stocks_value_by_price",
  stocks_value_by_price_trend: "stocks_value_by_price_trend",
  stocks_value_diff: "stocks_value_diff",
  stocks_value_diff_trend: "stocks_value_diff_trend",
  //---Комиссии и скидки ---
  spp_prc_discount: "spp_prc_discount",
  kvw_prc_commission: "kvw_prc_commission",
  //---Возвраты и покатушки ---
  unsold_products_delivery: "unsold_products_delivery",
  ride_share: "ride_share",
  //---Хранение и приёмка ---
  volume: "volume",
  warehouse_price: "warehouse_price",
  barcodes_count: "barcodes_count",
  //---Продвижение и медиа---
  cpm: "cpm",
  ctr: "ctr",
  cpc: "cpc",
  crm: "crm",
  sum: "sum",
  //
  gross_margin_to_product_cost: "gross_margin_to_product_cost",
  gross_margin_to_realization: "gross_margin_to_realization",
};
