import request from "root/js/request";

export const getAnalyticsProductCostV2 = async ({ cabinet_id__in, ...rest }) => {
  const response = await request({
    url: `analytics/product_cost_v2/`,
    method: "get",
    params: {
      cabinet_id__in,
      ...rest,
    },
  });
  return response.data;
};
