import React from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import cn from "classnames";
import { useSelector } from "react-redux";

import LinearLoading from "root/components/LinearLoading";
import Switch_ from "root/components/Switch";
import { TIME, WEEK } from "root/pages/financial-logic/constants";
import { getCellColor, getMinMaxValues } from "root/pages/financial-logic/helpers";

import styles from "./index.module.scss";

const PromotionMediaHeatMap = ({ filterByAdvertStat, setFilterByAdvertStat, isLoadingMap }) => {
  const { heatMapAsTable, isLoadingHeatMapAsTable } = useSelector(state => state.financeLogic);
  const minMaxValues = getMinMaxValues(heatMapAsTable);

  return (
    <Box className={styles.wrap}>
      <div className={styles.header}>
        <h4 className={styles.title}>
          Среднее количество заказов в день в заданном периоде в разрезе дней недели и времени суток
        </h4>
        <div className={styles.settingsBlock}>
          <p className={styles.text}>Выбранные товары</p>
          <Switch_
            onChange={() => setFilterByAdvertStat(prev => !prev)}
            checked={filterByAdvertStat}
            className={styles.switch}
          />
          <p className={styles.text}>Продвигаемые товары</p>
        </div>
      </div>

      {isLoadingHeatMapAsTable || isLoadingMap ? (
        <div className={styles.boxLoader}>
          <LinearLoading className={styles.loader} />
        </div>
      ) : (
        <div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={cn(styles.cell, styles.cellweek)}></TableCell>
                  <TableCell className={styles.cell}>Всего</TableCell>
                  {TIME.map((el, ind) => (
                    <TableCell key={ind} className={styles.cell}>
                      {el}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {heatMapAsTable?.map((el, index) => (
                  <TableRow key={el.day_of_week}>
                    <TableCell className={cn(styles.cell, styles.cellweek)}>
                      {WEEK.find(d => d.id === el.day_of_week).label}
                    </TableCell>
                    <TableCell
                      className={cn(
                        styles.cell,
                        { [styles.commonSum]: index === 7, [styles.borderBottom]: index === 6 },
                        styles.borderRight
                      )}
                      style={{
                        backgroundColor: getCellColor(
                          el.sum,
                          minMaxValues.byHours.min,
                          minMaxValues.byHours.max,
                          minMaxValues.byHours.mid
                        ),
                      }}>
                      {el.sum}
                    </TableCell>
                    {el.by_hours.map((h, idx) => (
                      <TableCell
                        key={`${el.day_of_week}_${idx}`}
                        className={cn(styles.cell, { [styles.borderBottom]: index === 6 })}
                        style={
                          index === 7
                            ? {
                              backgroundColor: getCellColor(
                                h,
                                minMaxValues.common.min,
                                minMaxValues.common.max,
                                minMaxValues.common.mid
                              ),
                            }
                            : {
                              backgroundColor: getCellColor(
                                h,
                                minMaxValues.data.min,
                                minMaxValues.data.max,
                                minMaxValues.data.mid
                              ),
                            }
                        }>
                        {h !== null ? h.toFixed(1) : "0.0"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

      )}
    </Box>
  );
};

export default PromotionMediaHeatMap;
