import React, { Suspense, useEffect, useState } from "react";

import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import cn from "classnames";
import { prop } from "ramda";
import { batch, useDispatch, useSelector } from "react-redux";
import { REQUESTS_DEBOUNCE_TIME } from "root/js/constants";
import { PenaltyTypes } from "root/store/financialLogic/action";
import { useDebouncedCallback } from "use-debounce";

import API from "root/js/api";

import CustomPieChart from "root/components/CustomPieChart/CustomPieChart";
import CardTwoProcents from "root/components/global/cards/card-two-procents";
import TabSelect from "root/components/global/page-tab/nav-bar-select";
import LinearLoading from "root/components/LinearLoading";
import TabPanel from "root/components/TabPanel";

import useNavigationMemory from "root/hooks/useNavigationMemory";

import { getLastWeek } from "../../../helpers";
import { penaltyTables, penaltyTabsSum } from "../../../info";
import PenaltyCheckbox from "../pages/penalty-checkbox";
import PenaltyDiagramLine from "../pages/penalty-diagram-line";
import PenaltyDiagramProduct from "../pages/penalty-diagram-product";
import PenaltyDynamic from "../pages/penalty-dynamic";

import styles from "./index.module.scss";

const PenaltiesSurcharges = ({ setIsDisabledWeek }) => {
  const {
    currencyName,
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    subjectsId,
    dateFrom,
    dateTo,
    penaltyWidgets,
    isLoadingPenaltyWidgets,
    isLoadingPenaltyCicrle,
    isLoadingPenaltyDiagramProducts,
    penaltyDiagramLine,
    isLoadingPenaltyDynamic,
    isLoadingPenaltyTypes,
    isLoadingSurchargesWidgets,
    surchargesWigets,
    penaltyCicrle,
  } = useSelector(state => state.financeLogic);

  const [tab, setTab] = useState(0);
  const [table, setTable] = useState(4);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [isLoadingOffices, setIsLoadingOffices] = useState(true);
  const [isLoadingDiagramLine, setIsLoadingDiagramLine] = useState(true);
  const [isLoadingAdditionalsWidgets, setIsLoadingAdditionalsWidgets] = useState(true);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(2);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };
  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  const value = tab === 0 ? "penalty" : "penalty_quantity";
  const dispatch = useDispatch();
  const { changeParams } = useNavigationMemory();
  const lastWeek = getLastWeek();
  const WBAPI = new API();

  useEffect(() => {
    changeParams("table", table);
  }, [table]);

  const onChangeTab = tab => {
    setTab(tab);
  };
  const onChageTable = table => {
    setTable(table);
  };

  const checkWeek = async () => {
    try {
      const res = await WBAPI.penaltyTable({
        key: keys.join(),
        value: "category_id",
        dateFrom: lastWeek.dateFrom,
        dateTo: lastWeek.dateTo,
      });
      res?.count > 0 ? setIsDisabledWeek?.(false) : setIsDisabledWeek?.(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (keys?.length > 0) checkWeek();
  }, [keys, window.location.pathname]);

  const fetchDataDebounce = useDebouncedCallback(async () => {
    dispatch(
      PenaltyTypes({
        keys,
        dateFrom,
        dateTo,
        categoryId,
        brandIds,
        articulIds,
        barcodeIds,
        subjectsId,
        value,
      })
    );
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (!penaltyWidgets && keys.length > 0 && dateFrom) {
      setIsLoadingProduct(true);
      setIsLoadingOffices(true);
      setIsLoadingDiagramLine(true);
      setIsLoadingAdditionalsWidgets(true);
      fetchDataDebounce.callback();
    }
  }, [keys, dateFrom, categoryId, brandIds, articulIds, barcodeIds, subjectsId]);

  return (
    <div className={styles.penaltyContainer}>
      <div className={styles.content}>
        {isLoadingPenaltyTypes || !penaltyDiagramLine ? (
          <div className={cn(styles.box0, styles.boxLoader, styles.box0Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box0}>
            <PenaltyCheckbox
              value={value}
              setIsLoadingProduct={setIsLoadingProduct}
              setIsLoadingOffices={setIsLoadingOffices}
              setIsLoadingDiagramLine={setIsLoadingDiagramLine}
              setIsLoadingAdditionalsWidgets={setIsLoadingAdditionalsWidgets}
            />
          </div>
        )}

        <div className={styles.box1}>
          {isMobile && (
            <IconButton onClick={prev} className={styles.controlBtn} sx={{ left: "-11px" }}>
              <ArrowLeftIcon />
            </IconButton>
          )}
          <div
            className={styles.widgetWrapper}
            style={{ transform: `translateX(-${currentIndex * 104}%)` }}>
            {isLoadingPenaltyWidgets || !penaltyWidgets ? (
              <div className={cn(styles.boxLoader, styles.box1Size)}>
                <LinearLoading className={styles.loader} />
              </div>
            ) : (
              <div className={styles.widgetBox}>
                <CardTwoProcents
                  style={{ borderRadius: "10px" }}
                  title="Штрафы и компенсации"
                  valueTitle="Сумма"
                  valueValuet={currencyName}
                  transformRotateIncrement="rotate(-90deg)"
                  transformRotateDicrement="rotate(-268deg)"
                  borderIncrement="4px solid red"
                  borderDicrement="4px solid #00C85A"
                  colorIncrement="red"
                  colorDicrement="#00C85A"
                  value={prop("penalty", prop("current", penaltyWidgets)) || 0}
                  resultPrecent={
                    (prop("penalty", prop("current", penaltyWidgets)) -
                      prop("penalty", prop("prev", penaltyWidgets))) /
                      prop("penalty", prop("prev", penaltyWidgets)) || 0
                  }
                  secondValueTitle="Количество"
                  secondValueValuet="шт"
                  secondValue={prop("penalty_quantity", prop("current", penaltyWidgets)) || 0}
                  secondResultPrecent={
                    (prop("penalty_quantity", prop("current", penaltyWidgets)) -
                      prop("penalty_quantity", prop("prev", penaltyWidgets))) /
                      prop("penalty_quantity", prop("prev", penaltyWidgets)) || 0
                  }
                />
              </div>
            )}

            {isLoadingSurchargesWidgets || !penaltyWidgets || isLoadingAdditionalsWidgets ? (
              <div className={cn(styles.boxLoader, styles.box2Size)}>
                <LinearLoading className={styles.loader} />
              </div>
            ) : (
              <div className={styles.widgetBox}>
                <CardTwoProcents
                  style={{ borderRadius: "10px" }}
                  title="Доплаты"
                  valueTitle="Сумма"
                  valueValuet={currencyName}
                  borderIncrement="4px solid #00C85A"
                  borderDicrement="4px solid red"
                  transformRotateIncrement="rotate(268deg)"
                  transformRotateDicrement="rotate(90deg)"
                  value={prop("additional_payment", prop("current", surchargesWigets)) || 0}
                  resultPrecent={
                    (prop("additional_payment", prop("current", surchargesWigets)) -
                      prop("additional_payment", prop("prev", surchargesWigets))) /
                      prop("additional_payment", prop("prev", surchargesWigets)) || 0
                  }
                  colorIncrement="#00C85A"
                  colorDicrement="red"
                  secondValueTitle="Количество"
                  secondValueValuet="шт"
                  secondValue={
                    prop("additional_payment_quantity", prop("current", surchargesWigets)) || 0
                  }
                  secondResultPrecent={
                    (prop("additional_payment_quantity", prop("current", surchargesWigets)) -
                      prop("additional_payment_quantity", prop("prev", surchargesWigets))) /
                      prop("additional_payment_quantity", prop("prev", surchargesWigets)) || 0
                  }
                />
              </div>
            )}
          </div>
          {isMobile && (
            <IconButton onClick={next} className={styles.controlBtn} sx={{ right: "-11px" }}>
              <ArrowLeftIcon sx={{ transform: "rotate(180deg)" }} />
            </IconButton>
          )}
        </div>

        {isLoadingPenaltyWidgets || !penaltyWidgets ? (
          <div className={cn(styles.box5, styles.boxLoader, styles.box5Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box5}>
            <TabSelect data={penaltyTabsSum} onChange={onChangeTab} tab={tab} />
          </div>
        )}

        {isLoadingPenaltyCicrle || isLoadingOffices ? (
          <div className={cn(styles.box6, styles.boxLoader, styles.box6Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <>
            <div className={styles.box6}>
              <CustomPieChart
                tab={value}
                colors={[
                  "#4169E1",
                  "#FF8C00",
                  "#D3D3D3",
                  "#FFD700",
                  "#00008B",
                  "#8B008B",
                  "rgb(0, 200, 90)",
                ]}
                precentInHint={true}
                data={penaltyCicrle}
                currencyName={currencyName}
                title={
                  value === "penalty"
                    ? "Сумма штрафов и компенсаций в разрезе складов"
                    : "Количество штрафов и компенсаций в разрезе складов"
                }
              />
            </div>
          </>
        )}

        {(isLoadingPenaltyDiagramProducts && penaltyDiagramLine?.length !== 0) ||
        isLoadingProduct ? (
          <div className={cn(styles.box7, styles.boxLoader, styles.box7Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box7}>
            <PenaltyDiagramProduct tab={value} />
          </div>
        )}

        {isLoadingPenaltyDynamic || isLoadingDiagramLine ? (
          <div className={cn(styles.box8, styles.boxLoader, styles.box8Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box8}>
            <PenaltyDynamic tab={value} />
          </div>
        )}
      </div>

      {isLoadingPenaltyTypes || !penaltyDiagramLine || isLoadingDiagramLine ? (
        <div className={cn(styles.box3, styles.boxLoader, styles.box3Size)}>
          <LinearLoading className={styles.loader} />
        </div>
      ) : (
        <div className={styles.box3}>
          <PenaltyDiagramLine tab={value} />
        </div>
      )}

      <Paper elevation={0} className={styles.tableWrap}>
        {penaltyTables.map((item, index) => (
          <Suspense key={item.keyIndex} fallback={<></>}>
            <TabPanel value={table} index={index}>
              <item.component onTabChange={onChageTable} tabValue={table} />
            </TabPanel>
          </Suspense>
        ))}
      </Paper>

      {!isLoadingPenaltyCicrle &&
        !isLoadingPenaltyDiagramProducts &&
        !isLoadingPenaltyWidgets &&
        penaltyDiagramLine?.length === 0 && (
          <div className={styles.noDataMessage}>
            <div className={styles.box}>
              <div className={styles.text}>
                Для заданных параметров фильтрации штрафы и доплаты отсутствуют
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default PenaltiesSurcharges;
