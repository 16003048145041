import request from "root/js/request";

export const getCabinetAdvertSettings = async ({ cabinet_id }) => {
  const response = await request({
    url: `/analytics/cabinet_advert_settings/`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response;
};

export const postCabinetAdvertSettings = async ({ cabinet_id, autotopup_source }) => {
  const response = await request({
    url: `/analytics/cabinet_advert_settings/`,
    method: "post",
    params: {
      cabinet_id,
    },
    data: {
      autotopup_source,
    },
  });
  return response;
};
