import request from "root/js/request";

export const postCompanyV2PurchaseCalc = async ({ plan, period, promo_code, ...rest }) => {
  const response = await request({
    url: "/company/v2/purchase_calc/",
    method: "post",
    data: {
      plan,
      period,
      promo_code,
    },
  });
  return response.data;
};
