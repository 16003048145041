import request from "root/js/request";

//
export const getTariffPlans = async ({ promo_code, ...rest }) => {
  const response = await request({
    url: "/tariff/plans/",
    method: "get",
    params: {
      promo_code,
      ...rest,
    },
  });

  return response.data;
};
