import React, { useState } from "react";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { Box, Typography } from "@mui/material";
import { trim } from "ramda";

import { CheckModal } from "root/pages/connections/components/connection-card";

import officialPartnerLogo from "root/img/logo/official_wb_partner.png";

import Button from "../Button";
import TextField_ from "../TextField";
import VideoDialog from "../VideoDialog";

import CallbackOrders from "./CallbackOrders";

import styles from "./index.module.scss";

const StartDemoOptions = ({
  onSubmitToken,
  fulfilledCallback,
  apiToken,
  setApiToken,
  isTokenLoading
}) => {
  const [isStartWithWildberriesBoxOpen, setIsStartWithWildberriesBoxOpen] = useState(true);
  const [isStartWithTokenBoxOpen, setIsStartWithTokenBoxOpen] = useState(false);
  const [isStartWithCallbackBoxOpen, setIsStartWithCallbackBoxOpen] = useState(false);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);

  const toggleStartWithTokenBoxOpen = () => {
    setIsStartWithTokenBoxOpen(prev => !prev);
    setIsStartWithCallbackBoxOpen(false);
    setIsStartWithWildberriesBoxOpen(false)
  };
  const toggleStartWithCallbackBoxOpen = () => {
    setIsStartWithCallbackBoxOpen(prev => !prev);
    setIsStartWithTokenBoxOpen(false);
    setIsStartWithWildberriesBoxOpen(false)
  };
  const toggleStartWithWildberriesBoxOpen = () => {
    setIsStartWithWildberriesBoxOpen(prev => !prev);
    setIsStartWithTokenBoxOpen(false);
    setIsStartWithCallbackBoxOpen(false)
  };
  const onCloseCheckModal = () => setIsCheckModalOpen(false)

  return (
    <Box display={"grid"} gap={1}>
      <CheckModal
        {...{
          open: isCheckModalOpen,
          onClose: onCloseCheckModal,
          is_demo: true
        }}
      />
      <ContentWithExpandArrow
        isOpen={isStartWithWildberriesBoxOpen}
        toggleOpen={toggleStartWithWildberriesBoxOpen}
        label={<b>Подключить кабинет Wildberries</b>}>
        <Box py={1}>
          <Box mt={2}>
            <Box mb={2}>
              <Box sx={{ float: "left" }} m={0.5} mr={2}>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={officialPartnerLogo}
                  alt="officialPartnerLogo"
                />
              </Box>
              <Typography>
                Нам нужно убедиться, что вы реальный человек, а не бот. Чтобы получить демодоступ, нажмите на кнопку «Подключить кабинет WB» и следуйте дальнейшим инструкциям.
              </Typography>
            </Box>
            <Typography variant="subtitle2">ㅤ</Typography>
            <Box display={"flex"} gap={1} mb={2}>
              <Box flex={1}>
                <Button
                  variant="filled"
                  // isLoading={isTokenLoading}
                  style={{ width: "100%" }}
                  onClick={() => setIsCheckModalOpen(true)}>
                  Подключить кабинет WB
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentWithExpandArrow>
      <ContentWithExpandArrow
        isOpen={isStartWithTokenBoxOpen}
        toggleOpen={toggleStartWithTokenBoxOpen}
        label={
          <>
            <b>Загрузить API-токен</b>
            {` (подтверждение < 1 минуты)`}
          </>
        }>
        <Box py={1}>
          <Box mt={2}>
            <Box mb={2}>
              <Box sx={{ float: "left" }} m={0.5} mr={2}>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={officialPartnerLogo}
                  alt="officialPartnerLogo"
                />
              </Box>
              <Typography>
                Нам нужно убедиться, что вы реальный человек, а не бот. Чтобы получить демодоступ,
                укажите ваш токен из личного кабинета поставщика с типом «Статистика» или закажите
                звонок менеджера.
              </Typography>
              <Box mt={2}>
                <Typography>
                  Если вы не знаете как получить API-токен, мы подготовили для вас подробную{" "}
                  <VideoDialog
                    videoUrl={"https://player.vimeo.com/video/1016116589"}
                    title={"Инструкция по активации демодоступа Wildbox"}
                    showYouTubeIcon={false}
                    buttonTitle={"видеоинструкцию"}
                  />
                  .
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle2">
              Загрузите свой API-токен статистики на чтение.
            </Typography>
            <Box display={"flex"} gap={1} mb={2}>
              <Box flex={3} position={"relative"}>
                <TextField_
                  placeholder="Введите API-токен"
                  value={apiToken}
                  onChange={e => setApiToken(e.target.value)}
                />
              </Box>
              <Box flex={1}>
                <Button
                  variant="filled"
                  disabled={trim(apiToken) == ""}
                  isLoading={isTokenLoading}
                  style={{ width: "100%" }}
                  onClick={onSubmitToken}>
                  Получить доступ
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentWithExpandArrow>
      <ContentWithExpandArrow
        isOpen={isStartWithCallbackBoxOpen}
        toggleOpen={toggleStartWithCallbackBoxOpen}
        label={
          <>
            <b>Заказать звонок менеджера</b> (подтверждение до 24 часов)
          </>
        }>
        <Box mt={2}>
          <Typography variant="subtitle2">
            Менеджер свяжется с вами в течение 24 часов, с 10:00 до 19:00 по МСК
          </Typography>
          <CallbackOrders fulfilledFunc={fulfilledCallback} />
        </Box>
      </ContentWithExpandArrow>
    </Box>
  );
};

// ------------------------------------------------
const ContentWithExpandArrow = ({ isOpen, toggleOpen, label, children }) => {
  return (
    <Box>
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        onClick={toggleOpen}
        className={isOpen ? styles.expandArrowOpenedBox : ""}
        sx={{ cursor: "pointer" }}>
        <ExpandCircleDownIcon className={styles.expandArrow} />
        <Typography>{label}</Typography>
      </Box>
      {isOpen && children}
    </Box>
  );
};

export default StartDemoOptions;
