import React, { Suspense, useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import { batch, useDispatch, useSelector } from "react-redux";
import { REQUESTS_DEBOUNCE_TIME } from "root/js/constants";
import {
  GetKeyIndicatorsWidgets,
  KeyIndicatorsDynamic,
  KeyIndicatorsServiceStructrueWB,
} from "root/store/financialLogic/action";
import { useDebouncedCallback } from "use-debounce";

import API from "root/js/api";

import CustomPieChart from "root/components/CustomPieChart/CustomPieChart";
import LinearLoading from "root/components/LinearLoading";
import TabPanel from "root/components/TabPanel";

import useNavigationMemory from "root/hooks/useNavigationMemory";

import { getLastWeek } from "../../../helpers";
import { tables } from "../../../info";
import CardIndicators from "../keyIndicatorsPages/card-widgets";
import KeyIndicatorsDiagram from "../keyIndicatorsPages/diagram";
import { renderDataForPieChart } from "../keyIndicatorsPages/helpers";

import styles from "./index.module.scss";

const colors = ["#00C85A", "#0000FF", "#FFA500", "#d3d3d3", "#D44CA9", "#9400D3", "#ffd700"];

const colorsExtra = [
  "#00C85A",
  "#00dcff",
  "#0000FF",
  "#FFA500",
  "#d3d3d3",
  "#D44CA9",
  "#9400D3",
  "#ffd700",
];

const KeyIndicators = ({ setIsDisabledWeek }) => {
  const {
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    subjectsId,
    dateFrom,
    dateTo,
    keyIndicatorsWidgets,
    isLoadingKeyIndicatorsWidgets,
    keyServiceStructureCircle,
    currencyName,
  } = useSelector(state => state.financeLogic);

  const [table, setTable] = useState(4);
  const { changeParams } = useNavigationMemory();
  const dispatch = useDispatch();
  const lastWeek = getLastWeek();
  const WBAPI = new API();

  const onChageTable = table => {
    setTable(table);
  };

  const checkWeek = async () => {
    try {
      const res = await WBAPI.keyIndicatorsTable({
        key: keys.join(),
        value: "category_id",
        dateFrom: lastWeek.dateFrom,
        dateTo: lastWeek.dateTo,
      });
      res?.count > 0 ? setIsDisabledWeek?.(false) : setIsDisabledWeek?.(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    changeParams("table", table);
  }, [table]);

  useEffect(() => {
    if (keys?.length > 0) checkWeek();
  }, [keys, window.location.pathname]);

  const fetchDataDebounce = useDebouncedCallback(async () => {
    batch(() => {
      dispatch(
        GetKeyIndicatorsWidgets({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
        })
      );
      dispatch(
        KeyIndicatorsDynamic({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
        })
      );
      dispatch(
        KeyIndicatorsServiceStructrueWB({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
        })
      );
    });
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0 && dateFrom && dateTo) {
      fetchDataDebounce.callback();
    }
  }, [keys, dateFrom, categoryId, brandIds, articulIds, barcodeIds, subjectsId]);

  const dataPieChart = renderDataForPieChart(keyServiceStructureCircle, dateTo);

  return (
    <div className={styles.indicatorsContainer}>
      {/* Блок виджетов */}
      {isLoadingKeyIndicatorsWidgets || !keyIndicatorsWidgets ? (
        <div className={styles.widgetBox}>
          <LinearLoading className={styles.loader} />
        </div>
      ) : (
        <CardIndicators />
      )}

      <div className={styles.boxDiagramaContainer}>
        {/* Блок диаграм */}
        {isLoadingKeyIndicatorsWidgets || !keyIndicatorsWidgets ? (
          <div className={styles.box}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "10px",
            }}>
            <KeyIndicatorsDiagram />
          </Paper>
        )}

        {/* Блок круговой диаграммы */}
        {isLoadingKeyIndicatorsWidgets || !keyIndicatorsWidgets ? (
          <div className={styles.box}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <>
            <CustomPieChart
              data={dataPieChart.data}
              title={"Структура затрат на WB"}
              titleTrips={`Структура затрат графически представлена в виде "пирога", в случае отрицательных значений комиссии тип графика перестраивается в горизонтальные столбики`}
              isNegativeVariant={true}
              precentInHint={true}
              currencyName={currencyName}
              tab={"none"}
              colors={dataPieChart.checkMonth ? colorsExtra : colors}
            />
          </>
        )}
      </div>

      <div className={styles.tableContainer}>
        {/* Таблица */}
        <Paper elevation={0} sx={{ padding: "20px" }}>
          {tables.map((item, index) => (
            <Suspense key={item.keyIndex} fallback={<></>}>
              <TabPanel value={table} index={index}>
                <item.component onTabChange={onChageTable} tabValue={table} />
              </TabPanel>
            </Suspense>
          ))}
        </Paper>
      </div>
    </div>
  );
};

export default KeyIndicators;
