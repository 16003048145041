import React, { useEffect, useState } from "react";

import cn from "classnames";
import { batch, useDispatch, useSelector } from "react-redux";
import { REQUESTS_DEBOUNCE_TIME } from "root/js/constants";
import {
  PaidDiagram,
  PaidOfficesCircle,
  PaidProducts,
  PaidStorageCircles,
  PaidWidget,
} from "root/store/financialLogic/action";
import { useDebouncedCallback } from "use-debounce";

import API from "root/js/api";

import CustomPieChart from "root/components/CustomPieChart/CustomPieChart";
import TabSelect from "root/components/global/page-tab/nav-bar-select";
import LinearLoading from "root/components/LinearLoading";
import { paidTabs } from "root/pages/financial-logic/info";

import { getLastWeek } from "../../../helpers";
import PaidDiagrams from "../components/paid-diagram";
import PaidProduct from "../components/paid-product";
import PaidCircleStorage from "../components/paid-storage-circle";
import PaidTables from "../components/paid-tables/app";
import PaidWidgets from "../components/paid-widgets";

import styles from "./index.module.scss";

const PaidAcceptance = ({ setIsDisabledWeek }) => {
  const [tab, setTab] = useState(0);
  const {
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    dateFrom,
    dateTo,
    subjectsId,
    isLoadingPaidWigets,
    isLoadingPaidOfficesCircles,
    isLoadingPaidStorageCircle,
    isLoadingPaidProduct,
    isLoadingPaidDiagram,
    paidDiagram,
    paidProducts,
    paidStorageCircle,
    paidOfficesCircles,
    paidWigets,
    currencyName,
  } = useSelector(state => state.financeLogic);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);

  const lastWeek = getLastWeek();
  const WBAPI = new API();

  const dispatch = useDispatch();

  const onChangeTab = tab => {
    setTab(tab);
  };

  const fetchProductDebounce = useDebouncedCallback(async () => {
    dispatch(
      PaidProducts({
        keys,
        categoryId,
        brandIds,
        value,
        dateFrom,
        dateTo,
        subjectsId,
      })
    );
    setIsLoadingProduct(false);
  }, REQUESTS_DEBOUNCE_TIME);

  const value = tab === 0 ? "warehouse_price_value" : "barcodes_count";
  useEffect(() => {
    if (keys.length > 0) {
      fetchProductDebounce.callback();
    }
  }, [keys, categoryId.join(""), brandIds.join(""), dateFrom, subjectsId.join(""), value]);

  const checkWeek = async () => {
    try {
      const res = await WBAPI.paidTable({
        key: keys.join(),
        dateFrom: lastWeek.dateFrom,
        dateTo: lastWeek.dateTo,
        group_by: "supplier_article",
      });
      res?.count > 0 ? setIsDisabledWeek?.(false) : setIsDisabledWeek?.(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (keys?.length > 0) checkWeek();
  }, [keys, window.location.pathname]);

  const fetchDataDebounce = useDebouncedCallback(async () => {
    batch(() => {
      dispatch(
        PaidDiagram({
          keys,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          dateFrom,
          dateTo,
          subjectsId,
        })
      );
      dispatch(
        PaidWidget({
          keys,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          dateFrom,
          dateTo,
          subjectsId,
        })
      );
      dispatch(
        PaidStorageCircles({
          keys,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          dateFrom,
          dateTo,
          subjectsId,
        })
      );
    });
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0) {
      fetchDataDebounce.callback();
    }
  }, [keys, categoryId, brandIds, articulIds, barcodeIds, dateFrom, subjectsId]);

  const fetchOfficesDebounce = useDebouncedCallback(async () => {
    dispatch(
      PaidOfficesCircle({
        keys,
        value,
        categoryId,
        brandIds,
        articulIds,
        barcodeIds,
        dateFrom,
        dateTo,
        subjectsId,
      })
    );
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0) {
      fetchOfficesDebounce.callback();
    }
  }, [keys, value, categoryId, brandIds, articulIds, barcodeIds, dateFrom, subjectsId]);

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.paidContainer}>
        {isLoadingPaidWigets || !paidWigets ? (
          <div className={cn(styles.box1, styles.loaderBox, styles.box1Size, styles.loaderFlex)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box1}>
            <PaidWidgets paidWigets={paidWigets} />
          </div>
        )}

        <div className={styles.box2}>
          <TabSelect data={paidTabs} onChange={onChangeTab} tab={tab} />
        </div>

        {isLoadingPaidOfficesCircles || !paidOfficesCircles ? (
          <div className={cn(styles.box3, styles.loaderBox, styles.box3Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <>
            <div className={styles.box3}>
              <CustomPieChart
                data={paidOfficesCircles}
                tab={value}
                colors={[
                  "#4169E1",
                  "#FF8C00",
                  "#D3D3D3",
                  "#FFD700",
                  "#00008B",
                  "#8B008B",
                  "rgb(0, 200, 90)",
                ]}
                title={
                  value === "warehouse_price_value"
                    ? "Стоимость хранения в разрезе складов"
                    : "Количество товара, подлежащего тарифицированию, в разрезе складов"
                }
                currencyName={currencyName}
              />
            </div>
          </>
        )}

        {isLoadingPaidStorageCircle || !paidStorageCircle ? (
          <div className={cn(styles.box4, styles.loaderBox, styles.box4Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box4}>
            <PaidCircleStorage paidStorageCircle={paidStorageCircle} tab={value} />
          </div>
        )}

        {isLoadingPaidProduct || !paidProducts || isLoadingProduct ? (
          <div className={cn(styles.box5, styles.loaderBox, styles.box5Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box5}>
            <PaidProduct paidProducts={paidProducts} tab={value} />
          </div>
        )}

        {isLoadingPaidDiagram || !paidDiagram ? (
          <div className={cn(styles.box6, styles.loaderBox, styles.box6Size)}>
            <LinearLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.box6}>
            <PaidDiagrams paidDiagram={paidDiagram} tab={value} />
          </div>
        )}
      </div>

      <div className={styles.box7}>
        <PaidTables />
      </div>

      {!isLoadingPaidWigets &&
        !isLoadingPaidOfficesCircles &&
        !isLoadingPaidStorageCircle &&
        !isLoadingPaidProduct &&
        !isLoadingPaidDiagram &&
        paidDiagram?.every(el => !el.volume && !el.warehouse_price) &&
        paidStorageCircle?.length === 0 &&
        paidOfficesCircles?.length === 0 && (
          <div className={styles.noDataMessage}>
            <div className={styles.box}>
              <div className={styles.text}>
                Для заданных параметров фильтрации затраты на хранение отсутствуют
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default PaidAcceptance;
