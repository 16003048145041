import { useMemo } from "react";

import { queryAPI } from "root/api/queryAPI";
import { getIsDataNotLoaded } from "root/utils/api/errorHandlers";

// -----------------------------------------------------
const useQueryAPI = ({ apiFuncName = "", func = () => {}, params }) => {
  // Этот хук некорректно работает в прод версии проекта, не стоит его испльзовать
  // до решения этой проблемы. Скорее всего дело в версиях библиотек
  const queryFunctionName = useMemo(() => {
    const funcName = apiFuncName || func.name;
    return `use${funcName.slice(0, 1).toUpperCase() + funcName.slice(1)}Query`;
  }, [apiFuncName, func]);

  const {
    data,
    isLoading: isL,
    isFetching: isF,
    error,
    ...rest
  } = (queryAPI[queryFunctionName] || (() => ({})))(params);
  const isLoading = useMemo(() => isL || isF, [isL, isF]);
  const isDataNotLoaded = useMemo(() => getIsDataNotLoaded(error), [error]);
  return {
    data,
    isLoading,
    isFirstLoading: isF,
    isReloading: isL,
    error,
    isDataNotLoaded,
    ...rest,
  };
};

export default useQueryAPI;
