import { createSlice } from "@reduxjs/toolkit"
import { difference, uniq } from "ramda"

const initialState = {
	page: 1,
	ids: {
		1: []
	},
	allIds: [],
	checkBoxSelect: {
		1: {
			all: false,
			checkeds: []
		}
	}
}

const productOptimizationSlice = createSlice({
	name: "productOptimization",
	initialState,
	reducers: {
		clearState(state) {
			state.page = 1
			state.ids = {
				1: []
			}
			state.allIds = []
			state.checkBoxSelect = {
				1: {
					all: false,
					checkeds: []
				}
			}
		},
		handleData(state, { payload }) {
			state.page = payload.page
			state.ids[payload.page] = payload.ids
			if (!state.checkBoxSelect?.[payload.page]) {
				state.checkBoxSelect = {
					...state.checkBoxSelect,
					[payload.page]: {
						all: false,
						checkeds: []
					}
				}
			}

			if (!state.ids?.[payload.page]) {
				state.ids = {
					...state.ids,
					[payload.page]: []
				}
			}
		},
		handleCheckBoxSelect(state, { payload }) {
			if (payload.checked) {
				state.allIds = [...state.allIds, payload.value]
				state.checkBoxSelect[state.page].checkeds = [
					...state.checkBoxSelect[state.page].checkeds,
					payload.value
				]
				state.checkBoxSelect[state.page].all = false
			} else {
				state.allIds = state.allIds.filter(item => item !== payload.value)
				state.checkBoxSelect[state.page].checkeds = state.checkBoxSelect[state.page].checkeds.filter(item => item !== payload.value)
				state.checkBoxSelect[state.page].all = false
			}

		},
		handleCheckBoxSelectAll(state, { payload }) {
			state.checkBoxSelect[state.page].all = payload.checked
			if (payload.checked) {
				state.checkBoxSelect[state.page].checkeds = payload.value
				state.allIds = uniq([...state.allIds, ...payload.value])
			} else {
				state.checkBoxSelect[state.page].checkeds = []
				state.allIds = difference(state.allIds, payload.value)
			}
		}
	},
})

export const {
	handleData,
	handleCheckBoxSelect,
	handleCheckBoxSelectAll,
	clearState
} = productOptimizationSlice.actions

export default productOptimizationSlice.reducer
