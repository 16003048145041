import { createSlice } from "@reduxjs/toolkit";
import { clone } from "ramda";

const loadStatus = {
  success: "success",
  error: "error",
  pending: "pending",
};
const initialState = {
  top100productsIds: [],
  productsLoadStatus: loadStatus.success,
  overviewData: {
    top100products: {
      monopoly: null,
      advTypeB: null,
      advTypeC: null,
      externalTraffic: null,
      newItemsCount: null,
      brandsCount: null,
      sellersCount: null,
      proceeds: null,
      orders: null,
      lostProceeds: null,
      proceedsPerPosition: null,
      middlePrice: null,
      smallestPrice: null,
      biggestPrice: null,
      weightedPrice: null,
    },
    topFiveBrands: [],
    topArticles: [],
  },
  productsTab: {
    data: [],
    isAllChecked: false,
    checkedIds: [],
  },
  brandsTab: {
    data: [],
    isAllChecked: false,
    checkedIds: [],
  },
  sellersTab: {
    data: [],
    isAllChecked: false,
    checkedIds: [],
  },
  categoriesTab: {
    data: [],
    isAllChecked: false,
    checkedIds: [],
  },
};

const searchTopsAnalysisSlice = createSlice({
  name: "searchTopsAnalysis",
  initialState,
  reducers: {
    setOverviewProductsData(state, { payload: newData }) {
      // console.log("newData", newData);

      const length = newData.length;

      let top10Proceeds = 0;
      let allProceeds = 0;
      let newItemsCount = 0;
      let allOrders = 0;
      let allLostProceeds = 0;
      let summaryPrice = 0;
      let weightedPrice = 0;
      let smallestPrice = newData[0].price;
      let biggestPrice = newData[0].price;
      let externalTrafficCount = 0;
      newData.forEach((item, index) => {
        if (index < 10) top10Proceeds += item.proceeds;
        if (item.is_new) newItemsCount++;
        if (item.price < smallestPrice) smallestPrice = item.price;
        if (item.price > biggestPrice) biggestPrice = item.price;
        if (item.product_ads) externalTrafficCount++;
        allProceeds += item.proceeds;
        allOrders += item.orders;
        allLostProceeds += item.lost_proceeds;
        summaryPrice += item.price;
        weightedPrice += item.weighted_price;
      });
      console.log("weightedPrice", weightedPrice);
      const dataSortedByProceeds = clone(newData).sort((a, b) => b.proceeds - a.proceeds);
      const topArticles = [];
      if (dataSortedByProceeds.length > 0) topArticles.push(dataSortedByProceeds[0]);
      if (dataSortedByProceeds.length > 19) topArticles.push(dataSortedByProceeds[19]);
      if (dataSortedByProceeds.length > 49) topArticles.push(dataSortedByProceeds[49]);
      state.overviewData.topArticles = topArticles;
      state.overviewData.top100products.monopoly = Math.round((top10Proceeds / allProceeds) * 100);
      state.overviewData.top100products.externalTraffic = externalTrafficCount;
      state.overviewData.top100products.newItemsCount = newItemsCount;
      state.overviewData.top100products.proceeds = allProceeds;
      state.overviewData.top100products.orders = allOrders;
      state.overviewData.top100products.lostProceeds = allLostProceeds;
      state.overviewData.top100products.proceedsPerPosition = Math.round(allProceeds / length);
      state.overviewData.top100products.middlePrice = Math.round(summaryPrice / length);
      state.overviewData.top100products.smallestPrice = Math.round(smallestPrice);
      state.overviewData.top100products.biggestPrice = Math.round(biggestPrice);
      state.overviewData.top100products.weightedPrice = Math.round(weightedPrice / length);
    },
    setOverviewBrandsData(state, { payload: data = [] }) {
      state.overviewData.top100products.brandsCount = data.length;
      state.overviewData.topFiveBrands = data.slice(0, 10).map(item => ({
        id: item.id,
        name: item.name,
        proceeds: item.proceeds,
      }));
    },
    setOverviewSellersData(state, { payload: data = [] }) {
      state.overviewData.top100products.sellersCount = data.length;
    },
    setAdvTypesPercentage(state, { payload: { advTypeB, advTypeC } }) {
      state.overviewData.top100products.advTypeB = advTypeB;
      state.overviewData.top100products.advTypeC = advTypeC;
    },
    setProductsData(state, { payload: newData }) {
      state.productsTab.isAllChecked = false;
      state.productsTab.checkedIds = [];
      state.productsTab.data = newData;
    },
    setBrandsData(state, { payload: newData }) {
      state.brandsTab.data = newData;
    },
    setSellersData(state, { payload: newData }) {
      state.sellersTab.data = newData;
    },
    setCategoriesData(state, { payload: newData }) {
      state.categoriesTab.data = newData;
    },
    toggleCheckItem(state, { payload: { id, tabName } }) {
      if (state[tabName].checkedIds.includes(id))
        state[tabName].checkedIds = state[tabName].checkedIds.filter(i => i !== id);
      else state[tabName].checkedIds.push(id);
      state[tabName].isAllChecked = state[tabName].data.length === state[tabName].checkedIds.length;
    },
    toggleCheckAll(state, { payload: tabName }) {
      if (!state[tabName].isAllChecked)
        state[tabName].checkedIds = [...state[tabName].data.map(item => item.id)];
      else state[tabName].checkedIds = [];
      state[tabName].isAllChecked = !state[tabName].isAllChecked;
    },
  },
});
export const {
  setOverviewProductsData,
  setOverviewBrandsData,
  setOverviewSellersData,
  setAdvTypesPercentage,
  setProductsData,
  setBrandsData,
  setSellersData,
  setCategoriesData,
  toggleCheckItem,
  toggleCheckAll,
} = searchTopsAnalysisSlice.actions;
export default searchTopsAnalysisSlice.reducer;
