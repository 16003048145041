import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analyzeByTab: "realization_quantity",
  groupByTab: 3,
  abcLabels: "0.8:A,0.15:B,0.05:C",
  xyzLabels: "0.1:X,0.25:Y,inf:Z",
  abcFilterType: "realization_quantity",
  abcFilterTypeABC: "A,B,C",
  abcFilterTypeXYZ: "",
  abcCircleFilterApplied: false,
  abcCheckboxesDisabled: false,
  xyzCheckboxesDisabled: false,
  quantity: 40,
  revenue: 40,
  grossMargin: 20,
};

const abcXyzAnalyzeSlice = createSlice({
  name: "abcXyzAnalyze",
  initialState,
  reducers: {
    setAnalyzeByTab: (state, action) => {
      state.analyzeByTab = action.payload;
    },
    setGroupBy: (state, action) => {
      state.groupByTab = action.payload;
    },
    setABCLabels: (state, action) => {
      state.abcLabels = action.payload;
    },
    setXYZLabels: (state, action) => {
      state.xyzLabels = action.payload;
    },
    setAbcTableFilterType: (state, action) => {
      state.abcFilterType = action.payload.name;
    },
    setAbcTableFilterABC: (state, action) => {
      state.abcFilterTypeABC = action.payload.name;
    },
    setAbcTableFilterXYZ: (state, action) => {
      state.abcFilterTypeXYZ = action.payload.name;
    },
    setAbcCircleFilterApplied: (state, action) => {
      state.abcCircleFilterApplied = action.payload.name;
    },
    setAbcCheckboxesDisabled: (state, action) => {
      state.abcCheckboxesDisabled = action.payload.name;
    },
    setXyzCheckboxesDisabled: (state, action) => {
      state.xyzCheckboxesDisabled = action.payload.name;
    },
    syntheticAbc: (state, action) => {
      const { grossMarginValue, revenueValue, quantityValue } = action.payload;

      state.revenue = revenueValue;
      state.grossMargin = grossMarginValue;
      state.quantity = quantityValue;
    },
  },
});

export default abcXyzAnalyzeSlice.reducer;

export const {
  setAnalyzeByTab,
  setGroupBy,
  setABCLabels,
  setXYZLabels,
  setAbcTableFilterType,
  setAbcTableFilterABC,
  setAbcTableFilterXYZ,
  setAbcCircleFilterApplied,
  setAbcCheckboxesDisabled,
  setXyzCheckboxesDisabled,
  syntheticAbc,
} = abcXyzAnalyzeSlice.actions;
