import React from "react";

import { format } from "date-fns";
import { Columns } from "root/js/columns";

import { palette } from "root/js/utils";
//
const ColumnsBuilder = new Columns();
const externalInfluenceOptions = {
  none: "не выявлено ❌",
  low: "низкое 💩",
  middle: "среднее 🤔",
  high: "высокое 🚀",
};
// ---
const isMatterOuterTraffic = (dynamic = []) => {
  const period = dynamic.length;
  if (period < 28)
    return <span style={{ color: "grey", fontWeight: "bolder" }}>не выявлено ❌</span>;

  const by28daysArr = [];
  const lastItemIndex = period - (period % 28);
  const currentData = dynamic.slice(0, lastItemIndex);
  const monthCount = Math.floor(period / 28);
  for (let i = 0; i < monthCount; i++) {
    by28daysArr.push([
      [...currentData.slice(28 * i + 0, 28 * i + 7)],
      [...currentData.slice(28 * i + 7, 28 * i + 14)],
      [...currentData.slice(28 * i + 14, 28 * i + 21)],
      [...currentData.slice(28 * i + 21, 28 * i + 28)],
    ]);
  }

  let points = 0;
  let summaryPoints = 0;
  by28daysArr.forEach(monthItem => {
    const maxProceedsWeekData = {
      index: null,
      value: 0,
    };
    const maxFrequencyWeekData = {
      index: null,
      value: 0,
    };
    monthItem.forEach((weekItem, weekIndex) => {
      const weekProceeds = weekItem.reduce((acc, item) => acc + item.proceeds, 0);
      const weekFrequency = weekItem.reduce((acc, item) => acc + item.product_ads, 0);

      if (weekFrequency > 500 && weekFrequency > maxFrequencyWeekData.value) {
        maxFrequencyWeekData.index = weekIndex;
        maxFrequencyWeekData.value = weekFrequency;
      }
      if (weekProceeds > maxProceedsWeekData.value) {
        maxProceedsWeekData.index = weekIndex;
        maxProceedsWeekData.value = weekProceeds;
      }
    });
    if (!Number.isNaN(maxFrequencyWeekData.index)) {
      summaryPoints++;
      if (maxFrequencyWeekData.index === maxProceedsWeekData.index) points++;
    }
  });

  const coefficient = points / summaryPoints;

  if (coefficient < 0.1)
    return (
      <span style={{ color: "grey", fontWeight: "bolder" }}>{externalInfluenceOptions.none}</span>
    );
  if (coefficient < 0.3)
    return (
      <span style={{ color: "grey", fontWeight: "bolder" }}>{externalInfluenceOptions.low}</span>
    );
  if (coefficient < 0.5)
    return (
      <span style={{ color: "grey", fontWeight: "bolder" }}>{externalInfluenceOptions.middle}</span>
    );
  return (
    <span style={{ color: "#cf3aa0", fontWeight: "bolder" }}>{externalInfluenceOptions.high}</span>
  );
};

const productDrawerAxis = [
  {
    color: palette.blue,
    default: true,
    id: "proceeds",
    name: "Выручка, ₽",
    orientation: "left",
    yAxisId: "proceeds",
    afix: "₽",
  },
  {
    color: palette.purple,
    default: false,
    id: "orders",
    name: "Заказы, шт.",
    orientation: "right",
    yAxisId: "orders",
    afix: "шт",
  },
  {
    name: "Товарные остатки",
    id: "quantity",
    color: palette.lightGreen,
    orientation: "right",
    yAxisId: "proceeds",
    afix: " шт",
    default: false,
  },
  {
    color: palette.gamboge,
    default: true,
    id: "price",
    name: "Средняя цена, ₽",
    orientation: "right",
    yAxisId: "price",
    afix: "₽",
  },
  // {
  //   color: palette.blueGreen,
  //   default: true,
  //   id: "weighted_price",
  //   name: "Средний чек, ₽",
  //   orientation: "right",
  //   yAxisId: "price",
  //   afix: "₽",
  // },

  {
    id: "count",
    name: "Частотность артикула",
    afix: "",
    color: palette.magentaPink,
    orientation: "left",
    default: true,
  },
];

const productDrawerColumns = ColumnsBuilder.getColumnsByKeys([
  {
    dataIndex: "date",
    render: v => format(new Date(v), "dd.MM.yyyy"),
    sortable: true,
  },
  {
    dataIndex: "product_ads",
  },
  {
    dataIndex: "orders",
  },
  {
    dataIndex: "price",
  },
  {
    dataIndex: "proceeds",
  },
  {
    dataIndex: "quantity",
  },
  // {
  //   dataIndex: "weighted_price",
  // },
  // {
  //   dataIndex: "damping_coefficient",
  // },
]);

export { isMatterOuterTraffic, productDrawerAxis, productDrawerColumns, externalInfluenceOptions };
