import React from "react"

import classNames from "classnames";

import Square from "../../icons/square"
import Triangle from "../../icons/triangle"
import Trips from "../../tips"

import { numberWithSpaces } from "root/js/utils"

import styles from "./index.module.scss"

const MiniCardPrecents = props => {
	const {
		title,
		deliver,
		deliverPrev,
		precent,
		trip,
		borderIncrement,
		borderDicrement,
		colorIncrement,
		colorDicrement,
		transformRotateIncrement,
		transformRotateDicrement,
		valuet,
		deliverySharePrevTitle,
		italicStyle
	} = props

	const result = (deliver - deliverPrev) / deliverPrev || 0
	return (
		<div
			className={styles.miniContainer}
			style={{
				borderTop:
					result < 0
						? borderDicrement
						: result === 0 || result === -Infinity || result === Infinity
							? "4px solid #FFA500"
							: borderIncrement,
			}}
		>
			<div className={styles.titleBox}>
				<h5> {title}</h5>
			</div>

			<div className={styles.deliveryContainer}>
				<span className={styles.subjects}>
					{numberWithSpaces(deliver.toFixed(0))} {valuet}
				</span>
			</div>

			<div className={styles.precentContainer}>
				<span
					style={{
						color:
							result < 0
								? colorDicrement
								: result === 0 || result === -Infinity || result === Infinity
									? "#FFA500"
									: colorIncrement,
					}}
					className={styles.precent}
				>
					{result === Infinity || result === -Infinity
						? "-"
						: `${(result * 100).toFixed(1)}%`}
				</span>

				{result === 0 || result === -Infinity || result === Infinity ? (
					<Square />
				) : (
					<Triangle
						result={result}
						colorIncrement={colorIncrement}
						colorDicrement={colorDicrement}
						transformRotateIncrement={transformRotateIncrement}
						transformRotateDicrement={transformRotateDicrement}
					/>
				)}
			</div>

			<div className={styles.deliveryPrecent}>
				{deliverySharePrevTitle &&
					<span className={classNames(styles.precentTitle, italicStyle && styles.italic)}>
            {deliverySharePrevTitle}
						{trip && <Trips text={trip} />}
          </span>
				}
				<span className={classNames(styles.precent, italicStyle && styles.italic)}>{(precent * 100).toFixed(1) || 0}% </span>
			</div>
		</div>
	)
}

export default MiniCardPrecents
