import request from "root/js/request";

export const postKeywordsPositionReloadRun = async ({ run_id, param }) => {
  const response = await request({
    url: `/parsers/keywords_position/reload_run/`,
    method: "post",
    data: {
      run_id,
      param,
    },
  });
  return response;
};
