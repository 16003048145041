import request from "root/js/request";

// Получаем историю всех запросов
export const getKeywordsPosition = async ({ ordering, limit, offset }) => {
  const response = await request({
    url: `/parsers/keywords_position/`,
    method: "get",
    params: {
      ordering,
      limit,
      offset,
    },
  });
  return response;
};
