import React, { useState } from "react";

import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { prop } from "ramda";
import { useSelector } from "react-redux";

import CardTwoTitle from "root/components/global/cards/card-two-title";

import styles from "./index.module.scss";

const CommisionCards = () => {
  const { commisionWidgets2, currencyName } = useSelector(state => state.financeLogic);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(3);
  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };
  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  return (
    <div className={styles.cardsWrapper}>
      {isMobile && (
        <IconButton onClick={prev} className={styles.controlBtn} sx={{ left: "-10px" }}>
          <ArrowLeftIcon />
        </IconButton>
      )}
      <div
        className={styles.cardsContainer}
        style={{ transform: `translateX(-${currentIndex * 103}%)` }}>
        <div className={styles.cardWrapper}>
          <CardTwoTitle
            valuet={"%"}
            title="Комиссия WB"
            commisionValue={
              Number(prop("kvw_prc_commission", prop("current", commisionWidgets2))) * 100
            }
            commisionValuePrev={
              Number(prop("kvw_prc_commission", prop("prev", commisionWidgets2))) * 100
            }
            transformRotateIncrement="rotate(-268deg)"
            transformRotateDicrement="rotate(0deg)"
            colorIncrement="#59D65E"
            colorDicrement="#EB255D"
            borderIncrement="4px solid #59D65E"
            borderDicrement="4px solid #EB255D"
            tripText="Средняя комиссия маркетплейса по тарифам Wildberries в соответствии с разделом 12 оферты"
          />
        </div>

        <div className={styles.cardWrapper}>
          <CardTwoTitle
            valuet={"%"}
            title="Скидка WB"
            commisionValue={
              Number(prop("spp_prc_discount", prop("current", commisionWidgets2))) * 100
            }
            commisionValuePrev={
              Number(prop("spp_prc_discount", prop("prev", commisionWidgets2))) * 100
            }
            borderIncrement="4px solid #EB255D"
            borderDicrement="4px solid #59D65E"
            transformRotateIncrement="rotate(-268deg)"
            transformRotateDicrement="rotate(0deg)"
            colorIncrement="#EB255D"
            colorDicrement="#59D65E"
            tripText="Средний размер скидки у покупателя, предоставляемой Wildberries"
          />
        </div>

        <div className={styles.cardWrapper}>
          <CardTwoTitle
            valuet={currencyName}
            title="Вознаграждение WB"
            commisionValue={Number(prop("commission", prop("current", commisionWidgets2)))}
            commisionValuePrev={Number(prop("commission", prop("prev", commisionWidgets2)))}
            borderIncrement="4px solid #00C85A"
            borderDicrement="4px solid #EB255D"
            transformRotateIncrement="rotate(-268deg)"
            transformRotateDicrement="rotate(0deg)"
            colorIncrement="#00C85A"
            colorDicrement="#EB255D"
            tripText="Сумма фактически начисленного вознаграждения Wildberries, включая НДС"
          />
        </div>

        {/*<div>*/}
        {/*  <CardTwoTitle*/}
        {/*    valuet={currencyName}*/}
        {/*    title="НДС"*/}
        {/*    commisionValue={prop("nds", prop("current", commisionWidgets)) || 0}*/}
        {/*    commisionValuePrev={prop("nds", prop("prev", commisionWidgets)) || 0}*/}
        {/*    estimadetPrecent={*/}
        {/*      prop("nds_to_commission_share", prop("current", commisionWidgets)) || 0*/}
        {/*    }*/}
        {/*    borderIncrement="4px solid #00C85A"*/}
        {/*    borderDicrement="4px solid red"*/}
        {/*    transformRotateIncrement="rotate(268deg)"*/}
        {/*    transformRotateDicrement="rotate(90deg)"*/}
        {/*    colorIncrement="#00C85A"*/}
        {/*    colorDicrement="red"*/}
        {/*  />*/}
        {/*</div>*/}
      </div>

      {isMobile && (
        <IconButton onClick={next} className={styles.controlBtn} sx={{ right: "-10px" }}>
          <ArrowLeftIcon sx={{ transform: "rotate(180deg)" }} />
        </IconButton>
      )}
    </div>
  );
};

export default CommisionCards;
