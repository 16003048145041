import request from "root/js/request";

export const getAnalyticsCapitalizationTable = async ({
  wb_api_key_ids,
  cabinet_id__in = wb_api_key_ids,
  product_cost__isnull = true,
  group_by = "barcode",
  ...rest
}) => {
  const response = await request({
    url: "/analytics/capitalization_table/",
    method: "get",
    params: {
      cabinet_id__in,
      product_cost__isnull,
      group_by,
      ...rest,
    },
  });
  return response.data;
};
