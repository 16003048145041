import request from "root/js/request";
const getFinesAndCompensationTopProducts = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  category_id__in,
  brand_id__in,
  supplier_article__in,
  barcode__in,
  subject_id__in,
  product_id__in,
  value,
  type_names,
}) => {
  const response = await request({
    url: "analytics/penalty_additionals_top_products/",

    method: "get",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      category_id__in,
      brand_id__in,
      supplier_article__in,
      barcode__in,
      subject_id__in,
      product_id__in,
      value,
      type_names,
    },
  });

  return response.data;
};

export { getFinesAndCompensationTopProducts };
