import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { batch, useDispatch, useSelector } from "react-redux";
import { REQUESTS_DEBOUNCE_TIME } from "root/js/constants";
import {
  PenaltyAdditionalsLinesDynamic,
  PenaltyAdditionalsWidgets,
  PenaltyDiagramProducts,
  PenaltyOffices,
  SurchargesAdditionalsWidgets
} from "root/store/financialLogic/action";
import { addCheckboxTypeName } from "root/store/financialLogic/financialLogicSlice";
import { useDebouncedCallback } from "use-debounce";

import styles from "./index.module.scss";

const PenaltyCheckbox = ({
  value,
  setIsLoadingProduct,
  setIsLoadingOffices,
  setIsLoadingDiagramLine,
  setIsLoadingAdditionalsWidgets
}) => {
  const {
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    subjectsId,
    dateFrom,
    dateTo,
    singlePenaltyType
  } = useSelector(state => state.financeLogic);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    dispatch(addCheckboxTypeName(selectedOption));
  }, [selectedOption]);

  const handleChecked = (e, type_name, color) => {
    if (e.target.checked) {
      setSelectedOption([...selectedOption, { type_name, color }]);
    } else {
      if(selectedOption.length === 1) return
      setSelectedOption(prev => {
        const arr = prev.filter(e => e.type_name != type_name);
        return arr;
      });
    }
  };

  useEffect(() => {
    setSelectedOption(state => [...state, ...singlePenaltyType]);
  }, [singlePenaltyType]);

  useEffect(() => {
    if (keys.length > 0 && dateFrom && selectedOption?.length > 0) {
      const types = selectedOption?.map(el => el.type_name);
      batch(() => {
        dispatch(
          PenaltyDiagramProducts({
            keys,
            dateFrom,
            dateTo,
            categoryId,
            brandIds,
            articulIds,
            barcodeIds,
            subjectsId,
            value,
            typeNames: types
          })
        );
        dispatch(
          PenaltyAdditionalsLinesDynamic({
            keys,
            dateFrom,
            dateTo,
            categoryId,
            brandIds,
            articulIds,
            barcodeIds,
            subjectsId,
            value,
            type_name: singlePenaltyType
          })
        );
        dispatch(
          PenaltyOffices({
            keys,
            dateFrom,
            dateTo,
            categoryId,
            brandIds,
            articulIds,
            barcodeIds,
            subjectsId,
            value,
            typeNames: types
          })
        );
      });
      setIsLoadingProduct(false);
      setIsLoadingOffices(false);
      setIsLoadingDiagramLine(false);
    }
  }, [selectedOption, value]);

  useEffect(() => {
    if (keys.length > 0 && dateFrom && selectedOption?.length > 0) {
      const types = selectedOption?.map(el => el.type_name);
      dispatch(
        PenaltyAdditionalsWidgets({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
          typeNames: types
        })
      );
    }
  }, [selectedOption]);

  const fetchAdditionalsWidgetsDebounce = useDebouncedCallback(async () => {
    const types = selectedOption?.map(el => el.type_name);
    dispatch(
      SurchargesAdditionalsWidgets({
        keys,
        dateFrom,
        dateTo,
        categoryId,
        brandIds,
        articulIds,
        barcodeIds,
        subjectsId,
        typeNames: types
      })
    );
    setIsLoadingAdditionalsWidgets(false);
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0 && dateFrom) {
      setIsLoadingAdditionalsWidgets(true);
      fetchAdditionalsWidgetsDebounce.callback();
    }
  }, [dateFrom, categoryId, brandIds, articulIds, barcodeIds, subjectsId]);

  return (
    <div className={styles.penaltyDynamicContainer}>
      <div>
        <h4>Типы штрафов и компенсаций</h4>
      </div>
      <div className={styles.fullBox}>
        <div className={styles.containerCheckbox}>
          {singlePenaltyType.map((elem, index) => (
            <div key={index}>
              <Box width="100%" key={index} className={styles.checkBoxBlock}>
                <div>
                  <Checkbox
                    className={styles.checkBox}
                    checked={!!selectedOption.find(item => item.type_name === elem.type_name)}
                    onChange={e => handleChecked(e, elem.type_name, elem.color)}
                    style={{
                      color: elem.color || "green"
                    }}
                  />

                  <span className={styles.fineName} style={{ lineHeight: "1.2" }}>
                    {elem.type_name}
                  </span>
                </div>
              </Box>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PenaltyCheckbox;
