import request from "root/js/request";

export const getCabinetBalances = async ({ cabinet_id }) => {
  const response = await request({
    url: `/analytics/cabinet_balances/`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response;
};
