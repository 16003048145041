import { createAsyncThunk } from "@reduxjs/toolkit";
import { path, prop } from "ramda";

import API from "root/js/api";

const WBAPI = new API();

// Поправил пару деталей, но старый код менять или переписывать не стал, есть странные момент
// но пока работает
export const getPartialPurchases = createAsyncThunk(
  "/getPartialPurchases",
  async ({ token }, thunkAPI) => {
    try {
      const response = await WBAPI.getPartialPurchase({ token });
      return response;
    } catch (error) {
      const errorStatus = path(["response", "status"], error);
      if (errorStatus === 400 || errorStatus === 404) console.error(error);
      console.log(error);
      if (errorStatus === 400 || errorStatus === 404) console.error(error);
      if (errorStatus === 401) WBAPI.resetToken();
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const getMyPartialPurchases = createAsyncThunk(
  "/getMyPartialPurchases",
  async (_, thunkAPI) => {
    try {
      const response = await WBAPI.getMyPartialPurchase();
      return response;
    } catch (error) {
      const errorStatus = path(["response", "status"], error);
      if (errorStatus === 400 || errorStatus === 404) console.error(error);
      if (errorStatus === 400 || errorStatus === 404) console.error(error);
      if (errorStatus === 401) {
        window.location = `${
          process.env.REACT_APP_ROUTER_BASE_PATH
        }/signin?redirect_url=${window.location.pathname.replace("/dashboard", "")}`;
        WBAPI.resetToken();
      }
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const payPartialPurchase = createAsyncThunk(
  "/payPartialPurchase",
  async ({ url_key }, thunkAPI) => {
    try {
      const response = await WBAPI.payPartialPurchase({ url_key });
      window.location.href = prop("url", prop("data", response));
      return response;
    } catch (error) {
      const errorStatus = path(["response", "status"], error);
      if (errorStatus === 400 || errorStatus === 404) console.log(error);
      if (errorStatus === 400 || errorStatus === 404) console.log(error);
      if (errorStatus === 401) WBAPI.resetToken();
      return thunkAPI.rejectWithValue("newPage");
    }
  }
);
