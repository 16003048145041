import { createSlice } from "@reduxjs/toolkit";
import { postfixes } from "root/utils";
import { getServerDate } from "root/utils/date";
import { daysMS } from "root/utils/date/constant";

// const getDateFrom = date => getServerDate(date);
const getDateFrom = date => getServerDate(date) && getServerDate(date) + "T00:00:01+03:00";
// const getDateTo = date => getServerDate(date);
const getDateTo = date => getServerDate(date) && getServerDate(date) + "T23:59:59.999+03:00";
//
function getFullWeeksRange(minDate, maxDate) {
  const min = new Date(minDate);
  const max = new Date(maxDate);
  const weeksCount = (max - min) / (7 * daysMS);
  if (weeksCount < 1) return [minDate, maxDate];
  const n = weeksCount >= 4 ? 4 : weeksCount;
  return [getServerDate(new Date(max - (7 * (n - 1) + 6) * daysMS)), getServerDate(max)];
}

const initialState = {
  cabinetsListWB: [],
  currentCabinetIdWB: null,
  currentCabinetIdsWB: [],
  isCabinetsLoading: false,
  currency: postfixes.rub,
  selectors: {},
  params: {
    // date_from: getDateFrom(defaultDateRange[0]),
    // date_to: getDateTo(defaultDateRange[1]),
  },
  dates: {
    selectedMonths: [],
    // maxDate: null,
    // minDate: null,
  },
};

const innerAnalyticsGlobal = createSlice({
  name: "innerAnalyticsGlobal",
  initialState,
  reducers: {
    setCabinetsListIA(state, { payload: newCabinetsList = [] }) {
      state.cabinetsListWB = newCabinetsList;
    },
    setCurrentCabinetIdIA(state, { payload: newCabinetId = null }) {
      state.currentCabinetIdWB = newCabinetId;
    },
    setCurrentCabinetIdsIA(state, { payload: newCabinetIds = [] }) {
      state.isCabinetsLoading = false;
      state.currentCabinetIdsWB = newCabinetIds;
    },
    setRRInfo(
      state,
      {
        payload: {
          minDate = "2022-01-01",
          maxDate = getServerDate(new Date()),
          currency = postfixes.rub,
        },
      }
    ) {
      //
      const defaultDateRange = getFullWeeksRange(minDate, maxDate);
      if (state.params.date_from && new Date(state.params.date_from) <= new Date(minDate))
        state.params.date_from = getDateFrom(minDate);
      else state.params.date_from = getDateFrom(defaultDateRange[0]);
      if (state.params.date_to && new Date(state.params.date_to) >= new Date(maxDate))
        state.params.date_to = getDateTo(maxDate);
      else state.params.date_to = getDateTo(defaultDateRange[1]);
      //

      state.currency = currency;
      state.dates.minDate = minDate;
      state.dates.maxDate = maxDate;
    },
    setIAParam(state, { payload: { name, value } }) {
      if (value.length) {
        state.selectors[name] = value;
        state.params[name] = value.map(i => i.id).join();
      } else {
        delete state.selectors[name];
        delete state.params[name];
      }
    },
    resetIAParams(state) {
      state.selectors = {};
      state.params = {
        date_from: state.params.date_from,
        date_to: state.params.date_to,
      };
    },
    setIASelectedMonths(state, { payload: { months: newSelectedMonths, dateRange } }) {
      state.dates.selectedMonths = newSelectedMonths;
      if (dateRange) {
        state.params.date_from = getDateFrom(dateRange[0]);
        state.params.date_to = getDateTo(dateRange[1]);
      } else {
        const [from, to] = getFullWeeksRange(state.dates.minDate, state.dates.maxDate);
        state.params.date_from = from;
        state.params.date_to = to;
      }
    },
    setAIDateRange(state, { payload: dateRange }) {
      const correctDateRange = !dateRange.length
        ? getFullWeeksRange(state.dates.minDate, state.dates.maxDate)
        : dateRange;
      state.params.date_from = getDateFrom(correctDateRange[0]);
      state.params.date_to = getDateTo(correctDateRange[1]);
      state.dates.selectedMonths = [];
    },
    setCabinetIdsLoading(state, { payload: isLoading }) {
      state.isCabinetsLoading = isLoading;
    },
    //
  },
});

export default innerAnalyticsGlobal.reducer;

export const {
  setCabinetsListIA,
  setCurrentCabinetIdIA,
  setCurrentCabinetIdsIA,
  setRRInfo,
  setIAParam,
  setCabinetIdsLoading,
  resetIAParams,
  setIASelectedMonths,
  setAIDateRange,
} = innerAnalyticsGlobal.actions;
