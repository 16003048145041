import React from 'react'

import { Checkbox } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { handleCheckBoxSelectAll } from 'root/store/categoryOptimization/categoryOptimization'

const AllCheckBoxes = () => {
  const dispatch = useDispatch()
  const page = useSelector(store => store.categoryOptimization.page)
  const allStore = useSelector(store => store.categoryOptimization.checkBoxSelect?.[page]?.all)
  const ids = useSelector(store => store.categoryOptimization.ids?.[page])

  const onChange = e => dispatch(handleCheckBoxSelectAll({ checked: e.target.checked, value: ids ?? [] }))

  return (
    <Checkbox
      size="small"
      color="primary"
      checked={allStore}
      onChange={(e) => onChange(e)}
    />
  )
}

export default AllCheckBoxes