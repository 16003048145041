import request from "root/js/request";

const getAdvertKeywordsByKeywordsTable = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  q,
  order_by,
  offset,
  limit,
  advert_id__in,
  brand_id__in,
  category_id__in,
  subject_id__in,
  product_id__in,
  type__in,
  status__in,
}) => {
  const response = await request({
    url: "/analytics/advert_keywords_by_keywords/",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      order_by,
      q,
      offset,
      limit,
      advert_id__in,
      brand_id__in,
      category_id__in,
      subject_id__in,
      product_id__in,
      type__in,
      status__in,
    },
  });

  return response.data;
};

export { getAdvertKeywordsByKeywordsTable };
