import React from "react";

import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

// -------------------------------------------
const externalAnalysisSection = {
  type: "section",
  title: "Анализ внешки",
  icon: TroubleshootIcon,
  children: [
    {
      title: "Поиск трафика WB",
      url: "/bloggers-search",
      component: React.lazy(() => import("root/pages/bloggers-search/App")),
      exact: false,
    },
    {
      title: "Поиск трафика другие МП",
      url: "/all-bloggers-search",
      component: React.lazy(() => import("root/pages/bloggers-search-for-all/App")),
      exact: false,
    },
    {
      title: "Отчеты по внешке",
      url: "/bloggers-data",
      component: React.lazy(() => import("root/pages/bloggers/App")),
      exact: false,
    },
    {
      title: "Подбор публикаций",
      url: "/bloggers-posts-selection",
      component: React.lazy(() => import("root/pages/bloggers-posts-selection/App")),
      exact: false,
    },
    {
      title: "Поиск блогеров",
      url: "/bloggers-profiles-selection",
      component: React.lazy(() => import("root/pages/bloggers-profiles-selection/App")),
      exact: false,
    },
    {
      title: "Анализ блогеров",
      url: "/blogger-card",
      component: React.lazy(() => import("root/pages/blogger-card/App")),
      exact: false,
    },
    {
      url: "/monitoring?tab=7",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "Мониторинг внешки",
      exact: false,
    },
  ],
};

export default externalAnalysisSection;
