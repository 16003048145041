import request from "root/js/request";

export const postCreateShares = async ({ promotion_id, cabinet_id }) => {
  const response = await request({
    url: `/analytics/shares/`,
    method: "post",
    params: {
      cabinet_id,
    },
    data: {
      promotion: promotion_id,
    },
  });
  return response;
};

// Редактировать все поля акции кроме level
export const putShares = async ({ cabinet_id, shares_id, correctShared }) => {
  const response = await request({
    url: `/analytics/shares/${shares_id}/?cabinet_id=${cabinet_id}`,
    method: "put",
    params: {
      cabinet_id,
    },
    data: correctShared,
  });
  return response;
};

export const deleteShares = async ({ cabinet_id, shares_id }) => {
  const response = await request({
    url: `/analytics/shares/${shares_id}`,
    method: "delete",
    params: {
      cabinet_id,
    },
  });
  return response;
};
