import request from "root/js/request";

export const getWBDynamicGeneralCategories = async ({
  product_ids,
  locf_dynamic,
  brand_ids,
  full_name,
  seller_ids,
  category_ids,
  collection_id,
  parent_id,
  down_parent_id,
  up_parent_id,
  level,
  wb_search,
  search,
  name, // new search!
  wb_catalog_url,
  city,
  date_from,
  date_to,
  period,
  limit,
  offset,
  ordering,
  has_child,
  extra_fields = "category_name",
  ...rest
}) => {
  const response = await request({
    url: "/wb_dynamic/general_categories/",
    method: "get",
    params: {
      product_ids,
      locf_dynamic,
      full_name,
      brand_ids,
      seller_ids,
      collection_id,
      category_ids,
      parent_id,
      down_parent_id,
      up_parent_id,
      level,
      wb_search,
      search,
      name,
      wb_catalog_url,
      city,
      date_from,
      date_to,
      period,
      limit,
      offset,
      ordering,
      has_child,
      extra_fields,
      ...rest,
    },
  });
  return response.data;
};
