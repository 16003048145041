import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import noPhoto from "../../img/photo.svg";

import styles from "./index.module.scss";

const Image = ({ className, classes = {}, src, ...rest }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState(src);
  const imgEl = useRef(null);

  const onLoad = () => {
    setLoaded(true);
  };
  const onError = () => {
    setImageSrc(noPhoto);
  };

  useEffect(() => {
    const img = imgEl.current;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src]);

  useEffect(() => {
    setImageSrc(src || noPhoto);
    // setImageSrc(noPhoto);
  }, [src]);
  return (
    <img
      loading="lazy"
      src={imageSrc}
      ref={imgEl}
      className={classNames(styles.root, className, classes.root)}
      data-loaded={isLoaded}
      {...rest}
    />
  );
};

export default Image;
