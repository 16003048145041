import request from "root/js/request";

export const postPromotionsProductsUpload = async ({ cabinet_id, shares_id }, formData) => {
  const response = await request({
    url: `/analytics/shares/${shares_id}/upload_excel/`,
    method: "post",
    params: { cabinet_id },
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });
  return response;
};
