import { createSlice, current } from "@reduxjs/toolkit"
import { isEmpty, prop } from "ramda"

import { setProfile } from "./actions"

const initialState = {
	id: null,
	role: null,
	company: null,
	email: null,
	is_email_verified: false,
	phone: null,
	name: null,
	product_keyword_monitoring_limit: 0,
	white_label: undefined,
}

export const profileSlice = createSlice({
	name: "profileSlice",
	initialState,
	reducers: {
		setZeroCostArticules(state, { payload }) {
			state.zeroCostArticules = payload
			if (!isEmpty(state.articuleBrandFilters)) {
				const filteredArticuleData = current(state).articuleFullData.filter(art =>
					state.articuleBrandFilters.includes(art.brand_name)
				)

				if (payload) {
					state.articuleData = filteredArticuleData.filter(({ cost }) => +cost.cost === 0)
					state.articuleLastData = filteredArticuleData.filter(
						({ cost }) => +cost.cost === 0
					)
				} else {
					state.articuleData = filteredArticuleData
					state.articuleLastData = filteredArticuleData
				}
			} else {
				if (payload) {
					state.articuleData = current(state).articuleFullData.filter(
						({ cost }) => +cost.cost === 0
					)
					// .map((item, index) => ({
					// 	...item,
					// 	cost: {
					// 		...item.cost,
					// 		index,
					// 	},
					// }))
					state.articuleLastData = current(state).articuleFullData.filter(
						({ cost }) => +cost.cost === 0
					)
					// .map((item, index) => ({
					// 	...item,
					// 	cost: {
					// 		...item.cost,
					// 		index,
					// 	},
					// }))
				} else {
					state.articuleData = current(state).articuleFullData
					state.articuleLastData = current(state).articuleFullData
				}
			}
		},

		setZeroCostBarcodes(state, { payload }) {
			state.zeroCostBarcodes = payload
			if (!isEmpty(state.barcodeBrandFilters)) {
				const filteredBarcodeData = current(state).barcodeFullData.filter(bcode =>
					state.barcodeBrandFilters.includes(bcode.brand_name)
				)

				if (payload) {
					state.barcodeData = filteredBarcodeData.filter(({ cost }) => +cost.cost === 0)
					state.barcodeLastData = filteredBarcodeData.filter(({ cost }) => +cost.cost === 0)
				} else {
					state.barcodeData = filteredBarcodeData
					state.barcodeLastData = filteredBarcodeData
				}
			} else {
				if (payload) {
					state.barcodeData = current(state).barcodeFullData.filter(
						({ cost }) => +cost.cost === 0
					)
					state.barcodeLastData = current(state).barcodeFullData.filter(
						({ cost }) => +cost.cost === 0
					)
				} else {
					state.barcodeData = current(state).barcodeFullData
					state.barcodeLastData = current(state).barcodeFullData
				}
			}
		},
		setBrandFilterArticules(state, { payload }) {
			state.articuleBrandFilters = payload
		},
		setBrandFilterBarcodes(state, { payload }) {
			state.barcodeBrandFilters = payload
		},
		setBrandFilterArticulesData(state, { payload }) {
			if (current(state).zeroCostArticules) {
				const zeroArticuleData = current(state).articuleFullData.filter(
					({ cost }) => +cost.cost === 0
				)
				if (!isEmpty(payload)) {
					state.articuleData = zeroArticuleData.filter(art =>
						payload.includes(art.brand_name)
					)
					state.articuleLastData = zeroArticuleData.filter(art =>
						payload.includes(art.brand_name)
					)
				} else {
					state.articuleData = zeroArticuleData
					state.articuleLastData = zeroArticuleData
				}
			} else {
				if (!isEmpty(payload)) {
					state.articuleData = current(state).articuleFullData.filter(art =>
						payload.includes(art.brand_name)
					)
					// .map((item, index) => ({
					// 	...item,
					// 	cost: {
					// 		...item.cost,
					// 		index,
					// 	},
					// }))
					state.articuleLastData = current(state).articuleFullData.filter(art =>
						payload.includes(art.brand_name)
					)
					// .map((item, index) => ({
					// 	...item,
					// 	cost: {
					// 		...item.cost,
					// 		index,
					// 	},
					// }))
				} else {
					state.articuleData = current(state).articuleFullData
					state.articuleLastData = current(state).articuleFullData
				}
			}
		},
		setBrandFilterBarcodesData(state, { payload }) {
			if (current(state).zeroCostBarcodes) {
				const zeroBarcodeData = current(state).barcodeFullData.filter(
					({ cost }) => +cost.cost === 0
				)
				if (!isEmpty(payload)) {
					state.barcodeData = zeroBarcodeData.filter(bcode =>
						payload.includes(bcode.brand_name)
					)
					state.barcodeLastData = zeroBarcodeData.filter(bcode =>
						payload.includes(bcode.brand_name)
					)
				} else {
					state.barcodeData = zeroBarcodeData
					state.barcodeLastData = zeroBarcodeData
				}
			} else {
				if (!isEmpty(payload)) {
					state.barcodeData = current(state).barcodeFullData.filter(bcode =>
						payload.includes(bcode.brand_name)
					)
					state.barcodeLastData = current(state).barcodeFullData.filter(bcode =>
						payload.includes(bcode.brand_name)
					)
				} else {
					state.barcodeData = current(state).barcodeFullData
					state.barcodeLastData = current(state).barcodeFullData
				}
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(setProfile.fulfilled, (state, { payload }) => {
			state.id = prop("id", payload)
			state.role = prop("role", payload)
			state.company = prop("company", payload)
			state.email = prop("email", payload)
			state.is_email_verified = prop("is_email_verified", payload)
			state.phone = prop("phone", payload)
			state.name = prop("name", payload)
			state.product_keyword_monitoring_limit = prop("product_keyword_monitoring_limit", payload)
			state.white_label = prop("white_label", payload)
			state.config = prop("config", payload)
		})
	}
})

export default profileSlice.reducer
