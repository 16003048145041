import React, { useEffect, useState } from "react";

import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import classnames from "classnames";
import { useLocation } from "react-router-dom";

import ExternalLinkPure from "root/components/ExternalLinkPure";
import LinkPure from "root/components/LinkPure";

import logoWithTextSrc from "root/img/logo/logo-with-text.svg";
import logoSrc from "root/img/logo/logo.svg";

import DrawerSection from "./DrawerSection";
import tgLogoSrc from "./tg-logo.svg";

import styles from "./index.module.scss";

import TelegramIcon from "root/icons/Telegram";

const locationWithSearch = location => `${location.pathname}${location.search}`;

const MainMenuDrawer = props => {
  const { open, onClose, drawerToggle, setIsDrawerOpen, className, ...rest } = props;

  return (
    <>
      <Hidden mdDown>
        <Drawer
          {...rest}
          classes={{
            paper: classnames(styles.paper, className, styles.deteils),
          }}
          PaperProps={{ "data-open": open, "data-transition": true }}
          variant="permanent">
          <Content
            {...props}
            drawerToggle={drawerToggle}
            setIsDrawerOpen={setIsDrawerOpen}
            isMobile={false}
          />
        </Drawer>
      </Hidden>

      <Hidden mdUp>
        <Drawer
          {...rest}
          classes={{
            paper: classnames(styles.paper, className),
          }}
          onClose={onClose}
          open={open}>
          <Content {...props} isMobile={true} />
        </Drawer>
      </Hidden>
    </>
  );
};

const Content = props => {
  const { isMobile, items, onClose, open, drawerToggle, setIsDrawerOpen } = props;

  const location = useLocation();

  const [expandedTitles, setExpandedTitles] = useState([]);

  const onExpand = title => {
    setExpandedTitles(prevTitles =>
      prevTitles.includes(title)
        ? prevTitles.filter(item => item !== title)
        : prevTitles.concat(title)
    );
  };

  useEffect(() => {
    if (!open) return;
    const activeItem = items.find(
      item =>
        item.type === "section" &&
        item.children.some(item => ~locationWithSearch(location).indexOf(item.url))
    );
    activeItem &&
      setExpandedTitles(arr =>
        arr.includes(activeItem.title) ? arr : arr.concat(activeItem.title)
      );
  }, [open, location, items]);

  return (
    <div
      onMouseOver={!isMobile ? () => !open && drawerToggle() : null}
      onMouseLeave={
        !isMobile
          ? () => {
              if (open) {
                drawerToggle();
                onClose();
              }
            }
          : null
      }
      className={styles.wrapper}>
      <div className={styles.header}>
        <LinkPure to={process.env.REACT_APP_HOME_PAGE} className={styles.logoWrapper}>
          <img src={logoWithTextSrc} className={styles.logoWithText} alt="logo" />
          <img src={logoSrc} className={styles.logo} alt="logo" />
        </LinkPure>
        {isMobile && (
          <MenuOpenOutlinedIcon
            className={styles.openButton}
            data-open={open}
            onClick={drawerToggle}
          />
        )}
      </div>
      <div className={styles.sections}>
        {items.map((item, index) => (
          <DrawerSection
            key={item.title}
            onClick={value => {
              isMobile && onClose();
            }}
            isNonClosing={item.expanded}
            isExpanded={item.expanded || expandedTitles.includes(item.title)}
            setIsDrawerOpen={setIsDrawerOpen}
            onExpand={onExpand}
            open={isMobile ? true : open}
            isMobile={isMobile}
            item={item}
            isLastItem={index === items.length - 1}
          />
        ))}
      </div>
      <ExternalLinkPure
        href="https://t.me/wildbox_news"
        target="_blank"
        className={styles.tgWrapper}>
        <div className={styles.tg}>
          <img className={styles.tgRocketLogo} src={tgLogoSrc} alt="telegram" />
          <TelegramIcon className={styles.tgLogo} />
          <div className={styles.tgInfo}>
            <div className={styles.tgTitle}>WildBox в Telegram</div>
            <div className={styles.tgSubtitle}>Перейти</div>
          </div>
        </div>
      </ExternalLinkPure>
    </div>
  );
};

export default MainMenuDrawer;
