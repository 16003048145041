import { createSlice } from "@reduxjs/toolkit"
import { clone, isEmpty, propOr } from "ramda"

import {
	createSPPList,
	deleteSPPItems,
	getSPPItemNotification,
	getSPPList,
	getSPPListLimits,
	getSPPNotifications,
	setSPPList,
} from "./actions"

const initialState = {
	sppListIsLoading: false,
	sppList: [],
	sppListCount: null,
	sppListError: null,

	sppNotificationsIsLoading: false,
	sppNotifications: [],
	sppNotificationsError: null,

	sppItemNotificationIsLoading: false,
	sppItemNotification: [],
	sppItemNotificationError: null,

	setSppListInProcess: false,
	setSppListSuccess: false,
	setSppListError: null,

	deleteSPPItemsInProcess: false,
	deleteSPPItemsSuccess: false,
	deleteSPPItemsError: null,




	tableCheckBox: [],
	tableCheckBoxIsAll: { 1: false },
	tableCheckBoxByPage: { 1: [] },
	tableAllIds: [],
	tableCount: 0,
	tablePage: 1,
	tablePerPage: 25,
	tableSearch: "",
	searchRerender: 0,

	sppEditItem: null,
	showEdit: false,

	isOpenDeleteModal: false,

	limits: {
		isLoading: false,
		usage_count: 500,
		max_usage_count: 500,
		exists_usage_count: 0
	},
	showAddModal: false,
}

const spp = createSlice({
	name: "spp",
	initialState,
	reducers: {
		setShowAddModal(state, { payload }) {
			state.showAddModal = payload
		},
		setDeleteModal(state, { payload }) {
			state.isOpenDeleteModal = payload.isOpen
		},
		setCheckBox(state, { payload }) {
			state.tableCheckBoxIsAll = {
				...state.tableCheckBoxIsAll,
				[payload.page]: false
			}
			
			// console.log(payload);

			if (payload.checked) {
				state.tableCheckBoxByPage = {
					...state.tableCheckBoxByPage,
					[payload.page]: [...(state.tableCheckBoxByPage?.[payload.page] ?? []), payload.id]
				}
				state.tableCheckBox = [...state.tableCheckBox, payload.id]
				return
			}

			state.tableCheckBox = state.tableCheckBox?.filter(item => item !== payload.id)
			state.tableCheckBoxByPage = {
				...state.tableCheckBoxByPage,
				[payload.page]: state.tableCheckBoxByPage?.[payload.page]?.filter(item => item !== payload.id)
			}
		},
		setCheckBoxAll(state, { payload }) {
			if (payload.checked) {
				state.tableCheckBox = state.tableAllIds
				state.tableCheckBoxIsAll = {
					...state.tableCheckBoxIsAll,
					[payload.page]: true
				}
				state.tableCheckBoxByPage = {
					...state.tableCheckBoxByPage,
					[payload.page]: state.sppList.map(item => item.product_id)
				}

				state.tableCheckBox = [
					...state.tableCheckBox,
					...state.sppList.map(item => item.product_id)
				]

				return
			}
			state.tableCheckBox = []
			state.tableCheckBoxIsAll = {
				...state.tableCheckBoxIsAll,
				[payload.page]: false
			}
			state.tableCheckBoxByPage = {
				...state.tableCheckBoxByPage,
				[payload.page]: []
			}

			state.sppList.forEach(({ product_id }) => {
				state.tableCheckBox = state.tableCheckBox.filter(item => item !== product_id)
			})

		},
		setTablePage(state, { payload }) {
			state.tablePage = payload
		},
		setSearchRerender(state, { payload }) {
			state.searchRerender = payload
		},
		setShowEdit(state, { payload }) {
			state.showEdit = payload
		},
		setSppEditItem(state, { payload }) {
			state.sppEditItem = payload
		},
		setSearchValue(state, { payload }) {
			state.tableSearch = payload
		},
	},
	extraReducers: builder => {
		// get list
		builder.addCase(getSPPList.pending, state => {
			state.sppListIsLoading = true
		})
		builder.addCase(getSPPList.fulfilled, (state, { payload }) => {
			state.sppListIsLoading = false
			state.sppListCount = payload.count
			state.sppList = propOr([], "results", payload).map(item => ({
				...item,
				articule: {
					image: item.image,
					articule: item.product_id,
				},
				spp_dynamic: item.dynamic.map(i => ({
					y: i.spp,
					date: i.create_date,
					max: i.max,
				})),
				current_spp: {
					max: item.max,
					value: item.current_spp,
				},
			}))
			state.sppListError = null
		})
		builder.addCase(getSPPList.rejected, state => {
			state.sppListIsLoading = false
			state.sppListError = "Spp list error..."
		})
		// get all notifications
		builder.addCase(getSPPNotifications.pending, state => {
			state.sppNotificationsIsLoading = true
		})
		builder.addCase(getSPPNotifications.fulfilled, (state, { payload }) => {
			state.sppNotificationsIsLoading = false
			state.sppNotifications = payload.results
			state.sppNotificationsError = null
		})
		builder.addCase(getSPPNotifications.rejected, state => {
			state.sppNotificationsIsLoading = false
			state.sppNotificationsError = "Spp notifications error..."
		})
		// get item notification
		builder.addCase(getSPPItemNotification.pending, state => {
			state.sppItemNotificationIsLoading = true
		})
		builder.addCase(getSPPItemNotification.fulfilled, (state, { payload }) => {
			state.sppItemNotificationIsLoading = false
			state.sppItemNotification = payload.results[0]
			state.sppItemNotificationError = null
		})
		builder.addCase(getSPPItemNotification.rejected, state => {
			state.sppItemNotificationIsLoading = false
			state.sppItemNotificationError = "Spp item error..."
		})
		// set bulk
		builder.addCase(setSPPList.pending, state => {
			state.setSppListInProcess = true
			state.setSppListSuccess = false
		})
		builder.addCase(setSPPList.fulfilled, state => {
			state.setSppListInProcess = false
			state.setSppListSuccess = true
			state.setSppListError = null
		})
		builder.addCase(setSPPList.rejected, state => {
			state.setSppListInProcess = false
			state.setSppListSuccess = false
			state.setSppListError = "Set spp list error..."
		})
		// set default
		builder.addCase(createSPPList.pending, state => {
			state.setSppListInProcess = true
			state.setSppListSuccess = false
		})
		builder.addCase(createSPPList.fulfilled, state => {
			state.setSppListInProcess = false
			state.setSppListSuccess = true
			state.setSppListError = null
		})
		builder.addCase(createSPPList.rejected, state => {
			state.setSppListInProcess = false
			state.setSppListSuccess = false
			state.setSppListError = "Set spp list error..."
		})

		builder.addCase(deleteSPPItems.pending, state => {
			state.deleteSPPItemsInProcess = true
			state.deleteSPPItemsSuccess = false
			
			state.isOpenDeleteModal = false
		})
		builder.addCase(deleteSPPItems.fulfilled, state => {
			state.deleteSPPItemsInProcess = false
			state.deleteSPPItemsSuccess = true
			state.deleteSPPItemsError = null
			
			state.isOpenDeleteModal = false
		})
		builder.addCase(deleteSPPItems.rejected, state => {
			state.deleteSPPItemsInProcess = false
			state.deleteSPPItemsSuccess = false
			state.deleteSPPItemsError = "Delete spp items error..."

			state.isOpenDeleteModal = false
		})






		builder.addCase(getSPPListLimits.pending, (state) => {
			state.limits.isLoading = true
		})
		builder.addCase(getSPPListLimits.fulfilled, (state, { payload }) => {
			state.limits.isLoading = false
			state.limits.usage_count = payload.usage_count
			state.limits.max_usage_count = payload.max_usage_count
			state.limits.exists_usage_count = payload.exists_usage_count
		})
		builder.addCase(getSPPListLimits.rejected, (state) => {
			state.limits.isLoading = false
		})
	},
})

export const sppActions = {
	...spp.actions,
	getSPPList,
	getSPPNotifications,
	getSPPItemNotification,
	setSPPList,
	createSPPList,
	deleteSPPItems,
}

export const {
	setCheckBoxAll,
	setTablePage,
	setSearchValue,
	setSearchRerender,
	setShowEdit,
	setSppEditItem,
	setCheckBox,
	setDeleteModal,
	setShowAddModal
} = spp.actions

export default spp.reducer
