import React, { Fragment } from "react";

import Container from "@mui/material/Container";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";

import Link from "root/components/Link";
import LinkPure from "root/components/LinkPure";

import styles from "./index.module.scss";

const codes = {
  500: {
    title: "Не удалось загрузить страницу",
    description: "Попробуйте обновить страницу позже.",
  },
  400: {
    title: "Ошибка на странице",
    description: "Попробуйте обновить страницу позже.",
  },
  403: {
    title: "Доступ запрещен",
    description:
      "У вашего пользователя нет доступа ни к одной оплаченной организации. Зайдите еще раз, проверив реквизиты входа.",
  },
  404: {
    title: "Страница не найдена",
    description: "В адресе есть ошибка или страница удалена.",
  },
  "403_group": {
    title: "Не удается открыть страницу покупки группового тарифа.",
    description:
      "Такое возможно, если у вас на текущий момент активен групповой тариф или вы уже находитесь в очереди на его покупку. Если вы считаете, что произошла ошибка, пожалуйста, свяжитесь с нашей службой поддержки в правом нижнем углу экрана и подробно опишите ситуацию.",
    code: 403,
  },
};

const ErrorPage = ({ code, defaultText = true }) => {
  const errorInfo = codes[code];

  return (
    <Fragment>
      <BodyClassName className={styles.body} />

      <Helmet>
        <meta name="theme-color" content="#191D36" />
        <title>{`Ошибка ${code}: ${errorInfo.title}`}</title>
      </Helmet>

      <Container maxWidth="xs">
        <div className={styles.header}>
          <LinkPure to="/">
            <img src={require("root/img/logo/logo-with-black-text.svg")} alt="" />
          </LinkPure>
        </div>

        <div className={styles.title}>
          {errorInfo.title}
          <div className={styles.badge}>{errorInfo.code || code}</div>
        </div>
        <div className={styles.description}>
          {errorInfo.description}
          {defaultText && (
            <>
              <br />
              <br />
              Если через час, после обновления страницы, ошибка повторится, напишите нам об этом в
              чат поддержки или на электронную почту <Link underline="always">
                info@service-analytic.com
              </Link>{" "}
              .{/*Если ошибка повторяется — напишите нам об этом в{" "}*/}
              {/*<Link underline="always">чат поддержки</Link> или на{" "}*/}
              {/*<Link underline="always">электронную почту</Link>.*/}
            </>
          )}
        </div>
      </Container>
    </Fragment>
  );
};

export default ErrorPage;
