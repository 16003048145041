import { reduce } from "ramda";

const defaultTooltipStart = "Шаблон будет применён для отзывов";
const templateSettingsData = [
  {
    id: "ai_enabled",
    title: "ИИ генерация ответа",
    tooltip: "Ответ будет генерироваться нейросетью",
    value: false
  },
  {
    id: "moderation_enabled",
    title: "Модерация ответов",
    tooltip: "Перед отправкой ответа на отзыв, он придёт к вам на модерацию",
    value: true
  },
  {
    id: "with_question_mark",
    title: "Вопрос в отзывах",
    tooltip: defaultTooltipStart + `, содержащих символ "?"`,
    value: false
  },
  {
    id: "with_photo",
    title: "Отзывы с фото",
    tooltip: defaultTooltipStart + " c фото",
    value: false
  },
  {
    id: "with_video",
    title: "Отзывы с видео",
    tooltip: defaultTooltipStart + " с видео",
    value: false
  },
  {
    id: "without_text",
    title: "Отзывы c текстом",
    tooltip: defaultTooltipStart + " c текстом",
    value: false
  }
  // {
  //   id: "with_text",
  //   title: "Отзывы с текстом",
  //   tooltip: defaultTooltipStart + " с текстом",
  //   value: false
  // }
];
const templateSettingsRenderItems = templateSettingsData.map(item => ({
  id: item.id,
  title: item.title,
  tooltip: item.tooltip
}));

const templateSettingsValues = templateSettingsData.reduce((acc, item) => {
  acc[item.id] = item.value;
  return acc;
}, {});

const convertServerDataToStateFormat = data => {
  const include_phrases = getStateObjFromArr(data.include_phrases);
  const exclude_phrases = getStateObjFromArr(data.exclude_phrases);
  const templateTargetObj = data.only_new
    ? {
        only_new: true,
        only_old_period: 30
      }
    : { only_new: false, only_old_period: data.only_old };
  return {
    is_active: data.is_active,
    name: data.name,
    delay_min: data.delay_min,
    ...templateTargetObj,
    include_phrases,
    exclude_phrases,
    include_brand_ids: data.include_brand_ids || [],
    include_product_ids: data.include_product_ids || [],
    include_subject_ids: data.include_subject_ids || [],
    templateSettings: {
      ...templateSettingsData.reduce((acc, item) => {
        acc[item.id] = item.id === "without_text" ? !data[item.id] : data[item.id];
        return acc;
      }, {})
      // with_text:
    },
    ratings: data.ratings,
    manual_answers: data.manual_answers
      ? data.manual_answers.map((item, index) => ({
          ...item,
          id: index + Math.random()
        }))
      : [],
    // recommendations: getStateObjFromArr(data.recommendations),
    recommendations: {
      isOn: !!data.recommendations?.length,
      list: data.recommendations
        ? data.recommendations.map((item, index) => ({
            ...item,
            recommendation: item.recommendation.trim(),
            id: index + Math.random()
          }))
        : []
    },
    spaceBetweenAnswerAndRecom: getSpaceBetweenAnswerAndRecom(data.recommendations),
    statistic: {
      state_all: data.state_all,
      state_on_moderation: data.state_on_moderation,
      state_on_response: data.state_on_response
    }
  };
};
const getStateObjFromArr = arr => ({
  isOn: !!arr?.length,
  list: arr || []
});
// const getCalculatedAnswersPriorityExceptOne = ()
const getCalculatedAnswersPriority = (
  arr = [{ answer: "v", proirity: 100 }],
  summPriority = 100
) => {
  const currentSummPriority = arr.reduce((acc, item) => acc + item.proirity, 0);
  return arr.map(item => ({
    ...item,
    proirity: (item.proirity / currentSummPriority) * summPriority
  }));
};

const getSpaceBetweenAnswerAndRecom = recomArr => {
  if (recomArr?.length) {
    const spaces = recomArr[0].recommendation.slice(0, 2);
    if (spaces === "\n\n") return "\n\n";
    if (spaces.slice(0, 1) === "\n") return "\n";
    if (spaces.slice(0, 1) === " ") return " ";
  }
  return "\n";
};

export { convertServerDataToStateFormat, templateSettingsRenderItems, templateSettingsValues };
