import request from "root/js/request";

const getAdvertsByDates = async ({
  wb_api_key_ids,
  q,
  date_from,
  date_to,
  advert_id__in,
  category_id__in,
  brand_id__in,
  supplier_article__in,
  subject_id__in,
  product_id__in,
  ...rest
}) => {
  const response = await request({
    url: "/api/analytics/advert_adverts_by_dates/",
    method: "get",
    params: {
      wb_api_key_ids,
      q,
      date_from,
      date_to,
      advert_id__in,
      category_id__in,
      brand_id__in,
      supplier_article__in,
      subject_id__in,
      product_id__in,
      ...rest,
    },
  });
  return response.data;
};

export { getAdvertsByDates };
