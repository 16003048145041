import React from "react"

import classnames from "classnames"

import styles from "./index.module.scss"

const ColorInTooltip = props => {
	const {color, name, subtitle, className, classes = {}, ...rest} = props

	return (
		<div
			{...rest}
			className={classnames(styles.root, className, classes.root)}
		>
			<div
				className={styles.dot}
				style={{
					backgroundColor: color,
				}}
			/>
			<div className={styles.info}>
				<div className={styles.name}>{name}</div>
				<div className={styles.subtitle}>{subtitle}</div>
			</div>
		</div>
	)
}

export default ColorInTooltip
