import React from "react";

import HintBox from "../../hint-box";

import styles from "./index.module.scss";
const DiagramHintBar = props => {
  const {
    article,
    articleText,
    product,
    titleValue,
    value,
    img,
    groupa,
    label,
    productNameText,
    titleValueSecond,
    valueSecond,
    titleValueThird,
    valueThird,
  } = props;
  return (
    <div className={styles.diagramDecription}>
      {groupa && label && (
        <div className={styles.labelContainer}>
          <span>
            {" "}
            {groupa} {label}{" "}
          </span>
        </div>
      )}
      <HintBox name={articleText} value={article || "—"} />

      {product && <HintBox name={productNameText} value={product} />}

      <HintBox name={titleValue} value={value} />

      {titleValueSecond && valueSecond && <HintBox name={titleValueSecond} value={valueSecond} />}
      {titleValueThird && valueThird && <HintBox name={titleValueThird} value={valueThird} />}

      {img && <HintBox img={img} />}
    </div>
  );
};

export default DiagramHintBar;
