import { createSlice } from "@reduxjs/toolkit";
import { equals, isEmpty, uniq } from "ramda";

const initialState = {
  products: {
    data: [],
    checkedItemsIds: [],
    isAllChecked: false,
  },
  "/growth-leaders-products": {
    count: 0,
    data: [],
    page: 1,
    isLoading: false,
    tableShow: false,
    tableRerender: 0,
    allCheckBoxes: [],
    perPage: 50,
    ordering: "-dynamic_proceeds_percent",
    checkBoxes: {
      1: {
        checkedAll: false,
        ids: [],
      },
    },
    ids: {
      1: [],
    },
  },
  "/growth-leaders-brands": {
    count: 0,
    data: [],
    page: 1,
    isLoading: false,
    tableShow: false,
    tableRerender: 0,
    allCheckBoxes: [],
    perPage: 50,
    ordering: "-dynamic_proceeds_percent",
    checkBoxes: {
      1: {
        checkedAll: false,
        ids: [],
      },
    },
    ids: {
      1: [],
    },
  },
  "/growth-leaders-sellers": {
    count: 0,
    data: [],
    page: 1,
    isLoading: false,
    tableShow: false,
    tableRerender: 0,
    allCheckBoxes: [],
    perPage: 50,
    ordering: "-dynamic_proceeds_percent",
    checkBoxes: {
      1: {
        checkedAll: false,
        ids: [],
      },
    },
    ids: {
      1: [],
    },
  },
  "/growth-leaders-categories": {
    count: 0,
    data: [],
    page: 1,
    isLoading: false,
    tableShow: false,
    tableRerender: 0,
    allCheckBoxes: [],
    perPage: 50,
    ordering: "-dynamic_proceeds_percent",
    checkBoxes: {
      1: {
        checkedAll: false,
        ids: [],
      },
    },
    ids: {
      1: [],
    },
  },
};

const bgArrTitles = [
  "dynamic_price_percent",
  "dynamic_orders_percent",
  "dynamic_lost_proceeds_percent",
  "dynamic_proceeds_percent",
];
const red_bg = "#FDE4EB";
const green_bg = "#E2F4EC";

const partsData = ({ data, oKey }) =>
  data?.map(item => {
    let obj = { ...item };
    if (equals("/growth-leaders-categories", oKey)) {
      obj = {
        ...item,
        level: 0,
        category_name: {
          name: item.full_name,
          id: item.id,
        },
      };
    }
    if (equals("/growth-leaders-brands", oKey)) {
      obj = {
        ...item,
        brand: {
          name: item.name,
          alias: item.alias,
          logo: item.logo,
          id: item.id,
        },
      };
    }
    if (equals("/growth-leaders-sellers", oKey)) {
      obj = {
        ...item,
        seller: {
          name: item.name,
          id: item.id,
        },
      };
    }
    if (equals("/growth-leaders-products", oKey)) {
      obj = {
        ...item,
        product: {
          name: item.name,
          image: item.image,
          id: item.id,
        },
      };
    }

    bgArrTitles.map(key => {
      obj[key] = {
        value: item[key],
        style: {
          background:
            Math.round(item[key]) === 0 ? undefined : Math.round(item[key]) > 0 ? green_bg : red_bg,
        },
      };
    });
    return obj;
  }) ?? [];

const growthLeadersSlice = createSlice({
  name: "growthLeaders",
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      state[payload.oKey].isLoading = payload?.isLoading;
    },
    showTable(state, { payload }) {
      state[payload.oKey].tableShow = payload?.tableShow;
    },
    rerenderTable(state, { payload }) {
      state[payload.oKey].tableRerender = state[payload.oKey].tableRerender + 1;
    },

    setCount(state, { payload }) {
      state[payload.oKey].count = payload.count;
    },
    setOrdering(state, { payload }) {
      state[payload.oKey].ordering = payload.e;
      state[payload.oKey].checkBoxes[payload.page] = {
        ...(state[payload.oKey].checkBoxes[payload.page] ?? {}),
        checkedAll: false,
      };
      state[payload.oKey].allCheckBoxes = [];
      state[payload.oKey].checkBoxes = {
        1: {
          checkedAll: false,
          ids: [],
        },
        [payload.page]: {
          checkedAll: false,
          ids: [],
        },
      };
    },
    setPage(state, { payload }) {
      state[payload.oKey].page = payload?.page ?? 1;
    },
    setData(state, { payload }) {
      // console.log({ payload });

      state[payload.oKey] = {
        ...state[payload.oKey],
        page: payload?.page ?? 1,
        ids: {
          ...(state[payload.oKey]?.ids ?? {}),
          [payload?.page ?? 1]: payload.data.map(item => item.id),
        },
        data: partsData(payload),
      };
    },
    checkAllAction(state, { payload }) {
      const { page = 1, bool = true, ids } = payload;

      state[payload.oKey].checkBoxes[page] = {
        ...(state[payload.oKey].checkBoxes[page] ?? {}),
        checkedAll: bool,
      };
      if (bool) {
        state[payload.oKey].checkBoxes[page].ids = ids;
      } else {
        state[payload.oKey].checkBoxes[page].ids = [];
      }

      if (bool) {
        state[payload.oKey].allCheckBoxes = uniq(
          state[payload.oKey].allCheckBoxes.concat(ids.filter(id => id))
        );
      } else {
        ids.forEach(id => {
          state[payload.oKey].allCheckBoxes = state[payload.oKey].allCheckBoxes.filter(
            value => value !== id
          );
        });
      }
    },
    resetCheckBoxes(state, { payload }) {
      state[payload.oKey].allCheckBoxes = [];
      state[payload.oKey].checkBoxes = {
        1: {
          checkedAll: false,
          ids: [],
        },
        [payload.page]: {
          checkedAll: false,
          ids: [],
        },
      };
    },
    checkAction(state, { payload }) {
      const { value, page = 1, bool = true } = payload;

      if (!state?.checkBoxes?.[page]) {
        state[payload.oKey].checkBoxes[page] = {
          ids: [],
          checkedAll: false,
        };
      } else {
        state[payload.oKey].checkBoxes[page].checkedAll = false;
      }
      if (bool) {
        state[payload.oKey].checkBoxes[page].ids.push(value);
        state[payload.oKey].allCheckBoxes.push(value);
      } else {
        state[payload.oKey].checkBoxes[page].ids = state[payload.oKey].checkBoxes[page].ids.filter(
          id => id !== value
        );
        state[payload.oKey].allCheckBoxes = state[payload.oKey].allCheckBoxes.filter(
          id => id !== value
        );
      }
    },
    growsLeadersToggleCheckedItem(state, { payload: { page, id } }) {
      if (state[page].checkedItemsIds.includes(id))
        state[page].checkedItemsIds = [...state[page].checkedItemsIds, id];
      else state[page].checkedItemsIds = state[page].checkedItemsIds.filter(i => i !== id);
    },
  },
});

export const {
  setCount,
  resetCheckBoxes,
  setData,
  setOrdering,
  setPage,
  checkAllAction,
  checkAction,
  showTable,
  rerenderTable,
  setIsLoading,
  growsLeadersToggleCheckedItem,
} = growthLeadersSlice.actions;

export default growthLeadersSlice.reducer;
