import React from "react"

import classNames from "classnames"

import Trips from "root/components/global/tips"

import Switch from "../../../Switch";
import Square from "../../icons/square"
import Triangle from "../../icons/triangle"

import { numberWithSpaces } from "root/js/utils"

import styles from "./index.module.scss"


const CardTwoProcents = props => {
  const {
    title,
    valueTitle,
    valueTrip,
    valueValuet,
    value,
    secondValueTitle,
    secondValueValuet,
    secondValue,
    secondResultPrecent,
    thirdValueTitle,
    thirdValueValuet,
    thirdValue,
    thirdResultPrecent,
    resultPrecent,
    secondValueTrip,
    thirdValueTrip,
    borderIncrement,
    borderDicrement,
    colorIncrement,
    colorDicrement,
    transformRotateIncrement,
    transformRotateDicrement,
    isSwitchBlock,
    titleSwitch

  } = props

  return (
    <div
      className={styles.cardTwoProcentsContainer}
      style={{
        borderTop:
          resultPrecent === Infinity || resultPrecent === -Infinity || resultPrecent === 0
            ? "4px solid #FFA500"
            : resultPrecent < 0
              ? borderDicrement
              : borderIncrement
      }}>
      <div>
        <h5> {title}</h5>
      </div>

      {isSwitchBlock && (
        <div className={styles.switcherBlock}>
          <span>{titleSwitch[0]}</span>
          <Switch className={styles.switch} />
          <span>{titleSwitch[1]}</span>
        </div>
      )}

      <div className={styles.data}>
        <div className={classNames("d-flex space-between", styles.alignItemsCenter)}>
          <span> {valueTitle}</span>
          {valueTrip && <Trips text={valueTrip} />}
        </div>
        <span className={styles.sum}>
          {valueValuet === "шт" ? numberWithSpaces(value) : numberWithSpaces(value?.toFixed(0))}{" "}
          {valueValuet}
        </span>
      </div>

      <div className={styles.resultPercentContainer}>
        <span
          style={{
            color:
              resultPrecent === Infinity || resultPrecent === -Infinity || resultPrecent === 0
                ? "#FFA500"
                : resultPrecent < 0
                  ? colorDicrement
                  : colorIncrement
          }}>
          {resultPrecent === Infinity || resultPrecent === -Infinity
            ? "-"
            : `${(resultPrecent * 100)?.toFixed(1)}%`}
        </span>

        {resultPrecent === Infinity || resultPrecent === -Infinity || resultPrecent === 0 ? (
          <Square />
        ) : (
          <Triangle
            result={resultPrecent}
            colorIncrement={colorIncrement}
            colorDicrement={colorDicrement}
            transformRotateIncrement={transformRotateIncrement}
            transformRotateDicrement={transformRotateDicrement}
          />
        )}
      </div>

      <div className={styles.data}>
        <div className={classNames("d-flex space-between", styles.alignItemsCenter)}>
          <span> {secondValueTitle} </span>
          {secondValueTrip && <Trips text={secondValueTrip} />}
        </div>
        <span className={styles.sum}>
          {secondValueValuet === "шт"
            ? numberWithSpaces(secondValue)
            : numberWithSpaces(secondValue?.toFixed(0))}{" "}
          {secondValueValuet}
        </span>
      </div>

      <div className={styles.resultPercentContainer}>
        <span
          style={{
            color:
              secondResultPrecent === Infinity ||
                secondResultPrecent === -Infinity ||
                secondResultPrecent === 0
                ? "#FFA500"
                : secondResultPrecent < 0
                  ? colorDicrement
                  : colorIncrement
          }}>
          {secondResultPrecent === Infinity || secondResultPrecent === -Infinity
            ? "-"
            : `${(secondResultPrecent * 100)?.toFixed(1)}%`}
        </span>

        {secondResultPrecent === Infinity ||
          secondResultPrecent === -Infinity ||
          secondResultPrecent === 0 ? (
          <Square />
        ) : (
          <Triangle
            result={secondResultPrecent}
            colorIncrement={colorIncrement}
            colorDicrement={colorDicrement}
            transformRotateIncrement={transformRotateIncrement}
            transformRotateDicrement={transformRotateDicrement}
          />
        )}
      </div>

      {thirdValueTitle && (
        <div className={styles.data}>
          <div className={classNames("d-flex space-between", styles.alignItemsCenter)}>
            <span> {thirdValueTitle} </span>
            {thirdValueTrip && <Trips text={thirdValueTrip} />}
          </div>
          <span className={styles.sum}>
            {thirdValueValuet === "шт"
              ? numberWithSpaces(thirdValue)
              : numberWithSpaces(thirdValue?.toFixed(0))}{" "}
            {thirdValueValuet}
          </span>
        </div>
      )}

      {thirdResultPrecent && (
        <div className={styles.resultPercentContainer}>
          <span
            style={{
              color:
                thirdResultPrecent === Infinity ||
                  thirdResultPrecent === -Infinity ||
                  thirdResultPrecent === 0
                  ? "#FFA500"
                  : thirdResultPrecent < 0
                    ? colorDicrement
                    : colorIncrement
            }}>
            {thirdResultPrecent === Infinity || thirdResultPrecent === -Infinity
              ? "-"
              : `${(thirdResultPrecent * 100)?.toFixed(1)}%`}
          </span>

          {thirdResultPrecent === Infinity ||
            thirdResultPrecent === -Infinity ||
            thirdResultPrecent === 0 ? (
            <Square />
          ) : (
            <Triangle
              result={thirdResultPrecent}
              colorIncrement={colorIncrement}
              colorDicrement={colorDicrement}
              transformRotateIncrement={transformRotateIncrement}
              transformRotateDicrement={transformRotateDicrement}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CardTwoProcents
