import { createSlice } from "@reduxjs/toolkit"
import { uniq } from "ramda"

const initialState = {
	formState: null,
	amount: 0,
	brand: {},
	page: 1,
	checkBoxes: {
		1: {
			checkedAll: false,
			ids: []
		},

	},
	ids: {
		1: []
	},
	allCheckBoxes: []
}

const sellerAnalyzerSlice = createSlice({
	name: "sellerAnalyzer",
	initialState,
	reducers: {
		clearState(state) {
			state.page = 1
			state.checkBoxes = {
				1: {
					checkedAll: false,
					ids: []
				}
			}
			state.ids = {
				1: []
			},
				state.allCheckBoxes = []
		},
		setPrevForm(state, { payload }) {
			state.formState = payload.formState
			state.amount = payload.amount
			state.brand = payload.brand
		},
		// !------------checkbox
		//!--ids
		getDataIds(state, { payload }) {
			const { page = 1, data = [] } = payload
			const ids = data.map(({ id }) => id)
			state.ids[page] = ids
			state.page = page
		},
		//!--checkAll
		checkAllAction(state, { payload }) {
			const { page = 1, bool = true, ids } = payload

			state.checkBoxes[page] = {
				...(state.checkBoxes[page] ?? {}),
				checkedAll: bool,
			}
			if (bool) {

				state.checkBoxes[page].ids = ids
			} else {
				state.checkBoxes[page].ids = []
			}

			if (bool) {
				state.allCheckBoxes = uniq(state.allCheckBoxes.concat(ids.filter(id => id)))
			} else {
				ids.forEach(id => {
					state.allCheckBoxes = state.allCheckBoxes.filter(value => value !== id)
				})
			}
		},
		checkAction(state, { payload }) {
			const { value, page = 1, bool = true } = payload

			if (!state?.checkBoxes?.[page]) {
				state.checkBoxes[page] = {
					ids: [],
					checkedAll: false
				}
			} else {
				state.checkBoxes[page].checkedAll = false
			}
			if (bool) {
				state.checkBoxes[page].ids.push(value)
				state.allCheckBoxes.push(value)
			} else {
				state.checkBoxes[page].ids = state.checkBoxes[page].ids.filter(id => id !== value)
				state.allCheckBoxes = state.allCheckBoxes.filter(id => id !== value)
			}
		}
	},
})

export const {
	setPrevForm,
	getDataIds,
	checkAllAction,
	checkAction,
	clearState
} = sellerAnalyzerSlice.actions

export default sellerAnalyzerSlice.reducer
