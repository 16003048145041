import React, { useState } from "react";

import { Paper } from "@mui/material";
import { isNil, prop } from "ramda";

import IALineChartLocf from "root/components/Charts/IALineChartLocf";

import { getAxisDate } from "root/js/utils";

const ReturnDiagram = ({ tab, riteReturnsDiagram }) => {
  const [isCheckOne, setIsCheckOne] = useState(true);
  const [isCheckTwo, setIsCheckTwo] = useState(true);
  const titleValue =
    tab === "ride"
      ? "Стоимость доставки нереализованных товаров в динамике в заданном периоде"
      : "Количество доставок нереализованных товаров в динамике в заданном периоде";

  const typeValue =
    tab === "ride"
      ? "Стоимость доставки нереализованных товаров"
      : "Количество доставок нереализованных товаров";

  const currency = tab === "ride" ? "руб" : "шт";

  const prepareDataReturns = elem => ({
    date: `${new Date(prop("date", elem)).getDate()} ${new Date(prop("date", elem)).toLocaleString(
      "ru-RU",
      { month: "short" }
    )}`,
    dateTick: getAxisDate(elem.date),
    checkOnePress: !isNil(elem.unsold_products_delivery) ? elem.unsold_products_delivery : null,
    checkTwoPress: !isNil(elem.ride_share) ? elem.ride_share : null,
  });

  const formattedData = riteReturnsDiagram?.map(item => {
    return prepareDataReturns(item);
  });

  const generateComponentDiagram = (data, currencyName, currencyNameTwo) => {
    return (
      <IALineChartLocf
        title={titleValue}
        data={data}
        currencyName={currencyName}
        currencyNameTwo={currencyNameTwo}
        isCheckOne={isCheckOne}
        isCheckTwo={isCheckTwo}
        setIsCheckOne={setIsCheckOne}
        setIsCheckTwo={setIsCheckTwo}
        labelOne={typeValue}
        labelTwo={"Доля покатушек по отношению к прямым доставкам"}
        checkOne={true}
        checkTwo={true}
        colorOne={"#33CAA6"}
        colorTwo={"#7D3ECE"}
        isBarOne={true}
        YAxisLeftColor={true}
        isShowYAxisRight={true}
      />
    );
  };

  return (
    <Paper
      elevation={0}
      style={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}>
      {generateComponentDiagram(formattedData, currency, "%")}
    </Paper>
  );
};

export default ReturnDiagram;
