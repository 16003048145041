import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { ClickAwayListener, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import classNames from "classnames";
import { addDays, format } from "date-fns";
import sub from "date-fns/sub";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import { defaultTo, equals } from "ramda";
import { DateRange, DefinedRange } from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import { useSelector } from "react-redux";
import { dateRangeToDays, getAvailableDaysBack, isMobile } from "root/js/utils.js";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import styles from "./index.module.scss";

const dateFormat = (v, text) => (v ? format(v, "dd.MM.yyyy") : text);

const customRuLocale = {
  ...rdrLocales.ru,
  localize: {
    ...rdrLocales.ru.localize,
    month: n =>
      [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ][n],
    day: n => ["вс.", "пн.", "вт.", "ср.", "чт.", "пт.", "сб."][n],
  },
};

const defaultDefinedRanges = [
  {
    label: "7 дней",
    value: 7,
    tariffsIds: [1, 5, 2, 3, 4],
  },
  {
    label: "14 дней",
    value: 14,
    tariffsIds: [1, 5, 2, 3, 4],
  },
  {
    label: "30 дней",
    value: 30,
    tariffsIds: [1, 5, 2, 3, 4],
  },
  {
    label: "90 дней",
    value: 90,
    tariffsIds: [1, 5, 3, 4],
  },
  {
    label: "180 дней",
    value: 180,
    tariffsIds: [1, 5, 4],
  },
];

const defaultPeriodList = [
  { title: "7 дней", value: 7 },
  { title: "14 дней", value: 14 },
  { title: "30 дней", value: 30 },
  { title: "90 дней", value: 90 },
  { title: "180 дней", value: 180 },
];

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const localDaysBack = zonedTimeToUtc(new Date(), timeZone).getHours() >= 10 ? 1 : 2;

const DatePickerField = ({
  value = [],
  onChange: onChangeProps = e => e,
  resetButton = false,
  open,
  setIsOpen,
  minDate = new window.Date("03/01/22"),
  maxDate: parentMaxDate,
  freeMaxDate = false,
  error30 = false,
  calculateLocal,
  noDaysBack = false,
  periodListProp = defaultPeriodList,
  periodProps = 30,
  setPeriodProps = e => e,
  showPlaceHolder = false,
  changeIsShowPlaceHolder = e => e,
}) => {
  const tariff = useSelector(store => store.userTariff);
  const [rangeFocusChangeStatus, setRangeFocusChangeStatus] = useState([1, 1]);
  const [period, setPeriod] = useState(periodProps);

  useLayoutEffect(() => {
    setSelectionRange({
      startDate: value[0],
      endDate: value[1],
      key: "selection",
    });
  }, [value]);

  useLayoutEffect(() => {
    setPeriod(periodProps);
  }, [periodProps]);

  //
  const haveLastAvailableDate = useSelector(s => s.profile.config?.date_limit?.date_limit_enable);
  const lastAvailableDate = useSelector(s => s.profile.config?.date_limit?.date_limit_date_to);
  const maxDate = useMemo(() => {
    if (freeMaxDate) return new Date();
    if (!haveLastAvailableDate) return parentMaxDate;
    return new Date(lastAvailableDate.split(".").reverse().join("-"));
  }, [parentMaxDate, lastAvailableDate, freeMaxDate]);
  const daysBack = useMemo(() => {
    if (freeMaxDate) return 0;
    if (!haveLastAvailableDate || !lastAvailableDate) return getAvailableDaysBack();
    const dayBackCount = (new Date() - maxDate) / (24 * 3600 * 1000);
    return Math.round(dayBackCount);
  }, [haveLastAvailableDate, lastAvailableDate, maxDate, freeMaxDate]);
  //
  const definedRanges = useMemo(() => {
    return defaultDefinedRanges;
  }, [defaultDefinedRanges]);
  //

  // const daysBack = getAvailableDaysBack();
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  useLayoutEffect(() => {
    setSelectionRange({
      startDate: value?.[0] ?? new Date(),
      endDate: value?.[1] ?? new Date(),
      key: "selection",
    });
  }, [value]);

  const onChange = ranges => {
    const { selection } = ranges;
    setSelectionRange(selection);
    onChangeProps([selection.startDate, selection.endDate]);
    changeIsShowPlaceHolder(true);
    if (equals(rangeFocusChangeStatus, [0, 1])) {
      setPeriod("");
      setPeriodProps("");
      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    }
  };

  const onOpen = () => setIsOpen(true);
  const onReset = () => onChangeProps([null, null]);

  const handleDefinedRangeChange = range => {
    const { selection } = range;
    setPeriod(selection.type);
    setPeriodProps(selection.type);
    setSelectionRange(selection);
    onChangeProps([selection.startDate, selection.endDate]);
    changeIsShowPlaceHolder(true);
    setTimeout(() => {
      setRangeFocusChangeStatus([1, 1]);
      setIsOpen(false);
    }, 1000);
  };

  const defaultMaxDate = defaultTo(
    // calculateLocal ? sub(new Date(zonedTimeToUtc(new Date(), timeZone, "yyyy-MM-dd")), {
    //   days: noDaysBack ? 0 : localDaysBack,
    // }) :
    sub(new Date(formatInTimeZone(new Date(), "Europe/Moscow", "yyyy-MM-dd")), {
      days: noDaysBack ? 0 : daysBack,
    })
  );

  useEffect(() => {
    if (value[1] > maxDate) {
      if (value[0] >= maxDate) {
        const period = value[1] - value[0];
        onChangeProps([new Date(maxDate - period), maxDate]);
      } else {
        onChangeProps([value[0], maxDate]);
      }
    }
  }, [maxDate, value]);

  const dateMaxBorders = useMemo(() => {
    if ([14, 4].includes(tariff.id)) {
      if (equals(rangeFocusChangeStatus, [0, 1])) {
        const newMaxDate = new Date(
          new Date(selectionRange.startDate).setDate(
            new Date(selectionRange.startDate).getDate() + 365
          )
        );
        if (new Date(newMaxDate) > new Date(defaultMaxDate(maxDate))) {
          return defaultMaxDate(maxDate);
        }
        return newMaxDate;
      }
      if (equals(rangeFocusChangeStatus, [1, 1])) {
        return defaultMaxDate(maxDate);
      }
    }
    if ([5].includes(tariff.id)) {
      if (equals(rangeFocusChangeStatus, [0, 1])) {
        const newMaxDate = new Date(
          new Date(selectionRange.startDate).setDate(
            new Date(selectionRange.startDate).getDate() + 90
          )
        );
        if (new Date(newMaxDate) > new Date(defaultMaxDate(maxDate))) {
          return defaultMaxDate(maxDate);
        }
        return newMaxDate;
      }
      if (equals(rangeFocusChangeStatus, [1, 1])) {
        return defaultMaxDate(maxDate);
      }
    }

    return defaultMaxDate(maxDate);
  }, [maxDate, rangeFocusChangeStatus, selectionRange]);

  const newMinDate = error30 ? sub(new Date(), { days: 90 }) : minDate;

  const dateMinBorders = useMemo(() => {
    if ([14, 4, 16].includes(tariff.id)) {
      if (equals(rangeFocusChangeStatus, [0, 1])) {
        const minDate = new Date(
          new Date(selectionRange.startDate).setDate(
            new Date(selectionRange.startDate).getDate() - 365
          )
        );
        if (new Date(newMinDate) > new Date(minDate)) {
          return newMinDate;
        }
        return minDate;
      }
      if (equals(rangeFocusChangeStatus, [1, 1])) {
        return newMinDate;
      }
    }
    if ([5, 1].includes(tariff.id)) {
      if (equals(rangeFocusChangeStatus, [0, 1])) {
        const minDate = new Date(
          new Date(selectionRange.startDate).setDate(
            new Date(selectionRange.startDate).getDate() - 90
          )
        );
        if (new Date(newMinDate) > new Date(minDate)) {
          return newMinDate;
        }
        return minDate;
      }
      if (equals(rangeFocusChangeStatus, [1, 1])) {
        return newMinDate;
      }
    }

    return newMinDate;
  }, [newMinDate, rangeFocusChangeStatus, selectionRange, minDate]);

  useLayoutEffect(() => {
    const inputRangesElement = document.querySelector(".rdrInputRanges");
    const rdrMonthsHorizontal = document.querySelector(".rdrMonthsHorizontal");
    const rdrDateDisplayWrapper = document.querySelector(".rdrDateDisplayWrapper");

    if (rdrMonthsHorizontal) {
      rdrMonthsHorizontal.style.scrollbarColor = "#e12da9";
      rdrMonthsHorizontal.style.overflowY = "hidden";
    }

    if (rdrDateDisplayWrapper) rdrDateDisplayWrapper.remove();
    if (inputRangesElement) inputRangesElement.remove();
  }, [open, selectionRange]);

  const availablePeriods = useMemo(() => {
    if (!minDate) return definedRanges;
    return definedRanges
      .filter(
        item => item.value <= dateRangeToDays(minDate, sub(new Date(), { days: daysBack })) + 1
      )
      .filter(item => periodListProp?.map(({ value }) => value).includes(item?.value));
  }, [definedRanges, minDate]);

  useEffect(() => {
    [
      ...availablePeriods.map(({ label, value }) => ({
        label,
        hasCustomRendering: false,
        range: () => ({
          type: value,
          startDate: addDays(defaultMaxDate(maxDate), Number(`-${value - 1}`)),
          endDate: defaultMaxDate(maxDate),
        }),
        isSelected: () => period == value,
        value,
      })),
    ].map(item => {
      const { startDate, endDate } = item.range();
      if (
        value?.[0] &&
        value?.[1] &&
        format(startDate, "dd.MM.yyyy") == format(value?.[0], "dd.MM.yyyy") &&
        format(endDate, "dd.MM.yyyy") == format(value?.[1], "dd.MM.yyyy")
      ) {
        setPeriodProps(item.value);
        setPeriod(item.value);
      }
    });
  }, [availablePeriods]);

  const isScreenWidthMd = useMediaQuery("(max-width: 960px)");
  const isScreenWidthSM = useMediaQuery("(max-width: 630px)");

  const changePeriodSelect = () => {
    setPeriod("");
    setPeriodProps("");
    setSelectionRange({
      startDate: dateMaxBorders,
      endDate: dateMaxBorders,
      key: "selection",
    });
    changeIsShowPlaceHolder(true);
    onChangeProps([dateMaxBorders, dateMaxBorders]);
    setTimeout(() => {
      setRangeFocusChangeStatus([1, 1]);
    }, 1000);
    //
    // setIsOpen(false)
  };

  const screens = useMemo(() => {
    if (
      new window.Date(dateMinBorders).getMonth() == new window.Date(dateMaxBorders).getMonth() &&
      new window.Date(dateMinBorders).getFullYear() == new window.Date(dateMaxBorders).getFullYear()
    ) {
      return 1;
    }
    return isMobile || isScreenWidthMd ? 1 : 2;
  }, [isMobile, isScreenWidthMd, dateMinBorders, dateMaxBorders]);

  return (
    <Box position="relative">
      <Tooltip
        PopperProps={{ disablePortal: true }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        className={styles.tooltip}
        open={open}
        title={
          <Box className={styles.root_dateRange}>
            <ClickAwayListener
              onClickAway={() => {
                setIsOpen(false);
                setRangeFocusChangeStatus([1, 1]);
              }}>
              <Box className={styles.dateRange_box}>
                <Box
                  className={styles.calendarWrapper}
                  // display={isScreenWidthMd ? "grid" : "flex"}
                >
                  {periodListProp && (
                    <Box width={"100%"}>
                      {isMobile || isScreenWidthSM ? null : (
                        <Box
                          borderBottom={"1px solid #eff2f7"}
                          fontWeight={400}
                          fontSize={14}
                          color={"#000"}
                          pl={"16px"}
                          pb={"20px"}
                          pt={"25px"}
                          mb={1}>
                          Период
                        </Box>
                      )}
                      <DefinedRange
                        onChange={handleDefinedRangeChange}
                        ranges={[selectionRange]}
                        locale={rdrLocales.ru}
                        className={styles.DefinedRange}
                        style={
                          isScreenWidthMd
                            ? {
                                display: "flex",
                              }
                            : {}
                        }
                        // direction="vertical"
                        renderStaticRangeLabel={({ label }) => label}
                        staticRanges={[
                          ...availablePeriods.map(({ label, value }) => ({
                            label,
                            hasCustomRendering: false,
                            range: () => ({
                              type: value,
                              startDate: addDays(defaultMaxDate(maxDate), Number(`-${value - 1}`)),
                              endDate: defaultMaxDate(maxDate),
                            }),
                            isSelected: () => period == value,
                          })),
                        ]}
                        rangeColors={["#cf3aa0"]}
                      />
                      {isMobile || isScreenWidthSM ? null : (
                        <Box
                          color={period == "" && "#cf3aa0 !important"}
                          fontWeight={period == "" && "900 !important"}
                          onClick={changePeriodSelect}
                          className={styles.periodSelect}>
                          Произвольный период
                        </Box>
                      )}
                    </Box>
                  )}
                  <DateRange
                    onRangeFocusChange={setRangeFocusChangeStatus}
                    // locale={rdrLocales.ru}
                    locale={customRuLocale}
                    editableDateInputs={true}
                    onChange={onChange}
                    showSelectionPreview={false}
                    moveRangeOnFirstSelection={false}
                    preventSnapRefocus={false}
                    // calendarFocus={
                    //     equals(rangeFocusChangeStatus, [0, 1]) ? "forwards" :
                    //     "backwards"
                    // }
                    months={screens}
                    ranges={[selectionRange]}
                    direction="horizontal"
                    className={styles["custom-date-range-picker"]}
                    scroll={{ enabled: false, amount: 1 }}
                    rangeColors={["#cf3aa0"]}
                    maxDate={dateMaxBorders}
                    minDate={dateMinBorders}
                  />
                </Box>
              </Box>
            </ClickAwayListener>
          </Box>
        }>
        <Box display="flex" alignItems="center" onClick={onOpen}>
          <Box className={classNames(styles.rangeInput, styles.leftInput)}>
            <Box
              component="span"
              color={value[0] ? undefined : "#798097"}
              pt={value[0] ? "2px" : undefined}>
              {showPlaceHolder ? (
                <Box component="span">Выберите дату</Box>
              ) : (
                dateFormat(value[0], "Выберите дату") +
                (value[0] ? " — " : "") +
                dateFormat(value[1], "")
              )}
            </Box>
            {(value[0] || value[1]) && resetButton && (
              <Box ml="4px" component="span" className={styles.resetIconWrapper}>
                <IconButton onClick={onReset} className={styles.clearButton}>
                  <ClearIcon className={styles.clearIcon} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default DatePickerField;
