import request from "root/js/request";

export const getSharesTableExport = async ({
  supplier_article,
  product_id,
  cabinet_id,
  shares_id,
  gross_margin,
  marginality,
  pred_quantity,
  days_of_stock,
  sales_amount,
  category_id,
  turnover,
  current_retail_price,
  synthetic_abc_group_label,
  date_on_site,
  ed,
  cost,
  is_level,
  subject_id,
  in_shares,
}) => {
  // Базовые параметры, которые всегда передаются
  const params = {
    cabinet_id,
    shares_id,
    supplier_article__icontains: supplier_article || undefined,
    product_id__icontains: product_id || undefined,
    synthetic_abc_group_label__in: Array.isArray(synthetic_abc_group_label)
      ? synthetic_abc_group_label
      : undefined,
    category_id,
    subject_id__in: Array.isArray(subject_id) ? subject_id : undefined,
    in_shares,
    is_level,
  };

  // Определяем параметр запроса в зависимости от того входят ли товары в акцию или нет
  const suffix = in_shares ? "_in" : "_out";

  // Обработка параметров с gte и lte
  if (gross_margin) {
    params[`gross_margin${suffix}_gte`] = gross_margin.gte;
    params[`gross_margin${suffix}_lte`] = gross_margin.lte;
  }

  if (marginality) {
    params[`marginality${suffix}_gte`] = marginality.gte;
    params[`marginality${suffix}_lte`] = marginality.lte;
  }

  if (pred_quantity) {
    params[`pred_quantity${suffix}_gte`] = pred_quantity.gte;
    params[`pred_quantity${suffix}_lte`] = pred_quantity.lte;
  }

  if (days_of_stock) {
    params[`days_of_stock${suffix}_gte`] = days_of_stock.gte;
    params[`days_of_stock${suffix}_lte`] = days_of_stock.lte;
  }

  if (sales_amount) {
    params[`sales_amount${suffix}_gte`] = sales_amount.gte;
    params[`sales_amount${suffix}_lte`] = sales_amount.lte;
  }

  // Общие параметры без суффиксов
  if (turnover) {
    params.turnover_gte = turnover.gte;
    params.turnover_lte = turnover.lte;
  }

  if (current_retail_price) {
    params.current_retail_price_gte = current_retail_price.gte;
    params.current_retail_price_lte = current_retail_price.lte;
  }

  if (date_on_site) {
    params.date_on_site_gte = date_on_site.gte;
    params.date_on_site_lte = date_on_site.lte;
  }

  if (ed) {
    params.ed_gte = ed.gte;
    params.ed_lte = ed.lte;
  }

  if (cost) {
    params.cost_gte = cost.gte;
    params.cost_lte = cost.lte;
  }

  const response = await request({
    url: `/analytics/shares_table/export/`,
    method: "get",
    params: params,
  });
  return response;
};
