import React from "react";

import { Bar, BarChart, Cell, Tooltip } from "recharts";

import ChartTooltipMUI from "root/components/Chart/ChartTooltipMUI";

import { calcColor, getTrendlineEq, hexToRgb, palette, randStr } from "root/js/utils";

const BarGraph_ = props => {
  const {
    dataKey,
    dataIndex = false,
    width = 118,
    reverse = false,
    height = 42,
    data: dataProp,
    tooltipPostfix,
    color,
    isTrendColor,
    toFixedNum = 0, // определяет округление значений в подсказке
  } = props;

  const length = dataProp?.length;
  const data = Array.isArray(dataProp)
    ? dataProp.map((item, ind) => ({
        ...item,
        rawData: item[dataKey],
        [dataKey]: parseFloat(item[dataKey]),
        offset: ind / length
      }))
    : [];

  let colorStart, colorEnd;
  const colorUv = randStr({
    length: 12,
    charset: "alphabetic"
  });

  if (isTrendColor) {
    const trendValue = getTrendlineEq(dataProp, dataKey);
    if (trendValue > 0) {
      colorStart = palette.trendPink;
      colorEnd = palette.trendGreen;
    } else if (trendValue < 0) {
      colorStart = palette.trendGreen;
      colorEnd = palette.trendPink;
    } else {
      colorStart = palette.blue;
      colorEnd = palette.blue;
    }
  } else {
    let direction;
    if (length >= 2) {
      const firstDay = data[0][dataKey];
      const lastDay = data[length - 1][dataKey];
      if (firstDay < lastDay) {
        direction = "up";
      } else if (firstDay === lastDay) {
        direction = "forward";
      } else {
        direction = "down";
      }
    } else {
      direction = "forward";
    }
    colorStart = {
      up: palette.pastelGreen,
      down: palette.magentaPink,
      forward: palette.blue
    }[direction];
    colorEnd = {
      up: palette.pastelGreen,
      down: palette.darkModerateMagneta,
      forward: palette.blue
    }[direction];
  }

  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{ top: 3, right: 0, bottom: 3, left: 0 }}>
      {!isTrendColor ? (
        <defs>
          <linearGradient id={colorUv} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={reverse ? colorStart : colorEnd} />
            <stop offset="100%" stopColor={reverse ? colorEnd : colorStart} />
          </linearGradient>
        </defs>
      ) : null}
      <Tooltip
        offset={-5}
        position={{ y: 0 }}
        allowEscapeViewBox={{ x: true }}
        isAnimationActive={false}
        content={({ active, payload }) => (
          <ChartTooltipMUI
            dataIndex={dataIndex}
            active={active}
            payload={payload}
            tooltipPostfix={tooltipPostfix}
            toFixedNum={toFixedNum}
          />
        )}
      />
      <Bar
        //animationDuration={900}
        isAnimationActive={false}
        strokeWidth={2}
        type="monotone"
        dataKey={dataKey}
        fillOpacity={1}
        minPointSize={1}>
        {data.map((entry, index) => {
          if (isTrendColor) {
            return (
              <Cell
                key={`cell-${index}`}
                fill={calcColor(entry.offset, hexToRgb(colorStart), hexToRgb(colorEnd), 1)}
              />
            );
          } else {
            return <Cell key={`cell-${index}`} fill={color ? color : `url(#${colorUv})`} />;
          }
        })}
      </Bar>
    </BarChart>
  );
};

export default BarGraph_;
