import React, { useEffect, useMemo } from "react";

import { Box, Typography } from "@mui/material";

import Tooltip_ from "root/components/Tooltip";

import { externalInfluenceOptions, isMatterOuterTraffic } from "./utils";

// --------------------------------------------------------------------------------------------
const ExternalInfluenceIndicator = ({ dynamic, title = "Влияние внешки на выручку за период" }) => {
  const externalInfluence = useMemo(() => isMatterOuterTraffic(dynamic), [dynamic]);
  const showExternalInfluence = useMemo(() => {
    return externalInfluenceOptions.high === externalInfluence?.props?.children;
  }, [externalInfluence]);
  const summaryFrequency = useMemo(() => {
    const sum = dynamic.reduce((acc, item) => {
      acc += item.product_ads;
      return acc;
    }, 0);
    // console.log(dynamic, sum);
    return sum;
  }, [dynamic]);
  if (!showExternalInfluence) return null;
  if (summaryFrequency < 500) return null;
  return (
    <Tooltip_ title="Показатель корреляции ключевых метрик артикула с результатами продвижения через внешний трафик от специально обученной модели ИИ.">
      <Box pr={2} sx={{ cursor: "pointer" }}>
        <Typography>
          <b>{title}</b> - {externalInfluence}
        </Typography>
      </Box>
    </Tooltip_>
  );
};

export default ExternalInfluenceIndicator;
