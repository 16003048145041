import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "root/js/api";

const WBAPI = new API();
export const GetCompanyWBCabinets = createAsyncThunk("get/company/WB", async () => {
  const response = await WBAPI.getCompanyWB();

  return response;
});

export const CreateCabinetsWB = createAsyncThunk(
  "create/cabinets/WN",
  async ({ name, is_shared }) => {
    const response = await WBAPI.createWBCabinets({ name, is_shared });
    return response;
  }
);

// export const GetWBCabintet =createAsyncThunk(
//   "get/WB/cabinter",
//   async({CABINET_ID})=>{
//     const response =  await WBAPI.getWBcabinet({CABINET_ID})

//   }
// )

export const DeleteWBCabinet = createAsyncThunk("delete/WB/cabinet", async ({ ID }) => {
  const response = await WBAPI.deleteWbCabinet({ ID });
  return response;
});

export const UpdateWBCabinet = createAsyncThunk(
  "update/wb/cabinet",
  async ({ ID, name, is_shared }) => {
    const res = await WBAPI.updateWBCabinet({ ID, name, is_shared });
    return res;
  }
);

export const SalesPlanSettings = createAsyncThunk(
  "settings/sales/plan",
  async ({ wb_api_key_ids }) => {
    const response = await WBAPI.salesPlanSettings({ wb_api_key_ids });
    return response;
  }
);

export const SalesPlanCompany = createAsyncThunk(
  "sales/plan/company",
  async ({ wb_api_key_id }) => {
    const response = await WBAPI.salesPlanCompany({ wb_api_key_id });
    return response;
  }
);

export const CreateSalesPlanCompany = createAsyncThunk(
  "create/sales/company",
  async ({
    wb_api_key_id,
    realization_value_share_diff,
    formattedDate,
    marketplace,
    category,
    gross_margin,
    gross_margin_share_diff,
    realization_value
  }) => {
    const response = await WBAPI.createSalesCompany({
      wb_api_key_id,
      formattedDate,
      realization_value_share_diff,
      marketplace,
      category,
      gross_margin,
      gross_margin_share_diff,
      realization_value
    });
    return response;
  }
);

export const DeleteSalesPlanCompany = createAsyncThunk(
  "delete/sales/company",
  async ({ wb_api_key_id, ID, destroy_all_subplan }) => {
    const response = await WBAPI.deleteSalesCompany({
      ID,
      wb_api_key_id,
      destroy_all_subplan
    });
    return response;
  }
);

export const EditSalesPlanCompany = createAsyncThunk(
  "edit/sales/company",
  async ({ ID,
    month,
    marketplace,
    category,
    gross_margin_share_diff,
    gross_margin,
    realization_value_share_diff,
    realization_value,
    wb_api_key_id }) => {
    const response = await WBAPI.editSalesPlanCompany({
      ID,
      month,
      marketplace,
      category,
      gross_margin_share_diff,
      gross_margin,
      realization_value_share_diff,
      realization_value,
      wb_api_key_id
    });
    return response
  }
);


export const CategorySalesPlan = createAsyncThunk(
  "category/sales/plan",
  async({wb_api_key_id})=>{
    const response = await WBAPI.categorySalesPlan({wb_api_key_id})
    return response.results
  }
)

export const GetTMCSettings = createAsyncThunk(
  "analytics/settings/",
  async({wb_api_key_id})=>{
    const response = await WBAPI.getTMCSettings({wb_api_key_id})
    return response;
  }
)

export const GetEvents = createAsyncThunk(
  "analytics/events/",
  async(wb_api_key_id)=>{
    const response = await WBAPI.getEvents(wb_api_key_id)
    return response;
  }
)

export const GetGlobalEvents = createAsyncThunk(
  "analytics/global/",
  async()=>{
    const response = await WBAPI.getGlobalEvents()
    return response;
  }
)

export const SaveEvent = createAsyncThunk(
  "analytics/addEvent/",
  async({ cabinetId, name, date, isRegular, isActive })=>{
    const response = await WBAPI.saveEvent({ cabinetId, name, date, isRegular, isActive })
    return response;
  }
)

export const UpdateEvent = createAsyncThunk(
  "analytics/updateEvent/",
  async({ ID, name, date, isRegular, isActive })=>{
    const response = await WBAPI.updateEvent({ ID, name, date, isRegular, isActive })
    return response;
  }
)

export const DeleteEvent = createAsyncThunk(
  "analytics/deleteEvent/",
  async({ ID })=>{
    const response = await WBAPI.deleteEvent({ ID })
    return response;
  }
)

