import {
  dzen,
  instagram,
  instagram_empty,
  telegram,
  tiktok,
  vkontakte,
  youtube
} from "root/icons/socialNetworksIcons";
import {
  dDzen,
  dInstagram,
  dTelegram,
  dTikTok,
  dVkontakte,
  dYouTube
} from "root/icons/socialNetworksIcons/disabled";

const socialNetworksImages = {
  dzen: {
    default: dzen,
    disabled: dDzen
  },
  instagram: {
    default: instagram,
    disabled: dInstagram,
    tooltip: "* Принадлежит компании Meta, которая признана экстремистской организацией в России."
  },
  telegram: {
    default: telegram,
    disabled: dTelegram
  },
  tiktok: {
    default: tiktok,
    disabled: dTikTok
  },
  vkontakte: {
    default: vkontakte,
    disabled: dVkontakte
  },
  youtube: {
    default: youtube,
    disabled: dYouTube
  }
};

export { socialNetworksImages };
