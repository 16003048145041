import request from "root/js/request";

export const getWBDynamicProductsTrendGrouped = async ({
  collection_id,
  product_ids,
  extra_fields,
  date_from,
  date_to,
  period,
  predict_trend,
  predict_trand = predict_trend,
  ...rest
}) => {
  const response = await request({
    url: `/wb_dynamic/products_trend/grouped/`,
    method: "get",
    params: {
      collection_id,
      product_ids,
      extra_fields,
      date_from,
      date_to,
      period,
      predict_trand,
      ...rest,
    },
  });
  return response.data;
};
