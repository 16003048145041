import request from "root/js/request";

const getTaxRates = async ({ wb_api_key_ids, product_cost_rate }) => {
  const response = await request({
    url: "/analytics/tax_rates/",
    method: "get",
    params: {
      wb_api_key_ids,
      product_cost_rate,
    },
  });
  return response.data;
};

export { getTaxRates };
