import React, { useEffect } from "react";

import { FormGroup, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { promotionMediaFilter } from "root/store/financialLogic/financialLogicSlice";

import { promotionMediaCompanyFilterCheckbox } from "root/pages/financial-logic/info";

import PageTab from "../../../../../../components/global/page-tab/nav-bar-tab";

import styles from "./index.module.scss";

const PromotionMediaFilterCheckbox = ({ setShowPositionChart, noDataCondition }) => {
  const dispatch = useDispatch();
  const { type } = useSelector(state => state.financeLogic);

  const handleChange = ({ id, checked }) => {
    if (checked) {
      if (id === 8) setShowPositionChart(true);
      dispatch(
        promotionMediaFilter({
          name: "type",
          value: [...type, id],
        })
      );
    } else {
      if (id === 8) setShowPositionChart(false);

      if (type?.length > 1) {
        const newType = type.filter(elem => elem !== id);
        dispatch(
          promotionMediaFilter({
            name: "type",
            value: newType,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (type?.length === 0) {
      const ids = promotionMediaCompanyFilterCheckbox.map(elem => elem.id);
      dispatch(
        promotionMediaFilter({
          name: "type",
          value: ids,
        })
      );
    }
  }, [type]);

  useEffect(() => {
    if (type?.length === 0) {
      dispatch(
        promotionMediaFilter({
          name: "type",
          value: [8, 9],
        })
      );
    }
  }, []);

  const formatedTypes = [
    ...promotionMediaCompanyFilterCheckbox.filter(item => item.id === 8),
    ...promotionMediaCompanyFilterCheckbox.filter(item => item.id === 9),
    ...promotionMediaCompanyFilterCheckbox.filter(item => item.id === 5),
    ...promotionMediaCompanyFilterCheckbox.filter(item => item.id === 6),
    ...promotionMediaCompanyFilterCheckbox.filter(item => item.id === 4),
    ...promotionMediaCompanyFilterCheckbox.filter(item => item.id === 7),
  ];

  return (
    <div className={styles.checkboxFilter}>
      <PageTab value={0}>
        {formatedTypes.map(elem => (
          <FormGroup
            style={{ display: "flex", flexDirection: "row" }}
            key={elem.id}
            className={styles.stackCheckbox}>
            <FormControlLabel
              control={
                <Checkbox
                  className={styles.navbarCheckbox}
                  checked={type?.includes(elem.id)}
                  onChange={e => handleChange({ id: elem.id, checked: e.target.checked })}
                  style={{
                    color: type?.includes(elem.id) ? "#cf3aa0" : "#798097",
                  }}
                  disabled={noDataCondition && type?.includes(elem.id)}
                />
              }
              label={elem.title}
            />
          </FormGroup>
        ))}
      </PageTab>
    </div>
  );
};

export default PromotionMediaFilterCheckbox;
