import React from "react";

import cn from "classnames";

import styles from "./index.module.scss";

const DiagramHint = props => {
  const {
    date,
    titleValue,
    value,
    secondValue,
    secondTitleValue,
    secondValueCurrency,
    thirdValue,
    thirdTitleValue,
    thirdValueCurrency,
    fourthValue,
    fourthTitleValue,
    fourthValueCurrency,
    className,
    firstLegendClass,
    secondLegendClass,
    thirdLegendClass,
    fourthLegendClass,
    valueColor,
    secondValueColor,
    thirdValueColor,
    fourthValueColor
  } = props;
  return (
    <div className={cn(styles.diagramDecription, className)}>
      {date && <p className={styles.hintTitle}>{date}</p>}
      <div className={styles.legendsBox}>
        {value && (
          <div className={styles.legendItem}>
            <div className={styles.legends}>
              <div className={styles.circle} style={{backgroundColor: valueColor ? valueColor : ''}}></div>
              <div className={cn(styles.title, firstLegendClass)}>{titleValue}:</div>
            </div>

            <div className={cn(styles.value, firstLegendClass)}>{value || "Нет данных"}</div>
          </div>
        )}

        {secondValue && (
          <div className={styles.legendItem}>
            <div className={styles.legends}>
              <div className={styles.secondCircle} style={{backgroundColor: secondValueColor ? secondValueColor : ''}}></div>
              <div className={cn(styles.title, secondLegendClass)}>{secondTitleValue}:</div>
            </div>
            <div className={cn(styles.value, secondLegendClass)}>
              {secondValue !== null && secondValue !== 'null'
                ? `${secondValue} ${secondValueCurrency ? secondValueCurrency : ""}`
                : "Нет данных"}
            </div>
          </div>
        )}

        {thirdValue && (
          <div className={styles.legendItem}>
            <div className={styles.legends}>
              <div className={styles.thirdCircle} style={{backgroundColor: thirdValueColor ? thirdValueColor : ''}}></div>
              <div className={cn(styles.title, thirdLegendClass)}>{thirdTitleValue}:</div>
            </div>
            <div className={cn(styles.value, thirdLegendClass)}>
              {thirdValue !== null && thirdValue !== 'null'
                ? `${thirdValue} ${thirdValueCurrency}`
                : "Нет данных"}
            </div>
          </div>
        )}

        {fourthValue && (
          <div className={styles.legendItem}>
            <div className={styles.legends}>
              <div className={styles.fourthCircle} style={{backgroundColor: fourthValueColor ? fourthValueColor : ''}}></div>
              <div className={cn(styles.title, fourthLegendClass)}>{fourthTitleValue}:</div>
            </div>
            <div className={cn(styles.value, fourthLegendClass)}>
              {fourthValue !== null && fourthValue !== 'null'
                ? `${fourthValue} ${fourthValueCurrency}`
                : "Нет данных"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiagramHint;
