import request from "root/js/request";

export const getKeyIndicatorsTable = async ({
  wb_api_key_ids,
  cabinet_ids,
  date_from,
  date_to,
  product_id__in,
  product_ids,
  group_by,
  order_by,
  limit,
  offset,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/key_indicators_table/",
    method: "get",
    params: {
      wb_api_key_ids: wb_api_key_ids || cabinet_ids, // для обратной совместимости
      date_from,
      date_to,
      product_id__in: product_id__in || product_ids, // для обратной совместимости
      group_by,
      order_by,
      limit,
      offset,
      ...rest,
    },
  });
  return response.data;
};
