import { createSlice } from "@reduxjs/toolkit"
import { any, clone, compose, isEmpty, isNil, keys, prop, propEq, propOr, uniq, uniqBy } from "ramda"

import {
	getSupplierArticleAdvertsUsageHistory,
	inputParams,
	moveObjectFromDataObjects,
	productFrequencyBrands,
	productFrequencyObjectKeys,
	productFrequencySeller,
	removeEmptyEnqueueSnackbar,
	removeErrorEnqueueSnackbar,
	removeSuccessEnqueueSnackbar,
	setAdverts,
	setDataObjects,
	setErrorInfo,
	setGlobalInfo,
	setInfo,
	setLimits,
	startFetchingData,
	startFetchingNewData,
	stopFetchingData
} from "./actions"


const initialState = {
	isLoading: false,
	params: {},
	keys: ["proceeds"],
	supplier_article: [],
	requestDates: null,
	status: null,
	intervalId: null,
	info: null,
	run_id: [],
	limits: {
		rerender: 0,
		exists_usage_count: "—",
		max_usage_count: "—",
		usage_count: 1,
	},
	data: {
		objects: [],
		successEnqueueSnackbar: [],
		errorEnqueueSnackbar: [],
		emptyEnqueueSnackbar: [],

	},
	errorInfo: null,
	globalInfo: null,
	supplierArticleAdvertsLoading: [],
	proceeds: {
		rawPostfix: " руб.",
		postfix: " руб.",
		minString: "0",
		maxString: "0",
		minNumber: 0,
		maxNumber: 0,
		params: {}
	},
	process: {},

	page: 1,
	checkBoxes: {
		1: {
			checkedAll: false,
			ids: []
		},

	},
	ids: {
		1: []
	},
	allCheckBoxes: []
}

export const bloggersSearchSlice = createSlice({
	name: "bloggersSearchSlice",
	initialState,
	reducers: {
		// !------------checkbox
		//!--ids
		getDataIds(state, { payload }) {
			const { page = 1, data = [] } = payload
			const ids = data.map(({ id }) => id)
			state.ids[page] = ids
			state.page = page
		},
		//!--checkAll
		checkAllAction(state, { payload }) {
			const { page = 1, bool = true, ids } = payload

			state.checkBoxes[page] = {
				...(state.checkBoxes[page] ?? {}),
				checkedAll: bool,
			}
			if (bool) {

				state.checkBoxes[page].ids = ids
			} else {
				state.checkBoxes[page].ids = []
			}

			if (bool) {
				state.allCheckBoxes = uniq(state.allCheckBoxes.concat(ids.filter(id => id)))
			} else {
				ids.forEach(id => {
					state.allCheckBoxes = state.allCheckBoxes.filter(value => value !== id)
				})
			}
		},
		checkAction(state, { payload }) {
			const { value, page = 1, bool = true } = payload

			if (!state?.checkBoxes?.[page]) {
				state.checkBoxes[page] = {
					ids: [],
					checkedAll: false
				}
			} else {
				state.checkBoxes[page].checkedAll = false
			}
			if (bool) {
				state.checkBoxes[page].ids.push(value)
				state.allCheckBoxes.push(value)
			} else {
				state.checkBoxes[page].ids = state.checkBoxes[page].ids.filter(id => id !== value)
				state.allCheckBoxes = state.allCheckBoxes.filter(id => id !== value)
			}
		}
	},
	extraReducers: builder => {
		builder.addCase(inputParams.fulfilled, (state, { payload }) => {
			state[payload.key] = {
				...state[payload.key],
				...payload,
				params: {
					...state[payload.key].params,
					...payload.params
				}
			}
		})

		builder.addCase(removeErrorEnqueueSnackbar.fulfilled, (state, { payload = null }) => {
			state.data.errorEnqueueSnackbar = propOr([], "errorEnqueueSnackbar", propOr([], "data", state))?.filter(item => String(item?.supplier_article) !== String(payload)).filter(item => item)
		})
		builder.addCase(removeEmptyEnqueueSnackbar.fulfilled, (state, { payload }) => {
			state.data.emptyEnqueueSnackbar = propOr([], "emptyEnqueueSnackbar", propOr([], "data", state)).filter((item) => String(item?.supplier_article) !== String(payload)).filter(item => item)
		})

		builder.addCase(removeSuccessEnqueueSnackbar.fulfilled, (state, { payload }) => {
			state.data.successEnqueueSnackbar = propOr([], "successEnqueueSnackbar", propOr([], "data", state)).filter((item) => String(item?.supplier_article) !== String(payload)).filter(item => item)
		})

		builder.addCase(setDataObjects.fulfilled, (state, { payload }) => {
			// console.log("||", { payload });
			if (prop("status", payload) === "error") {
				state.data.errorEnqueueSnackbar = [...clone(state.data.errorEnqueueSnackbar), { supplier_article: payload.item }]
			} else {
				state.data.objects = [...state.data.objects, payload]
			}
		})

		builder.addCase(setDataObjects.rejected, (state, { payload }) => {
			state.data.errorEnqueueSnackbar = [...clone(state.data.errorEnqueueSnackbar), payload.item]
		})

		builder.addCase(moveObjectFromDataObjects.fulfilled, (state, { payload }) => {
			state.data.objects = [...state.data.objects, payload]
		})

		builder.addCase(startFetchingData.fulfilled, (state, { payload }) => {
			state.intervalId = payload.intervalId;
			state.supplier_article = payload.supplier_article;
			// state.limits.rerender = state.limits.rerender + 1

		});

		builder.addCase(startFetchingNewData.fulfilled, (state, { payload }) => {
			state.intervalId = payload.intervalId;

		})


		builder.addCase(stopFetchingData.fulfilled, (state) => {
			state.intervalId = null;
		});
		builder.addCase(setLimits.fulfilled, (state, { payload }) => {
			state.limits = {
				...state.limits,
				...payload
			};
		});

		builder.addCase(setAdverts.fulfilled, (state, { payload }) => {
			state.process = {
				...state.process,
				[payload.supplier_article]: [
					...propOr([], payload.supplier_article, state),
					payload.run_id
				].filter(item => item)
			}
			state.supplierArticleAdvertsLoading = [...state.supplierArticleAdvertsLoading, payload.supplier_article];
			state.supplier_article = uniq([...state.supplier_article, ...payload.supplier_article]);
			state.run_id = [...propOr([], "run_id", state), payload.run_id]
		});

		builder.addCase(setInfo.fulfilled, (state, { payload }) => {
			state.info = payload
		});
		builder.addCase(setGlobalInfo.fulfilled, (state, { payload }) => {
			state.globalInfo = payload
		});
		builder.addCase(setErrorInfo.fulfilled, (state, { payload }) => {
			state.errorInfo = payload
			if (isNil(payload)) {
				state.info = payload
			}
		});

		builder.addCase(getSupplierArticleAdvertsUsageHistory.fulfilled, (state, { payload }) => {
			if (isNil(state.requestDates)) {
				state.requestDates = {
					...payload,
					count: isEmpty(propOr([], "results", payload).filter(({ status }) => (status === "success" || status === "process")))
						? 0
						: propOr(0, "count", payload),
					results: propOr([], "results", payload).filter(({ status }) => (status === "success" || status === "process"))
				}
				state.data.objects = propOr([], "results", payload).filter(({ status }) => (status === "process" || status === "wait"))
			}

			propOr([], "objects", prop("data", state)).map(item => {
				const newItem = propOr([], "results", payload).find(({ run_id }) => run_id === item.run_id)
				if (prop("status", newItem) === "success") {
					state.data.successEnqueueSnackbar = [...clone(state.data.successEnqueueSnackbar), newItem]
					state.requestDates = {
						...payload,
						count: isEmpty(propOr([], "results", payload).filter(({ status = null }) => (status === "success" || status === "process")))
							? 0
							: propOr(0, "count", payload),
						results: uniqBy(
							prop("supplier_article"),
							[
								...propOr([], "results", payload).map((item) => {
									if (item.supplier_article === newItem.supplier_article && item.run_id === newItem.run_id) {
										return newItem
									}
									return item
								}),
								...state.requestDates.results,
							]
						)
					}
					state.data.objects = clone(state.data.objects).filter(({ run_id }) => run_id !== item.run_id)
				}
				if (prop("status", newItem) === "error") {
					state.data.errorEnqueueSnackbar = [...clone(state.data.errorEnqueueSnackbar), newItem]
					state.data.objects = clone(state.data.objects).filter(({ run_id }) => run_id !== item.run_id)
					state.requestDates.results = clone(state.requestDates.results).filter(({ run_id }) => run_id !== item.run_id)
				}
				if (prop("status", newItem) === "empty") {
					state.data.emptyEnqueueSnackbar = [...clone(state.data.emptyEnqueueSnackbar), newItem]
					state.data.objects = clone(state.data.objects).filter(({ run_id }) => run_id !== item.run_id)
					state.requestDates.results = clone(state.requestDates.results).filter(({ run_id }) => run_id !== item.run_id)
				}
			})

		});


		builder.addCase(productFrequencyBrands.pending, state => {
			state.brands.isLoading = true
		})


		builder.addCase(productFrequencyBrands.rejected, state => {
			state.brands.isLoading = false
		})
		builder.addCase(productFrequencyBrands.fulfilled, (state, { payload }) => {
			state.brands.isLoading = false
			state.brands.results = propOr([], "results", payload)
			state.brands.count = propOr(0, "count", payload)
		})



		builder.addCase(productFrequencySeller.pending, state => {
			state.sellers.isLoading = true
		})
		builder.addCase(productFrequencySeller.rejected, state => {
			state.sellers.isLoading = false
		})
		builder.addCase(productFrequencySeller.fulfilled, (state, { payload }) => {
			state.sellers.isLoading = false
			state.sellers.results = propOr([], "results", payload)
			state.sellers.count = propOr(0, "count", payload)
		})






		builder.addCase(productFrequencyObjectKeys.fulfilled, (state, { payload }) => {
			state.params = payload
		})

	},
})

export const {
	setPrevForm,
	getDataIds,
	checkAllAction,
	checkAction
} = bloggersSearchSlice.actions

export default bloggersSearchSlice.reducer
