//
// 1 - Демо "Лайт"
// 2 - Базовый
// 3 - Расширенный
// 4 - Профессиональный
// 5 - Профессиональный ( экспресс )
// 14 - Корпоративный
// 15 - Лёгкий старт
// 16 - Демо "Макс"
// 17 - Лёгкий старт +
// 19 - Спец проекты
export const allTariffs = [1, 2, 3, 4, 5, 14, 15, 16, 17];
export const allTariffsExcept2 = allTariffs.filter(id => id !== 2);
export const allTariffsExcept23 = allTariffs.filter(id => id !== 2 && id !== 3);
// --------------------------------------------------------
const accessByTariffs = {
  // competitorAnalysisSections | "Анализ конкурентов"
  "/search/brand": allTariffsExcept2, // "Анализ бренда"
  "/search/seller": allTariffsExcept2, // "Анализ поставщика"
  "/analysis-by-subject": allTariffsExcept2, // "Анализ предметов"
  "/analysis-by-characteristics": allTariffsExcept23, // "Анализ характеристик"
  "/articles-comparison": allTariffsExcept23, // "Анализ характеристик"
  // growthLeadersSection | "Лидеры роста"
  "/growth_leaders/products": allTariffsExcept23, // "По товарам"
  "/growth_leaders/brands": allTariffsExcept2, // "По брендам"
  "/growth_leaders/sellers": allTariffsExcept2, // "По поставщикам"
  "/growth_leaders/categories": allTariffsExcept2, // "По категориям"
  // nichesAndTrendsSection | "Ниши и тренды"
  "/trend-requests-old": allTariffsExcept2, // "Трендовые запросы"
  "/keywords-summary-v2": [4, 5, 14, 15, 16, 17], // "Оракул запросов"
  "/product-analyzer": allTariffsExcept23, // "По товарам"
  "/brand-analyzer": allTariffsExcept2, // "По брендам"
  "/seller-analyzer": allTariffsExcept2, // "По поставщикам"
  "/category-analyzer": allTariffsExcept2, // "По категориям"
  "/request-analyzer": allTariffsExcept23, // "По запросам"
  // externalAnalysisSection | "Анализ внешки"
  "/bloggers-search": [4, 14, 16, 17], // "Поиск трафика WB"
  "/all-bloggers-search": [4, 14, 16, 17], // "Поиск трафика другие МП"
  "/bloggers-data": [4, 14, 16, 17], // "Отчеты по внешке"
  "/bloggers-posts-selection": [4, 14], // "Отборщик публикаций"
  "/bloggers-profiles-selection": [4, 14], // "Отборщик блогеров"
  "/blogger-card": [4, 14], // "Анализ блогера"
  "/monitoring?tab=7": [2, 3, 4, 14, 16, 17],
  // promotionSection | "Продвижение"
  "/top-find": allTariffsExcept23, // "Рекомендации"
  "/cluster_checkup": allTariffsExcept2, // "Проверка кластера"
  // "/groupClusterer": allTariffsExcept2, // "Кластеризатор"
  // seoSection | "SEO"
  "/keywords-position": [2, 3, 4, 5, 14], // "Индексация запросов", // [4 , 5, 14, 15 , 16, 17]
  "/keys-finder": allTariffsExcept2, // "Поисковые подсказки"
  "/articles-keys": allTariffsExcept2, // "Запросы у списков"
  "/keys-top-find": allTariffsExcept23, // "Запросы у топов поиска"
  "/sellers-keys": allTariffsExcept23, // "Пылесос запросов"
  "/keys-comparison": allTariffsExcept23, // "Сравнение списков"
  "/check-products-by-keys/product": allTariffsExcept2, // "Вхождение запросов"
  // "/utm-links": allTariffsExcept2, // "UTM ссылки"
  // internalAnalyticsSection | "Внутренняя аналитика"
  // Выяснилось, что внутряк открыт везде
  // "/stocks": allTariffsExcept2, // "Акции"
  // "/setting": allTariffsExcept2, // "Настройки"
  // "/decoding-detail?tab=decoding-detail": allTariffsExcept2, // "Расшифровка детализаций"
};
// -----------------------------------------------------------------------------

export { accessByTariffs };
