import { createSlice } from "@reduxjs/toolkit";

import { bloggersProfilesSelectionParamsForRedux } from "./utils";

export const paramsBPrS = {
  source: [],
  brand_id: [],
  account_name_hash: [],
  item: [],
  product_id: [],
  ...bloggersProfilesSelectionParamsForRedux,
};

const initialState = {
  params: {
    ...paramsBPrS,
  },
};

const bloggersProfilesSelection = createSlice({
  name: "bloggersProfilesSelection",
  initialState,
  reducers: {
    setBPrSSocialNetworks(state, { payload: newSN = [] }) {
      state.params.source = newSN;
    },
    setBPrSParamByName(state, { payload: { value, name } }) {
      state.params[name] = value;
    },
    setSavedParamsBPrS(state, { payload: savedParams }) {
      state.params = savedParams;
    },
    resetReduxFiltersBPrS(state) {
      state.params = paramsBPrS;
    },
  },
});

export default bloggersProfilesSelection.reducer;
export const {
  setBPrSSocialNetworks,
  setBPrSParamByName,
  setSavedParamsBPrS,
  resetReduxFiltersBPrS,
} = bloggersProfilesSelection.actions;
