import React from "react"

import TooltipUI from "@mui/material/Tooltip"

import {numberWithSpaces, timestampToDateStringBriefly} from "root/js/utils"

import styles from "./index.module.scss"

const ChartTooltipMUI = props => {
	const {active, dataIndex=false, payload, tooltipPostfix, toFixedNum} = props
	
	
	if (active && payload) {
	
		return (
			<TooltipUI
				title={
					<div>
						<div className={styles.header}>
							 {timestampToDateStringBriefly(payload[0].payload.date).toUpperCase()} 
						</div>
						<div className={styles.value}>
							{payload[0].payload.max
								? payload[0].payload.max
								: typeof payload[0].payload.rawData === "number"&& dataIndex !== "rating"
									? numberWithSpaces((payload[0].payload.rawData).toFixed(toFixedNum))
									: payload[0].payload.rawData
							}
							{tooltipPostfix}
						</div>
					</div>
				}
				open
				arrow
				placement="top"
				enterDelay={600}
				enterNextDelay={600}
				classes={{
					tooltip: styles.tooltip,
					arrow: styles.tooltipArrow,
				}}
			>
				<div className={styles.tooltipConent} />
			</TooltipUI>
		)
	} else return null
}

export default ChartTooltipMUI
