import request from "root/js/request";

export const getSppLimitsMonitoring = async () => {
  const response = await request({
    url: `monitoring/spp_user_notification/limits/`,
    method: "get",
    params: {},
  });
  return response.data;
};
