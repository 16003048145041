import React from "react";

import { Box } from "@mui/material";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import { betaPages, newPages } from "root/App/menu/miniBanners";

import { BetaBanner, NewBanner } from "../MainMenuDrawer/utils";
import VideoDialogAuto from "../VideoDialog/VideoDialogAuto";

import styles from "./index.module.scss";

const PageTitle = props => {
  const { children, className, ...rest } = props;
  const history = useHistory();
  return (
    <Box display={"flex"} gap={2} alignItems={"center"} flexWrap={"wrap"}>
      <Box display={"flex"} alignItems={"center"} gap={1} flexWrap={"wrap"}>
        {betaPages.pages.includes(history.location.pathname) && <BetaBanner />}
        {newPages.pages.includes(history.location.pathname) && <NewBanner />}
        <h2 className={classnames(styles.root, className)} {...rest}>
          {children}
        </h2>
      </Box>
      <VideoDialogAuto />
    </Box>
  );
};

export default PageTitle;
