import request from "root/js/request";

export const getMPCabinets = async ({ wb_types, extra_fields, synced_types } = {}) => {
  const response = await request({
    url: "/company/mp_cabinets/",
    method: "get",
    params: {
      wb_types,
      extra_fields,
      synced_types,
    },
  });
  return response.data;
};
