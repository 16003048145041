import React from "react";

import Optimization from "root/icons/Optimization";

// ----------------------------------------------------------------------------------
const promotionSection = {
  type: "section",
  title: "Продвижение",
  icon: Optimization,
  children: [
    {
      title: "Автореклама (Бустер)",
      url: "/auto-advertising",
      component: React.lazy(() => import("root/pages/auto-advertising/App")),
      exact: false,
    },
    {
      title: "Реклама в поиске",
      url: "/advertising-optimization",
      component: React.lazy(() => import("root/pages/advertising-optimization/App")),
      exact: false,
    },
    {
      title: "Реклама в карточке",
      url: "/product-optimization",
      component: React.lazy(() => import("root/pages/product-optimization/App")),
      exact: false,
    },
    {
      title: "Реклама в каталоге",
      url: "/category-optimization",
      component: React.lazy(() => import("root/pages/category-optimization/App")),
      exact: false,
    },
    {
      title: "Рекомендации",
      url: "/top-find",
      component: React.lazy(() => import("root/pages/top-find/App")),
      exact: false,
    },
    {
      title: "Проверка кластера",
      url: "/cluster_checkup",
      component: React.lazy(() => import("root/pages/cluster_checkup/App")),
      exact: false,
    },
    {
      title: "Кластеризатор OLD",
      url: "/groupClusterer",
      component: React.lazy(() => import("root/pages/group_clusterer/App")),
      exact: false,
      noProd: true,
    },
    {
      title: "Кластеризатор",
      url: "/clusterer",
      component: React.lazy(() => import("root/pages/clusterer/App")),
      exact: false,
    },
  ],
};

export default promotionSection;
