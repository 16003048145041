import request from "root/js/request";

// Обновляет все уровни
export const putSharesLevels = async ({ cabinet_id, shares_id, updatedLevels }) => {
  const response = await request({
    url: `/analytics/shares/${shares_id}/share_level/?cabinet_id=${cabinet_id}`,
    method: "put",
    data: { data: updatedLevels },
  });
  return response;
};

// 
export const postSharesLevel = async ({ cabinet_id, shares_id, updatedLevels }) => {
  const response = await request({
    url: `/analytics/shares/${shares_id}/share_level/?cabinet_id=${cabinet_id}`,
    method: "post",
    params: {
      cabinet_id,
    },
    data:  { data: updatedLevels },
  });
  return response;
};

// Удаляем один уровень у акции
export const deleteSharesLevel = async ({ cabinet_id, shares_id, share_level_id }) => {
  const response = await request({
    url: `/analytics/shares/${shares_id}/share_level/${share_level_id}/?cabinet_id=${cabinet_id}`,
    method: "delete",
    params: {
      cabinet_id,
    },
  });
  return response;
};
