import React, { Fragment, Suspense, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { enqueueSnackbar } from "notistack";
import { isEmpty, length, propOr } from "ramda";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { userParams } from "root/js/ym";
import { setProfile } from "root/store/profile/actions";

import API from "root/js/api";

import GlobalNotifications from "root/components/GlobalNotifications";
import MainMenuDrawer from "root/components/MainMenuDrawer";
import PromoAccess from "root/components/promoAccessModal";

import useNavigationMemory from "root/hooks/useNavigationMemory";

import Header from "../components/Header";
import LinkPure from "../components/LinkPure";
import Loading from "../components/Loading";
import NoTariffDialog from "../components/NoTariffDialog";
import TooManyRequestsDialog from "../components/TooManyRequestsDialog";
import ErrorPage from "../pages/error/components/ErrorPage";
import {
  removeEmptyEnqueueSnackbar,
  removeErrorEnqueueSnackbar,
  removeSuccessEnqueueSnackbar,
  setLimits,
  startFetchingNewData,
} from "../store/bloggersSearchSlice/actions";
import { setError } from "../store/user/userSlice";
import { getUserTariff } from "../store/userTariff/userTariff";

import drawerPages from "./menu/drawerPages"; // Страницы в меню
import otherMenuPages from "./menu/otherPages"; // разные страницы, доступные для открытия
import { filterPageAndHerChildByProp } from "./utils";

import styles from "./index.module.scss";

import "root/css/global.scss";

//
const WBAPI = new API();
// const urlAlways = ["/decoding-detail"]
const otherPages = otherMenuPages;

// *
// -----------------------------------------------------------------
const App = (/*props*/) => {
  const history = useHistory();
  const { params, navigateWithParams } = useNavigationMemory();
  const location = useLocation();
  const dispatch = useDispatch();
  let { token } = useParams();

  const [userProfile, setUserProfile] = useState({});

  const { id: tariffId, name: tariffName, isLoading } = useSelector(state => state.userTariff);

  // ---------------- DRAWER PAGES LOGIC
  const isAdmin = useMemo(() => userProfile.company?.is_admin, [userProfile]);
  //
  const visibleDrawerPages = useMemo(() => {
    let result = drawerPages;

    const isDev = process.env.NODE_ENV === "development";
    const noProd =
      ["https://dev.service-analytic.com", "https://dev03.service-analytic.com"].includes(window.location.origin) ||
      window.location.origin.includes("localhost");

    if (!isAdmin) result = filterPageAndHerChildByProp(result, "onlyForAdmin");
    if (!noProd) result = filterPageAndHerChildByProp(result, "noProd");
    if (!isDev) result = filterPageAndHerChildByProp(result, "onlyInDev");

    return result;
  }, [drawerPages, isAdmin]);
  //

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // GlobalNotificationLogic
  const [isPageBlocked, setIsPageBlocked] = useState(false);
  //
  const [isAuth, setIsAuth] = useState(true);

  const { error, expiredTariff } = useSelector(state => state.user);
  const { white_label } = useSelector(state => state.profile);

  useEffect(() => {
    if (location.pathname.includes("/iframe")) {
      localStorage.setItem(
        "token",
        // location.pathname.split("/").at(-1)
        propOr("", length(location.pathname.split("/")) - 1, location.pathname.split("/"))
      );
    }
  }, [token]);

  useEffect(() => {
    if (location.pathname.includes("/test")) {
      if (location.pathname.includes("id") || location.pathname.includes("product"))
        history.push("197760188");
      if (location.pathname.includes(":keyword")) history.push("платье");
      if (location.pathname.includes("product_id")) {
        history.push("/test/position/197760188/платье");
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("/iframe")) return;

    dispatch(getUserTariff());
    //dispatch(checkTariffExpired())
  }, []);

  useEffect(() => {
    dispatch(setError(""));
  }, [location]);

  const servicePages = useMemo(
    () => [
      {
        url: process.env.REACT_APP_HOME_PAGE,
        showInDrawer: false,
        component: React.lazy(() => import("../pages/home/App")),
        exact: true,
      },
      {
        url: "/product/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/product/App")),
        exact: true,
      },
      {
        url: "/check-product-id/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/check-products-by-keys/product")),
        exact: false,
      },
      {
        url: "/brand/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/brand/App")),
        exact: true,
      },
      {
        url: "/category/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/category/App")),
        exact: true,
      },
      {
        url: "/seller/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/seller/App")),
        exact: true,
      },
      ...drawerPages.reduce((acc, item) => {
        if (item.type === "section") {
          return acc.concat(item.children.filter(item => !item.disabled)).map(item => {
            if ((!tariffId || expiredTariff) && !propOr(false, "openIt", item)) {
              // item.onClickHandlerDisabled = true
            } else if (
              !isLoading &&
              item.tariffIds?.length &&
              !item.tariffIds.find(id => id === tariffId) &&
              !item.openIt
              // &&equals(item.url, urlAlways)
            ) {
              item.disabled = true;
              item.onClickMessageDisabled = true;
              item.onClickHandlerDisabled = true;
              item.hoverEffect = true;
              item.hoverText = "Для данного тарифа недоступно";
            } else {
              item.onClickHandlerDisabled = false;
            }
            return {
              ...item,
              url: item?.url?.replace(/\?.+$/, ""),
            };
          });
        } else {
          return item.disabled ? acc : acc.concat(item);
        }
      }, []),
      {
        url: "/cabinet",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/cabinet/App")),
        exact: true,
      },
    ],
    [drawerPages, white_label, tariffId, isLoading, expiredTariff]
  );

  const otherPagesPaths = useMemo(() => otherPages.map(item => item.url), [otherPages]);

  const servicePaths = useMemo(() => servicePages.map(page => page.url), [servicePages]);

  useEffect(() => {
    if (location.pathname.includes("/iframe") || location.pathname.includes("/welcome-to-extension")||location.pathname.includes("/unsub-from-newsletters")) return;
    (async function loop() {
      try {
        const isAuth_ = await WBAPI.isAuth();
        setIsAuth(isAuth_);
        const user = await WBAPI.getProfile();
        localStorage.setItem(
          "user",
          JSON.stringify({
            id: user?.id,
            companyId: user?.company?.id,
          })
        );
        dispatch(setProfile({ data: user }));
        setUserProfile(user);
        localStorage.setItem(
          "user",
          JSON.stringify({
            id: user?.id,
            companyId: user?.company?.id,
          })
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    //каждые 12 сек проверяем авторизацию
    if (!isEmpty(userProfile)) {
      let timer;
      (async function loop() {
        try {
          if (tariffId && isAuth) {
            window.uid = userProfile.id; //это для ErrorMessageBody
            //устанавливаем данные юзера для метрики
            !timer &&
              setTimeout(
                () =>
                  userParams({
                    UserID: String(userProfile.id),
                    email: userProfile.email,
                    tariff: { name: tariffName, id: tariffId },
                  }),
                3000
              );
          }
        } catch (err) {
          console.error(err);
        }
        timer = setTimeout(loop, 12000);
      })();

      return () => clearTimeout(timer);
    }
  }, [tariffId, isAuth, userProfile]);

  // const supplierArticle = useSelector(store => store.bloggersSearchSlice.supplier_article)
  const dataObjects = useSelector(store => store.bloggersSearchSlice.data.objects);
  const {
    emptyEnqueueSnackbar = [],
    errorEnqueueSnackbar = [],
    successEnqueueSnackbar = [],
  } = useSelector(store => store.bloggersSearchSlice.data);
  const intervalId = useSelector(store => store.bloggersSearchSlice.intervalId);
  const limits = useSelector(store => store.bloggersSearchSlice.limits);

  useEffect(() => {
    if (!isEmpty(successEnqueueSnackbar)) {
      successEnqueueSnackbar.forEach(({ supplier_article: article }) => {
        if (article) {
          enqueueSnackbar({
            title: `Отчет по источникам трафика для артикула ${article} сформирован.`,
            message:
              location.pathname === "/bloggers-search/bloggers-data" ? (
                <Fragment>
                  Если у вас артикула {article} в статусе «В процессе». Нажмите на кнопку «Обновить
                  статусы загрузки», чтобы скачать отчет по источникам трафика для артикула{" "}
                  {article}
                </Fragment>
              ) : (
                <Fragment>
                  Скачать отчет можно на странице «Поиск трафика»{" -> "}
                  <LinkPure
                    to={`/bloggers-search/bloggers-data`}
                    className={styles.link}
                    title={"Нажмите для перехода к «История анализа источников трафика»"}>
                    «История анализа источников трафика»
                  </LinkPure>
                  .
                </Fragment>
              ),
            variant: "success",
          });
        }
        dispatch(setLimits());
        dispatch(removeSuccessEnqueueSnackbar(article));
      });
    }

    if (!isEmpty(errorEnqueueSnackbar)) {
      errorEnqueueSnackbar?.forEach(item => {
        if (item?.supplier_article) {
          enqueueSnackbar({
            title: `Не удалось собрать информацию по источникам трафика для артикула ${
              item?.supplier_article ?? ""
            }.`,
            message: "",
            variant: "error",
          });
        }
        dispatch(setLimits());
        dispatch(removeErrorEnqueueSnackbar(item?.supplier_article));
      });
    }

    if (!isEmpty(emptyEnqueueSnackbar)) {
      emptyEnqueueSnackbar.forEach(item => {
        if (item?.supplier_article) {
          enqueueSnackbar({
            title: `По данному артикулу ${
              item?.supplier_article ?? ""
            } не найдено источников трафика. Возможно публикации были размещены на ресурсах, которые невозможно собрать автоматическим путем.`,
            message: "",
            variant: "error",
          });
        }
        dispatch(setLimits());
        dispatch(removeEmptyEnqueueSnackbar(item?.supplier_article));
      });
    }
  }, [errorEnqueueSnackbar, limits, emptyEnqueueSnackbar, successEnqueueSnackbar]);

  useEffect(() => {
    clearInterval(intervalId);
    const startFetching = async () => {
      dispatch(
        startFetchingNewData({
          supplier_article_ids: dataObjects
            .map(({ supplier_article }) => String(supplier_article))
            .join(","),
          dataObjects,
          dispatch,
        })
      );
    };

    if (!isEmpty(dataObjects)) {
      startFetching();
    }
  }, [dataObjects]);

  // -------------------------------------------- MINDBOX
  const [lastPath, setLastPath] = useState("");

  const { id: wildboxId } = useSelector(store => store.profile);

  useEffect(() => {
    //
    if (process.env.NODE_ENV === "development" || !wildboxId || wildboxId === "null")
      return () => {};
    //
    const currentPath = location.pathname;
    if (currentPath !== lastPath) {
      console.log("MINDBOX", history.location.pathname, window.mindbox);
      window.mindbox("async", {
        operation: "ViewPage",
        data: {
          viewProduct: {
            product: {
              ids: {
                wildbox: location.pathname,
              },
            },
          },
          customer: {
            ids: {
              wildboxId: `${wildboxId}`,
            },
          },
        },
        onSuccess: function () {
          console.log("MINDBOX SUCCESS");
        },
        onError: function (error) {
          console.log("MINDBOX ERROR", error);
        },
      });
      setLastPath(currentPath);
    }
  }, [location]);
  // --------

  return (
    <>
      <CssBaseline />
      <Route
        path={servicePaths}
        render={() => {
          if (!(isAuth || ["iframe", "signin", "unsub-from-newsletters", "welcome-to-extension"].find(item => location.pathname.includes(item)))) {
            navigateWithParams("/signin", { redirect_url: location.pathname, ...params });
          }
          return <></>;
        }}
      />

      <Switch>
        <Route
          path={servicePaths}
          render={props => {
            return (
              <>
                <Helmet>
                  <meta name="description" content="Бесплатная аналитика брендов Wildberries" />
                  <title>Wildbox</title>
                </Helmet>
                <MainMenuDrawer
                  items={visibleDrawerPages}
                  drawerToggle={() => setIsDrawerOpen(value => !value)}
                  setIsDrawerOpen={setIsDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  open={isDrawerOpen}
                />
                <Box className={styles.root} data-drawer-open={isDrawerOpen}>
                  <Box className={styles.emptyPlace}></Box>

                  <Box className={styles.content}>
                    <Header
                      isDrawerOpen={isDrawerOpen}
                      drawerToggle={() => setIsDrawerOpen(value => !value)}
                      // onOpenNotifications={() => setIsNotificationsOpen(true)}
                    />
                    <PromoAccess />
                    <GlobalNotifications
                      isPageBlocked={isPageBlocked}
                      setIsPageBlocked={setIsPageBlocked}
                    />
                    {!isPageBlocked && (
                      <Switch>
                        {servicePages.map(page => (
                          <Route
                            key={page.url}
                            exact={page.exact}
                            path={page.url}
                            render={props => {
                              if (error === "throttled") {
                                return <TooManyRequestsDialog />;
                              }
                              if (isLoading) return null;

                              if (
                                expiredTariff &&
                                props.match.path === "/decoding-detail" &&
                                !props.location.search.includes("?tab=decoding-detail")
                              ) {
                                history.push("/tariff");
                                return <NoTariffDialog />;
                              }
                              if (
                                expiredTariff &&
                                props.match.path !== "/tariff" &&
                                !props.match.path.includes("/auto-feedbacks") &&
                                props.match.path !== "/partner" &&
                                props.match.path !== "/cabinet" &&
                                props.match.path !== "/useful" &&
                                props.match.path !== "/logout" &&
                                props.match.path !== "/wb-cabinets" &&
                                // props.match.path !==
                                // 	"/decoding-detail" &&
                                !otherPages.find(otherPage => otherPage.url === props.match.path)
                              ) {
                                history.push("/tariff");
                                return <NoTariffDialog />;
                              }

                              if (error === "internal_server_error") {
                                return <ErrorPage code={500} />;
                              }

                              if (error === "not_found") {
                                return <ErrorPage code={404} />;
                              }

                              if (page.disabled) {
                                history.push("/home");
                                // был очень странный баг в мониторинге, пользователей с определёнными тарифами выкидывало на главную
                                // Пофиксил так, но это не решение, пока не выяснено в чём проблема оставляю
                                // if (page.url !== "/monitoring") history.push("/home");
                              }

                              return (
                                <Suspense fallback={<Loading />}>
                                  <page.component isDrawerOpen={isDrawerOpen} />
                                </Suspense>
                              );
                            }}
                          />
                        ))}
                        <Route
                          exact
                          path="*"
                          render={props => {
                            if (error === "throttled") {
                              return <TooManyRequestsDialog />;
                            }
                            return <Redirect to={process.env.REACT_APP_HOME_PAGE} />;
                          }}
                        />
                      </Switch>
                    )}
                  </Box>
                </Box>
              </>
            );
          }}
        />

        <Route
          path={otherPagesPaths}
          render={props => (
            <>
              {otherPages.map(page => (
                <Route
                  key={page.url}
                  path={page.url}
                  render={props => {
                    if (error === "throttled") {
                      return <TooManyRequestsDialog />;
                    }
                    return (
                      <Suspense fallback={<Loading />}>
                        <page.component />
                      </Suspense>
                    );
                  }}
                />
              ))}
            </>
          )}
        />

        {otherPages.map(page => (
          <Route
            key={page.url}
            path={page.url}
            render={() => {
              if (error === "throttled") {
                return <TooManyRequestsDialog />;
              }
              return (
                <Suspense fallback={<Loading />}>
                  <page.component />
                </Suspense>
              );
            }}
          />
        ))}

        <Route
          exact
          path="*"
          render={() => {
            navigateWithParams(process.env.REACT_APP_HOME_PAGE, params);
            return <></>;
          }}
        />
      </Switch>
    </>
  );
};

export default App;
