import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Pagination, PaginationItem } from "@mui/lab";
import {
  Box,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { isNil, prop, propOr } from "ramda";
import { useHistory } from "react-router-dom";

import LinearLoading from "../LinearLoading";
import Tooltip from "../Tooltip";

import paginationStyles from "./pagination.module.scss";

import { numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

import TooltipQuestionIcon from "root/icons/TooltipQuestion";

import "./table.scss";

const getSortStateByOrdering = ordering => {
  if (ordering) {
    return {
      columnDataIndex: ordering.replace("-", ""),
      order: /^-/.test(ordering) ? "desc" : "asc",
    };
  }
  return {
    columnDataIndex: null,
    order: null,
  };
};

const TableSort = props => {
  const {
    ordering,
    //isLoadingSorting,
    onSort,
    hidePerPage,
    containerMaxHeight = "75vh",
    containerMinHeight,
    dataFormater,
    setPage,
    onPageChange = (_, v) => setPage(v),
    setPerPage,
    onPerPageChange = setPerPage,
    columns,
    data: dataProp,
    emptyMessage = "Нет данных",
    isLoading,
    total,
    perPage,
    page,
    className,
    classes = {},
    hideCount,
    scrollFromRight,
    onlyOneSticky = false,
    hasCountLimit = false,
    ...rest
  } = props;

  const scrollBoxRef = useRef(null);
  const history = useHistory();
  // const [shadows, setShadows] = useState(null)

  const [isMobile, setIsMobile] = useState(false);
  const [sort, setSort] = useState(getSortStateByOrdering(ordering));
  const [data, setData] = useState(dataFormater ? dataFormater(dataProp) : dataProp);

  const onSortFunction = columnDataIndex => {
    if (sort.columnDataIndex === columnDataIndex) {
      if (sort.order === "asc") {
        onSort(`-${columnDataIndex}`);
      } else if (sort.order === "desc") {
        onSort(columnDataIndex);
      }
    } else {
      onSort(`-${columnDataIndex}`);
    }
  };

  const onResize = () => {
    const width = document.documentElement.offsetWidth;
    if (width < 768 && !isMobile) {
      setIsMobile(true);
    } else if (width >= 768 && isMobile) {
      setIsMobile(false);
    }
  };

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      columns.forEach((item, index) => {
        if (item.sticky) {
          const headers = document.querySelectorAll("#header" + index);
          const bodys = document.querySelectorAll("#body" + index);

          headers &&
            headers.forEach(header => {
              header.style.removeProperty("left");
            });
          bodys &&
            bodys.forEach(body => {
              body.style.removeProperty("left");
            });
        }
      });

      let columnsWidth = [];

      columns.forEach((item, index) => {
        if (item.sticky) {
          const headers = document.querySelectorAll("#header" + index);
          const bodys = document.querySelectorAll("#body" + index);

          let offsetLeft = 0;

          if (!onlyOneSticky && headers.length) {
            if (index === 0) {
              columnsWidth[0] = 75;
            } else if (index === 1) {
              offsetLeft = columnsWidth[0] - 3;
              columnsWidth[1] = headers[0].offsetWidth;
            } else if (index === 2) {
              offsetLeft = columnsWidth[1] + columnsWidth[0] - 2;
              columnsWidth[2] = headers[0].offsetWidth;
            } else if (index === 3) {
              offsetLeft = columnsWidth[2] + columnsWidth[1] + columnsWidth[0] - 3;
              columnsWidth[3] = headers[0].offsetWidth;
            }
          }

          headers &&
            headers.forEach(header => {
              header.style.setProperty("left", offsetLeft + "px", "important");
              header.style.setProperty("z-index", 11, "important");
            });
          bodys &&
            bodys.forEach(body => {
              body.style.setProperty("left", offsetLeft + "px", "important");
              // body.style.setProperty("z-index", 11, "important")
              body.style.setProperty("z-index", 9, "important");
            });
        }
      });
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [columns, dataProp, isLoading]);

  useLayoutEffect(() => {
    if (!isLoading && scrollFromRight) {
      scrollBoxRef.current.scrollLeft = 100000;
      // scrollBoxRef.current.scrollRight = 10000
    }
  }, [isLoading]);

  useLayoutEffect(() => {
    setData(dataFormater ? dataFormater(dataProp) : dataProp);
  }, [dataProp, dataFormater]);

  useLayoutEffect(() => {
    setSort(getSortStateByOrdering(ordering));
  }, [ordering]);

  useLayoutEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  const handleScroll = useCallback(() => {
    const el = scrollBoxRef.current;
    const gap = 5;
    const scrollLeft = el.scrollLeft;
    const offsetWidth = el.offsetWidth;
    const right = offsetWidth + scrollLeft;
    const scrollWidth = el.scrollWidth;
    let direction = "";
    if (scrollWidth === offsetWidth) {
      direction = "hidden";
    } else if (right >= scrollWidth - gap && scrollLeft >= gap) {
      direction = "left";
    } else if (scrollLeft >= gap && right <= scrollWidth - gap) {
      direction = "both";
    } else if (right <= scrollWidth - gap) {
      direction = "right";
    } else {
      direction = "hidden";
    }

    const data = document.querySelector("#shadows");
    data.dataset.shadows = direction;
  }, []);

  useEffect(() => {
    handleScroll();
  }, []);

  useEffect(() => {
    const div = scrollBoxRef.current;
    div.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const containerStyle = {
    // maxHeight: containerMaxHeight ? `${containerMaxHeight}px` : "unset",
    maxHeight: `${window.innerHeight - 80}px`,
    minHeight: containerMinHeight ? `${containerMinHeight}px` : "unset",
  };

  // Топорный метод, тк нет времени переделывать систему под универсальный
  const getHardHint = colName => {
    const priceColNames = ["price", "price_dynamic"];
    // Во внутренней аналитике СПП считается точно
    if (history.location.pathname.includes("sales-analytics")) return "";
    if (priceColNames.includes(colName))
      return "Для расчета берется усредненный размер СПП по предмету";
    return "";
  };

  const renderTableHead = () => {
    const hasGroupedColumns = columns.some(col => col.groupTitle && col.columns);

    return (
      <TableHead>
        {hasGroupedColumns && (
          <TableRow>
            {columns.map((column, index) => {
              if (column.groupTitle) {
                return (
                  <TableCell
                    key={`group-${index}`}
                    align="center"
                    colSpan={column.colSpan}
                    style={{
                      fontFamily: "Roboto",
                      color: "rgba(121, 128, 151, 1)",
                      borderLeft: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      ...column.style,
                    }}>
                    {column.groupTitle}
                  </TableCell>
                );
              }
              return (
                <TableCell
                  key={`single-${index}`}
                  rowSpan={2}
                  className={styles.cell}
                  onClick={column.sortable ? () => onSortFunction(column.dataIndex) : undefined}
                  data-hassorder={Boolean(column.sortable)}
                  style={{
                    minWidth: column.width ? column.width : "auto",
                    ...column.style,
                  }}
                  data-sticky={Boolean(column.sticky)}
                  id={column.sticky ? "header" + index : ""}>
                  <Tooltip title={column.hint || getHardHint(column.dataIndex)}>
                    <div className={classNames(column.hint ? styles.hint : {}, styles.cellFlex)}>
                      {column.title}
                      {(column.hint || getHardHint(column.dataIndex)) && <TooltipQuestionIcon />}
                      {column.sortable && (
                        <div className={styles.sorter}>
                          <ArrowDropUpIcon
                            data-active={
                              sort.columnDataIndex === column.dataIndex &&
                              sort.order === (column.reverseSort ? "desc" : "asc")
                            }
                          />
                          <ArrowDropDownIcon
                            data-active={
                              sort.columnDataIndex === column.dataIndex &&
                              sort.order === (column.reverseSort ? "asc" : "desc")
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </TableCell>
              );
            })}
          </TableRow>
        )}
        <TableRow>
          {columns.map((column, index) => {
            if (column.groupTitle) {
              return column.columns.map((subColumn, subIndex) => (
                <TableCell
                  key={`sub-${index}-${subIndex}`}
                  className={styles.cell}
                  onClick={
                    subColumn.sortable ? () => onSortFunction(subColumn.dataIndex) : undefined
                  }
                  data-hassorder={Boolean(subColumn.sortable)}
                  style={{
                    minWidth: subColumn.width ? subColumn.width : "auto",
                    ...subColumn.style,
                    top: hasGroupedColumns ? 57 : 0,
                  }}>
                  <Tooltip title={subColumn.hint || getHardHint(subColumn.dataIndex)}>
                    <div className={classNames(subColumn.hint ? styles.hint : {}, styles.cellFlex)}>
                      {subColumn.title}
                      {(subColumn.hint || getHardHint(subColumn.dataIndex)) && (
                        <TooltipQuestionIcon />
                      )}
                      {subColumn.sortable && (
                        <div className={styles.sorter}>
                          <ArrowDropUpIcon
                            data-active={
                              sort.columnDataIndex === subColumn.dataIndex &&
                              sort.order === (subColumn.reverseSort ? "desc" : "asc")
                            }
                          />
                          <ArrowDropDownIcon
                            data-active={
                              sort.columnDataIndex === subColumn.dataIndex &&
                              sort.order === (subColumn.reverseSort ? "asc" : "desc")
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </TableCell>
              ));
            }
            return hasGroupedColumns ? null : (
              <TableCell
                key={`regular-${index}`}
                className={styles.cell}
                onClick={column.sortable ? () => onSortFunction(column.dataIndex) : undefined}
                data-hassorder={Boolean(column.sortable)}
                style={{
                  minWidth: column.width ? column.width : "auto",
                  ...column.style,
                }}
                data-sticky={Boolean(column.sticky)}
                id={column.sticky ? "header" + index : ""}>
                <Tooltip title={column.hint || getHardHint(column.dataIndex)}>
                  <div className={classNames(column.hint ? styles.hint : {}, styles.cellFlex)}>
                    {column.title}
                    {(column.hint || getHardHint(column.dataIndex)) && <TooltipQuestionIcon />}
                    {column.sortable && (
                      <div className={styles.sorter}>
                        <ArrowDropUpIcon
                          data-active={
                            sort.columnDataIndex === column.dataIndex &&
                            sort.order === (column.reverseSort ? "desc" : "asc")
                          }
                        />
                        <ArrowDropDownIcon
                          data-active={
                            sort.columnDataIndex === column.dataIndex &&
                            sort.order === (column.reverseSort ? "asc" : "desc")
                          }
                        />
                      </div>
                    )}
                  </div>
                </Tooltip>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  // Модифицируем функцию для получения всех колонок (включая подколонки)
  const getAllColumns = useCallback(() => {
    return columns.reduce((acc, column) => {
      if (column.groupTitle && column.columns) {
        const groupColumns = column.columns.map((subColumn, index) => ({
          ...subColumn,
          inGroup: true,
          isFirstInGroup: index === 0,
          isLastInGroup: index === column.columns.length - 1,
        }));
        return [...acc, ...groupColumns];
      }
      return [...acc, column];
    }, []);
  }, [columns]);

  return (
    <Box
      {...rest}
      className={classNames(styles.root, className, classes.root, "scrollBox")}
      data-shadows={
        null
        // shadows
      }
      id="shadows">
      {isLoading && <LinearLoading className={styles.loading} />}
      <TableContainer
        className={classNames(styles.container, classes.container)}
        style={containerStyle}
        ref={scrollBoxRef}>
        <Table stickyHeader style={containerStyle}>
          {renderTableHead()}
          <TableBody>
            {data.length > 0 &&
              data.map((dataItem, rowIndex) => (
                <TableRow
                  key={`${dataItem.id}-${rowIndex}`}
                  className={
                    dataItem.level !== undefined && dataItem.level > 0 ? styles.childrenRow : null
                  }>
                  {getAllColumns().map((column, colIndex) => {
                    const tableCellStyle = propOr(
                      propOr({}, "style", prop(prop("dataIndex", column), dataItem)),
                      "style",
                      dataItem
                    );
                    return (
                      <TableCell
                        key={`${column.dataIndex}-${colIndex}`}
                        style={{
                          ...tableCellStyle,
                          padding: !isNil(propOr(null, "tooltip", prop(column.dataIndex, dataItem)))
                            ? 0
                            : 16,
                        }}
                        className={styles.cell}
                        data-sticky={Boolean(column.sticky)}
                        id={column.sticky ? "body" + colIndex : ""}>
                        <Tooltip title={propOr(null, "tooltip", prop(column.dataIndex, dataItem))}>
                          <Box
                            style={{
                              cursor: !isNil(
                                propOr(null, "tooltip", prop(column.dataIndex, dataItem))
                              )
                                ? "pointer"
                                : "auto",
                              padding: !isNil(
                                propOr(null, "tooltip", prop(column.dataIndex, dataItem))
                              )
                                ? 16
                                : 0,
                            }}>
                            {column.render
                              ? column.render(propOr("—", column.dataIndex, dataItem))
                              : prop("value", prop(column.dataIndex, dataItem))
                              ? dataItem[column.dataIndex].value
                              : propOr("—", column.dataIndex, dataItem)}
                          </Box>
                        </Tooltip>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            {data.length === 0 && (
              <TableRow>
                <TableCell colSpan={getAllColumns().length}>
                  <Box py={2} textAlign="center">
                    <p className={styles.noDataText}>{emptyMessage}</p>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(total > data.length || total > perPage) && (
        <Box py={2} className={styles.wrapper}>
          {!hidePerPage && (
            <Hidden smDown>
              <Box className={styles.perPage}>
                <Box mr={1}>
                  <Typography variant="subtitle1">Показать:</Typography>
                </Box>
                {[10, 25, 50, 100]
                  .filter((item, index, arr) => {
                    const prevItem = index > 0 ? arr[index - 1] : 0;
                    if (total >= item) return true;
                    else if (total <= item && total > prevItem) return true;
                    else return false;
                  })
                  .map(item => (
                    <PaginationItem
                      key={item}
                      classes={paginationStyles}
                      selected={perPage === item}
                      shape="rounded"
                      type="page"
                      variant="outlined"
                      onClick={() => onPerPageChange(item)}
                      page={item}
                    />
                  ))}
              </Box>
            </Hidden>
          )}

          {!hideCount ? (
            <Hidden xsDown>
              <Box mr={1}>
                <Typography variant="subtitle1" noWrap>
                  {(page - 1) * perPage + 1}-
                  {page === Math.ceil(total / perPage) ? total : page * perPage} из{" "}
                  {hasCountLimit && "отображаемых"} {numberWithSpaces(total)}
                </Typography>
              </Box>
            </Hidden>
          ) : null}
          <Pagination
            count={Math.ceil(total / perPage)}
            size={isMobile ? "small" : "medium"}
            variant="outlined"
            shape="rounded"
            classes={{
              ul: styles.flexWrapNowrap,
            }}
            onChange={onPageChange}
            page={page}
            renderItem={item => <PaginationItem classes={paginationStyles} {...item} />}
          />
        </Box>
      )}
    </Box>
  );
};

export default TableSort;
