import request from "root/js/request";

export const getAdvertAutoToPup = async ({ cabinet_id }) => {
  const response = await request({
    url: `/analytics/advert_autotopup/`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response;
};

export const postAdvertAutoToPup = async ({
  cabinet_id,
  advert_id,
  less_than,
  top_up_amount,
  not_more_than,
  time_period_days,
  is_active,
  restart_paused_advert,
  start_time,
  end_time,
}) => {
  const response = await request({
    url: `/analytics/advert_autotopup/`,
    method: "post",
    params: {
      cabinet_id,
    },
    data: {
      cabinet_id,
      advert_id,
      less_than,
      top_up_amount,
      not_more_than,
      time_period_days,
      is_active,
      restart_paused_advert,
      start_time,
      end_time,
    },
  });
  return response;
};
