export const MONTHS = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь"
];

export const SECONDS = 60;
export const MINUTES = 60;
export const HOURS = 24;
export const DAYS = 7;
export const MILLISECONDS = 1000;

export const WEEK = [
  {
    id: 1,
    label: "Понедельник"
  },
  {
    id: 2,
    label: "Вторник"
  },
  {
    id: 3,
    label: "Среда"
  },
  {
    id: 4,
    label: "Четверг"
  },
  {
    id: 5,
    label: "Пятница"
  },
  {
    id: 6,
    label: "Суббота"
  },
  {
    id: 7,
    label: "Воскресенье"
  },
  {
    id: 8,
    label: "Всего"
  }
];

export const TIME = [
  "00-01",
  "01-02",
  "02-03",
  "03-04",
  "04-05",
  "05-06",
  "06-07",
  "07-08",
  "08-09",
  "09-10",
  "10-11",
  "11-12",
  "12-13",
  "13-14",
  "14-15",
  "15-16",
  "16-17",
  "17-18",
  "18-19",
  "19-20",
  "20-21",
  "21-22",
  "22-23",
  "23-24"
];
