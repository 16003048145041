import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getGlobalEvents } from "root/api/analytics/global_events";

import DiagramLineHint from "root/components/global/diagrama/diagram-line-hint";
import LinearLoading from "root/components/LinearLoading";
import DataNotLoaded from "root/components/Plugs/DataNotLoaded";

import useActiveDots from "root/hooks/useActiveDots";
import useQueryAPI from "root/hooks/useQueryAPI";

import CheckboxesDiagram from "./components/CheckboxesDiagram";
import DiagramLine from "./components/DiagramLine";
import {
  checkZeroAxis,
  compareMonthAndDay,
  getYaxisDomain,
  getYaxisDomainRight,
  getYaxisDomainTwoLeft,
  getYaxisDomainTwoRight,
  hasValidValue,
  renderDot,
} from "./helpers";

import { numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

const IALineChartLocf = ({
  title,
  data,
  //--- Значение(руб, шт, %, л),
  //---Если в диаграмме только 1 currencyName, то передавать остальные не нужно ---
  currencyName = "",
  currencyNameTwo = currencyName,
  currencyNameThree = currencyName,
  currencyNameFour = currencyName,
  //--- Булевое значение чекбоксов и функция переключения  ---
  isCheckOne,
  setIsCheckOne,
  isCheckTwo,
  setIsCheckTwo,
  isCheckThree,
  setIsCheckThree,
  isCheckFour,
  setIsCheckFour,
  isTrend,
  setIsTrend,
  isEvents,
  setIsEvents,
  //--- Названия чекбоксов ---
  labelOne,
  labelTwo,
  labelThree,
  labelFour,
  //--- Цвета чекбоксов ---
  colorOne,
  colorTwo,
  colorThree,
  colorFour,
  //--- Чекбоксы  ---
  checkOne = true,
  checkTwo = false,
  checkThree = false,
  checkFour = false,
  trend = false,
  events = false,
  //--- Является ли баром, если да, вместо линии компонент Bar  ---
  isBarOne = false,
  isBarTwo = false,
  isBarThree = false,
  isBarFour = false,
  //--- Нужен ли цвет для основного YAxis ---
  YAxisLeftColor = false,
  //--- Нужны ли дополнительные YAxis ---
  isShowYAxisTwoLeft = false,
  isShowYAxisRight = false,
  isShowYAxisTwoRight = false,

  isHideTwoLeftLineY = true,
  eventsData = [],
  globalEventData = [],
  isLoading,
  customHeight,
  noDataText = "данные",
}) => {
  const { eventList } = useSelector(state => state.settingsDebriefing);

  const { rechartsRef } = useActiveDots();
  let formattedEventList = [];
  let formattedGlobalEventList = [];
  let mergedArray = [];
  const mergedData = {};
  const [activeEventDot, setActiveEventDot] = useState(null);

  const checkboxesConfig = [
    {
      label: labelOne,
      condition: checkOne,
      checked: isCheckOne,
      onChange: setIsCheckOne,
      className: styles.currentBox,
      color: colorOne,
    },
    {
      label: labelTwo,
      condition: checkTwo,
      checked: isCheckTwo,
      onChange: setIsCheckTwo,
      className: styles.prevBox,
      color: colorTwo,
    },
    {
      label: labelThree,
      condition: checkThree,
      checked: isCheckThree,
      onChange: setIsCheckThree,
      className: styles.currentCommissionWB,
      color: colorThree,
    },
    {
      label: labelFour,
      condition: checkFour,
      checked: isCheckFour,
      onChange: setIsCheckFour,
      className: styles.currentDiscountWB,
      color: colorFour,
    },
    {
      label: "Тренд",
      condition: trend,
      checked: isTrend,
      onChange: setIsTrend,
      className: styles.trendBox,
    },
    {
      label: "События",
      condition: events,
      checked: isEvents,
      onChange: setIsEvents,
      className: styles.eventBox,
    },
  ];

  globalEventData?.forEach(item => {
    if (eventsData.length) {
      let compareDateMinOne = compareMonthAndDay(
        new Date(eventsData[0].currentDate).getFullYear() + "-" + item.date,
        eventsData[0].currentDate,
        eventsData[eventsData.length - 1].currentDate
      );
      let compareDate = compareMonthAndDay(
        new Date(eventsData[eventsData.length - 1].currentDate).getFullYear() + "-" + item.date,
        eventsData[0].currentDate,
        eventsData[eventsData.length - 1].currentDate
      );

      if (compareDateMinOne || compareDate) {
        formattedGlobalEventList.push({
          date: `${new Date(item.date).getDate()} ${new Date(item.date).toLocaleString("ru-RU", {
            month: "short",
          })}`,
          eventName: item.name,
          fakeYAxis: 100,
        });
      }
    }
  });
  eventList?.forEach(item => {
    if (eventsData.length) {
      let compareDate = compareMonthAndDay(
        item.date,
        eventsData[0].currentDate,
        eventsData[eventsData.length - 1].currentDate
      );

      if (compareDate) {
        formattedEventList.push({
          date: `${new Date(item.date).getDate()} ${new Date(item.date).toLocaleString("ru-RU", {
            month: "short",
          })}`,
          eventName: item.name,
          fakeYAxis: 100,
        });
      }
    }
  });

  data?.forEach(item => {
    const date = item?.date;
    mergedData[date] = { ...mergedData[date], ...item };
  });

  formattedEventList?.forEach(item => {
    mergedData[item?.date] = { ...mergedData[item?.date], ...item };
  });

  formattedGlobalEventList?.forEach(item => {
    mergedData[item?.date] = { ...mergedData[item?.date], ...item };
  });

  mergedArray = Object.values(mergedData);

  const createCustomYAxisTick =
    (color, currencyName, xOffset = 0, hideCondition = false) =>
    props => {
      const { x, y, payload } = props;
      if (hideCondition) return null;

      const tickColor = color;
      const percentage = payload.value * 100;

      const formattedValue =
        currencyName === "руб" ||
        currencyName.includes("₽") ||
        currencyName === "шт" ||
        currencyName === "л"
          ? `${payload.value
              .toLocaleString("ru-RU", { maximumFractionDigits: 1 })
              .replace(",", ".")} ${currencyName}`
          : currencyName === "%"
          ? `${percentage
              .toLocaleString("ru-RU", { maximumFractionDigits: 1 })
              .replace(",", ".")} ${currencyName}`
          : new Intl.NumberFormat("en", {
              notation: "compact",
              compactDisplay: "short",
            }).format(payload.value);

      return (
        <g transform={`translate(${x},${y})`} width={100}>
          <text
            width={100}
            x={
              currencyName === "руб" || currencyName.includes("₽")
                ? xOffset + 20
                : payload.value < 100
                ? xOffset - 5
                : xOffset
            }
            y={-12}
            dy={16}
            fontSize={14}
            textAnchor="end"
            fill={tickColor}>
            {formattedValue}
          </text>
        </g>
      );
    };

  const CustomYAxisTick = createCustomYAxisTick(
    YAxisLeftColor ? colorOne : "#262626",
    currencyName,
    currencyName === "руб" || currencyName.includes("₽") ? -15 : 0
  );
  const CustomYAxisTickRight = createCustomYAxisTick(
    colorTwo,
    currencyNameTwo,
    currencyNameTwo === "шт" ? 60 : 35
  );
  const CustomYAxisTickTwoRight = createCustomYAxisTick(
    colorThree,
    currencyNameThree,
    currencyNameThree === "руб" || currencyName.includes("₽") ? 30 : 35
  );
  const CustomYAxisTickTwoLeft = createCustomYAxisTick(
    colorFour,
    currencyNameFour,
    5,
    isHideTwoLeftLineY
  );

  function yAxisTickFormater(value) {
    return `${numberWithSpaces(value)} ${currencyName}`;
  }

  const formatPeriod = (pressValue, currencyName) => {
    return (
      pressValue &&
      ((toolTipFormatter && toolTipFormatter(pressValue, currencyName)) ||
        `${numberWithSpaces(pressValue)} ${currencyName}`)
    );
  };

  const CustomTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
      const {
        payload: { checkOnePress, checkTwoPress, checkThreePress, checkFourPress, date },
      } = payload[0];
      const item = data?.find(el => el.date === date);

      const checkOnePeriod = formatPeriod(checkOnePress, currencyName);
      const checkTwoPeriod = formatPeriod(checkTwoPress, currencyNameTwo);
      const checkThreePeriod = formatPeriod(checkThreePress, currencyNameThree);
      const checkFourPeriod = formatPeriod(checkFourPress, currencyNameFour);

      return (
        <DiagramLineHint
          date={item?.dateTick ? item.dateTick : date}
          isCheckOne={isCheckOne}
          isCheckTwo={isCheckTwo}
          isCheckThree={isCheckThree}
          isCheckFour={isCheckFour}
          checkOneValue={checkOnePeriod}
          checkTwoValue={checkTwoPeriod}
          checkThreeValue={checkThreePeriod}
          checkFourValue={checkFourPeriod}
          checkOnePeriodText={labelOne}
          checkTwoPeriodText={labelTwo}
          checkThreePeriodText={labelThree}
          checkFourPeriodText={labelFour}
          colorOne={colorOne}
          colorTwo={colorTwo}
          colorThree={colorThree}
          colorFour={colorFour}
        />
      );
    }
    return null;
  };
  function toolTipFormatter(value, ...currencyNames) {
    const formatMap = {
      " ₽": v =>
        v > 10
          ? `${numberWithSpaces(v.toFixed(0))} ₽`
          : `${v.toLocaleString("ru-RU", { maximumFractionDigits: 1 })} ₽`,
      "₽": v =>
        v > 10
          ? `${numberWithSpaces(v.toFixed(0))} ₽`
          : `${v.toLocaleString("ru-RU", { maximumFractionDigits: 1 })} ₽`,
      руб: v =>
        v > 10
          ? `${numberWithSpaces(v.toFixed(0))} ₽`
          : `${v.toLocaleString("ru-RU", { maximumFractionDigits: 1 })} ₽`,
      "%": v => {
        const percentage = v * 100;
        return Number.isInteger(percentage)
          ? `${percentage.toLocaleString("ru-RU").replace(",", ".")} %`
          : `${percentage
              .toLocaleString("ru-RU", { maximumFractionDigits: 1 })
              .replace(",", ".")} %`;
      },
      шт: v => `${numberWithSpaces(v)} шт`,
      л: v => `${numberWithSpaces(v)} л`,
    };

    for (const currencyName of currencyNames) {
      if (currencyName && formatMap[currencyName]) {
        return formatMap[currencyName](value);
      }
    }
    return value;
  }

  const CustomTooltipBarEvents = ({ payload, active }) => {
    if (active && payload && payload.length) {
      const {
        payload: { date, eventName },
      } = payload[0];
      const item = data?.find(el => el.date === date);
      const events = eventName && eventName;

      return events ? (
        <DiagramLineHint
          date={item?.dateTick ? item.dateTick : date}
          isEvents={isEvents}
          eventsText="Событие"
          eventsValue={events}
        />
      ) : (
        ""
      );
    }
    return null;
  };

  const handleMouseEnter = () => {
    setActiveEventDot(true);
  };

  const handleMouseLeave = () => {
    setActiveEventDot(false);
  };

  const RenderCustomizedLabel = props => {
    const { x, y, width, value } = props;

    if (value) {
      return (
        <g onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
          <rect
            x={x + width / 2 - 7.5}
            y={y + 20}
            width={15}
            height={15}
            className={styles.hoveredPoint}
          />
        </g>
      );
    }
  };

  const isEmptyData = data?.every(item => {
    return (
      !hasValidValue(item.checkOnePress) &&
      !hasValidValue(item.checkTwoPress) &&
      !hasValidValue(item.checkThreePress) &&
      !hasValidValue(item.checkFourPress) &&
      !hasValidValue(item.zeroAxis) &&
      !hasValidValue(item.fakeYAxis)
    );
  });

  useEffect(() => {
    if (!isCheckOne && !isCheckTwo && !isTrend) {
      setIsCheckOne(true);
    } else if (!isTrend && !isCheckOne) {
      setIsCheckTwo(true);
    } else if (!isCheckTwo && !isCheckOne) {
      setIsTrend(true);
    }
  }, [isCheckOne, isCheckTwo, isTrend]);

  if (isLoading) {
    return <LinearLoading className={styles.linearLoading} />;
  }

  const normalizeData = data =>
    data.map(item => ({
      ...item,
      checkOnePress: item.checkOnePress || item.checkOnePress === 0 ? item.checkOnePress : NaN,
      checkTwoPress: item.checkTwoPress || item.checkTwoPress === 0 ? item.checkTwoPress : NaN,
      checkThreePress:
        item.checkThreePress || item.checkThreePress === 0 ? item.checkThreePress : NaN,
      checkFourPress: item.checkFourPress || item.checkFourPress === 0 ? item.checkFourPress : NaN,
    }));

  const normalizedData = normalizeData(mergedArray);
  return (
    <div className={styles.container}>
      {title && (
        <Typography sx={{ fontSize: 18, fontWeight: 500, color: "#43455C" }} pb={1}>
          {title}
        </Typography>
      )}
      <div className={styles.checkboxContainer}>
        <CheckboxesDiagram checkboxesConfig={checkboxesConfig} />
      </div>

      <div className={styles.scrollableBlock}>
        {isLoading ? <LinearLoading /> : isEmptyData && <DataNotLoaded text={noDataText} />}
        <DiagramLine
          rechartsRef={rechartsRef}
          data={normalizedData}
          isCheckOne={isCheckOne}
          isCheckTwo={isCheckTwo}
          isCheckThree={isCheckThree}
          isCheckFour={isCheckFour}
          isTrend={isTrend}
          isEvents={isEvents}
          yAxisTickFormater={yAxisTickFormater}
          toolTipFormatter={toolTipFormatter}
          CustomYAxisTick={CustomYAxisTick}
          CustomYAxisTickTwoLeft={CustomYAxisTickTwoLeft}
          CustomYAxisTickRight={CustomYAxisTickRight}
          CustomYAxisTickTwoRight={CustomYAxisTickTwoRight}
          CustomTooltip={CustomTooltip}
          CustomTooltipBarEvents={CustomTooltipBarEvents}
          RenderCustomizedLabel={RenderCustomizedLabel}
          customDotOneArea={({ payload, cx, cy }) =>
            renderDot(payload, cx, cy, "checkOnePress", data, colorOne)
          }
          customDotTwoArea={({ payload, cx, cy }) =>
            renderDot(payload, cx, cy, "checkTwoPress", data, colorTwo)
          }
          customDotThreeArea={({ payload, cx, cy }) =>
            renderDot(payload, cx, cy, "checkThreePress", data, colorThree)
          }
          customDotFourArea={({ payload, cx, cy }) =>
            renderDot(payload, cx, cy, "checkFourPress", data, colorFour)
          }
          isZeroAxis={checkZeroAxis(data)}
          checkOne={checkOne}
          checkTwo={checkTwo}
          checkThree={checkThree}
          checkFour={checkFour}
          trend={trend}
          events={events}
          colorOne={colorOne}
          colorTwo={colorTwo}
          colorThree={colorThree}
          colorFour={colorFour}
          getYaxisDomain={() => getYaxisDomain(data)}
          getYaxisDomainRight={() => getYaxisDomainRight(data)}
          getYaxisDomainTwoRight={() => getYaxisDomainTwoRight(data)}
          getYaxisDomainTwoLeft={() => getYaxisDomainTwoLeft(data)}
          activeEventDot={activeEventDot}
          isBarOne={isBarOne}
          isBarTwo={isBarTwo}
          isBarThree={isBarThree}
          isBarFour={isBarFour}
          YAxisLeftColor={YAxisLeftColor}
          isShowYAxisRight={isShowYAxisRight}
          isShowYAxisTwoRight={isShowYAxisTwoRight}
          isShowYAxisTwoLeft={isShowYAxisTwoLeft}
          isHideTwoLeftLineY={isHideTwoLeftLineY}
          customHeight={customHeight}
        />
      </div>
    </div>
  );
};

export default IALineChartLocf;
