import request from "root/js/request";

const getSettings = async ({ wb_api_key_ids }) => {
  const response = await request({
    url: "/analytics/settings/",
    method: "get",
    params: {
      wb_api_key_ids,
    },
  });
  return response.data;
};

const saveSettings = async ({
  wb_api_key_id,
  cost_function,
  clamped_avg_max_enters,
  clamped_avg_max_days,
  count_kits,
  tax_mode,
  tax_mode_rate,
}) => {
  const response = await request({
    url: `/analytics/settings/`,
    method: "post",
    data: {
      wb_api_key_id,
      cost_function,
      clamped_avg_max_enters,
      clamped_avg_max_days,
      count_kits,
      tax_mode,
      tax_mode_rate,
    },
  });
  return response.data;
};

export { getSettings, saveSettings };
