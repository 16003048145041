import React, { useEffect, useRef, useState } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Drawer } from "@mui/material";
import { InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import classNames from "classnames";
import cn from "classnames";
import { batch, useDispatch, useSelector } from "react-redux";
import SelectorWithDrawer from "root/entities/SelectorsWithDrawer/default";
import { GetRealizationReportArticle } from "root/store/financialLogic/action";
import {
  clearSelector,
  clearSelectors,
  getIdArticul,
  getIdBarcod,
  getIdBrand,
  getIdCategory,
  getIdProducts,
  getSubjectId,
} from "root/store/financialLogic/financialLogicSlice";

import HintBox from "root/components/global/hint-box";
import TableImage from "root/components/TableImage";
import TooltipUI from "root/components/Tooltip";
import { calcDate, formatDate } from "root/pages/financial-logic/helpers";

import { isMobile } from "../../../js/utils";

import "./styles.css";
import { filterOnlyNumbers } from "root/js/utils";

import styles from "./index.module.scss";

const SelectItems = ({
  disabledSelects,
  setMounths,
  clearDate,
  shownItemsList = [],
  isLastYear,
  clearPageData,
  isPromotionPage,
  isPaidStoragePage,
}) => {
  const {
    keys,
    dateFrom,
    dateTo,
    financialLogicBrand,
    financialLogicCategory,
    financialLogicBarcodeSearch,
    subjects,
    isLoadingKeyIndicatorsWidgets,
    categoryId,
    brandIds,
    articulIds,
    productIds,
    subjectsId,
    barcodeIds,
    realizationReportArticle,
    realizationReportProducts,
  } = useSelector(state => state.financeLogic);
  const [showClearFiltersButton, setShowClearFiltersButton] = useState(false);
  const [isUpdatedOptions, setIsUpdatedOptions] = useState(false);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [isOpenCategories, setIsOpenCategories] = useState(false);

  const [brand, setBrand] = useState([]);
  const [isOpenBrand, setIsOpenBrand] = useState(false);
  const [isShowBrand, setIsShowBrand] = useState(false);

  const [articul, setArticul] = useState([]);
  const [products, setProducts] = useState([]);

  const [barcode, setBarcode] = useState([]);
  const [isOpenBarcode, setIsOpenBarcode] = useState(false);
  const [isShowBarcode, setIsShowBarcode] = useState(false);

  const [subject, setSubject] = useState([]);
  const [isOpenSubject, setIsOpenSubject] = useState(false);
  const [isShowSubject, setIsShowSubject] = useState(false);

  const [isArticlePopupOpen, setIsArticlePopupOpen] = useState(false);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isViewActive, setIsViewActive] = useState(false);
  const [articleList, setArticleList] = useState([]);

  const [articleSearch, setArticleSearch] = useState("");
  const [codeWbSearch, setCodeWbSearch] = useState("");

  const [selectorTexts, setSelectorTexts] = useState({
    categories: "Выберите категории",
    subject: "Выберите предметы",
    brand: "Выберите бренды",
    barcode: "Выберите баркоды",
  });

  const uniqueArticleList = new Set();

  const filteredArticleList = articleList.filter(el => {
    const duplicate = uniqueArticleList.has(el.product_id);
    uniqueArticleList.add(el.product_id);
    return !duplicate;
  });

  const ref = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref6 = useRef();

  const previousValueRef = useRef("");

  const currentPageUrl = window.location.href;

  let dateFromLastYear,
    dateToLastYear = false;
  if (isLastYear) {
    dateToLastYear = calcDate(
      formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
      ),
      1
    );
    dateFromLastYear = formatDate(
      new Date(
        dateToLastYear.getFullYear() - 1,
        dateToLastYear.getMonth(),
        dateToLastYear.getDate()
      )
    );
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (
        isOpenCategories &&
        !!ref.current &&
        !ref.current.contains(e.target) &&
        !e.target.closest(".MuiAutocomplete-listbox")
      ) {
        closeAll();
      }
      if (
        isOpenSubject &&
        !!ref2.current &&
        !ref2.current.contains(e.target) &&
        !e.target.closest(".MuiAutocomplete-listbox")
      ) {
        closeAll();
      }
      if (
        isOpenBrand &&
        ref3.current &&
        !ref3.current.contains(e.target) &&
        !e.target.closest(".MuiAutocomplete-listbox")
      ) {
        closeAll();
      }
      if (
        isOpenBarcode &&
        ref6.current &&
        !ref6.current.contains(e.target) &&
        !e.target.closest(".MuiAutocomplete-listbox")
      ) {
        closeAll();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);

    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [
    isOpenCategories,
    isOpenSubject,
    isOpenBrand,
    isOpenBarcode,
    categories,
    brand,
    articul,
    barcode,
    subject,
  ]);

  useEffect(() => {
    if (isLoadingKeyIndicatorsWidgets === false) {
      setIsShowBrand(false);
      setIsShowSubject(false);
      setIsShowBarcode(false);
    }
  }, [isLoadingKeyIndicatorsWidgets]);

  const handleCancel = () => {
    setBrand([]);
    setSubject([]);
    setMounths([]);
    setArticul([]);
    setProducts([]);
    setBarcode([]);
    setCategories([]);
    setSelectedArticles([]);
    setSelectedProducts([]);
    if (clearPageData) dispatch(clearPageData());
    dispatch(clearSelectors());
    dispatch(clearDate());
    setIsUpdatedOptions(true);
    setShowClearFiltersButton(false);
  };

  useEffect(() => {
    if (
      categories.length === 0 &&
      subject.length === 0 &&
      brand.length === 0 &&
      articul.length === 0 &&
      barcode.length === 0
    ) {
      setShowClearFiltersButton(false);
    }
  }, [subject, brand, categories, articul, barcode]);
  const getOptions = () => {
    if (keys?.length > 0) {
      dispatch(
        GetRealizationReportArticle({
          keys,
          dateFrom: dateFromLastYear ? dateFromLastYear : dateFrom,
          dateTo: dateToLastYear ? dateToLastYear : dateTo,
          brand_id: brand.map(({ id }) => id).join(),
          category_id: categories.map(({ id }) => id).join(),
          subject_id: subject.map(({ id }) => id).join(),
          article: articul.map(({ supplier_article }) => supplier_article),
        })
      );
    }
  };

  useEffect(() => {
    if (
      categories.length === 0 &&
      subject.length === 0 &&
      brand.length === 0 &&
      articul.length === 0 &&
      barcode.length === 0
    ) {
      setShowClearFiltersButton(false);
    }
  }, [subject, brand, categories, articul, barcode]);

  const closeAll = () => {
    setIsOpenBarcode(prev => {
      if (prev) {
        setBarcode(financialLogicBarcodeSearch.filter(el => barcodeIds.includes(el.barcode)));
      }
    });
    setIsOpenBrand(prev => {
      if (prev) {
        setBrand(financialLogicBrand.filter(el => brandIds.includes(el.id)));
      }
    });
    setIsOpenSubject(prev => {
      if (prev) {
        setSubject(subjects.filter(el => subjectsId.includes(el.id)));
      }
    });
    setIsOpenCategories(prev => {
      if (prev) {
        setCategories(financialLogicCategory.filter(el => categoryId.includes(el.id)));
      }
    });
  };

  const handleFilter = (empty = "") => {
    setShowClearFiltersButton(false);
    setIsOpenCategories(false);
    setIsOpenSubject(false);
    setIsOpenBrand(false);
    setIsOpenBarcode(false);

    batch(() => {
      if (clearPageData) dispatch(clearPageData());
      dispatch(getIdBrand(empty && empty === "brand" ? [] : brand.map(({ id }) => id)));
      dispatch(getSubjectId(empty && empty === "subject" ? [] : subject.map(({ id }) => id)));
      dispatch(
        getIdCategory(empty && empty === "categories" ? [] : categories.map(({ id }) => id))
      );
      dispatch(
        getIdBarcod(empty && empty === "barcode" ? [] : barcode.map(({ barcode }) => barcode))
      );
      dispatch(clearDate());
      setSelectedArticles(
        empty && empty === "article" ? [] : articul.map(({ supplier_article }) => supplier_article)
      );
    });

    if (empty) setIsUpdatedOptions(true);

    if (
      categories.length > 0 ||
      subject.length > 0 ||
      brand.length > 0 ||
      articul.length > 0 ||
      barcode.length > 0
    ) {
      setShowClearFiltersButton(true);
    }
  };

  const closePopup = () => {
    setIsArticlePopupOpen(false);
    setSelectedArticles(articulIds);
    setSelectedProducts(productIds);
  };

  const chooseArticles = element => evt => {
    selectedArticles?.includes(element.supplier_article)
      ? setSelectedArticles(selectedArticles?.filter(el => el !== element.supplier_article))
      : setSelectedArticles(state => [...state, element.supplier_article]);

    selectedProducts?.includes(element.product_id)
      ? setSelectedProducts(selectedProducts?.filter(el => el !== element.product_id))
      : setSelectedProducts(state => [...state, element.product_id]);
    if (isViewActive) {
      const intersection = realizationReportArticle.filter(el =>
        selectedArticles
          ?.filter(el => el !== element.supplier_article)
          ?.includes(el.supplier_article)
      );
      setArticleList(intersection);
    }
  };

  const handleChooseArticles = () => {
    if (clearPageData) dispatch(clearPageData());
    dispatch(clearDate());
    setIsArticlePopupOpen(false);
    dispatch(getIdArticul(selectedArticles));
    dispatch(getIdProducts(selectedProducts));
    setArticul(selectedArticles.map(el => ({ supplier_article: el })));
    setProducts(selectedProducts.map(el => ({ product_id: el })));
    if (selectedArticles?.length > 0) setShowClearFiltersButton(true);
    setIsUpdatedOptions(true);
  };

  const filterArticlesAccordingToSearch = data => {
    if (!codeWbSearch && !articleSearch) {
      setArticleList(data);
    } else if (codeWbSearch && !articleSearch) {
      const res = data?.filter(el => el.product_id.toString().includes(codeWbSearch));
      setArticleList(res);
    } else if (!codeWbSearch && articleSearch) {
      const res = data?.filter(el =>
        el.supplier_article.toLowerCase().includes(articleSearch.toLowerCase())
      );
      setArticleList(res);
    } else {
      const resCodeWb = data?.filter(el => el.product_id.toString().includes(codeWbSearch));
      const res = resCodeWb?.filter(el =>
        el.supplier_article.toLowerCase().includes(articleSearch.toLowerCase())
      );
      setArticleList(res);
    }
  };
  const handleViewClick = () => {
    if (isViewActive) {
      setIsViewActive(false);
      filterArticlesAccordingToSearch(realizationReportArticle);
    } else {
      setIsViewActive(true);
      const intersection = realizationReportArticle.filter(el =>
        selectedArticles.includes(el.supplier_article)
      );
      filterArticlesAccordingToSearch(intersection);
    }
  };

  const openArticlesPopap = evt => {
    if (evt.target.closest(".clearButtonInSelector")) return;
    setIsArticlePopupOpen(true);
  };

  const clearArticles = () => {
    setSelectedArticles([]);
    setSelectedProducts([]);
    handleFilter("article");
    dispatch(clearSelector("article"));
    setArticul([]);
    setProducts([]);
    dispatch(getIdArticul([]));
    dispatch(getIdProducts([]));
  };

  const handleChangeArticle = evt => {
    const filteredValue = filterOnlyNumbers(evt.target.value);
    evt.target.value = filteredValue;
    setArticleSearch(evt.target.value);
    previousValueRef.current = filteredValue;
  };

  const handleChangeCodeWb = evt => {
    const filteredValue = filterOnlyNumbers(evt.target.value);
    evt.target.value = filteredValue;
    setCodeWbSearch(evt.target.value);
    previousValueRef.current = filteredValue;
  };

  useEffect(() => {
    if (isViewActive) {
      const intersection = realizationReportArticle.filter(el =>
        selectedArticles.includes(el.supplier_article)
      );
      filterArticlesAccordingToSearch(intersection);
    } else {
      filterArticlesAccordingToSearch(realizationReportArticle);
    }
  }, [articleSearch, codeWbSearch]);

  useEffect(() => {
    if (isUpdatedOptions) {
      setIsUpdatedOptions(false);
    }
  }, [isUpdatedOptions, keys.join(",")]);

  useEffect(() => {
    if (keys.length > 0 && ((dateFrom && dateTo) || (dateFromLastYear && dateToLastYear))) {
      setIsUpdatedOptions(true);
    }
  }, [keys, dateFrom, isPromotionPage === true, isPaidStoragePage === true]);

  useEffect(() => {
    getOptions();
    setIsUpdatedOptions(false);
  }, [keys.join(","), barcode, categories, subject, brand]);

  useEffect(() => {
    if (
      barcodeIds?.length > 0 ||
      brandIds?.length > 0 ||
      articulIds?.length > 0 ||
      categoryId?.length > 0 ||
      subjectsId?.length > 0
    ) {
      setShowClearFiltersButton(true);
      const initBarcode = financialLogicBarcodeSearch?.filter(el =>
        barcodeIds?.includes(el.barcode)
      );
      const initBarnd = financialLogicBrand?.filter(el => brandIds?.includes(el.id));
      const initArticle = realizationReportArticle?.filter(el =>
        articulIds?.includes(el.supplier_article)
      );
      const initProducts = realizationReportArticle?.filter(el =>
        productIds?.includes(el.product_id)
      );
      const initCategory = financialLogicCategory?.filter(el => categoryId?.includes(el.id));
      const initSubject = subjects?.filter(el => subjectsId?.includes(el.id));

      setBarcode(initBarcode ? initBarcode : []);
      setBrand(initBarnd ? initBarnd : []);
      setArticul(initArticle ? initArticle : []);
      setProducts(initProducts ? initProducts : []);
      setSelectedArticles(articulIds);
      setSelectedProducts(productIds);
      setCategories(initCategory ? initCategory : []);
      setSubject(initSubject ? initSubject : []);
      setIsUpdatedOptions(true);
    }
  }, []);

  useEffect(() => {
    if (disabledSelects) {
      if (disabledSelects.includes("category") && categories?.length > 0) {
        dispatch(clearSelector("categories"));
        setCategories([]);
      }
      if (disabledSelects.includes("subject") && subject?.length > 0) {
        dispatch(clearSelector("subject"));
        setSubject([]);
      }
      if (disabledSelects.includes("brand") && brand?.length > 0) {
        dispatch(clearSelector("brand"));
        setBrand([]);
      }
      if (disabledSelects.includes("article") && articul?.length > 0) {
        dispatch(clearSelector("article"));
        setArticul([]);
        setSelectedArticles([]);
        setProducts([]);
        setSelectedProducts([]);
      }

      if (disabledSelects.includes("barcode") && barcode?.length > 0) {
        dispatch(clearSelector("barcode"));
        setBarcode([]);
      }
    }
  }, [currentPageUrl, disabledSelects]);

  useEffect(() => {
    if (realizationReportArticle) setArticleList(realizationReportArticle);
  }, [realizationReportArticle]);

  useEffect(() => {
    const clearSelectors = () => {
      if (categories?.length > 0) {
        setCategories([]);
        dispatch(clearSelector("categories"));
      }
      if (brand?.length > 0) {
        setBrand([]);
        dispatch(clearSelector("brand"));
      }
      if (articul?.length > 0) {
        clearArticles();
      }
      if (barcode?.length > 0) {
        setBarcode([]);
        dispatch(clearSelector("barcode"));
      }
      if (subject?.length > 0) {
        setSubject([]);
        dispatch(clearSelector("subject"));
      }
    };
    const timeout = setTimeout(clearSelectors(), 1000);

    return function () {
      clearTimeout(timeout);
    };
  }, [keys]);

  useEffect(() => {
    if (isArticlePopupOpen) {
      const selectedItems = articul
        .filter(el =>
          realizationReportArticle.find(item => el.supplier_article === item.supplier_article)
        )
        ?.map(el => el.supplier_article);
      setSelectedArticles(selectedItems);
      const selectedItemsProducts = products
        .filter(el => realizationReportArticle.find(item => el.product_id === item.product_id))
        ?.map(el => el.product_id);
      setSelectedProducts(selectedItemsProducts);
    }
  }, [isArticlePopupOpen]);

  window.onscroll = function () {
    let scrolled = window.pageYOffset || document.documentElement.scrollTop;
    let widthDocument = document.documentElement.offsetWidth;
    let stickyStart;

    if (widthDocument > 960) {
      stickyStart = 310;
    } else if (widthDocument < 959) {
      stickyStart = 376;
    } else if (widthDocument < 760) {
      stickyStart = 441;
    }

    const stickyBlock = document.getElementById("sticky-block");

    if (stickyBlock) {
      if (scrolled >= stickyStart) {
        if (isMobile || widthDocument < 600) return;
        stickyBlock.classList.add("fixed-top");
      } else {
        stickyBlock.classList.remove("fixed-top");
      }
    }
  };

  useEffect(() => {
    handleFilter();
  }, [categories, subject, brand, articul, barcode]);

  useEffect(() => {
    setSelectorTexts({
      categories:
        categories.length === 0
          ? "Выберите категории"
          : `Выбрано категорий - ${categories.length} шт.`,
      subject:
        subject.length === 0 ? "Выберите предметы" : `Выбрано предметов - ${subject.length} шт.`,
      brand: brand.length === 0 ? "Выберите бренды" : `Выбрано брендов - ${brand.length} шт.`,
      barcode:
        barcode.length === 0 ? "Выберите баркоды" : `Выбрано баркодов - ${barcode.length} шт.`,
    });
  }, [categories, subject, brand, barcode]);

  useEffect(() => {
    const hasFilters = brand?.length > 0 || categories?.length > 0 || subject?.length > 0;

    if (hasFilters && selectedArticles.length !== articulIds.length) {
      clearArticles();
    }

    if (hasFilters && selectedArticles.length === 0 && articulIds.length === 0) {
      setSelectedArticles(articulIds);
      setSelectedProducts(productIds);
    }
  }, [brand, articulIds, categories, subject]);

  return (
    <div id={"sticky-block"} className={styles.wrapperBlock}>
      {showClearFiltersButton && (
        <div className={styles.clearFiltersBlock}>
          <Box onClick={handleCancel} className={classNames(styles.ClearButtonBox)}>
            <span>Сбросить ранее выбранные фильтры</span>
            <IconButton className={styles.clearButton}>
              <ClearIcon className={styles.clearIcon} />
            </IconButton>
          </Box>
        </div>
      )}

      <div className={styles.financialSelectContainer}>
        {((shownItemsList.length > 0 && shownItemsList.includes("category")) ||
          shownItemsList.length == 0) && (
          <div className={styles.autocompleteWrap}>
            <Box>
              <SelectorWithDrawer
                checkedItems={categories}
                setCheckedItems={setCategories}
                apiMethodName={"useGetBrandFinancialCategoriesQuery"}
                emptySelectorText={"Выберите категории"}
                noEmptySelectorText={selectorTexts.categories}
                fetchParams={{
                  key: keys[0],
                  dateFrom: dateFromLastYear ? dateFromLastYear : dateFrom,
                  dateTo: dateToLastYear ? dateToLastYear : dateTo,
                  subject: subject.map(({ id }) => id).join() || undefined,
                  brand: brand.map(({ id }) => id).join() || undefined,
                  barcode: barcode.map(({ barcode }) => barcode).join() || undefined,
                }}
                getCorrectDataAndTotal={data => [data, data.length]}
                onChange={handleFilter}
                disabled={
                  (disabledSelects?.length > 0 && disabledSelects.includes("category")) ||
                  barcode.length >= 1 ||
                  articul.length >= 1
                }
              />
            </Box>
          </div>
        )}

        {((shownItemsList.length > 0 && shownItemsList.includes("subject")) ||
          shownItemsList.length == 0) && (
          <div className={styles.autocompleteWrap}>
            <Box>
              <SelectorWithDrawer
                checkedItems={subject}
                setCheckedItems={setSubject}
                apiMethodName={"useGetSubjectsQuery"}
                emptySelectorText={"Выберите предметы"}
                noEmptySelectorText={selectorTexts.subject}
                fetchParams={{
                  key: keys[0],
                  dateFrom: dateFromLastYear ? dateFromLastYear : dateFrom,
                  dateTo: dateToLastYear ? dateToLastYear : dateTo,
                  category: categories.map(({ id }) => id).join() || undefined,
                  brand: brand.map(({ id }) => id).join() || undefined,
                  barcode: barcode.map(({ barcode }) => barcode).join() || undefined,
                }}
                getCorrectDataAndTotal={data => [data, data.length]}
                onChange={handleFilter}
                disabled={
                  (disabledSelects?.length > 0 && disabledSelects.includes("subject")) ||
                  barcode.length >= 1 ||
                  articul.length >= 1
                }
              />
            </Box>
          </div>
        )}

        {((shownItemsList.length > 0 && shownItemsList.includes("brand")) ||
          shownItemsList.length == 0) && (
          <div className={styles.autocompleteWrap}>
            <SelectorWithDrawer
              checkedItems={brand}
              setCheckedItems={setBrand}
              apiMethodName={"useGetBrandFinancialLogicQuery"}
              emptySelectorText={"Выберите бренды"}
              noEmptySelectorText={selectorTexts.brand}
              fetchParams={{
                key: keys[0],
                dateFrom: dateFromLastYear ? dateFromLastYear : dateFrom,
                dateTo: dateToLastYear ? dateToLastYear : dateTo,
                category: categories.map(({ id }) => id).join() || undefined,
                brand_ids: brand.map(({ id }) => id).join() || undefined,
                subject: subject.map(({ id }) => id).join() || undefined,
                barcode: barcode.map(({ barcode }) => barcode).join() || undefined,
              }}
              getCorrectDataAndTotal={data => [data, data.length]}
              onChange={handleFilter}
              disabled={
                (disabledSelects?.length > 0 && disabledSelects.includes("brand")) ||
                barcode.length >= 1 ||
                articul.length >= 1
              }
            />
          </div>
        )}

        <div className={styles.btnBlock}>
          <TooltipUI
            // title={
            //   articul?.length && (
            //     <div>
            //       {articul
            //         .filter(el =>
            //           realizationReportArticle.find(
            //             item => el.supplier_article === item.supplier_article
            //           )
            //         )
            //         ?.map((elem, index) => {
            //           return (
            //             <div key={index}>
            //               <span>{elem.supplier_article}</span>
            //             </div>
            //           );
            //         })}
            //     </div>
            //   )
            // }
            title={
              products?.length && (
                <div>
                  {products?.map((elem, index) => {
                    return (
                      <div key={index}>
                        <span>{elem.product_id}</span>
                      </div>
                    );
                  })}
                </div>
              )
            }>
            <Button
              className={cn(styles.bthChoose, {
                [styles.buttonChip]: articul?.length > 0,
                [styles.disabledButton]:
                  (disabledSelects?.length > 0 && disabledSelects.includes("article")) ||
                  barcode.length >= 1,
              })}
              variant="outlined"
              disabled={
                (disabledSelects?.length > 0 && disabledSelects.includes("article")) ||
                barcode.length >= 1
              }
              onClick={openArticlesPopap}
              endIcon={articul?.length > 0 ? null : <FilterAltOutlinedIcon />}>
              {articul?.length > 0 ? (
                <>
                  <Chip
                    className={styles.fSize15}
                    size="medium"
                    variant="outlined"
                    label={`Выбрано артикулов -
                     ${
                       articul.filter(el =>
                         realizationReportArticle.find(
                           item => el.supplier_article === item.supplier_article
                         )
                       )?.length
                     } шт.`}
                  />
                  <span
                    className={cn(styles.buttonLabel, {
                      [styles.disabled]:
                        (disabledSelects?.length > 0 && disabledSelects.includes("article")) ||
                        barcode.length >= 1,
                    })}>
                    Артикул
                  </span>
                  <IconButton
                    className={cn(styles.clearButtonInSelector, "clearButtonInSelector")}
                    onClick={clearArticles}>
                    <ClearIcon className={styles.clearIconInSelector} />
                  </IconButton>
                </>
              ) : (
                "Выберите артикулы"
              )}
            </Button>
          </TooltipUI>

          <Drawer
            anchor="right"
            open={isArticlePopupOpen}
            onClose={closePopup}
            className={styles.drawerWrap}
            style={{ width: "100%" }}>
            <Box
              px={1}
              py={1}
              display="flex"
              justifyContent="end"
              className={styles.closeIconBlock}>
              <IconButton
                onClick={closePopup}
                sx={{
                  zIndex: 12,
                }}>
                <CloseIcon fill="gray" />
              </IconButton>
            </Box>
            <Box>
              <FormControl component="fieldset" variant="standard">
                <FormGroup className={styles.articleList}>
                  {selectedArticles &&
                    filteredArticleList &&
                    filteredArticleList.length > 0 &&
                    filteredArticleList.map(el => (
                      <FormControlLabel
                        key={el.product_id}
                        className={styles.label}
                        control={
                          <Checkbox
                            checked={selectedArticles?.includes(el.supplier_article)}
                            onChange={chooseArticles(el)}
                            name={el.supplier_article}
                            className={cn(styles.checkbox, {
                              [styles.checkboxActive]: selectedArticles?.includes(
                                el.supplier_article
                              ),
                            })}
                            checkedIcon={<CheckCircleIcon />}
                            icon={<CheckCircleIcon />}
                          />
                        }
                        label={
                          <TooltipUI
                            title={
                              <div className={styles.tooltip}>
                                <HintBox name="Артикул" value={el.product_id} />
                                <HintBox name="Наименование" value={el.product_name} />
                              </div>
                            }>
                            <div
                              className={cn(styles.articleWrap, {
                                [styles.articleWrapActive]: selectedArticles?.includes(
                                  el.supplier_article
                                ),
                              })}>
                              <TableImage
                                src={el.product_thumbnail}
                                className={styles.imgArticle}
                              />
                              <span className={styles.article}>{el.product_id}</span>
                            </div>
                          </TooltipUI>
                        }
                      />
                    ))}
                  {selectedArticles && articleList && articleList.length === 0 && (
                    <p className={styles.emptyMessage}>Ничего не найдено</p>
                  )}
                </FormGroup>
              </FormControl>
            </Box>
            <div className={styles.controlBlock}>
              <div className={styles.viewBlock}>
                <p
                  className={
                    styles.countMessage
                  }>{`Выбрано ${selectedArticles?.length} товаров`}</p>
                <IconButton className={styles.eyeButton} onClick={handleViewClick}>
                  <VisibilityIcon className={cn({ [styles.activeIcon]: isViewActive })} />
                </IconButton>
              </div>
              <div className={styles.searchBlock}>
                {/* <TextField
                  value={articleSearch}
                  placeholder="Поиск по артикулу"
                  onChange={handleChangeArticle}
                  className={styles.inputSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <TextField
                  value={codeWbSearch}
                  placeholder="Поиск по коду WB"
                  onChange={handleChangeCodeWb}
                  className={styles.inputSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className={styles.viewBlock}>
                <p
                  className={
                    styles.countMessage
                  }>{`Выбрано ${selectedArticles?.length} товаров`}</p>
                <IconButton className={styles.eyeButton} onClick={handleViewClick}>
                  <VisibilityIcon className={cn({ [styles.activeIcon]: isViewActive })} />
                </IconButton>
              </div> */}
              <Button
                color="primary"
                variant="contained"
                onClick={handleChooseArticles}
                className={styles.btnSaveArticles}>
                Применить
              </Button>
            </div>
          </Drawer>
        </div>

        {((shownItemsList.length > 0 && shownItemsList.includes("barcode")) ||
          shownItemsList.length == 0) && (
          <div className={styles.autocompleteWrap}>
            <SelectorWithDrawer
              checkedItems={barcode}
              setCheckedItems={setBarcode}
              apiMethodName={"useGetBarcodesSearchQuery"}
              emptySelectorText={"Выберите баркод"}
              noEmptySelectorText={selectorTexts.barcode}
              fetchParams={{
                key: keys[0],
                dateFrom: dateFromLastYear ? dateFromLastYear : dateFrom,
                dateTo: dateToLastYear ? dateToLastYear : dateTo,
                category: categories.map(({ id }) => id).join() || undefined,
                brandsId: brand.map(({ id }) => id).join() || undefined,
                subject: subject.map(({ id }) => id).join() || undefined,
                aritucl: JSON.stringify(articulIds) || undefined,
              }}
              getCorrectDataAndTotal={data => {
                const obj = data.map(({ barcode }) => {
                  return { barcode, id: barcode, name: barcode };
                });
                return [obj, data.length];
              }}
              onChange={handleFilter}
              disabled={
                (disabledSelects?.length > 0 && disabledSelects.includes("barcode")) ||
                financialLogicBarcodeSearch.length <= 1
                  ? true
                  : false
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectItems;
