import React, { useRef } from "react";

import { isNil } from "ramda";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import HintBoxCircle from "root/components/global/hint-box-circle";
import SkeletonWhite from "root/components/SkeletonWhite";

import { NoDataMassage } from "../../../../../../components/NoDataMessage";

import { getAxisDate, getMinPointSize, numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

const PenaltyDynamic = props => {
  const { tab, rechartsRef } = props;
  const {
    penaltyTypes,
    singlePenaltyType,
    dateFrom,
    currencyName,
    dateTo,
    isLoadingPenaltyDynamic,
    penaltyTypeName,
  } = useSelector(state => state.financeLogic);
  const diagramRef = useRef(null);

  const CustomYAxisTick = props => {
    const { x, y, payload } = props;
    const tickColor = "#262626";
    return (
      <g transform={`translate(${x},${y})`} width={130}>
        <text width={130} x={0} y={-12} dy={16} textAnchor="end" fill={tickColor}>
          {tab && tab === "penalty"
            ? `${numberWithSpaces(payload.value.toFixed(0))} ${currencyName}`
            : `${payload.value} шт`}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ payload, active }) => {
    if (active && payload && payload.length) {
      const {
        payload: { valueSum, date },
      } = payload[0];

      payload.forEach(item => {
        const matchingPenaltyType = singlePenaltyType.find(
          penalty => penalty.type_name === item.dataKey
        );
        item.color = matchingPenaltyType ? matchingPenaltyType.color : "green";
      });

      const result =
        tab === "penalty"
          ? `${numberWithSpaces(valueSum.toFixed(0))} ${currencyName}`
          : `${valueSum} шт`;

      const title = tab === "penalty" ? "Сумма штрафов" : "Количество штрафов";
      const currency = tab === "penalty" ? currencyName : "шт";

      const hintTitle = tab === "penalty" ? "Общая сумма" : "Общее количество";

      return (
        <div className={styles.diagramDecription}>
          <p className={styles.hintTitle}>{date}</p>
          <span>{title}: </span>

          <p style={{ color: payload[0].color }}>
            {payload.map((elem, index) => {
              return (
                <HintBoxCircle
                  key={index}
                  circleColor={elem.color}
                  name={elem.dataKey}
                  value={
                    elem.value && elem.value !== null
                      ? `${numberWithSpaces(elem.value.toFixed(0))} ${currency}`
                      : "нет данных"
                  }
                />
              );
            })}
          </p>
          <span>{payload.length > 1 && <HintBoxCircle name={hintTitle} value={result} />}</span>
        </div>
      );
    }

    return null;
  };
  const dynamicByTypeName = penaltyTypes.reduce((acc, i) => {
    acc[i.type_name] = (i.dynamic || []).reduce((aggr, { date, value }) => {
      aggr[date] = value;

      return aggr;
    }, {});
    return acc;
  }, {});

  const ONE_DAY = 24 * 60 * 60 * 1000;
  const dateToDateKey = dt =>
    `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(
      -2
    )}`;
  const daysCount = (dateTo - dateFrom) / ONE_DAY;

  const data = Array.from({ length: daysCount }).map((_, i) => {
    const date = new Date(dateFrom.getTime() + ONE_DAY * i);

    const dateKey = dateToDateKey(date);

    return {
      date: getAxisDate(dateKey),

      ...penaltyTypes.reduce(
        (acc, { type_name }) => {
          acc[type_name] = dynamicByTypeName[type_name] && dynamicByTypeName[type_name][dateKey];
          if (penaltyTypeName.filter(item => item.type_name === type_name)) {
            acc.valueSum += acc[type_name] || 0;
          }

          return acc;
        },
        { valueSum: 0 }
      ),
    };
  });

  function findNameColor(type_name) {
    if (type_name && singlePenaltyType.length) {
      const name = singlePenaltyType.find(item => item.type_name === type_name);
      if (name !== undefined) {
        const color = name.color !== undefined ? name.color : "blue";
        return color;
      }
    }
  }
  const CustomXAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={10} transform="rotate(-35)" fontSize={14} textAnchor="end" fill="#262626">
          {payload.value}
        </text>
      </g>
    );
  };

  const keysToCheck = [
    "valueSum",
    "Штраф МП. Невыполненный заказ (отмена продавцом)",
    "Штраф МП. Невыполненный заказ",
    "Подмена товара (с фото)",
    "Платная приемка МП на СЦ",
    "Платное хранение возвратов на ПВЗ более 3 дней",
    "Сторно. Штраф МП. Невыполненный заказ",
    "Сторно. Отгрузка на иной СЦ",
  ];

  const isNotEmptyData = data.some(item =>
    keysToCheck.some(key => !isNil(item[key]) && item[key] !== 0)
  );

  return (
    <div className={styles.penaltyDynamicContainer}>
      <div>
        <h4>
          {tab === "penalty"
            ? "Сумма штрафов и компенсаций в динамике в заданном периоде"
            : "Количество штрафов и компенсаций в динамике в заданном периоде"}
        </h4>
      </div>
      {isLoadingPenaltyDynamic ? (
        <SkeletonWhite
          className={styles.scrollableBlock}
          style={{ width: "100%", minWidth: "1100px", height: "340px" }}
        />
      ) : (
        <div className={styles.scrollableBlock} ref={diagramRef}>
          {isNotEmptyData ? (
            <ResponsiveContainer
              width="100%"
              minWidth={1100}
              height={340}
              ref={rechartsRef}
              debounce={340}>
              <BarChart
                className={styles.rechartsWrapper}
                width={1100}
                height={340}
                data={data}
                margin={{
                  top: 25,
                  right: 30,
                  left: 30,
                  bottom: 40,
                }}>
                <CartesianGrid strokeDasharray="3 3 3 3" />
                <XAxis
                  dataKey="date"
                  // scale="band"
                  axisLine={true}
                  tickLine={true}
                  activeDot={false}
                  tickSize={8}
                  height={30}
                  stroke="#262626"
                  tick={CustomXAxisTick}
                />

                <YAxis dataKey="valueSum" tick={CustomYAxisTick} domain={[dataMin => 0]} />
                <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
                {penaltyTypeName.map((elem, index) => {
                  return (
                    <Bar
                      key={index}
                      dataKey={elem.type_name}
                      stackId="a"
                      minPointSize={getMinPointSize(diagramRef, "yAxis", "height")}>
                      {data.map((entry, ind) => {
                        if (entry[elem.type_name]) {
                          return <Cell key={`cell-${ind}`} fill={findNameColor(elem.type_name)} />;
                        } else {
                          return <Cell key={`cell-${ind}`} fill="transparent" />;
                        }
                      })}
                    </Bar>
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <NoDataMassage />
          )}
        </div>
      )}
    </div>
  );
};

export default PenaltyDynamic;
