import React, { useCallback, useMemo, useState } from "react";

import { Box, Dialog, Typography } from "@mui/material";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import request from "root/js/request";

import { useFetchErrorHandler } from "root/hooks/useFetchErrorHandler";

import Button_ from "../Button";

import styles from "./index.module.scss";

// -------------------------------------------------------------------
const BannerModal = ({ data = { actions: [] }, removeNotification }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const showBannerInThisPage = useMemo(() => {
    return (
      ("/dashboard" + history.location.pathname).includes(data.module) ||
      data.module === "/dashboard"
    );
  }, [data]);
  if (!showBannerInThisPage) return null;
  return (
    <Dialog
      open={isOpen}
      classes={{
        root: styles.bannerV2Root,
        paper: styles.bannerV2Paper,
      }}>
      <Box className={styles.bannerV2Content}>
        <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
          {data.subject || "Уведомление"}
        </Typography>
        <TextIfExistOrNull text={data.description} />
        <TextIfExistOrNull text={data.description2} />
        <Box>
          {data.actions?.map(item => (
            <ActionButton key={item.url} data={item} onClose={() => setIsOpen(false)} />
          ))}
        </Box>
      </Box>
      {data.is_exitable && (
        <Box display={"flex"} justifyContent={"center"} mb={2}>
          <Button_
            onClick={() => {
              removeNotification(data.id);
              setIsOpen(false);
            }}>
            Закрыть
          </Button_>
        </Box>
      )}
    </Dialog>
  );
};

const TextIfExistOrNull = ({ text }) => {
  if (!text) return null;
  else return <Typography>{text}</Typography>;
};

const ActionButton = ({ data, onClose = () => {} }) => {
  const errorHandler = useFetchErrorHandler();
  const colorStyles = useMemo(() => {
    switch (data.color) {
      case 1:
        return styles.redButton;
      case 2:
        return styles.yellowButton;
      case 3:
        return styles.greenButton;
      case 4:
        return styles.purpleButton;
      default:
        return "";
    }
  }, [data]);

  const fetchFunc = useCallback(async () => {
    try {
      const response = await request({
        url: data.url.replace("/api", ""),
        method: data.method,
        data: data.body,
      });
      if (response.status === 200) onClose();
    } catch (error) {
      errorHandler(error);
    }
  }, [data]);

  const onClick = () => fetchFunc();
  return (
    <Button_
      className={classNames(styles.bannerV2Button, colorStyles)}
      variant={"contained"}
      onClick={onClick}>
      {data.name}
    </Button_>
  );
};

export default BannerModal;
