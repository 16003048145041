import React, { useState } from "react";

import { Box } from "@mui/material";

import API from "root/js/api";

import Button_ from "root/components/Button";

import { useFetchErrorHandler } from "root/hooks/useFetchErrorHandler";

import { downloadFile } from "root/js/utils";

import XlsIcon from "root/icons/Xls";
// variables
const WBAPI = new API();
// ----------------------------------------------------------
const ProductDynamicExportButton = ({ params, isLoading, disabled, productId }) => {
  const [isExporting, setIsExporting] = useState(false);
  const errorHandler = useFetchErrorHandler();
  const onExport = async () => {
    try {
      setIsExporting(true);
      const response = await WBAPI.exportProductsDynamicById({ ...params });
      downloadFile(response.url, `Динамика внешки по артикулу «${productId}»`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsExporting(false);
    }
  };
  return (
      <Button_
        variant="outlined"
        disabled={disabled}
        iconAfter={XlsIcon}
        isLoading={isLoading || isExporting}
        onClick={onExport}
        loadingText="Отчет формируется...">
        Скачать отчет
      </Button_>
  );
};

export default ProductDynamicExportButton;
