import request from "root/js/request";

export const getSharesProductArticle = async ({
  shares_id,
  cabinet_id,
  category_id__in,
  subject_id__in,
  in_shares,
}) => {
  const response = await request({
    url: `/analytics/shares/${shares_id}/products/?cabinet_id=${cabinet_id}`,
    method: "get",
    params: {
      category_id__in,
      subject_id__in,
      in_shares,
    },
  });
  return response;
};
