import { postfixes, symbols } from "root/utils";

import { numberWithSpaces } from "root/js/utils";

// --------------------------- RENDER METHODS
const toFixed2Render = v => {
  if (isNaN(v)) return symbols.none;
  if (v % 1) {
    if ((v * 10) % 1) return numberWithSpaces((+v).toFixed(2));
    return numberWithSpaces((+v).toFixed(1));
  }
  return numberWithSpaces(v);
};

const externalTrafficOptions = [
  {
    id: "any",
    title: "Любое",
    serverValue: undefined,
  },
  {
    id: "low",
    title: "Не выявлено",
    serverValue: false,
  },
  {
    id: "high",
    title: "Высокое",
    serverValue: true,
  },
];

const bloggersPostsSelectionParams = [
  {
    dataIndex: "frequency",
    title: "Суммарная частотность за 3 дня после публикации",
    hint: "Сколько раз вбили артикул в поиске WB в течение 3х дней с момента публикации, включая день публикации",
    default: true,
  },
  {
    dataIndex: "proceeds",
    title: "Суммарная выручка за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "orders",
    title: "Суммарное количество заказов за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "price",
    title: "Средняя цена товара за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "orders_percent",
    title: "Прирост количества заказов в % за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "proceeds_percent",
    title: "Прирост выручки в % за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "orders_diff",
    title: "Прирост количества заказов за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "proceeds_diff",
    title: "Прирост выручки за 3 дня после публикации",
    default: true,
  },
  // {
  //   dataIndex: "like_count",
  //   title: "Количество лайков",
  //   postfix: postfixes.pcs,
  //   default: true,
  // },
  // {
  //   dataIndex: "view_count",
  //   title: "Количество просмотров",
  //   postfix: postfixes.pcs,
  //   default: true,
  // },
  // {
  //   dataIndex: "comment_count",
  //   title: "Количество комментариев",
  //   postfix: postfixes.pcs,
  //   default: true,
  // },
  // {
  //   dataIndex: "subscriber_count",
  //   title: "Количество подписчиков у автора публикации",
  //   postfix: postfixes.pcs,
  //   default: true,
  // },
  // {
  //   dataIndex: "er",
  //   title: "ER",
  //   render: toFixed2Render,
  //   default: true,
  // },
  // {
  //   dataIndex: "err",
  //   title: "ERR",
  //   render: toFixed2Render,
  //   default: true,
  // },
].map(item => ({ ...item, sortable: true }));

const bloggersPostsSelectionParamsForRedux = bloggersPostsSelectionParams.reduce((acc, item) => {
  acc[item.dataIndex + "__gte"] = "";
  acc[item.dataIndex + "__lte"] = "";
  return acc;
}, {});

const getDataForServerFromReduxBPS = data => {
  const correctData = {};
  for (const key in data) {
    if (key.includes("lte") || key.includes("gte")) {
      // if (data[key]) correctData[key] = parseInt(data[key].slice(3).replace(/\s/g, ""));
      if (data[key]) correctData[key] = data[key];
    } else if (key === "valueble")
      correctData[key] = externalTrafficOptions.find(item => item.id === data[key]).serverValue;
    else {
      switch (key) {
        case "account_name_hash":
          correctData[key + "__in"] = data[key].length
            ? data[key].map(item => item.id).join()
            : undefined;
          break;
        case "source":
          correctData[key + "__in"] = data[key].length ? data[key].join() : undefined;
          break;
        case "brand_id":
        case "product_id":
        case "item":
          correctData[key + "__in"] = data[key].length
            ? data[key].map(item => item.id).join()
            : undefined;
          break;
      }
    }
  }
  return correctData;
};

export {
  externalTrafficOptions,
  getDataForServerFromReduxBPS,
  bloggersPostsSelectionParams,
  bloggersPostsSelectionParamsForRedux,
};
