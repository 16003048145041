import request from "root/js/request";

const getGroupsKeywordsCluster = async ({
  search,
  limit,
  offset,
  stop_clusters,
  stop_keywords,
  stop_search,
  clusters,
  ...rest
}) => {
  const keywords = search ? search : undefined;
  const response = await request({
    url: `/parsers/keywords_cluster/groups/`,
    method: "POST",
    params: {
      limit,
      offset,
    },
    data: {
      clusters,
      stop_search,
      stop_keywords,
      keywords,
      stop_clusters,
      ...rest,
    },
  });
  return response.data;
};

export { getGroupsKeywordsCluster };
