const postfixes = {
  pcs: " шт.",
  rub: " ₽",
  percent: "%",
  volume: " л",
  day: "дн.",
  hour: "часа",
};

// «»
const symbols = {
  none: "–",
  success: "✅",
  error: "❌",
};

// это нужно для корректной предложки автозаполнения
const inputNames = {
  keyword: "search_keyword",
  wb_token: "wb-token",
  wb_article: "wb_article",
  wb_category: "wb_category",
  wb_subject: "wb_subject",
  wb_brand: "wb_brand",
  seller_article: "seller_article",
  barcode: "barcode",
  advert_companies: "advert_companies",
  product_id: "product_id",
  shk_id: "shk_id",
};

export { postfixes, symbols, inputNames };
