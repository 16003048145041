import React, { useState } from "react";

import Paper from "@mui/material/Paper";

import PagesTab from "../../../../../../../../components/global/pages-tabs";
import { promotionTopProductsOrdering } from "../../../../../../info";

const promotionTopProductsCompanies = props => {
  const [tab, setTab] = useState(0);
  const [parentTab, setParentTab] = useState(1);
  const onChageTab = tab => {
    setTab(tab);
  };

  return (
    <div>
      <Paper
        elevation={0}
        style={{
          width: "100%",
          borderRadius: "10px",
          marginTop: "-10px"
        }}>
        <PagesTab
          tab={tab}
          parentTab={parentTab}
          pages={promotionTopProductsOrdering}
          onChange={onChageTab}
        />
      </Paper>
    </div>
  );
};

export default promotionTopProductsCompanies;
