import React, {useEffect, useState} from "react"

import LinearProgress from "@mui/material/LinearProgress"
import classnames from "classnames"


import styles from "./index.module.scss"

const LinearLoading = props => {
	const {className, text = "Подождите, данные загружаются", ...rest} = props

	const [progress, setProgress] = useState(10)
	const [buffer, setBuffer] = useState(10)

	useEffect(() => {
		// const int = setInterval(() => {
		// 	setProgress(value => (value += 34 * ((96 - value) / 100)))
		// 	setBuffer(value => (value += 34 * ((96 - value) / 100)))
		// }, 1000)
		const int = () => {
			setProgress(value => (value += 34 * ((96 - value) / 100)))
			setBuffer(value => (value += 34 * ((96 - value) / 100)))
		}

		return () => clearInterval(int())
	}, [])

	return (
		<div
			className={classnames(styles.root, className)}
			{...rest}
		>
			<div className={styles.box}>
				<div className={styles.text}>{text}</div>
				<div className={styles.progressWrapper}>
					<LinearProgress
						variant="buffer"
						value={progress}
						valueBuffer={buffer}
						className={styles.progress}
					/>
				</div>
			</div>
		</div>
	)
}

export default LinearLoading
