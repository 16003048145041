import request from "root/js/request";

// Получение списка правил
export const getCartKillerRules = async ({ cabinet_id, limit, offset, ...rest }) => {
  const response = await request({
    url: "/api/analytics/cart_killer/",
    method: "get",
    params: {
      cabinet_id,
      limit,
      offset,
      ...rest,
    },
  });
  return response.data;
};

// Создание правила
export const postCreateCartKillerRule = async ({
  cabinet_id,
  identifier_type,
  identifier_id,
  cart_count,
  time_in_hours,
  duration,
  discount,
  abc_group = null,
}) => {
  const response = await request({
    url: "/api/analytics/cart_killer/",
    method: "post",
    params: { cabinet_id },
    data: {
      identifier_type,
      identifier_id,
      cart_count,
      time_in_hours,
      duration,
      discount,
      abc_group,
    },
  });
  return response.data;
};

// Изменение правила
export const putUpdateCartKillerRule = async ({
  id,
  cabinet_id,
  identifier_type,
  identifier_id,
  cart_count,
  time_in_hours,
  duration,
  discount,
  abc_group = null,
}) => {
  const response = await request({
    url: `/api/analytics/cart_killer/${id}/`,
    method: "put",
    params: { cabinet_id },
    data: {
      identifier_type,
      identifier_id,
      cart_count,
      time_in_hours,
      duration,
      discount,
      abc_group,
    },
  });
  return response.data;
};

// Изменение статуса правила
export const patchUpdateCartKillerRuleStatus = async ({ id, cabinet_id, status }) => {
  const response = await request({
    url: `/api/analytics/cart_killer/${id}/set_status/`,
    method: "patch",
    params: { cabinet_id },
    data: { status },
  });
  return response.data;
};

// Удаление правила
export const deleteCartKillerRule = async ({ id, cabinet_id }) => {
  const response = await request({
    url: `/api/analytics/cart_killer/${id}/`,
    method: "delete",
    params: { cabinet_id },
  });
  return response.data;
};
