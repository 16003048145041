import React from "react";

import Tab from "@mui/material/Tab";

import Trips from "../global/tips";

import styles from "./index.module.scss";

const Tab_ = props => {
  return props.trip ? (
    <Tab
      {...props}
      classes={styles}
      icon={<Trips text={props.trip} />}
      iconPosition="end"
    />
  ) : (
    <Tab {...props} classes={styles} />
  );
};

export default Tab_;
