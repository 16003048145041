import React, { useState } from "react";

import { useSelector } from 'react-redux';

import DiagramBarChards from "root/components/global/diagrama/diagram-bar-chards";

import DiagramHintBar from "../../../../../../components/global/diagrama/diagram-bar-hint";

import { numberWithSpaces } from 'root/js/utils';
const PaidProduct = ({ tab, paidProducts }) => {
    const {
        currencyName
    } = useSelector(state => state.financeLogic);

    const [tooltipData, setTooltipData] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const getTooltipContent = (product_name, supplier_article, product_thumbnail, price, count) => {
        const titleValuePrice = "Сумма хранения";
        const titleValueCount = "Количество товара, подлежащего тарифицированию";
        return (
            <DiagramHintBar
                article={supplier_article}
                articleText="Артикул"
                productNameText="Наименование"
                product={product_name}
                titleValue={titleValuePrice}
                value={price}
                titleValueSecond={titleValueCount}
                valueSecond={count}
                img={product_thumbnail}
            />
        );
    };

    const CustomTooltip = ({ active, payload, data }) => {
        if (active && payload && payload.length) {
            const {
                payload: { product_name, supplier_article, warehouse_price_value, barcodes_count, product_thumbnail, product_id }
            } = payload[0];
            const price = `${numberWithSpaces(warehouse_price_value.toFixed(0))} ${currencyName}`;
            const count = `${numberWithSpaces(barcodes_count)} шт`;
            if (selectedProduct !== product_id) setSelectedProduct(product_id);
            const ind = paidProducts.findIndex(el => el.product_id === product_id);
            if (activeIndex !== ind) setActiveIndex(ind);

            return getTooltipContent(product_name, supplier_article, product_thumbnail, price, count);
        } else if (data) {
            const product = paidProducts.find(el => el.product_id === data.value);
            const { product_name, supplier_article, warehouse_price_value, barcodes_count, product_thumbnail } = product;
            const price = `${numberWithSpaces(warehouse_price_value.toFixed(0))} ${currencyName}`;
            const count = `${numberWithSpaces(barcodes_count)} шт`;
            return getTooltipContent(product_name, supplier_article, product_thumbnail, price, count);
        }

        return null;
    };

    const title = `Топ ${paidProducts.length} ${tab === "warehouse_price_value" ? "товаров по стоимости хранения" : "товаров по количеству, подлежащему тарифицированию"
        }`;

    return (
        <DiagramBarChards
            CustomTooltip={CustomTooltip}
            data={paidProducts}
            title={title}
            name="product_id"
            value="value"
            tab={tab}
            compaireText={"warehouse_price_value"}
            width={450}
            height={paidProducts?.length * 90}
            leftYAxisWidth={60}
            rightYAxisWidth={85}
            valuet={currencyName}
            secondValuet="шт"
            products={paidProducts}
            setTooltipData={setTooltipData}
            tooltipData={tooltipData}
            currentProduct={selectedProduct}
            setCurrentProduct={setSelectedProduct}
            setExternalInd={setActiveIndex}
            externalInd={activeIndex}
            hasProductLink
            hasMinBarHeight
            isAcceptance
            color={"#CF3AA0"}
        />
    )
}

export default PaidProduct
