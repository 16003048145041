import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "root/js/api";

import { defaultToArr } from "root/js/utils";

const WBAPI = new API();

export const Top10LogicKey = createAsyncThunk("get/key", async thunkAPI => {
  try {
    const keys = await WBAPI.getApiKeys();
    return defaultToArr(keys);
  } catch (e) {
    return thunkAPI.rejectWithValue("Error fetching key");
  }
});

export const getTop10Products = createAsyncThunk(
  "analytics/getTop10Products",
  async ({ cabinet_id, order_by }, thunkAPI) => {
    try {
      const response = await WBAPI.getTop10Products({ key: cabinet_id, order_by });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
