import request from "root/js/request";

export const managementProductsInShares = async ({
  products,
  cabinet_id,
  shares_id,
  in_shares,
}) => {
  const response = await request({
    url: `/analytics/shares/${shares_id}/product_management/`,
    method: "post",
    params: {
      cabinet_id,
      product_id__in: products,
      in_shares, // true для добавления, false для удаления
    },
  });
  return response;
};
