import request from "root/js/request";

const getTradeEconomyWidgets = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  // ниже очень странные параметры, но удалить может быть опасно, если автор читает - пусть сделает
  ids, // нет на сервере такого параметра, что за ids непонятные, хз что это может быть вообще
  brandsId, // что за название?? id брендов? не бывает такого
  article,
  barcode,
  product_id,
  subject,
  //
  category_id__in = ids, // для обратной совместимости
  subject_id__in = subject,
  brand_id__in = brandsId,
  product_id__in = product_id,
  barcode__in = barcode,
  supplier_article__in = article,
}) => {
  const response = await request({
    url: "/analytics/trade_economy_widgets/",
    method: "get",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      category_id__in,
      brand_id__in,
      supplier_article__in,
      barcode__in,
      product_id__in,
      subject_id__in,
    },
  });
  return response.data;
};

export { getTradeEconomyWidgets };
