import request from "root/js/request";

const getAbcEconomicLogicTable = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  category_id__in,
  brand_id__in,
  subject_id__in,
  product_id__in,
  barcode__in,
  analyze_by,
  group_by,
  order_by,
  q,
  limit,
  offset,
  synthetic_abc_realization_quantity_share_weight,
  synthetic_abc_proceeds_share_weight,
  synthetic_abc_gross_margin_share_weight,
  labels,
  xyz_labels,
  synthetic_abc_group_label__in,
  gross_margin_group_label__in,
  proceeds_group_label__in,
  realization_quantity_group_label__in,
  xyz_label__in,
  product_id__icontains,
  supplier_article,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/abc_analysis_table/",
    method: "get",
    params: {
      wb_api_key_ids,
      category_id__in,
      brand_id__in,
      subject_id__in,
      product_id__in,
      barcode__in,
      date_from,
      date_to,
      synthetic_abc_realization_quantity_share_weight,
      synthetic_abc_proceeds_share_weight,
      synthetic_abc_gross_margin_share_weight,
      group_by,
      limit,
      offset,
      q,
      order_by,
      labels,
      xyz_labels,
      synthetic_abc_group_label__in,
      gross_margin_group_label__in,
      proceeds_group_label__in,
      realization_quantity_group_label__in,
      xyz_label__in,
      product_id__icontains,
      supplier_article,
      ...rest,
    },
  });
  return response.data;
};

export { getAbcEconomicLogicTable };
