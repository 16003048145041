import request from "root/js/request";
import { getServerDate } from "root/utils/date";

const ONE_DAY = 24 * 60 * 60 * 1000;

const dateToDateKey = dt =>
  `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`;

const respToRespByDates = resp =>
  resp.reduce((acc, i) => {
    acc[i.date] = i;
    return acc;
  }, {});

// const formatDate = date => {
//   return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
//     "0" + date.getDate()
//   ).slice(-2)}`;
// };

const getTradeEconomyDynamic = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  category_id__in,
  brand_id__in,
  supplier_article__in,
  barcode__in,
  subject_id__in,
  product_id__in,
}) => {
  // date_from = formatDate(new Date(date_from));
  date_from = getServerDate(new Date(date_from));
  // date_to = formatDate(new Date(date_to));
  date_to = getServerDate(new Date(date_to));

  const prevDateTo = new Date(date_from);
  prevDateTo.setDate(prevDateTo.getDate());

  const prevDateFrom = new Date(prevDateTo.getTime() - (new Date(date_to) - new Date(date_from)));

  // const prevDateFromFormatted = formatDate(prevDateFrom);
  const prevDateFromFormatted = getServerDate(prevDateFrom);
  // const prevDateToFormatted = formatDate(prevDateTo);
  const prevDateToFormatted = getServerDate(prevDateTo);

  const [currentResponse, prevResponse] = await Promise.all([
    request({
      url: "/analytics/trade_economy_dynamic/",
      params: {
        wb_api_key_ids,
        date_from: date_from && date_from + "T00:00:01+03:00",
        date_to: date_to && date_to + "T23:59:59.999+03:00",
        category_id__in,
        brand_id__in,
        supplier_article__in,
        barcode__in,
        subject_id__in,
        product_id__in,
      },
    }),
    request({
      url: "analytics/trade_economy_dynamic/",
      params: {
        wb_api_key_ids,
        date_from: prevDateFromFormatted && prevDateFromFormatted + "T00:00:01+03:00",
        date_to: prevDateToFormatted && prevDateToFormatted + "T23:59:59.999+03:00",
        category_id__in,
        brand_id__in,
        supplier_article__in,
        barcode__in,
        subject_id__in,
        product_id__in,
      },
    }),
  ]);

  const curRespByDates = respToRespByDates(currentResponse.data);
  const prevRespByDates = respToRespByDates(prevResponse.data);

  const daysCount = (new Date(date_to) - new Date(date_from)) / ONE_DAY;

  const data = Array.from({ length: daysCount + 1 }).map((_, i) => {
    const curDate = new Date(new Date(date_from).getTime() + ONE_DAY * i);
    const prevDate = new Date(prevDateFrom.getTime() + ONE_DAY * i);

    const curDateKey = dateToDateKey(curDate);
    const prevDateKey = dateToDateKey(prevDate);

    return {
      currentDate: curDateKey,
      current: curRespByDates[curDateKey] || null,
      prev: prevRespByDates[prevDateKey] || null,
    };
  });

  return data;
};

export { getTradeEconomyDynamic };
