import React, { useState } from "react";

import Paper from "@mui/material/Paper";

import PagesTab from "../../../../../../components/global/pages-tabs";
import { promotionTopProductsTypes } from "../../../../info";

import styles from "./index.module.scss";

const PromotionMediaTopProducts = () => {
  const [tab, setTab] = useState(0);

  const onChageTab = tab => {
    setTab(tab);
  };

  return (
    <div className={styles.topProductsTabsBlock}>
      <Paper
        elevation={0}
        style={{
          width: "100%",
          borderRadius: "10px",
          height: "100%"
        }}>
        <PagesTab
          tab={tab}
          pages={promotionTopProductsTypes}
          onChange={onChageTab}
        />
      </Paper>
    </div>
  )
}

export default PromotionMediaTopProducts
