import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadStatuses } from "root/js/variables";

import API from "../../js/api";

const initialState = {
  data: {},
  loadStatus: loadStatuses.fulfilled,
  dynamicsForPeriod: {
    product: {},
    colorDynamic: [],
    colorSizeDynamic: [],
    //sizeDynamic: [],
    brandSizeDynamic: [],
    warehousesDynamic: [],
    colorWarehouseDynamic: [],
    productKeywords: [],
    colorSizeDynamic2: [],
  },
  dynamicsForMonth: {
    product: {},
    productRelative: {},
    //colorDynamic: [],
    colorSizeDynamic: [],
    //sizeDynamic: [],
    brandSizeDynamic: [],
  },
  dynamicsFor2Weeks: {
    product: {},
  },
  isLoading: {
    isProductLoading: true,
    isProductForMonthLoading: true,
    isProductFor2WeeksLoading: true,
    isProductRelativeLoading: true,
    isColorDynamicLoading: true,
    isColorSizeDynamicLoading: false,
    isBrandSizeDynamicForPeriodLoading: true,
    isBrandSizeDynamicForMonthLoading: true,
    isWarehousesDynamicLoading: true,
    isColorWarehouseDynamicLoading: false,
    isProductKeywordsLoading: true,
  },
};

const WBAPI = new API();

export const fetchProductDynamic = createAsyncThunk(
  "productDynamics/fetchProductDynamic",
  async (obj, { rejectWithValue }) => {
    try {
      const { loadMonthData, load2WeeksData, ...params } = obj;

      const response = await WBAPI.getProductsDynamic(params);

      if (response.results?.[0]) {
        response.results[0].imgs = response.results[0].images;
      }

      let parserData;

      if (!response.results?.[0]) {
        parserData = await WBAPI.getProductInfoByProduct_ids({ id: params.product_ids });
      }
      // ! SUP-440
      const productForPeriod = response.results?.[0] ?? parserData ?? {};

      // ввёл новые данные чтобы упростить раздел, пока они будут дублироваться, но в будущем,
      // при рефакторинге от дубликата избавимся
      const productData = response.results?.[0] ?? parserData ?? {};

      const { date_from, date_to, ...restParams } = params;

      let productForMonth;

      if (loadMonthData) {
        const response2 = await WBAPI.getProductsDynamic({ ...restParams, period: 30 });

        if (response2.results?.[0]) {
          response2.results[0].imgs = response2.results[0].images;
        }

        if (!response2.results?.[0] && !parserData) {
          parserData = await WBAPI.getProductInfoByProduct_ids({ id: params.product_ids });
        }

        productForMonth = response2.results?.[0] ?? parserData ?? {};

        productForMonth.images = productForMonth.imgs
          ? productForMonth.imgs.map(item => item.url)
          : [productForMonth.imgs];
      }

      let productFor2Weeks;

      if (load2WeeksData) {
        const response3 = await WBAPI.getProductsDynamic({ ...restParams, period: 14 });

        if (response3.results?.[0]) {
          response3.results[0].imgs = response3.results[0].images;
        }

        productFor2Weeks = response3.results?.[0] ?? parserData ?? {};

        productFor2Weeks.images = productFor2Weeks.imgs
          ? productFor2Weeks.imgs.map(item => item.url)
          : [productFor2Weeks.imgs];
      }

      return { productData, productForPeriod, productForMonth, productFor2Weeks };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        error,
      });
    }
  }
);

export const fetchProductRelative = createAsyncThunk(
  "productDynamics/fetchProductRelative",
  async (obj, { rejectWithValue }) => {
    try {
      const { item, ...params } = obj;

      let productRelativeForMonth;

      const { date_from, date_to, ...restParams } = params;
      productRelativeForMonth = await WBAPI.getProductRelative({ ...restParams, period: 30 });

      const response = await WBAPI.getProductColorDynamic({
        period: 30,
        limit: 1,
        item,
        extra_fields: ["price"].join(),
      });

      productRelativeForMonth = {
        ...productRelativeForMonth,
        colors_count: response?.count ?? 0,
      };

      return { productRelativeForMonth };
    } catch (error) {
      console.log(error);
      return { productRelativeForMonth: {} };
    }
  }
);

export const fetchColorDynamic = createAsyncThunk(
  "productDynamics/fetchColorDynamic",
  async (obj, { rejectWithValue }) => {
    try {
      const { ...params } = obj;

      let { results: colorDynamicForPeriod } = await WBAPI.getProductColorDynamic({ ...params });

      // const response = await WBAPI.getProductColorDynamic({...restParams, item: itemForMonth, period: 30})
      // const colorDynamicForMonth = response.results

      return { colorDynamicForPeriod };
    } catch (error) {
      console.log(error);
      return { colorDynamicForPeriod: [] };
    }
  }
);

// export const fetchColorSizeDynamic = createAsyncThunk(
// 	"productDynamics/fetchColorSizeDynamic",
// 	async (obj, {rejectWithValue}) => {
// 		try {
// 			const {...params} = obj
//
// 			const {results: colorSizeDynamicForPeriod} =  await WBAPI.getProductsColorSizeDynamic({...params})
//
// 			const {date_from, date_to, ...restParams} = params
// 			const response = await WBAPI.getProductsColorSizeDynamic({...restParams, period: 30})
// 			const colorSizeDynamicForMonth = response.results
//
// 			return {colorSizeDynamicForPeriod, colorSizeDynamicForMonth}
// 		} catch (error) {
// 			console.log(error)
// 			return {colorSizeDynamicForPeriod: [], colorSizeDynamicForMonth: []}
// 		}
// 	}
// )

export const fetchColorSizeDynamic = createAsyncThunk(
  "productDynamics/fetchColorSizeDynamic",
  async (obj, { rejectWithValue }) => {
    try {
      const { ...params } = obj;

      const { results: colorSizeDynamic } = await WBAPI.getProductsColorSizeDynamic({ ...params });

      return { colorSizeDynamic };
    } catch (error) {
      console.log(error);
      return { colorSizeDynamic: [] };
    }
  }
);

export const fetchBrandSizeDynamicForPeriod = createAsyncThunk(
  "productDynamics/fetchBrandSizeDynamicForPeriod",
  async (params, { rejectWithValue }) => {
    try {
      const { results: brandSizeDynamic } = await WBAPI.getBrandSizeDynamic({ ...params });

      return { brandSizeDynamic };
    } catch (error) {
      console.log(error);
      return { brandSizeDynamic: [] };
    }
  }
);

export const fetchBrandSizeDynamicForMonth = createAsyncThunk(
  "productDynamics/fetchBrandSizeDynamicForMonth",
  async (params, { rejectWithValue }) => {
    try {
      const { results: brandSizeDynamic } = await WBAPI.getBrandSizeDynamic({
        ...params,
        // period: 30,
      });

      return { brandSizeDynamic };
    } catch (error) {
      console.log(error);
      return { brandSizeDynamic: [] };
    }
  }
);

export const fetchWarehousesDynamic = createAsyncThunk(
  "productDynamics/fetchWarehousesDynamic",
  async (params, { rejectWithValue }) => {
    try {
      const warehousesDynamicForPeriod = await WBAPI.getWarehousesDynamic({
        ...params,
        extra_fields: params.extra_fields + ",turnover",
      });

      return { warehousesDynamicForPeriod };
    } catch (error) {
      console.log(error);
      return { warehousesDynamicForPeriod: [] };
    }
  }
);

export const fetchColorWarehouseDynamic = createAsyncThunk(
  "productDynamics/fetchColorWarehouseDynamic",
  async (params, { rejectWithValue }) => {
    try {
      const colorWarehouseDynamicForPeriod = await WBAPI.getProductsColorWarehouseDynamic({
        ...params,
      });

      return { colorWarehouseDynamicForPeriod };
    } catch (error) {
      console.log(error);
      return { colorWarehouseDynamicForPeriod: [] };
    }
  }
);

export const fetchProductKeywords = createAsyncThunk(
  "productDynamics/fetchProductKeywords",
  async (params, { rejectWithValue }) => {
    try {
      const { results: productKeywordsForPeriod } = await WBAPI.getProductKeywords({ ...params });

      return { productKeywordsForPeriod };
    } catch (error) {
      console.log(error);
      return { productKeywordsForPeriod: [] };
    }
  }
);

const productDynamicsSlice = createSlice({
  name: "productDynamics",
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      state.isLoading = { ...state.isLoading, ...payload };
    },
    clearLoadStatuses(state) {
      state.isLoading = { ...initialState.isLoading };
    },
    setInitState() {
      return initialState;
    },
    resetProductDynamics(state, { payload }) {
      const resetForMonth = payload;

      return {
        ...initialState,
        dynamicsForMonth: resetForMonth ? initialState.dynamicsForMonth : state.dynamicsForMonth,
        dynamicsFor2Weeks: resetForMonth ? initialState.dynamicsFor2Weeks : state.dynamicsFor2Weeks,
        isLoading: {
          ...initialState.isLoading,
          isProductForMonthLoading: resetForMonth,
          isProductFor2WeeksLoading: resetForMonth,
        },
      };
    },
    // setAllLoading(state) {
    // 	state.isLoading = {
    // 		isProductLoading: true,
    // 		isProductRelativeLoading: true,
    // 		isColorDynamicLoading: true,
    // 		isColorSizeDynamicLoading: true,
    // 		isBrandSizeDynamicLoading: true,
    // 		isWarehousesDynamicLoading: true,
    // 		isColorWarehouseDynamicLoading: true
    // 	}
    // },
    setDynamicsForPeriod(state, { payload }) {
      state.dynamicsForPeriod = payload;
    },
    setDynamicsForMonth(state, { payload }) {
      state.dynamicsForMonth = payload;
    },
    setColorSizeDynamic(state, { payload }) {
      state.dynamicsForPeriod.colorSizeDynamic = payload;
      state.dynamicsForMonth.colorSizeDynamic = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProductDynamic.fulfilled, (state, { payload }) => {
      state.loadStatus = loadStatuses.fulfilled;
      if (payload.productData) state.data = payload.productData;
      if (payload.productForPeriod) {
        state.dynamicsForPeriod.product = payload.productForPeriod;
      }
      if (payload.productForMonth) {
        state.dynamicsForMonth.product = payload.productForMonth;
      }
      if (payload.productFor2Weeks) {
        state.dynamicsFor2Weeks.product = payload.productFor2Weeks;
      }
    });
    builder.addCase(fetchProductDynamic.pending, state => {
      state.loadStatus = loadStatuses.pending;
    });
    builder.addCase(fetchProductDynamic.rejected, (state, { payload }) => {
      state.loadStatus = loadStatuses.rejected;
      state.dynamicsForPeriod.product = {};
      state.dynamicsForMonth.product = {};
      state.dynamicsFor2Weeks.product = {};
      state.isLoading = getFalseIsLoadingObj(state.isLoading);
    });
    builder.addCase(fetchProductRelative.fulfilled, (state, { payload }) => {
      state.dynamicsForMonth.productRelative = payload.productRelativeForMonth;
      state.isLoading.isProductRelativeLoading = false;
    });
    builder.addCase(fetchColorDynamic.fulfilled, (state, { payload }) => {
      state.dynamicsForPeriod.colorDynamic = payload.colorDynamicForPeriod;
      state.isLoading.isColorDynamicLoading = false;
    });
    builder.addCase(fetchColorDynamic.rejected, (state, { payload }) => {
      state.dynamicsForPeriod.colorDynamic = [];
      state.isLoading.isColorDynamicLoading = false;
    });
    // builder.addCase(fetchColorSizeDynamic.fulfilled, (state, {payload}) => {
    // 	state.dynamicsForPeriod.colorSizeDynamic = payload.colorSizeDynamicForPeriod
    // 	state.dynamicsForMonth.colorSizeDynamic = payload.colorSizeDynamicForMonth
    // })
    builder.addCase(fetchColorSizeDynamic.pending, (state, { payload }) => {
      state.isLoading.isColorSizeDynamicLoading = true;
      state.dynamicsForPeriod.colorSizeDynamic = [];
    });
    builder.addCase(fetchColorSizeDynamic.fulfilled, (state, { payload }) => {
      state.dynamicsForPeriod.colorSizeDynamic = payload.colorSizeDynamic;
      state.isLoading.isColorSizeDynamicLoading = false;
    });
    builder.addCase(fetchColorSizeDynamic.rejected, (state, { payload }) => {
      state.isLoading.isColorSizeDynamicLoading = false;
    });
    builder.addCase(fetchBrandSizeDynamicForPeriod.fulfilled, (state, { payload }) => {
      state.dynamicsForPeriod.brandSizeDynamic = payload.brandSizeDynamic;
    });
    builder.addCase(fetchBrandSizeDynamicForMonth.pending, state => {
      state.isLoading.isBrandSizeDynamicForMonthLoading = true;
    });
    builder.addCase(fetchBrandSizeDynamicForMonth.fulfilled, (state, { payload }) => {
      state.dynamicsForMonth.brandSizeDynamic = payload.brandSizeDynamic;
      state.isLoading.isBrandSizeDynamicForMonthLoading = false;
    });
    builder.addCase(fetchWarehousesDynamic.fulfilled, (state, { payload }) => {
      state.dynamicsForPeriod.warehousesDynamic = payload.warehousesDynamicForPeriod;
    });

    builder.addCase(fetchColorWarehouseDynamic.pending, (state, { payload }) => {
      state.isLoading.isColorWarehouseDynamicLoading = true;
      state.dynamicsForPeriod.colorWarehouseDynamic = [];
    });
    builder.addCase(fetchColorWarehouseDynamic.fulfilled, (state, { payload }) => {
      state.dynamicsForPeriod.colorWarehouseDynamic = payload.colorWarehouseDynamicForPeriod;
      state.isLoading.isColorWarehouseDynamicLoading = false;
    });
    builder.addCase(fetchColorWarehouseDynamic.rejected, (state, { payload }) => {
      state.isLoading.isColorWarehouseDynamicLoading = false;
    });

    builder.addCase(fetchProductKeywords.fulfilled, (state, { payload }) => {
      state.dynamicsForPeriod.productKeywords = payload.productKeywordsForPeriod;
    });
  },
});

export const {
  setIsLoading,
  clearLoadStatuses,
  setColorSizeDynamic,
  resetProductDynamics,
  setInitState,
} = productDynamicsSlice.actions;

export default productDynamicsSlice.reducer;

const getFalseIsLoadingObj = loadObj => {
  const newLoadObj = { ...loadObj };
  for (const key in newLoadObj) {
    newLoadObj[key] = false;
  }
  return newLoadObj;
};
