import { createAsyncThunk, createSlice /*, rejectWithValue*/ } from "@reduxjs/toolkit";
import { clone, difference, isEmpty, length, prop, propOr, uniq, uniqBy } from "ramda";

import API from "root/js/api";

import {
  columns,
  localStorageColumnSettings,
} from "root/pages/category-optimization/components/Columns";

import { calcOffset, dynamicDataCreator, getDefaultColumns } from "root/js/utils";

const WBAPI = new API();

const defaultColumns = columns
  .filter(item =>
    getDefaultColumns(localStorageColumnSettings, [
      "id",
      "book_place",
      "subject",
      "cpm",
      "proceeds",
      "orders_dynamic",
      "product",
      "time",
      "cpm",
      "expected_position",
      "position",
    ]).includes(item.dataIndex)
  )
  .map(item => item.dataIndex);

const initialState = {
  isLoading: true,
  error: null,
  data: [],
  product_ids: [],
  cpmData: [],
  length: 0,
  order: "book_place",
  tableData: [],

  perPage: 50,

  showingColumns: defaultColumns,

  page: 1,
  ids: {
    1: [],
  },
  allIds: [],
  checkBoxSelect: {
    1: {
      all: false,
      checkeds: [],
    },
  },
};

const cpmData = [];

export const getProductsDynamicAction = createAsyncThunk(
  "getProductsDynamicAction",
  async (params, { rejectWithValue }) => {
    try {
      cpmData.splice(0, cpmData.length);
      const dynamicData = await WBAPI.getProductsDynamic({
        product_ids: params.product_ids,
        period: 30,
        offset: 0,
        limit: length(params?.data ?? []),
        extra_fields: params.extra_fields,
        ordering: "product_ids",
      });
      params?.data?.map(({ id, time, cpm, expected_position, tp, position, advert_position }) => {
        dynamicData.results.map(item => {
          if (id === item.id) {
            cpmData.push({
              ...item,
              cpm,
              position,
              advert_position,
              expected_position,
              time,
              tp,
              ...clone(dynamicDataCreator(item.dynamic)),
            });
          }
        });
      });

      return {
        cpmData: cpmData.map((item, index) => {
          const auto_adv = {};
          if (prop("position", item) && prop("expected_position", item)) {
            auto_adv["tooltip"] = {
              boost: prop("position", item),
              cpm: prop("cpm", item),
              noBoost: prop("expected_position", item),
            };
            auto_adv["value"] = prop("expected_position", item) + "->" + prop("position", item);
          }
          return {
            ...item,
            book_place: index + 1,
            auto_adv,
            product: {
              name: item.name,
              image: propOr(item?.image, "url", item?.images?.[0]),
              articule: item.id,
              type: item.tp,
            },
          };
        }),
        length: length(cpmData),
      };
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getKeyWordCPMAction = createAsyncThunk(
  "getKeyWordCPMAction",
  async (params, { rejectWithValue }) => {
    try {
      const data = await WBAPI.getKeyWordCPM(params);
      const product_ids = String(data.map(({ product_id }) => product_id));

      params.dispatch(
        getProductsDynamicAction({
          product_ids,
          period: 30,
          offset: 0,
          limit: length(data),
          data,
          extra_fields: params.extra_fields_showingColumns,
          ordering: "product_ids",
        })
      );

      return { product_ids, data };
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const categoryOptimizationSlice = createSlice({
  name: "categoryOptimization",
  initialState,
  reducers: {
    clearState(state) {
      state.page = 1;
      state.order = "book_place";
      state.ids = {
        1: [],
      };
      state.allIds = [];
      state.checkBoxSelect = {
        1: {
          all: false,
          checkeds: [],
        },
      };
    },
    setCategoryOptimizationOrder(state, { payload }) {
      const isDescending = payload.includes("-");
      const dataIndex = payload.replace("-", "");
      const _data = state.tableData.slice();

      if (dataIndex === "product") {
        _data.sort((a, b) => a.product.name.localeCompare(b.product.name));
        isDescending && _data.reverse();
      } else if (_data[0] && typeof _data[0][dataIndex] === "string") {
        _data.sort((a, b) => a[dataIndex].localeCompare(b[dataIndex]));
        isDescending && _data.reverse();
      } else {
        _data.sort((a, b) =>
          isDescending ? b[dataIndex] - a[dataIndex] : a[dataIndex] - b[dataIndex]
        );
      }
      state.tableData = _data;
      state.order = payload;
    },
    handlePageChange(state, { payload }) {
      // const page = payload
      state.page = payload;
      state.tableData = uniqBy(
        prop("id"),
        state?.cpmData?.filter(
          (_, index) =>
            index >= calcOffset(payload, state.perPage) && index < state.perPage * payload
        )
      );
      // if(state.ids?.[payload]){
      state.ids[payload] = uniqBy(
        prop("id"),
        state?.cpmData?.filter(
          (_, index) =>
            index >= calcOffset(payload, state.perPage) && index < state.perPage * payload
        )
      )
        .filter(({ id }) => id)
        .map(item => item.id);
      // }

      if (!state?.checkBoxSelect?.[payload]) {
        state.checkBoxSelect[payload] = {
          all: false,
          checkeds: [],
        };
      }
    },
    handlePerPageChange(state, { payload }) {
      state.page = 1;
      state.perPage = payload;

      state.tableData = uniqBy(
        prop("id"),
        state?.cpmData?.filter(
          (_, index) => index >= calcOffset(1, state.perPage) && index < state.perPage * 1
        )
      );

      state.ids[1] = uniqBy(
        prop("id"),
        state?.cpmData?.filter(
          (_, index) => index >= calcOffset(1, state.perPage) && index < state.perPage * 1
        )
      )
        .filter(({ id }) => id)
        .map(item => item.id);
    },
    setShowingColumns(state, { payload }) {
      state.showingColumns = uniq(["product", "id", ...payload]);
    },
    handleCheckBoxSelect(state, { payload }) {
      state.checkBoxSelect[state.page].all = false;
      const checkeds = state.checkBoxSelect[state.page].checkeds;
      if (payload.checked) {
        state.checkBoxSelect[state.page].checkeds = [...checkeds, payload.value];
        state.allIds = uniq([...state.allIds, payload.value]);
      } else {
        state.checkBoxSelect[state.page].checkeds = checkeds.filter(item => item !== payload.value);
        state.allIds = uniq(state.allIds.filter(item => item !== payload.value));
      }
    },
    handleCheckBoxSelectAll(state, { payload }) {
      state.checkBoxSelect[state.page].all = payload.checked;
      if (payload.checked) {
        state.checkBoxSelect[state.page].checkeds = payload.value;
        state.allIds = uniq([...state.allIds, ...payload.value]);
      } else {
        state.checkBoxSelect[state.page].checkeds = [];
        state.allIds = difference(state.allIds, payload.value);
      }
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: {
    [getKeyWordCPMAction.pending]: state => {
      state.isLoading = true;
    },
    [getKeyWordCPMAction.fulfilled]: (state, { payload }) => {
      state.product_ids = payload?.product_ids ?? "";
      state.data = payload?.data ?? [];
      if (isEmpty(payload?.product_ids)) {
        state.isLoading = false;
        state.error = "error";
      }
    },
    [getKeyWordCPMAction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      // state.error = payload.error
      state.error = "error";
    },

    [getProductsDynamicAction.pending]: state => {
      state.isLoading = true;
    },
    [getProductsDynamicAction.fulfilled]: (state, { payload }) => {
      state.cpmData = payload.cpmData;
      state.tableData = uniqBy(
        prop("id"),
        payload?.cpmData?.filter(
          (_, index) =>
            index >= calcOffset(state.page, state.perPage) && index < state.perPage * state.page
        )
      );

      state.ids[state.page] = uniqBy(
        prop("id"),
        payload?.cpmData?.filter(
          (_, index) =>
            index >= calcOffset(state.page, state.perPage) && index < state.perPage * state.page
        )
      )
        .filter(({ id }) => id)
        .map(item => item.id);
      state.length = payload.length;
      state.isLoading = false;
    },
    [getProductsDynamicAction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
  },
});

export const {
  setCategoryOptimizationOrder,
  handlePageChange,
  handlePerPageChange,
  setShowingColumns,
  handleCheckBoxSelect,
  clearError,
  handleCheckBoxSelectAll,
  clearState,
} = categoryOptimizationSlice.actions;

export default categoryOptimizationSlice.reducer;
