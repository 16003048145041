import React from "react";

import { Box } from "@mui/material";
import classnames from "classnames";
import { Link } from "react-router-dom";

import LinkArrow from "../LinkArrow";

import styles from "./index.module.scss";

const Link_ = ({ underline = "hover", children, className, to, withIcon = false, ...rest }) => {
  return to ? (
    <Link
      to={to}
      data-underline={underline}
      className={classnames(styles.root, className)}
      {...rest}>
      <Box display={"inline-flex"} alignItems={"center"} gap={0.5}>
        {children}
        {withIcon && <LinkArrow />}
      </Box>
    </Link>
  ) : (
    <span className={classnames(styles.root, className)} data-underline={underline} {...rest}>
      {children}
    </span>
  );
};

export default Link_;
