import React from "react";

import { Box } from "@mui/material";

import LinearLoading from "root/components/LinearLoading";
import Plug from "root/components/Plugs";
import DataNotLoaded from "root/components/Plugs/DataNotLoaded";

import styles from "./index.module.scss";

// -------------------------------------------------------------------
const ScrollableChartWrapper = ({
  pb,
  children,
  isLoading,
  isDataNotLoaded,
  dataNotLoadedText,
  isEmpty,
  emptyText,
  ...rest
}) => {
  return (
    <Box className={styles.scrollableChartWrapper} {...rest}>
      {isLoading ? (
        <LinearLoading />
      ) : isDataNotLoaded ? (
        <DataNotLoaded text={dataNotLoadedText} />
      ) : isEmpty ? (
        <Plug text={emptyText} />
      ) : null}
      <Box pb={pb} className={styles.scrollableChart}>
        {children}
      </Box>
    </Box>
  );
};

export default ScrollableChartWrapper;
