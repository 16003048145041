import request from "root/js/request";

const getAdvertTop = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  status,
  type,
  advert_id__in,
  brand_id__in,
  category_id__in,
  subject_id__in,
  product_id__in,
  group_by,
  limit,
  offset,
  reverse,
}) => {
  const response = await request({
    url: "analytics/advert_top/",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      status,
      type,
      advert_id__in,
      brand_id__in,
      category_id__in,
      subject_id__in,
      product_id__in,
      group_by,
      limit,
      offset,
      reverse,
    },
  });
  return response.data;
};

export { getAdvertTop };
