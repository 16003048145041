import { notificationFields } from "root/pages/settings-debriefing/components/notifications/utils";

import { getNotificationGroup } from "./actions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  notifications: notificationFields.reduce((acc, item) => {
    acc[item.name] = {
      id: item.id,
      value: item.defaultValue,
      is_active: false,
      isPercent: item.isPercent,
    };
    if (item.hasDirection) acc[item.name].direction = "gte";
    if (item.hasPeriod) acc[item.name].period = null;
    return acc;
  }, {}),
  subscriptions: {},
  currentGroupId: null,
  isLoading: true,
  hasChanges: false,
  filteredNotifications: notificationFields,
  errorGroup: null,
};

const notificationIA = createSlice({
  name: "notificationIA",
  initialState,
  reducers: {
    setNotificationId(state, { payload: { name, id } }) {
      state.notifications[name].id = id;
    },
    setNotificationValueByName(state, { payload: { name, value } }) {
      state.notifications[name].value = value;
      state.hasChanges = true;
    },
    setNotificationDirection(state, { payload: { name, value } }) {
      state.notifications[name].direction = value;
      state.hasChanges = true;
    },
    setNotificationIsActive(state, { payload: { name, value } }) {
      state.notifications[name].is_active = value;
      state.hasChanges = true;
    },
    setNotificationPeriod(state, { payload: { name, value } }) {
      state.notifications[name].period = value;
      state.hasChanges = true;
    },
    setCurrentGroupId(state, { payload: groupId }) {
      state.currentGroupId = groupId;
      state.hasChanges = false;
    },
    filterNotificationsByType(state, { payload: category }) {
      state.filteredNotifications = notificationFields.filter(field => field.category === category);
    },
    setSubscriptions(state, { payload: { groupId, subscriptions } }) {
      state.subscriptions[groupId] = subscriptions;
      subscriptions.forEach(sub => {
        if (state.notifications[sub.name]) {
          state.notifications[sub.name].value = sub.value;
          state.notifications[sub.name].is_active = sub.is_active;
          state.notifications[sub.name].direction = sub.direction;
          state.notifications[sub.name].period = sub.period;
          state.notifications[sub.name].id = sub.id;
        } else {
          state.notifications[sub.name] = { ...sub, id: sub.id };
        }
      });
    },
    resetChanges(state) {
      state.hasChanges = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getNotificationGroup.pending, state => {
        state.isLoading = true;
      })
      .addCase(getNotificationGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errorGroup = null;
        state.currentGroupId = action.payload[0]?.id;
      });

    builder.addCase(getNotificationGroup.rejected, state => {
      state.isLoading = false;
      state.errorGroup = "Ошибка получения группы, пожалуйста, выберите другой кабинет";
    });
  },
});

export const prepareNotificationData = notification => {
  const fieldConfig = notificationFields.find(field => field.name === notification.field);
  return {
    ...notification,
    gte: fieldConfig?.isPercent && notification.gte ? notification.gte / 100 : notification.gte,
    lte: fieldConfig?.isPercent && notification.lte ? notification.lte / 100 : notification.lte,
  };
};

export default notificationIA.reducer;
export const {
  setNotificationId,
  setNotificationValueByName,
  setNotificationDirection,
  setNotificationIsActive,
  setNotificationPeriod,
  setCurrentGroupId,
  setSubscriptions,
  filterNotificationsByType,
  resetChanges,
} = notificationIA.actions;
