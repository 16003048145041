import React from "react";

import { postfixes, symbols } from "root/utils";
import { getDateByDayBack } from "root/utils/date";

import { TrendRequestsCircleRating } from "root/pages/trend-requests/components/ByParameters/RatingSelector";

const getRatingValue = v => {
  if (v < 1 || v > 100 || isNaN(v)) return 0;
  if (v <= 20) return 1; // Ужасные
  if (v <= 35) return 2; // Плохие
  if (v <= 50) return 3; // Средние
  if (v <= 75) return 4; // Хорошие
  if (v <= 100) return 5; // Лучшие
};

// --------------------------------------------------------------------------
const trendRequestsParams = [
  {
    dataIndex: "rating",
    title: "Рейтинг качества запроса",
    hint: "Рейтинг рассчитывается исходя из значений показателей выручки, частотности, рекламы, монополизации, количества отзывов и других параметров",
    render: v => <TrendRequestsCircleRating count={getRatingValue(v)} />,
    default: true,
  },
  {
    dataIndex: "days30",
    title: "Частотность за 30 дн.",
    default: true,
  },
  {
    dataIndex: "days60",
    title: "Частотность за 60 дн.",
  },
  {
    dataIndex: "days90",
    title: "Частотность за 90 дн.",
  },
  {
    dataIndex: "diff_percent30",
    // title: "Изменение частотности за 30 дн. в процентном соотношении запросов",
    title: "Динамика за 30 дн.",
    hasTarget: true,
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "diff_percent60",
    // title: "Изменение частотности за 60 дн. в процентном соотношении запросов",
    title: "Динамика за 60 дн.",
    hasTarget: true,
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "diff_percent90",
    // title: "Изменение частотности за 90 дн. в процентном соотношении запросов",
    title: "Динамика за 90 дн.",
    hasTarget: true,
    postfix: postfixes.percent,
    default: true,
  },
  // {
  //   dataIndex: "weighted_price",
  //   title: "Средний чек",
  //   postfix: postfixes.rub,
  //   default: true,
  // },
  // {
  //   dataIndex: "demand_coefficient",
  //   title: "Коэффициент демпинга",
  //   postfix: postfixes.percent,
  //   default: true,
  // },
  {
    dataIndex: "proceeds",
    title: "Выручка за 30 дн. у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.rub,
    default: true,
  },
  {
    dataIndex: "lost_proceeds",
    title: "Упущенная выручка за 30 дн. у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.rub,
  },
  {
    dataIndex: "potential_proceeds",
    title: "Потенциал выручки за 30 дн. у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.rub,
    // default: true,
  },
  {
    dataIndex: "avg_proceeds_product",
    title: "Средняя выручка за 30 дн. у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.rub,
    default: true,
  },
  {
    dataIndex: "avg_proceeds_product_sales",
    title: "Средняя выручка за 30 дн. у артикулов с продажами на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.rub,
    // default: true,
  },
  {
    dataIndex: "percent_lost_proceeds",
    title: "Процент упущенной выручки за 30 дн. у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "percent_proceeds_top30",
    title: "Монопольность",
    hint: "Процент выручки, приходящийся на топ 30 артикулов от всех артикулов на первой странице. Показатель рассчитывается на последнюю дату анализируемого периода, для расчёта используется выручка за 30 дн.. Если артикулов по запросу меньше 30-ти то мы по умолчанию ставим 100Процент",
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "orders",
    title: "Количество заказов у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.pcs,
  },
  {
    dataIndex: "lost_orders",
    title: "Упущенные заказы у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.pcs,
  },
  {
    dataIndex: "percent_lost_orders",
    title: "Процент упущенных заказов у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.percent,
  },
  {
    dataIndex: "potential_orders",
    title: "Потенциал заказов у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.pcs,
  },
  {
    dataIndex: "avg_price",
    title: "Средняя цена у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.rub,
    default: true,
  },
  {
    dataIndex: "median_price",
    title: "Медианная цена у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.rub,
    // default: true,
  },
  {
    dataIndex: "percent_count_products_advertising",
    title: "Процент артикулов в рекламе на первой странице",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "percent_products_external_traffic",
    title: "Процент артикулов с внешним трафиком на первой странице",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "products",
    // title: "Количество продуктов на сегодня",
    title: "Количество артикулов по запросу",
    postfix: postfixes.pcs,
    default: true,
  },
  {
    dataIndex: "demand_coefficient_product",
    // title: "Коэффициент спроса артикула за 30 дн.",
    title: "Отношение частотности запроса к количеству артикулов по запросу",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.pcs,
    default: true,
  },
  {
    dataIndex: "percent_products_sales",
    title: "Процент артикулов с продажами у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "percent_sellers_sales",
    title: "Процент поставщиков с продажами у артикулов на первых 3-х страницах",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.percent,
  },
  {
    dataIndex: "avg_reviews_top100",
    title:
      "Среднее количество отзывов у артикулов на первой странице без учета внутренней рекламы WB",
    hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
    postfix: postfixes.pcs,
    default: true,
  },
  {
    dataIndex: "subject_percent_sales",
    title: "Процент выкупа",
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "subject_fbo",
    // title: "Склад WB",
    title: "Комиссия FBO",
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "subject_fbs_to_fbo",
    // title: "Склад продавца - везу на склад WB",
    title: "Комиссия FBS",
    postfix: postfixes.percent,
  },
  {
    dataIndex: "subject_fbs_self_deliver",
    // title: "Склад продавца - везу самостоятельно до клиента",
    title: "Комиссия DBS",
    postfix: postfixes.percent,
  },
  {
    dataIndex: "subject_fbs_self_deliver_express",
    // title: "Склад продавца - везу самостоятельно до клиента экспресс",
    title: "Комиссия DBS Express",
    postfix: postfixes.percent,
  },

  // ----------------------------------------------------------------

  // {
  //   dataIndex: "frequency_per_product30",
  //   title: "Количество запросов на 1 артикул",
  //   default: true,
  // },

  // {
  //   dataIndex: "count_products",
  //   title: "Количество артикулов за 30 дн.",
  //   postfix: postfixes.pcs,
  // },
  // {
  //   dataIndex: "count_products_sales",
  //   title: "Количество артикулов с продажами за 30 дн.",
  //   postfix: postfixes.pcs,
  // },

  // {
  //   dataIndex: "percent_products_advertising",
  //   title: "Процент артикулов в рекламе на первой странице за 30 дн.",
  //   postfix: postfixes.percent,
  //   default: true,
  // },`

  // {
  //   dataIndex: "count_products_external_traffic",
  //   title: "Количество артикулов с внешним трафиком на первой странице",
  //   hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
  //   postfix: postfixes.pcs,
  // },

  // {
  //   dataIndex: "count_sellers",
  //   title: "Количество поставщиков у артикулов на первых 3-х страницах",
  //   hint: "Показатель рассчитывается на последнюю дату анализируемого периода",
  //   postfix: postfixes.pcs,
  //   // default: true,
  // },
  // {
  //   dataIndex: "count_sellers_sales",
  //   title: "Количество поставщиков с продажами за 30 дн.",
  //   postfix: postfixes.pcs,
  //   // default: true,
  // },

  // {
  //   dataIndex: "avg_speed_reviews_top100",
  //   title:
  //     "Средняя скорость набора отзывов у органических артикулов (не рекламных АРК + Аукцион) из топ 100 за 30 дн.",
  //   postfix: postfixes.pcs,
  //   default: true,
  // },
  // {
  //   dataIndex: "first_week30",
  //   title: "Частотность за 30 дн. в самую раннюю неделю за 30 дн.",
  // },
  // {
  //   dataIndex: "first_week60",
  //   title: "Частотность за 30 дн. в самую раннюю неделю за 60 дн.",
  // },
  // {
  //   dataIndex: "first_week90",
  //   title: "Частотность за 30 дн. в самую раннюю неделю за 90 дн.",
  // },
  // {
  //   dataIndex: "last_week30",
  //   title: "Частотность за 30 дн. в самую последнюю неделю за 30 дн.",
  // },
  // {
  //   dataIndex: "last_week60",
  //   title: "Частотность за 30 дн. в самую последнюю неделю за 60 дн.",
  // },
  // {
  //   dataIndex: "last_week90",
  //   title: "Частотность за 30 дн. в самую последнюю неделю за 90 дн.",
  // },
  // {
  //   dataIndex: "diff_frequency30",
  //   // title: "Изменение частотности за 30 дн. в количестве запросов",
  //   title: "Динамика за 30 дн. в количестве запросов",
  //   hasTarget: true,
  // },
  // {
  //   dataIndex: "diff_frequency60",
  //   // title: "Изменение частотности за 60 дн. в количестве запросов",
  //   title: "Динамика за 60 дн. в количестве запросов",
  //   hasTarget: true,
  // },
  // {
  //   dataIndex: "diff_frequency90",
  //   // title: "Изменение частотности за 90 дн. в количестве запросов",
  //   title: "Динамика за 90 дн. в количестве запросов",
  //   hasTarget: true,
  // },

  // {
  //   dataIndex: "count_products_advertising",
  //   title: "Количество артикулов в рекламе на первой странице за 30 дн.",
  //   postfix: postfixes.pcs,
  // },
  // {
  //   dataIndex: "count_products_advertising ",
  //   title: "Количество артикулов в рекламе на первой странице",
  //   postfix: postfixes.pcs,
  // },
].map(item => ({
  ...item,
  title: item.postfix ? `${item.title}, ${item.postfix.trim()}` : item.title,
}));
// -----------------------------------------------------------------------------
const trendRequestsSeasonality = {
  option: 0,
  list_month_is_seasonality: [],
  list_month_is_up_trend: [],
  list_month_is_dow_trend: [],
};
const getTrendRequestsSeasonalityParams = seasonality => {
  const obj = {};
  switch (seasonality.option) {
    case 0:
      break;
    case 1:
      obj.is_seasonality__isnull = true;
      break;
    case 2:
      obj.is_seasonality__exact = true;
      break;
    case 3:
      obj.is_seasonality__exact = false;
      break;
  }
  if (seasonality.list_month_is_seasonality.length && ![1, 3].includes(seasonality.option))
    obj.list_month_is_seasonality__contains = seasonality.list_month_is_seasonality?.join();
  if (seasonality.list_month_is_up_trend.length)
    obj.list_month_is_up_trend__contains = seasonality.list_month_is_up_trend?.join();
  if (seasonality.list_month_is_dow_trend.length)
    obj.list_month_is_dow_trend__contains = seasonality.list_month_is_dow_trend?.join();

  return obj;
};
// --------------------------------------------------------------------------
const reduxTrendRequestsParams = trendRequestsParams.reduce((acc, item) => {
  if (item.hasTarget) {
    acc[item.dataIndex] = {
      target: null,
      gte: "",
      lte: "",
    };
    acc[item.dataIndex] = {
      target: null,
      gte: "",
      lte: "",
    };
  } else {
    acc[item.dataIndex + "__lte"] = "";
    acc[item.dataIndex + "__gte"] = "";
  }
  return acc;
}, {});
reduxTrendRequestsParams.name__icontains = "";
//
const checkHaveTrendRequestsParamsFilters = params => {
  for (const key in params) {
    if (typeof params[key] === "object") {
      if (params[key].target !== null && (params[key].gte || params[key].lte)) return true;
    } else {
      if (params[key]) return true;
    }
  }
  return false;
};
//
const defaultTrendRequestsCreateData = getDateByDayBack(30);
// --------------------------------------------------------------------------
const trendRequestsSelectors = {
  create_date: defaultTrendRequestsCreateData,
  subject_ids: [],
  rating_range__in: [],
};

export {
  trendRequestsParams,
  reduxTrendRequestsParams,
  checkHaveTrendRequestsParamsFilters,
  defaultTrendRequestsCreateData,
  trendRequestsSelectors,
  trendRequestsSeasonality,
  getTrendRequestsSeasonalityParams,
};
