import React, { useEffect, useState } from "react";

import { isNil } from "ramda";
import { useDispatch, useSelector } from "react-redux";

import API from "root/js/api";

import DiagramBarChards from "root/components/global/diagrama/diagram-bar-chards";

import { downloadFile } from "root/js/utils";

const PenaltyDiagramLine = ({ tab }) => {
  const {
    penaltyDiagramLine,
    currencyName,
    penaltyReasoneImg,
    keys,
    categoryId,
    dateFrom,
    dateTo,
    brandIds,
    articulIds,
    barcodeIds,
    subjectsId,
  } = useSelector(state => state.financeLogic);
  const dispatch = useDispatch();
  const WBAPI = new API();
  const [customExtendedData, setCustomExtendedData] = useState([]);
  const [dynamicWidth, setDynamicWidth] = useState(1500);
  const [isExporting, setisExporting] = useState(false);

  const onResize = () => {
    const width = document.documentElement.offsetWidth;

    if (width > 2100) {
      setDynamicWidth(1600);
    } else if (width < 1440) {
      setDynamicWidth(1000);
    } else {
      setDynamicWidth(width - 250);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const {
        payload: { type_name },
      } = payload[0];

      const date = penaltyReasoneImg.find(elem => elem.type_name === type_name);

      return "";
      // <div className={styles.diagramDecription}>
      //   {date.info.map((elem, index) => {
      //     return (
      //       <div key={index} className={styles.itemBlock}>
      //         <img src={elem.product_thumbnail} alt="img"/>
      //         <span style={{ display: "inline-block", marginLeft: "8px" }}>
      //           <p style={{ marginBottom: "5px"}}>{elem.product_id}</p>
      //           <p style={{ maxWidth: "500px"}}>{elem.product_name}</p>
      //         </span>
      //       </div>
      //     );
      //   })}
      // </div>
    }

    return null;
  };

  const values = penaltyDiagramLine?.map(elem =>
    elem.value !== null ? Number(elem.value).toFixed(0) : "Нет данных"
  );

  const handleTypeClick = async typeName => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    try {
      const res = await WBAPI.penaltyAdditionalsPenaltyTypes({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,
        subject,
        value: tab,
        type_name: typeName,
      });
      const info = [...customExtendedData];
      const item = info.find(el => el.type_name === typeName);

      if (item) {
        const types = info.map(el => {
          if (el.type_name === typeName) {
            return {
              type_name: typeName,
              info: res,
            };
          } else {
            return el;
          }
        });
        setCustomExtendedData(types);
      } else {
        const types = [
          ...info,
          {
            type_name: typeName,
            info: res,
          },
        ];
        setCustomExtendedData(types);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const isEmptyData = penaltyDiagramLine.some(
    item =>
      isNil(item.penalty) ||
      isNil(item.penalty_quantity) ||
      item.penalty === 0 ||
      item.penalty_quantity === 0
  );
  const exportAdditionalsPenaltyTypes = async typeName => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    try {
      setisExporting(true);
      const res = await WBAPI.penaltyAdditionalsPenaltyTypesExport({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,
        subject,
        value: tab,
        type_name: typeName,
      });
      downloadFile(res.url, `Автореклама`);
    } catch (err) {
      console.error(err);
    } finally {
      setisExporting(false);
    }
  };

  return (
    <DiagramBarChards
      CustomTooltip={CustomTooltip}
      ticksValue={values}
      data={penaltyDiagramLine}
      title="Основания штрафов и компенсаций"
      titleTrip="При нажатии на основание штрафа появится всплывающее окно с перечнем товаров и суммой (количеством) штрафов для каждого товара"
      name="type_name"
      value={tab === "penalty" ? "penalty" : "penalty_quantity"}
      width={dynamicWidth}
      height={penaltyDiagramLine?.length * 60}
      positionEnd={true}
      tab={tab}
      compaireText="penalty"
      color="#D44CA9"
      valuet={currencyName}
      secondValuet="шт"
      leftYAxisIsClickable={true}
      rightYAxisWidth={100}
      leftYAxisWidth={450}
      customExtendedData={customExtendedData}
      handleTypeClick={handleTypeClick}
      hasMinBarHeight
      interval={0}
      exportFunction={exportAdditionalsPenaltyTypes}
      isExporting={isExporting}
      styleWripper={{ maxWidth: "100%" }}
      isEmptyData={isEmptyData}
    />
  );
};

export default PenaltyDiagramLine;
