import React, { forwardRef } from "react";

import Template from "./utils/Template";

export default forwardRef((props, ref) => (
  <Template
    ref={ref}
    tags={["none"]}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}>
    <path
      d="M15.5011 6.25175C15.4537 6.11457 15.3676 5.99407 15.2531 5.90488C15.1386 5.81569 15.0007 5.76163 14.8561 5.74925L10.5886 5.12675L8.67608 1.24925C8.61467 1.12245 8.51878 1.01551 8.3994 0.940684C8.28002 0.865858 8.14197 0.826172 8.00108 0.826172C7.86019 0.826172 7.72215 0.865858 7.60277 0.940684C7.48338 1.01551 7.3875 1.12245 7.32608 1.24925L5.41358 5.11925L1.14608 5.74925C1.00727 5.76898 0.876775 5.82723 0.769394 5.91737C0.662014 6.00752 0.582052 6.12596 0.538582 6.25925C0.49879 6.38951 0.49522 6.52814 0.528253 6.66027C0.561285 6.79241 0.629675 6.91305 0.726082 7.00925L3.82358 10.0093L3.07358 14.2693C3.04681 14.4099 3.06083 14.5552 3.11399 14.6881C3.16714 14.821 3.25722 14.9359 3.37358 15.0193C3.48699 15.1003 3.62076 15.1482 3.75987 15.1575C3.89897 15.1667 4.03791 15.1371 4.16108 15.0718L8.00108 13.0693L11.8261 15.0793C11.9313 15.1386 12.0502 15.1696 12.1711 15.1693C12.33 15.1698 12.4849 15.1199 12.6136 15.0268C12.7299 14.9434 12.82 14.8285 12.8732 14.6956C12.9263 14.5627 12.9404 14.4174 12.9136 14.2768L12.1636 10.0168L15.2611 7.01675C15.3694 6.92501 15.4494 6.80443 15.4919 6.66902C15.5344 6.5336 15.5376 6.38891 15.5011 6.25175ZM10.8886 9.25175C10.8006 9.33683 10.7348 9.44213 10.6969 9.55848C10.659 9.67483 10.6501 9.79869 10.6711 9.91925L11.2111 13.0618L8.39108 11.5618C8.28257 11.504 8.16152 11.4738 8.03858 11.4738C7.91565 11.4738 7.79459 11.504 7.68608 11.5618L4.86608 13.0618L5.40608 9.91925C5.42703 9.79869 5.41817 9.67483 5.38026 9.55848C5.34234 9.44213 5.27654 9.33683 5.18858 9.25175L2.93858 7.00175L6.09608 6.54425C6.21758 6.52735 6.33308 6.48091 6.43246 6.40899C6.53183 6.33708 6.61205 6.24188 6.66608 6.13175L8.00108 3.27425L9.41108 6.13925C9.46511 6.24938 9.54533 6.34458 9.64471 6.41649C9.74408 6.48841 9.85958 6.53485 9.98108 6.55175L13.1386 7.00925L10.8886 9.25175Z"
      fill="white"
    />
  </Template>
));
