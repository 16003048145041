import request from "root/js/request";

// получить все склейки
const getAnalyticsGluing = async ({
  imt_id,
  cabinet_id__in,
  subject_id__in,
  brand_id__in,
  product_id__icontains,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/gluing/?extra_fields=conversion",
    method: "get",
    params: {
      imt_id,
      cabinet_id__in,
      subject_id__in,
      brand_id__in,
      product_id__icontains,
      ...rest,
    },
  });
  return response.data;
};

// получить активную склейку
const getAnalyticsGluingActive = async ({ imt_id, cabinet_id }) => {
  const response = await request({
    url: `/analytics/gluing/${imt_id}/?extra_fields=conversion`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response.data;
};

// получить список артикулов доступные для добавления в склейку
const getAnalyticsGluingProducts = async ({ imt_id, cabinet_id, ...rest }) => {
  const response = await request({
    url: `/analytics/gluing/${imt_id}/products/`,
    method: "get",
    params: {
      cabinet_id,
      ...rest,
    },
  });
  return response.data;
};

// склеить карточку
const addAnalyticsGluing = async ({ imt_id, cabinet_id, product_ids }) => {
  const response = await request({
    url: `/analytics/gluing/${imt_id}/merge/`,
    method: "post",
    params: {
      cabinet_id,
    },
    data: {
      product_ids,
    },
  });
  return response.data;
};

// разъединить карточку
const deleteAnalyticsGluing = async ({ cabinet_id, product_id }) => {
  const response = await request({
    url: `/analytics/gluing/unmerge/`,
    method: "post",
    params: {
      cabinet_id,
    },
    data: {
      product_id,
    },
  });
  return response;
};

export {
  addAnalyticsGluing,
  getAnalyticsGluing,
  getAnalyticsGluingActive,
  getAnalyticsGluingProducts,
  deleteAnalyticsGluing,
};
