import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { generate as randStr } from "randomstring"
import { useHistory, useLocation } from "react-router-dom"

import {/*isNumeric, randStr,*/  objToUrlParams } from "root/js/utils"

const navigationIdParamName = "navigationid"

const getPathname = (str) => str.replace(/\?.*/, "")

const urlMatch = (a, b) => {
	return getPathname(a) === getPathname(b)
}

const getSessionNavigation = id => {
	const exist = window.sessionStorage.getItem(id)
	return exist ? JSON.parse(exist) : null
}
const setSessionNavigation = (id, arr) => window.sessionStorage.setItem(id, JSON.stringify(arr))

export default function useNavigationMemory({
	currentPageName: currentPageNameProp,
	rewriteLinks = false,
	groupId,
	keep = true,
	initial = false,
	allowSingle = true,
} = {}) {
	const history = useHistory()
	const location = useLocation()

	const [currentPageName, setCurrentPageName] = useState(currentPageNameProp || document.title)
	const unlistenNavigation = useRef(false)

	const locationHref = useMemo(() => {
		return `${location.pathname}${location.search}`
	}, [location])

	const params = useMemo(() => {
		const urlParams = new URLSearchParams(location.search)
		return Object.fromEntries(urlParams.entries())
	}, [location])

	const pureParams = useMemo(
		() =>
			Object.fromEntries(
				Object.entries(params).filter(pair => pair[0] !== navigationIdParamName)
			),
		[params]
	)

	const existingNavigationId = useMemo(
		() => params[navigationIdParamName] || groupId || undefined,
		[params, groupId]
	)

	const navigationId = useMemo(() => {
		return (
			existingNavigationId ||
			randStr({
				length: 16,
				charset: "alphabetic",
			})
		)
	}, [existingNavigationId])

	const navigationHistory = useMemo(() => {
		const currentPage = {
			url: locationHref,
			name: currentPageName,
			active: true,
			keep,
			initial,
		}
		const result = []
		const sessionNavigationHistory =
			existingNavigationId && getSessionNavigation(existingNavigationId)
		if (
			!initial &&
			((!allowSingle && !existingNavigationId) ||
				(existingNavigationId && !sessionNavigationHistory))
		) {
			return []
		} else if (existingNavigationId && sessionNavigationHistory) {
			const prevActiveIndex = sessionNavigationHistory.findIndex(page => page.active)
			let activePageFound
			sessionNavigationHistory.forEach((page, index) => {
				page.active = urlMatch(page.url, location.pathname)
				if (page.active) {
					Object.assign(page, currentPage)
					activePageFound = {
						index,
						page,
					}
				}
			})

			//if current page is initial and it has no params but there are some pages in history and current page is not active so reset history

			if (
				initial &&
				(Object.keys(params).length === 0 ||
					(Object.keys(params).length === 1 && params[navigationIdParamName]) ||
					(Object.keys(params).length === 2 &&
						params[navigationIdParamName] &&
						params.tab)) /* &&
				activePageFound &&
				(activePageFound.index !== 0 || !activePageFound.page.initial)*/
			) {
				result.push(currentPage)
			} else {
				!activePageFound && sessionNavigationHistory.push(currentPage)
				const currentActiveIndex = sessionNavigationHistory.findIndex(page => page.active)
				result.push(
					...sessionNavigationHistory.filter(
						(item, index) =>
							//clear page if it's between prev and current (when we jump)
							!(prevActiveIndex < index && currentActiveIndex > index) ||
							item.active ||
							item.keep
					)
				)
			}
		} else {
			result.push(currentPage)
		}
		setSessionNavigation(navigationId, result)
		return result
	}, [
		location,
		navigationId,
		existingNavigationId,
		allowSingle,
		currentPageName,
		locationHref,
		keep,
		initial,
		params,
	])

	const navigateWithParams = useCallback(
		(url, obj) => {
			obj[navigationIdParamName] = navigationId
			const urlWithParams = `${url}?${objToUrlParams(obj)}`
			const foundInHistory = navigationHistory.find(item => urlMatch(item.url, url))
			if (foundInHistory) {
				foundInHistory.url = urlWithParams
				setSessionNavigation(navigationId, navigationHistory)
			}

			history.push(urlWithParams)
		},
		[navigationId, history, navigationHistory]
	)

	const changeParams = useCallback((key, value) => {
		const currentUrl = window.location.href;
		const url = new URL(currentUrl);
		url.searchParams.set(key, value);
		// Push the updated URL to the browser history
		window.history.pushState({}, '', url.toString());
	}, [])

	const createNavigationLink = useCallback(
		url => {
			const pathname = getPathname(url)
			const search = url.match(/\?.+/)
			if (search) {
				const urlParams = new URLSearchParams(search[0])
				const newParams = Object.fromEntries(urlParams.entries())
				newParams[navigationIdParamName] = navigationId
				return `${pathname}?${objToUrlParams(newParams)}`
			} else {
				return `${pathname}?${navigationIdParamName}=${navigationId}`
			}
		},
		[navigationId]
	)

	useEffect(() => {
		if (!existingNavigationId && allowSingle) {
			const newParams = {
				...params,
				[navigationIdParamName]: navigationId,
			}
			history.replace(`${location.pathname}?${objToUrlParams(newParams)}`)
		}
	}, [history, navigationId, location, existingNavigationId, allowSingle, params])

	useEffect(() => {
		const callback = mutations => {
			setCurrentPageName(mutations[0].target.innerText)
		}
		const observer = new MutationObserver(callback)
		if (!currentPageNameProp) {
			observer.observe(document.querySelector("title"), {
				subtree: true,
				characterData: true,
				childList: true,
			})
		}

		return () => {
			observer.disconnect()
		}
	}, [currentPageNameProp])

	useEffect(() => {
		if (!rewriteLinks) return
		const unlisten = history.listen(({ pathname, search }) => {
			//location.pathname === pathname — if prev pathname === current
			if (unlistenNavigation.current || location.pathname === pathname) return
			unlistenNavigation.current = true
			history.replace(createNavigationLink(`${pathname}${search}`))
		})
		return unlisten
	}, [rewriteLinks, location.pathname, history, createNavigationLink])
	return {
		navigationHistory,
		params: pureParams,
		navigateWithParams,
		navigationId,
		setCurrentPageName,
		createNavigationLink,
		changeParams
	}
}
