import React, { useState } from "react";

import { Paper } from "@mui/material";
import { isNil } from "ramda";

import IALineChartLocf from "root/components/Charts/IALineChartLocf";

const PaidDiagrams = ({ tab, paidDiagram }) => {
  const [isCheckOne, setIsCheckOne] = useState(true);
  const [isCheckTwo, setIsCheckTwo] = useState(true);

  const titleValue =
    tab === "warehouse_price_value"
      ? "Стоимость хранения в динамике в заданном периоде"
      : "Количество товара, подлежащего тарифицированию, в динамике в заданном периоде";

  const typeValue =
    tab === "warehouse_price_value"
      ? "Стоимость хранения"
      : "Количество товара, подлежащего тарифицированию";

  const currency = tab === "warehouse_price_value" ? "руб" : "шт";

  const prepareDataPaid = (elem, field) => ({
    date: elem.date,
    dateTick: elem.date,
    checkOnePress: !isNil(+elem.volume) ? +elem.volume : null,
    checkTwoPress: !isNil(+elem[field]) ? +elem[field] : null,
  });

  const formattedData = paidDiagram?.map(item => {
    const field = tab === "warehouse_price_value" ? "warehouse_price" : "barcodes_count";
    return prepareDataPaid(item, field);
  });

  const generateComponentDiagram = (data, currencyName, currencyNameTwo) => {
    return (
      <IALineChartLocf
        title={titleValue}
        data={data}
        currencyName={currencyName}
        currencyNameTwo={currencyNameTwo}
        isCheckOne={isCheckOne}
        isCheckTwo={isCheckTwo}
        setIsCheckOne={setIsCheckOne}
        setIsCheckTwo={setIsCheckTwo}
        labelOne={"Объём товара"}
        labelTwo={typeValue}
        checkOne={true}
        checkTwo={true}
        colorOne={"#33CAA6"}
        colorTwo={"#7D3ECE"}
        isBarOne={true}
        YAxisLeftColor={true}
        isShowYAxisRight={true}
      />
    );
  };
  return (
    <Paper
      elevation={0}
      style={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}>
      {generateComponentDiagram(formattedData, "л", currency)}
    </Paper>
  );
};

export default PaidDiagrams;
