import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //============PromotionMedia
  showPositionChart: true,
  status: [],
  type: [8, 9],
  companies: [],
  canFetch: false,
  //============FinesAndCompensation
  finesAndCompensationTypeNames: [],
};

const salesAnalyticsSlice = createSlice({
  name: "salesAnalytics",
  initialState,
  reducers: {
    //============PromotionMedia
    promotionMediaFilter: (state, action) => {
      const { name, value } = action.payload;
      if (name === "status") {
        state.status = value;
      } else if (name === "type") {
        state.type = value;
      } else if (name === "company") {
        state.companies = value;
      }
      state.canFetch = state.status.length > 0 && state.type.length > 0;
    },

    //============FinesAndCompensation
    addCheckboxTypeName: (state, action) => {
      state.finesAndCompensationTypeNames = action.payload;
      state.canFetch = state.finesAndCompensationTypeNames.length > 0;
    },
  },
});

export const { promotionMediaFilter, addCheckboxTypeName } = salesAnalyticsSlice.actions;
export const salesAnalyticsSliceReducer = salesAnalyticsSlice.reducer;
