import { createAsyncThunk } from "@reduxjs/toolkit"
import { isEmpty, isNil, propOr } from "ramda"

import API from "root/js/api"

const WBAPI = new API()

export const inputParams = createAsyncThunk(
    "/inputParams",
    async (params, thunkAPI) => {
        try {
            return params
        } catch (error) {
            return thunkAPI.rejectWithValue("Error")
        }
    }
)

export const productFrequencyBrands = createAsyncThunk(
    "/productFrequencyBrands",
    async ({ params }, thunkAPI) => {
        try {
            const data = await WBAPI.getProductFrequencyBrands(params)
            return data
        } catch (error) {
            return thunkAPI.rejectWithValue("Error")
        }
    }
)

export const productFrequencySeller = createAsyncThunk(
    "/productFrequencySeller",
    async ({ params }, thunkAPI) => {
        try {
            const data = await WBAPI.getProductFrequencySellers(params)
            return data
        } catch (error) {
            return thunkAPI.rejectWithValue("Error")
        }
    }
)

export const productFrequencyObjectKeys = createAsyncThunk(
    "/productFrequencyObjectKeys",
    async (params, thunkAPI) => {
        try {
            return params
        } catch (error) {
            return thunkAPI.rejectWithValue("Error")
        }
    }
)

export const setAdverts = createAsyncThunk(
	"/setAdverts",
	async (params, thunkAPI) => {
		try {
			const data = await WBAPI.getSupplierArticleAdverts({ ...params })
			return { ...params, ...data }
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)

export const getSupplierArticleAdvertsUsageHistory = createAsyncThunk(
	"/getSupplierArticleAdvertsUsageHistory",
	async (params, thunkAPI) => {
		try {
			const data = await WBAPI.getSupplierArticleAdvertsUsageHistory({
				supplier_article: params.supplier_article_ids ? undefined : params.addArticule,
				supplier_article_ids: params.supplier_article_ids,
			})

			return {
				...data,
				intervalId: params.intervalId,
				addArticule: params.addArticule
			}
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)


export const setInfo = createAsyncThunk("/setInfo", async (params, thunkAPI) => {
	try {
		return params
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const setLimits = createAsyncThunk("/setLimits", async (_, thunkAPI) => {
	try {
		const data = await WBAPI.getSupplierArticleAdvertsLimits()
		return data
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})

export const setGlobalInfo = createAsyncThunk("/setGlobalInfo", async (params, thunkAPI) => {
	try {
		return params
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})

export const setErrorInfo = createAsyncThunk("/setErrorInfo", async (params, thunkAPI) => {
	try {
		return params
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const moveObjectFromDataObjects = createAsyncThunk("/moveObjectFromDataObjects", async (params, thunkAPI) => {
	try {
		return params
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const setDataObjects = createAsyncThunk("/setDataObjects", async (params, thunkAPI) => {
	try {
		const data = await params.dispatch(setAdverts({ supplier_article: params.value, marketplace: params?.marketplace ?? 1 }))
		if (propOr({}, "payload", data) == "Error") {
			return { status: "error", item: params.value }
		}
		return { ...propOr({}, "payload", data) }
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})

export const removeErrorEnqueueSnackbar = createAsyncThunk("/removeErrorEnqueueSnackbar", async (params, thunkAPI) => {
	try {
		return params
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const removeEmptyEnqueueSnackbar = createAsyncThunk("/removeEmptyEnqueueSnackbar", async (params, thunkAPI) => {
	try {
		return params
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const removeSuccessEnqueueSnackbar = createAsyncThunk("/removeSuccessEnqueueSnackbar", async (params, thunkAPI) => {
	try {
		return params
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})

export const startFetchingData = createAsyncThunk(
	"/startFetchingData",
	async ({ dispatch, supplier_article, successEnqueueSnackbar, addArticule = null }, thunkAPI) => {
		try {
			if (!isNil(addArticule)) {
				await dispatch(setAdverts({ dispatch, supplier_article: addArticule }))
			}
			await dispatch(getSupplierArticleAdvertsUsageHistory({
				addArticule
			}));
			const intervalId = setInterval(() => {
				dispatch(
					getSupplierArticleAdvertsUsageHistory({
						supplier_article: supplier_article.join(","),
						intervalId,
						successEnqueueSnackbar,
						addArticule
					})
				);
			}, 30000);

			return {
				intervalId,
				supplier_article
			}
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)



export const startFetchingNewData = createAsyncThunk(
	"/startFetchingNewData",
	async ({
		dispatch,
		successEnqueueSnackbar,
		supplier_article_ids = [],
		dataObjects
	}, thunkAPI) => {
		try {
			const intervalId = setInterval(() => {
				dispatch(
					getSupplierArticleAdvertsUsageHistory({
						supplier_article_ids: isEmpty(supplier_article_ids) ? undefined : supplier_article_ids,
						intervalId,
						dataObjects,
						successEnqueueSnackbar
					})
				);
			}, 30000);

			return {
				intervalId,
				supplier_article_ids
			}
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)

export const stopFetchingData = createAsyncThunk(
	'/stopFetchingData',
	async (intervalId, thunkAPI) => {
		try {
			// Очистите интервал
			clearInterval(intervalId);
			return intervalId;
		} catch (error) {
			return thunkAPI.rejectWithValue('Error');
		}
	}
);
