import request from "root/js/request";

export const getSocialRating = async ({
  account_name_hash__in,
  product_id__in,
  brand_id__in,
  subject_id__in,
  source__in,
  frequency_r__gte,
  frequency_r__lte,
  price_r__gte,
  price_r__lte,
  er_r__gte,
  er_r__lte,
  subscriber_r__gte,
  subscriber_r__lte,
  posts__gte,
  posts__lte,
  valueble_posts_percent_r_gte,
  valueble_posts_percent_r_lte,
  like_r__gte,
  like_r__lte,
  comment_r__gte,
  comment_r__lte,
  proceeds_r__gte,
  proceeds_r__lte,
  orders_percent_r__gte,
  orders_percent_r__lte,
  orders_r__gte,
  orders_r__lte,
  err_r__gte,
  err_r__lte,
  view_r__gte,
  view_r__lte,
  //
  frequency_avg__gte,
  frequency_avg__lte,
  price_avg__gte,
  price_avg__lte,
  er_avg__gte,
  er_avg__lte,
  subscriber_count__gte,
  subscriber_count__lte,
  posts_count__gte,
  posts_count__lte,
  valueble_posts_percent_gte,
  valueble_posts_percent_lte,
  like_avg__gte,
  like_avg__lte,
  comment_avg__gte,
  comment_avg__lte,
  proceeds_avg__gte,
  proceeds_avg__lte,
  orders_percent_avg__gte,
  orders_percent_avg__lte,
  orders_avg__gte,
  orders_avg__lte,
  err_avg__gte,
  err_avg__lte,
  view_avg__gte,
  view_avg__lte,
  //
  ordering,
  limit,
  offset,
  search,
}) => {
  const response = await request({
    url: `parsers/social/rating/`,
    method: "get",
    params: {
      account_name_hash__in,
      product_id__in,
      brand_id__in,
      subject_id__in,
      source__in,
      frequency_r__gte,
      frequency_r__lte,
      price_r__gte,
      price_r__lte,
      er_r__gte,
      er_r__lte,
      subscriber_r__gte,
      subscriber_r__lte,
      posts__gte,
      posts__lte,
      valueble_posts_percent_r_gte,
      valueble_posts_percent_r_lte,
      like_r__gte,
      like_r__lte,
      comment_r__gte,
      comment_r__lte,
      proceeds_r__gte,
      proceeds_r__lte,
      orders_percent_r__gte,
      orders_percent_r__lte,
      orders_r__gte,
      orders_r__lte,
      err_r__gte,
      err_r__lte,
      view_r__gte,
      view_r__lte,
      //
      frequency_avg__gte,
      frequency_avg__lte,
      price_avg__gte,
      price_avg__lte,
      er_avg__gte,
      er_avg__lte,
      subscriber_count__gte,
      subscriber_count__lte,
      posts_count__gte,
      posts_count__lte,
      valueble_posts_percent_gte,
      valueble_posts_percent_lte,
      like_avg__gte,
      like_avg__lte,
      comment_avg__gte,
      comment_avg__lte,
      proceeds_avg__gte,
      proceeds_avg__lte,
      orders_percent_avg__gte,
      orders_percent_avg__lte,
      orders_avg__gte,
      orders_avg__lte,
      err_avg__gte,
      err_avg__lte,
      view_avg__gte,
      view_avg__lte,
      //
      ordering,
      limit,
      offset,
      search,
    },
  });
  return response.data;
};
