import request from "root/js/request";

export const getAdvertAutoToPupSetting = async ({ advert_id, cabinet_id }) => {
  const response = await request({
    url: `/analytics/advert_autotopup/${advert_id}`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response;
};
