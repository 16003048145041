import React, { useState } from "react";

import { useMediaQuery } from "@mui/material";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const DiagramLine = props => {
  const {
    data,
    rechartsRef,
    isCheckOne,
    isCheckTwo,
    isCheckThree,
    isCheckFour,
    isTrend,
    isEvents,

    checkOne,
    checkTwo,
    checkThree,
    checkFour,
    trend,
    events,

    colorOne,
    colorTwo,
    colorThree,
    colorFour,

    yAxisTickFormater,
    customHeight,

    CustomYAxisTick,
    CustomYAxisTickTwoLeft,
    CustomYAxisTickRight,
    CustomYAxisTickTwoRight,

    getYaxisDomain,
    getYaxisDomainRight,
    getYaxisDomainTwoRight,
    getYaxisDomainTwoLeft,

    CustomTooltip,
    isZeroAxis,

    customDotTwoArea,
    customDotOneArea,
    customDotThreeArea,
    customDotFourArea,

    RenderCustomizedLabel,
    CustomTooltipBarEvents,
    activeEventDot,

    isBarOne,
    isBarTwo,
    isBarThree,
    isBarFour,

    YAxisLeftColor,

    isShowYAxisRight,
    isShowYAxisTwoRight,
    isShowYAxisTwoLeft,

    isHideTwoLeftLineY,
  } = props;

  const [activeTooltip, setActiveTooltip] = useState(null);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const yAxisDataKey = data?.reduce(
    ([dataKey, maxValue], val) => {
      const checkValues = [
        { key: "checkOnePress", value: val.checkOnePress },
        { key: "checkTwoPress", value: val.checkTwoPress },
        { key: "checkThreePress", value: val.checkThreePress },
        { key: "checkFourPress", value: val.checkFourPress },
      ].filter(item => item.value !== undefined && item.value !== null && !isNaN(item.value));

      checkValues.forEach(({ key, value }) => {
        if (value > maxValue) {
          dataKey = key;
          maxValue = value;
        }
      });

      return [dataKey, maxValue];
    },
    ["checkOnePress", 0]
  );

  const CustomXAxisTick = ({ x, y, payload }) => {
    const item = data?.find(el => el.date === payload.value);
    if (item) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={10} transform="rotate(-35)" fontSize={14} textAnchor="end" fill="#798097">
            {item.dateTick}
          </text>
        </g>
      );
    }
  };

  const handleMouseEnter = type => {
    setActiveTooltip(type);
  };

  return (
    <div style={{ width: isMobile ? "900px" : "100%", overflowX: isMobile ? "auto" : undefined }}>
      <ResponsiveContainer
        width="100%"
        // minWidth={960}
        height={customHeight ? customHeight : 340}
        ref={rechartsRef}
        debounce={340}>
        <ComposedChart
          width={200}
          height={customHeight ? customHeight : 340}
          data={data}
          margin={{ top: 10, right: 20, left: 40, bottom: 40 }}>
          <CartesianGrid strokeDasharray="9 9" />
          <XAxis
            stroke="#262626"
            axisLine={{ stroke: "#ccc" }}
            tickLine={{ stroke: "#ccc" }}
            dataKey="date"
            activeDot={false}
            tickSize={8}
            height={30}
            tick={CustomXAxisTick}
          />

          <YAxis
            axisLine={true}
            tickLine={false}
            dataKey={yAxisDataKey}
            tickSize={13}
            tickFormatter={yAxisTickFormater}
            tick={CustomYAxisTick}
            stroke={YAxisLeftColor ? colorOne : "#D6DAE0"}
            domain={getYaxisDomain && getYaxisDomain()}
          />

          {events && isEvents && (
            <YAxis
              hide={true}
              axisLine={false}
              tickLine={false}
              dataKey={"fakeYAxis"}
              stroke="transparent"
              yAxisId="fakeLeftAxis"
              domain={[0, 100]}
            />
          )}

          <Tooltip
            content={
              activeTooltip === "event" || activeEventDot ? (
                <CustomTooltipBarEvents />
              ) : (
                <CustomTooltip />
              )
            }
          />

          {events && isEvents && (
            <Bar
              dataKey="fakeYAxis"
              barSize={2}
              fill="#CF3AA0"
              yAxisId="fakeLeftAxis"
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter("event")}>
              <LabelList
                dataKey="eventName"
                content={RenderCustomizedLabel}
                orientation="vertical"
              />
            </Bar>
          )}

          {trend && isTrend && (
            <Line
              type="monotone"
              dataKey="proceeds_trend"
              fill="#FCBC00"
              strokeWidth={3}
              stroke="#FCBC00"
              strokeDasharray="0"
              dot={false}
              fillOpacity={0.1}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
              domain={getYaxisDomain && getYaxisDomain()}
            />
          )}

          {/* Сначала рендерим Bar, чтобы Line были выше Bar позиционно*/}
          {checkOne && isCheckOne && isBarOne && (
            <Bar
              dataKey="checkOnePress"
              fill={colorOne}
              barSize={30}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}

          {checkTwo && isCheckTwo && isBarTwo && (
            <Bar
              dataKey="checkTwoPress"
              fill={colorTwo}
              barSize={30}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}

          {checkThree && isCheckThree && isBarThree && (
            <Bar
              dataKey="checkThreePress"
              fill={colorThree}
              barSize={30}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}

          {checkFour && isCheckFour && isBarFour && (
            <Bar
              dataKey="checkFourPress"
              fill={colorFour}
              barSize={30}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}

          {checkTwo && isCheckTwo && isShowYAxisRight && (
            <YAxis
              yAxisId="right1"
              orientation="right"
              axisLine={true}
              tickLine={false}
              dataKey={yAxisDataKey}
              tickSize={13}
              tickFormatter={yAxisTickFormater}
              tick={CustomYAxisTickRight}
              stroke={colorTwo}
              domain={getYaxisDomainRight && getYaxisDomainRight()}
            />
          )}

          {checkThree && isCheckThree && isShowYAxisTwoRight && (
            <YAxis
              yAxisId="right2"
              orientation="right"
              axisLine={true}
              tickLine={false}
              dataKey={"checkThreePress"}
              tickSize={13}
              tickFormatter={yAxisTickFormater}
              tick={CustomYAxisTickTwoRight}
              stroke={colorThree}
              domain={getYaxisDomainTwoRight && getYaxisDomainTwoRight()}
            />
          )}

          {checkFour && isCheckFour && isShowYAxisTwoLeft && (
            <YAxis
              yAxisId="left2"
              orientation="left"
              axisLine={isHideTwoLeftLineY ? false : true}
              tickLine={false}
              dataKey={yAxisDataKey}
              tickSize={13}
              tickFormatter={yAxisTickFormater}
              tick={CustomYAxisTickTwoLeft}
              stroke={colorFour}
              domain={getYaxisDomainTwoLeft && getYaxisDomainTwoLeft()}
            />
          )}

          {checkOne && isCheckOne && !isBarOne && (
            <Line
              type="monotone"
              dataKey="checkOnePress"
              stroke={colorOne}
              strokeWidth={3}
              fill={colorOne}
              fillOpacity={0.1}
              dot={customDotOneArea ? customDotOneArea : false}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}

          {checkTwo && isCheckTwo && !isBarTwo && (
            <Line
              yAxisId={isShowYAxisRight ? "right1" : undefined}
              type="monotone"
              dataKey="checkTwoPress"
              fill={colorTwo}
              strokeWidth={3}
              stroke={colorTwo}
              fillOpacity={0.1}
              dot={customDotTwoArea ? customDotTwoArea : false}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}

          {checkThree && isCheckThree && !isBarThree && (
            <Line
              yAxisId={isShowYAxisTwoRight ? "right2" : undefined}
              type="monotone"
              dataKey="checkThreePress"
              stroke={colorThree}
              strokeWidth={3}
              fill={colorThree}
              fillOpacity={0.1}
              dot={customDotThreeArea ? customDotThreeArea : false}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}

          {checkFour && isCheckFour && !isBarFour && (
            <Line
              yAxisId={isShowYAxisTwoLeft ? "left2" : undefined}
              type="monotone"
              dataKey="checkFourPress"
              stroke={colorFour}
              strokeWidth={3}
              fill={colorFour}
              fillOpacity={0.1}
              dot={customDotFourArea ? customDotFourArea : false}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}
          {isZeroAxis && (
            <Area
              type="monotone"
              dataKey="zeroAxis"
              stroke="rgb(0, 0, 0)"
              strokeWidth={1}
              fill="rgb(255, 255, 255)"
              fillOpacity={1}
              strokeDasharray="3 3"
              activeDot={false}
              isAnimationActive={false}
              onMouseEnter={() => handleMouseEnter()}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DiagramLine;
