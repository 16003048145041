import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Typography } from "@mui/material";
import { isEmpty } from "ramda";
import { useHistory } from "react-router-dom";
import { WBapi } from "root/services/WBapi";

import BannerModal from "./BannerModal";
import BlockBanner from "./BlockBanner";
import { GlobalNotificationsColors } from "./utils";

import styles from "./index.module.scss";

import Close from "root/icons/Close";

// ------------------------------------------------------------------
const GlobalNotifications = ({ isPageBlocked, setIsPageBlocked }) => {
  const history = useHistory();
  const [haveNotification, setHaveNotification] = useState(false);
  const [correctData, setCorrectData] = useState([]);
  const removeNotification = id => {
    //
    const savedBanners = localStorage.getItem("banners");
    if (savedBanners) {
      const newSavedBanners = JSON.parse(savedBanners);
      newSavedBanners[id] = "closed";
      localStorage.setItem("banners", JSON.stringify(newSavedBanners));
    } else {
      const newSavedBanners = { [id]: "closed" };
      localStorage.setItem("banners", JSON.stringify(newSavedBanners));
    }
    //
    setCorrectData(prev => prev.filter(item => item.id !== id));
  };
  //
  const filterBannersBySaved = arr => {
    const savedBanners = JSON.parse(localStorage.getItem("banners"));
    if (!savedBanners) return arr;
    return arr.filter(({ id }) => !savedBanners[id]);
  };
  // SHOW BLOCK ON PAGES
  const correctDataInCurrentPage = useMemo(
    () =>
      correctData.filter(
        ({ module }) =>
          ("/dashboard" + history.location.pathname).includes(module) ||
          module === "general" ||
          module === "/dashboard"
      ),
    [correctData, history.location]
  );
  // -------------- FETCH LOGIC
  const { data: fetchData = [] } = WBapi.useGetBannersV2Query({});
  //
  useEffect(() => {
    if (!isEmpty(fetchData)) {
      setHaveNotification(fetchData);
      setCorrectData(fetchData);
    }
  }, [fetchData]);
  //
  useEffect(() => {
    const needBlockPage = !!correctDataInCurrentPage.find(item => item.is_block);
    if (needBlockPage !== isPageBlocked) setIsPageBlocked(needBlockPage);
  }, [correctDataInCurrentPage]);
  //
  const blockBanners = useMemo(
    () => filterBannersBySaved(correctDataInCurrentPage).filter(item => item.is_block),
    [correctDataInCurrentPage]
  );
  //
  const topBanners = useMemo(
    () =>
      filterBannersBySaved(correctDataInCurrentPage)
        .filter(item => !item.is_block)
        .filter(item => item.is_exitable),
    [correctDataInCurrentPage]
  );
  //
  const modalBanners = useMemo(
    () =>
      filterBannersBySaved(correctDataInCurrentPage)
        .filter(item => !item.is_block)
        .filter(item => !item.is_exitable),
    [correctDataInCurrentPage]
  );
  //
  const stateStyles = useCallback(
    color => {
      switch (color) {
        case 1:
          return { backgroundColor: GlobalNotificationsColors.red };
        case 2:
          return { backgroundColor: GlobalNotificationsColors.yellow };
        case 3:
          return { backgroundColor: GlobalNotificationsColors.green };
        default:
          return { backgroundColor: GlobalNotificationsColors.purple };
      }
    },
    [correctData]
  );
  if (!haveNotification) return null;
  return (
    <>
      {blockBanners.map(item => (
        <BlockBanner key={item.id} data={item} removeNotification={removeNotification} />
      ))}
      {modalBanners.map(item => (
        <BannerModal key={item.id} data={item} removeNotification={removeNotification} />
      ))}

      {topBanners.map(({ id, subject, description, description2, color, is_exitable }) => (
        <Box key={id} sx={stateStyles(color)} className={styles.notificationBox}>
          <Box className={styles.notificationInnerBox}>
            <Box mb={0.5}>
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}>{subject}</Typography>
            </Box>
            <Typography>{description}</Typography>
            <Typography>{description2}</Typography>
            {is_exitable && (
              <Box className={styles.closeIcon} onClick={() => removeNotification(id)}>
                <Close />
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default GlobalNotifications;
