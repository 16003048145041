import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";
import CheckboxBlankIcon from "mdi-material-ui/CheckboxBlank";
import CheckboxMarkedIcon from "mdi-material-ui/CheckboxMarked";

// import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';

import TooltipQuestionIcon from "../../icons/TooltipQuestion";
import TooltipUI from "../Tooltip";

import { palette } from "root/js/utils";

import styles from "./index.module.scss";

import CheckBoxDashedIcon from "root/icons/CheckBoxDashed";
import CheckBoxDashedCheckedIcon from "root/icons/CheckBoxDashedChecked";

const ColorCheckbox = props => {
  const {
    onRemove,
    disabled,
    onChange,
    color,
    name,
    subtitle,
    checked,
    className,
    classes = {},
    dashed,
    image = null,
    tooltip = null,
    tooltipText = tooltip,
    tooltipCheckboxText = null,
    ...rest
  } = props;

  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);

  return (
    <div
      {...rest}
      className={classnames(styles.root, className, classes.root)}
      data-aligncenter={!subtitle}
      data-disabled={disabled}
      data-changable={Boolean(onChange)}>
      {tooltipCheckboxText ? (
        <TooltipUI title={tooltipCheckboxText}>
          <div style={{ display: "flex" }}>
            <Checkbox
              className={styles.checkbox}
              onChange={disabled ? undefined : onChange}
              size="small"
              checked={checked}
              checkedIcon={
                dashed ? (
                  <CheckBoxDashedCheckedIcon stroke={color} fill={color} />
                ) : (
                  <CheckboxMarkedIcon />
                )
              }
              icon={
                dashed ? <CheckBoxDashedIcon stroke={color} fill={color} /> : <CheckboxBlankIcon />
              }
              style={{
                color: checked || (!checked && !onChange) ? color : palette.lightGrayishBlue,
              }}
            />
            <div className={styles.info}>
              {image && (
                <div
                  className={styles.modal}
                  style={{
                    opacity: isImageModalOpen ? "1" : "0",
                    display: isImageModalOpen ? "block" : "none",
                  }}>
                  <img alt="img" className={styles.modalImage} src={image} />
                </div>
              )}
              <div
                className={styles.nameContainer}
                onMouseOver={() => setIsImageModalOpen(true)}
                onMouseOut={() => setIsImageModalOpen(false)}>
                <div onClick={!disabled ? onChange : undefined} className={styles.name}>
                  {name}
                </div>
                {!subtitle && tooltipText && (
                  <TooltipUI
                    title={tooltipText}
                    enterTouchDelay={0}
                    style={{
                      height: "32px",
                      marginLeft: "6px",
                      cursor: "pointer",
                    }}>
                    <TooltipQuestionIcon />
                  </TooltipUI>
                )}
                {onRemove && <CloseIcon onClick={onRemove} className={styles.closeIcon} />}
              </div>
              {subtitle && tooltipText ? (
                <Tooltip title={tooltipText}>
                  <div
                    className={styles.subtitle}
                    onClick={!disabled ? onChange : undefined}
                    onMouseOver={() => setIsImageModalOpen(true)}
                    onMouseOut={() => setIsImageModalOpen(false)}>
                    {subtitle}
                  </div>
                </Tooltip>
              ) : (
                <div
                  className={styles.subtitle}
                  onClick={!disabled ? onChange : undefined}
                  onMouseOver={() => setIsImageModalOpen(true)}
                  onMouseOut={() => setIsImageModalOpen(false)}>
                  {subtitle}
                </div>
              )}
            </div>
          </div>
        </TooltipUI>
      ) : (
        <>
          <Checkbox
            className={styles.checkbox}
            onChange={disabled ? undefined : onChange}
            size="small"
            checked={checked}
            checkedIcon={
              dashed ? (
                <CheckBoxDashedCheckedIcon stroke={color} fill={color} />
              ) : (
                <CheckboxMarkedIcon />
              )
            }
            icon={
              dashed ? <CheckBoxDashedIcon stroke={color} fill={color} /> : <CheckboxBlankIcon />
            }
            style={{
              color: checked || (!checked && !onChange) ? color : palette.lightGrayishBlue,
            }}
          />
          <div className={styles.info}>
            {image && (
              <div
                className={styles.modal}
                style={{
                  opacity: isImageModalOpen ? "1" : "0",
                  display: isImageModalOpen ? "block" : "none",
                }}>
                <img alt="img" className={styles.modalImage} src={image} />
              </div>
            )}
            <div
              className={styles.nameContainer}
              onMouseOver={() => setIsImageModalOpen(true)}
              onMouseOut={() => setIsImageModalOpen(false)}>
              <div onClick={!disabled ? onChange : undefined} className={styles.name}>
                {name}
              </div>
              {!subtitle && tooltipText && (
                <TooltipUI
                  title={tooltipText}
                  enterTouchDelay={0}
                  style={{
                    height: "32px",
                    marginLeft: "6px",
                    cursor: "pointer",
                  }}>
                  <TooltipQuestionIcon />
                </TooltipUI>
              )}
              {onRemove && <CloseIcon onClick={onRemove} className={styles.closeIcon} />}
            </div>
            {subtitle && tooltipText ? (
              <Tooltip title={tooltipText}>
                <div
                  className={styles.subtitle}
                  onClick={!disabled ? onChange : undefined}
                  onMouseOver={() => setIsImageModalOpen(true)}
                  onMouseOut={() => setIsImageModalOpen(false)}>
                  {subtitle}
                </div>
              </Tooltip>
            ) : (
              <div
                className={styles.subtitle}
                onClick={!disabled ? onChange : undefined}
                onMouseOver={() => setIsImageModalOpen(true)}
                onMouseOut={() => setIsImageModalOpen(false)}>
                {subtitle}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ColorCheckbox;
