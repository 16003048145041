import React from "react"

import Switch from "@mui/material/Switch"
import classnames from "classnames"


import styles from "./index.module.scss"

const Switch_ = props => {
	const {className, classes = {}, ...rest} = props

	return (
		<Switch
			{...rest}
			className={classnames(styles.root, className, classes.root)}
			classes={{
				root: styles.root,
				track: styles.track,
				checked: styles.checked,
				thumb: styles.thumb,
				switchBase: styles.switchBase,
			}}
		/>
	)
}

export default Switch_
