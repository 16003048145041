const { createSlice } = require("@reduxjs/toolkit");

const loadStatus = {
  fulfilled: "fulfilled",
  pending: "pending",
  rejected: "rejected"
};

const initialState = {
  subjectsList: [],
  subjectsData: {},
  subjectsLoadStatus: loadStatus.fulfilled,
  checkedSubjects: [],
  showingColumns: [
    "vendor_codes",
    "product_with_orders",
    "brands_count",
    "sellers_count",
    "price",
    "discount",
    "proceeds",
    "proceeds_dynamic",
    "orders",
    "quantity"
  ]
};

const analysisBySubjectSlice = createSlice({
  name: "analysisBySubject",
  initialState,
  reducers: {
    addSubject(state, action) {
      state.subjectsList.push(action.payload);
    },
    setSubjects(state, { payload: newSubjectsList }) {
      state.subjectsList = newSubjectsList;
    },
    removeSubject(state, action) {
      state.subjectsList = state.subjectsList.filter(subjectName => subjectName !== action.payload);
    },
    setShowingColumns(state, action) {
      state.showingColumns = action.payload;
    },
    addToCheckedSubjects(state, action) {
      state.checkedSubjects.push(action.payload);
    },
    removeFromCheckedSubjects(state, action) {
      state.checkedSubjects = state.checkedSubjects.filter(item => item !== action.payload);
    }
  }
});

export const {
  addSubject,
  setSubjects,
  removeSubject,
  setShowingColumns,
  addToCheckedSubjects,
  removeFromCheckedSubjects
} = analysisBySubjectSlice.actions;
export default analysisBySubjectSlice.reducer;
