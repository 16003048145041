export const FBO_COORDINATES = [
  { warehouse__name: "Ереван 3", coordinates: [44.44829, 40.136107] },
  { warehouse__name: "Минск", coordinates: [27.399092, 53.857778] },
  { warehouse__name: "Хабаровск", coordinates: [135.110697, 48.44905] },
  { warehouse__name: "Улан-Удэ", coordinates: [107.641213, 51.840969] },
  { warehouse__name: "Атакент", coordinates: [76.904089, 43.222962] },
  { warehouse__name: "Астана", coordinates: [71.509418, 51.216201] },
  { warehouse__name: "Уфа 2", coordinates: [55.907505, 54.585382] },
  { warehouse__name: "Пенза", coordinates: [44.905937, 53.234341] },
  { warehouse__name: "Новосемейкино", coordinates: [50.333135, 53.38944] },
  { warehouse__name: "Казань", coordinates: [48.842408, 55.848721] },
  { warehouse__name: "Калининград", coordinates: [20.45905, 54.666244] },
  { warehouse__name: "Санкт-Петербург Уткина Заводь", coordinates: [30.509464, 59.84598] },
  { warehouse__name: "Невинномысск", coordinates: [41.89544, 44.663423] },
  { warehouse__name: "Красноярск", coordinates: [92.948125, 56.075519] },
  { warehouse__name: "Новосибирск", coordinates: [82.916459, 54.941901] },
  { warehouse__name: "Екатеринбург - Перспективный 12", coordinates: [60.577676, 56.72846] },
  { warehouse__name: "Екатеринбург - Испытателей 14г", coordinates: [60.81316, 56.767609] },
  { warehouse__name: "Шелепаново", coordinates: [37.18339, 56.113547] },
  { warehouse__name: "Сынково", coordinates: [37.614401, 55.34774] },
  { warehouse__name: "Белые Столбы", coordinates: [37.815893, 55.331275] },
  { warehouse__name: "Белгород", coordinates: [36.679399, 50.593676] },
  { warehouse__name: "Белая дача", coordinates: [37.888459, 55.65431] },
  { warehouse__name: "Электросталь", coordinates: [38.417711, 55.74639] },
  { warehouse__name: "Чехов 2", coordinates: [37.545312, 55.122406] },
  { warehouse__name: "Чехов 1", coordinates: [37.556262, 55.125659] },
  { warehouse__name: "Тула", coordinates: [37.465416, 54.436884] },
  { warehouse__name: "Рязань (Тюшевское)", coordinates: [39.578271, 54.681706] },
  { warehouse__name: "Пушкино", coordinates: [37.884228, 55.992382] },
  { warehouse__name: "Подольск 4", coordinates: [37.611832, 55.43485] },
  { warehouse__name: "Подольск 3", coordinates: [37.589796, 55.411823] },
  { warehouse__name: "Подольск", coordinates: [37.593003, 55.417219] },
  { warehouse__name: "Обухово", coordinates: [38.243276, 55.819929] },
  { warehouse__name: "Котовск", coordinates: [41.45434, 52.600086] },
  { warehouse__name: "Коледино", coordinates: [37.583832, 55.38617] },
  { warehouse__name: "Вёшки", coordinates: [37.562209, 55.918313] },
  { warehouse__name: "Череповец", coordinates: [37.935271, 59.158741] },
  { warehouse__name: "Краснодар (Тихорецкая)", coordinates: [39.068855, 45.054455] },
  { warehouse__name: "Иваново", coordinates: [40.941105, 56.951769] },
  { warehouse__name: "Крыловская", coordinates: [39.946724, 46.312237] },
  { warehouse__name: "Радумля 1", coordinates: [37.158021, 56.084586] },
  { warehouse__name: "Санкт-Петербург Шушары", coordinates: [30.453229, 59.797371] },
  { warehouse__name: "СЦ Кузнецк", coordinates: [46.583837, 53.101059] },
  { warehouse__name: "СЦ Хабаровск", coordinates: [135.12843, 48.449259] }
];

export const RADIUS_COEFFICIENT_MAX = 50;
export const RADIUS_COEFFICIENT_MIN = 10;
export const WIDTH_MAP = 1200;
export const HEIGHT_MAP = 450;
export const ROTATE_MAP = [265];
export const CENTER_MAP = [85, 60];
export const INIT_ZOOM_MAP = 2;
export const INIT_SCALE_MAP = 300;
export const STEP_ZOOM_MAP = 1.5;
export const MAX_ZOOM_MAP = 20;
export const MIN_ZOOM_MAP = 1;
export const MIN_RADUIS = 10;
export const MAX_RADUIS = 22;
export const BOARD_RADIUS_QUANTITY = 10;
