import React from "react";

import { Box } from "@mui/material";

import { betaPages, newPages } from "root/App/menu/miniBanners";

import styles from "./index.module.scss";

// ------------------------------------------------------
const TitleWithBanner = ({ section }) => {
  const { title, id } = section;
  const isBeta = betaPages.sections.includes(id);
  const isNew = newPages.sections.includes(id);
  const haveBetaItems = section.children.some(item => betaPages.pages.includes(item.url));
  const haveNewItems = section.children.some(item => newPages.pages.includes(item.url));
  return (
    <Box className={styles.menuPageTitleBox}>
      <span>{title}</span>
      {isNew ? (
        <NewMini />
      ) : isBeta ? (
        <BetaMini />
      ) : haveNewItems ? (
        <HaveNewSymbol />
      ) : (
        haveBetaItems && <HaveNewSymbol />
      )}
      {/* {isBeta ? <BetaMini /> : haveBetaItems && <HaveNewSymbol />}
      {isNew ? <NewMini /> : haveNewItems && <HaveNewSymbol />} */}
    </Box>
  );
};
//
const HaveNewSymbol = () => <Box className={styles.haveNewSymbol}></Box>;
const HaveBetaSymbol = () => <Box className={styles.haveBetaSymbol}></Box>;
//
const BetaBanner = () => <Box className={styles.betaBanner}>Beta</Box>;
const NewBanner = () => <Box className={styles.newBanner}>New</Box>;
//
const BetaMini = () => <Box className={styles.betaMini}>Beta</Box>;
const NewMini = () => <Box className={styles.newMini}>New</Box>;

export { TitleWithBanner, BetaMini, NewMini, BetaBanner, NewBanner };
