import React, { Fragment, useEffect, useMemo, useState } from "react";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Paper, Typography } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useSnackbar } from "notistack";
import { isEmpty, trim } from "ramda";
import { WBapi } from "root/services/WBapi";
import { postfixes } from "root/utils";

import API from "root/js/api";

import RightDrawer from "root/components/RightDrawer";

import Button from "../Button";
import Dialog from "../Dialog";
import Link from "../Link";
import PageTitle from "../PageTitle";
import TabPanel from "../TabPanel";
import Tooltip from "../Tooltip";
import VideoDialog from "../VideoDialog";

import StartDemoOptions from "./StartDemoOptions";

import styles from "./index.module.scss";

import SuccessArrow from "root/icons/SuccessArrow";
// variables
const redMark = "❌";
const successEmoji = "✅";
const demoLightAdvantages = [
  {
    text: "2 дня доступа",
  },
  {
    text: "1000 запросов в сутки",
  },
  {
    text: "70% функционала сервиса",
  },
  {
    text: "Анализ внешки",
    haveNo: true,
    // tooltip: {
    //   title: "Кроме:",
    //   rows: ["«Поиск публикаций»", "«Поиск блогеров»", "«Анализ блогеров»"],
    // },
  },
  {
    text: "Урезанные лимиты в разделах «SEO-тексты», «Группировка», «Мониторинг позиций в поиске» и др.",
    haveNo: true,
    tooltip: {
      title: "Лимиты:",
      rows: [
        "«SEO-тексты» – 15 шт. ",
        "«Мониторинг позиций в поиске» – 100 запросов",
        "«Группировка» – до 5 000 запросов за раз",
        "«Кластеризатор» – до 2 000 запросов за раз",
        "Отчеты в разделе «Поиск трафика» – недоступно",
        "«Автоотзывы» – 10 шт.",
      ],
    },
  },
];
const demoMaxAdvantages = [
  {
    text: "5 дней доступа",
  },
  {
    text: "1500 запросов в сутки",
  },
  // {
  //   text: "100% функционала сервиса",
  // },
  {
    text: "Анализ внешки",
    tooltip: {
      title: "Кроме:",
      rows: ["«Поиск публикаций»", "«Поиск блогеров»", "«Анализ блогеров»"],
    },
  },
  {
    text: "Расширенные лимиты в разделах «SEO-тексты», «Группировка», «Мониторинг позиций в поиске» и др.",
    tooltip: {
      title: "Лимиты:",
      rows: [
        "«SEO-тексты» – 500 шт. ",
        "«Группировка» – до 10 000 запросов за раз",
        "«Мониторинг позиций в поиске» – 100 запросов",
        "«Кластеризатор» – до 5 000 запросов  за раз",
        "Отчеты в разделе «Поиск трафика» – 20 шт. ",
        "«Автоотзывы» – 50 шт.",
      ],
    },
  },
];
const WBAPI = new API();
const tariffs = [
  {
    title: `Демо «Лайт»`,
    id: "demo_light",
    icon: "2 дня бесплатно",
  },
  {
    title: `Демо «Макс»`,
    id: "demo_max",
    icon: "5 дней за 4500 ₽.",
  },
];

// ---------------------------------------------------------------------------------
const PromoAccess = () => {
  const { enqueueSnackbar } = useSnackbar();
  //

  const [showTariffs, setShowTariffs] = useState(false);
  const [isDemoAvailable, setIsDemoAvailable] = useState(false);
  const [isTestMaxAvailable, setIsTestMaxAvailable] = useState(false);
  const canUseOnlyTestMax = useMemo(
    () => !isDemoAvailable && isTestMaxAvailable,
    [isDemoAvailable, isTestMaxAvailable]
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogInfo, setDialogInfo] = useState("");
  const [selected, setSelected] = useState(tariffs[0].id);
  const [apiToken, setApiToken] = useState("");
  const [isTokenLoading, setIsTokenLoading] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  //
  const getUnknownErrorSnackbar = () => {
    enqueueSnackbar({
      title: "Ошибка",
      message:
        "При отправке токена произошла неизвестная ошибка. Пожалуйста, попробуйте позднее или обратитесь в нашу поддержку в правом нижнем углу",
      type: "error",
    });
  };
  const [canRequestDemonstration, setCanRequestDemonstration] = useState(false);

  const checkIsDemoTariffsAvailable = async () => {
    try {
      const response = await WBAPI.demoAvailable();
      const { lite_available, max_available } = response;
      setShowTariffs(lite_available || max_available);
      setIsDemoAvailable(lite_available);
      setIsTestMaxAvailable(max_available);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitToken = async () => {
    try {
      const token = trim(apiToken);
      if (!validateToken(token)) {
        enqueueSnackbar({
          title: "Ошибка",
          message: "Введён некорректный токен",
          type: "error",
        });
        return null;
      }
      setIsTokenLoading(true);
      await WBAPI.getDemoAccessByToken({ token });
      // if (response.status === 201) {
      setModalOpen(false);
      setDialogInfo("success");
      checkIsDemoTariffsAvailable();
      window.reload();
      // } else getUnknownErrorSnackbar();
    } catch (error) {
      const errorCode = error.response?.data?.token[0]?.code;
      switch (errorCode) {
        case "invalid":
          return setDialogInfo("invalid_token");
        case "exist":
          return setDialogInfo("exist");
        case "already_used":
          return setDialogInfo("exist");
        case "invalid_type":
          return setDialogInfo("invalid_token_type");
        case "now_allowed":
          return setDialogInfo("have_payment_history");
        // default:
        //   getUnknownErrorSnackbar();
      }
    } finally {
      setIsTokenLoading(false);
    }
  };

  const onPay = async () => {
    try {
      setIsPayment(true);
      const response = await WBAPI.buyTariffv2({
        plan: 16,
        promocode: "XHSRYBADCOBRKSRBJPBPICDP",
      });
      window.location.href = response.data.url;
    } catch (error) {
      const errMessage = error?.response?.data?.promo_code?.promo_code[0]?.message;
      console.log(error?.response?.data?.promo_code?.promo_code[0]?.message);
      enqueueSnackbar({
        title: "Ошибка",
        message: (
          <Box>
            <Typography variant="subtitle1">Не удалось перейти на страницу оплаты</Typography>
            {errMessage && <Typography variant="subtitle1">{errMessage}</Typography>}
          </Box>
        ),
        type: "error",
      });
    }
    setIsPayment(false);
  };

  useEffect(() => {
    if (dialogInfo) return () => { };
    checkIsDemoTariffsAvailable();
  }, [dialogInfo]);

  const handleCloseSuccessModal = () => {
    setDialogInfo("");
    setApiToken("");
    window.location.reload();
  };

  const handleCloseExistModal = () => setDialogInfo("");

  const correctTariffs = useMemo(() => {
    if (!isDemoAvailable) setSelected(tariffs[1].id);
    else setSelected(tariffs[0].id);
    return tariffs.filter(item => {
      if (item.id === tariffs[0].id) return isDemoAvailable;
      if (item.id === tariffs[1].id) return isTestMaxAvailable;
      else return true;
    });
  }, [tariffs, isDemoAvailable, isTestMaxAvailable]);
  //
  if (!showTariffs) return null;
  return (
    <Fragment>
      <Box ml={9} mb={0} className={styles.root} onClick={() => setModalOpen(true)}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          gap={1}
          alignItems={"center"}>
          <Box textAlign={"center"} component={"span"} textTransform={"uppercase"}>
            {canUseOnlyTestMax
              ? "Попробовать максимум возможностей ->"
              : "СНАЧАЛА ХОТИТЕ ПОПРОБОВАТЬ?"}
          </Box>
          <Box component={"span"} className={styles.more}>
            ПОДРОБНОСТИ ТУТ
          </Box>
        </Box>
      </Box>
      <DemonstrationCallBox {...{ setDialogInfo, canRequestDemonstration, setCanRequestDemonstration }} />
      <RightDrawer
        classes={{ paper: styles.drawer }}
        zIndex={1000}
        isOpen={modalOpen}
        fullWidth
        onClose={() => setModalOpen(false)}>
        <Paper elevation={0}>
          <Box p={2} pb={13}>
            <Box mb={3} mt={1.5}>
              <PageTitle>Доступные варианты тестирования</PageTitle>
            </Box>

            <Box mb={3} className={styles.cards}>
              {correctTariffs.map((item, i) => (
                <Box
                  key={i}
                  className={styles.card}
                  onClick={() => setSelected(item.id)}
                  style={
                    selected === item.id
                      ? { border: "1px solid #cf3aa0" }
                      : { border: "1px solid #798097" }
                  }>
                  <Box className={styles.icon} style={{ color: "#0FB682" }}>
                    <b>{item.icon}</b>
                  </Box>
                  <Box
                    className={styles.title}
                    style={selected === item.id ? { color: "#cf3aa0" } : {}}>
                    {item.title}
                  </Box>
                  <div className={styles.item}>
                    <div className={styles.flexBox}>
                      <div className={styles.itemContent}>
                        <div className={styles.nameWrapper} data-active={item.id == selected}>
                          <div className={styles.radio} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              ))}
            </Box>

            <TabPanel value={selected} index={tariffs[0].id}>
              <Box>
                <Box mb={3}>
                  <TariffAdvantages advantagesList={demoLightAdvantages}>
                    <Box
                      flex={1}
                      display={"flex"}
                      flexDirection={"column"}
                      style={{
                        placeSelf: "flex-end",
                      }}>
                      <Box
                        minWidth={220}
                        maxWidth={300}
                        width={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        style={{
                          placeSelf: "flex-end",
                        }}>
                        <Box className={styles.asButtonBox}>БЕСПЛАТНО</Box>
                      </Box>
                    </Box>
                  </TariffAdvantages>
                </Box>
                <Box mb={2} display={"flex"} flexDirection={"column"} gap={1}>
                  <PageTitle>Как получить доступ:</PageTitle>
                </Box>
                <StartDemoOptions
                  fulfilledCallback={() => setDialogInfo("success_call")}
                  {...{ apiToken, setApiToken, onSubmitToken, isTokenLoading }}
                />
              </Box>
            </TabPanel>
            <TabPanel value={selected} index={tariffs[1].id}>
              <TariffAdvantages advantagesList={demoMaxAdvantages}>
                <Box
                  flex={1}
                  display={"flex"}
                  flexDirection={"column"}
                  style={{
                    placeSelf: "flex-end",
                  }}>
                  <Box
                    minWidth={220}
                    maxWidth={300}
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    style={{
                      placeSelf: "flex-end",
                    }}>
                    <Box display="flex" flexDirection="row-reverse" mb={2}>
                      <PageTitle>4500{postfixes.rub}</PageTitle>
                    </Box>
                    <Button variant="filled" onClick={onPay} isLoading={isPayment}>
                      Оплатить
                    </Button>
                  </Box>
                </Box>
              </TariffAdvantages>
            </TabPanel>
          </Box>
          {/* для высоты, чтобы не перекрывало нижние стики компоненты */}
        </Paper>
      </RightDrawer>
      {!!dialogInfo && (
        <Dialog open={!!dialogInfo} onClose={handleCloseExistModal}>
          <TabPanel value={dialogInfo} index={"success"}>
            <Box p={2} textAlign={"center"}>
              <Box>
                <PageTitle>✅ Токен успешно добавлен</PageTitle>
              </Box>
              <Box maxWidth={360} my={2}>
                <Typography variant="subtitle2">
                  Доступ ко всем возможностям сервиса будет активирован в течение пары минут.
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Button variant="filled" onClick={handleCloseSuccessModal}>
                  Продолжить
                </Button>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={dialogInfo} index={"exist"}>
            <Box p={2} textAlign={"center"}>
              <Box>
                <PageTitle>{redMark} Демо тариф уже выдавался</PageTitle>
              </Box>
              <Box maxWidth={450} my={2}>
                <Typography variant="subtitle2">
                  Токен вашего кабинета поставщика ранее уже получал демодоступ. Вы можете заказать
                  демонстрацию сервиса от менеджера или приобрести тариф на странице{" "}
                  <Link to="/tariff" underline="hover" onClick={handleCloseExistModal}>
                    «Оплата сервиса»
                  </Link>
                  .
                </Typography>
                <Typography variant="subtitle2">
                  Если у вас остались вопросы, можете задать их в нашем чате поддержки, в правом
                  нижнем углу сайта.
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Button variant="filled" onClick={handleCloseExistModal}>
                  Закрыть
                </Button>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={dialogInfo} index={"invalid_token"}>
            <Box p={2} textAlign={"center"}>
              <Box>
                <PageTitle>{redMark} Ошибка отправки токена</PageTitle>
              </Box>
              <Box maxWidth={450} display={"flex"} flexDirection={"column"} gap={2} my={2}>
                <Typography variant="subtitle2">
                  Пожалуйста, убедитесь, что вы отправляете токен:
                </Typography>
                <Typography variant="subtitle2">
                  <b>«Статистика» с доступом на чтение </b>
                </Typography>
                <Typography variant="subtitle2">
                  Мы записали для вас подробную{" "}
                  <VideoDialog
                    urlId={"5jwUkmYP8W8"}
                    title={"Инструкция по активации демодоступа Wildbox"}
                    showYouTubeIcon={false}
                    buttonTitle={"видеоинструкцию"}
                  />{" "}
                  как сгенерировать и загрузить нужный токен. Если у вас остались вопросы, напишите
                  нам в чат поддержки, в правом нижнем углу экрана.
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Button variant="filled" onClick={handleCloseExistModal}>
                  Закрыть
                </Button>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={dialogInfo} index={"invalid_token_type"}>
            <Box p={2} textAlign={"center"}>
              <Box>
                <PageTitle>{redMark} Введен некорректный токен</PageTitle>
              </Box>
              <Box maxWidth={450} my={2} display={"flex"} flexDirection={"column"} gap={2}>
                <Typography variant="subtitle2">
                  Пожалуйста, убедитесь, что вы отправляете токен:
                </Typography>
                <Typography variant="subtitle2">
                  <b>«Статистика» с доступом на чтение</b>
                </Typography>
                <Typography variant="subtitle2">
                  Мы записали для вас подробную{" "}
                  <VideoDialog
                    urlId={"5jwUkmYP8W8"}
                    title={"Инструкция по активации демодоступа Wildbox"}
                    showYouTubeIcon={false}
                    buttonTitle={"видеоинструкцию"}
                  />
                  , как сгенерировать и загрузить нужный токен. Если у вас остались вопросы,
                  напишите нам в чат поддержки, в правом нижнем углу экрана.
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Button variant="filled" onClick={handleCloseExistModal}>
                  Закрыть
                </Button>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={dialogInfo} index={"have_payment_history"}>
            <Box p={2} textAlign={"center"}>
              <Box>
                <PageTitle>{redMark} Что-то пошло не так</PageTitle>
              </Box>
              <Box maxWidth={450} my={2} display={"flex"} flexDirection={"column"} gap={2}>
                <Typography variant="subtitle2">
                  Судя по всему, вы уже пользовались сервисом ранее. Демо доступ доступен только
                  новым пользователям.
                </Typography>
                <Typography variant="subtitle2">
                  Если вы считаете, что это ошибка, отправьте номер телефона, привязанный к
                  аккаунту, и скриншот этого окна в чат поддержки, в правом нижнем углу экрана.
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Button variant="filled" onClick={handleCloseExistModal}>
                  Закрыть
                </Button>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={dialogInfo} index={"demonstration_call"}>
            <Box p={2} textAlign={"center"}>
              <DemonstrationDialog
                {...{ handleCloseExistModal, setDialogInfo, setCanRequestDemonstration }}
              />
            </Box>
          </TabPanel>
          <TabPanel value={dialogInfo} index={"success_call"}>
            <Box p={2} textAlign={"center"}>
              <Box>
                <PageTitle>{successEmoji} Заявка успешно отправлена</PageTitle>
              </Box>
              <Box maxWidth={360} my={2}>
                <Typography variant="subtitle2">
                  Менеджер свяжется с вами в течение 24 часов, с 10:00 до 19:00 по МСК
                </Typography>
              </Box>
              <Box maxWidth={360} my={2}>
                <Typography variant="subtitle2">
                  Если у вас остались вопросы, можете задать их в нашем чате поддержки, в правом
                  нижнем углу экрана.
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Button
                  variant="filled"
                  onClick={() => {
                    setDialogInfo("");
                  }}>
                  Продолжить
                </Button>
              </Box>
            </Box>
          </TabPanel>
        </Dialog>
      )}
    </Fragment>
  );
};

const validateToken = token => {
  const regExp = /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-+/=]*)/gm;
  return regExp.test(token);
};

// --------------------------------------------------------------------------------------------
const TariffAdvantages = ({ advantagesList, children, title = "На тарифе доступно:" }) => {
  return (
    <Box border={"1px solid #798097"} borderRadius={"4px"} p={2}>
      <Box mb={2}>
        <PageTitle>{title}</PageTitle>
      </Box>
      <Box display={"flex"} flexWrap={"wrap"} gap={2}>
        <Box flex={1} display={"grid"} gap={".5rem"}>
          {advantagesList.map(({ haveNo, text, isBold, tooltip = {} }) => (
            <Box key={text} className={styles.advantagesRow} color={isBold ? "#0FB682" : undefined}>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                {haveNo ? redMark : <SuccessArrow />}
              </Box>
              <Box>
                <Typography textAlign={"start"}>
                  {isBold ? <b>{text}</b> : text}
                  {!isEmpty(tooltip) && (
                    <Tooltip
                      title={
                        <Box pb={2}>
                          <Typography>
                            <b>{tooltip.title}</b>
                          </Typography>
                          {tooltip.rows.map(row => (
                            <Typography key={row}>{row}</Typography>
                          ))}
                        </Box>
                      }>
                      <Box display={"inline"} ml={1}>
                        <HelpOutlineOutlinedIcon color="disabled" sx={{ cursor: "pointer" }} />
                      </Box>
                    </Tooltip>
                  )}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {children}
      </Box>
    </Box>
  );
};

const DemonstrationCallBox = ({
  setDialogInfo,
  canRequestDemonstration,
  setCanRequestDemonstration,
}) => {
  const { data: phoneHistoryData = {} } = WBapi.useCheckCallbackOrdersQuery({ params: {} });
  useEffect(() => {
    if (!isEmpty(phoneHistoryData)) {
      setCanRequestDemonstration(
        phoneHistoryData.allow_request_demonstration && !phoneHistoryData.usage_count
      );
    }
  }, [phoneHistoryData]);
  return (
    <Box className={styles.orderDemonstrationBox}>
      {canRequestDemonstration && (
        <Typography onClick={e => e.stopPropagation()} className={styles.orderDemonstration}>
          ПОЛУЧИТЕ БЕСПЛАТНУЮ ДЕМОНСТРАЦИЮ СЕРВИСА ПО{" "}
          <Link underline="hover" onClick={() => setDialogInfo("demonstration_call")}>
            ССЫЛКЕ
          </Link>
          .
        </Typography>
      )}
    </Box>
  );
};
const DemonstrationDialog = ({ setDialogInfo, setCanRequestDemonstration }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [phoneNumber, setPhoneNumber] = useState("");
  const onChangePhoneNumber = newPhoneNum => {
    const numberWithoutSpaces = newPhoneNum.split(" ").join("");
    if (numberWithoutSpaces.length >= 13 && numberWithoutSpaces.includes("+79")) return null;
    if (numberWithoutSpaces.length >= 16) return null;
    setPhoneNumber(newPhoneNum);
  };
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const onOrderDemonstration = async () => {
    try {
      setIsOrderLoading(true);
      const response = await WBAPI.orderCallBack({
        phone: phoneNumber,
        is_demonstration: true,
      });
      if (response.run_id) {
        setDialogInfo("success_call");
        setCanRequestDemonstration(false);
      }
    } catch (error) {
      const errorsList = error?.response?.data?.non_field_errors;
      const errorCode = errorsList && errorsList.length ? errorsList[0]?.code : "";
      if (errorCode === "limit_reached")
        enqueueSnackbar({
          title: "Ошибка!",
          message: "Вы уже оставили максимальное количество заявок, ожидайте звонка менеджера",
          type: "error",
        });
      if (errorCode === "exist")
        enqueueSnackbar({
          title: "Ошибка!",
          message: "Вы уже оставили заявку на данный номер, ожидайте звонка менеджера",
          type: "error",
        });
    } finally {
      setIsOrderLoading(false);
    }
  };
  return (
    <>
      <Box>
        <PageTitle>Заказ демонстрации сервиса</PageTitle>
      </Box>
      <Box maxWidth={450} my={2} display={"flex"} flexDirection={"column"} gap={2}>
        <Typography variant="subtitle2">
          Пожалуйста, укажите контактный номер телефона. Наш менеджер свяжется с вами, чтобы
          подобрать удобное время для проведения демонстрации.
        </Typography>
        <Box>
          <MuiTelInput defaultCountry="RU" value={phoneNumber} onChange={onChangePhoneNumber} />
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box width={260} display={"grid"}>
            <Button
              disabled={isOrderLoading || !phoneNumber.trim()}
              variant="filled"
              onClick={onOrderDemonstration}>
              Заказать звонок
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PromoAccess;
