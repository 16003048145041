import React from "react";

import RateReviewIcon from "@mui/icons-material/RateReview";


// ------------------------------------------------------------------
const autofeedbacksSection = {
  type: "section",
  title: "Автоотзывы",
  icon: RateReviewIcon,
  // disabled: tariffId !== 16,
  component: React.lazy(() => import("root/pages/auto-feedbacks/App")),
  children: [
    {
      url: "/auto-feedbacks/templates",
      component: React.lazy(() => import("root/pages/auto-feedbacks/App")),
      title: "Шаблоны",
      exact: false,
    },
    {
      url: "/auto-feedbacks/feedbacks",
      component: React.lazy(() => import("root/pages/auto-feedbacks/App")),
      title: "Отзывы",
      exact: false,
    },
  ],
};

export default autofeedbacksSection;
