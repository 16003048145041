import request from "root/js/request";
const getStorageAndAcceptanceTable = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  category_id__in,
  brand_id__in,
  supplier_article__in,
  barcode__in,
  group_by,
  order_by,
  subject_id__in,
  product_id__in,
  limit,
  offset,
  q,
  product_id__icontains,
  supplier_article,
}) => {
  const response = await request({
    url: "/analytics/paid_storage_table/",

    method: "get",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      category_id__in,
      brand_id__in,
      supplier_article__in,
      barcode__in,
      group_by,
      order_by,
      subject_id__in,
      product_id__in,
      limit,
      offset,
      q,
      product_id__icontains,
      supplier_article,
    },
  });
  return response.data;
};

export { getStorageAndAcceptanceTable };
