import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: {
    itemsIds: [],
    checkedItemsIds: [],
    fetchParams: {},
    isFormedTableVisible: false,
  },
  brands: {
    itemsIds: [],
    checkedItemsIds: [],
    fetchParams: {},
    isFormedTableVisible: false,
  },
  sellers: {
    itemsIds: [],
    checkedItemsIds: [],
    fetchParams: {},
    isFormedTableVisible: false,
  },
  categories: {
    itemsIds: [],
    checkedItemsIds: [],
    fetchParams: {},
    isFormedTableVisible: true,
  },
};
//
const growthLeadersSlice = createSlice({
  name: "growthLeaders",
  initialState,
  reducers: {
    setGrowthLeadersFetchParams(state, { payload: { page, fetchParams } }) {
      state[page].fetchParams = fetchParams;
    },
    setGrowthLeadersFormedVisibility(state, { payload: { page, visible } }) {
      state[page].isFormedTableVisible = !!visible;
    },
    setGrowthLeadersItemsIds(state, { payload: { page, ids } }) {
      state[page].itemsIds = ids;
    },
    toggleGrowthLeadersCheckedItem(state, { payload: { page, id } }) {
      const checkedItemsIds = state[page].checkedItemsIds;
      if (checkedItemsIds.includes(id))
        state[page].checkedItemsIds = checkedItemsIds.filter(i => i !== id);
      else state[page].checkedItemsIds = [...checkedItemsIds, id];
    },
    resetGrowthLeadersCheckedItems(state, { payload: { page } }) {
      state[page].checkedItemsIds = [];
    },
    toggleGrowthLeadersCheckAll(state, { payload: { page, isAllChecked } }) {
      if (isAllChecked) {
        state[page].checkedItemsIds = [];
      } else {
        const newItems = state[page].itemsIds.filter(i => !state[page].checkedItemsIds.includes(i));
        state[page].checkedItemsIds = [...state[page].checkedItemsIds, ...newItems];
      }
    },
    resetGrowthLeadersState(state) {
      state = initialState;
    },
  },
});

export const {
  setGrowthLeadersFetchParams,
  setGrowthLeadersFormedVisibility,
  setGrowthLeadersItemsIds,
  toggleGrowthLeadersCheckAll,
  toggleGrowthLeadersCheckedItem,
  resetGrowthLeadersCheckedItems,
  resetGrowthLeadersState,
} = growthLeadersSlice.actions;
export default growthLeadersSlice.reducer;
