import { convertServerDataToStateFormat, templateSettingsValues } from "./initialData";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  templateCreator: {
    is_active: true,
    name: "Новый шаблон",
    delay_min: 60,
    only_new: false,
    only_old_period: 60,
    include_phrases: { isOn: false, list: [] },
    exclude_phrases: { isOn: false, list: [] },
    include_brand_ids: [],
    include_product_ids: [],
    include_subject_ids: [],
    invalid_articles: { brand: [], subject: [] },
    templateSettings: {
      ...templateSettingsValues,
    },
    ratings: [1, 2, 3, 4, 5],
    manual_answers: [{ id: 1, answer: "", priority: 4 }],
    recommendations: {
      isOn: true,
      list: [{ id: 1, recommendation: "", priority: 4 }],
    },
    spaceBetweenAnswerAndRecom: "\n",
    statistic: {},
  },
  answersPage: {
    checkedItems: [],
  },
};

const autoFeedbacksSlice = createSlice({
  name: "autoFeedbacks",
  initialState,
  reducers: {
    toggleIsActive(state) {
      state.templateCreator.is_active = !state.templateCreator.is_active;
    },
    changeTemplateName(state, { payload: newName }) {
      state.templateCreator.name = newName;
    },
    changeDelayMin(state, { payload: newDelay }) {
      state.templateCreator.delay_min = newDelay;
    },
    toggleOnlyNew(state) {
      state.templateCreator.only_new = !state.templateCreator.only_new;
    },
    changeOnlyOldPeriod(state, { payload: newPeriod }) {
      state.templateCreator.only_old_period = newPeriod;
    },
    changePhrasesIn(state, { payload: newList }) {
      state.templateCreator.include_phrases.list = newList;
    },
    changePhrasesOut(state, { payload: newList }) {
      state.templateCreator.exclude_phrases.list = newList;
    },
    togglePhrasesIn(state) {
      state.templateCreator.include_phrases.isOn = !state.templateCreator.include_phrases.isOn;
    },
    togglePhrasesOut(state) {
      state.templateCreator.exclude_phrases.isOn = !state.templateCreator.exclude_phrases.isOn;
    },
    changeProductIdsIn(state, { payload: newList }) {
      state.templateCreator.include_product_ids = newList;
    },
    changeBrandsIn(state, { payload: newList }) {
      state.templateCreator.include_brand_ids = newList;
    },
    changeSubjectsIn(state, { payload: newList }) {
      state.templateCreator.include_subject_ids = newList;
    },
    toggleTemplateSetting(state, { payload: settingName }) {
      state.templateCreator.templateSettings[settingName] =
        !state.templateCreator.templateSettings[settingName];
    },
    onCheckTemplateSettingRating(state, { payload: id }) {
      const ratings = state.templateCreator.ratings;
      if (ratings.includes(id)) {
        if (ratings.length > 1) state.templateCreator.ratings = ratings.filter(item => item !== id);
      } else {
        state.templateCreator.ratings = [...ratings, id];
      }
    },
    calculateAnswersPriority(state) {},
    changeManualAnswers(state, { payload: newManualAnswers }) {
      state.templateCreator.manual_answers = newManualAnswers;
    },
    toggleRecommendationIsOn(state) {
      state.templateCreator.recommendations.isOn = !state.templateCreator.recommendations.isOn;
    },
    changeRecommendationList(state, { payload: newList }) {
      state.templateCreator.recommendations.list = newList;
    },
    changeSpaceBetweenAnswerAndRecom(state, { payload: newSpace }) {
      state.templateCreator.spaceBetweenAnswerAndRecom = newSpace;
    },
    setInitialChangingTemplateData(state, { payload: data }) {
      const newTemplateCreatorData = convertServerDataToStateFormat(data);
      state.templateCreator = newTemplateCreatorData;
    },
    setNewTemplate(state) {
      state.templateCreator = { ...initialState.templateCreator };
    },
    setInvalidArticles: (state, action) => {
      state.templateCreator.invalid_articles = action.payload;
    },
    // ---------- answersPage
    toggleIsItemChecked(state, { payload: newItem }) {
      const checkedItems = state.answersPage.checkedItems;
      if (checkedItems.find(item => item.id === newItem.id)) {
        state.answersPage.checkedItems = state.answersPage.checkedItems.filter(
          item => item.id !== newItem.id
        );
      } else {
        state.answersPage.checkedItems.push(newItem);
      }
    },
    clearCheckedItems(state) {
      state.answersPage.checkedItems = [];
    },
  },
});

export default autoFeedbacksSlice.reducer;

export const {
  toggleIsActive,
  changeTemplateName,
  changeDelayMin,
  toggleOnlyNew,
  changeOnlyOldPeriod,
  changePhrasesIn,
  changePhrasesOut,
  togglePhrasesIn,
  togglePhrasesOut,
  changeProductIdsIn,
  setInvalidArticles,
  changeBrandsIn,
  changeSubjectsIn,
  toggleTemplateSetting,
  onCheckTemplateSettingRating,
  changeManualAnswers,
  toggleRecommendationIsOn,
  changeRecommendationList,
  changeSpaceBetweenAnswerAndRecom,
  setInitialChangingTemplateData,
  setNewTemplate,
  // ------------ answersPage
  toggleIsItemChecked,
  clearCheckedItems,
} = autoFeedbacksSlice.actions;
