import React, { PureComponent, useMemo } from 'react'

import { Checkbox } from '@mui/material'
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
import { equals, prop, sort } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Column, Columns } from "root/js/columns.js"
import { checkBrand } from 'root/store/monitoring/brands/actions';
import shortNumber from 'short-number';

import BarGraph from "root/components/BarGraph"
import TableImage from "root/components/TableImage"

import { addWeeks, isMobile, palette } from 'root/js/utils'

import styles from "./index.module.scss";

const ColumnsBuilder = new Columns()
const diff = (a, b) => a - b


const brandColumns = ({ setSelectedItems }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { brandsCheckBoxes, fullIds } = useSelector(store => store.monitoringBrands)

    const params = useMemo(() => {
        const result = {}
        const urlParams = new URLSearchParams(history.location.search)
        for (const pair of urlParams.entries()) {
            result[pair[0]] = Number(pair[1])
        }
        return result
    }, [history.location.search])

    const isSmUp = useMemo(() => window?.innerWidth > 600, [window?.innerWidth])

    const checkAll = () => {
        setSelectedItems(
            equals(sort(diff, fullIds), sort(diff, brandsCheckBoxes))
                ? [] : fullIds
        )
        dispatch(
            checkBrand(equals(sort(diff, fullIds),
                sort(diff, brandsCheckBoxes))
                ? [] : fullIds
            )
        )
    }

    const checkElem = (id) => {
        setSelectedItems(brandsCheckBoxes.includes(id)
            ? brandsCheckBoxes.filter(item => item !== id)
            : [...brandsCheckBoxes, id]
        )

        dispatch(checkBrand(brandsCheckBoxes.includes(id)
            ? brandsCheckBoxes.filter(item => item !== id)
            : [...brandsCheckBoxes, id]
        ))
    }

    const checkBoxData = {
        title: (
            <Checkbox
                size="small"
                color="primary"
                checked={
                    equals(sort(diff, fullIds), sort(diff, brandsCheckBoxes))
                    && !equals(brandsCheckBoxes, [])
                }
                onChange={checkAll}
            />
        ),
        dataIndex: "id",
        key: "id",
        postfix: "",
        sticky: !isMobile,
        render: id => (
            <Checkbox
                size="small"
                color="primary"
                checked={brandsCheckBoxes.includes(id)}
                onChange={() => checkElem(id)}
            />
        ),
    }

    const titleDataForBrand = new Column({
        dataIndex: "data",
        postfix: "",
        sticky: isSmUp,
        render: data => (
            <div className={styles.product}>
                {data.image && (
                    <TableImage
                        src={data.image}
                        className={styles.image}
                    />
                )}
                <Link
                    className={styles.text}
                    target='_black'
                    to={`/brand/${data.articule}`}
                >
                    {data.name}
                </Link>
            </div>
        ),
        title: "Бренд",
    })

    const columns = ColumnsBuilder.getColumnsByKeys([
        checkBoxData,
        titleDataForBrand,
        {
            dataIndex: "vendor_codes",
            sortable: false,
        },
        {
            dataIndex: "vendor_codes_dynamic",
            sortable: false,
            dynamicIndex: "vendor_codes",
            render: (value, column) => (
                <BarGraph
                    dataKey="y"
                    data={value}
                    tooltipPostfix={column.rawPostfix}
                />
            ),
        },
        {
            dataIndex: "price",
            sortable: true,
            default: true,
        },
        {
            dataIndex: "weighted_price",
            sortable: true,
        },
        {
            dataIndex: "price_dynamic",
            title: "Динамика средней цены без СПП",
            sortable: false,
            rowTitle: "Динамика средней цены без СПП",
            dynamicIndex: "price",
            render: (value, column) => (
                <BarGraph
                    dataKey="y"
                    data={value}
                    tooltipPostfix={column.rawPostfix}
                />
            ),
        },
        {
            dataIndex: "discount",
            sortable: true,
            default: true,
        },
        {
            dataIndex: "discount_dynamic",
            sortable: false,
            dynamicIndex: "discount",
            render: (value, column) => (
                <BarGraph
                    dataKey="y"
                    data={value}
                    tooltipPostfix={column.rawPostfix}
                />
            ),
        },
        {
            dataIndex: "product_with_orders",
            sortable: true,
            default: true,
        },
        {
            dataIndex: "product_with_orders_dynamic",
            sortable: false,
            default: true,
            dynamicIndex: "product_with_orders",
            render: (value, column) => (
                <BarGraph
                    dataKey="y"
                    data={value}
                    tooltipPostfix={column.rawPostfix}
                />
            ),
        },
        {
            dataIndex: "sellers_with_orders",
            sortable: true,
            default: true,
        },
        {
            dataIndex: "sellers_with_orders_dynamic",
            sortable: false,
            dynamicIndex: "sellers_with_orders",
            render: (value, column) => (
                <BarGraph
                    dataKey="y"
                    data={value}
                    tooltipPostfix={column.rawPostfix}
                />
            ),
        },
        {
            dataIndex: "proceeds",
            sortable: true,
            default: true,
        },
        {
            dataIndex: "proceeds_dynamic",
            sortable: false,
            dynamicIndex: "proceeds",
            render: (value, column) => (
                <BarGraph
                    dataKey="y"
                    data={value}
                    tooltipPostfix={column.rawPostfix}
                />
            ),
            default: true,
        },
        {
            dataIndex: "orders",
            sortable: true,
            default: true,
        },
        {
            dataIndex: "orders_dynamic",
            sortable: false,
            dynamicIndex: "orders",
            render: (value, column) => (
                <BarGraph
                    dataKey="y"
                    data={value}
                    tooltipPostfix={column.rawPostfix}
                />
            ),
        },
        {
            dataIndex: "lost_proceeds",
            sortable: true,
            default: true,
        },
        {
            dataIndex: "quantity",
            sortable: true,
        },
        {
            dataIndex: "quantity_dynamic",
            sortable: false,
            dynamicIndex: "quantity",
            render: (value, column) => (
                <BarGraph
                    dataKey="y"
                    data={value}
                    tooltipPostfix={column.rawPostfix}
                />
            ),
        },
        { dataIndex: "likes", sortable: true, },
    ])
    return { columns }
}
export const defaultExtrafilds = [
    "price",
    "discount",
    "proceeds",
    "proceeds_dynamic",
    "orders",
    "lost_proceeds",
]

export const sortColumnSettings = (a, b) => {
    if (a?._title && b?._title) {
        return a?._title?.localeCompare(b?._title)
    }
    if (a?._title && !b?._title) {
        return a?._title?.localeCompare(b?.title)
    }
    if (!a?._title && b?._title) {
        return a?.title?.localeCompare(b?._title)
    }
    return a?.title?.localeCompare(b?.title)
}

export const TABS = [
    {
        title: "Бренды",
        tabType: "brand"
    },
    {
        title: "Тренды",
        tabType: "trands",
    },
    {
        title: "Сезонность",
        tabType: "seasonality",
    }
]
export const colors = [
    {
        name: 'Выручка',
        id: 'proceeds',
        yAxisId: 'proceeds',
        color: palette.blue,
        orientation: 'left',
        afix: ' ₽',
        default_checked: true,
        default: true,
    },
    {
        name: 'Средняя цена',
        id: 'price',
        yAxisId: 'price',
        color: palette.gamboge,
        orientation: 'right',
        afix: ' ₽',
        default_checked: true,
        default: true,
    },
    {
        name: 'Заказы',
        id: 'orders',
        yAxisId: 'orders',
        color: palette.purple,
        orientation: 'left',
        afix: ' шт',
        default_checked: true,
        default: true,
    },
    {
        name: 'Товарные остатки',
        id: 'quantity',
        yAxisId: 'quantity',
        color: palette.lightGreen,
        orientation: 'right',
        afix: ' шт',
        default_checked: true,
        default: true,
    },
    {
        name: 'Артикулов',
        id: 'vendor_codes',
        yAxisId: 'vendor_codes',
        color: palette.brown,
        orientation: 'right',
        afix: ' шт',
    },
    // {
    //     name: 'Брендов с продажами',
    //     id: 'position_with_orders',
    //     yAxisId: 'position_with_orders',
    //     color: palette.brown,
    //     orientation: 'left',
    //     afix: ' шт'
    // },
    {
        name: 'Поставщиков',
        id: 'sellers_count',
        orientation: 'left',
        yAxisId: 'sellers_count',
        afix: ' шт',
        color: palette.magentaPink
    },
    {
        name: 'Поставщиков с продажами',
        id: 'sellers_with_orders',
        yAxisId: 'sellers_with_orders',
        color: palette.pink,
        orientation: 'right',
        afix: ' шт'
    },
];

export const diffDates = (day_one, day_two) => {
    return (day_one - day_two) / (60 * 60 * 24 * 1000)
}
export const dateSet = (value) => {
    const rawDate = new Date(value);
    return `${format(rawDate, 'dd.MM.yyyy', { locale: ru })} — ${addWeeks(rawDate, 1)}`;
}
export const combineObjectsBySimilarDates = (objects) => Object
    .values(objects
        .reduce((acc, obj) => {
            const key = obj.date;
            if (!acc[key]) {
                acc[key] = { date: key, ...obj }; // Копируем объект, чтобы не изменять исходные данные
            } else {
                for (const prop in obj) {
                    if (prop !== 'date') {
                        if (typeof obj[prop] === 'number') {
                            acc[key][prop] = (acc[key][prop] || 0) + obj[prop];
                        }
                    }
                }
            }
            return acc;
        }, {}))
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map(item => ({
        ...item,
        date: item?.date ? dateSet(item.date) : "—"
    }));
export class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, payload } = this.props;
        const dateArray = prop(0, payload?.value?.split(" —"));
        return (
            <g transform={`translate(${x - 7},${y - 5})`}>
                <text
                    x={0}
                    y={0}
                    dy={16}
                    textAnchor="end"
                    fill="#666"
                    transform="rotate(-45)"
                    style={{
                        fill: "#798097",
                        fontSize: "12px"
                    }}>
                    {dateArray}
                </text>
            </g>
        );
    }
}


const showAxisLine = ["proceeds", "orders"]

const dashedLines = ["sellers_with_orders", "product_with_orders", "sales", "diff_sales", "percent_sales"]
const trandColumns = ColumnsBuilder.getColumnsByKeys([
    {
        dataIndex: 'date',
        title: 'Период',
        render: value => {
            const weekStartDay = new Date(value)
            const weekEndDay = new Date(Date.parse(weekStartDay) + 6 * 24 * 3600 * 1000)
            return `${format(weekStartDay, "dd.MM.yyyy")} - ${format(weekEndDay, "dd.MM.yyyy")}`
        },
        sortable: true,
        default: true
    },
    {
        dataIndex: 'proceeds',
        dynamicIndex: 'proceeds_dynamic',
        sortable: true,
        default: true
    },
    {
        dataIndex: 'orders',
        dynamicIndex: 'orders_dynamic',
        sortable: true,
        default: true
    },
    {
        dataIndex: 'vendor_codes',
        sortable: true,
        default: true
    },
    {
        dataIndex: 'price',
        dynamicIndex: 'price_dynamic',
        sortable: true,
        default: true
    },
    // {
    //     dataIndex: 'position_with_orders',
    //     title: "Брендов с продажами",
    //     // dynamicIndex: 'position_with_orders',
    //     sortable: true,
    //     default: true
    // },
    {
        dataIndex: 'sellers_with_orders',
        dynamicIndex: 'sellers_with_orders_dynamic',
        sortable: true,
        default: true
    },
    {
        dataIndex: 'discount',
        dynamicIndex: 'discount_dynamic',
        sortable: true,
        render: value => `${Math.round(value / 10)}%`,
    }
]);

export const trandParams = {
    columns: trandColumns,
    showAxisLine,
    dashedLines,
    colors,
    combineObjectsBySimilarDates,
    CustomizedAxisTick
}

export default brandColumns
