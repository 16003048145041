import React, { useEffect, useState } from "react";

import { Button, ClickAwayListener, MenuList, OutlinedInput } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Grow from "@mui/material/Grow";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { GetAdvertsByDates } from "root/store/financialLogic/action";
import { promotionMediaFilter } from "root/store/financialLogic/financialLogicSlice";

import styles from "./index.module.scss";

const PromotionMediaFilterCompany = ({ noDataCondition }) => {
  const dispatch = useDispatch();
  const {
    promotionSelectedCompany,
    keys,
    dateFrom,
    dateTo,
    brandIds,
    articulIds,

    subjectsId,
  } = useSelector(state => state.financeLogic);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    setSelectOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectOpen(false);
  };

  const isLastYear = false;
  let dateFromLastYear,
    dateToLastYear = false;
  if (isLastYear) {
    dateToLastYear = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    );
    dateFromLastYear = new Date(
      dateToLastYear.getFullYear() - 1,
      dateToLastYear.getMonth(),
      dateToLastYear.getDate()
    );
  }

  const handleChange = ({ id, checked }) => {
    if (checked) {
      const newStatus = [...selectedCompanies, id];
      setSelectedCompanies(newStatus);
    } else {
      const newStatus = selectedCompanies.filter(elem => elem !== id);
      setSelectedCompanies(newStatus);
    }
  };

  const handleFilter = () => {
    setAnchorEl(null);
    setSelectOpen(false);
    dispatch(
      promotionMediaFilter({
        name: "company",
        value: selectedCompanies,
      })
    );
  };

  useEffect(() => {
    const brands = brandIds.map(({ id }) => id);
    const subjectss = subjectsId.map(({ id }) => id);
    const article = articulIds.map(({ supplier_article }) => supplier_article);

    if (keys.length > 0 && ((dateFrom && dateTo) || (dateFromLastYear && dateToLastYear))) {
      dispatch(
        GetAdvertsByDates({
          keys,
          dateFrom: dateFromLastYear ? dateFromLastYear : dateFrom,
          dateTo: dateToLastYear ? dateToLastYear : dateTo,
          subjectsId: subjectss,
          articleIds: article,
          brandIds: brands,
        })
      );
    }
  }, [keys, dateFrom, dateTo, dateFromLastYear, dateToLastYear]);

  const formatedStatuses = promotionSelectedCompany
    .filter(item => {
      if (selectedCompanies.includes(item.advert_id)) {
        return item;
      }
    })
    .map(i => i.name);

  return (
    <div className={styles.selectContainer}>
      <FormControl sx={{ m: 1, width: 300 }} size="small" className={styles.customHeight}>
        {/*<TooltipUI*/}
        {/*  title={*/}
        {/*    formatedStatuses.length && (*/}
        {/*      <div>*/}
        {/*        {formatedStatuses.map((elem, index) => {*/}
        {/*          return (*/}
        {/*            index < 10 ?*/}
        {/*              <div key={index}>*/}
        {/*                <span>{elem.length > 25 ? `${elem.slice(0, 25)}...` : elem}</span>*/}
        {/*              </div> : ""*/}
        {/*          );*/}
        {/*        })}*/}
        {/*        {formatedStatuses.length > 9 ?*/}
        {/*          <div key={10}>*/}
        {/*            <span>... и еще {formatedStatuses.length - 10}</span>*/}
        {/*          </div> : ""*/}
        {/*        }*/}
        {/*      </div>*/}
        {/*    )*/}
        {/*  }>*/}
        <InputLabel>Кампания</InputLabel>
        <Select
          className={styles.customHeight}
          multiple
          value={formatedStatuses}
          input={<OutlinedInput label="Кампания" />}
          renderValue={() => formatedStatuses.join(", ")}
          onClick={handleOpen}
          open={false}
          disabled={noDataCondition}
        />
        {/*</TooltipUI>*/}
      </FormControl>
      <Popper
        open={selectOpen}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 20 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
            <Paper className={styles.menuPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={selectOpen}>
                  {promotionSelectedCompany.map(elem => (
                    <MenuItem key={elem.advert_id} value={elem.name}>
                      <Checkbox
                        checked={selectedCompanies.includes(elem.advert_id)}
                        onChange={e =>
                          handleChange({ id: elem.advert_id, checked: e.target.checked })
                        }
                      />
                      <ListItemText primary={elem.name} />
                    </MenuItem>
                  ))}
                  <MenuItem className={styles.fixedApplyButton}>
                    {promotionSelectedCompany.length ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleFilter}
                        className={styles.btn}>
                        Применить
                      </Button>
                    ) : (
                      "Нет активных кампаний"
                    )}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default PromotionMediaFilterCompany;
