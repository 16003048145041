import { defaultToObj } from "root/js/utils";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  productTab: {
    data: [],
    checkedIds: [],
    isAllChecked: false
  },
  brandsTab: {
    data: [],
    checkedIds: [],
    isAllChecked: false
  },
  sellersTab: {
    data: [],
    checkedIds: [],
    isAllChecked: false
  },
  subcategoriesTab: {
    data: [],
    checkedIds: [],
    isAllChecked: false
  },
  growthLeadersTab: {
    tab: "categories",
    categories: {
      data: [],
      checkedIds: [],
      isAllChecked: false
    },
    products: {
      data: [],
      checkedIds: [],
      isAllChecked: false
    },
    trendingQueries: {
      data: [],
      checkedIds: [],
      isAllChecked: false
    },
    sellers: {
      data: [],
      checkedIds: [],
      isAllChecked: false
    },
    brands: {
      data: [],
      checkedIds: [],
      isAllChecked: false
    },
  }
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setItemsList(state, { payload: { tabName, newData = [] } }) {
      state[tabName].data = newData;
    },
    toggleCheckItem(state, { payload: {
      growthLeadersTabName = false,
      tabName,
      id
    } }) {
      if (growthLeadersTabName) {
        if (state.growthLeadersTab[growthLeadersTabName].checkedIds.find(item => item === id)) state.growthLeadersTab[growthLeadersTabName].checkedIds = state.growthLeadersTab[growthLeadersTabName].checkedIds.filter(item => item !== id);
        else state.growthLeadersTab[growthLeadersTabName].checkedIds = [...state.growthLeadersTab[growthLeadersTabName].checkedIds, id];

        const isAllChecked = state.growthLeadersTab[growthLeadersTabName].data
          .map(item => item.id)
          .every(itemId => state.growthLeadersTab[growthLeadersTabName].checkedIds.includes(itemId));
        state.growthLeadersTab[growthLeadersTabName].isAllChecked = isAllChecked;
      } else {

        if (state[tabName].checkedIds.find(item => item === id))
          state[tabName].checkedIds = state[tabName].checkedIds.filter(item => item !== id);
        else state[tabName].checkedIds = [...state[tabName].checkedIds, id];
        const isAllChecked = state[tabName].data
          .map(item => item.id)
          .every(itemId => state[tabName].checkedIds.includes(itemId));
        state[tabName].isAllChecked = isAllChecked;
      }
    },
    toggleCheckAll(state, { payload: {
      tabName,
      growthLeadersTabName = false,
    } }) {
      if (growthLeadersTabName) {
        if (state.growthLeadersTab[growthLeadersTabName].isAllChecked) state.growthLeadersTab[growthLeadersTabName].checkedIds = [];
        else
          state.growthLeadersTab[growthLeadersTabName].checkedIds = [
            ...state.growthLeadersTab[growthLeadersTabName].checkedIds,
            ...state.growthLeadersTab[growthLeadersTabName].data.map(item => item.id)
          ];
        state.growthLeadersTab[growthLeadersTabName].isAllChecked = !state.growthLeadersTab[growthLeadersTabName].isAllChecked;
      } else {
        if (state[tabName].isAllChecked) state[tabName].checkedIds = [];
        else
          state[tabName].checkedIds = [
            ...state[tabName].checkedIds,
            ...state[tabName].data.map(item => item.id)
          ];
        state[tabName].isAllChecked = !state[tabName].isAllChecked;
      }
    },
    checkIsAllChecked(state, { payload: { tabName, growthLeadersTabName = false } }) {
      if (growthLeadersTabName) {
        const isAllChecked = state.growthLeadersTab[growthLeadersTabName].data
          .map(item => item.id)
          .every(itemId => state.growthLeadersTab[growthLeadersTabName].checkedIds.includes(itemId));
        state.growthLeadersTab[growthLeadersTabName].isAllChecked = isAllChecked;
      } else {
        const isAllChecked = state[tabName].data
          .map(item => item.id)
          .every(itemId => state[tabName].checkedIds.includes(itemId));
        state[tabName].isAllChecked = isAllChecked;
      }
    },
    setData(state, { payload: { growthLeadersTabName, newData = [] } }) {
      state.growthLeadersTab[growthLeadersTabName] = {
        ...state.growthLeadersTab[growthLeadersTabName],
        data: newData
      };
    },
    setTab(state, { payload }) {
      state.growthLeadersTab.tab = payload
    },
  }
});

export default categorySlice.reducer;

export const { setTab, setItemsList, setData, toggleCheckItem, toggleCheckAll, checkIsAllChecked } =
  categorySlice.actions;
