import React from "react";

import NicheMenuIcon from "root/icons/NicheMenu";

// ----------------------------------------------------------------------------------
const seoSection = {
  type: "section",
  id: "seo",
  title: "SEO",
  icon: NicheMenuIcon,
  children: [
    {
      // title: "ChatGPT",
      title: "SEO-тексты",
      url: "/chat-gpt",
      component: React.lazy(() => import("root/pages/chatGPT/App")),
      exact: false,
      // tariffIds: [1, 2, 3, 5, 4, 14, 15, 16, 17],
    },
    {
      title: "Индексация запросов",
      url: "/keywords-position",
      component: React.lazy(() => import("root/pages/keywords-position/App")),
      // tariffIds: [4, 14, 16, 17],
      exact: false,
    },
    {
      url: "/position",
      component: React.lazy(() => import("root/pages/position/App")),
      title: "Позиции в поиске",
      exact: false,
    },
    {
      url: "/grouping",
      component: React.lazy(() => import("root/pages/groupingNew/App")),
      title: "Группировка запросов",
      exact: false,
    },
    {
      url: "/keystat",
      component: React.lazy(() => import("root/pages/keystat/App")),
      title: "Популярность запросов",
      exact: false,
    },
    {
      url: "/keys-finder",
      component: React.lazy(() => import("root/pages/keys-finder/App")),
      title: "Поисковые подсказки",
      exact: false,
      // tariffIds: [1, 5, 3, 4, 14, 15, 16, 17],
    },
    {
      title: "Запросы у списков",
      url: "/articles-keys",
      component: React.lazy(() => import("root/pages/articles-keys/App")),
      exact: false,
      // tariffIds: [1, 5, 3, 4, 14, 15, 16, 17],
    },
    {
      title: "Запросы у топов поиска",
      url: "/keys-top-find",
      component: React.lazy(() => import("root/pages/keys-top-find/App")),
      exact: false,
      // tariffIds: [1, 5, 4, 14, 15, 16, 17],
    },
    {
      title: "Пылесос запросов",
      url: "/sellers-keys",
      component: React.lazy(() => import("root/pages/sellers-keys/App")),
      exact: false,
      // tariffIds: [1, 5, 4, 14, 15, 16, 17],
    },
    {
      title: "Сравнение списков",
      url: "/keys-comparison",
      component: React.lazy(() => import("root/pages/keys-comparison/App")),
      exact: false,
      // tariffIds: [1, 5, 4, 14, 15, 16, 17],
    },
    {
      url: "/check-products-by-keys/product",
      component: React.lazy(() =>
        import("root/pages/check-products-by-keys/input/components/Product")
      ),
      title: "Вхождение запросов",
      // disabled: true,
      exact: false,
      // tariffIds: [1, 5, 3, 4, 14, 15, 16, 17],
    },
    {
      url: "/seo-ai",
      component: React.lazy(() => import("root/pages/seo-ai/App")),
      title: "SEO-AI",
      // disabled: true,
      exact: false,
    },
    // {
    // 	title: "Оптимизация рекламы",
    // 	url: "/advertising-optimization",
    // 	component: React.lazy(() =>
    // 		import("root/pages/advertising-optimization/App")
    // 	),
    // 	exact: false,
    // },

    //? {
    //? 	title: "Трекер позиций",
    //? 	disabled: true,
    //? },
    {
      title: "UTM ссылки",
      url: "/utm-links",
      component: React.lazy(() => import("root/pages/utm-generator/App")),
      exact: false,
      // tariffIds: [1, 5, 3, 4, 14, 15],
    },
  ],
};

export default seoSection;
