import request from "root/js/request";

export const getWarehousesSupplyWidgets = async ({
  wb_api_key_ids,
  category_id__in,
  brand_id__in,
  subject_id__in,
  barcode__in,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/forecasting/warehouse_supply_widgets/",
    method: "get",
    params: {
      wb_api_key_ids,
      category_id__in,
      brand_id__in,
      subject_id__in,
      barcode__in,
      ...rest,
    },
  });
  return response.data;
};
