import React, { useEffect, useMemo, useState } from "react";

import { Box, Typography } from "@mui/material";
import { isEmpty } from "ramda";

import Tab_ from "../Tab";
import Tabs_ from "../Tabs";

import styles from "./index.module.scss";

// -------------------------------------------------------------
const VideoPlayerWithSelect = ({ urls = {} }) => {
  const players = useMemo(() => Object.keys(urls) || [], [urls]);
  const [currentPlayer, setCurrentPlayers] = useState(players[0]);
  if (isEmpty(urls)) return null;
  return (
    <Box>
      <Tabs_ value={currentPlayer} onChange={(_, v) => setCurrentPlayers(v)}>
        {players.map(playerName => (
          <Tab_ key={playerName} value={playerName} label={<CustomLabel labelId={playerName} />} />
        ))}
      </Tabs_>
      <Box className={styles.containerVideo}>
        <iframe
          width="100%"
          height="360"
          src={urls[currentPlayer]}
          allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          className={styles.video}
          allowfullscreen
        />
      </Box>
    </Box>
  );
};

const CustomLabel = ({ labelId }) => {
  const title = useMemo(() => {
    switch (labelId) {
      case "youtube":
        return "YouTube";
      case "vimeo":
        return "Vimeo";
      case "vk":
        return "VK";
      default:
        return "Player";
    }
  }, [labelId]);
  return (
    <Box px={1}>
      <Typography>{title}</Typography>
    </Box>
  );
};

const getVideoUrlsById = (ids = {}) => {
  if (isEmpty(ids)) return {};
  const vkIds = ids.vk.split("_");
  return {
    vimeo: `https://player.vimeo.com/video/${ids.vimeo}`,
    youtube: `https://www.youtube.com/embed/${ids.youtube}?modestbranding=1&rel=0&showinfo=0&origin`,
    vk: `https://vk.com/video_ext.php?oid=-${vkIds[0]}&id=${vkIds[1]}&hd=2`,
  };
};

export { getVideoUrlsById };

export default VideoPlayerWithSelect;
