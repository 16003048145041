import React from 'react'

import styles from "./index.module.scss"
const CircleHint = (props) => {
  const { preName, circleColor, name, value, precent } = props
  return (
    <div className={styles.diagramDecription}>
      <div className={styles.legendsBox}>
        {preName &&
          <div className={styles.title}>
            {preName}
          </div>
        }
        <div>
          <div className={styles.legends}>
            <div style={{ background: circleColor }} className={styles.circle}></div>
            <div className={styles.title}>
              {name}:
            </div>
          </div>
          <div className={styles.value}>  {value}
            {precent && (precent)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CircleHint
