import React, { useState } from "react";

import { useSelector } from "react-redux";

import DiagramBarChards from "root/components/global/diagrama/diagram-bar-chards";
import DiagramHintBar from "root/components/global/diagrama/diagram-bar-hint";

import { numberWithSpaces } from "root/js/utils";

const ReturnDiagramaProduct = props => {
  const { tab, riterReturnDynamicProduct } = props;
  const { currencyName } = useSelector(state => state.financeLogic);

  const [tooltipData, setTooltipData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getTooltipContent = (product_name, supplier_article, product_thumbnail, price, count) => {
    const titleValuePrice = "Сумма покатушек";
    const titleValueCount = "Количество покатушек";
    return (
      <DiagramHintBar
        article={supplier_article}
        articleText="Артикул"
        productNameText="Наименование"
        product={product_name}
        titleValue={titleValuePrice}
        value={price}
        titleValueSecond={titleValueCount}
        valueSecond={count}
        img={product_thumbnail}
      />
    );
  };

  const CustomTooltip = ({ active, payload, data }) => {
    if (active && payload && payload.length) {
      const {
        payload: { product_name, supplier_article, ride, return_amount, product_thumbnail, product_id }
      } = payload[0];
      const price = `${numberWithSpaces(ride.toFixed(0))} ${currencyName}`;
      const count = `${return_amount} шт`;
      if (selectedProduct !== product_id) setSelectedProduct(product_id);
      const ind = riterReturnDynamicProduct?.findIndex(el => el.product_id === product_id);
      if (activeIndex !== ind) setActiveIndex(ind);

      return getTooltipContent(product_name, supplier_article, product_thumbnail, price, count);
    } else if (data) {
      const product = riterReturnDynamicProduct?.find(el => el.product_id === data.value);
      const { product_name, supplier_article, ride, return_amount, product_thumbnail } = product;
      const price = `${numberWithSpaces(ride.toFixed(0))} ${currencyName}`;
      const count = `${return_amount} шт`;
      return getTooltipContent(product_name, supplier_article, product_thumbnail, price, count);
    }

    return null;
  };
  const title = `Топ ${riterReturnDynamicProduct?.length} ${
    tab === "ride" ? "товаров по сумме покатушек " : "товаров по количеству покатушек "
  }`;

  return (
    <DiagramBarChards
      CustomTooltip={CustomTooltip}
      data={riterReturnDynamicProduct}
      title={title}
      name="product_id"
      value="value"
      tab={tab}
      compaireText={"ride"}
      width={450}
      height={riterReturnDynamicProduct?.length * 80}
      leftYAxisWidth={60}
      rightYAxisWidth={85}
      valuet={currencyName}
      secondValuet="шт"
      products={riterReturnDynamicProduct}
      setTooltipData={setTooltipData}
      tooltipData={tooltipData}
      currentProduct={selectedProduct}
      setCurrentProduct={setSelectedProduct}
      setExternalInd={setActiveIndex}
      externalInd={activeIndex}
      hasMinBarHeight
      hasProductLink
      color={"#CF3AA0"}
    />
  );
};

export default ReturnDiagramaProduct;
