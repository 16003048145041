import request from "root/js/request";

const getAdvertHistory = async ({
  cabinet_id,
  advert_id,
  date_from,
  date_to,
  limit = 1000,
  offset = 0,
}) => {
  const response = await request({
    url: "/api/analytics/advert_history/",
    method: "get",
    params: {
      cabinet_id,
      advert_id,
      date_from: date_from || undefined,
      date_to: date_to || undefined,
      limit,
      offset,
    },
  });
  return response.data;
};

export { getAdvertHistory };
