import React from "react";

import TableImage from "root/components/TableImage";

import styles from "./index.module.scss";

const HintBox = ({ name, value, img, size, color, productName, productNameTitle }) => {
  return (
    <div className={styles.legendsBox}>
      <div>
        <div className={styles.legends}>
          {name && (
            <div
              style={{
                color: color && color,
                fontSize: size && size,
              }}
              className={styles.title}>
              {name}:
            </div>
          )}
        </div>
        {value && (
          <div className={styles.value}>
            <span>{value}</span>
          </div>
        )}

        {productNameTitle && (
          <div className={styles.legends}>
            <div
              style={{
                color: color && color,
                fontSize: size && size,
              }}
              className={styles.title}>
              {productNameTitle}:
            </div>
          </div>
        )}
        {productName && <div className={styles.value}>{productName}</div>}

        {img && <TableImage src={img} alt={img} className={styles.img} />}
      </div>
    </div>
  );
};

export default HintBox;
