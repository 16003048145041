import React, { useCallback, useMemo, useState } from "react";

import { Box, Typography } from "@mui/material";
import { isEmpty } from "ramda";

import Dialog_ from "../Dialog";
import Tab_ from "../Tab";
import Tabs_ from "../Tabs";

import VideoPlayerWithSelect from "./VideoPlayerWithSelect";

import styles from "./index.module.scss";

import YouTube from "root/icons/YouTube";

// --------------------------------------------------------------------------------------------
const VideoDialog = ({
  videoIds = {},
  videoIdsList = [],
  urlId,
  videoUrl,
  title = "",
  buttonTitle = "Как пользоваться разделом?",
  showYouTubeIcon = true,
  onlyDrawer = false,
  isOnlyDrawerOpen = false,
  setIsOnlyDrawerOpen = () => {},
}) => {
  const [isDialogOpen, setDialogOpen] = useState(onlyDrawer && isOnlyDrawerOpen);
  const isOpen = useMemo(
    () => (onlyDrawer ? isOnlyDrawerOpen : isDialogOpen),
    [onlyDrawer, isOnlyDrawerOpen, isDialogOpen]
  );

  const haveMoreThenOneVideos = useMemo(() => !isEmpty(videoIdsList), [videoIdsList]);
  const [currentTabId, setCurrentTabId] = useState(videoIdsList[0]?.label || null);
  const currentVideoIds = useMemo(() => {
    if (haveMoreThenOneVideos)
      return videoIdsList.find(({ label }) => label === currentTabId).videoIds;
    else return videoIds;
  }, [currentTabId, videoIdsList, videoIds, haveMoreThenOneVideos]);

  const correctUrls = getVideoUrlsById(currentVideoIds);

  const onClose = useCallback(
    () => (onlyDrawer ? setIsOnlyDrawerOpen(false) : setDialogOpen(false)),
    [onlyDrawer]
  );
  return (
    <>
      {!onlyDrawer && (
        <Box color={"#cf3aa0"} className={styles.button} onClick={() => setDialogOpen(true)}>
          {showYouTubeIcon && <YouTube />}
          {buttonTitle}
        </Box>
      )}

      <Dialog_ onClose={onClose} open={isOpen}>
        <Box className={styles.modal}>
          <Box className={styles.modalHeader}>
            <Typography variant="h2">{title}</Typography>
          </Box>
          {haveMoreThenOneVideos && (
            <Tabs_ value={currentTabId} onChange={(_, v) => setCurrentTabId(v)}>
              {videoIdsList.map(({ label }) => (
                <Tab_ key={label} value={label} label={label} />
              ))}
            </Tabs_>
          )}
          <Box>
            {!isEmpty(correctUrls) ? (
              <VideoPlayerWithSelect urls={correctUrls} />
            ) : (
              <iframe
                title={urlId}
                width="100%"
                height="400"
                src={videoUrl ? videoUrl : `https://www.youtube.com/embed/${urlId}?modestbranding=1&rel=0&showinfo=0&origin`}
                allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            )}
          </Box>
        </Box>
      </Dialog_>
    </>
  );
};

const getVideoUrlsById = (ids = {}) => {
  if (isEmpty(ids)) return {};
  const vkIds = ids.vk.split("_");
  return {
    vimeo: `https://player.vimeo.com/video/${ids.vimeo}`,
    youtube: `https://www.youtube.com/embed/${ids.youtube}?modestbranding=1&rel=0&showinfo=0&origin`,
    vk: `https://vk.com/video_ext.php?oid=-${vkIds[0]}&id=${vkIds[1]}&hd=2`,
  };
};

export default VideoDialog;
