import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { isEmpty } from "ramda";

import API from "root/js/api";

import apiFunctions from "./apiFunctions";

const WBAPI = new API();

const WBAPIBaseQuery =
  () =>
  async ({ apiMethod, params }) => {
    try {
      const data = await WBAPI[apiMethod](params);
      return { data };
    } catch (error) {
      console.log({ error });
      return {
        error,
      };
    }
  };

export const WBapi = createApi({
  reducerPath: "WBAPI",
  baseQuery: WBAPIBaseQuery(),
  tagTypes: ["UserData"],
  endpoints: build => ({
    getUserData: build.query({
      query: ({ params }) => ({ apiMethod: apiFunctions.getUserData, params }),
      providesTags: result => ["UserData"],
    }),
    getUserDataById: build.query({
      query: ({ params }) => ({ apiMethod: apiFunctions.getUserDataById, params }),
    }),
    saveUserData: build.mutation({
      query: ({ params }) => ({ apiMethod: apiFunctions.saveUserData, params }),
      invalidatesTags: ["UserData"],
    }),
    patchUserData: build.mutation({
      query: ({ params }) => ({ apiMethod: apiFunctions.patchUserData, params }),
      invalidatesTags: ["UserData"],
    }),
    deleteUserData: build.mutation({
      query: ({ params }) => ({ apiMethod: apiFunctions.deleteUserData, params }),
      invalidatesTags: ["UserData"],
    }),
    getParsersSellers: build.query({
      query: ({ params }) => ({ apiMethod: apiFunctions.getParsersSellers, params }),
      keepUnusedDataFor: 600,
    }),
    getParsersBrands: build.query({
      // query: ({params}) => ({apiMethod: apiFunctions.getParsersBrands, params}),
      query: ({ params }) => {
        const obj = { ...params };
        if (!params?.date_from && !params?.date_to && !params?.period) {
          obj["period"] = 7;
        }
        return {
          apiMethod: apiFunctions.getBrandsDynamic,
          params: {
            ...obj,
          },
        };
      },
      keepUnusedDataFor: 600,
    }),
    getParsersCategories: build.query({
      query: ({ params }) => ({ apiMethod: apiFunctions.getParsersBrands, params }),
      // query: ({params}) => ({apiMethod: apiFunctions.getBrandsDynamic, params}),
      keepUnusedDataFor: 600,
    }),
    getPositionsKeywords: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductKeywordsByProductId,
        params,
      }),
    }),
    getPositionsMyKeywords: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductFavouritesDynamic,
        params,
      }),
      providesTags: result => ["PositionsMyKeywords"],
    }),
    deleteKeywordsToProduct: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.deleteKeywordsToProduct,
        params,
      }),
      invalidatesTags: ["PositionsMyKeywords"],
    }),
    deleteAllGeoKeywordsToProduct: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.deleteProductKeywordPositions,
        params,
      }),
      invalidatesTags: ["PositionsMyKeywords"],
    }),
    addKeywordsToProduct: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.addKeywordsToProduct,
        params,
      }),
      invalidatesTags: ["PositionsMyKeywords"],
    }),
    getGeo: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductKeywordGeolocationsByArticule,
        params,
      }),
      providesTags: result => ["PositionsMyKeywords"],
    }),

    // !
    getMyKeywordsHeader: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductFavouritesDynamicTop,
        params,
      }),
      providesTags: result => ["PositionsMyKeywords"],
    }),
    // !--
    getKeywordsHeader: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getTopKeywordsDynamic,
        params,
      }),
    }),

    // ! GEO LOCATION BY ARTICULE SIZES
    getSizesByArticuleLocations: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSizesBySizeLocations,
        params,
      }),
      invalidatesTags: () => ["GeoSizeLocations"],
    }),

    getAllGeolocations: build.query({
      providesTags: () => ["GeoLocations"],
      query: ({ params }) => ({
        apiMethod: apiFunctions.getGeolocations,
        params,
      }),
    }),
    getProductFrequencySubjects: build.query({
      providesTags: () => ["ProductFrequencySubjects"],
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductFrequencySubjectIds,
        params,
      }),
    }),

    searchProductFrequencySubjects: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductFrequencySubjectIds,
        params,
      }),
      invalidatesTags: () => ["ProductFrequencySubjects"],
    }),

    getProductFrequencyBrands: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductFrequencyBrands,
        params,
      }),
      invalidatesTags: () => ["ProductFrequencyBrands"],
    }),
    getProductBrands: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductFrequencyBrands,
        params,
      }),
      providesTags: () => ["getProductBrands"],
    }),

    getProductFrequencySellers: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductFrequencySellers,
        params,
      }),
      invalidatesTags: () => ["ProductFrequencySellers"],
    }),

    getProductSellers: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductFrequencySellers,
        params,
      }),
      providesTags: () => ["getProductFrequencySellers"],
    }),

    getParsersSubjects: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSubjectIds,
        params,
      }),
      invalidatesTags: () => ["getSubjectIds"],
    }),

    getSubjectIds: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSubjectIds,
        params,
      }),
      providesTags: () => ["getSubjectIds"],
    }),

    getDinamicKeywordsFrazes: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getDinamicKeywordsFrazes,
        params,
      }),
      providesTags: () => ["getDinamicKeywordsFrazes"],
    }),

    getSupplier: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSupplierArticleAdvertsUsageHistory,
        params,
      }),
      providesTags: () => ["getSupplier"],
    }),

    getAnalyticsPricesSubjects: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getAnalyticsPricesSubjects,
        params,
      }),
      providesTags: () => ["getAnalyticsPricesSubjects"],
    }),

    setSupplier: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSupplierArticleAdvertsUsageHistory,
        params,
      }),
      invalidatesTags: () => ["getSupplier"],
    }),
    getSupplierArticleAdvertsLimits: build.query({
      query: params => ({
        apiMethod: apiFunctions.getSupplierArticleAdvertsLimits,
        params,
      }),
      invalidatesTags: () => ["getSupplierArticleAdvertsLimits"],
    }),
    getProductsDynamic: build.query({
      query: ({ params }) => {
        return {
          apiMethod: apiFunctions.getProductsDynamic,
          params,
        };
      },
      providesTags: () => ["getProductsDynamic"],
    }),

    getHistoryDates: build.query({
      query: ({ params }) => {
        return {
          apiMethod: apiFunctions.getHistoryDates,
          params,
        };
      },
      providesTags: () => ["getHistoryDates"],
    }),

    getCompanyWB: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getCompanyWB,
        params,
      }),
      providesTags: () => ["getCompanyWB"],
    }),
    getMpCabinets: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getMpCabinets,
        params,
      }),
      providesTags: () => ["getMpCabinets"],
    }),
    getSppAnalyticsPrices: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSppAnalyticsPrices,
        params,
      }),
      providesTags: () => ["getSppAnalyticsPrices"],
    }),
    setSppAnalyticsPrices: build.mutation({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSppAnalyticsPrices,
        params,
      }),
      invalidatesTags: () => ["getSppAnalyticsPrices"],
    }),

    getBrands: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBrands,
        params,
      }),
      providesTags: () => ["getBrands"],
    }),
    getCategories: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getCategories,
        params,
      }),
      providesTags: () => ["getCategories"],
    }),

    getGroupsKeywordsCluster: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getGroupsKeywordsCluster,
        params,
      }),
      providesTags: () => ["getGroupsKeywordsCluster"],
    }),
    getAnalyticsShares: build.query({
      query: params => {
        if (!params?.cabinet_id) {
          return null;
        }
        return {
          apiMethod: apiFunctions.getAnalyticsShares,
          params,
        };
      },
      providesTags: () => ["getAnalyticsShares"],
    }),

    getKeywordsStatSubjects: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getKeywordsStatSubjects,
        params,
      }),
      providesTags: () => ["getKeywordsStatSubjects"],
      keepUnusedDataFor: 600,
    }),
    getKeywordsSummarySubjects: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getKeywordsSummarySubjects,
        params,
      }),
      providesTags: () => ["getKeywordsSummarySubjects"],
      keepUnusedDataFor: 600,
    }),
    getProductsDynamicAnalysis: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductsDynamicAnalysis,
        params,
      }),
      providesTags: () => ["getProductsDynamicAnalysis"],
    }),
    getGroupedProductDynamic: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getGroupedProductDynamic,
        params,
      }),
      providesTags: () => ["getGroupedProductDynamic"],
    }),
    getPriceSteps: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getPriceSteps,
        params,
      }),
      providesTags: () => ["getPriceSteps"],
    }),
    getProductsTrendGrouped: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductsTrendGrouped,
        params,
      }),
      providesTags: () => ["getProductsTrendGrouped"],
    }),
    getWareHouseLogistic: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getWareHouseLogistic,
        params,
      }),
      providesTags: () => ["getWareHouseLogistic"],
    }),
    getProductsTrend: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductsTrend,
        params,
      }),
      providesTags: () => ["getProductsTrend"],
    }),
    getGroupedBrandsTrend: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getGroupedBrandsTrend,
        params,
      }),
      providesTags: () => ["getGroupedBrandsTrend"],
    }),
    getBrandsTrend: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBrandsTrend,
        params,
      }),
      providesTags: () => ["getBrandsTrend"],
    }),
    getSellersDynamic: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSellersDynamic,
        params,
      }),
      providesTags: () => ["getSellersDynamic"],
    }),
    getGeneralCategoriesDynamic: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getGeneralCategoriesDynamic,
        params,
      }),
      providesTags: () => ["getGeneralCategoriesDynamic"],
    }),
    getParsersCollectionsProducts: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getParsersCollectionsProducts,
        params,
      }),
      providesTags: () => ["getParsersCollectionsProducts"],
    }),
    getExactParsersCollectionsKeywords: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getExactParsersCollectionsKeywords,
        params,
      }),
      providesTags: () => ["getExactParsersCollectionsKeywords"],
    }),
    getProductKeywordsStat: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getProductKeywordsStat,
        params,
      }),
      providesTags: () => ["getProductKeywordsStat"],
    }),
    getSellersTrend: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSellersTrend,
        params,
      }),
      providesTags: () => ["getSellersTrend"],
    }),
    getSellersTrendGrouped: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSellersTrendGrouped,
        params,
      }),
      providesTags: () => ["getSellersTrendGrouped"],
    }),
    getCategoriesTrend: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getCategoriesTrend,
        params,
      }),
      providesTags: () => ["getCategoriesTrend"],
    }),
    getGroupedCategoriesTrend: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getGroupedCategoriesTrend,
        params,
      }),
      providesTags: () => ["getGroupedCategoriesTrend"],
    }),
    getBrandsDynamic: build.query({
      query: params => ({
        apiMethod: apiFunctions.getBrandsDynamic,
        params,
      }),
      providesTags: () => ["getBrandsDynamic"],
    }),
    getKeywordStatByPeriod: build.query({
      query: params => ({
        apiMethod: apiFunctions.getKeywordStatByPeriod,
        params,
      }),
      providesTags: () => ["getKeywordStatByPeriod"],
    }),
    getKeywordsStatByIdDaily: build.query({
      query: params => ({
        apiMethod: apiFunctions.getKeywordsStatByIdDaily,
        params,
      }),
      providesTags: () => ["getKeywordsStatByIdDaily"],
    }),
    getMonitoringTraffics: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getMonitoringTraffics,
        params,
      }),
      providesTags: () => ["getMonitoringTraffics"],
    }),
    getMonitoringTrafficsPublicationDynamic: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getMonitoringTrafficsPublicationDynamic,
        params,
      }),
      providesTags: () => ["getMonitoringTrafficsPublicationDynamic"],
    }),
    getMonitoringTrafficsLimits: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getMonitoringTrafficsLimits,
        params,
      }),
      providesTags: () => ["getMonitoringTrafficsLimits"],
    }),
    getTrafficsTariffPlan: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getTrafficsTariffPlan,
        params,
      }),
      providesTags: () => ["getTrafficsTariffPlan"],
    }),
    getAutoreviewsTemplates: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getAutoreviewsTemplates,
        params,
      }),
      providesTags: () => ["getAutoreviewsTemplates"],
    }),
    getFeedbacksByCabinetId: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getFeedbacksByCabinetId,
        params,
      }),
      providesTags: () => ["getFeedbacksByCabinetId"],
    }),
    getFeedbacksProducts: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getFeedbacksProducts,
        params,
      }),
      providesTags: () => ["getFeedbacksProducts"],
    }),
    checkCallbackOrders: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.checkCallbackOrders,
        params,
      }),
      providesTags: () => ["checkCallbackOrders"],
    }),
    getAutoreviewsBrands: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getAutoreviewsBrands,
        params,
      }),
      providesTags: () => ["getAutoreviewsBrands"],
    }),
    getAutoreviewsSubjects: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getAutoreviewsSubjects,
        params,
      }),
      providesTags: () => ["getAutoreviewsSubjects"],
    }),
    getBanners: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBanners,
        params,
      }),
      providesTags: () => ["getBanners"],
    }),
    getBrandFinancialCategories: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBrandFinancialCategories,
        params,
      }),
      providesTags: () => ["getBrandFinancialCategories"],
    }),
    getSubjects: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getSubjects,
        params,
      }),
      providesTags: () => ["getSubjects"],
    }),
    getBrandFinancialLogic: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBrandFinancialLogic,
        params,
      }),
      providesTags: () => ["getBrandFinancialLogic"],
    }),
    getBarcodesSearch: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBarcodesSearch,
        params,
      }),
      providesTags: () => ["getBarcodesSearch"],
    }),
    getTop10Products: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getTop10Products,
        params,
      }),
      providesTags: () => ["getTop10Products"],
    }),
    getRealizationReportArticle: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getRealizationReportArticle,
        params,
      }),
      providesTags: () => ["getRealizationReportArticle"],
    }),
    // getBanners: build.query({
    //   query: ({ params }) => ({
    //     apiMethod: apiFunctions.getBanners,
    //     params
    //   }),
    //   providesTags: () => ["getBanners"]
    // }),
    // getBrandFinancialCategories: build.query({
    //   query: ({ params }) => ({
    //     apiMethod: apiFunctions.getBrandFinancialCategories,
    //     params
    //   }),
    //   providesTags: () => ["getBrandFinancialCategories"]
    // }),
    // getSubjects: build.query({
    //   query: ({ params }) => ({
    //     apiMethod: apiFunctions.getSubjects,
    //     params
    //   }),
    //   providesTags: () => ["getSubjects"]
    // }),
    // getBrandFinancialLogic: build.query({
    //   query: ({ params }) => ({
    //     apiMethod: apiFunctions.getBrandFinancialLogic,
    //     params
    //   }),
    //   providesTags: () => ["getBrandFinancialLogic"]
    // }),
    // getBarcodesSearch: build.query({
    //   query: ({ params }) => ({
    //     apiMethod: apiFunctions.getBarcodesSearch,
    //     params
    //   }),
    //   providesTags: () => ["getBarcodesSearch"]
    // }),
    // getTop10Products: build.query({
    //   query: ({ params }) => ({
    //     apiMethod: apiFunctions.getTop10Products,
    //     params
    //   }),
    //   providesTags: () => ["getTop10Products"]
    // }),
    // getRealizationReportArticle: build.query({
    //   query: ({ params }) => ({
    //     apiMethod: apiFunctions.getRealizationReportArticle,
    //     params
    //   }),
    //   providesTags: () => ["getRealizationReportArticle"]
    // }),
    getBannersV2: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBannersV2,
        params,
      }),
      providesTags: () => ["getBannersV2"],
    }),
    getProductCategoryGeoPVZ: build.query({
      query: params => ({
        apiMethod: apiFunctions.getProductCategoryGeoPVZ,
        params,
      }),
      providesTags: () => ["getProductCategoryGeoPVZ"],
    }),
    getProductPositionDynamic: build.query({
      query: params => ({
        apiMethod: apiFunctions.getProductPositionDynamic,
        params,
      }),
      providesTags: () => ["getProductPositionDynamic"],
    }),
    getParsersCategoriesExact: build.query({
      query: params => ({
        apiMethod: apiFunctions.getParsersCategoriesExact,
        params,
      }),
      providesTags: () => ["getParsersCategoriesExact"],
    }),
    getCategoryBrands: build.query({
      query: params => ({
        apiMethod: apiFunctions.getCategoryBrands,
        params,
      }),
      providesTags: () => ["getCategoryBrands"],
    }),
    getCategorySellers: build.query({
      query: params => ({
        apiMethod: apiFunctions.getCategorySellers,
        params,
      }),
      providesTags: () => ["getCategorySellers"],
    }),
    getProductsSummary: build.query({
      query: params => {
        return {
          apiMethod: apiFunctions.getProductsSummary,
          params,
        };
      },
      providesTags: () => ["getProductsSummary"],
    }),
    getProductsSummaryCount: build.query({
      query: params => {
        if (isEmpty(params)) {
          return { count: 0 };
        }
        return {
          apiMethod: apiFunctions.getProductsSummaryCount,
          params,
        };
      },
      providesTags: () => ["getProductsSummaryCount"],
    }),
    getBrandsSummaryCount: build.query({
      query: params => {
        if (isEmpty(params)) {
          return { count: 0 };
        }
        return {
          apiMethod: apiFunctions.getBrandsSummaryCount,
          params,
        };
      },
      providesTags: () => ["getBrandsSummaryCount"],
    }),
    getSellersSummaryCount: build.query({
      query: params => {
        if (isEmpty(params)) {
          return { count: 0 };
        }
        return {
          apiMethod: apiFunctions.getSellersSummaryCount,
          params,
        };
      },
      providesTags: () => ["getSellersSummaryCount"],
    }),
    getCategoriesSummaryCount: build.query({
      query: params => {
        if (isEmpty(params)) {
          return { count: 0 };
        }
        return {
          apiMethod: apiFunctions.getCategoriesSummaryCount,
          params,
        };
      },
      providesTags: () => ["getCategoriesSummaryCount"],
    }),
    getBrandsSummary: build.query({
      query: params => {
        return {
          apiMethod: apiFunctions.getBrandsSummary,
          params,
        };
      },
      providesTags: () => ["getBrandsSummary"],
    }),
    getSellersSummary: build.query({
      query: params => {
        return {
          apiMethod: apiFunctions.getSellersSummary,
          params,
        };
      },
      providesTags: () => ["getSellersSummary"],
    }),
    getCategoriesSummary: build.query({
      query: params => {
        if (isEmpty(params)) return;
        return {
          apiMethod: apiFunctions.getCategoriesSummary,
          params,
        };
      },
      providesTags: () => ["getCategoriesSummary"],
    }),
    getProductsFrequency: build.query({
      query: params => {
        if (isEmpty(params)) return;
        return {
          apiMethod: apiFunctions.getProductsFrequency,
          params,
        };
      },
      providesTags: () => ["getProductsFrequency"],
    }),
    getSocialNetworksData: build.query({
      query: params => ({
        apiMethod: apiFunctions.getSocialNetworksData,
        params,
      }),
      providesTags: () => ["getSocialNetworksData"],
    }),
    getMpCabinetsoAuthRefresh: build.query({
      query: params => {
        if (!params?.id) {
          return null;
        }
        return {
          apiMethod: apiFunctions.getMpCabinetsoAuthRefresh,
          params,
        };
      },
      providesTags: () => ["getMpCabinetsoAuthRefresh"],
    }),
    getoAuth20ByCabinetId: build.query({
      query: params => {
        if (!params?.id) {
          return null;
        }
        return {
          apiMethod: apiFunctions.getoAuth20ByCabinetId,
          params,
        };
      },
      providesTags: () => ["getoAuth20ByCabinetId"],
    }),
    // ------- ИНДЕКСИРОВАНИЕ ЗАПРОСОВ
    getKeywordsPosition: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getKeywordsPosition,
        params,
      }),
      providesTags: () => ["getKeywordsPosition"],
    }),
    // ------- КАРТОЧКА БЛОГЕРА ОСНОВНЫЕ ПОКАЗАТЕЛИ
    getBloggerGeneralStat: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBloggerGeneralStat,
        params,
      }),
      providesTags: () => ["getBloggerGeneralStat"],
    }),
    // ------- КАРТОЧКА БЛОГЕРА
    getBloggerCard: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBloggerCard,
        params,
      }),
      providesTags: () => ["getBloggerCard"],
    }),
    // ------- ОТБОРЩИК ПОСТОВ
    getBloggersPostsSelection: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBloggersPostsSelection,
        params,
      }),
      providesTags: () => ["getBloggersPostsSelection"],
    }),
    getBloggersPostsSelectionCount: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBloggersPostsSelectionCount,
        params,
      }),
      providesTags: () => ["getBloggersPostsSelectionCount"],
    }),
    getBloggersPostsSelectionBloggers: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBloggersPostsSelectionBloggers,
        params,
      }),
      providesTags: () => ["getBloggersPostsSelectionBloggers"],
    }),
    getBloggersPostsSelectionBrands: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBloggersPostsSelectionBrands,
        params,
      }),
      providesTags: () => ["getBloggersPostsSelectionBrands"],
    }),
    getBloggersPostsSelectionSubjects: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBloggersPostsSelectionSubjects,
        params,
      }),
      providesTags: () => ["getBloggersPostsSelectionSubjects"],
    }),
    getBloggersPostsSelectionProducts: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getBloggersPostsSelectionProducts,
        params,
      }),
      providesTags: () => ["getBloggersPostsSelectionProducts"],
    }),
    getKeywordSummary: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getKeywordSummary,
        params,
      }),
      providesTags: () => ["getKeywordSummary"],
    }),
    getKeywordSummary2: build.query({
      query: ({ params }) => ({
        apiMethod: apiFunctions.getKeywordSummary2,
        params,
      }),
      providesTags: () => ["getKeywordSummary2"],
    }),
    // ----------------
  }),
});
