import React, { useEffect, useRef, useState } from "react"

import classnames from "classnames"

import nophoto from "./nophoto.svg"

import styles from "./index.module.scss"

const TableImage = props => {
	const { className, classes = {}, src, ...rest } = props
	const [isLoaded, setLoaded] = useState(false)
	const [imageSrc, setImageSrc] = useState(src)
	const imgEl = useRef(null)

	const onLoad = () => {
		setLoaded(true)
	}
	const onError = () => {
		setImageSrc(nophoto)
	}

	useEffect(() => {
		const img = imgEl.current
		img.addEventListener("load", onLoad)
		img.addEventListener("error", onError)
		return () => {
			img.removeEventListener("load", onLoad)
			img.removeEventListener("error", onError)
		}
	}, [src])

	useEffect(() => {
		setImageSrc(src || nophoto)
	}, [src])

	return (
		<img
			alt=""
			loading="lazy"
			src={imageSrc}
			ref={imgEl}
			className={classnames(styles.root, className, classes.root)}
			data-loaded={isLoaded}

			{...rest}
		/>
	)
}

export default TableImage
