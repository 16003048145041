import React from "react";

import Star from "root/icons/Star";
// import СompetitorsMenuIcon from "root/icons/СompetitorsMenu";

// -------------------------------------------
const growthLeadersSection = {
  id: "growthLeaders",
  type: "section",
  title: "Лидеры роста",
  icon: Star,
  children: [
    // {
    //   url: "/growth-leaders-products",
    //   component: React.lazy(() => import("root/pages/growth-leaders/App")),
    //   title: "По товарам",
    //   exact: false,
    // },
    {
      url: "/growth_leaders/products",
      component: React.lazy(() => import("root/pages/growth_leaders/App")),
      title: "По товарам",
      exact: true,
    },
    // {
    //   url: "/growth-leaders-brands",
    //   component: React.lazy(() => import("root/pages/growth-leaders/App")),
    //   title: "По брендам",
    //   exact: false,
    // },
    // {
    //   url: "/growth-leaders-sellers",
    //   component: React.lazy(() => import("root/pages/growth-leaders/App")),
    //   title: "По поставщикам",
    //   exact: false,
    // },
    // {
    //   url: "/growth-leaders-categories",
    //   component: React.lazy(() => import("root/pages/growth-leaders/App")),
    //   title: "По категориям",
    //   exact: false,
    // },

    {
      url: "/growth_leaders/brands",
      component: React.lazy(() => import("root/pages/growth_leaders/App")),
      title: "По брендам",
      exact: true,
    },
    {
      url: "/growth_leaders/sellers",
      component: React.lazy(() => import("root/pages/growth_leaders/App")),
      title: "По поставщикам",
      exact: true,
    },
    {
      url: "/growth_leaders/categories",
      component: React.lazy(() => import("root/pages/growth_leaders/App")),
      title: "По категориям",
      exact: true,
    },
  ],
};

export default growthLeadersSection;
