import request from "root/js/request";

export const getAnalyticsAdverts = async ({
  wb_api_key_ids,
  advert_id,
  limit,
  offset,
  status,
  ordering,
  type,
  cabinet_id,
  search,
  extra_fields,
  autotopup,
}) => {
  const response = await request({
    url: `/analytics/adverts/`,
    method: "GET",
    params: {
      cabinet_id,
      wb_api_key_ids,
      advert_id,
      limit,
      offset,
      status__in: status,
      ordering,
      type__in: type,
      search,
      extra_fields,
      autotopup,
    },
    data: {},
  });
  return response.data;
};
