import React, { useState } from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Cell } from "recharts";

import DiagramBarChards from "../../../../../../../../components/global/diagrama/diagram-bar-chards";
import DiagramHintBar from "../../../../../../../../components/global/diagrama/diagram-bar-hint";
import { NoDataMassage } from "../../../../../../../../components/NoDataMessage";
import { AdvertTop } from "../../../../../../../../store/financialLogic/action";

import { numberWithSpaces } from "root/js/utils";
const promotionTopProductsOutsiders = ({ parentTab }) => {
  const {
    keys,
    dateFrom,
    dateTo,
    status,
    type,
    advertTopProducts,
    categoryId,
    brandIds,
    subjectsId,
  } = useSelector(state => state.financeLogic);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    try {
      if (keys.length > 0) {
        dispatch(
          AdvertTop({
            keys,
            dateFrom,
            dateTo,
            group_by: parentTab ? "advert_id" : "product_id",
            reverse: 1,
            brand: brandIds,
            category: categoryId,
            subject: subjectsId,
          })
        )
          .unwrap()
          .then();
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [dateFrom, keys, categoryId.join(""), brandIds.join(""), subjectsId.join("")]);

  const getTooltipContent = (product_name, product_thumbnail, supplier_article, result) => {
    return (
      <DiagramHintBar
        article={product_name}
        articleText="Наименование"
        titleValue={"ДРР"}
        value={result}
        img={product_thumbnail}
      />
    );
  };

  const getTooltipContentCompany = (advert_name, result) => {
    return (
      <DiagramHintBar
        articleText="Кампания"
        article={advert_name}
        titleValue={"ДРР"}
        value={result}
      />
    );
  };

  const CustomTooltip = ({ active, payload, data }) => {
    if (active && payload && payload.length) {
      if (parentTab) {
        const {
          payload: { advert_name, crr, advert_id },
        } = payload[0];

        const result = crr ? `${numberWithSpaces((crr * 100).toFixed(1))}%` : "Нет данных";
        if (selectedProduct !== advert_id) setSelectedProduct(advert_id);
        const ind = advertTopProducts.findIndex(el => el.advert_id === advert_id);
        if (activeIndex !== ind) setActiveIndex(ind);

        return getTooltipContentCompany(advert_name, result);
      } else {
        const {
          payload: { product_name, crr, product_thumbnail, supplier_article, product_id },
        } = payload[0];

        const result = crr ? `${numberWithSpaces((crr * 100).toFixed(1))}%` : "Нет данных";
        if (selectedProduct !== product_id) setSelectedProduct(product_id);
        const ind = advertTopProducts.findIndex(el => el.product_id === product_id);
        if (activeIndex !== ind) setActiveIndex(ind);

        return getTooltipContent(product_name, product_thumbnail, supplier_article, result);
      }
    } else if (data) {
      if (parentTab) {
        const product = advertTopProducts.find(el => el.advert_id === data.value);
        const { advert_name, crr } = product;
        const result = crr ? `${numberWithSpaces((crr * 100).toFixed(1))}%` : "Нет данных";
        return getTooltipContentCompany(advert_name, result);
      } else {
        const product = advertTopProducts.find(el => el.product_id === data.value);
        if (product) {
          const { product_name, crr, product_thumbnail, supplier_article } = product;
          const result = crr ? `${numberWithSpaces((crr * 100).toFixed(1))}%` : "Нет данных";
          return getTooltipContent(product_name, product_thumbnail, supplier_article, result);
        }
      }
    }

    return null;
  };

  const handleCellMouseEnter = index => {
    setActiveIndex(index);
  };

  const handleCellMouseLeave = () => {
    setActiveIndex(null);
  };

  const title = `Топ ${advertTopProducts.length || 0} ${
    parentTab === 0 ? "товаров с наибольшим ДРР" : "кампаний с наибольшим ДРР"
    // parentTab === 0 ? "товаров с наименьшим ДРР" : "кампаний с наименьшим ДРР"
  }`;

  return (
    <>
      {advertTopProducts.length > 0 ? (
        <DiagramBarChards
          CustomTooltip={CustomTooltip}
          data={advertTopProducts}
          title={title}
          name={parentTab ? "advert_id" : "product_id"}
          value="crr"
          tab={tab}
          compaireText={0}
          width={483}
          height={advertTopProducts?.length * 80}
          promotionHeight={true}
          leftYAxisWidth={60}
          rightYAxisWidth={80}
          valuet={"%"}
          secondValuet={"%"}
          currentProduct={selectedProduct}
          setCurrentProduct={setSelectedProduct}
          setExternalInd={setActiveIndex}
          products={advertTopProducts}
          setTooltipData={setTooltipData}
          tooltipData={tooltipData}
          hasMinBarHeight
          hasProductLink={parentTab === 0}
          dataColorCell={advertTopProducts?.map((elem, index) => {
            if (elem?.crr) {
              return (
                <Cell
                  key={index}
                  onMouseEnter={() => handleCellMouseEnter(index)}
                  onMouseLeave={handleCellMouseLeave}
                  fill={
                    index === activeIndex
                      ? "gray"
                      : tab === 0
                      ? elem.crr < 0
                        ? "#ffa500"
                        : "#00c85a"
                      : elem.crr > 0
                      ? "#ffa500"
                      : "#ff0000"
                  }
                />
              );
            } else {
              return (
                <Cell
                  key={index}
                  onMouseEnter={() => handleCellMouseEnter(index)}
                  onMouseLeave={handleCellMouseLeave}
                  fill="transparent"
                />
              );
            }
          })}
        />
      ) : (
        <NoDataMassage/>
      )}
    </>
  );
};

export default promotionTopProductsOutsiders;
