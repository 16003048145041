const notificationFields = [
  {
    name: "buyout_share",
    text: {
      title: "Процент выкупа",
      become: "стал",
      on: "на",
    },
    hasDirection: true,
    postFix: "%",
    defaultValue: 0.1,
    section: "products",
    isPercent: true,
  },
  {
    name: "proceeds",
    text: {
      title: "Выручка за",
      become: "стала",
      on: "на",
    },
    hasDirection: true,
    hasPeriod: true,
    postFix: "₽",
    defaultValue: 10,
    section: "products",
  },
  {
    name: "stocks",
    text: {
      title: "Остатков за",
      become: "стало",
      on: "на",
    },
    hasPeriod: true,
    hasDirection: true,
    postFix: "шт",
    defaultValue: 10,
    section: "products",
  },
  {
    name: "sale_avg_bill",
    text: {
      title: "Средний чек за",
      become: "стал",
      on: "на",
    },
    hasDirection: true,
    hasPeriod: true,
    postFix: "₽",
    defaultValue: 10,
    section: "products",
  },
  {
    name: "defect_share",
    text: {
      title: "Процент брака за",
      become: "стал",
      on: "на",
    },
    hasDirection: true,
    hasPeriod: true,
    postFix: "%",
    defaultValue: 0.4,
    section: "products",
    isPercent: true,
  },
  {
    name: "losses_share",
    text: {
      title: "Процент потерянных вещей за",
      become: "стал",
      on: "на",
    },
    hasDirection: true,
    hasPeriod: true,
    postFix: "%",
    defaultValue: 0.5,
    section: "products",
    isPercent: true,
  },
  {
    name: "ride_share",
    text: {
      title: "Доля покатушек за",
      become: "стала",
      on: "на",
    },
    hasDirection: true,
    hasPeriod: true,
    postFix: "%",
    defaultValue: 0.5,
    section: "products",
    isPercent: true,
  },
  {
    name: "overhead_share",
    text: {
      title: "Доля накладных расходов за",
      become: "стала",
      on: "на",
    },
    hasDirection: true,
    hasPeriod: true,
    postFix: "%",
    defaultValue: 0.5,
    section: "products",
    isPercent: true,
  },
  {
    name: "marginality",
    text: {
      title: "Рентабельность (маржинальность) за",
      become: "стала",
      on: "на",
    },
    hasDirection: true,
    hasPeriod: true,
    postFix: "%",
    defaultValue: 0.5,
    section: "products",
    isPercent: true,
  },
  {
    name: "no_orders",
    text: {
      title: "Товары без движения за",
      become: "стали",
      on: "на",
    },
    hasDirection: true,
    hasPeriod: true,
    postFix: "дней",
    defaultValue: 50,
    section: "products",
  },
  {
    name: "advert_orders",
    text: {
      title: "Заказы с рекламы за",
      become: "стали",
      on: "на",
    },
    hasPeriod: true,
    hasDirection: true,
    postFix: "шт",
    defaultValue: 100,
    section: "promotion",
  },
  {
    name: "advert_sum",
    text: {
      title: "Затраты в рублях за",
      become: "стали",
      on: "на",
    },
    hasPeriod: true,
    hasDirection: true,
    postFix: "₽",
    defaultValue: 100,
    section: "promotion",
  },
  {
    name: "romi",
    text: {
      title: "ROMI",
      become: "стал",
      on: "на",
    },
    hasDirection: true,
    postFix: "₽",
    defaultValue: 100,
    section: "promotion",
  },
  {
    name: "crr",
    text: {
      title: "Доля рекламных расходов",
      become: "стала",
      on: "на",
    },
    hasDirection: true,
    postFix: "%",
    defaultValue: 0.1,
    section: "promotion",
    isPercent: true,
  },
  {
    name: "cpm",
    text: {
      title: "CPM",
      become: "стал",
      on: "на",
    },
    hasDirection: true,
    postFix: "₽",
    defaultValue: 10,
    section: "promotion",
  },
  {
    name: "cpa",
    text: {
      title: "СPA (затраты/заказы за период) за",
      become: "стал",
      on: "на",
    },
    hasPeriod: true,
    hasDirection: true,
    postFix: "₽",
    defaultValue: 100,
    section: "promotion",
  },
  {
    name: "advert_gross_margin",
    text: {
      title: "Валовая маржа за",
      become: "стала",
      on: "на",
    },
    hasPeriod: true,
    hasDirection: true,
    postFix: "%",
    defaultValue: 0.1,
    section: "promotion",
    isPercent: true,
  },
  {
    name: "advert_sum_price",
    text: {
      title: "Выручка с продаж товаров путем рекламы за",
      become: "стала",
      on: "на",
    },
    hasPeriod: true,
    hasDirection: true,
    postFix: "₽",
    defaultValue: 100,
    section: "promotion",
  },
];

const formatValueIfPercent = (value, isPercent) => {
  if (value && isPercent && value > 1 && value <= 100) {
    return value / 100;
  }
  return value;
};

const prepareNotificationForServer = notification => {
  const fieldConfig = notificationFields.find(field => field.name === notification.field);
  if (!fieldConfig) return;
  return {
    ...notification,
    gte: formatValueIfPercent(notification.gte, fieldConfig?.isPercent),
    lte: formatValueIfPercent(notification.lte, fieldConfig?.isPercent),
  };
};

export { notificationFields, prepareNotificationForServer, formatValueIfPercent };
