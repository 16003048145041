import React from "react";

import MonitoringHomePageIcon from "root/icons/MonitoringHomePage";

// ---------------------------------------------------------------
const competitorAnalysisSections = {
  type: "section",
  title: "Анализ конкурентов",
  icon: MonitoringHomePageIcon,
  expanded: true,
  children: [
    {
      url: "/search/product",
      component: React.lazy(() => import("root/pages/search/components/Product")),
      title: "Анализ артикула",
      exact: false,
    },
    {
      url: "/search/brand",
      component: React.lazy(() => import("root/pages/search/components/Brand")),
      title: "Анализ бренда",
      // tariffIds: [1, 5, 3, 4, 14, 15, 16, 17],
      exact: false,
    },
    {
      url: "/search/seller",
      component: React.lazy(() => import("root/pages/search/components/Seller")),
      title: "Анализ поставщика",
      // tariffIds: [1, 5, 3, 4, 14, 15, 16, 17],
      exact: false,
    },
    {
      url: "/search/category",
      component: React.lazy(() => import("root/pages/search/components/Category")),
      title: "Анализ категорий",
      exact: false,
    },
    {
      url: "/analysis-by-subject",
      component: React.lazy(() => import("root/pages/analysis-by-subject/App")),
      title: "Анализ предметов",
      // tariffIds: [1, 3, 4, 5, 14, 15, 16, 17],
      exact: false,
    },
    {
      url: "/analysis-by-characteristics",
      component: React.lazy(() => import("root/pages/analysis-by-characteristics/App")),
      title: "Анализ характеристик",
      // tariffIds: [1, 4, 5, 14, 15, 16, 17],
      exact: false,
    },
    {
      url: "/search-tops-analysis",
      component: React.lazy(() => import("root/pages/search-tops-analysis/App")),
      title: "Анализ топов поиска",
      exact: false,
    },
    {
      title: "Сравнительный анализ",
      url: "/articles-comparison",
      component: React.lazy(() => import("root/pages/articles-comparison/App")),
      exact: false,
      // tariffIds: [1, 5, 4, 14, 15, 16, 17],
    },
    {
      url: "/geo-size-location",
      component: React.lazy(() => import("root/pages/geo-location/App")),
      title: "ГЕО Видимость",
      exact: false,
    },
    {
      url: "/brands-rating",
      component: React.lazy(() => import("root/pages/brands-rating/App")),
      title: "Рейтинг брендов",
      onlyInDev: true,
      onlyForAdmin: true,
      // onlyAdminInDev: true,
      exact: false,
    },
    {
      url: "/sellers-rating",
      component: React.lazy(() => import("root/pages/sellers_rating/App")),
      title: "Рейтинг поставщиков",
      onlyInDev: true,
      onlyForAdmin: true,
      // onlyAdminInDev: true,
      exact: false,
    },
    {
      url: "/external-traffic",
      component: React.lazy(() => import("root/pages/external-traffic/App")),
      title: "Частотность артикула",
      exact: false,
    },
  ],
};

export default competitorAnalysisSections;
