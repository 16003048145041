import React, { useEffect, useRef, useState } from "react";

import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import { Box, Button, Checkbox, ListItemText, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatePicker,
  getLastMounth,
  getMonthPeriod,
  mounthDate,
  prevLastWeek,
} from "root/store/financialLogic/financialLogicSlice";

import DatePickerRu from "root/components/DatePickerRu";
import {
  formatSelectedData,
  getDataPickerLabel,
  getMonthIndex,
  getOptionFromDate,
  getStringFromDate,
  sortMonthList,
} from "root/pages/financial-logic/helpers";

import useDateRange from "root/hooks/useDateRange";
import useMinMaxDates from "root/hooks/useMinMaxDates";

import styles from "./index.module.scss";

import "./styles.css";

const periodsList = [
  { title: "7 дней", value: 7 },
  { title: "14 дней", value: 14 },
  { title: "30 дней", value: 30 },
  { title: "90 дней", value: 90 },
  { title: "180 дней", value: 180 },
];

const DatePickerLastWeek = ({ setMounths, clearDate, mounths, isDisabledWeek, tab, pages }) => {
  const { dateFrom, apiKeys, keys, mounthsDate } = useSelector(state => state.financeLogic);

  const [prevWeek, setPrevWeek] = useState(true);
  const [isShowWeek, setIsShowWeek] = useState(true);
  const [isShowMounths, setIsShowMounths] = useState(false);
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  const { dateRange, setDateRange } = useDateRange();

  const [matchingKeys, setMatchingKeys] = useState(apiKeys.filter(item => keys.includes(item.id)));
  const { maxDate, minDate } = useMinMaxDates(matchingKeys);
  const [initialMounths, setInitialMounths] = useState(mounths);

  const handleOnOpen = () => {
    setInitialMounths(mounths);
    setIsOpenSelect(true);
  };

  useEffect(() => {
    setMatchingKeys(apiKeys.filter(item => keys.includes(item.id)));
  }, [keys]);

  const dispatch = useDispatch();

  const setAndFormatMonths = () => {
    const newMonths = formatSelectedData(
      [getOptionFromDate(dateRange[1]), getOptionFromDate(dateRange[0])],
      sortMonthList(mounthsDate)
    );
    setMounths(newMonths);
  };
  function getWeekRange(currentDate, startDayOfWeek = 1, endDayOfWeek = 0) {
    const ONE_DAY = 24 * 60 * 60 * 1000;
    const weekAgo = new Date(currentDate - 7 * ONE_DAY);

    // Вычисляем начало недели
    const start = nearestDay(weekAgo, startDayOfWeek, -1 * ONE_DAY);
    start.setHours(0, 0, 0, 0);

    // Вычисляем конец недели
    const end = nearestDay(weekAgo, endDayOfWeek, ONE_DAY);
    end.setHours(0, 0, 0, 0);

    return { start, end };
  }

  useEffect(() => {
    setPrevWeek(false);
    setIsShowWeek(false);
    setMounths([]);
    dispatch(getDatePicker(dateRange));
    dispatch(clearDate());
  }, [dateRange]);

  const setDateRangeHandler = newDateRange => {
    setDateRange(newDateRange);
  };

  function nearestDay(date, day, step) {
    while (date.getDay() != day) {
      date = new Date(date.getTime() + step);
    }

    return date;
  }

  const handlePrevWeek = () => {
    setPrevWeek(true);
    setMounths([]);

    setIsShowWeek(true);
    setIsShowMounths(false);

    dispatch(clearDate());

    const { start, end } = getWeekRange(new Date());

    dateRange[0] = start;
    dateRange[1] = end;

    setDateRange([dateRange[0], dateRange[1]]);
    dispatch(prevLastWeek({ start, end }));
  };

  const mounth = matchingKeys.map(elem => {
    return {
      minDate: elem.min_rr_date,
      maxDate: elem.max_rr_date,
    };
  });

  useEffect(() => {
    dispatch(mounthDate(mounth));
  }, [keys, mounth]);

  const handleChangeMounths = evt => {
    setPrevWeek(false);
    setIsShowWeek(false);
    setIsShowMounths(true);

    const filtredData = evt.target.value.filter(el => el !== undefined);

    if (filtredData?.length > 0) {
      const data = formatSelectedData(
        sortMonthList(filtredData),
        sortMonthList(mounthsDate)
      ).reverse();
      setMounths(data);
    } else {
      setMounths([]);
    }
  };

  function getLastMonthDates() {
    const currentDate = new Date();

    const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

    const lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    dateRange[0] = firstDayOfLastMonth;
    dateRange[1] = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    dispatch(
      getLastMounth({
        start: firstDayOfLastMonth,
        end: lastDayOfLastMonth,
      })
    );
  }

  const handleSelectMonth = () => {
    dateRange[0] = new Date(mounths[0].slice(-4), getMonthIndex(mounths[0].split(" ")[0]), 1);
    dateRange[1] = new Date(
      mounths[mounths.length - 1].slice(-4),
      getMonthIndex(mounths[mounths.length - 1].split(" ")[0]) + 1,
      0
    );

    setDateRange([dateRange[0], dateRange[1]]);
    dispatch(clearDate());
    dispatch(getMonthPeriod(mounths));
    setAndFormatMonths();
  };
  const handleResetMonth = () => {
    setMounths([]);
    dispatch(getMonthPeriod(mounths));
  };

  const handleOnClose = () => {
    setIsOpenSelect(false);
    if (JSON.stringify(mounths) === JSON.stringify(initialMounths)) {
      return;
    }

    if (mounths?.length > 0) {
      handleSelectMonth();
    } else {
      const { end } = getWeekRange(new Date());

      dateRange[1] = end;

      handleResetMonth();
      getLastMonthDates();
      setDateRange([dateRange[0], end]);
    }
  };

  useEffect(() => {
    if (!isOpenSelect && mounths.length > 0) {
      const { start, end } = getWeekRange(new Date());
      if (
        getStringFromDate(start) !== getStringFromDate(dateRange[0]) ||
        getStringFromDate(end) !== getStringFromDate(dateRange[1])
      ) {
        setAndFormatMonths();
      }
    }
  }, [isOpenSelect]);

  useEffect(() => {
    getLastMonthDates();
  }, []);

  return (
    <Paper elevation={0}>
      <Box p={2} marginTop={"24px"}>
        <div className={styles.selectFinancialContainer}>
          <div className={styles.datePicker}>
            <div className={styles.titleWrapper}>
              <Typography variant="h5" fontSize={"20px"}>
                {pages[tab].title}
              </Typography>
            </div>
            <div className={styles.coverBox}>
              <FormControl
                size="small"
                className={classNames(styles.selectItem, styles.selectMonthAsDateBlock)}>
                <InputLabel id="select-month-label">Календарный месяц</InputLabel>
                {mounths && (
                  <Select
                    labelId="select-month-label"
                    id="select-month-label"
                    size="small"
                    label="Календарный месяц"
                    value={mounths}
                    onClose={handleOnClose}
                    onOpen={handleOnOpen}
                    open={isOpenSelect}
                    multiple
                    renderValue={selected => getDataPickerLabel(selected)}
                    onChange={handleChangeMounths}
                    className={classNames(
                      styles.selectSize,
                      styles.selectMonthAsDate,
                      mounths.length ? styles.monthIsSelected : ""
                    )}
                    style={
                      {
                        // zIndex: isShowMounths ? 100 : 0
                      }
                    }>
                    {mounthsDate &&
                      sortMonthList(mounthsDate).map((item, index) => (
                        <MenuItem key={index} value={item} className={styles.noXPadding}>
                          <ListItemText primary={item} />
                          <Checkbox checked={mounths?.indexOf(item) > -1} />
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                onClick={handlePrevWeek}
                disabled={isDisabledWeek}
                className={classNames(
                  styles.standartButton,
                  prevWeek ? styles.bttnSize : styles.logicBttnSizedefault
                )}
                style={{
                  zIndex: isShowWeek ? 100 : 0,
                }}>
                Предыдущая неделя
              </Button>
              <DatePickerRu
                value={dateRange}
                onChange={setDateRangeHandler}
                minDate={minDate && new window.Date(minDate)}
                maxDate={maxDate && maxDate}
                periodListProp={periodsList}
                periodProps={""}
              />
            </div>
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default DatePickerLastWeek;
