import React, { Fragment, useState } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import lightFormat from "date-fns/lightFormat";
import { enqueueSnackbar } from "notistack";
import { isEmpty, path } from "ramda";
import { useDispatch } from "react-redux";
import { checkTariffExpired, setError } from "root/store/user/userSlice";

import API from "root/js/api";

import Button from "root/components/Button";
import IconButton from "root/components/IconButton";
import InlineButton from "root/components/InlineButton";
import MenuItem from "root/components/MenuItem";
import Tooltip from "root/components/Tooltip";

import typesJson from "../types.json";

import styles from "./index.module.scss";

import AddIcon from "root/icons/Add";
import ComponentSetting from "root/icons/ComponentSetting";

const WBAPI = new API();

const ConnectionCard = props => {
  const {
    id,
    onDelete,
    name,
    wb_oauth: oAuth = false,
    create_date: createDate,
    permissions: chips = [],
    onOpenDrower,
  } = props;
  const dispatch = useDispatch();
  const nameLength = window.innerWidth > 1200 ? 30 : window.innerWidth > 470 ? 20 : 10;

  const [anchorEl, setAnchorEl] = useState(null);
  const [permitionModal, setPermitionModal] = useState({
    wildberriesPortal: false,
  });

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onOpenModal = param => {
    setPermitionModal(state => ({ ...state, [param]: true }));
  };
  const onCloseModal = param => {
    setPermitionModal(state => ({ ...state, [param]: false }));
  };

  const [canCheckAuthWB, setCanCheckAuthWB] = useState(true);
  const [isLoadingAuthWB, setIsLoadingAuthWB] = useState(false);
  const refreshMpCabinetsoAuthRefresh = async () => {
    //
    if (!canCheckAuthWB || isLoadingAuthWB) return null;
    setCanCheckAuthWB(false);
    setTimeout(() => setCanCheckAuthWB(true), 5000);
    //
    try {
      setIsLoadingAuthWB(true);
      await WBAPI.getMpCabinetsoAuthRefresh({ id });
      enqueueSnackbar({
        title: "Кабинет подключен",
        variant: "success",
      });
    } catch (error) {
      const responseStatus = path(["response", "status"], error);
      if (responseStatus === 401) {
        WBAPI.resetToken();
        return history.push(
          `/signin?redirect_url=${window.location.pathname}?${window.location.search}`
        );
      } else if (responseStatus === 429) {
        dispatch(setError("throttled"));
      }
      if (responseStatus === 403) {
        dispatch(checkTariffExpired());
      }
      enqueueSnackbar({
        title: "Ошибка",
        message: "Кабинет не подключен",
        variant: "error",
      });
    } finally {
      setIsLoadingAuthWB(false);
    }
  };

  const startEdition = () => {
    onOpenDrower(id);
    setAnchorEl(null);
  };

  return (
    <Box position={"relative"} height={"100%"}>
      <CheckModal
        {...{
          open: permitionModal?.wildberriesPortal,
          onClose: () => onCloseModal("wildberriesPortal"),
          id,
        }}
      />
      <Box p={3} className={styles.root}>
        <Box mb={3} onClick={startEdition} style={{ cursor: "pointer" }}>
          <div className={styles.header}>
            <div className={styles.info}>
              <Box mb={1}>
                <div className={styles.name}>
                  <span>
                    {name.length > nameLength ? name.substr(0, nameLength) + "..." : name}
                  </span>
                </div>
              </Box>
              <div className={styles.lastUpdate}>ID: {id}</div>
              <div className={styles.lastUpdate}>
                Дата создания: {lightFormat(new Date(createDate), "dd.MM.yyyy в HH:mm")}
              </div>
              {oAuth && (
                <div className={styles.oAuthText}> Ваш кабинет имеет аутентификацию на WB </div>
              )}
            </div>
          </div>
        </Box>

        {isEmpty(chips) && !oAuth ? (
          <Fragment>
            <Divider />
            <Box mt={3}>
              <Box
                className={classNames(styles.chip, styles.addNewChip)}
                style={{
                  width: "100%",
                  cursor: "pointer",
                }}
                textAlign={"center"}
                justifyContent={"center"}
                display={"flex"}
                gap={"4px"}
                onClick={startEdition}>
                <AddIcon
                  style={{
                    display: "grid",
                    paddingBottom: "2px",
                    width: "15px",
                    height: "15px",
                  }}
                />
                Добавить API-ключ
              </Box>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Divider />
            <Box mt={3} display={"flex"} flexWrap={"wrap"} gap={"1rem"}>
              {chips.map(({ type, is_revoked, read_only }, index) => (
                <Tooltip
                  key={type + "_$_" + index}
                  title={is_revoked ? "Токен устарел, вам нужно обновить." : null}>
                  <Box
                    className={classNames(
                      is_revoked && styles.is_revoked,
                      read_only && !is_revoked && styles.read_only,
                      styles.chip
                    )}>
                    {typesJson[type]}
                  </Box>
                </Tooltip>
              ))}
              <Box className={classNames(styles.addNewChip, styles.chip)} onClick={startEdition}>
                {/* <AddIcon style={{ width: "15px", height: "15px" }} /> */}
                Добавить API-токен
              </Box>
            </Box>
          </Fragment>
        )}
        {!oAuth && (
          <Box mt={1}>
            <Box
              className={classNames(styles.chip, styles.oAuth)}
              style={{
                width: "100%",
                cursor: "pointer",
              }}
              textAlign={"center"}
              justifyContent={"center"}
              display={"flex"}
              gap={"4px"}
              onClick={() => onOpenModal("wildberriesPortal")}>
              Подключить через портал Wildberries
            </Box>
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: -56,
          }}
          variant="menu">
          {/* {oAuth && <MenuItem onClick={refreshMpCabinetsoAuthRefresh}>
						<InlineButton
							variant="primary"
							bigger
						>
							Проверить подключение
						</InlineButton>
					</MenuItem>} */}
          <MenuItem onClick={startEdition}>
            <InlineButton variant="primary" bigger>
              Редактировать
            </InlineButton>
          </MenuItem>
          <MenuItem onClick={() => onDelete(id)}>
            <InlineButton variant="primary" bigger>
              Удалить
            </InlineButton>
          </MenuItem>
        </Menu>
      </Box>
      <Box className={styles.buttonWrapper}>
        {oAuth && (
          // <Tooltip
          //   title={
          //     !canCheckAuthWB &&
          //     "Недавно вы уже проверяли подключение, пожалуйста повторите попытку позже"
          //   }>
          //   <Box>
          <Button
            className={classNames(styles.oAuth, styles.chip, styles.oAuthBg)}
            sx={{
              width: "fit-content",
              cursor: "pointer",
            }}
            textAlign={"center"}
            justifyContent={"center"}
            display={"flex"}
            gap={"4px"}
            disabled={!canCheckAuthWB}
            isLoading={isLoadingAuthWB}
            onClick={refreshMpCabinetsoAuthRefresh}>
            Проверить <br />
            подключение
          </Button>
          //   </Box>
          // </Tooltip>
        )}
        <IconButton variant="primaty-outlined" onClick={openMenu}>
          {/* <MoreHorizIcon /> */}
          <ComponentSetting />
        </IconButton>
      </Box>
    </Box>
  );
};

export const CheckModal = ({ open, onClose, id, is_demo }) => {
  const dispatch = useDispatch();
  const fetchUrlConnectWbOauth = async () => {
    try {
      const data = await WBAPI.getUrlConnectWbOauth({ cabinet_id: id, is_demo });
      if (data.url) {
        window.location.replace(data.url);
      }
    } catch (error) {
      const responseStatus = path(["response", "status"], error);
      if (responseStatus === 401) {
        WBAPI.resetToken();
        return history.push(
          `/signin?redirect_url=${window.location.pathname}?${window.location.search}`
        );
      } else if (responseStatus === 429) {
        dispatch(setError("throttled"));
      }
      if (responseStatus === 403) {
        dispatch(checkTariffExpired());
      }
      enqueueSnackbar({
        title: "Ошибка",
        message: "Попробуйте позде",
        variant: "error",
      });
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={2}>
        <Box height={"20px"} />
        <Typography variant="h4" className={styles.title} align="center">
          Подключение кабинета Wildberries
        </Typography>
        <Box height={"20px"} />
        <Typography variant="subtitle2" className={styles.text} align="center">
          Для аутентификации вы будете перенаправлены на страницу портала для поставщиков
          seller.wildberries.ru.
          <br />
          Пожалуйста, проверьте, что в этом браузере вы авторизованы в личном кабинете WB, который
          собираетесь подключить к Wildbox.
          <br />
          Для продолжения нажмите на кнопку и следуйте дальнейшим инструкциям.
        </Typography>
        <Box height={"20px"} />
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Button variant="filled" fullWidth onClick={fetchUrlConnectWbOauth}>
            <Box component={"span"} whiteSpace={"normal"}>
              Подключить личный кабинет Wildberries
            </Box>
          </Button>
          <Button variant="outlined" fullWidth onClick={onClose}>
            Отмена
          </Button>
        </Box>
        <Box height={"20px"} />
      </Box>
    </Dialog>
  );
};

export default ConnectionCard;
