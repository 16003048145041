import React from "react";

import Paper from "@mui/material/Paper";

import Tab from "root/components/Tab";
import Tabs from "root/components/Tabs";

import styles from "../../../Tab/index.module.scss";

const TabSelect = props => {
  const { onChange, tab, data } = props;
  return (
    <Paper elevation={0}>
      <div>
        <Tabs value={tab} onChange={(e, tab) => onChange(tab)}>
          {data.map((item, index) => (
            <Tab
              key={item.keyIndex}
              value={index}
              label={item.title}
              disabled={item.disabled}
              trip={item.hint}
            />
          ))}
        </Tabs>
      </div>
    </Paper>
  );
};

export default TabSelect;
