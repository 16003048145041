import { useEffect, useState } from 'react';

const useMinMaxDates = (matchingKeys) => {
  const [maxDate, setMaxDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());

  useEffect(() => {
    const maxDates = matchingKeys
      .filter((elem) => elem.max_rr_date)
      .map((elem) => new Date(elem.max_rr_date));

     
    const minDates = matchingKeys.map(
      (elem) => new Date(elem.min_rr_date || elem.create_date)
    );

    const newMaxDate = maxDates.length ? new Date(Math.max.apply(null, maxDates)) : new Date();
    const newMinDate = new Date(Math.min.apply(null, minDates));

    setMaxDate(newMaxDate);
    setMinDate(newMinDate);
  }, [matchingKeys]);

  return { maxDate, minDate };
};

export default useMinMaxDates;