export const SEARCH_DEBOUNCE_TIME = 2000;
export const CHANGE_DEBOUNCE_TIME = 1000;

export const MONTH_LIST = [
  {
    label: "Январь",
    value: "01"
  },
  {
    label: "Февраль",
    value: "02"
  },
  {
    label: "Март",
    value: "03"
  },
  {
    label: "Апрель",
    value: "04"
  },
  {
    label: "Май",
    value: "05"
  },
  {
    label: "Июнь",
    value: "06"
  },
  {
    label: "Июль",
    value: "07"
  },
  {
    label: "Август",
    value: "08"
  },
  {
    label: "Сентябрь",
    value: "09"
  },
  {
    label: "Октябрь",
    value: "10"
  },
  {
    label: "Ноябрь",
    value: "11"
  },
  {
    label: "Декабрь",
    value: "12"
  }
];

export const REQUESTS_DEBOUNCE_TIME = 500;