import request from "root/js/request";

import { convertToMoscowTimeString } from "root/js/utils";

const getCapitalizationDynamic = async ({
  cabinet_ids,
  product_ids,
  date_from,
  date_to,
  group_by
}) => {
  const response = await request({
    url: "/analytics/capitalization_dynamic/",
    method: "get",
    params: {
      wb_api_key_ids: cabinet_ids,
      product_id__in: product_ids,
      date_from: convertToMoscowTimeString(date_from),
      date_to: convertToMoscowTimeString(date_to, true),
      group_by
    }
  });
  return response.data;
};

export { getCapitalizationDynamic };
