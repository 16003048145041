import request from "root/js/request";

export const getWBDynamicCategoriesSummary = async ({
  sales_percent__gte,
  sales_percent__lte,
  on_site_date__gte,
  on_site_date__lte,
  date_from,
  date_to,
  sales__gte,
  sales__lte,
  sales_proceeds__gte,
  sales_proceeds__lte,
  level,
  parent_id,
  down_parent_id,
  up_parent_id,
  sellers__lte,
  sellers__gte,
  sellers_with_orders__lte,
  sellers_with_orders__gte,
  avg_percent_sellers_with_orders__lte,
  avg_percent_sellers_with_orders__gte,
  dynamic_sales_percent__lte,
  dynamic_sales_percent__gte,
  brands__lte,
  brands__gte,
  brands_with_orders__lte,
  brands_with_orders__gte,
  avg_percent_brands_with_orders__lte,
  avg_percent_brands_with_orders__gte,
  products_with_orders__lte,
  products_with_orders__gte,
  avg_percent_articles_with_orders__lte,
  avg_percent_articles_with_orders__gte,
  avg_proceeds_per_article__lte,
  avg_proceeds_per_article__gte,
  avg_proceeds_per_article_with_orders__lte,
  avg_proceeds_per_article_with_orders__gte,
  avg_orders_per_article__lte,
  avg_orders_per_article__gte,
  avg_orders_per_article_with_orders__lte,
  avg_orders_per_article_with_orders__gte,
  vendor_codes__lte,
  vendor_codes__gte,
  avg_proceeds__lte,
  avg_proceeds__gte,
  dynamic_vendor_codes_percent__lte,
  dynamic_vendor_codes_percent__gte,
  dynamic_avg_proceeds_percent__lte,
  dynamic_avg_proceeds_percent__gte,
  brand_id__in,
  brand_id,
  category_id__in,
  category_id,
  seller_id__in,
  seller_id,
  product_id__in,
  product_id,
  orders__lte,
  orders__gte,
  proceeds__lte,
  proceeds__gte,
  quantity__lte,
  quantity__gte,
  returns__lte,
  returns__gte,
  lost_proceeds__lte,
  lost_proceeds__gte,
  incomes__lte,
  incomes__gte,
  ransom__lte,
  ransom__gte,
  price__lte,
  price__gte,
  old_price__lte,
  old_price__gte,
  discount__lte,
  discount__gte,
  basic_discount__lte,
  basic_discount__gte,
  promo_discount__lte,
  promo_discount__gte,
  orders_failed__lte,
  orders_failed__gte,
  incomes_failed__lte,
  incomes_failed__gte,
  proceeds_failed__lte,
  proceeds_failed__gte,
  position__lte,
  position__gte,
  returns_percent__lte,
  returns_percent__gte,
  reviews,
  // reviewsо,
  lost_proceeds_share,
  dynamic_proceeds_percent__lte,
  dynamic_proceeds_percent__gte,
  dynamic_orders_percent__lte,
  dynamic_orders_percent__gte,
  dynamic_price_percent__lte,
  dynamic_price_percent__gte,
  dynamic_lost_proceeds_percent__lte,
  dynamic_lost_proceeds_percent__gte,
  dynamic_quantity_percent__lte,
  dynamic_quantity_percent__gte,
  limit,
  offset,
  ordering,
  in_stock_orders_avg__gte,
  in_stock_orders_avg__lte,
  in_stock_proceeds__gte,
  in_stock_proceeds__lte,
  month_width,
  // month_width: true,
  has_child,
  weighted_price__gte,
  weighted_price__lte,
}) => {
  const response = await request({
    url: `/wb_dynamic/categories_summary/`,
    method: "get",
    params: {
      sales_percent__gte,
      sales_percent__lte,
      on_site_date__gte,
      on_site_date__lte,
      date_from,
      date_to,
      sales__gte,
      sales__lte,
      sales_proceeds__gte,
      sales_proceeds__lte,
      level,
      parent_id,
      down_parent_id,
      up_parent_id,
      sellers__lte,
      sellers__gte,
      sellers_with_orders__lte,
      sellers_with_orders__gte,
      avg_percent_sellers_with_orders__lte,
      avg_percent_sellers_with_orders__gte,
      dynamic_sales_percent__lte,
      dynamic_sales_percent__gte,
      brands__lte,
      brands__gte,
      brands_with_orders__lte,
      brands_with_orders__gte,
      avg_percent_brands_with_orders__lte,
      avg_percent_brands_with_orders__gte,
      products_with_orders__lte,
      products_with_orders__gte,
      avg_percent_articles_with_orders__lte,
      avg_percent_articles_with_orders__gte,
      avg_proceeds_per_article__lte,
      avg_proceeds_per_article__gte,
      avg_proceeds_per_article_with_orders__lte,
      avg_proceeds_per_article_with_orders__gte,
      avg_orders_per_article__lte,
      avg_orders_per_article__gte,
      avg_orders_per_article_with_orders__lte,
      avg_orders_per_article_with_orders__gte,
      vendor_codes__lte,
      vendor_codes__gte,
      avg_proceeds__lte,
      avg_proceeds__gte,
      dynamic_vendor_codes_percent__lte,
      dynamic_vendor_codes_percent__gte,
      dynamic_avg_proceeds_percent__lte,
      dynamic_avg_proceeds_percent__gte,
      brand_id__in,
      brand_id,
      category_id__in,
      category_id,
      seller_id__in,
      seller_id,
      product_id__in,
      product_id,
      orders__lte,
      orders__gte,
      proceeds__lte,
      proceeds__gte,
      quantity__lte,
      quantity__gte,
      returns__lte,
      returns__gte,
      lost_proceeds__lte,
      lost_proceeds__gte,
      incomes__lte,
      incomes__gte,
      ransom__lte,
      ransom__gte,
      price__lte,
      price__gte,
      old_price__lte,
      old_price__gte,
      discount__lte,
      discount__gte,
      basic_discount__lte,
      basic_discount__gte,
      promo_discount__lte,
      promo_discount__gte,
      orders_failed__lte,
      orders_failed__gte,
      incomes_failed__lte,
      incomes_failed__gte,
      proceeds_failed__lte,
      proceeds_failed__gte,
      position__lte,
      position__gte,
      returns_percent__lte,
      returns_percent__gte,
      reviews,
      // reviewsо,
      lost_proceeds_share,
      dynamic_proceeds_percent__lte,
      dynamic_proceeds_percent__gte,
      dynamic_orders_percent__lte,
      dynamic_orders_percent__gte,
      dynamic_price_percent__lte,
      dynamic_price_percent__gte,
      dynamic_lost_proceeds_percent__lte,
      dynamic_lost_proceeds_percent__gte,
      dynamic_quantity_percent__lte,
      dynamic_quantity_percent__gte,
      limit,
      offset,
      ordering,
      in_stock_orders_avg__gte,
      in_stock_orders_avg__lte,
      in_stock_proceeds__gte,
      in_stock_proceeds__lte,
      month_width: month_width || undefined,
      // month_width: true,
      has_child,
      weighted_price__gte,
      weighted_price__lte,
    },
  });
  return response.data;
};
