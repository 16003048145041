import React from 'react'

import TooltipUI from "../../../components/Tooltip"
import TooltipQuestionIcon from "../../../icons/TooltipQuestion"
const Trips = ({ text }) => {
  return (
    <TooltipUI
      disableInteractive
      title={
        <div style={{ textAlign: "left" }}>
          {text}
        </div>
      }
      enterTouchDelay={0}
    >
      <TooltipQuestionIcon
        onClick={(e) => e.stopPropagation()}
        style={{ cursor: 'pointer', marginLeft: '6px', color: 'gray', pointerEvents: "auto" }} />
    </TooltipUI>
  )
}

export default Trips