import { createSlice } from "@reduxjs/toolkit";

import { reduxTrendRequestsParams, trendRequestsSeasonality, trendRequestsSelectors } from "./data";

//
const initialState = {
  params: reduxTrendRequestsParams,
  selectors: trendRequestsSelectors,
  seasonality: trendRequestsSeasonality,
  isEmptyRequiredFields: false,
  data: [],
  checkedItemsIds: [],
  isAllChecked: false,
};
// -----------------------------------------------------------------------------
const trendRequestsSlice = createSlice({
  name: "trendRequests",
  initialState,
  reducers: {
    setTrendRequestsParam(state, { payload: { name, value } }) {
      state.params[name] = value;
    },
    setTrendRequestsParamWithTarget(state, { payload: { name, value, _te } }) {
      state.params[name][_te] = value;
      if (value.trim()) state.isEmptyRequiredFields = false;
    },
    setTrendRequestsParamTarget(state, { payload: { name, value } }) {
      state.params[name].target = value;
    },
    setTrendRequestsEmptyFieldsFlag(state) {
      state.isEmptyRequiredFields = true;
    },
    setTrendRequestsSelector(state, { payload: { name, value } }) {
      state.selectors[name] = value;
    },
    setTrendRequestsSeasonality(state, { payload: { name, value } }) {
      state.seasonality[name] = value;
    },
    setTrendRequestsSavedFilters(state, { payload: { params, selectors, seasonality } }) {
      state.params = params;
      state.selectors = selectors;
      state.seasonality = seasonality;
    },
    resetTrendRequestsFilters(state) {
      state.params = reduxTrendRequestsParams;
      state.selectors = trendRequestsSelectors;
      state.seasonality = trendRequestsSeasonality;
      localStorage.removeItem("saved_filtering_options");
    },
    // --------------- FORMED PAGE
    restoreChecked(state) {
      state.checkedItemsIds = [];
      state.isAllChecked = false;
    },
    setTrendRequestsData(state, { payload: newData }) {
      state.data = newData;
      //
      if (state.data.every(item => state.checkedItemsIds.includes(item.id))) {
        state.isAllChecked = true;
      } else {
        state.isAllChecked = false;
      }
    },
    setTrendRequestsCheckedItemsIds(state, { payload: id }) {
      const haveItem = state.checkedItemsIds.includes(id);
      if (haveItem) {
        state.checkedItemsIds = state.checkedItemsIds.filter(item => item !== id);
      } else {
        state.checkedItemsIds = [...state.checkedItemsIds, id];
      }
      //
      if (state.data.every(item => state.checkedItemsIds.includes(item.id))) {
        state.isAllChecked = true;
      } else {
        state.isAllChecked = false;
      }
    },
    toggleTrendRequestsIsAllChecked(state) {
      if (state.isAllChecked) {
        state.checkedItemsIds = state.checkedItemsIds.filter(
          id => !state.data.find(item => item.id === id)
        );
      } else {
        const arr = [];
        for (const item of state.data) {
          if (!state.checkedItemsIds.includes(item.id)) arr.push(item.id);
        }
        // console.log(arr);

        state.checkedItemsIds = [...state.checkedItemsIds, ...arr];
      }
      state.isAllChecked = !state.isAllChecked;
    },
  },
});

export default trendRequestsSlice.reducer;
export const {
  setTrendRequestsParam,
  setTrendRequestsParamWithTarget,
  setTrendRequestsParamTarget,
  setTrendRequestsEmptyFieldsFlag,
  setTrendRequestsSelector,
  setTrendRequestsSeasonality,
  setTrendRequestsSavedFilters,
  resetTrendRequestsFilters,
  restoreChecked,
  setTrendRequestsData,
  setTrendRequestsCheckedItemsIds,
  toggleTrendRequestsIsAllChecked,
} = trendRequestsSlice.actions;
