import request from "root/js/request";

export const getWarehousesSupplyTable = async ({
  wb_api_key_ids,
  group_by,
  brand_id__in,
  category_id__in,
  subject_id__in,
  sort,
  q,
  with_seasonality,
  with_in_way_stocks,
  with_seasonality_spikes,
  with_mp_growth,
  with_safety_stock,
  with_company_plan,
  with_return_share,
  with_cancel_share,
  with_abc_xyz_cz_excluded,
  with_trend,
  forecast_period,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/forecasting/warehouse_supply_table/",
    method: "get",
    params: {
      wb_api_key_ids,
      group_by,
      brand_id__in,
      category_id__in,
      subject_id__in,
      q,
      order_by: sort,
      with_seasonality,
      with_in_way_stocks,
      with_seasonality_spikes,
      with_mp_growth,
      with_safety_stock,
      with_company_plan,
      with_return_share,
      with_cancel_share,
      with_abc_xyz_cz_excluded,
      with_trend,
      forecast_period,
      ...rest,
    },
  });
  return response.data;
};
