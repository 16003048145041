import React from "react";

import MonitoringMenuIcon from "root/icons/MonitoringMenu";

// ------------------------------------------------------------------
const monitoringSection = {
  type: "section",
  title: "Мониторинг",
  icon: MonitoringMenuIcon,
  children: [
    {
      url: "/monitoring?tab=0",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "Товаров",
      exact: false,
    },
    {
      url: "/monitoring?tab=1",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "Брендов",
      exact: false,
    },
    {
      url: "/monitoring?tab=2",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "Поставщиков",
      exact: false,
    },
    {
      url: "/monitoring?tab=3",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "Категорий",
      exact: false,
    },
    {
      url: "/monitoring?tab=4",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "Запросов",
      exact: false,
    },
    {
      url: "/monitoring?tab=5",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "СПП",
      exact: false,
    },
    {
      url: "/monitoring?tab=6",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "Позиций в поиске",
      exact: false,
    },
    {
      url: "/monitoring?tab=7",
      component: React.lazy(() => import("root/pages/monitoring/App")),
      title: "Внешнего трафика",
      exact: false,
      // tariffIds: [2, 3, 4, 14, 16, 17],
    },
  ],
};

export default monitoringSection;
