const { default: request } = require("root/js/request");

const getOrderAndSales = async ({
  cabinet_ids,
  ordering,
  section,
  brand_names,
  limit,
  offset,
  date_from,
  date_to,
  period
}) => {
  const response = await request({
    url: `analytics/order_and_sales/`,
    method: "get",
    params: {
      wb_api_key_ids: cabinet_ids,
      brand_name__icontains: brand_names,
      section,
      ordering,
      date_from,
      date_to,
      period,
      limit,
      offset
    }
  });
  return response.data;
};

export { getOrderAndSales };
