import request from "root/js/request";

const getAbcAnalysisWidgetsOzon = async ({
  wb_api_key_ids,
  cabinet_ids,
  date_from,
  date_to,
  analyze_by,
  labels,
  xyz_labels,
  ...rest
}) => {
  try {
    const response = await request({
      url: `ozon_analytics/abc_analysis/widgets/`,
      method: "get",
      params: {
        cabinet_ids: cabinet_ids || wb_api_key_ids,
        date_from,
        date_to,
        analyze_by,
        labels,
        xyz_labels,
        ...rest,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in getAbcAnalysisWidgets:", error);
    throw error;
  }
};

export { getAbcAnalysisWidgetsOzon };
