import request from "root/js/request";

export const postSharesUpload = async ({ cabinet_id, shares_id }) => {
  const response = await request({
    url: `analytics/shares/${shares_id}/shares_upload/?cabinet_id=${cabinet_id}`,
    method: "post",
    params: {
      cabinet_id,
      shares_id,
    },
  });
  return response;
};
