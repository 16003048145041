import React, { useState } from "react";

import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { prop } from "ramda";
import { useSelector } from "react-redux";

import CardTwoProcents from "root/components/global/cards/card-two-procents";

import styles from "./index.module.scss";

const PaidWidgets = ({ paidWigets }) => {
  const { currencyName } = useSelector(state => state.financeLogic);

  const [currentIndex, setCurrentIndex] = useState(0)
  const [length, setLength] = useState(2)
  const isMobile = useMediaQuery('(max-width: 600px)');
  const next = () => {
    if (currentIndex < (length - 1)) {
      setCurrentIndex(prevState => prevState + 1)
    }
  }
  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  return (
    <div className={styles.wrapperWidgets}>
      {isMobile &&
        <IconButton onClick={prev} className={styles.controlBtn} sx={{ left: "5px" }}>
          <ArrowLeftIcon />
        </IconButton>
      }
      <div className={styles.paidWidgetsContainer}
        style={{ transform: `translateX(-${currentIndex * 102}%)` }}
      >
        <CardTwoProcents
          title="Хранение"
          valueTitle="Сумма"
          borderIncrement="4px solid red"
          borderDicrement="4px solid #00C85A"
          transformRotateIncrement="rotate(0deg)"
          transformRotateDicrement="rotate(90deg)"
          colorIncrement="red"
          colorDicrement="#00C85A"
          valueValuet={currencyName}
          value={prop("warehouse_price", prop("current", paidWigets)) || 0}
          resultPrecent={
            (prop("warehouse_price", prop("current", paidWigets)) -
              prop("warehouse_price", prop("prev", paidWigets))) /
            prop("warehouse_price", prop("prev", paidWigets)) || 0
          }
          secondValueTitle="Количество"
          secondValueValuet="шт"
          secondValue={prop("barcodes_count", prop("current", paidWigets)) || 0}
          secondResultPrecent={
            (prop("barcodes_count", prop("current", paidWigets)) -
              prop("barcodes_count", prop("prev", paidWigets))) /
            prop("barcodes_count", prop("prev", paidWigets)) || 0
          }
          secondValueTrip="Сумма количества товара, подлежащего тарифицированию в каждый из дней периода"
          thirdValueTrip="Сумма объема товара, подлежащего тарифицированию в каждый из дней периода"
          thirdValueTitle="Объём"
          thirdValueValuet="л"
          thirdValue={Number(prop("volume", prop("current", paidWigets))?.toFixed(0)) || 0}
          thirdResultPrecent={
            (prop("volume", prop("current", paidWigets)) - prop("volume", prop("prev", paidWigets))) /
            prop("volume", prop("prev", paidWigets)) || 0
          }
        />

        <CardTwoProcents
          title="Приёмка"
          valueTitle="Сумма"
          borderIncrement="4px solid #00C85A"
          borderDicrement="4px solid red"
          transformRotateIncrement="rotate(0deg)"
          transformRotateDicrement="rotate(90deg)"
          colorIncrement="#00C85A"
          colorDicrement="red"
          valueValuet={currencyName}
          value={Number(prop("acceptance_value", prop("current", paidWigets))?.toFixed(0)) || 0}
          resultPrecent={
            (prop("acceptance_value", prop("current", paidWigets)) -
              prop("acceptance_value", prop("prev", paidWigets))) /
            prop("acceptance_value", prop("prev", paidWigets)) || 0
          }
          secondValueTitle="Количество"
          secondValueValuet="шт"
          secondValue={Number(prop("acceptance_count", prop("current", paidWigets))?.toFixed(0)) || 0}
          secondResultPrecent={
            (prop("acceptance_count", prop("current", paidWigets)) -
              prop("acceptance_count", prop("prev", paidWigets))) /
            prop("acceptance_count", prop("prev", paidWigets)) || 0
          }
        />
      </div>
      {isMobile &&
        <IconButton onClick={next} className={styles.controlBtn} sx={{ right: "5px" }}>
          <ArrowLeftIcon sx={{ transform: "rotate(180deg)" }} />
        </IconButton>
      }
    </div>
  );
};

export default PaidWidgets;
