import { createSlice } from "@reduxjs/toolkit";

import { bloggersPostsSelectionParamsForRedux, externalTrafficOptions } from "./utils";

export const paramsBPS = {
  source: [],
  brand_id: [],
  account_name_hash: [],
  item: [],
  product_id: [],
  valueble: externalTrafficOptions[0].id,
  ...bloggersPostsSelectionParamsForRedux,
};

const initialState = {
  params: {
    ...paramsBPS,
  },
};

const bloggersPostsSelection = createSlice({
  name: "bloggersPostsSelection",
  initialState,
  reducers: {
    setSocialNetworks(state, { payload: newSN = [] }) {
      state.params.source = newSN;
    },
    setBPSParamByName(state, { payload: { value, name } }) {
      state.params[name] = value;
    },
    setSavedParamsBPS(state, { payload: savedParams }) {
      state.params = savedParams;
    },
    resetReduxFiltersBPS(state) {
      state.params = paramsBPS;
    },
  },
});

export default bloggersPostsSelection.reducer;
export const { setSocialNetworks, setBPSParamByName, setSavedParamsBPS, resetReduxFiltersBPS } =
  bloggersPostsSelection.actions;
