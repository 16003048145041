import React from "react";

import { Box } from "@mui/material";
import classNames from "classnames";

import styles from "./index.module.scss";

const Plug = ({ className, text = "Подождите, данные загружаются", children, ...rest }) => {
  return (
    <Box className={classNames(styles.root, className)} {...rest}>
      <Box className={styles.box}>
        <Box className={styles.text}>{text}</Box>
        {children && <Box className={styles.childrenBox}>{children}</Box>}
      </Box>
    </Box>
  );
};

export default Plug;
