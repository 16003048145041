import request from "root/js/request";

export const getAnalyticsAdvertDynamicGMRelated = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  status,
  type,
  advert_id__in,
  brand_id__in,
  category_id__in,
  subject_id__in,
  product_id__in,
  type__in,
  status__in,
  ...rest
}) => {
  const response = await request({
    url: "analytics/advert_dynamic/gm_related/",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      status,
      type,
      advert_id__in,
      brand_id__in,
      category_id__in,
      subject_id__in,
      product_id__in,
      type__in,
      status__in,
      ...rest,
    },
  });
  return response.data;
};
