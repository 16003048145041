import request from "root/js/request";

// Список групп
const getNotificationGroups = async ({ cabinet_id }) => {
  const response = await request({
    url: `/analytics/notification-groups/`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response.data;
};
// Создание группы
const postNotificationGroups = async ({ cabinet_id, name }) => {
  const response = await request({
    url: `/analytics/notification-groups/`,
    method: "post",
    params: {
      cabinet_id,
    },
    data: {
      cabinet_id,
      name,
    },
  });
  return response;
};

// Удаление групп
const deleteNotificationGroupById = async (cabinet_id, group_id) => {
  try {
    const response = await request({
      url: `/api/analytics/notification-groups/${group_id}/`,
      method: "delete",
      params: { cabinet_id },
    });

    return response.data;
  } catch (error) {
    console.error(`Error deleting group with ID ${group_id}:`, error);
    throw error;
  }
};

// Редактирование групп
const editNotificationGroupById = async (cabinet_id, group_id, name) => {
  try {
    const response = await request({
      url: `/api/analytics/notification-groups/${group_id}/`,
      method: "put",
      params: {
        cabinet_id,
      },
      data: {
        cabinet_id,
        name,
      },
    });

    return response;
  } catch (error) {
    console.error(`Error editing group with ID ${group_id}:`, error);
    throw error;
  }
};

// Тестирование
const testNotificationGroups = async ({ cabinet_id, group_id }) => {
  const response = await request({
    url: `/api/analytics/notification-groups/${group_id}/test/`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response.data;
};

export {
  getNotificationGroups,
  postNotificationGroups,
  deleteNotificationGroupById,
  editNotificationGroupById,
  testNotificationGroups,
};
