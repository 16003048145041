import { createAsyncThunk } from "@reduxjs/toolkit"

export const setWords = createAsyncThunk(
	"/setWords",
	async ({ value = "" }) => value
)
export const setParams = createAsyncThunk(
	"/setParams",
	async (data) => data
)
export const getDataIds = createAsyncThunk(
	"/getDataIds",
	async ({ data, page }) => {
		const ids = data.map(({ keyword_id }) => keyword_id)
		return ({
			ids,
			page,
		})
	}
)
export const checkAllAction = createAsyncThunk("/checkAllAction", async ({ bool, page, ids }) => ({ bool, page, ids }))
export const clearCheckBox = createAsyncThunk("/clearCheckBox", async (e) => (e))
export const checkAction = createAsyncThunk("/checkAction", async ({ bool, value, page }) => ({ bool, value, page }))
