import request from "root/js/request";

export const postUploadCost = async ({ shares_id, cabinet_id }, formData) => {
  const response = await request({
    url: `/analytics/shares_products/${shares_id}/upload_cost/?cabinet_id=${cabinet_id}`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });
  return response;
};
