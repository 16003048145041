import request from "root/js/request";

export const getAnalyticsCategoriesByContent = async ({
  imt_id,
  cabinet_id__in,
  subject_id__in,
  brand_id__in,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/categories/by-content/",
    method: "get",
    params: {
      imt_id,
      cabinet_id__in,
      subject_id__in,
      brand_id__in,
      ...rest,
    },
  });
  return response;
};
