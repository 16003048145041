import React from "react";

import { format } from "date-fns";
import { keys } from "ramda";
import { Label, ReferenceLine } from "recharts";

import {
  dzen,
  // instagram,
  instagram_empty,
  telegram,
  tiktok,
  vkontakte,
  youtube,
} from "root/icons/socialNetworksIcons";

// variables
const socialNetworkImages = {
  dzen,
  instagram: instagram_empty,
  telegram,
  tiktok,
  vkontakte,
  youtube,
};

// ------------------------------------------------------------------------
export const socialNetworkReferenceLines = (
  socialNetworks = [],
  setHoverMark = () => {},
  styles,
  yAxisId,
  onServerFormat
) => {
  return socialNetworks.map(item => {
    const date = onServerFormat
      ? format(new Date(item.date), "yyyy-MM-dd")
      : format(new Date(item.date), "dd.MM.yyyy");
    const { value = {} } = item;
    return (
      <ReferenceLine key={date} x={date} stroke="purple" yAxisId={yAxisId} index={date}>
        {keys(value).map((socialItem, index) => {
          const src = socialNetworkImages[socialItem] || "";
          const targetLine = document.querySelector(`line[x="${date}"]`);
          return (
            <Label
              value={item}
              content={props =>
                renderCustomLabel({
                  onMouseLeave: () => setHoverMark(),
                  onMouseEnter: () =>
                    setHoverMark({ date, item: socialItem, count: value?.[socialItem] ?? 0 }),
                  y: (index + 1) * 25,
                  x: 7,
                  src,
                  ...props,
                })
              }
              key={socialItem}
              position="insideTopRight"
              fontSize={28}
              dx={13.5}
              style={targetLine ? {} : { webkitTextSecurity: "square" }}
              className={styles.label}
              dy={(index + 1) * 25}
            />
          );
        })}
      </ReferenceLine>
    );
  });
};

//
const labelWidth = 14;
const labelHeight = 14;
//
const renderCustomLabel = ({ onMouseLeave, onMouseEnter, viewBox, src, y }) => {
  return (
    <foreignObject
      {...{ onMouseLeave, onMouseEnter, y }}
      style={{
        cursor: "pointer",
      }}
      x={viewBox.x - labelWidth / 2}
      width={labelWidth}
      height={labelHeight}>
      <img
        src={src}
        style={{ width: labelWidth, height: labelHeight, objectFit: "contain" }}
        alt="Photo"
      />
    </foreignObject>
  );
};

// export default SocialNetworkReferenceLines;
