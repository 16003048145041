import { createSlice } from "@reduxjs/toolkit"
import { keys, length, propOr } from "ramda"

import { createAnalyticsShares, getSharesDynamicByProduct, getSharesTable, getStocks, patchAnalyticsShares, switchDataFromTable } from "./actions"

import { defaultToObj, palette } from "root/js/utils"

const initialState = {
	isLoading: false,
	isAddingStock: false,
	types: "3,4",
	file: null,
	stocksTitle: "",
	cabinets: {
		current: null,
		isLoading: false,
		results: []
	},
	stocks: {
		title: null,
		current: null,
		count: 0,
		results: [],
		isLoading: false
	},
	params: {
		start_date: null,
		duration_days: null,
		ranking_influence: null,
	},
	create: false,
	isShowTable: false,
	table: {
		isExporting: false,
		exportActiveType: undefined,
		exportType: [
			{
				key: undefined,
				title: "Цена и скидки"
			},
			{
				key: "planned_price",
				title: "Цена"
			},
			{
				key: "promotion_discount",
				title: "Скидка"
			}
		],
		count: 0,
		filters: {
			synthetic_abc_group_label__in: [],
			days_on_site_gte: undefined,
			days_on_site_lte: undefined,
		},
		results: [],
		tabs: [
			{
				key: "participate",
				title: "Участвуют",
				active: true,
			},
			{
				key: "do_not_participate",
				title: "Не участвуют",
				active: false,
			}
		],
		isLoading: false,
		product_id__icontains: "",
		supplier_article__icontains: "",
		error: false,
		checkBoxSelect: { 1: [] },
		ids: [],
		page: 1,
		perPage: 50
	},
	dynamicModule: {
		isOpen: false,
		checkBoxes: [
			// {
			// 	key: "position",
			// 	orientationKey: "position",
			// 	title: "Позиция",
			// 	afix: "",
			// 	checked: false,
			// 	disabled: true,
			// 	orientation: "left",
			// 	color: palette.purple,
			// },
			{
				title: "Цена",
				key: "price",
				afix: " руб.",
				orientationKey: "price",
				checked: true,
				disabled: false,
				orientation: "right",
				color: palette.magentaPink,
			},
			{
				key: "frequency",
				orientationKey: "frequency",
				title: "Частотность",
				checked: true,
				afix: "",
				disabled: false,
				orientation: "right",
				color: palette.pastelGreen,
			},
			{
				title: "Заказы",
				key: "orders",
				orientationKey: "frequency",
				checked: true,
				afix: " шт.",
				disabled: false,
				orientation: "left",
				color: palette.darkYellow,
			},
			{
				title: "СПП",
				key: "spp",
				afix: "",
				orientationKey: "frequency",
				checked: true,
				disabled: false,
				orientation: "right",
				color: palette.blue,
			},
		],
		dynamic: [],
		promos: [],
		isLoading: false
	},
	reports: []
}

export const stocks = createSlice({
	name: "stocks",
	initialState,
	reducers: {
		resetAllData(state) {
			state.isLoading = false
			state.isAddingStock = false
			state.file = null
			// state.stocksTitle = ""
			// state.params = {
			// 	start_date: null,
			// 	duration_days: null,
			// 	ranking_influence: null,
			// }
			state.create = false
			state.isShowTable = false
			state.table = {
				isExporting: false,
				exportActiveType: undefined,
				exportType: [
					{
						key: undefined,
						title: "Цена и скидки"
					},
					{
						key: "planned_price",
						title: "Цена"
					},
					{
						key: "promotion_discount",
						title: "Скидка"
					}
				],
				count: 0,
				filters: {
					synthetic_abc_group_label__in: [],
					days_on_site_gte: undefined,
					days_on_site_lte: undefined,
				},
				results: [],
				tabs: [
					{
						key: "participate",
						title: "Участвуют",
						active: true,
					},
					{
						key: "do_not_participate",
						title: "Не участвуют",
						active: false,
					}
				],
				isLoading: false,
				product_id__icontains: "",
				supplier_article__icontains: "",
				error: false,
				checkBoxSelect: { 1: [] },
				ids: [],
				page: 1,
				perPage: 50
			}
			state.dynamicModule = {
				isOpen: false,
				checkBoxes: [
					// {
					// 	key: "position",
					// 	orientationKey: "position",
					// 	title: "Позиция",
					// 	afix: "",
					// 	checked: false,
					// 	disabled: true,
					// 	orientation: "left",
					// 	color: palette.purple,
					// },
					{
						title: "Цена",
						key: "price",
						afix: " руб.",
						orientationKey: "price",
						checked: true,
						disabled: false,
						orientation: "right",
						color: palette.magentaPink,
					},
					{
						key: "frequency",
						orientationKey: "frequency",
						title: "Частота использования",
						checked: true,
						afix: "",
						disabled: false,
						orientation: "right",
						color: palette.pastelGreen,
					},
					{
						title: "Заказы",
						key: "orders",
						orientationKey: "frequency",
						checked: true,
						afix: " шт.",
						disabled: false,
						orientation: "left",
						color: palette.darkYellow,
					},
					{
						title: "СПП",
						key: "spp",
						afix: "",
						orientationKey: "frequency",
						checked: true,
						disabled: false,
						orientation: "right",
						color: palette.blue,
					},
				],
				dynamic: [],
				promos: [],
				isLoading: false
			}
			state.reports = []
		},
		handleExportType(state, { payload }) {
			state.table.exportActiveType = payload
		},
		handleExport(state, { payload }) {
			state.table.isExporting = payload
		},
		handleCheckBox(state, { payload }) {
			state.dynamicModule.checkBoxes = state.dynamicModule.checkBoxes.map(item => {
				if (item.key == payload) return { ...item, checked: !item.checked }
				return item
			})
		},
		handleStocksTitle(state, { payload = "" }) {
			state.stocksTitle = payload
		},
		searchByCodeWb(state, { payload = "" }) {
			state.table.product_id__icontains = payload
		},
		openDynamicModule(state, { payload }) {
			state.dynamicModule.isOpen = payload
		},
		closeDynamicModule(state, { payload }) {
			state.dynamicModule.isOpen = false
		},

		searchSupplierArticle(state, { payload = "" }) {
			state.table.supplier_article__icontains = payload
		},
		checkAll(state, { payload = [] }) {
			state.table.checkBoxSelect[state.table.page] = payload
		},
		changePage(state, { payload = 1 }) {
			state.table.page = payload
		},
		setFilters(state, { payload = {} }) {
			state.table.filters = { ...state.table.filters, ...payload }
		},
		changeTab(state, { payload }) {
			state.table.tabs = state.table.tabs.map(item => ({
				...item,
				active: item.key === payload
			}))
		},
		checkOne(state, { payload }) {
			state.table.checkBoxSelect[state.table.page] = (state.table.checkBoxSelect?.[state.table.page] ?? []).includes(payload)
				? (state.table.checkBoxSelect?.[state.table.page] ?? []).filter(item => item !== payload)
				: [...(state.table.checkBoxSelect?.[state.table.page] ?? []), payload]
		},

		getCabinets(state, { payload = [] }) {
			state.cabinets.results = payload
		},
		getCurrentCabinet(state, { payload }) {
			state.cabinets.current = payload
		},
		cabinetsIsLoading(state, { payload }) {
			state.cabinets.isLoading = payload
		},
		cabinetReset(state) {
			state.cabinets.title = null
			state.cabinets.current = null
			state.cabinets.isLoading = false
			state.cabinets.results = []
		},
		// getStocks(state, { payload = { count: 0, results: [] } }) {
		// 	const { count = 0, results = [] } = payload
		// 	state.stocks.count = count
		// 	state.stocks.results = results
		// },
		getCurrentStock(state, { payload }) {
			state.stocks.current = payload
			state.isAddingStock = false
		},
		stocksIsLoading(state, { payload }) {
			state.stocks.isLoading = payload
		},
		stocksReset(state) {
			state.stocks.title = null
			state.stocks.current = null
			state.stocks.count = 0
			state.stocks.results = []
			state.stocks.isLoading = false
		},
		setIsAddingStock(state, { payload }) {
			state.isAddingStock = payload
		},
		setParams(state, { payload }) {
			const { key, value } = payload
			state.params[key] = value
		},
		setFile(state, { payload }) {
			state.file = payload
		},
		handleTable(state, { payload }) {
			state.isShowTable = payload
			if (payload) {
				state.table.tabs = state.table.tabs.map(item => ({
					...item,
					active: item.key === "participate"
				}))
			}
		}

	},
	extraReducers: builder => {
		builder.addCase(createAnalyticsShares.pending, state => {
			state.stocks.isLoading = true
		})
		builder.addCase(createAnalyticsShares.fulfilled, (state, { payload }) => {
			state.stocks.count = state.stocks.count + 1
			state.stocks.results = [payload, ...state.stocks.results]


			state.stocks.current = payload
			state.params.duration_days = payload?.duration_days ?? null
			state.params.start_date = payload?.start_date ?? null
			state.params.ranking_influence = payload?.ranking_influence ?? null

			state.isAddingStock = false
			state.stocks.isLoading = false
		})
		builder.addCase(createAnalyticsShares.rejected, state => {
			state.stocks.isLoading = false
			state.stocks.error = "Error"
		})





		builder.addCase(getSharesTable.pending, state => {
			state.table.isLoading = true
		})
		builder.addCase(getSharesTable.fulfilled, (state, { payload }) => {
			state.reports = payload.report

			state.table.results = payload?.results ?? []
			state.table.ids = payload.results.map(item => item.product_id)
			state.table.count = payload?.count ?? 0
			state.table.isLoading = false

		})
		builder.addCase(getSharesTable.rejected, state => {
			state.table.isLoading = false
			state.table.error = "Error"
			state.reports = []
			state.table.results = []
			state.table.ids = []
			state.table.count = 0
		})

		builder.addCase(switchDataFromTable.pending, state => {
			state.table.isLoading = true
		})
		builder.addCase(switchDataFromTable.fulfilled, (state, { payload }) => {
			state.table.checkBoxSelect[payload] = []
		})
		builder.addCase(switchDataFromTable.rejected, state => {
			state.table.isLoading = false
		})


		builder.addCase(getStocks.pending, state => {
			state.stocks.isLoading = true
		})
		builder.addCase(getStocks.fulfilled, (state, { payload }) => {
			const { count = 0, results = [] } = payload
			state.stocks.count = count
			state.stocks.results = results
			state.stocks.current = propOr(null, 0, results)
			state.stocks.isLoading = false
		})
		builder.addCase(getStocks.rejected, state => {
			state.stocks.isLoading = false
		})





		builder.addCase(patchAnalyticsShares.pending, state => {
			state.stocks.isLoading = true
		})
		builder.addCase(patchAnalyticsShares.fulfilled, (state, { payload }) => {
			// state.stocks.current = payload
			state.stocks.current = payload
			state.stocks.results = state.stocks.results.map(item => {
				if (item.id == payload.id) {
					return payload
				}
				return item
			})
			state.stocks.isLoading = false
		})
		builder.addCase(patchAnalyticsShares.rejected, state => {
			state.stocks.isLoading = false
		})





		builder.addCase(getSharesDynamicByProduct.pending, state => {
			state.dynamicModule.isLoading = true
		})
		builder.addCase(getSharesDynamicByProduct.fulfilled, (state, { payload }) => {
			const objectKeys = keys(defaultToObj(payload?.dynamic?.[0]))

			state.dynamicModule.checkBoxes = state.dynamicModule.checkBoxes.map(element => ({
				...element,
				disabled: !objectKeys.includes(element.key) || payload?.dynamic.every(item => !item?.[element.key]),
				checked: objectKeys.includes(element.key) && payload?.dynamic.some(item => item?.[element.key] || item?.[element.key] == 0),
			}));

			state.dynamicModule.dynamic = payload?.dynamic ?? []
			state.dynamicModule.promos = payload?.promos ?? []
			state.dynamicModule.isLoading = false
		})
		builder.addCase(getSharesDynamicByProduct.rejected, state => {
			state.dynamicModule.isLoading = false
			state.dynamicModule.dynamic = []
			state.dynamicModule.promos = []
		})
	},
})

export const {
	getCabinets,
	cabinetsIsLoading,
	getCurrentCabinet,
	// getStocks,
	stocksIsLoading,
	getCurrentStock,
	stocksReset,
	setIsAddingStock,
	setParams,
	setFile,
	checkAll,
	checkOne,
	changeTab,
	handleTable,
	setFilters,
	changePage,
	searchByCodeWb,
	searchSupplierArticle,
	openDynamicModule,
	closeDynamicModule,
	handleCheckBox,
	handleStocksTitle,
	handleExport,
	handleExportType,
	resetAllData
} = stocks.actions

export default stocks.reducer
