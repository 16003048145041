import request from "root/js/request";

const ONE_DAY = 24 * 60 * 60 * 1000;

const dateToDateKey = dt =>
  `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`;

const respToRespByDates = resp =>
  resp.reduce((acc, i) => {
    acc[i.date] = i;
    return acc;
  }, {});

const formatDate = date => {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
};

const getEconomyOfShipmentsDynamicOzon = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  category_id__in,
  brand_id__in,
  supplier_article__in,
  barcode__in,
  subject_id__in,
  product_id__in,
  ...rest
}) => {
  date_from = formatDate(new Date(date_from));
  date_to = formatDate(new Date(date_to));

  const prevDateTo = new Date(date_from);
  prevDateTo.setDate(prevDateTo.getDate());

  const prevDateFrom = new Date(prevDateTo.getTime() - (new Date(date_to) - new Date(date_from)));

  const prevDateFromFormatted = formatDate(prevDateFrom);
  const prevDateToFormatted = formatDate(prevDateTo);

  const [currentResponse, prevResponse] = await Promise.all([
    request({
      url: "ozon_analytics/posting_economy_dynamic/",
      params: {
        wb_api_key_ids,
        date_from,
        date_to,
        category_id__in,
        brand_id__in,
        supplier_article__in,
        barcode__in,
        subject_id__in,
        product_id__in,
        ...rest,
      },
    }),
    request({
      url: "ozon_analytics/posting_economy_dynamic/",
      params: {
        wb_api_key_ids,
        date_from: prevDateFromFormatted,
        date_to: prevDateToFormatted,
        category_id__in,
        brand_id__in,
        supplier_article__in,
        barcode__in,
        subject_id__in,
        product_id__in,
        ...rest,
      },
    }),
  ]);

  const curRespByDates = respToRespByDates(currentResponse.data);
  const prevRespByDates = respToRespByDates(prevResponse.data);

  const daysCount = (new Date(date_to) - new Date(date_from)) / ONE_DAY;

  const data = Array.from({ length: daysCount }).map((_, i) => {
    const curDate = new Date(new Date(date_from).getTime() + ONE_DAY * i);
    const prevDate = new Date(prevDateFrom.getTime() + ONE_DAY * i);

    const curDateKey = dateToDateKey(curDate);
    const prevDateKey = dateToDateKey(prevDate);

    return {
      currentDate: curDateKey,
      current: curRespByDates[curDateKey] || null,
      prev: prevRespByDates[prevDateKey] || null,
    };
  });

  return data;
};

export { getEconomyOfShipmentsDynamicOzon };
