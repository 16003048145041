import { createSlice } from "@reduxjs/toolkit";

export const selectParams = {
  category_id__in: [],
  subject_id__in: [],
  brand_id__in: [],
};

const initialState = {
  params: {
    ...selectParams,
  },

  hasJam: false,
  hasSubscription: true,
  activePeriod: 14,
  isAttention: false,
  mergedProducts: [],
  isUpdating: false,
  searchQuery: "",
};

const mergeAnalysis = createSlice({
  name: "mergeAnalysis",
  initialState,
  reducers: {
    setSelectParamByName(state, { payload: { value, name } }) {
      state.params[name] = value;
    },
    setActivePeriod(state, { payload }) {
      state.activePeriod = payload;
    },
    setHasJam(state, { payload }) {
      state.hasJam = payload;
    },
    setIsAttention(state) {
      state.isAttention = !state.isAttention;
    },
    setMergedProducts(state, { payload }) {
      state.mergedProducts = payload;
    },
    setIsUpdating(state, { payload }) {
      state.isUpdating = payload;
    },
    setSearchQuery(state, { payload }) {
      state.searchQuery = payload;
    },
  },
});

export default mergeAnalysis.reducer;
export const {
  setSelectParamByName,
  setActivePeriod,
  setHasJam,
  setIsAttention,
  setMergedProducts,
  setIsUpdating,
  setSearchQuery,
} = mergeAnalysis.actions;
