import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Drawer, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import API from "root/js/api";

import DiagramHint from "root/components/global/diagrama/diagram-hint";
import ProductInTable from "root/components/ProductInTable";
import TableSort from "root/components/TableSort";

import useActiveDots from "root/hooks/useActiveDots";

import { calcOffset, downloadFile, getAxisDate, isMobile, numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

import XlsIcon from "root/icons/Xls";

const PaidStorageCircle = props => {
  const {
    currencyName,
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    dateFrom,
    dateTo,
    subjectsId,
  } = useSelector(state => state.financeLogic);
  const { tab, paidStorageCircle } = props;
  const { rechartsRef } = useActiveDots();
  const WBAPI = new API();

  const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDataCount, setTableDataCount] = useState(0);
  const [coef, setCoef] = useState(null);
  const [tablePage, setTablePage] = useState(1);
  const [tablePerPage, setTablePerPage] = useState(25);
  const [offset, setOffset] = useState(null);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const {
        payload: { date, barcodes_count, warehouse_coef, warehouse_price, fill },
      } = payload[0];

      return (
        <DiagramHint
          date={date}
          value={
            tab === "warehouse_price_value"
              ? warehouse_price !== null
                ? `${numberWithSpaces(warehouse_price.toFixed(0))} ${currencyName}`
                : "нет данных"
              : barcodes_count !== null
              ? `${barcodes_count.toFixed(0)} шт`
              : "нет данных"
          }
          titleValue={
            tab === "warehouse_price_value"
              ? "Сумма хранения"
              : "Количество товара, подлежащего тарифицированию"
          }
          secondTitleValue="Коэффициент хранения"
          secondValue={warehouse_coef !== null ? `${warehouse_coef}` : "нет данных"}
        />
      );
    }

    return null;
  };

  const getTableData = async () => {
    setIsLoading(true);
    if (offset === 0) setTablePage(1);
    try {
      const res = await WBAPI.paidStorageWarehouseDetailsTable({
        key: keys.join(),
        dateFrom,
        dateTo,
        ids: categoryId.join(),
        brandsId: brandIds.join(),
        aritucl: JSON.stringify(articulIds),
        barcod: barcodeIds.join(),
        group_by: "details",
        limit: tablePerPage,
        offset: offset !== null ? offset : calcOffset(tablePage, tablePerPage),
        subject: subjectsId.join(),
        warehouse_coef: coef,
      });
      setOffset(null);
      setTableData(res.results);
      setTableDataCount(res.count);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onPageChange = (e, page) => {
    setTablePage(page);
  };

  const onPerPageChange = perPage => {
    setTablePerPage(perPage);
    setTablePage(1);
  };

  const handleScatterClick = async payload => {
    setIsDetailPopupOpen(true);
    setCoef(payload.warehouse_coef);
    setTablePage(1);
    setTablePerPage(25);
  };

  const exportToExcel = async () => {
    try {
      setIsExporting(true);
      const res = await WBAPI.paidStorageWarehouseDetailsTableDownload({
        key: keys.join(),
        dateFrom,
        dateTo,
        ids: categoryId.join(),
        brandsId: brandIds.join(),
        aritucl: JSON.stringify(articulIds),
        barcod: barcodeIds.join(),
        group_by: "details",
        subject: subjectsId.join(),
        warehouse_coef: coef,
      });

      downloadFile(res.url, `Автореклама`);
    } catch (err) {
      console.error(err);
    }
    setIsExporting(false);
  };

  const columns = [
    {
      dataIndex: "office_name",
      title: "Склад",
      render: value => value,
    },
    {
      dataIndex: "date",
      title: "Дата",
      sticky: !isMobile,
      render: value => getAxisDate(value),
    },
    {
      dataIndex: "title",
      title: "Товар",
      sticky: !isMobile,
      render: ({ name, image, id }) => <ProductInTable image={image} text={name} articule={id} />,
    },
    {
      dataIndex: "volume",
      title: "Объем товара, л",
      render: value => value,
    },
    {
      title: `Стоимость хранения, ${currencyName}`,
      dataIndex: "warehouse_price",
      render: value => value,
    },
  ];

  useEffect(() => {
    if (coef || coef === 0) {
      getTableData();
    }
  }, [coef, tablePage, tablePerPage]);

  // const getUniqueValues = (data, key) => {
  //   return [...new Set(data.map(item => item[key]))];
  // };
  //
  // const uniqueValues = getUniqueValues(paidStorageCircle, tab === "warehouse_price_value" ? "warehouse_price" : "barcodes_count");
  // const minVal = Math.min(...uniqueValues);
  // const maxVal = Math.max(...uniqueValues);
  // const yDomain = [minVal - (minVal * 0.1) , maxVal + (maxVal * 0.1)];
  return (
    <div className={styles.cicleBox}>
      <div className={styles.title}>
        <h4>
          {tab === "warehouse_price_value"
            ? "Разброс стоимости хранения по коэффициентам хранения"
            : "Разброс количества товара, подлежащего тарифицированию, по коэффициентам хранения"}
        </h4>
      </div>
      <Box mt={1.5} height={"100%"}>
        <div className={styles.scrollableBlock}>
          <ResponsiveContainer
            className={styles.limitedWidth}
            width="100%"
            minWidth={350}
            height="100%"
            ref={rechartsRef}
            debounce={320}>
            <ScatterChart
              width={350}
              height={230}
              data={paidStorageCircle}
              margin={{
                top: 10,
                right: 15,
                bottom: 0,
                left: 10,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis allowDataOverflow dataKey="warehouse_coef" type="number" stroke="#798097" />
              <YAxis
                allowDataOverflow
                dataKey={tab === "warehouse_price_value" ? "warehouse_price" : "barcodes_count"}
                tickFormatter={value =>
                  tab === "warehouse_price_value"
                    ? `${numberWithSpaces(value.toFixed(0))} ${currencyName}`
                    : `${numberWithSpaces(value.toFixed(0))} шт`
                }
                type="number"
                width={85}
                yAxisId="1"
                stroke="#798097"
              />
              <Tooltip cursor={false} content={<CustomTooltip />} />
              <Scatter
                yAxisId="1"
                type="natural"
                dataKey={tab === "warehouse_price_value" ? "warehouse_price" : "barcodes_count"}
                stroke="#1E85FF"
                fill="#1E85FF"
                animationDuration={300}
                onClick={({ payload }) => handleScatterClick(payload)}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </Box>

      <Drawer
        anchor="right"
        open={isDetailPopupOpen}
        onClose={() => setIsDetailPopupOpen(false)}
        className={styles.drawerWrap}>
        <Box px={1} py={1} display="flex" justifyContent="end" className={styles.closeIconBlock}>
          <IconButton
            onClick={() => setIsDetailPopupOpen(false)}
            sx={{
              zIndex: 12,
            }}>
            <CloseIcon fill="gray" />
          </IconButton>
        </Box>

        <Button
          variant="outlined"
          iconAfter={XlsIcon}
          isLoading={isExporting}
          disabled={isExporting}
          className={styles.btnExport}
          loadingText="Отчет формируется..."
          onClick={exportToExcel}>
          Выгрузить в Excel
        </Button>

        <Box className={styles.tableWrap}>
          {tableData && (
            <TableSort
              columns={columns}
              data={tableData.map(item => ({
                ...item,
                title: {
                  image: item.product_thumbnail,
                  name: item.product_name,
                  id: item.product_id,
                },
                volume: item.volume !== null ? `${item.volume.toFixed(1)} л` : "-",
                warehouse_price:
                  item.warehouse_price !== null
                    ? `${
                        item.warehouse_price && numberWithSpaces(item.warehouse_price.toFixed(2))
                      } ${currencyName}`
                    : "-",
                button: "",
              }))}
              isLoading={isLoading}
              name="details"
              showPerPageWhenSwitching
              page={tablePage}
              perPage={tablePerPage}
              onPageChange={onPageChange}
              onPerPageChange={onPerPageChange}
              total={tableDataCount}
              isCalcHeight
            />
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default PaidStorageCircle;
