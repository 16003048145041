import { format } from "date-fns";
import { ru } from "date-fns/locale";

import { daysMS, months } from "./constant";

// -------------------------------------------------------------
const getDateByDayBack = n => new Date(Date.now() - n * 24 * 3600 * 1000);
// -------------------------------------------------------------
const getFrontDate = (userDate = "", viewOption = "dd.MM.yyyy") => {
  try {
    const date = typeof userDate === "object" ? userDate : new Date(userDate);
    return format(date, viewOption, {
      locale: ru,
    });
  } catch (err) {
    console.error("Передано некорректное значение даты, root/utils/date, getFrontDate");
    return "";
  }
};
// -------------------------------------------------------------
const getServerDate = (userDate = "") => {
  try {
    const date = typeof userDate === "object" ? userDate : new Date(userDate);
    return format(date, "yyyy-MM-dd");
  } catch (err) {
    console.error("Передано некорректное значение даты, root/utils/date, getServerDate");
  }
};

// -------------------------------------------------------------------------
function formatToISOWithTimezone(date) {
  const tzOffset = 3 * 60; // Сдвиг +03:00 в минутах
  const tzHours = Math.floor(tzOffset / 60)
    .toString()
    .padStart(2, "0");
  const tzMinutes = (tzOffset % 60).toString().padStart(2, "0");
  const isoString = new Date(date).toISOString(); // Формат в UTC
  const formatted = isoString.replace("Z", `+${tzHours}:${tzMinutes}`);
  return formatted;
}
// -------------------------------------------------------------------------
function getPreviousPeriod(period) {
  const [startDate, endDate] = period.map(i => new Date(i));
  const periodLength = Math.floor((endDate - startDate) / daysMS) + 1;
  return [
    new Date(startDate - periodLength * daysMS),
    new Date(endDate - periodLength * daysMS),
  ].map(i => formatToISOWithTimezone(i));
}
// -------------------------------------------------------------------------
const getMonthDate = (date = "11-2024") => {
  const [month, year] = date.split("-");
  return `${months.find(i => i.id == month)?.title || month} ${year}`;
};

export { getFrontDate, getServerDate, getDateByDayBack, getPreviousPeriod, getMonthDate };
