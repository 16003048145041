import request from "root/js/request";

export const getKeywordsPositionData = async ({ ordering, run_id, limit, offset }) => {
  const response = await request({
    url: `/parsers/keywords_position/get_data/`,
    method: "get",
    params: {
      ordering,
      run_id,
      limit,
      offset,
    },
  });
  return response;
};
