import request from "root/js/request";

export const postAdvertsUpdateList = async ({ cabinet_id }) => {
  const response = await request({
    url: `/analytics/adverts/update_list/`,
    method: "post",
    params: {
      cabinet_id,
    },
  });
  return response;
};

export const getAdvertsUpdateList = async ({ cabinet_id, run_id }) => {
  const response = await request({
    url: `/analytics/adverts/update_list/`,
    method: "get",
    params: {
      cabinet_id,
      run_id,
    },
  });
  return response;
};
