import React from 'react'

import styles from "./index.module.scss"
const HintBoxCircle = (props) => {
  const { circleColor, name, value, } = props
  return (


    <div className={styles.legendsBox}>

      <div>
        <div className={styles.legends}>{
          circleColor && (
            <div style={{ background: circleColor }} className={styles.circle}></div>
          )
        }

          <div className={styles.title}>
            {name}:
          </div>
        </div>
        <div className={styles.value}>  {value} </div>

      </div>
    </div>






  )
}

export default HintBoxCircle
