import React, { Suspense } from "react";

import Paper from "@mui/material/Paper";

import SkeletonWhite from "root/components/SkeletonWhite";
import Tab from "root/components/Tab";
import TabPanel from "root/components/TabPanel";
import Tabs from "root/components/Tabs";

import { clearAllDate } from "../../../store/financialLogic/financialLogicSlice";
import DatePickerLastWeek from "../date-picker-last-week";
import SelectItems from "../select-items";

import styles from "./index.module.scss";
const PagesTab = props => {
  const {
    tab,
    pages,
    onChange,
    isDisabledWeek,
    setIsDisabledWeek,
    withoutborder,
    isLogicPage,
    clearDate,
    mounths,
    setMounths,
    padding = "10px 12px",
    ...res
  } = props;

  return (
    <div className={styles.pagesContainer}>
      <>
        <div className={styles.menuTab} style={{ padding: padding }}>
          <Tabs value={tab} onChange={(e, tab) => onChange(tab)}>
            {pages.map((item, index) => (
              <Tab
                key={`${item.keyIndex}-${item.title}`}
                value={index}
                label={item.title}
                trip={item.trip}
              />
            ))}
          </Tabs>
        </div>
      </>
      {isLogicPage && (
        <>
          <SelectItems
            clearDate={clearDate}
            mounths={mounths}
            setMounths={setMounths}
            isPromotionPage={tab === 4}
            isPaidStoragePage={tab === 3}
          />
          <div>
            <DatePickerLastWeek
              pages={pages}
              tab={tab}
              clearDate={clearDate}
              mounths={mounths}
              setMounths={setMounths}
              isDisabledWeek={isDisabledWeek}
            />
          </div>
        </>
      )}
      {pages.map((item, index) => (
        <Suspense
          key={`${item.keyIndex}-${item.title}-${index}`}
          fallback={<SkeletonWhite height={"300px"} />}>
          <TabPanel value={tab} index={index}>
            <item.component {...res} setIsDisabledWeek={setIsDisabledWeek} tab={tab} />
          </TabPanel>
        </Suspense>
      ))}
    </div>
  );
};

export default PagesTab;
