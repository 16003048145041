// Для добавления плашки к секции необходимо указать её id
// Для дочернего элемента секции - страницы указывается url
// --------------------------------------------------------
const betaPages = {
  sections: [],
  pages: [
    // externalAnalysisSection | "Анализ внешки"
    "/bloggers-posts-selection", // "Отборщик публикаций"
    "/bloggers-profiles-selection", // "Отборщик блогеров"
    "/blogger-card", // "Анализ блогера"
  ],
};

const newPages = {
  sections: [
    // "growthLeaders", // Лидеры роста
    // "niches_and_trends", // Ниши и тренды
  ],
  pages: [
    // growthLeadersSection | "Лидеры роста"
    // "/growth_leaders/products", // "По товарам"
    // "/growth_leaders/brands", // "По товарам"
    // "/growth_leaders/sellers", // "По товарам"
    // "/growth_leaders/categories", // "По товарам"
    // nichesAndTrendsSection | "Ниши и тренды"
    "/keywords-summary-v2", // "Оракул запросов"
    // externalAnalysisSection | "Анализ внешки"
    "/all-bloggers-search", // "Поиск трафика другие МП"
    "/bloggers-posts-selection", // "Отборщик публикаций"
    "/bloggers-profiles-selection", // "Отборщик блогеров"
    "/blogger-card", // "Анализ блогера"
    // seoSection | "SEO"
    "/keywords-position", // "Индексация запросов"
  ],
};

export { betaPages, newPages };
