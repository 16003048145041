import React from "react";

import Checkbox from "@mui/material/Checkbox";

import styles from "./index.module.scss";

const CheckboxesDiagram = ({ checkboxesConfig }) => {
  return (
    <>
      {checkboxesConfig.map(
        ({ label, condition, checked, onChange, className, color }) =>
          condition && (
            <div key={label} onClick={() => onChange(prev => !prev)} className={styles.checkBox}>
              <Checkbox
                checked={checked}
                className={className}
                style={{ color: color, padding: "10px 0" }}
              />
              <span className={styles.text}>{label}</span>
            </div>
          )
      )}
    </>
  );
};

export default CheckboxesDiagram;
