import request from "root/js/request";

import { convertToMoscowTimeString } from "root/js/utils";

const getTradeEconomyTable = async ({
  wb_api_key_ids,
  cabinet_id__in = wb_api_key_ids,
  product_cost__isnull,
  date_from,
  date_to,
  product_ids,
  product_id__icontains,
  product_id__in,
  barcode__in,
  brand_id_in,
  category_id__in,
  subject_id__in,
  group_by,
  order_by,
  limit = 1000,
  offset = 0,
  q,
  supplier_article,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/trade_economy_table/",
    params: {
      cabinet_id__in,
      wb_api_key_ids: wb_api_key_ids,
      product_cost__isnull,
      date_from,
      date_to,
      product_id__icontains,
      product_id__in,
      barcode__in,
      brand_id_in,
      category_id__in,
      subject_id__in,
      group_by,
      order_by,
      limit,
      offset,
      q,
      supplier_article,
      ...rest,
    },
  });
  return response.data;
};

export { getTradeEconomyTable };
