import request from "root/js/request";

export const getAnaliticsCommissionDynamicV2 = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  advert_id__in,
  category_id__in,
  brand_id__in,
  supplier_article__in,
  product_id__in,
  barcode__in,
  subject_id__in,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/commission_dynamic_v2/",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      advert_id__in,
      category_id__in,
      brand_id__in,
      supplier_article__in,
      product_id__in,
      barcode__in,
      subject_id__in,
      ...rest,
    },
  });
  return response.data;
};
