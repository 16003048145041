import { createSlice } from "@reduxjs/toolkit";

// Это надо удалить, просто пропсами передай
const initialState = {
  //---------// Unit-Economy logic
  skuFiltersParam: {},
  skuOption: "showAll",
};

const innerEconomicGlobal = createSlice({
  name: "innerEconomicGlobal",
  initialState,
  reducers: {
    //---------// Unit-Economy logic
    setSkuOption(state, { payload: { value } }) {
      state.skuOption = value;
    },
    setSkuFiltersParam(state, { payload: { name, value } }) {
      state.skuFiltersParam[name] = value;
    },
    clearSkuFiltersParam(state) {
      state.skuFiltersParam = {};
    },
  },
  //---------//
});

export default innerEconomicGlobal.reducer;

export const { setSkuOption, setSkuFiltersParam, clearSkuFiltersParam } =
  innerEconomicGlobal.actions;
