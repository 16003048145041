import request from "root/js/request";

export const getKeywordsPositionRunId = async ({ product_id, geolocation_id, keywords, ordering }) => {
  const response = await request({
    url: `/parsers/keywords_position/`,
    method: "post",
    data: {
      product_id,
      geolocation_id,
      keywords,
      ordering,
    },
  });
  return response;
};
