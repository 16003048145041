import request from "root/js/request";

export const getKeywordsSummaryV2Subjects = async ({ search, limit, offset }) => {
  const response = await request({
    url: `/wb_dynamic/keywords_summary_v2/subjects/`,
    method: "GET",
    params: {
      // Тут вышло недопонимание с беком, почему то search предназначен для ключевых фраз
      // входящих в предмет
      subject_name__icontains: search,
      limit,
      offset,
    },
  });
  return response.data;
};
