import request from "root/js/request";

const getWBtokens = async ({ CABINET_ID }) => {
  const response = await request({
    url: `/company/wb_cabinets/${CABINET_ID}/tokens/`,
    method: "get",
  });
  return response.data;
};

const addWBToken = async ({ CABINET_ID, token, types }) => {
  const response = await request({
    url: `/company/wb_cabinets/${CABINET_ID}/tokens/`,
    method: "post",
    data: {
      token,
      types,
    },
  });
  return response.data;
};
export { getWBtokens, addWBToken };
