import React, { Suspense, useEffect } from "react";
import { useState } from "react";

import Paper from "@mui/material/Paper";
import classNames from "classnames";
import { batch, useDispatch, useSelector } from "react-redux";
import { REQUESTS_DEBOUNCE_TIME } from "root/js/constants";
import {
  CommisionDiagrams,
  CommisionTopProducts,
  CommisionWidgets,
  CommisionWidgets2,
  CommissionDiscountByCategories,
} from "root/store/financialLogic/action";
import { useDebouncedCallback } from "use-debounce";

import API from "root/js/api";

import TabSelect from "root/components/global/page-tab/nav-bar-select";
import LinearLoading from "root/components/LinearLoading";
import TabPanel from "root/components/TabPanel";
import { commissionTopTabs, tablesCommision } from "root/pages/financial-logic/info";

import useNavigationMemory from "root/hooks/useNavigationMemory";

import { getLastWeek } from "../../../helpers";
import CommisionCards from "../pages/commision-cards";
import CommissionDiagram from "../pages/commision-diagram";
import CommisionStacked from "../pages/commision-stacked";
import CommisionTopProductsChart from "../pages/commision-top-products";

import styles from "./index.module.scss";

const CommissionStructure = ({ setIsDisabledWeek }) => {
  const dispatch = useDispatch();

  const lastWeek = getLastWeek();
  const WBAPI = new API();

  const {
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    isLoadingCommisionWidgets,
    subjectsId,
    dateFrom,
    dateTo,
    commisionWidgets,
    isLoadingCommisionDiagrams,
    commisionDiagram,
    commisionCircle,
    currencyName,
    commisionTopProducts,
    isLoadingCommisionTopProducts,
    commisionDiscount,
    isLoadingCommisionDiscount,
  } = useSelector(state => state.financeLogic);

  const [tab, setTab] = useState(0);
  const [tableTab, setTableTab] = useState(4);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);

  const { changeParams } = useNavigationMemory();

  const onChangeTab = tab => {
    setTab(tab);
  };

  const checkWeek = async () => {
    try {
      const res = await WBAPI.commisionTable({
        key: keys.join(),
        value: "category_id",
        dateFrom: lastWeek.dateFrom,
        dateTo: lastWeek.dateTo,
      });
      res?.count > 0 ? setIsDisabledWeek?.(false) : setIsDisabledWeek?.(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (keys?.length > 0) checkWeek();
  }, [keys, window.location.pathname]);

  const fetchDataDebounce = useDebouncedCallback(async () => {
    setIsLoadingProduct(true);
    batch(() => {
      dispatch(
        CommissionDiscountByCategories({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
        })
      );

      dispatch(
        CommisionWidgets({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
        })
      );
      dispatch(
        CommisionWidgets2({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
        })
      );
      dispatch(
        CommisionDiagrams({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
        })
      );

      dispatch(
        CommisionTopProducts({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
        })
      );
    });

    setIsLoadingProduct(false);
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (
      keys.length > 0 &&
      Object.values(commisionWidgets).length === 0 &&
      commisionDiagram.length === 0 &&
      dateFrom
    ) {
      setIsLoadingProduct(true);
      fetchDataDebounce.callback();
    }
  }, [keys, categoryId, brandIds, articulIds, dateFrom, subjectsId]);

  useEffect(() => {
    changeParams("table", tableTab);
  }, [tableTab]);

  const onChageTabTable = tab => {
    setTableTab(tab);
  };

  function hasNegativeValue(data) {
    return data.reduce((hasNegative, item) => hasNegative || item.precent < 0, false);
  }
  const hasNegative = hasNegativeValue(commisionCircle);
  console.log("commisionTopProducts", commisionTopProducts);
  return (
    <div className={styles.commissionStructureContainer}>
      <div className={styles.flexableContainer}>
        <div className={styles.leftBlock}>
          <div className={styles.cardsAndDiagramBox}>
            {isLoadingCommisionWidgets || Object.values(commisionWidgets).length === 0 ? (
              <div className={classNames(styles.boxLoader, styles.widgetSize)}>
                <LinearLoading className={styles.loader} />
              </div>
            ) : (
              <CommisionCards />
            )}

            {isLoadingCommisionDiscount ? (
              <div className={classNames(styles.boxLoader, styles.commisionSize)}>
                <LinearLoading className={styles.loader} />
              </div>
            ) : (
              <CommisionStacked
                title="Средние значения скидки WB в разрезе категорий"
                commisionCircle={commisionCircle}
                data={commisionDiscount}
                currencyName={currencyName}
                hasNegative={hasNegative}
              />
            )}
          </div>

          <div
            className={classNames(
              styles.containerDiagrams,
              !hasNegative ? styles.positiveChartProps : ""
            )}>
            {isLoadingCommisionDiagrams || commisionDiagram?.length === 0 ? (
              <div className={classNames(styles.boxLoader, styles.diagramSize)}>
                <LinearLoading className={styles.loader} />
              </div>
            ) : (
              <CommissionDiagram commisionDiagram={commisionDiagram} />
            )}
          </div>

          <div className={styles.commissionTopBox}>
            <TabSelect data={commissionTopTabs} onChange={onChangeTab} tab={tab} />
            {isLoadingCommisionTopProducts || isLoadingProduct ? (
              <div className={styles.boxLoader}>
                <LinearLoading className={styles.loader} />
              </div>
            ) : (
              <CommisionTopProductsChart tab={tab} commisionTopProducts={commisionTopProducts} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Paper elevation={0}>
          {tablesCommision.map((item, index) => (
            <Suspense key={item.keyIndex} fallback={<></>}>
              <TabPanel key={item.keyIndex} value={tableTab} index={index}>
                <item.component onChageTabTable={onChageTabTable} tableTab={tableTab} />
              </TabPanel>
            </Suspense>
          ))}
        </Paper>
      </div>
    </div>
  );
};

export default CommissionStructure;
