import React from "react";

import { Box, Typography } from "@mui/material";

import Image from "root/components/Image";

import directorImage from "./staff.jpg";

// ------------------------------------------------------
const DirectorBar = () => {
  return (
    <Box display={"flex"} gap={1} alignItems={"center"}>
      <Box>
        <Image src={directorImage} />
      </Box>
      <Box>
        <Typography>Дмитрий</Typography>
        <Typography>IT-директор Wildbox</Typography>
      </Box>
    </Box>
  );
};

export default DirectorBar;
