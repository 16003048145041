import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "root/js/api";

import { calcDate } from "root/pages/financial-logic/helpers";

import { defaultToArr } from "root/js/utils";

const WBAPI = new API();
export const GetFinancialLogicKey = createAsyncThunk("get/key", async thunkAPI => {
  try {
    const keys = await WBAPI.getCompanyApiKey();

    return defaultToArr(keys);
  } catch (e) {
    return thunkAPI.rejectWithValue("Error fetching key");
  }
});

export const GetFinancialLogicBrand = createAsyncThunk(
  "get/brand",
  async ({ keys, dateTo, dateFrom, categoryId, subjectsId, brand, barcodeIds }, thunkAPI) => {
    const key = keys.join();
    const category = categoryId.join();
    const subject = subjectsId.join();
    const barcode = barcodeIds.join();
    try {
      const response = await WBAPI.getBrandFinancialLogic({
        key,
        dateTo,
        dateFrom,
        subject,
        category,
        barcode,
      });
      return { response, brand };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching brand list");
    }
  }
);

export const GetFinancialLogicBrandForPromotion = createAsyncThunk(
  "get/brandPromotion",
  async ({ keys, dateTo, dateFrom, categoryId, subjectsId, brand }, thunkAPI) => {
    const key = keys.join();
    const category = categoryId.join();
    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getBrandFinancialLogicForPromotion({
        key,
        dateTo,
        dateFrom,
        subject,
        category,
      });
      return { response, brand };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching brand list");
    }
  }
);

export const GetFinancialLogicBrandForPaidStorage = createAsyncThunk(
  "get/brandPaidStorage",
  async ({ keys, dateTo, dateFrom, categoryId, subjectsId, brand }, thunkAPI) => {
    const key = keys.join();
    const category = categoryId.join();
    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getBrandFinancialLogicForPaidStorage({
        key,
        dateTo,
        dateFrom,
        subject,
        category,
      });
      return { response, brand };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching brand list");
    }
  }
);

export const GetFinancialLogicCategories = createAsyncThunk(
  "get/categories",
  async ({ keys, dateTo, dateFrom, subjectsId, brandIds, categories, barcodeIds }, thunkAPI) => {
    const key = keys.join();
    const subject = subjectsId.join();
    const brand = brandIds.join();
    const barcode = barcodeIds.join();
    try {
      const response = await WBAPI.getBrandFinancialCategories({
        key,
        dateFrom,
        dateTo,
        subject,
        brand,
        barcode,
      });
      return { response, categories };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetProductSearch = createAsyncThunk(
  "get/product",
  async ({ keys, dateTo, dateFrom, subjectsId, categoryId, brandIds, articulIds }, thunkAPI) => {
    const key = keys.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const category = categoryId.join();
    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getProductSearch({
        key,
        dateFrom,
        dateTo,
        brandsId,
        aritucl,
        category,
        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetProductSearchForPromotion = createAsyncThunk(
  "get/productPromotion",
  async ({ keys, dateTo, dateFrom, subjectsId, categoryId, brandIds, articulIds }, thunkAPI) => {
    const key = keys.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const category = categoryId.join();
    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getProductSearchForPromotion({
        key,
        dateFrom,
        dateTo,
        brandsId,
        aritucl,
        category,
        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetProductSearchForPaidStorage = createAsyncThunk(
  "get/productPaidStorage",
  async ({ keys, dateTo, dateFrom, subjectsId, categoryId, brandIds, articulIds }, thunkAPI) => {
    const key = keys.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const category = categoryId.join();
    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getProductSearchForPaidStorage({
        key,
        dateFrom,
        dateTo,
        brandsId,
        aritucl,
        category,
        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetBarcodesSearch = createAsyncThunk(
  "get/barcodes",
  async (
    { keys, text, dateTo, dateFrom, subjectsId, categoryId, brandIds, articulIds, barcode },
    thunkAPI
  ) => {
    const key = keys.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const category = categoryId.join();
    const subject = subjectsId.join();

    try {
      const response = await WBAPI.getBarcodesSearch({
        key,
        text,
        dateTo,
        dateFrom,
        brandsId,
        aritucl,
        category,
        subject,
      });

      return { response, barcode };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetBarcodesSearchForPaidStorage = createAsyncThunk(
  "get/barcodesPaidStorage",
  async (
    { keys, text, dateTo, dateFrom, subjectsId, categoryId, brandIds, articulIds, barcode },
    thunkAPI
  ) => {
    const key = keys.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const category = categoryId.join();
    const subject = subjectsId.join();

    try {
      const response = await WBAPI.getBarcodesSearchForPaidStorage({
        key,
        text,
        dateTo,
        dateFrom,
        brandsId,
        aritucl,
        category,
        subject,
      });

      return { response, barcode };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetSupplierArticles = createAsyncThunk(
  "get/article",
  async ({ keys, dateTo, dateFrom, subjectsId, categoryId, brandIds }, thunkAPI) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getArticle({
        key,
        dateFrom,
        dateTo,
        subject,

        brandsId,
        ids,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetSupplierArticlesForPromotion = createAsyncThunk(
  "get/articlePromotion",
  async ({ keys, dateTo, dateFrom, subjectsId, categoryId, brandIds }, thunkAPI) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getArticleForPromotion({
        key,
        dateFrom,
        dateTo,
        subject,

        brandsId,
        ids,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetSupplierArticlesForPaidStorage = createAsyncThunk(
  "get/articlePaidStorage",
  async ({ keys, dateTo, dateFrom, subjectsId, categoryId, brandIds }, thunkAPI) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getArticleForPaidStorage({
        key,
        dateFrom,
        dateTo,
        subject,

        brandsId,
        ids,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetAdvertsByDates = createAsyncThunk(
  "get/advertsByDates",
  async ({ keys, dateTo, dateFrom, subjectsId, articleIds, brandIds }, thunkAPI) => {
    const key = keys.join();
    const articleid = JSON.stringify(articleIds);
    const brandsId = brandIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getAdvertsByDates({
        key,
        dateFrom,
        dateTo,
        subject,

        brandsId,
        articleid,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetSubjects = createAsyncThunk(
  "get/subject",
  async ({ keys, dateTo, dateFrom, categoryId, brandIds, subject, barcodeIds }, thunkAPI) => {
    const key = keys.join();
    const category = categoryId.join();
    const brand = brandIds.join();
    const barcode = barcodeIds.join();
    try {
      const response = await WBAPI.getSubjects({ key, dateFrom, dateTo, category, brand, barcode });

      return { response, subject };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetSubjectsForPromotion = createAsyncThunk(
  "get/subjectPromotion",
  async ({ keys, dateTo, dateFrom, categoryId, brandIds, subject }, thunkAPI) => {
    const key = keys.join();
    const category = categoryId.join();
    const brand = brandIds.join();
    try {
      const response = await WBAPI.getSubjectsForPromotion({
        key,
        dateFrom,
        dateTo,
        category,
        brand,
      });

      return { response, subject };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetSubjectsForPaidStorage = createAsyncThunk(
  "get/subjectPaidStorage",
  async ({ keys, dateTo, dateFrom, categoryId, brandIds, subject }, thunkAPI) => {
    const key = keys.join();
    const category = categoryId.join();
    const brand = brandIds.join();
    try {
      const response = await WBAPI.getSubjectsForPaidStorage({
        key,
        dateFrom,
        dateTo,
        category,
        brand,
      });

      return { response, subject };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching categories list");
    }
  }
);

export const GetKeyIndicatorsWidgets = createAsyncThunk(
  "get/indicators/widgets",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.getKeyIndicatorsWidgets({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const keyIndicatorsOtherWbStructure = createAsyncThunk(
  "/other/wb",
  async ({ keys, dateFrom, dateTo }, thunkAPI) => {
    const key = keys.join();

    try {
      const response = await WBAPI.keyIndicatorsOtherWBStructure({ key, dateFrom, dateTo });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const KeyIndicatorsDynamic = createAsyncThunk(
  "get/dynamic",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const article = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    try {
      const ONE_DAY = 24 * 60 * 60 * 1000;
      const prevDateTo = calcDate(new Date(dateFrom), 1);
      const prevDateFrom = new Date(prevDateTo - (dateTo - dateFrom));

      const currentRequest = WBAPI.keyIndicatorsDynamic({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        article,
        barcod,

        subject,
      });
      const prevRequest = WBAPI.keyIndicatorsDynamic({
        key,
        dateFrom: prevDateFrom,
        dateTo: prevDateTo,
        ids,
        brandsId,
        article,
        barcod,

        subject,
      });
      const [currentResponse, prevResponse] = await Promise.all([currentRequest, prevRequest]);
      const respToRespByDates = resp =>
        resp.reduce((acc, i) => {
          acc[i.date] = i;
          return acc;
        }, {});

      const curRespByDates = respToRespByDates(currentResponse);
      const prevRespByDates = respToRespByDates(prevResponse);

      const dateToDateKey = dt =>
        `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(
          -2
        )}`;
      const daysCount = (dateTo - dateFrom) / ONE_DAY;

      const data = Array.from({ length: daysCount }).map((_, i) => {
        const curDate = new Date(dateFrom.getTime() + ONE_DAY * i);
        const prevDate = new Date(prevDateFrom.getTime() + ONE_DAY * i);

        const curDateKey = dateToDateKey(curDate);
        const prevDateKey = dateToDateKey(prevDate);

        return {
          currentDate: curDateKey,
          // prevDate:prevDateKey,
          current: curRespByDates[curDateKey],
          prev: prevRespByDates[prevDateKey],
        };
      });

      return data;
    } catch (e) {
      console.log(e.message);
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const KeyIndicatorsServiceStructrueWB = createAsyncThunk(
  "get/WB",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const article = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.keyIndicatorsServiceStructrueWB({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        article,
        barcod,

        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const KeyIndicatorsTable = createAsyncThunk(
  "table",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      value,
      sort,
      limit,
      offset,
      subjectsId,
      q,
      product_id__icontains,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.keyIndicatorsTable({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        value,
        sort,
        limit,
        offset,
        subject,
        q,
        product_id__icontains,
      });

      return { value, response };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const CommissionDiscountByCategories = createAsyncThunk(
  "commision/commissionDiscountByCategories",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    try {
      const response = await WBAPI.commissionDiscountByCategories({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const CommisionWidgets = createAsyncThunk(
  "commision/widget",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    try {
      const response = await WBAPI.commisionWidgets({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const CommisionWidgets2 = createAsyncThunk(
  "commision/widget2",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    try {
      const response = await WBAPI.commisionWidgets2({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const CommisionTopProducts = createAsyncThunk(
  "commision/top",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const subject = subjectsId.join();

    const article = JSON.stringify(articulIds);
    const barcode = barcodeIds.join();

    try {
      const response = await WBAPI.commisionTopProducts({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        subject,

        article,
        barcode,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const CommisionDiagrams = createAsyncThunk(
  "commision/diagram",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const ONE_DAY = 24 * 60 * 60 * 1000;
    const prevDateTo = calcDate(new Date(dateFrom), 1);
    const prevDateFrom = new Date(prevDateTo - (dateTo - dateFrom));

    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    try {
      const currentRequest = WBAPI.commisionDiagram({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
      });
      const prevRequest = WBAPI.commisionDiagram({
        key,
        dateFrom: prevDateFrom,
        dateTo: prevDateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
      });

      const [currentResponse, prevResponse] = await Promise.all([currentRequest, prevRequest]);

      const respToRespByDates = resp =>
        resp.reduce((acc, i) => {
          acc[i.date] = i;
          return acc;
        }, {});

      const curRespByDates = respToRespByDates(currentResponse);
      const prevRespByDates = respToRespByDates(prevResponse);

      const dateToDateKey = dt =>
        `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(
          -2
        )}`;
      const daysCount = (dateTo - dateFrom) / ONE_DAY;

      const data = Array.from({ length: daysCount }).map((_, i) => {
        const curDate = new Date(dateFrom.getTime() + ONE_DAY * i);
        const prevDate = new Date(prevDateFrom.getTime() + ONE_DAY * i);

        const curDateKey = dateToDateKey(curDate);
        const prevDateKey = dateToDateKey(prevDate);

        return {
          currentDate: curDateKey,
          current: curRespByDates[curDateKey],
          prev: prevRespByDates[prevDateKey],
        };
      });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const CommissionDiscountTopDetails = createAsyncThunk(
  "commision/commissionDiscountTopDetails",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,
      subjectsId,
      discountGroup,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    try {
      const response = await WBAPI.commissionDiscountTopDetails({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        discountGroup,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);

export const CommisionTable = createAsyncThunk(
  "commision/table",
  async (
    {
      keys,
      limit,
      offset,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      value,
      subjectsId,
      sort,
      q,
      product_id__icontains,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    try {
      const response = await WBAPI.commisionTable({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        value,
        sort,
        limit,
        offset,
        subject,
        q,
        product_id__icontains,
      });

      return { value, response };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgest list");
    }
  }
);

export const RiteReturnsWidgets = createAsyncThunk(
  "riter/widgets",
  async (
    { keys, dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.riterReturnsWidgets({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgest list");
    }
  }
);

export const RiteReturnDiagrama = createAsyncThunk(
  "diagrama",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      value,
      subjectsId,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.riterReturnsDiagram({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        value,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgest list");
    }
  }
);
export const RiterReturnDynamicProduct = createAsyncThunk(
  "product/dynamic",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      value,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    try {
      const response = await WBAPI.riterReturnsDiagramProduct({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        value,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgest list");
    }
  }
);

export const RiterReturnOffices = createAsyncThunk(
  "offices",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      value,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    try {
      const response = await WBAPI.riteReturnOffices({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        value,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgest list");
    }
  }
);

export const RiterReturnTables = createAsyncThunk(
  "table/return",
  async (
    {
      keys,
      limit,
      offset,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      value,
      subjectsId,
      sort,
      q,
      product_id__icontains,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    try {
      const response = await WBAPI.riteReturnTables({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        value,
        sort,
        limit,
        offset,
        subject,
        q,
        product_id__icontains,
      });

      return { value, response };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgest list");
    }
  }
);

export const PenaltyAdditionalsWidgets = createAsyncThunk(
  "getPenaltyWidgets",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      typeNames,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    const type_names = JSON.stringify(typeNames);
    try {
      const response = await WBAPI.penaltyAdditionalsWidgets({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        type_names,
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const SurchargesAdditionalsWidgets = createAsyncThunk(
  "getSurchargesWidgets",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      typeNames,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    const type_names = JSON.stringify(typeNames);
    try {
      const response = await WBAPI.penaltyAdditionalsWidgets({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        type_names,
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const PenaltyAdditionalsLinesDynamic = createAsyncThunk(
  "dynamic/penalty",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      value,
      type_name,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    const names = type_name.map(elem => elem.type_name);
    try {
      const promises = type_name.map(elem => {
        return WBAPI.penaltyAdditionalDynamic({
          key,
          dateFrom,
          dateTo,
          ids,
          brandsId,
          aritucl,
          barcod,

          subject,
          value,
          type_name: elem.type_name,
        });
      });
      const res = await Promise.all(promises);
      const response = names.map((elem, index) => {
        return {
          type_name: elem,
          dynamic: res[index],
        };
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const PenaltyTypes = createAsyncThunk(
  "dynamic/penalty/types",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      d,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    try {
      const response = await WBAPI.penaltyTypes({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const PenaltyOffices = createAsyncThunk(
  "offices/penalty",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      value,
      typeNames,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    const type_names = JSON.stringify(typeNames);
    try {
      const response = await WBAPI.penaltyOffices({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        value,
        type_names,
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

export const PenaltyDiagramProducts = createAsyncThunk(
  "diagram/penalty/products",
  async (
    {
      keys,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      value,
      typeNames,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();
    const type_names = JSON.stringify(typeNames);
    try {
      const response = await WBAPI.penaltyProducts({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        value,
        type_names,
      });

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgets list");
    }
  }
);

// TODO chech unused
export const PenaltyAdditionalsPenaltyTypes = createAsyncThunk(
  "penalty/additional/reason",
  async ({
    keys,
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    value,
    typeNames,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    const promises = typeNames.map(elem => {
      return WBAPI.penaltyAdditionalsPenaltyTypes({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        value,
        type_name: elem,
      });
    });

    const res = await Promise.all(promises);

    return res;
  }
);

export const PenaltyTables = createAsyncThunk(
  "penalty/table",
  async (
    {
      keys,
      limit,
      offset,
      dateFrom,
      dateTo,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      value,
      subjectsId,
      sort,
      q,
      product_id__icontains,
    },
    thunkAPI
  ) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    try {
      const response = await WBAPI.penaltyTable({
        key,
        dateFrom,
        dateTo,
        ids,
        brandsId,
        aritucl,
        barcod,

        value,
        sort,
        limit,
        offset,
        subject,
        q,
        product_id__icontains,
      });

      return { value, response };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching widgest list");
    }
  }
);

export const AdvertWidgetsCompany = createAsyncThunk(
  "advert/widgets",
  async ({
    keys,
    dateFrom,
    dateTo,
    status,
    type,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    companies,
  }) => {
    try {
      const key = keys.join();
      const status_in = status.join();
      const type_in = type.join();

      const categories = categoryId.join();
      const brands = brandIds.join();
      const articles = JSON.stringify(articulIds);
      const barcodes = barcodeIds.join();
      const subjects = subjectsId.join();

      const companiess = companies.join();

      const response = await WBAPI.advertWidgetsCompany({
        key,
        dateFrom: dateFrom,
        dateTo: dateTo,
        status: status_in,
        type: type_in,
        categories,
        brands,
        articles,
        barcodes,
        subjects,

        companiess,
      });
      return response;
    } catch (error) {
      console.error("Error in AdvertWidgetsCompany:", error);
      throw error;
    }
  }
);

export const AdvertDynamic = createAsyncThunk(
  "advert/dynamic",
  async ({
    keys,
    dateFrom,
    dateTo,
    status,
    type,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    companies,
  }) => {
    try {
      const ONE_DAY = 24 * 60 * 60 * 1000;
      const prevDateTo = calcDate(new Date(dateFrom), 1);
      const prevDateFrom = new Date(prevDateTo - (dateTo - dateFrom));
      const key = keys.join();
      const status_in = status.join();
      const type_in = type.join();

      const categories = categoryId.join();
      const brands = brandIds.join();
      const articles = JSON.stringify(articulIds);
      const barcodes = barcodeIds.join();
      const subjects = subjectsId.join();

      const companiess = companies.join();

      const currentRequest = WBAPI.advertDynamic({
        key,
        dateFrom: dateFrom,
        dateTo: dateTo,
        status: status_in,
        type: type_in,
        categories,
        brands,
        articles,
        barcodes,
        subjects,

        companiess,
      });
      const prevRequest = WBAPI.advertDynamic({
        key,
        dateFrom: prevDateFrom,
        dateTo: prevDateTo,
        status: status_in,
        type: type_in,
        categories,
        brands,
        articles,
        barcodes,
        subjects,

        companiess,
      });
      const [currentResponse, prevResponse] = await Promise.all([currentRequest, prevRequest]);
      const respToRespByDates = resp =>
        resp.reduce((acc, i) => {
          acc[i.date] = i;
          return acc;
        }, {});

      const curRespByDates = respToRespByDates(currentResponse);
      const prevRespByDates = respToRespByDates(prevResponse);

      const dateToDateKey = dt =>
        `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(
          -2
        )}`;
      const daysCount = (dateTo - dateFrom) / ONE_DAY;

      const data = Array.from({ length: daysCount }).map((_, i) => {
        const curDate = new Date(dateFrom.getTime() + ONE_DAY * i);
        const prevDate = new Date(prevDateFrom.getTime() + ONE_DAY * i);

        const curDateKey = dateToDateKey(curDate);
        const prevDateKey = dateToDateKey(prevDate);

        return {
          currentDate: curDateKey,
          // prevDate:prevDateKey,
          current: curRespByDates[curDateKey],
          prev: prevRespByDates[prevDateKey],
        };
      });

      return data;
    } catch (error) {
      console.error("Error in AdvertWidgetsCompany:", error);
      throw error;
    }
  }
);

export const AdvertProductPosition = createAsyncThunk(
  "advert/products",
  async ({
    keys,
    dateFrom,
    dateTo,
    status,
    type,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    companies,
  }) => {
    try {
      const key = keys.join();
      const status_in = status.join();
      const type_in = type.join();

      const categories = categoryId.join();
      const brands = brandIds.join();
      const articles = JSON.stringify(articulIds);
      const barcodes = barcodeIds.join();
      const subjects = subjectsId.join();

      const companiess = companies.join();

      const response = await WBAPI.advertProductPosition({
        key,
        dateFrom: dateFrom,
        dateTo: dateTo,
        status: status_in,
        type: type_in,
        categories,
        brands,
        articles,
        barcodes,
        subjects,

        companiess,
      });

      return response;
    } catch (error) {
      console.error("Error in AdvertWidgetsCompany:", error);
      throw error;
    }
  }
);

export const MediaCircle = createAsyncThunk(
  "circle/media",
  async ({
    keys,
    dateFrom,
    dateTo,
    status,
    type,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    companies,
  }) => {
    const key = keys.join();
    const status_in = status.join();
    const type_in = type.join();

    const categories = categoryId.join();
    const brands = brandIds.join();
    const articles = JSON.stringify(articulIds);
    const barcodes = barcodeIds.join();
    const subjects = subjectsId.join();

    const companiess = companies.join();

    const response = await WBAPI.mediaCircle({
      key,
      dateFrom: dateFrom,
      dateTo: dateTo,
      status: status_in,
      type: type_in,
      categories,
      brands,
      articles,
      barcodes,
      subjects,

      companiess,
    });
    return response;
  }
);
export const AdvertTables = createAsyncThunk(
  "advert/tables",
  async ({
    keys,
    dateFrom,
    dateTo,
    brands,
    categories,

    group_by,
    q,
    sort,
    offset,
    limit,
    status,
    type,
    companies,
    advert_id,
    indicators,
  }) => {
    const key = keys.join();
    const status_in = status.join();
    const type_in = type.join();
    const brand = brands.join();
    const category = categories.join();

    const companiess = companies.join();
    let indicatorsArray = [];

    indicators?.map(item => {
      indicatorsArray[item["data"]] = { gte: item["from"], lte: item["to"] };
    });

    const response = await WBAPI.advertTables({
      key,
      dateFrom,
      dateTo,
      brand,
      category,

      group_by,
      q,
      sort,
      offset,
      limit,
      status: status_in,
      type: type_in,
      companiess,
      advert_id,
      indicatorsArray,
    });

    return { group_by, response };
  }
);

export const AdvertTablesHints = createAsyncThunk(
  "advert/tablesHints",
  async ({
    keys,
    dateFrom,
    dateTo,
    brands,
    categories,

    group_by,
    q,
    sort,
    offset,
    limit,
    status,
    type,
    companies,
    advert_id,
  }) => {
    const key = keys.join();
    const status_in = status.join();
    const type_in = type.join();
    const brand = brands.join();
    const category = categories.join();

    const companiess = companies.join();

    const response = await WBAPI.advertTablesHints({
      key,
      dateFrom,
      dateTo,
      brand,
      category,

      group_by,
      q,
      sort,
      offset,
      limit,
      status: status_in,
      type: type_in,
      companiess,
      advert_id,
    });

    return { group_by, response };
  }
);

export const AdvertKeywordsTable = createAsyncThunk(
  "advert/keywords",
  async ({ keys, dateFrom, dateTo, q, sort, offset, limit, status, type }) => {
    const key = keys.join();
    const statuses = status.join();
    const types = type.join();
    const response = await WBAPI.AdvertKeywordsTable({
      key,
      dateFrom,
      dateTo,
      q,
      sort,
      offset,
      limit,
      statuses,
      types,
    });
    return { response };
  }
);
export const AdvertKeywordsByKeywordsTable = createAsyncThunk(
  "advert/keywordsByKeywords",
  async ({
    keys,
    dateFrom,
    dateTo,
    q,
    sort,
    offset,
    companies,
    limit,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    type,
    status,
  }) => {
    const key = keys.join();
    const response = await WBAPI.AdvertKeywordsByKeywordsTable({
      key,
      dateFrom,
      dateTo,
      q,
      sort,
      offset,
      companies: companies.join(),
      limit,
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      type,
      status,
    });
    return { response };
  }
);

export const AdvertTop = createAsyncThunk(
  "advert/top",
  async ({ keys, dateFrom, dateTo, group_by, limit, reverse, brand, category, subject }) => {
    const key = keys.join();
    const categories = category.join();
    const brands = brand.join();
    const subjects = subject.join();
    const response = await WBAPI.AdvertTop({
      key,
      dateFrom,
      dateTo,
      group_by,
      limit,
      reverse,
      brands,
      categories,
      subjects,
    });
    return { response };
  }
);

export const PaidProducts = createAsyncThunk(
  "paid/product",
  async ({
    keys,
    value,
    dateFrom,
    dateTo,
    categoryId,
    brandIds,

    subjectsId,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();

    const subject = subjectsId.join();
    const response = await WBAPI.paidProducts({
      dateFrom,
      dateTo,
      subject,
      key,
      brandsId,
      ids,
      value,
    });
    return response;
  }
);

export const PaidWidget = createAsyncThunk(
  "paid/widgets",
  async ({
    keys,

    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    const response = await WBAPI.paidWidget({
      key,
      dateFrom,
      dateTo,
      ids,
      brandsId,
      aritucl,
      barcod,

      subject,
    });
    return response;
  }
);

export const PaidDiagram = createAsyncThunk(
  "paid/diagram",
  async ({
    keys,

    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    const response = await WBAPI.paidDiagram({
      key,
      dateFrom,
      dateTo,
      ids,
      brandsId,
      aritucl,
      barcod,

      subject,
    });
    return response;
  }
);

export const PaidStorageCircles = createAsyncThunk(
  "paid/Circles",
  async ({
    keys,

    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    const response = await WBAPI.paidStorageCircles({
      key,
      dateFrom,
      dateTo,
      ids,
      brandsId,
      aritucl,
      barcod,

      subject,
    });
    return response;
  }
);

export const PaidOfficesCircle = createAsyncThunk(
  "paid/offices/circle",
  async ({
    keys,

    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    value,
    subjectsId,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    const response = await WBAPI.paidOfficesCircle({
      key,
      dateFrom,
      dateTo,
      ids,
      brandsId,
      aritucl,
      barcod,

      value,
      subject,
    });
    return response;
  }
);

export const PaidTable = createAsyncThunk(
  "paid/table",
  async ({
    keys,
    limit,
    offset,
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    group_by,
    subjectsId,
    sort,
    q,
    product_id__icontains,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    const response = await WBAPI.paidTable({
      key,
      dateFrom,
      dateTo,
      ids,
      brandsId,
      aritucl,
      barcod,

      group_by,
      sort,
      limit,
      offset,
      subject,
      q,
      product_id__icontains,
    });

    return { response, group_by };
  }
);

export const PaidStorageWarehouseCoefsTable = createAsyncThunk(
  "paid/tableCoefs",
  async ({
    keys,
    limit,
    offset,
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    group_by,
    subjectsId,
    sort,
    q,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    const response = await WBAPI.paidStorageWarehouseCoefsTable({
      key,
      dateFrom,
      dateTo,
      ids,
      brandsId,
      aritucl,
      barcod,

      group_by,
      sort,
      limit,
      offset,
      subject,
      q,
    });

    return { response, group_by };
  }
);

export const PaidStorageWarehouseDetailsTable = createAsyncThunk(
  "paid/tableDetails",
  async ({
    keys,
    limit,
    offset,
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    group_by,
    subjectsId,
    sort,
    q,
  }) => {
    const key = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();

    const response = await WBAPI.paidStorageWarehouseDetailsTable({
      key,
      dateFrom,
      dateTo,
      ids,
      brandsId,
      aritucl,
      barcod,

      group_by,
      sort,
      limit,
      offset,
      subject,
      q,
    });
    return { response, group_by };
  }
);

export const GetRealizationReportArticle = createAsyncThunk(
  "selectors/realization_report",
  async ({ keys, dateFrom, dateTo, brand_id, category_id, subject_id, article }) => {
    const key = keys.join();
    const response = await WBAPI.getRealizationReportArticle({
      key,
      dateFrom,
      dateTo,
      brand_id,
      category_id,
      subject_id,
    });
    return { response, article };
  }
);

export const GetRealizationReportArticleAdvert = createAsyncThunk(
  "selectors/realization_report_advert",
  async ({ keys, dateFrom, dateTo, brand_id, category_id, subject_id, article }) => {
    const key = keys.join();
    const response = await WBAPI.getRealizationReportArticleAdvert({
      key,
      dateFrom,
      dateTo,
      brand_id,
      category_id,
      subject_id,
    });
    return { response, article };
  }
);

export const GetRealizationReportArticlePaidStorage = createAsyncThunk(
  "selectors/realization_report_advert_paid_storage",
  async ({ keys, dateFrom, dateTo, brand_id, category_id, subject_id, article }) => {
    const key = keys.join();
    const response = await WBAPI.getRealizationReportArticlePaidStorage({
      key,
      dateFrom,
      dateTo,
      brand_id,
      category_id,
      subject_id,
    });
    return { response, article };
  }
);

export const GetPromotionKeyPhrasesCatalogTable = createAsyncThunk(
  "/analytics/advert_word_stat",
  async ({
    keys,
    dateFrom,
    dateTo,
    q,
    sort,
    offset,
    limit,
    companies,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    type,
    status,
  }) => {
    const response = await WBAPI.getPromotionKeyPhrasesCatalogTable({
      key: keys.join(),
      dateFrom,
      dateTo,
      q,
      sort,
      offset,
      limit,
      companies: companies.join(),
      categoryId,
      brandIds,
      articulIds,
      barcodeIds,

      subjectsId,
      type,
      status,
    });

    return response;
  }
);

export const GetHeatMapAsTable = createAsyncThunk(
  "advert_heat_map/as_table",
  async ({
    key,
    dateFrom,
    dateTo,
    brand_id__in,
    category_id__in,
    subject_id__in,
    barcode__in,
    // supplier_article__in,
    product_id__in,
    filter_by_advert_stat,
  }) => {
    const res = await WBAPI.getHeatMapAsTable({
      key,
      dateFrom,
      dateTo,
      brand_id__in,
      category_id__in,
      subject_id__in,
      barcode__in,
      // supplier_article__in,
      product_id__in,
      filter_by_advert_stat,
    });
    return res;
  }
);
