import request from "root/js/request";

export const getAdvertsContentCardProductIds = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  brand_id__in,
  supplier_article__in,
  subject_id__in,
  ...rest
}) => {
  const response = await request({
    url: "/api/analytics/advert_content_card_product_ids/",
    method: "get",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      supplier_article__in,
      brand_id__in,
      subject_id__in,
      ...rest,
    },
  });
  return response.data;
};
