const months = [
  {
    id: 1,
    title: "Январь",
  },
  {
    id: 2,
    title: "Февраль",
  },
  {
    id: 3,
    title: "Март",
  },
  {
    id: 4,
    title: "Апрель",
  },
  {
    id: 5,
    title: "Май",
  },
  {
    id: 6,
    title: "Июнь",
  },
  {
    id: 7,
    title: "Июль",
  },
  {
    id: 8,
    title: "Август",
  },
  {
    id: 9,
    title: "Сентябрь",
  },
  {
    id: 10,
    title: "Октябрь",
  },
  {
    id: 11,
    title: "Ноябрь",
  },
  {
    id: 12,
    title: "Декабрь",
  },
];

const weekDays = {
  0: { short: "вс" },
  1: { short: "пн" },
  2: { short: "вт" },
  3: { short: "ср" },
  4: { short: "чт" },
  5: { short: "пт" },
  6: { short: "сб" },
};

const daysMS = 24 * 3600 * 1000;

export { months, weekDays, daysMS };
