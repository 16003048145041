import React, { useCallback, useEffect, useRef, useState } from "react"

import { filterObject } from "root/js/utils.js"

export default function useActiveDots(initial) {
	const [currentActiveDots, setCurrentActiveDots] = useState([])
	const [isRefSet, setIsRefSet] = useState(false)
	const dots = useRef({})
	const rechartsRef = useRef(null)
	const activeDotFactory = useCallback(
		(e, props = {}) => {
			if (e.value === null) return null
			dots.current = filterObject(dots.current, (key, value) => value.index === e.index)
			dots.current[e.key] = e
			const isActive = currentActiveDots.find(item => item.key === e.key)
			return (
				<circle
					cx={e.cx}
					cy={e.cy}
					r={8}
					strokeWidth={4}
					stroke={e.fill}
					fill="white"
					strokeOpacity={isActive ? 1 : 0}
					fillOpacity={isActive ? 1 : 0}
					data-circle-id={e.key}
					{...props}
				/>
			)
		},
		[currentActiveDots]
	)
	useEffect(() => {
		if (isRefSet) return
		let int
		let containerElement
		const onMouse = e => {
			const x = e.clientX
			const y = e.clientY
			const radius = 16
			if (!containerElement.container) return null
			const { x: containerX, y: containerY } =
				containerElement.container.getBoundingClientRect()
			const [realX, realY] = [x - containerX, y - containerY]
			const activeDots = Object.values(dots.current)
				.filter(
					item =>
						realX >= item.cx - radius &&
						realX <= item.cx + radius &&
						realY >= item.cy - radius &&
						realY <= item.cy + radius
				)
				.sort((a, b) => a.cy - b.cy)
				.slice(0, 5)
			setCurrentActiveDots(activeDots.length > 0 ? activeDots : [])
		}
		const addEvents = () => {
			containerElement.container.addEventListener("mousemove", onMouse)
			containerElement.container.addEventListener("click", onMouse)
			setIsRefSet(true)
		}

		if (rechartsRef.current) {
			containerElement = rechartsRef.current
			addEvents()
		} else {
			int = setInterval(() => {
				if (rechartsRef.current) {
					containerElement = rechartsRef.current
					addEvents()
					clearInterval(int)
				}
			}, 1000)
		}

		return () => {
			clearInterval(int)
			if (containerElement?.current) {
				containerElement.container.removeEventListener("mousemove", onMouse)
				containerElement.container.removeEventListener("click", onMouse)
			}
		}
	}, [isRefSet])

	return { rechartsRef, currentActiveDots, activeDotFactory }
}
