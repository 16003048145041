import lightFormat from "date-fns/lightFormat";

import { pluralize, regExps } from "./utils";

export default [
  {
    key: "seller_names",
    isValid: value => value.length,
    label: value => value.split(",").map(item => `Поставщик: ${item}`)
  },
  {
    key: "brand_names",
    isValid: value => value.length,
    label: value => value.split(",").map(item => `Бренд: ${item}`)
  },
  {
    key: "subjects_names",
    isValid: value => value.length,
    label: value => value.split(",").map(item => `Предмет: ${item}`)
  },
  {
    key: "category_names",
    isValid: value => value.length,
    label: value => value.split(",").map(item => `Категория: ${item}`)
  },
  {
    key: "category_names_uniq",
    isValid: value => value.length,
    label: value => value.split("|~_^|$|^_~|").map(item => `Категория: ${item}`)
  },
  {
    key: "isnot_wb",
    isValid: value => value === "true",
    label: () => "Исключить товары WB"
  },
  {
    key: "is_new",
    isValid: value => value === "true",
    label: () => "Только новинки"
  },
  {
    key: "city",
    isValid: value => value.length,
    label: value => `Регион: ${value}`
  },
  {
    key: "keyword",
    isValid: value => value.length,
    label: value => `Ключевое слово: ${value}`
  },
  {
    key: "wb_search",
    isValid: value => value.length,
    label: value => `Ключевой запрос: ${value}`
  },
  {
    key: "wb_catalog_url",
    isValid: value => regExps.catalogUrl.test(value),
    label: value => `Ссылка на каталог: ${value.replace(/^https:\/\/.+?\//, "").substr(0, 16)}...`
  },
  {
    key: "wb_search",
    isValid: value => value.length,
    label: value => `Поиск на WB «${value}»`
  },
  {
    key: "period",
    isValid: value => typeof value === "number",
    label: value => `За последние ${value} ${pluralize(value, "день", "дня", "дней")}`
  },
  {
    key: "date",
    isValid: ({ min, max }) => min.length > 0 && max.length > 0,
    label: ({ min, max }) =>
      `За период от ${lightFormat(new Date(min), "dd.MM.yyyy")} до ${lightFormat(
        new Date(max),
        "dd.MM.yyyy"
      )}`
  },
  {
    key: "product_id",
    isValid: value => value.length,
    label: value => `Артикул: ${value}`
  },
  {
    key: "phrase",
    isValid: value => value.length,
    label: value => `Поисковый запрос: «${value}»`
  }
];
