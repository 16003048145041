import React from "react";

import { Box } from "@mui/material";

import styles from "./index.module.scss";

//
const filterPageAndHerChildByProp = (arr, property) => {
  return arr
    .filter(s => !s[property])
    .map(s => {
      if (s.type === "section")
        return { ...s, children: s.children.filter(item => !item[property]) };
      return s;
    });
};

export { filterPageAndHerChildByProp };
