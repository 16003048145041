import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M21 5.51998L18.48 3L12 9.48L5.51998 3L3 5.51998L9.48 12L3 18.48L5.51998 21L12 14.52L18.48 21L21 18.48L14.52 12L21 5.51998Z" />
	</Template>
))
