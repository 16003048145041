import React, { useState } from "react";

import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { prop } from "ramda";
import { useSelector } from "react-redux";

import Card from "root/components/global/card";

import { numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

const PromotionMediaWidgets = ({ promotionWidgets }) => {
  const { currencyName } = useSelector(state => state.financeLogic);

  const views = promotionWidgets?.current?.views ? promotionWidgets?.current?.views : 0;
  const viewsPrev = promotionWidgets?.prev?.views ? promotionWidgets?.prev?.views : 0;
  const clicks = promotionWidgets?.current?.clicks ? promotionWidgets?.current?.clicks : 0;
  const clicksPrev = promotionWidgets?.prev?.clicks ? promotionWidgets?.prev?.clicks : 0;
  const atbs = promotionWidgets?.current?.atbs !== null ? promotionWidgets?.current?.atbs : null;
  const atbsPrev = promotionWidgets?.prev?.atbs !== null ? promotionWidgets?.prev?.atbs : null;
  const orders = promotionWidgets?.current?.orders ? promotionWidgets?.current?.orders : 0;
  const ordersPrev = promotionWidgets?.prev?.orders ? promotionWidgets?.prev?.orders : 0;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(5);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };
  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {isMobile && (
        <IconButton onClick={prev} className={styles.controlBtn} sx={{ left: "5px" }}>
          <ArrowLeftIcon />
        </IconButton>
      )}
      <div
        className={styles.promotWidgetsContainer}
        style={{ transform: `translateX(-${currentIndex * 97}%)` }}>
        <div className={styles.separateCardBlock}>
          <Card
            titleFirstLine="Воронка"
            labelFirstLine="Просмотры"
            valueFirstLine={views}
            percentFirstLine={(views - viewsPrev) / viewsPrev}
            labelSecondLine="Клики"
            valueSecondLine={clicks}
            customFormat={numberWithSpaces(clicks?.toFixed(0))}
            percentSecondLine={(clicks - clicksPrev) / clicks}
            labelThirdLine="Корзина"
            valueThirdLine={atbs}
            percentThirdLine={(atbs - atbsPrev) / atbs}
            labelFourthLine="Заказы"
            valueFourthLine={orders}
            percentFourthLine={(orders - ordersPrev) / orders}
            unit={"%"}
            withoutBorder={true}
          />
        </div>

        <div className={styles.fourWidgetsBlock}>
          <div className={styles.widgets}>
            <Card
              titleFirstLine="Конверсии"
              labelFirstLine="CTR"
              valueFirstLine={prop("ctr", prop("current", promotionWidgets))}
              currencyFirstLine={"%"}
              percentFirstLine={
                (prop("ctr", prop("current", promotionWidgets)) -
                  prop("ctr", prop("prev", promotionWidgets))) /
                prop("ctr", prop("prev", promotionWidgets))
              }
              unit={"%"}
              labelSecondLine="CR"
              valueSecondLine={prop("cr", prop("current", promotionWidgets))}
              currencySecondLine={"%"}
              percentSecondLine={
                (prop("cr", prop("current", promotionWidgets)) -
                  prop("cr", prop("prev", promotionWidgets))) /
                prop("cr", prop("prev", promotionWidgets))
              }
              labelThirdLine="CAR"
              valueThirdLine={prop("car", prop("current", promotionWidgets))}
              currencyThirdLine={"%"}
              percentThirdLine={
                (prop("car", prop("current", promotionWidgets)) -
                  prop("car", prop("prev", promotionWidgets))) /
                prop("car", prop("prev", promotionWidgets))
              }
              withoutBorder={true}
              tripFirstLine="Click-Through Rate – показатель кликабельности (Количество кликов / Количество просмотров)"
              tripSecondLine="Conversion Rate – коэффициент конверсии или просто “в народе” конверсия (Количество заказов / Количество кликов)"
              tripThirdLine="Cart Abandonment Rate – коэффициент брошенных корзин (1 – Количество заказов / Количество добавлений в корзину)"
            />

            <Card
              titleFirstLine="Стоимости"
              labelFirstLine="CPM"
              valueFirstLine={Number(prop("cpm", prop("current", promotionWidgets))?.toFixed(0))}
              currencyFirstLine={currencyName}
              percentFirstLine={
                (prop("cpm", prop("current", promotionWidgets)) -
                  prop("cpm", prop("prev", promotionWidgets))) /
                prop("cpm", prop("prev", promotionWidgets))
              }
              unit={"%"}
              labelSecondLine="CPC"
              valueSecondLine={Number(prop("cpc", prop("current", promotionWidgets))?.toFixed(1))}
              customFormat={`${Number(
                prop("cpc", prop("current", promotionWidgets))?.toFixed(1)
              )} ${currencyName}`}
              percentSecondLine={
                (prop("cpc", prop("current", promotionWidgets)) -
                  prop("cpc", prop("prev", promotionWidgets))) /
                prop("cpc", prop("prev", promotionWidgets))
              }
              labelThirdLine="CPO"
              valueThirdLine={Number(prop("cpa", prop("current", promotionWidgets))?.toFixed(0))}
              currencyThirdLine={currencyName}
              percentThirdLine={
                (prop("cpa", prop("current", promotionWidgets)) -
                  prop("cpa", prop("prev", promotionWidgets))) /
                prop("cpa", prop("prev", promotionWidgets))
              }
              withoutBorder={true}
              tripFirstLine="Cost per Mille – плата за 1000 показов (Расход на рекламу / Количество просмотров * 1000)"
              tripSecondLine="Cost per Click – плата за клик (Расход на рекламу / Количество кликов)"
              tripThirdLine="Cost per Order – средняя стоимость привлечения заказа (Расход на рекламу / Количество заказов)"
              isFirstInvertedDirection
              isSecondInvertedRotate
              isThirdInvertedDirection
            />

            <Card
              titleFirstLine="Метрики"
              labelFirstLine="ROMI"
              valueFirstLine={prop("romi", prop("current", promotionWidgets))}
              currencyFirstLine={"%"}
              percentFirstLine={
                (prop("romi", prop("current", promotionWidgets)) -
                  prop("romi", prop("prev", promotionWidgets))) /
                prop("romi", prop("prev", promotionWidgets))
              }
              unit={"%"}
              labelSecondLine="ROAS"
              valueSecondLine={prop("roas", prop("current", promotionWidgets))}
              currencySecondLine={"%"}
              percentSecondLine={
                (prop("roas", prop("current", promotionWidgets)) -
                  prop("roas", prop("prev", promotionWidgets))) /
                prop("roas", prop("prev", promotionWidgets))
              }
              labelThirdLine="ДРР"
              valueThirdLine={prop("crr", prop("current", promotionWidgets))}
              currencyThirdLine={"%"}
              percentThirdLine={
                (prop("crr", prop("current", promotionWidgets)) -
                  prop("crr", prop("prev", promotionWidgets))) /
                prop("crr", prop("prev", promotionWidgets))
              }
              withoutBorder={true}
              tripFirstLine="Return on marketing investment – коэффициент возврата маркетинговых инвестиций ((Доход от рекламы – Расход на рекламу) / Расход на рекламу)"
              tripSecondLine="Return on ad spend – коэффициент возврата рекламных затрат (Доход от рекламы / Расход на рекламу)"
              tripThirdLine="Доля рекламных расходов – коэффициент отношения рекламных расходов к доходам от этой рекламы (Расход на рекламу / Доход от рекламы)"
              isFirstInvertedDirection
              isFirstInvertedRotate
              isThirdInvertedDirection
            />

            <Card
              titleFirstLine="В денежном выражении"
              labelFirstLine="Затраты"
              valueFirstLine={Number(prop("sum", prop("current", promotionWidgets))?.toFixed(0))}
              currencyFirstLine={currencyName}
              percentFirstLine={
                (prop("sum", prop("current", promotionWidgets)) -
                  prop("sum", prop("prev", promotionWidgets))) /
                prop("sum", prop("prev", promotionWidgets))
              }
              unit={"%"}
              labelSecondLine="Доходы"
              valueSecondLine={Number(
                prop("sum_price", prop("current", promotionWidgets))?.toFixed(0)
              )}
              currencySecondLine={currencyName}
              percentSecondLine={
                (prop("sum_price", prop("current", promotionWidgets)) -
                  prop("sum_price", prop("prev", promotionWidgets))) /
                prop("sum_price", prop("prev", promotionWidgets))
              }
              labelThirdLine="Валовая маржа"
              valueThirdLine={Number(
                prop("gross_margin", prop("current", promotionWidgets))?.toFixed(0)
              )}
              currencyThirdLine={currencyName}
              percentThirdLine={
                (prop("gross_margin", prop("current", promotionWidgets)) -
                  prop("gross_margin", prop("prev", promotionWidgets))) /
                prop("gross_margin", prop("prev", promotionWidgets))
              }
              withoutBorder={true}
              isFirstInvertedDirection
            />
          </div>
          {/*<div className={styles.settingsLine}>*/}
          {/*  <div className={styles.checkboxes}>*/}
          {/*    <div className={styles.item}>*/}
          {/*      <Checkbox checked={true} />*/}
          {/*      <Typography variant="body2">Автоматическая РК</Typography>*/}
          {/*    </div>*/}
          {/*    <div className={styles.item}>*/}
          {/*      <Checkbox checked={true} />*/}
          {/*      <Typography variant="body2">Поиск + каталог</Typography>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <Button type="submit" variant="contained" className={styles.bttnSize}>*/}
          {/*    Сравнить по типам кампаний*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </div>
      </div>
      {isMobile && (
        <IconButton onClick={next} className={styles.controlBtn} sx={{ right: "5px" }}>
          <ArrowLeftIcon sx={{ transform: "rotate(180deg)" }} />
        </IconButton>
      )}
    </div>
  );
};

export default PromotionMediaWidgets;
