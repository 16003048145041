import React from "react";

// ---------------------------------------------------------------------
const otherMenuPages = [
  {
    url: "/signin",
    component: React.lazy(() => import("root/pages/sign/App")),
  },
  // {
  // 	url: "/signup",
  // 	component: React.lazy(() => import("root/pages/sign/App")),
  // },
  {
    url: "/restore-password",
    component: React.lazy(() => import("root/pages/sign/App")),
  },
  {
    url: "/set-password",
    component: React.lazy(() => import("root/pages/sign/App")),
  },
  {
    url: "/change-password",
    component: React.lazy(() => import("root/pages/sign/App")),
  },
  {
    url: "/callback",
    component: React.lazy(() => import("root/pages/sign/App")),
  },
  {
    url: "/expired",
    component: React.lazy(() => import("root/pages/expired/App")),
  },
  {
    url: "/error/:code",
    component: React.lazy(() => import("root/pages/error/App")),
  },
  {
    url: "/transaction",
    component: React.lazy(() => import("root/pages/cabinet/components/Transaction")),
  },
  {
    url: "/iframe/product-chart/:id/:token",
    component: React.lazy(() => import("root/iframes/ProductChartById")),
  },
  {
    url: "/iframe/keywords-chart/:id/:token",
    component: React.lazy(() => import("root/iframes/KeywordsChartById")),
  },
  {
    url: "/iframe/keywords-table/:id/:token",
    component: React.lazy(() => import("root/iframes/KeywordsTableById")),
  },
  {
    url: "/iframe/request-analyzer/:keyword/:token",
    component: React.lazy(() => import("root/iframes/RequestAnalizerByKeyword")),
  },
  {
    url: "/iframe/wordstat/:keyword/:token",
    component: React.lazy(() => import("root/iframes/WordstatByKeyword")),
  },
  {
    url: "/decoding-detail",
    component: React.lazy(() => import("root/pages/decoding-detail/App")),
  },
  {
    url: "/setting",
    component: React.lazy(() => import("root/pages/setting/App")),
  },
  {
    url: "/connections",
    component: React.lazy(() => import("root/pages/connections/App")),
  },
  {
    url: "/iframe/clusterer/:keyword/:token",
    component: React.lazy(() => import("root/iframes/ClustererByKeyword")),
  },
  {
    url: "/iframe/geo-size-location/:product/:token",
    component: React.lazy(() => import("root/iframes/GeoSizeLocationById")),
  },
  {
    url: "/iframe/outside-traffic/:id/:token",
    component: React.lazy(() => import("root/iframes/Outer")),
  },
  // {
  //   // title: "Анализ источников трафика",
  //   url: "/bloggers-data",
  //   component: React.lazy(() => import("root/pages/bloggers/App")),
  //   // tariffIds: [4, 14],
  //   // exact: false
  // },
  {
    url: "/iframe/keys-finder/:keyword/:token",
    component: React.lazy(() => import("root/iframes/KeysFinderByKeyword")),
  },
  {
    url: "/iframe/position/:product_id/:phrase/:token",
    component: React.lazy(() => import("root/iframes/PositionById")),
  },
  {
    url: "/partial-purchase/:token",
    component: React.lazy(() => import("root/pages/partial-purchase/components/FormedList")),
  },
  {
    url: "/welcome-to-extension",
    component: React.lazy(() => import("root/iframes/WelcomePageForExtension/index")),
  },
  {
    url: "/unsub-from-newsletters",
    component: React.lazy(() => import("root/iframes/WelcomePageForExtension/unsubPage")),
  },
  {
    url: "/google_sheet_save",
    component: React.lazy(() => import("root/pages/google_sheet_save/App")),
  },
];

export default otherMenuPages;
