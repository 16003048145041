import React, { useEffect, useLayoutEffect, useState } from "react";

import { Box } from "@mui/material";

import DatePickerField from "./DatePickerField";

import styles from "./index.module.scss";

const DatePicker = ({ value, onChange, withBox = true, ...props }) => {
  const [openDateRange, setIsOpenDateRange] = useState(false);
  const [valueC, setValue] = useState(value);
  const onChangeDatePickerField = newDateRange => {
    setValue(newDateRange);
    // if (!openDateRange) onChange(newDateRange);
  };
  useLayoutEffect(() => {
    if (!openDateRange) {
      onChange(valueC);
    }
  }, [openDateRange]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  if (!withBox) {
    return (
      <DatePickerField
        {...{
          open: openDateRange,
          setIsOpen: setIsOpenDateRange,
          calculateLocal: true,
          onChange: onChangeDatePickerField,
          value: valueC,
          ...props,
        }}
      />
    );
  }
  return (
    <Box className={styles.wrapper}>
      <DatePickerField
        {...{
          open: openDateRange,
          setIsOpen: setIsOpenDateRange,
          calculateLocal: true,
          onChange: onChangeDatePickerField,
          value: valueC,
          ...props,
        }}
      />
    </Box>
  );
};

export default DatePicker;
