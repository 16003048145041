import { createSlice } from "@reduxjs/toolkit";

import {
  GetPlanFactByCategory,
  GetPlanFactMonthDynamic,
  GetPlanFactWidgets,
  SalesDynamic,
  SalesTable,
  SalesWidgets
} from "./action";

const initialState = {
  planFactWidgets: {},
  isLoadingPlanFactWidgets: false,
  planFactMonthDynamic: [],
  isLoadingPlanFactMonthDynamic: false,
  planFactCategories: [],
  isLoadingPlanFactCategories: false,
  planFactSumType: "realization",
  forecastOrders: {},
  forecastOrdersLoading: false,
  forecastDynamic: {},
  forecastDynamicLoading: false,
  forecastTable: [],
  forecastTableCount: 0
};

const bussinessAnaltyticsSlice = createSlice({
  name: "bussines/analitics",
  initialState,
  reducers: {
    clearAllDate: state => {
      state.planFactWidgets = {};
      state.forecastOrders = {};
      state.forecastDynamic = {};
      state.planFactCategories = [];
      state.planFactMonthDynamic = [];
      state.planFactSumType = "realization";
    },
    clearDataTable: (state, action) => {
      const { name } = action.payload;

      if (name === "subject_id") {
        state.forecastSubjectTable = [];
      } else if (name === "month") {
        state.forecastMonthTable = [];
      } else if (name === "category_id") {
        state.forecastCategoryTable = [];
      } else if (name === "shk_id") {
        state.forecastHatchTable = [];
      } else if (name === "supplier_article") {
        state.forecastArticleTable = [];
      } else if (name === "brand_id") {
        state.forecastBrandTable = [];
      } else if (name === "barcode") {
        state.forecastBarcodeTable = [];
      } else if (name === "all") {
        state.forecastSubjectTable = [];
        state.forecastMonthTable = [];
        state.forecastCategoryTable = [];
        state.forecastHatchTable = [];
        state.forecastArticleTable = [];
        state.forecastBrandTable = [];
        state.forecastBarcodeTable = [];
      }
    },
    switchPlanFactSumType: state => {
      state.planFactSumType === "margin"
        ? (state.planFactSumType = "realization")
        : (state.planFactSumType = "margin");
    }
  },
  extraReducers: bulider => {
    bulider
      .addCase(GetPlanFactWidgets.pending, state => {
        state.isLoadingPlanFactWidgets = true;
      })
      .addCase(GetPlanFactWidgets.fulfilled, (state, action) => {
        state.planFactWidgets = action.payload;
        state.isLoadingPlanFactWidgets = false;
      })
      .addCase(GetPlanFactWidgets.rejected, state => {
        state.isLoadingPlanFactWidgets = false;
      })

      .addCase(GetPlanFactByCategory.pending, state => {
        state.isLoadingPlanFactCategories = true;
      })
      .addCase(GetPlanFactByCategory.fulfilled, (state, action) => {
        state.planFactCategories = action.payload;
        state.isLoadingPlanFactCategories = false;
      })
      .addCase(GetPlanFactByCategory.rejected, state => {
        state.isLoadingPlanFactCategories = false;
      })

      .addCase(GetPlanFactMonthDynamic.pending, state => {
        state.isLoadingPlanFactMonthDynamic = true;
      })
      .addCase(GetPlanFactMonthDynamic.fulfilled, (state, action) => {
        state.planFactMonthDynamic = action.payload;
        state.isLoadingPlanFactMonthDynamic = false;
      })
      .addCase(GetPlanFactMonthDynamic.rejected, state => {
        state.isLoadingPlanFactMonthDynamic = false;
      })
      .addCase(SalesWidgets.fulfilled, (state, action) => {
        state.forecastOrders = action.payload;
        state.forecastOrdersLoading = false;
      })
      .addCase(SalesWidgets.pending, (state, action) => {
        state.forecastOrdersLoading = true;
      })
      .addCase(SalesDynamic.fulfilled, (state, action) => {
        state.forecastDynamic = action.payload;
        state.forecastDynamicLoading = false;
      })
      .addCase(SalesDynamic.pending, (state, action) => {
        state.forecastDynamicLoading = true;
      })
      .addCase(SalesTable.fulfilled, (state, action) => {
        state.forecastTable = action.payload.results;
        state.forecastTableCount = action.payload.count;
      });
  }
});

export const { switchPlanFactSumType, clearDataTable } = bussinessAnaltyticsSlice.actions

export const bussinessAnaltyticsSliceReducer = bussinessAnaltyticsSlice.reducer;
