import { createAsyncThunk } from "@reduxjs/toolkit"
import { getSppLimitsMonitoring } from "root/api/spp/monitoring"

import API from "root/js/api"

const WBAPI = new API()

export const getSPPListLimits = createAsyncThunk( "/getSPPListLimits", async (state, thunkAPI) => {
		try {
			const response = await getSppLimitsMonitoring()
			// console.log(response);
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error fetching spp list...")
		}
	}
)

export const getSPPList = createAsyncThunk(
	"/getSppList",
	async ({ dateFrom, dateTo, limit, offset, ordering = "current_spp", product_id }, thunkAPI) => {
		try {
			const response = await WBAPI.getSPPList({
				dateFrom,
				ordering,
				dateTo,
				limit,
				offset,
				product_id,
			})

			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error fetching spp list...")
		}
	}
)

export const getSPPNotifications = createAsyncThunk(
	"/getSPPNotifications",
	async ({ limit, offset, product_id }, thunkAPI) => {
		try {
			const response = await WBAPI.getSPPNotifications({ limit, offset, product_id })

			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error fetching spp notifications...")
		}
	}
)

export const getSPPItemNotification = createAsyncThunk(
	"/getSPPItemNotification",
	async (id, thunkAPI) => {
		try {
			const response = await WBAPI.getSPPItemNotification({ id })

			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error fetching spp item...")
		}
	}
)

export const setSPPList = createAsyncThunk("/setSppList", async (list, thunkAPI) => {
	try {
		const response = await WBAPI.setSPPListBulkUpdate({ list })
		return response
	} catch (err) {
		return thunkAPI.rejectWithValue("Error setting spp list...")
	}
})

export const createSPPList = createAsyncThunk("/createSPPList", async (list, thunkAPI) => {
	try {
		const response = await WBAPI.setSPPList({ list })
		return response
	} catch (err) {
		return thunkAPI.rejectWithValue("Error setting spp list...")
	}
})

export const deleteSPPItems = createAsyncThunk("/deleteSPPItems", async (ids, thunkAPI) => {
	try {
		const response = await WBAPI.deleteSPPItemsBulkDelete({ ids })
		return response
	} catch (err) {
		return thunkAPI.rejectWithValue("Error deleting spp item...")
	}
})
