import { createSlice } from "@reduxjs/toolkit";
import { prop } from "ramda";

import { calcDate, formatDate } from "root/pages/financial-logic/helpers";
import { formatRemainsFboTableData } from "root/pages/inventory-management/components/turnover-capitalization/helpers";

import {
  CapitalizationDiagram,
  CapitalizationStockStructure,
  CapitalizationTable,
  CapitalizationWidgets,
  DistributionByWarehousesTable,
  GetRemainsFbo,
  GetRemainsFboTable,
  InventoryDynamic,
  InventoryTable,
  InventoryWidgets,
  OptimizationInventoryDistribution,
  OptimizationTables,
  OptimizationTariffTable
} from "./action";

const initialState = {
  data: {},
  widgets: [],
  products: null,
  calendars: [],
  dynamics: null,
  isWareHouseSeasonality:
    localStorage.getItem("isWareHouseSeasonality") !== null
      ? localStorage.getItem("isWareHouseSeasonality")
      : true,
  isWareHouseSpikes:
    localStorage.getItem("isWareHouseSpikes") !== null
      ? localStorage.getItem("isWareHouseSpikes")
      : true,
  isGrowth: localStorage.getItem("isGrowth") !== null ? localStorage.getItem("isGrowth") : true,
  isCompanyPlan:
    localStorage.getItem("isCompanyPlan") !== null ? localStorage.getItem("isCompanyPlan") : true,
  isInsuranceReserve:
    localStorage.getItem("isInsuranceReserve") !== null
      ? localStorage.getItem("isInsuranceReserve")
      : true,
  isReturnShare:
    localStorage.getItem("isReturnShare") !== null ? localStorage.getItem("isReturnShare") : true,
  isCancelShare:
    localStorage.getItem("isCancelShare") !== null ? localStorage.getItem("isCancelShare") : true,
  isABCExcluded:
    localStorage.getItem("isABCExcluded") !== null ? localStorage.getItem("isABCExcluded") : true,
  isTend: localStorage.getItem("isTend") !== null ? localStorage.getItem("isTend") : true,
  isInWayStocks:
    localStorage.getItem("isInWayStocks") !== null ? localStorage.getItem("isInWayStocks") : true,
  forcastPeriod: 0,
  isInventoryWidgetsLoading: false,
  isInventoryDynamicLoading: false,
  isOptimizationDistributionLoading: false,
  currencyName: "руб",
  warehouseTableBarcode: [],
  wareHouseTableArticle: [],

  optimizationDateRegions: null,
  optimizationlocalRate: [],
  optimizationRegionName: [],
  optimizationSalesShare: [],
  optimizationSubjectShare: [],
  optimizationPredQuanity: [],

  optimizationSalesShareWeight: 1,
  optimizationSubjectWeight: 0,
  optimizationClaster: [],

  optimizationTableTariff: [],
  isLoadingOptimizationTableTariff: false,
  distributionByWarehousesTable: [],
  optimizationTableArticle: [],
  optimizationTableBarcode: [],

  capitalizationWidgets: {},
  capitalizationDiagram: null,
  capitalizationStockStructure: null,
  capitalizationTableArticle: [],
  capitalizationTableArticleCount: [],
  capitalizationTableBarcode: [],
  capitalizationTableBarcodeCount: [],
  capitalizationTableCategory: [],
  capitalizationTableCategoryCount: [],
  capitalizationTableBrand: [],
  capitalizationTableBrandCount: [],
  capitalizationTableSubject: [],
  capitalizationTableSubjectCount: [],
  dateFrom: "",
  dateTo: "",
  capitalizationPerod: 30,

  remainsFbo: null,
  isLoadingRemainsFbo: false,
  remainsFboTableCategory: [],
  remainsFboCountCategory: 0,
  remainsFboTableArticle: [],
  remainsFboCountArticle: 0,
  remainsFboTableBarcode: [],
  remainsFboCountBarcode: 0,
  remainsFboTableBrand: [],
  remainsFboCountBrand: 0,
  remainsFboTableSubject: [],
  remainsFboCountSubject: 0,
  isLoadingRemainsFboTable: false,
  isCapitalizationWidgetsLoading: true,
  isCapitalizationDiagramLoading: true,
  isCapitalizationStockStructureLoading: true,
  tableScroll: null,
  remainsFboTableSettings: []
};

const inventoryManagmentSlice = createSlice({
  name: "inventoryManagment",
  initialState,
  reducers: {
    clearDate: state => {
      state.data = {};
      state.warehouseTableBarcode = [];
      state.remainsFboTableArticle = [];
      state.remainsFboTableCategory = [];
      state.remainsFboTableSubject = [];
      state.remainsFboTableBrand = [];
      state.remainsFboTableBarcode = [];
      state.capitalizationTableArticle = [];
      state.capitalizationTableCategory = [];
      state.capitalizationTableSubject = [];
      state.capitalizationTableBrand = [];
      state.capitalizationTableBarcode = [];
      state.warehouseTableBarcode = [];
      state.wareHouseTableArticle = [];
      state.optimizationTableArticle = [];
      state.optimizationTableBarcode = [];
      state.capitalizationWidgets = {};
      state.capitalizationDiagram = null;
      state.capitalizationStockStructure = null;
    },
    clearTableWarehouse: (state, action) => {
      const { name } = action.payload;
      if (name === "barcode") {
        state.warehouseTableBarcode = [];
      } else if (name === "supplier_article") {
        state.wareHouseTableArticle = [];
      }
    },

    wareHouseSettingsChecked: (state, action) => {
      const { name } = action.payload;

      if (name === "Цикличность") {
        state.isWareHouseSeasonality = !state.isWareHouseSeasonality;
        localStorage.setItem("isWareHouseSeasonality", state.isWareHouseSeasonality);
      } else if (name === "История всплесков") {
        state.isWareHouseSpikes = !state.isWareHouseSpikes;
        localStorage.setItem("isWareHouseSpikes", state.isWareHouseSpikes);
      } else if (name === "Рост маркетплейса") {
        state.isGrowth = !state.isGrowth;
        localStorage.setItem("isGrowth", state.isGrowth);
      } else if (name === "Планы компании") {
        state.isCompanyPlan = !state.isCompanyPlan;
        localStorage.setItem("isCompanyPlan", state.isCompanyPlan);
      } else if (name === "Страховой резерв") {
        state.isInsuranceReserve = !state.isInsuranceReserve;
        localStorage.setItem("isInsuranceReserve", state.isInsuranceReserve);
      } else if (name === "Возвраты") {
        state.isReturnShare = !state.isReturnShare;
        localStorage.setItem("isReturnShare", state.isReturnShare);
      } else if (name === "Отмены") {
        state.isCancelShare = !state.isCancelShare;
        localStorage.setItem("isCancelShare", state.isCancelShare);
      } else if (name === "АВС") {
        state.isABCExcluded = !state.isABCExcluded;
        localStorage.setItem("isABCExcluded", state.isABCExcluded);
      } else if (name === "Тенденции") {
        state.isTend = !state.isTend;
        localStorage.setItem("isTend", state.isTend);
      } else if (name === "Товары в пути") {
        state.isInWayStocks = !state.isInWayStocks;
        localStorage.setItem("isInWayStocks", state.isInWayStocks);
      }
    },
    forcast: (state, action) => {
      state.forcastPeriod = action.payload;
    },
    clearOptimizationTable: (state, action) => {
      const { name } = action.payload;
      if (name === "supplier_article") {
        state.optimizationTableArticle = [];
      } else if (name === "barcode") {
        state.optimizationTableBarcode = [];
      } else if (name === "all") {
        state.optimizationTableBarcode = [];
        state.optimizationTableArticle = [];
      }
    },
    optimizationWeight: (state, action) => {
      const { value, value1 } = action.payload;

      state.optimizationSalesShareWeight = value;
      state.optimizationSubjectWeight = value1;
    },
    optimizationClasterFilter: (state, action) => {
      state.optimizationClaster = action.payload;
    },
    perodDate: (state, action) => {
      const date = new Date();
      let startDate = new Date(date.setHours(0, 0, 0, 0));

      let period = action.payload;

      let endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() - period);

      state.dateTo = formatDate(startDate);
      state.dateFrom = formatDate(endDate);

      state.capitalizationPerod = action.payload;
    },

    clearCapitalizationTable: (state, action) => {
      const { name } = action.payload;

      if (name === "supplier_article") {
        state.capitalizationTableArticle = [];
      } else if (name === "category_id") {
        state.capitalizationTableCategory = [];
      } else if (name === "subject_id") {
        state.capitalizationTableSubject = [];
      } else if (name === "brand_id") {
        state.capitalizationTableBrand = [];
      } else if (name === "barcode") {
        state.capitalizationTableBarcode = [];
      } else if (name === "all") {
        state.capitalizationTableArticle = [];
        state.capitalizationTableCategory = [];
        state.capitalizationTableSubject = [];
        state.capitalizationTableBrand = [];
        state.capitalizationTableBarcode = [];
      }
    },
    clearRemainsFbo: (state, action) => {
      state.remainsFbo = null;
    },
    clearRemainsFboTable: (state, action) => {
      const { name } = action.payload;
      if (name === "supplier_article") {
        state.remainsFboTableArticle = [];
      } else if (name === "category_id") {
        state.remainsFboTableCategory = [];
      } else if (name === "subject_id") {
        state.remainsFboTableSubject = [];
      } else if (name === "brand_id") {
        state.remainsFboTableBrand = [];
      } else if (name === "barcode_ref" || name === "barcode") {
        state.remainsFboTableBarcode = [];
      } else if (name === "all") {
        state.remainsFboTableArticle = [];
        state.remainsFboTableCategory = [];
        state.remainsFboTableSubject = [];
        state.remainsFboTableBrand = [];
        state.remainsFboTableBarcode = [];
      }
    },
    setTableScroll: (state, action) => {
      state.tableScroll = action.payload;
    },
    setRemainsFboTableSettings: (state, action) => {
      state.remainsFboTableSettings = action.payload;
    }
  },
  extraReducers: bulider => {
    bulider
      .addCase(InventoryWidgets.pending, state => {
        state.isInventoryWidgetsLoading = true;
      })

      .addCase(InventoryWidgets.fulfilled, (state, action) => {
        state.data = action.payload;
        state.widgets = [
          {
            id: 1,
            title: "Бюджет допоставки",
            value: prop("additional_delivery_value", action.payload),
            trip: "Общая стоимость товаров, подлежащих допоставке на срок загрузки склада, эквивалентном задаваемому горизонту прогноза"
          },
          {
            id: 2,
            title: "Упущенная выгода",
            value: prop("lost_profit", action.payload),
            trip: "Недополученная валовая маржа ввиду несвоевременного пополнения стока"
          },
          {
            id: 3,
            title: "Замороженная оборотка",
            value: prop("frozen_capital", action.payload),
            trip: "Стоимость запасов остающихся на складе по истечении задаваемого срока ввиду переизбытка стока либо низкой оборачиваемости товара"
          },
          {
            id: 4,
            title: "Потенциальные потери",
            value: prop("potential_lost", action.payload),
            trip: "Стоимость складских остатков с ограниченным сроком годности"
          }
        ];
        state.products = prop("additional_delivery_products", action.payload);

        state.calendars = prop("payment_calendar", action.payload);
        state.isInventoryWidgetsLoading = false;
      })

      .addCase(InventoryWidgets.rejected, state => {
        state.isInventoryWidgetsLoading = false;
        state.products = [];
      })
      .addCase(InventoryDynamic.pending, state => {
        state.isInventoryDynamicLoading = true;
      })
      .addCase(InventoryDynamic.fulfilled, (state, action) => {
        state.dynamics = action.payload;
        state.isInventoryDynamicLoading = false;
      })
      .addCase(InventoryDynamic.rejected, state => {
        state.isInventoryDynamicLoading = false;
        state.dynamics = [];
      })
      .addCase(InventoryTable.fulfilled, (state, action) => {
        const { response, group_by } = action.payload;
        if (group_by === "barcode") {
          state.warehouseTableBarcode = response.results;
          state.wareHouseBarcodeCount = response.count;
        } else if (group_by === "supplier_article") {
          state.wareHouseTableArticle = response.results;
          state.wareHouseArticleCount = response.count;
        }
      })
      .addCase(OptimizationTables.fulfilled, (state, action) => {
        if (action.payload) {
          const { response, group_by } = action.payload;
          if (group_by === "supplier_article") {
            state.optimizationTableArticle = response.results;
            state.optimizationTableArticleCount = response.count;
          } else if (group_by === "barcode") {
            state.optimizationTableBarcode = response.results;
            state.optimizationTableBarcodeCount = response.count;
          }
        }
      })
      .addCase(OptimizationTariffTable.pending, (state, action) => {
        if (action.payload) {
          state.isLoadingOptimizationTableTariff = true;
        }
      })
      .addCase(OptimizationTariffTable.fulfilled, (state, action) => {
        if (action.payload) {
          state.optimizationTableTariff = action.payload.response;
          state.optimizationTableTariffCount = action.payload.response.count;
          state.isLoadingOptimizationTableTariff = false;
        }
      })
      .addCase(OptimizationTariffTable.rejected, (state, action) => {
        if (action.payload) {
          state.isLoadingOptimizationTableTariff = true;
        }
      })
      .addCase(DistributionByWarehousesTable.fulfilled, (state, action) => {
        if (action.payload) {
          state.distributionByWarehousesTable = action.payload.response;
          state.distributionByWarehousesTableCount = action.payload.response.count;
        }
      })
      .addCase(OptimizationInventoryDistribution.pending, state => {
        state.isOptimizationDistributionLoading = true;
      })
      .addCase(OptimizationInventoryDistribution.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.optimizationDateRegions = action.payload;
          state.optimizationlocalRate = action.payload.map(elem =>
            elem.local_rate !== null ? (elem.local_rate * 100).toFixed(1) : "-"
          );

          state.optimizationRegionName = action.payload.map(elem => {
            return {
              regionName: elem.name !== null ? elem.name : "-",
              isExcluded: !elem.is_excluded
            };
          });

          state.clusters = action.payload.map(({ name = "-" }) => name);

          state.optimizationSalesShare = action.payload.map(elem =>
            elem.sales_share !== null ? (elem.sales_share * 100).toFixed(1) : "-"
          );
          state.optimizationSubjectShare = action.payload.map(elem =>
            elem.by_subject_share !== null ? (elem.by_subject_share * 100).toFixed(1) : "-"
          );
          state.optimizationPredQuanity = action.payload.map(elem =>
            elem.pred_quantity !== null ? elem.pred_quantity.toFixed(0) : "-"
          );
        }

        state.isOptimizationDistributionLoading = false;
      })
      .addCase(OptimizationInventoryDistribution.rejected, state => {
        state.isOptimizationDistributionLoading = false;
        state.optimizationDateRegions = [];
      })
      .addCase(CapitalizationWidgets.pending, state => {
        state.isCapitalizationWidgetsLoading = true;
      })
      .addCase(CapitalizationWidgets.fulfilled, (state, action) => {
        state.capitalizationWidgets = action.payload;
        state.isCapitalizationWidgetsLoading = false;
      })
      .addCase(CapitalizationWidgets.rejected, state => {
        state.isCapitalizationWidgetsLoading = false;
      })

      .addCase(CapitalizationDiagram.pending, (state, action) => {
        state.isCapitalizationDiagramLoading = true;
      })

      .addCase(CapitalizationDiagram.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.capitalizationDiagram = action.payload;
          state.isCapitalizationDiagramLoading = false;
        }
      })
      .addCase(CapitalizationDiagram.rejected, state => {
        state.isCapitalizationDiagramLoading = false;
      })
      .addCase(CapitalizationStockStructure.pending, state => {
        state.isCapitalizationStockStructureLoading = true;
      })
      .addCase(CapitalizationStockStructure.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.capitalizationStockStructure = action.payload;
        }
        state.isCapitalizationStockStructureLoading = false;
      })
      .addCase(CapitalizationStockStructure.rejected, state => {
        state.isCapitalizationStockStructureLoading = false;
      })

      .addCase(CapitalizationTable.fulfilled, (state, action) => {
        if (action.payload) {
          const { response, group_by } = action.payload;
          if (group_by === "supplier_article") {
            state.capitalizationTableArticle = response.results;
            state.capitalizationTableArticleCount = response.count;
          } else if (group_by === "category_id") {
            state.capitalizationTableCategory = response.results;
            state.capitalizationTableCategoryCount = response.count;
          } else if (group_by === "subject_id") {
            state.capitalizationTableSubject = response.results;
            state.capitalizationTableSubjectCount = response.count;
          } else if (group_by === "brand_id") {
            state.capitalizationTableBrand = response.results;
            state.capitalizationTableBrandCount = response.count;
          } else if (group_by === "barcode") {
            state.capitalizationTableBarcode = response.results;
            state.capitalizationTableBarcodeCount = response.count;
          }
        }
      })
      .addCase(GetRemainsFbo.pending, (state, action) => {
        state.isLoadingRemainsFbo = true;
      })
      .addCase(GetRemainsFbo.fulfilled, (state, action) => {
        if (action.payload) state.remainsFbo = action.payload;
        state.isLoadingRemainsFbo = false;
      })
      .addCase(GetRemainsFbo.rejected, (state, action) => {
        state.isLoadingRemainsFbo = false;
      })
      .addCase(GetRemainsFboTable.pending, state => {
        state.isLoadingRemainsFboTable = true;
      })
      .addCase(GetRemainsFboTable.fulfilled, (state, action) => {
        state.isLoadingRemainsFboTable = false;
        const { response, group_by } = action.payload;
        if (group_by === "supplier_article") {
          state.remainsFboTableArticle = formatRemainsFboTableData(response);
          state.remainsFboCountArticle = response.count;
        } else if (group_by === "subject_id") {
          state.remainsFboTableSubject = formatRemainsFboTableData(response);
          state.remainsFboCountSubject = response.count;
        } else if (group_by === "brand_id") {
          state.remainsFboTableBrand = formatRemainsFboTableData(response);
          state.remainsFboCountBrand = response.count;
        } else if (group_by === "category_id") {
          state.remainsFboTableCategory = formatRemainsFboTableData(response);
          state.remainsFboCountCategory = response.count;
        } else if (group_by === "barcode_ref") {
          state.remainsFboTableBarcode = formatRemainsFboTableData(response);
          state.remainsFboCountBarcode = response.count;
        }
      })
      .addCase(GetRemainsFboTable.rejected, state => {
        state.isLoadingRemainsFboTable = false;
      });
  }
});
export const {
  clearDate,
  clearTableWarehouse,
  wareHouseSettingsChecked,
  forcast,
  clearOptimizationTable,
  optimizationWeight,
  optimizationSalesShareWeight,
  optimizationSubjectWeight,
  optimizationClasterFilter,
  clearCapitalizationTable,
  perodDate,
  clearRemainsFbo,
  clearRemainsFboTable,
  setTableScroll,
  setRemainsFboTableSettings
} = inventoryManagmentSlice.actions;
export const inventoryManagmentSliceReducer = inventoryManagmentSlice.reducer;
