import React, { useCallback, useMemo } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import { useSnackbar } from "notistack";
import { defaultTo } from "ramda";
import { useLocation } from "react-router-dom";

import { betaPages, newPages } from "root/App/menu/miniBanners";
import LinkPure from "root/components/LinkPure";
import TooltipUI from "root/components/Tooltip";

import { TitleWithBanner } from "../utils";

import styles from "./index.module.scss";

const locationWithSearch = location => `${location.pathname}${location.search}`;

// --------------------------------------------------------------------
const DrawerSection = props => {
  const {
    item,
    onClick,
    onExpand,
    isNonClosing,
    isLastItem,
    isExpanded,
    setIsDrawerOpen,
    isMobile,
    open,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const isActive =
    item.type === "section"
      ? item.children.some(item => ~locationWithSearch(location).indexOf(item.url))
      : Boolean(~locationWithSearch(location).indexOf(item.url));

  const onClickDisabled = useCallback(
    (title, message) => {
      const defaultTitle = defaultTo("Раздел в разработке");
      const defaultMessage = defaultTo(
        "Совсем скоро вы сможете пользоваться этим функционалом. Как только раздел будет доступен, мы пришлем вам уведомление."
      );
      return enqueueSnackbar({
        title: defaultTitle(title),
        message: defaultMessage(message),
        variant: "success",
      });
    },
    [enqueueSnackbar]
  );

  return {
    item: item => (
      <LinkPure to={item.url} className={styles.item} onClick={onClick} data-active={isActive}>
        {isActive && <div className={styles.activeIndicator} />}
        {/* <div className={styles.dot} data-active={isActive} /> */}
        <PagesTitle url={item.url} isActiveDot={isActive} title={item.title} />
        {/* <div className={styles.label}>{item.title}</div> */}
      </LinkPure>
    ),
    section: item => (
      <div
        className={styles.section}
        data-active={isActive}
        data-open={open}
        onClick={isMobile ? undefined : () => !open && setIsDrawerOpen(true)}>
        <Divider className={styles.divider} />
        {isActive && <div className={styles.activeIndicator} />}
        <div
          className={styles.title}
          onClick={() => onExpand(item.title)}
          data-nonclosing={isNonClosing}>
          <item.icon className={styles.icon} />
          {/* <div className={styles.label}>{item.title}</div> */}
          <div className={styles.label}>
            <TitleWithBanner section={item} />
          </div>
          {!isNonClosing && (
            <ExpandMoreIcon className={styles.expandIcon} data-expanded={isExpanded} />
          )}
        </div>
        <Collapse in={open === false ? false : isExpanded} timeout={300}>
          {item.children.map(child => {
            const isActiveItem = Boolean(~locationWithSearch(location).indexOf(child.url));
            return child.disabled ? (
              child.hoverEffect ? (
                <TooltipUI dark={true} placement={"right"} title={child.hoverText}>
                  <div
                    key={child.url}
                    className={styles.item}
                    onClick={() =>
                      !child.onClickMessageDisabled &&
                      onClickDisabled(child.disabledTitle, child.disabledMessage)
                    }>
                    {/* <div className={styles.dot} /> */}
                    <PagesTitle url={child.url} title={child.title} />
                    {/* <div className={styles.label}>{child.title}</div> */}
                    <LockOutlinedIcon className={styles.lock} />
                  </div>
                </TooltipUI>
              ) : (
                <div
                  key={child.url}
                  className={styles.item}
                  onClick={() =>
                    !child.onClickMessageDisabled &&
                    onClickDisabled(child.disabledTitle, child.disabledMessage)
                  }>
                  {/* <div className={styles.dot} /> */}
                  <PagesTitle url={child.url} title={child.title} />
                  {/* <div className={styles.label}>{child.title}</div> */}
                  <LockOutlinedIcon className={styles.lock} />
                </div>
              )
            ) : child.onClickHandlerDisabled ? (
              <div key={child.url} className={styles.item}>
                {/* <div className={styles.dot} /> */}
                <PagesTitle url={child.url} title={child.title} />
                {/* <div className={styles.label}>{child.title}</div> */}
              </div>
            ) : (
              <LinkPure
                key={child.url}
                to={child.url}
                className={styles.item}
                onClick={onClick}
                data-active={isActiveItem}>
                {isActiveItem && <div className={styles.activeIndicator} />}
                {/* <div className={styles.dot} data-active={isActiveItem} /> */}
                <PagesTitle url={child.url} title={child.title} />
                {/* <div className={styles.label}>{child.title}</div> */}
              </LinkPure>
            );
          })}
        </Collapse>
        {isLastItem && <Divider className={styles.divider} />}
      </div>
    ),
    sectionItem: item =>
      item.disabled ? (
        <div className={styles.section} data-open={open} onClick={onClickDisabled}>
          <Divider className={styles.divider} />
          <div className={styles.title}>
            <item.icon className={styles.icon} />
            <div className={styles.label}>{item.title}</div>
            <LockOutlinedIcon className={styles.lock} />
          </div>
          {isLastItem && <Divider className={styles.divider} />}
        </div>
      ) : (
        <LinkPure to={item.url} onClick={onClick}>
          <div className={styles.section} data-active={isActive} data-open={open}>
            <Divider className={styles.divider} />
            {isActive && <div className={styles.activeIndicator} />}
            <div
              className={styles.title}
              onClick={() => onExpand(item.title)}
              //data-active={isActive}
            >
              <item.icon className={styles.icon} />
              <div className={styles.label}>{item.title}</div>
            </div>
            {isLastItem && <Divider className={styles.divider} />}
          </div>
        </LinkPure>
      ),
  }[item.type](item);
};

const PagesTitle = ({ url, isActiveDot, title }) => {
  const isBeta = useMemo(() => betaPages.pages.includes(url), [betaPages, url]);
  const isNew = useMemo(() => newPages.pages.includes(url), [betaPages, url]);
  return (
    <>
      <div className={styles.dot} data-active={isActiveDot}>
        {isNew ? (
          <Box className={styles.newMini}>new</Box>
        ) : isBeta ? (
          <Box className={styles.betaMini}>beta</Box>
        ) : null}
      </div>
      <div className={styles.label}>
        <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} gap={0.5}>
          {title}
          {isBeta && isNew && <Box className={styles.additionalBetaMini}>beta</Box>}
        </Box>
      </div>
    </>
  );
};

// const PageTitle = ({ url, title }) => {
//   return <div className={styles.label}>{title}</div>;
// };

export default DrawerSection;
