import React, { useState } from "react";

import { useSelector } from "react-redux";

import DiagramBarChards from "root/components/global/diagrama/diagram-bar-chards";
import DiagramHintBar from "root/components/global/diagrama/diagram-bar-hint";

import { checkNoDataValue } from "../../../../helpers";

import { numberWithSpaces } from "root/js/utils";

const PenaltyDiagramProduct = ({ tab }) => {
  const { penaltyDiagramProducts, currencyName } = useSelector(state => state.financeLogic);
  const [tooltipData, setTooltipData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getTooltipContent = (product_name, product_thumbnail, supplier_article, price, count) => {
    const titleValuePrice = "Сумма штрафов и компенсаций";
    const titleValueCount = "Количество штрафов и компенсаций";
    return (
      <DiagramHintBar
        article={supplier_article}
        articleText="Артикул"
        productNameText="Наименование"
        product={product_name}
        titleValue={titleValuePrice}
        value={price}
        titleValueSecond={titleValueCount}
        valueSecond={count}
        img={product_thumbnail}
      />
    );
  };

  const CustomTooltip = ({ active, payload, data }) => {
    if (active && payload && payload.length) {
      const {
        payload: { product_name, penalty, penalty_quantity, product_thumbnail, supplier_article, product_id }
      } = payload[0];

      const price = `${numberWithSpaces(penalty?.toFixed(0))} ${currencyName}`;
      const count = `${penalty_quantity?.toFixed(0)} шт`;
          if (selectedProduct !== product_id) setSelectedProduct(product_id);
          const ind = penaltyDiagramProducts.findIndex(el => el.product_id === product_id);
          if (activeIndex !== ind) setActiveIndex(ind);

      return getTooltipContent(product_name, product_thumbnail, supplier_article, price, count);
    } else if (data) {
      const product = penaltyDiagramProducts.find(el => el.product_id === data.value);
      const { product_name, penalty, penalty_quantity, product_thumbnail, supplier_article } = product;
      const price = `${numberWithSpaces(penalty?.toFixed(0))} ${currencyName}`;
      const count = `${penalty_quantity?.toFixed(0)} шт`;
      return getTooltipContent(product_name, product_thumbnail, supplier_article, price, count);
    }

    return null;
  };
  const title = `Топ ${penaltyDiagramProducts.length} ${
    tab === "penalty" ? "товаров по сумме штрафов и компенсаций" : "товаров по количеству штрафов и компенсаций"
  } `;
  const isEmptyDataValue = checkNoDataValue(penaltyDiagramProducts)

  return (
    <DiagramBarChards
      CustomTooltip={CustomTooltip}
      data={penaltyDiagramProducts}
      title={title}
      name="product_id"
      value="value"
      tab={tab}
      compaireText="penalty"
      width={470}
      height={penaltyDiagramProducts?.length * 80}
      leftYAxisWidth={60}
      rightYAxisWidth={85}
      valuet={currencyName}
      secondValuet="шт"
      products={penaltyDiagramProducts}
      setTooltipData={setTooltipData}
      tooltipData={tooltipData}
      currentProduct={selectedProduct}
      setCurrentProduct={setSelectedProduct}
      setExternalInd={setActiveIndex}
      externalInd={activeIndex}
      hasMinBarHeight
      hasProductLink
      color={"#CF3AA0"}
      isEmptyData={isEmptyDataValue}
    />
  );
};

export default PenaltyDiagramProduct;
