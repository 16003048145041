import request from "root/js/request";

export const getSocialBloggersRating = async ({ item__in, subject_id__in, ...rest }) => {
  const response = await request({
    url: `parsers/social/bloggers/rating/`,
    method: "get",
    params: {
      subject_id__in: subject_id__in || item__in,
      ...rest,
    },
  });
  return response.data;
};
