import request from "root/js/request";

const getAbcEconomicLogicLorenzeCurve = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  category_id__in,
  brand_id__in,
  subject_id__in,
  product_id__in,
  barcode__in,
  analyze_by,
  labels,
  xyz_labels,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/abc_analysis_lorenz_curve/",
    method: "get",
    params: {
      wb_api_key_ids,
      date_from,
      date_to,
      category_id__in,
      brand_id__in,
      subject_id__in,
      product_id__in,
      barcode__in,
      analyze_by,
      labels,
      xyz_labels,
      ...rest,
    },
  });
  return response.data;
};

export { getAbcEconomicLogicLorenzeCurve };
