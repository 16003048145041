import React from "react"

import Skeleton from "@mui/lab/Skeleton"


const SkeletonWhite = props => {
  const {  ...rest } = props

  return (
    <Skeleton
      variant='rectangular'
      sx={{ bgcolor: 'white' }}
      animation='wave'
      width='100%'
      height='100%'
      {...rest}
    ></Skeleton>
  )
}

export default SkeletonWhite
