import { lazy } from "react";

import CommissionStructure from "./components/commision-structure/app";
import KeyIndicators from "./components/key-indicators/app";
import PaidAcceptance from "./components/paid-acceptance/app";
import PenaltiesSurcharges from "./components/penalties-surcharges/app";
import PromotionMedia from "./components/promotion-media/app";
import promotionTopProductsCompanies from "./components/promotion-media/components/promotion-media-top-products/components/promotion-top-products-companies";
import promotionTopProductsFavorites from "./components/promotion-media/components/promotion-media-top-products/components/promotion-top-products-favorites";
import promotionTopProductsItems from "./components/promotion-media/components/promotion-media-top-products/components/promotion-top-products-items";
import promotionTopProductsOutsiders from "./components/promotion-media/components/promotion-media-top-products/components/promotion-top-products-outsiders";
import ReturnsRides from "./components/returns-rides/app";

const CommisionBarcodeTable = lazy(() =>
  import("./components/commision-structure/pages/commision-table/commision-barcode")
);
const CommisionBrandTable = lazy(() =>
  import("./components/commision-structure/pages/commision-table/commision-brand")
);
const CommisionCategoryTable = lazy(() =>
  import("./components/commision-structure/pages/commision-table/commision-category")
);
const CommisionHatchTable = lazy(() =>
  import("./components/commision-structure/pages/commision-table/commision-hatch")
);
const CommisionMonthTable = lazy(() =>
  import("./components/commision-structure/pages/commision-table/commision-month")
);
const CommisionVendorCodeTable = lazy(() =>
  import("./components/commision-structure/pages/commision-table/commision-vendor-code")
);
const Barcode = lazy(() =>
  import("./components/key-indicators/keyIndicatorsPages/table-structure/barcode")
);
const Brand = lazy(() =>
  import("./components/key-indicators/keyIndicatorsPages/table-structure/brand")
);
const Category = lazy(() =>
  import("./components/key-indicators/keyIndicatorsPages/table-structure/category")
);
const Hatch = lazy(() =>
  import("./components/key-indicators/keyIndicatorsPages/table-structure/hatch")
);
const Month = lazy(() =>
  import("./components/key-indicators/keyIndicatorsPages/table-structure/month")
);
const Subject = lazy(() =>
  import("./components/key-indicators/keyIndicatorsPages/table-structure/subject")
);
const VendorCode = lazy(() =>
  import("./components/key-indicators/keyIndicatorsPages/table-structure/vendor-code")
);
const ReturnBarcodeTable = lazy(() =>
  import("./components/returns-rides/pages/return-tables/return-barcode")
);
const ReturnBrandTable = lazy(() =>
  import("./components/returns-rides/pages/return-tables/return-brand")
);
const ReturnCategoryTable = lazy(() =>
  import("./components/returns-rides/pages/return-tables/return-category")
);
const ReturnHatchTable = lazy(() =>
  import("./components/returns-rides/pages/return-tables/return-hatch")
);
const ReturnMonthTable = lazy(() =>
  import("./components/returns-rides/pages/return-tables/return-month")
);
const ReturnStockTable = lazy(() =>
  import("./components/returns-rides/pages/return-tables/return-stock")
);
const ReturnVendorCode = lazy(() =>
  import("./components/returns-rides/pages/return-tables/return-vendor-code")
);
const PenaltyVenderCodeTable = lazy(() =>
  import("./components/penalties-surcharges/pages/penalty-tables/penalty-vendor-code")
);
const PenaltyStockTable = lazy(() =>
  import("./components/penalties-surcharges/pages/penalty-tables/penalty-stock")
);
const PenaltyMonthTable = lazy(() =>
  import("./components/penalties-surcharges/pages/penalty-tables/penalty-month")
);
const PenaltyHatchTable = lazy(() =>
  import("./components/penalties-surcharges/pages/penalty-tables/penalty-hatch")
);
const PenaltyCategoryTable = lazy(() =>
  import("./components/penalties-surcharges/pages/penalty-tables/penalty-category")
);
const PenaltyBrandTable = lazy(() =>
  import("./components/penalties-surcharges/pages/penalty-tables/penalty-brand")
);
const PenaltyBarcodeTable = lazy(() =>
  import("./components/penalties-surcharges/pages/penalty-tables/penalty-barcode")
);
const PenaltySubject = lazy(() =>
  import("./components/penalties-surcharges/pages/penalty-tables/penalty-subject")
);
const CommisionSubject = lazy(() =>
  import("./components/commision-structure/pages/commision-table/commision-subject")
);
const ReturnSubjectTable = lazy(() =>
  import("./components/returns-rides/pages/return-tables/return-subject")
);
const PromotionCompanyTable = lazy(() =>
  import(
    "./components/promotion-media/components/promotion-media-table/components/promotion-company-table"
  )
);
const PromotionKeyPhrasesTable = lazy(() =>
  import(
    "./components/promotion-media/components/promotion-media-table/components/promotion-key-phrases"
  )
);
const PromotionKeyPhrasesCatalogTable = lazy(() =>
  import(
    "./components/promotion-media/components/promotion-media-table/components/promotion-key-phrases-catalog"
  )
);
const PromotionDateTable = lazy(() =>
  import(
    "./components/promotion-media/components/promotion-media-table/components/promotion-date-table"
  )
);
const PromotionArticleTable = lazy(() =>
  import(
    "./components/promotion-media/components/promotion-media-table/components/promotion-article-table"
  )
);
const PromotionAppTypeTable = lazy(() =>
  import(
    "./components/promotion-media/components/promotion-media-table/components/promotion-app-type-table"
  )
);
const PromotionMonthTable = lazy(() =>
  import(
    "./components/promotion-media/components/promotion-media-table/components/promotion-month-table"
  )
);
const PromotionCompanyTypeTable = lazy(() =>
  import(
    "./components/promotion-media/components/promotion-media-table/components/promotion-company-type"
  )
);
const PaidTableArticle = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-article")
);
const PaidTableBarcode = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-barcode")
);
const PaidTableBrand = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-brand")
);
const PaidTableCategory = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-category")
);
const PaidTableOffice = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-office")
);
const PaidTableSubject = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-subject")
);
const PaidTableCoefs = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-coefs")
);
const PaidTableDetails = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-details")
);
const PaidTableMonth = lazy(() =>
  import("./components/paid-acceptance/components/paid-tables/components/paid-table-month")
);

export const tabs = [
  {
    keyIndex: 0,
    title: "Ключевые показатели",
    callName: "Key indicators",
    component: KeyIndicators,
  },
  {
    keyIndex: 1,
    title: "Возвраты и покатушки",
    callName: "Returns and rides",
    component: ReturnsRides,
  },
  {
    keyIndex: 2,
    title: "Штрафы и компенсации",
    callName: "Penalties and surcharges",
    component: PenaltiesSurcharges,
  },
  {
    keyIndex: 3,
    title: "Хранение и приёмка",
    component: PaidAcceptance,
  },
  {
    keyIndex: 4,
    title: "Продвижение и медиа",
    component: PromotionMedia,
  },
  {
    keyIndex: 5,
    title: "Комиссии и скидки",
    callName: "Commission structure",
    component: CommissionStructure,
  },
];

export const tables = [
  {
    keyIndex: 0,
    title: "Месяц",
    component: Month,
  },
  {
    keyIndex: 1,
    title: "Категория",
    component: Category,
  },

  {
    keyIndex: 2,
    title: "Предмет",
    component: Subject,
  },

  {
    keyIndex: 3,
    title: "Бренд",
    component: Brand,
  },

  {
    keyIndex: 4,
    title: "Артикул",
    component: VendorCode,
  },

  {
    keyIndex: 5,
    title: "Баркод",
    component: Barcode,
  },

  {
    keyIndex: 6,
    title: "Штрихкод",
    component: Hatch,
  },
];

export const deliveryReturnRides = [
  {
    keyIndex: 1,
    title: "По стоимости доставок",
  },
  {
    keyIndex: 2,
    title: "По количеству доставок",
  },
  // {
  //   keyIndex: 3,
  //   title: "(Применяется ко всем диаграммам)",
  //   disabled: true,
  //   hint: "Селектор предназначен для перевода денежного выражения в количественное и наоборот во всех диаграммах страницы"
  // }
];

export const commissionTopTabs = [
  {
    keyIndex: 1,
    title: "Количество артикулов",
  },
  {
    keyIndex: 2,
    title: "Количество реализованного товара",
  },
];

export const penaltyTabsSum = [
  {
    keyIndex: 1,
    title: "По сумме штрафов",
  },
  {
    keyIndex: 2,
    title: "По количеству штрафов",
  },
  // {
  //   keyIndex: 3,
  //   title: "(Применяется ко всем диаграммам)",
  //   disabled: true,
  //   hint: "Селектор предназначен для перевода денежного выражения в количественное и наоборот во всех диаграммах страницы"
  // }
];

export const penalties = [
  {
    keyIndex: 1,
    title: "По сумме штрафов",
  },
  {
    keyIndex: 2,
    title: "По количеству штрафов",
  },
];

export const returnTables = [
  {
    keyIndex: 0,
    title: "Месяц",
    component: ReturnMonthTable,
  },

  {
    keyIndex: 1,
    title: "Категория",
    component: ReturnCategoryTable,
  },

  {
    keyIndex: 2,
    title: "Предмет",
    component: ReturnSubjectTable,
  },

  {
    keyIndex: 3,
    title: "Бренд",
    component: ReturnBrandTable,
  },

  {
    keyIndex: 4,
    title: "Артикул",
    component: ReturnVendorCode,
  },

  {
    keyIndex: 5,
    title: "Баркод",
    component: ReturnBarcodeTable,
  },

  {
    keyIndex: 6,
    title: "Штрихкод",
    component: ReturnHatchTable,
  },

  {
    keyIndex: 7,
    title: "Склад",
    component: ReturnStockTable,
  },
];

export const tablesCommision = [
  {
    keyIndex: 0,
    title: "Месяц",
    component: CommisionMonthTable,
  },
  {
    keyIndex: 1,
    title: "Категория",
    component: CommisionCategoryTable,
  },

  {
    keyIndex: 2,
    title: "Предмет",
    component: CommisionSubject,
  },

  {
    keyIndex: 3,
    title: "Бренд",
    component: CommisionBrandTable,
  },

  {
    keyIndex: 4,
    title: "Артикул",
    component: CommisionVendorCodeTable,
  },
  {
    keyIndex: 5,
    title: "Баркод",
    component: CommisionBarcodeTable,
  },

  {
    keyIndex: 6,
    title: "Штрихкод",
    component: CommisionHatchTable,
  },
];

export const penaltyTables = [
  {
    keyIndex: 0,
    title: "Месяц",
    component: PenaltyMonthTable,
  },

  {
    keyIndex: 1,
    title: "Категория",
    component: PenaltyCategoryTable,
  },

  {
    keyIndex: 2,
    title: "Предмет",
    component: PenaltySubject,
  },

  {
    keyIndex: 3,
    title: "Бренд",
    component: PenaltyBrandTable,
  },

  {
    keyIndex: 4,
    title: "Артикул",
    component: PenaltyVenderCodeTable,
  },

  {
    keyIndex: 5,
    title: "Баркод",
    component: PenaltyBarcodeTable,
  },

  {
    keyIndex: 6,
    title: "Штрихкод",
    component: PenaltyHatchTable,
  },

  {
    keyIndex: 7,
    title: "Склад",
    component: PenaltyStockTable,
  },
];

export const penaltyColors = [
  "#CF3AA0",
  "#68B818",
  "#FCBC00",
  "#1E85FF",
  "#B983EF",
  "#2BBBF1",
  "#56AEC4",
  "#F79EC0",
  "#9EDEF7",
  "#BD916A",
];

export const promotionMediaCompanyFilterCheckbox = [
  // {
  //   id: 4,
  //   title: "В каталоге"
  // },
  // {
  //   id: 5,
  //   title: "В карточке товара"
  // },
  // {
  //   id: 6,
  //   title: "В поиске"
  // },
  // {
  //   id: 7,
  //   title: "На главной странице"
  // },
  {
    id: 8,
    // title: "Автоматическая РК"
    title: "Автоматическая кампания",
  },
  {
    id: 9,
    // title: "Поиск + каталог"
    title: "Аукцион",
  },
];

export const promotionMediaCompanyFilterHeaderCheckbox = [
  {
    id: 9,
    title: "Идут показы",
  },
  {
    id: 11,
    title: "На паузе",
  },
  {
    id: 7,
    title: "Завершено",
  },
];

export const promotionMediaTableTabe = [
  {
    keyIndex: 1,
    tabIndex: "month",
    title: "Месяц",
    component: PromotionMonthTable,
  },
  {
    keyIndex: 2,
    tabIndex: "advert_type",
    title: "Тип кампании",
    component: PromotionCompanyTypeTable,
  },
  {
    keyIndex: 3,
    tabIndex: "advert_id",
    title: "Кампания",
    component: PromotionCompanyTable,
  },
  {
    keyIndex: 4,
    tabIndex: "date",
    title: "Даты",
    component: PromotionDateTable,
  },
  {
    keyIndex: 5,
    tabIndex: "app_type",
    title: "Платформа",
    component: PromotionAppTypeTable,
  },
  {
    keyIndex: 6,
    tabIndex: "product_id",
    title: "Код WB",
    component: PromotionArticleTable,
  },
];

export const keyPhrasesTables = [
  {
    keyIndex: 0,
    title: "Ключевые фразы",
    component: PromotionKeyPhrasesTable,
  },
  // {
  //   keyIndex: 0,
  //   title: "Ключевые фразы (Автоматическая РК)",
  //   component: PromotionKeyPhrasesTable
  // },
  // {
  //   keyIndex: 1,
  //   title: "Ключевые фразы (Поиск + каталог)",
  //   component: PromotionKeyPhrasesCatalogTable
  // }
];

export const paidTabs = [
  {
    keyIndex: 1,
    title: "По стоимости хранения",
  },
  {
    keyIndex: 2,
    title: "По количеству товара, подлежащего тарифицированию",
  },
  // {
  //   keyIndex: 3,
  //   title: "(Применяется ко всем диаграммам)",
  //   disabled: true,
  //   hint: "Селектор предназначен для перевода денежного выражения в количественное и наоборот во всех диаграммах страницы"
  // }
];

export const paidTablesPage = [
  {
    keyIndex: 1,
    title: "Месяц",
    component: PaidTableMonth,
  },
  {
    keyIndex: 2,
    title: "Категория",
    component: PaidTableCategory,
  },

  {
    keyIndex: 3,
    title: "Предмет",
    component: PaidTableSubject,
  },

  {
    keyIndex: 4,
    title: "Бренд",
    component: PaidTableBrand,
  },

  {
    keyIndex: 5,
    title: "Артикул",
    component: PaidTableArticle,
  },
  {
    keyIndex: 6,
    title: "Баркод",
    component: PaidTableBarcode,
  },
  {
    keyIndex: 7,
    title: "Склад",
    component: PaidTableOffice,
  },
  {
    keyIndex: 8,
    title: "Коэффициенты хранения",
    component: PaidTableCoefs,
  },
  {
    keyIndex: 9,
    title: "Детализация",
    component: PaidTableDetails,
  },
];

export const promotionTopProductsTypes = [
  {
    keyIndex: 1,
    title: "Товары",
    component: promotionTopProductsItems,
  },
  {
    keyIndex: 2,
    title: "Кампании",
    component: promotionTopProductsCompanies,
  },
];

export const promotionTopProductsOrdering = [
  {
    keyIndex: 1,
    title: "Фавориты",
    component: promotionTopProductsFavorites,
  },
  {
    keyIndex: 2,
    title: "Аутсайдеры",
    component: promotionTopProductsOutsiders,
  },
];

export const promotionIndicators = [
  {
    keyIndex: "views",
    title: "Просмотры, шт.",
  },
  {
    keyIndex: "clicks",
    title: "Клики, шт.",
  },
  {
    keyIndex: "atbs",
    title: "Добавление в корзину, шт.",
  },
  {
    keyIndex: "sum",
    title: "Затраты, руб.",
  },
  {
    keyIndex: "sum_price",
    title: "Сумма заказов, руб.",
  },
  {
    keyIndex: "orders",
    title: "Заказы, шт.",
  },
  {
    keyIndex: "ctr",
    title: "CTR",
  },
  {
    keyIndex: "cpc",
    title: "CPC",
  },
  {
    keyIndex: "frq",
    title: "FRQ",
  },
  {
    keyIndex: "cr",
    title: "CR",
  },
  {
    keyIndex: "cpm",
    title: "CPM",
  },
  {
    keyIndex: "cpa",
    title: "CPA",
  },
  {
    keyIndex: "romi",
    title: "ROMI",
  },
  {
    keyIndex: "roas",
    title: "ROAS",
  },
  {
    keyIndex: "crr",
    title: "CRR",
  },
  {
    keyIndex: "car",
    title: "CAR",
  },
  {
    keyIndex: "gross_margin",
    title: "Валовая маржа",
  },
  {
    keyIndex: "advert_id",
    title: "ID кампании",
  },
];
