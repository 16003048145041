const GlobalNotificationsColors = {
  red: "#C54B6C",
  yellow: "#F7CE76",
  green: "#98D4BB",
  purple: "#cf3aa0",
};

export {
  GlobalNotificationsColors
}
