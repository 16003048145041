import React from "react";

import Debriefing from "root/icons/Debriefing";

// -------------------------------------------------------------------------
const debriefingSection = {
  type: "section",
  title: "Аналитика продаж",
  icon: Debriefing,
  noProd: true,
  children: [
    {
      url: "/sales-analytics-settings",
      component: React.lazy(() => import("root/pages/sales-analytics-settings/App")),
      title: "Настройки и данные",
    },
    {
      url: "/settings-debriefing",
      component: React.lazy(() => import("root/pages/settings-debriefing/App")),
      title: "Настройки OLD",
    },
    {
      title: "Wildberries",
      url: "/sales-analytics-wb",
      component: React.lazy(() => import("root/pages/sales-analytics-wb/App")),
    },
    {
      title: "Wildberries OLD",
      url: "/wildberries",
      component: React.lazy(() => import("root/pages/wildberries/App")),
    },
    {
      title: "Ozon",
      url: "/sales-analytics-ozon",
      component: React.lazy(() => import("root/pages/sales-analytics-ozon/App")),
    },
    {
      title: "Управление запасами",
      url: "/inventory-management",
      component: React.lazy(() => import("root/pages/inventory-management/App")),
    },
    {
      title: "Управление запасами v2",
      url: "/sales-analytics-warehouses",
      component: React.lazy(() => import("root/pages/sales-analytics-warehouses/App")),
    },
    // -------------------------------------------------------------------
    // Разделы ниже были в планах, необходимо проверить их актуальность и
    // скорее всего удалить
    // -------------------------------------------------------------------
    // {
    //   url: "/ai",
    //   component: React.lazy(() => import("root/pages/ai/App")),
    //   title: "AI “автопилот”",
    // },
    {
      url: "/business-analytics",
      component: React.lazy(() => import("root/pages/business-analytics/App")),
      title: "Бизнес-аналитика",
    },
    // {
    //   url: "/yandex",
    //   component: React.lazy(() => import("root/pages/yandex/App")),
    //   title: "Яндекс Маркет",
    // },
    // {
    //   url: "/products-monitoring",
    //   component: React.lazy(() => import("root/pages/products-monitoring/App")),
    //   title: "Мониторинг товаров",
    // },
    // {
    //   url: "/price-management",
    //   component: React.lazy(() => import("root/pages/price-management/App")),
    //   title: "Управление ценами",
    // },
    // {
    //   url: "/advertisement",
    //   component: React.lazy(() => import("root/pages/advertisement/App")),
    //   title: "Управление рекламой",
    // },
    // {
    //   url: "/wms",
    //   component: React.lazy(() => import("root/pages/WMS/App")),
    //   title: "Целевая WMS",
    // },
    // {
    //   url: "/faq",
    //   component: React.lazy(() => import("root/pages/FAQ/App")),
    //   title: "F.A.Q.",
    // }
    // {
    // 	url: "solution-fbs",
    // 	component: React.lazy(() => import("root/pages/solutions-for-fbs/App")),
    // 	title: "Решения для FBS",
    // },
    // {
    // 	url: "/inventory-management",
    // 	component: React.lazy(() => import("root/pages/support-services/App")),
    // 	title: "Инструменты нейросетей",
    // },
    // {
    // 	url: "/business-economic",
    // 	component: React.lazy(() => import("root/pages/business-economic/App")),
    // 	title: "Экономика бизнеса",
    // },
  ],
};

export default debriefingSection;
