import { postfixes, symbols } from "root/utils";

import { numberWithSpaces } from "root/js/utils";

// --------------------------- RENDER METHODS
const toFixed2Render = v => {
  if (isNaN(v)) return symbols.none;
  if (v % 1) {
    if ((v * 10) % 1) return numberWithSpaces((+v).toFixed(2));
    return numberWithSpaces((+v).toFixed(1));
  }
  return numberWithSpaces(v);
};

const bloggersProfilesSelectionParams = [
  {
    dataIndex: "frequency_avg",
    title: "Средняя частотность за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "valueble_posts_percent",
    title: "% публикаций, после которых заказы росли в течение первых 3-х дней",
    default: true,
  },
  {
    dataIndex: "proceeds_percent_avg",
    title: "Средний прирост выручки за 3 дня после публикации",
    postfix: postfixes.percent,
    default: true,
  },
  {
    dataIndex: "proceeds_avg",
    title: "Средняя выручка за 3 дня после публикации",
    default: true,
  },
  // {
  //   dataIndex: "proceeds_diff_sum",
  //   title: "Прирост выручки за 3 дня после публикации",
  //   default: true,
  // },
  {
    dataIndex: "proceeds_diff_avg",
    title: "Средний прирост выручки за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "orders_percent_avg",
    title: "Средний прирост количества заказов за 3 дня после публикации",
    default: true,
  },
  {
    dataIndex: "orders_avg",
    title: "Среднее количество заказов за 3 дня после публикации",
    default: true,
  },
  // {
  //   dataIndex: "orders_diff_sum",
  //   title: "Суммарная разница в количестве заказов за 3 дня после публикации",
  //   default: true,
  // },
  {
    dataIndex: "orders_diff_avg",
    title: "Средний прирост заказов за 3 дня после публикации",
    default: true,
  },
  // {
  //   dataIndex: "price_avg",
  //   title: "Средняя цена товара в периоде",
  //   default: true,
  // },
  {
    dataIndex: "posts_count",
    title: "Количество публикаций",
    default: true,
  },
  // {
  //   dataIndex: "like_avg",
  //   title: "Среднее количество лайков",
  //   default: true,
  // },
  // {
  //   dataIndex: "view_avg",
  //   title: "Среднее количество просмотров",
  //   postfix: postfixes.pcs,
  //   default: true,
  // },
  // {
  //   dataIndex: "comment_avg",
  //   title: "Среднее количество комментариев",
  //   postfix: postfixes.pcs,
  //   default: true,
  // },
  // {
  //   dataIndex: "subscriber_count",
  //   title: "Количество подписчиков",
  //   postfix: postfixes.pcs,
  //   default: true,
  // },
  // {
  //   dataIndex: "er_avg",
  //   title: "Средний ER",
  //   render: toFixed2Render,
  //   default: true,
  // },
  // {
  //   dataIndex: "er_avg",
  //   title: "Средний ERR",
  //   render: toFixed2Render,
  //   default: true,
  // },
].map(item => ({ ...item, sortable: true }));

const bloggersProfilesSelectionParamsForRedux = bloggersProfilesSelectionParams.reduce(
  (acc, item) => {
    acc[item.dataIndex + "__gte"] = "";
    acc[item.dataIndex + "__lte"] = "";
    return acc;
  },
  {}
);

const getDataForServerFromReduxBPrS = data => {
  const correctData = {};
  for (const key in data) {
    if (key.includes("lte") || key.includes("gte")) {
      // if (data[key]) correctData[key] = parseInt(data[key].slice(3).replace(/\s/g, ""));
      if (data[key]) correctData[key] = data[key];
    } else {
      switch (key) {
        case "source":
          correctData[key + "__in"] = data[key].length ? data[key].join() : undefined;
          break;
        case "account_name_hash":
        case "brand_id":
        case "product_id":
        case "item":
          correctData[key + "__in"] = data[key].length
            ? data[key].map(item => item.id).join()
            : undefined;
          break;
      }
    }
  }
  return correctData;
};

export {
  getDataForServerFromReduxBPrS,
  bloggersProfilesSelectionParams,
  bloggersProfilesSelectionParamsForRedux,
};
