const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  checkedLists: [],
  cachedCollections: [],
};

const searchQueryGraphicSlice = createSlice({
  name: "searchQueryGraphic",
  initialState,
  reducers: {
    toggleListChecked(state, action) {
      if (state.checkedLists.find(item => item.id === action.payload.id)) {
        state.checkedLists.push(action.payload);
      } else state.checkedLists = state.checkedLists.filter(item => item.id !== action.payload.id);
    },
    addListOnCheckedLists(state, action) {
      state.checkedLists.push(action.payload);
    },
    removeListFromCheckedLists(state, action) {
      state.checkedLists = state.checkedLists.filter(item => item.id !== action.payload.id);
    },
    addToCachedCollections(state, action) {
      state.cachedCollections.push(action.payload);
    },
    clearCachedCollections(state) {
      state.cachedCollections = [];
    },
  },
});

export default searchQueryGraphicSlice.reducer;
export const {
  toggleListChecked,
  addListOnCheckedLists,
  removeListFromCheckedLists,
  addToCachedCollections,
  clearCachedCollections,
} = searchQueryGraphicSlice.actions;
