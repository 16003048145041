import React, { useEffect, useMemo, useState } from "react";

import { Paper } from "@mui/material";
import { propOr } from "ramda";
import { WBapi } from "root/services/WBapi";
import { getServerDate } from "root/utils/date";

import RightDrawer from "root/components/RightDrawer";

import useDateRange from "root/hooks/useDateRange";

import ProductDrawerContent from "./ProductDrawerContent";

// ------------------------------------------------------------------------------------------------
const ProductDrawer = ({ isOpen, onClose = () => {}, productId, dateRange: parentDateRange }) => {
  const { dateRange: defaultDateRange } = useDateRange(30);
  const [dateRange, setDateRange] = useState(
    parentDateRange ? parentDateRange.map(item => new Date(item)) : defaultDateRange
  );

  const params = {
    product_ids: productId,
    product_ads_min: 500,
    date_from: getServerDate(dateRange[0]),
    date_to: getServerDate(dateRange[1]),
    extra_fields:
      // "product_ads,product_ads_dynamic,proceeds_dynamic,orders_dynamic,price_dynamic,quantity_dynamic,weighted_price_dynamic,damping_coefficient_dynamic",
      "product_ads,product_ads_dynamic,proceeds_dynamic,orders_dynamic,price_dynamic,quantity_dynamic",
  };

  const {
    data = { results: [{ dynamic: [] }] },
    isLoading: isL,
    isFetching: isF,
  } = WBapi.useGetProductsDynamicQuery({ params });

  // По историческим причинам мы перестраиваем структуру этих данных
  // ранее был менее удобный бек, а теперь, чтобы не перестраивать необходимо
  // изменить все дочерние компоненты
  const productDynamic = useMemo(() => {
    return propOr([], "dynamic", data?.results?.[0]);
  }, [data]);
  const socialNetworksDynamic = useMemo(
    () =>
      productDynamic
        .filter(item => item.product_ads_advertinfo)
        .map(item => {
          const value = {};
          for (const key in item.product_ads_advertinfo) {
            value[key] = item.product_ads_advertinfo[key].count;
          }
          return { date: item.date, value };
        }),
    [productDynamic]
  );

  const isLoading = useMemo(() => isL || isF, [isL, isF]);

  return (
    <RightDrawer {...{ isOpen, onClose }} zIndex={1000}>
      <Paper>
        <ProductDrawerContent
          data={data.results?.[0]}
          isLoading={isLoading}
          productId={productId}
          params={params}
          socialNetworks={socialNetworksDynamic}
          // maxDate={maxDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Paper>
    </RightDrawer>
  );
};

export default ProductDrawer;
