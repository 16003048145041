import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "root/js/api";

import { calcDate } from "root/pages/financial-logic/helpers";
const WBAPI = new API();

export const InventoryWidgets = createAsyncThunk(
  "inventory/widgets",
  async ({
    keys,
    categoryId,
    brandIds,
    subjectsId,
    with_seasonality,
    with_in_way_stocks,
    with_seasonality_spikes,
    with_mp_growth,
    with_safety_stock,
    with_company_plan,
    with_return_share,
    with_cancel_share,
    with_abc_xyz_cz_excluded,
    with_trend,
    forecast_period,
    articulIds,
    barcodeIds,

  }) => {
    const key = keys.join();
    const subject = subjectsId.join();
    const brand = brandIds.join();
    const category = categoryId.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();


    try {
      const response = await WBAPI.inventoryWidets({
        key,
        category,
        brand,
        subject,
        with_seasonality,
        with_in_way_stocks,
        with_seasonality_spikes,
        with_mp_growth,
        with_safety_stock,
        with_company_plan,
        with_return_share,
        with_cancel_share,
        with_abc_xyz_cz_excluded,
        with_trend,
        forecast_period,
        aritucl,
        barcod,

      });

      return response;
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const InventoryDynamic = createAsyncThunk(
  "inventory/dynamic",
  async ({ keys, brandIds, subjectsId, categoryId, articulIds, barcodeIds }) => {
    const key = keys.join();
    const subject = subjectsId.join();
    const brand = brandIds.join();
    const category = categoryId.join();
    const aritucl = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();


    try {
      const response = await WBAPI.inventoryDynamic({
        key,
        category,
        brand,
        subject,
        aritucl,
        barcod,

      });
      return response;
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const InventoryTable = createAsyncThunk(
  "inventory/table/wareHouse",
  async ({
    keys,
    categoryId,
    brandIds,
    subjectsId,
    group_by,
    sort,
    q,
    limit,
    offset,
    with_seasonality,
    with_in_way_stocks,
    with_seasonality_spikes,
    with_mp_growth,
    with_safety_stock,
    with_company_plan,
    with_return_share,
    with_cancel_share,
    with_abc_xyz_cz_excluded,
    with_trend,
    forecast_period,

    articulIds,
    barcodeIds,
    product_id__icontains
  }) => {
    try {
      const key = keys.join();
      const subject = subjectsId.join();
      const brand = brandIds.join();
      const category = categoryId.join();
      const article = JSON.stringify(articulIds);
      const barcod = barcodeIds.join();


      const response = await WBAPI.inventoryTable({
        key,
        brand,
        subject,
        category,
        group_by,
        sort,
        q,
        limit,
        offset,
        with_seasonality,
        with_in_way_stocks,
        with_seasonality_spikes,
        with_mp_growth,
        with_safety_stock,
        with_company_plan,
        with_return_share,
        with_cancel_share,
        with_abc_xyz_cz_excluded,
        with_trend,
        forecast_period,

        article,
        barcod,
        product_id__icontains
      });

      return { response, group_by };
    } catch (e) {
      return e.message;
    }
  }
);

export const OptimizationTables = createAsyncThunk(
  "optimizaton/table/",
  async ({
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    group_by,
    sort,
    limit,
    offset,
    q,
    by_subject_share_weight,
    sales_share_weight,
    optimizationClaster,
    period,
    isWareHouseSeasonality,
    isInWayStocks,
    isWareHouseSpikes,
    isGrowth,
    isTend,
    isABCExcluded,
    isCancelShare,
    isReturnShare,
    isCompanyPlan,
    fbo,
    sales_share_history_period,
    product_id__icontains
  }) => {
    try {
      const wb_api_key_ids = keys.join();
      const ids = categoryId.join();
      const brandsId = brandIds.join();
      const article = JSON.stringify(articulIds);
      const barcod = barcodeIds.join();

      const subject = subjectsId.join();
      const region = JSON.stringify(optimizationClaster.map(elem => elem.regionName));
      const isRegion = JSON.stringify(optimizationClaster.map(elem => elem.isExcluded));

      const response = await WBAPI.optimizationTable({
        group_by,
        ids,
        brandsId,
        article,
        barcod,

        subject,
        wb_api_key_ids,
        sort,
        limit,
        offset,
        q,
        by_subject_share_weight,
        sales_share_weight,
        exclude_region_clusters: region,
        is_excluded: isRegion,
        forecast_period: period,
        with_seasonality: isWareHouseSeasonality,
        with_in_way_stocks: isInWayStocks,
        with_seasonality_spikes: isWareHouseSpikes,
        with_mp_growth: isGrowth,
        with_trend: isTend,
        with_abc_xyz_cz_excluded: isABCExcluded,
        with_cancel_share: isCancelShare,
        with_return_share: isReturnShare,
        with_company_plan: isCompanyPlan,
        fbo_share: fbo / 100,
        sales_share_history_period,
        product_id__icontains
      });

      return { response, group_by };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const OptimizationTariffTable = createAsyncThunk(
  "optimizaton/tariff_table/",
  async ({ keys, period, sort }) => {
    try {
      const wb_api_key_ids = keys.join();

      const response = await WBAPI.optimizatioTariffTable({
        wb_api_key_ids,
        period,
        sort
      });

      return { response };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const DistributionByWarehousesTable = createAsyncThunk(
  "forecasting/warehouse_supply_distribution_by_warehouses/",
  async ({ keys }) => {
    try {
      const wb_api_key_ids = keys.join();

      const response = await WBAPI.distributionByWarehousesTable({
        wb_api_key_ids
      });

      return { response };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const OptimizationInventoryDistribution = createAsyncThunk(
  "optimizaon/distribition",
  async ({
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    sales_share_history_period,
    sales_share_weight,
    by_subject_share_weight,
    optimizationClaster,
    period,
    isWareHouseSeasonality,
    isInWayStocks,
    isWareHouseSpikes,
    isGrowth,
    isTend,
    isABCExcluded,
    isCancelShare,
    isReturnShare,
    isCompanyPlan,
    fbo
  }) => {
    try {
      const wb_api_key_ids = keys.join();
      const ids = categoryId.join();
      const brandsId = brandIds.join();
      const article = JSON.stringify(articulIds);
      const barcod = barcodeIds.join();

      const subject = subjectsId.join();
      const region = JSON.stringify(optimizationClaster.map(elem => elem.regionName));
      const isRegion = JSON.stringify(optimizationClaster.map(elem => elem.isExcluded));

      const response = await WBAPI.optimizationDistribution({
        ids,
        brandsId,
        article,
        barcod,

        subject,
        wb_api_key_ids,
        sales_share_history_period,
        sales_share_weight,
        by_subject_share_weight,
        region,
        isRegion,
        forecast_period: period,
        with_seasonality: isWareHouseSeasonality,
        with_in_way_stocks: isInWayStocks,
        with_seasonality_spikes: isWareHouseSpikes,
        with_mp_growth: isGrowth,
        with_trend: isTend,
        with_abc_xyz_cz_excluded: isABCExcluded,
        with_cancel_share: isCancelShare,
        with_return_share: isReturnShare,
        with_company_plan: isCompanyPlan,
        fbo_share: fbo / 100
      });

      return response;
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const CapitalizationWidgets = createAsyncThunk(
  "capitalization/widgets",
  async ({ keys, categoryId, brandIds, articulIds, barcodeIds, subjectsId, period }) => {
    const wb_api_key_ids = keys.join();

    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const article = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    const response = await WBAPI.capitalizationWidgets({
      ids,
      brandsId,
      article,
      barcod,

      subject,
      wb_api_key_ids,
      period
    });
    return response;
  }
);

export const CapitalizationDiagram = createAsyncThunk(
  "capitalization/diagram",
  async ({
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,

    dateFrom,
    dateTo
  }) => {
    const wb_api_key_ids = keys.join();

    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const article = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    const ONE_DAY = 24 * 60 * 60 * 1000;
    const prevDateTo = new Date(dateFrom - ONE_DAY);
    const prevDateFrom = new Date(prevDateTo - (dateTo - dateFrom));

    const currentRequest = WBAPI.capitalizationDiagram({
      ids,
      brandsId,
      article,
      barcod,
      subject,
      wb_api_key_ids,
      dateFrom,
      dateTo: calcDate(dateTo, 1)
    });
    const prevRequest = WBAPI.capitalizationDiagram({
      dateFrom: prevDateFrom,
      dateTo: calcDate(dateFrom, 1),
      ids,
      brandsId,
      article,
      barcod,
      subject,
      wb_api_key_ids
    });
    const [currentResponse, prevResponse] = await Promise.all([currentRequest, prevRequest]);
    const respToRespByDates = resp =>
      resp.reduce((acc, i) => {
        acc[i.date] = i;
        return acc;
      }, {});

    const curRespByDates = respToRespByDates(currentResponse);
    const prevRespByDates = respToRespByDates(prevResponse);

    const dateToDateKey = dt =>
      `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(
        -2
      )}`;
    const daysCount = (dateTo - dateFrom) / ONE_DAY;

    const data = Array.from({ length: daysCount }).map((_, i) => {
      const curDate = new Date(dateFrom.getTime() + ONE_DAY * i);
      const prevDate = new Date(prevDateFrom.getTime() + ONE_DAY * i);

      const curDateKey = dateToDateKey(curDate);
      const prevDateKey = dateToDateKey(prevDate);

      return {
        currentDate: curDateKey,
        // prevDate:prevDateKey,
        current: curRespByDates[curDateKey],
        prev: prevRespByDates[prevDateKey]
      };
    });

    return data;
  }
);

export const CapitalizationStockStructure = createAsyncThunk(
  "capitalization/stock_structure",
  async ({ keys, categoryId, brandIds, articulIds, barcodeIds, subjectsId, period }) => {
    const wb_api_key_ids = keys.join();

    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const article = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    const response = await WBAPI.capitalizationStockStructure({
      ids,
      brandsId,
      article,
      barcod,

      subject,
      wb_api_key_ids,
      period
    });
    return response;
  }
);

export const CapitalizationTable = createAsyncThunk(
  "capitalization/table",
  async ({
    keys,
    period,
    group_by,
    q,
    limit,
    offset,
    sort,
    categoryId,
    brandIds,
    subjectsId,

    articulIds,
    barcodeIds,
    product_id__icontains
  }) => {
    try {
      const key = keys.join();
      const ids = categoryId.join();
      const brandsId = brandIds.join();
      const aritucl = JSON.stringify(articulIds);
      const barcod = barcodeIds.join();

      const subject = subjectsId.join();

      const response = await WBAPI.capitalizationTable({
        key,
        period,
        group_by,
        q,
        limit,
        offset,
        sort,
        ids,
        brandsId,
        aritucl,
        barcod,

        subject,
        product_id__icontains
      });
      return { response, group_by };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const GetRemainsFbo = createAsyncThunk(
  "remains_fbo/stocks_total",
  async ({ keys, categoryId, brandIds, articulIds, barcodeIds, subjectsId }) => {
    try {
      const ids = categoryId.join() ? categoryId.join() : undefined;
      const brandsId = brandIds.join() ? brandIds.join() : undefined;
      const article = articulIds?.length > 0 ? JSON.stringify(articulIds) : undefined;
      const barcode = barcodeIds.join() ? barcodeIds.join() : undefined;

      const subject = subjectsId.join() ? subjectsId.join() : undefined;
      const response = await WBAPI.getRemainsFbo({
        keys,
        brandsId,
        article,
        barcode,

        subject,
        ids
      });
      return response;
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const GetRemainsFboTable = createAsyncThunk(
  "remains_fbo/table",
  async (
    {
      keys,
      group_by,
      sort,
      limit,
      offset,
      q,
      category_id__in,
      subject_id__in,
      brand_id__in,
      product_id__in,
      supplier_article__in,
      barcode_ref__in,
      product_id__icontains
    },
    thunkAPI
  ) => {
    const key = keys.join();

    try {
      const response = await WBAPI.getRemainsFboTable({
        key,
        group_by,
        sort,
        limit,
        offset,
        q,
        category_id__in,
        subject_id__in,
        brand_id__in,
        product_id__in,
        supplier_article__in,
        barcode_ref__in,
        product_id__icontains
      });

      return { group_by, response };
    } catch (e) {
      return thunkAPI.rejectWithValue("Error fetching dynamic list");
    }
  }
);
