import request from "root/js/request";

export const getWBDynamicKeywordsStatByIDDailyDynamic = async ({
  keyword_id,
  date_from,
  date_to,
  predict_trend,
  predict_trand = predict_trend,
  period,
  ...rest
}) => {
  const response = await request({
    url: `/wb_dynamic/keywords_stat/${keyword_id}/daily_dynamic/`,
    method: "get",
    params: {
      date_from,
      date_to,
      predict_trand,
      period,
    },
  });
  return response.data;
};
