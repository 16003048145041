import { useCallback, useMemo, useState } from "react";

import { format } from "date-fns";
import { isNil } from "ramda";
import { useSelector } from "react-redux";

import { dateToTimestamp, getDateRange } from "root/js/utils";

export default function useDateRange(days = 30, options = { byToday: false }) {
  const haveLastAvailableDate = useSelector(s => s.profile.config?.date_limit?.date_limit_enable);
  const lastAvailableDate = useSelector(s => s.profile.config?.date_limit?.date_limit_date_to);
  const maxDate = useMemo(() => {
    if (!haveLastAvailableDate || !lastAvailableDate) return null;
    const correctDate = lastAvailableDate.split(".").reverse().join("-");
    const dayBackCount = (new Date() - new Date(correctDate)) / (24 * 3600 * 1000);
    return Math.floor(dayBackCount);
  }, [haveLastAvailableDate, lastAvailableDate]);
  const { from, to } = getDateRange(days, maxDate);
  const byTodayDate = [new Date(Date.now() - days * 24 * 3600 * 1000), new Date(Date.now())];
  const defaultDateRange = options.defaultDateRange || (options.byToday ? byTodayDate : [from, to]);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  //
  const isDateRangeDefault = useMemo(() => {
    const isNilDateRange = isNil(dateRange?.[0]) || isNil(dateRange?.[1]);
    const isNilDefaultDateRange = isNil(defaultDateRange?.[0]) || isNil(defaultDateRange?.[1]);

    if (isNilDefaultDateRange && !isNilDateRange) return false;
    if (!isNilDefaultDateRange && isNilDateRange) return false;
    if (isNilDefaultDateRange && isNilDateRange) return true;

    return (
      format(defaultDateRange[0], "dd.MM.yyyy") === format(dateRange[0], "dd.MM.yyyy") &&
      format(defaultDateRange[1], "dd.MM.yyyy") === format(dateRange[1], "dd.MM.yyyy")
    );
  }, [dateRange]);

  const resetToDefaultDate = useCallback(() => setDateRange(defaultDateRange), [defaultDateRange]);

  const { dateFromISO, dateToISO } = useMemo(
    () => ({
      dateFromISO: isNil(dateRange?.[0])
        ? null
        : isNaN(new Date(dateRange[0]).valueOf())
        ? null
        : dateToTimestamp(dateRange[0]),
      dateToISO: isNil(dateRange?.[1])
        ? null
        : isNaN(new Date(dateRange[1]).valueOf())
        ? null
        : dateToTimestamp(dateRange[1]),
    }),
    [dateRange]
  );

  return {
    dateRange,
    setDateRange,
    dateFromISO,
    dateToISO,
    isDateRangeDefault,
    resetToDefaultDate,
  };
}
