import { useSnackbar } from "notistack";
import { path } from "ramda";
import { useDispatch } from "react-redux";
import { checkTariffExpired } from "root/store/user/userSlice";

import API from "root/js/api";

const WBAPI = new API();

export const useFetchErrorHandler = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  //
  return error => {
    const responseStatus = path(["response", "status"], error);
    switch (responseStatus) {
      case 401:
        WBAPI.resetToken();
        history.push(`/signin?redirect_url=${window.location.pathname}?${window.location.search}`);
        break;
      case 429:
        enqueueSnackbar({
          title: "Ошибка",
          message: "Превышен суточный лимит запросов. Вернитесь завтра",
          variant: "error",
          autoHideDuration: 3000,
        });
        break;
      case 403:
        dispatch(checkTariffExpired());
        break;
      default:
        enqueueSnackbar({
          title: "Ошибка",
          message:
            "Что-то пошло не так, пожалуйста обратитесь в службу поддержки или попробуйте повторить запрос позднее",
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 3000,
        });
    }
  };
};
