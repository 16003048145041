import React from "react";

import { Cell, Pie, PieChart, Tooltip } from "recharts";

import styles from "./index.module.scss";
import "./index.css";

const Circle = props => {
  const { data, colors, width, height, cx, cy, CustomizedCursor, wrapperStyle } = props;

  return (
    <div className="svg-circle">
      <PieChart
        width={width ? width : 380}
        height={height ? height : 380}
        className={styles.configCircle}
        style={{position: "inherit"}}
        >
        <Pie
          data={data}
          cx={cx ? cx : 160}
          cy={cy ? cy : 150}
          innerRadius={120}
          outerRadius={220}
          fill="#8884d8"
          paddingAngle={1}
          dataKey="value"
          minAngle={1}>
          {data?.map((entry, index) => {
            if (entry?.value) {
              return <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />;
            } else {
              return <Cell key={`cell-${index}`} fill="transparent" />;
            }
          })}
        </Pie>

        <Tooltip
          wrapperStyle={wrapperStyle ? wrapperStyle : { top: -65, left: -10 }}
          content={<CustomizedCursor />}
        />
      </PieChart>
    </div>
  );
};

export default Circle;
