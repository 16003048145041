import React from "react"

import classNames from "classnames"

import Square from "../icons/square"
import Triangle from "../icons/triangle"
import Trips from "../tips"

import { numberWithSpaces } from "root/js/utils"

import styles from "./index.module.scss"


const Card = props => {
	const {
		titleFirstLine,
		titleSecondLine,
		titleThirdLine,
		titleFourthLine,
		labelFirstLine,
		labelSecondLine,
		labelThirdLine,
		labelFourthLine,
		valueFirstLine,
		valueSecondLine,
		valueThirdLine,
		valueFourthLine,
		currencyFirstLine,
		currencySecondLine,
		currencyThirdLine,
		currencyFourthLine,
		tripFirstLine,
		tripSecondLine,
		tripThirdLine,
		tripFourthLine,
		customStyles,
		trip,
		percentFirstLine,
		percentSecondLine,
		percentThirdLine,
		percentFourthLine,
		unit,
		customFormat,
		withoutBorder,
		customFormatFourthLine,
		customFormatTirdLine,
		isFirstInvertedDirection,
		isFirstInvertedRotate,
		isSecondInvertedDirection,
		isSecondInvertedRotate,
		isThirdInvertedDirection
	} = props


	const borderIncrement = "4px solid #00C85A";
	const borderDicrement = "4px solid red";
	const transformRotateIncrement = "rotate(0deg)";
	const transformRotateDicrement = "rotate(90deg)";
	const colorIncrement = "#00C85A";
	const colorDicrement = "red";

	const getFirstValueColor = () => {
		if (
			percentFirstLine === 0 || valueFirstLine === null ||
			(percentFirstLine * 100)?.toFixed(1) === "0.0" ||
			(percentFirstLine * 100)?.toFixed(1) === "-0.0" ||
			percentFirstLine === Infinity ||
			percentFirstLine === -Infinity
		) {
			return "#FFA500";
		} else if (isFirstInvertedDirection) {
			return (percentFirstLine * 100)?.toFixed(1) < 0 ? colorIncrement : colorDicrement;
		} else {
			return (percentFirstLine * 100)?.toFixed(1) < 0 ? colorDicrement : colorIncrement;
		}
	};

	const getSecondValueColor = () => {
		if (
			percentSecondLine === 0 || valueSecondLine === null ||
			(percentSecondLine * 100)?.toFixed(1) === "0.0" ||
			(percentSecondLine * 100)?.toFixed(1) === "-0.0" ||
			percentSecondLine === Infinity ||
			percentSecondLine === -Infinity
		) {
			return "#FFA500";
		} else if (isSecondInvertedDirection) {
			return (percentSecondLine * 100)?.toFixed(1) < 0 ? colorIncrement : colorDicrement;
		} else {
			return (percentSecondLine * 100)?.toFixed(1) < 0 ? colorDicrement : colorIncrement;
		}
	};

	const getThirdValueColor = () => {
		if (
			percentThirdLine === 0 || valueThirdLine === null ||
			(percentThirdLine * 100)?.toFixed(1) === "0.0" ||
			(percentThirdLine * 100)?.toFixed(1) === "-0.0" ||
			percentThirdLine === Infinity ||
			percentThirdLine === -Infinity
		) {
			return "#FFA500";
		} else if (isThirdInvertedDirection) {
			return (percentThirdLine * 100)?.toFixed(1) < 0 ? colorIncrement : colorDicrement;
		} else {
			return (percentThirdLine * 100)?.toFixed(1) < 0 ? colorDicrement : colorIncrement;
		}
	};

	return (
		<div
			className={classNames(styles.childCardContainer, customStyles ? styles.noMarginTop : '')}
			style={{
				borderTop:
					withoutBorder ? 'transparent' :
						(percentFirstLine * 100)?.toFixed(1) < 0
							? borderDicrement
							: (percentFirstLine * 100)?.toFixed(1) === "0.0" || (percentFirstLine * 100)?.toFixed(1) === "-0.0" || percentFirstLine === 0 || percentFirstLine === Infinity || percentFirstLine === - Infinity
								? "4px solid #FFA500"
								: borderIncrement,
			}}
		>

			{/********************** First line ************************/}
			{titleFirstLine &&
				<div className={styles.alignItemsCenter}>
					<h4> {titleFirstLine}</h4>
					{trip && <Trips text={trip} />}
				</div>
			}

			<div className={styles.data}>
				<span>
					{labelFirstLine ?? ' '}
					{tripFirstLine && <Trips text={tripFirstLine} />}
				</span>

				{typeof valueFirstLine !== 'undefined' ?
					!isNaN(valueFirstLine) && valueFirstLine !== null ?
						currencyFirstLine ?
							<span className={styles.sum}>
								{currencyFirstLine === "%"
									? `${(valueFirstLine * 100)?.toFixed(1)}${currencyFirstLine}`
									: `${numberWithSpaces(valueFirstLine?.toFixed(0))} ${currencyFirstLine}`}

							</span>
							:
							<span className={styles.sum}>{numberWithSpaces(valueFirstLine?.toFixed(0))}</span>
						: '—'
					: ''
				}
			</div>

			{typeof percentFirstLine !== 'undefined' ?
				<div className={styles.childPercent}>
					{!isNaN(percentFirstLine) && percentFirstLine !== null ?
						<div className={styles.aligned}>
							<span
								style={{
									color:
										getFirstValueColor(),
								}}
							>

								{percentFirstLine === Infinity || percentFirstLine === -Infinity || valueFirstLine === null
									? "-"
									: percentFirstLine === 0 || (percentFirstLine * 100)?.toFixed(1) === "0.0" ||
										(percentFirstLine * 100)?.toFixed(1) === "-0.0"
										? "0"
										: `${(percentFirstLine * 100)?.toFixed(1)}${unit}`}

							</span>

							{percentFirstLine === 0 || valueFirstLine === null ||
								(percentFirstLine * 100)?.toFixed(1) === "0.0" ||
								(percentFirstLine * 100)?.toFixed(1) === "-0.0" || percentFirstLine === Infinity || percentFirstLine === -Infinity ? (
								<Square />
							) : (
								<Triangle
									result={percentFirstLine}
									colorIncrement={isFirstInvertedDirection ? colorDicrement : colorIncrement}
									colorDicrement={isFirstInvertedDirection ? colorIncrement : colorDicrement}
									transformRotateIncrement={isFirstInvertedRotate ? transformRotateDicrement : transformRotateIncrement}
									transformRotateDicrement={isFirstInvertedRotate ? transformRotateIncrement : transformRotateDicrement}
								/>
							)}
						</div>
						: <span>&nbsp;</span>
					}
				</div>
				: ''
			}

			{/********************** Second line ************************/}
			{titleSecondLine &&
				<div className={styles.alignItemsCenter}>
					<h4> {titleSecondLine}</h4>
					{trip && <Trips text={trip} />}
				</div>
			}

			<div className={styles.data}>
				<span>
					{labelSecondLine ?? ' '}
					{tripSecondLine && <Trips text={tripSecondLine} />}
				</span>

				{typeof valueSecondLine !== 'undefined' ?
					!isNaN(valueSecondLine) && valueSecondLine !== null ?
						(currencySecondLine ?
							<span className={styles.sum}>
								{currencySecondLine === "%"
									? `${(valueSecondLine * 100)?.toFixed(1)}${currencySecondLine}`
									: `${numberWithSpaces(valueSecondLine?.toFixed(0))} ${currencySecondLine}`}

							</span>
							:
							(
								customFormat ?
									<span className={styles.sum}>
										{customFormat}
									</span>
									:
									<span className={styles.sum}>
										{(valueSecondLine)?.toFixed(1)}
									</span>
							)
						)
						: '—'
					: ''
				}
			</div>

			{typeof percentSecondLine !== 'undefined' ?
				<div className={styles.childPercent}>
					{!isNaN(percentSecondLine) && percentSecondLine !== null ?
						<div className={styles.aligned}>
							<span
								style={{
									color:
										getSecondValueColor(),
								}}
							>

								{percentSecondLine === Infinity || percentSecondLine === -Infinity || valueSecondLine === null
									? "-"
									: (percentSecondLine * 100)?.toFixed(1) === "0.0" ||
										(percentSecondLine * 100)?.toFixed(1) === "-0.0"
										? "0"
										: `${(percentSecondLine * 100).toFixed(1)}${unit}`}

							</span>

							{percentSecondLine === 0 || valueSecondLine === null ||
								(percentSecondLine * 100)?.toFixed(1) === "0.0" ||
								(percentSecondLine * 100)?.toFixed(1) === "-0.0" || percentSecondLine === Infinity || percentSecondLine === -Infinity ? (
								<Square />
							) : (
								<Triangle
									result={percentSecondLine}
									colorIncrement={isSecondInvertedDirection ? colorDicrement : colorIncrement}
									colorDicrement={isSecondInvertedDirection ? colorIncrement : colorDicrement}
									transformRotateIncrement={isSecondInvertedRotate ? transformRotateDicrement : transformRotateIncrement}
									transformRotateDicrement={isSecondInvertedRotate ? transformRotateIncrement : transformRotateDicrement}
								/>
							)}
						</div>
						: <span>&nbsp;</span>
					}
				</div>
				: ''
			}

			{/********************** Third line ************************/}
			{titleThirdLine &&
				<div className={styles.alignItemsCenter}>
					<h4> {titleThirdLine}</h4>
					{trip && <Trips text={trip} />}
				</div>
			}
			<div className={styles.data}>
				<span>
					{labelThirdLine ?? ' '}
					{tripThirdLine && <Trips text={tripThirdLine} />}
				</span>

				{typeof valueThirdLine !== 'undefined' ?
					!isNaN(valueThirdLine) && valueThirdLine !== null ?
						currencyThirdLine ?
							<span className={styles.sum}>
								{currencyThirdLine === "%"
									?
									valueThirdLine === 0
										? `0${currencyThirdLine}`
										: `${(valueThirdLine * 100)?.toFixed(1)}${currencyThirdLine}`
									: valueThirdLine === 0
										? `0 ${currencyThirdLine}`
										: `${numberWithSpaces(valueThirdLine?.toFixed(0))} ${currencyThirdLine}`}

							</span>
							:
							<span className={styles.sum}>{numberWithSpaces(valueThirdLine?.toFixed(0))}</span>
						:
						customFormatTirdLine ?
							<span className={styles.sum}>{customFormatTirdLine}</span>
							: '—'
					: ''
				}
			</div>

			{typeof percentThirdLine !== 'undefined' ?
				<div className={styles.childPercent}>
					{!isNaN(percentThirdLine) && percentThirdLine !== null ?
						<div className={styles.aligned}>
							<span
								style={{
									color:
										getThirdValueColor(),
								}}
							>

								{percentThirdLine === Infinity || percentThirdLine === -Infinity || valueThirdLine === null
									? "-"
									: (percentThirdLine * 100)?.toFixed(1) === "0.0" ||
										(percentThirdLine * 100)?.toFixed(1) === "-0.0"
										? "0"
										: `${(percentThirdLine * 100)?.toFixed(1)}${unit}`}
							</span>

							{percentThirdLine === 0 || valueThirdLine === null ||
								(percentThirdLine * 100)?.toFixed(1) === "0.0" ||
								(percentThirdLine * 100)?.toFixed(1) === "-0.0" ||
								percentThirdLine === Infinity || percentThirdLine === -Infinity ? (
								<Square />
							) : (
								<Triangle
									result={percentThirdLine}
									colorIncrement={isThirdInvertedDirection ? colorDicrement : colorIncrement}
									colorDicrement={isThirdInvertedDirection ? colorIncrement : colorDicrement}
									transformRotateIncrement={transformRotateIncrement}
									transformRotateDicrement={transformRotateDicrement}
								/>
							)}
						</div>
						: <span>&nbsp;</span>
					}
				</div>
				: ''
			}

			{/********************** Fourth line ************************/}
			{titleFourthLine &&
				<div className={styles.alignItemsCenter}>
					<h4> {titleFourthLine}</h4>
					{trip && <Trips text={trip} />}
				</div>
			}

			<div className={styles.data}>
				<span>
					{labelFourthLine ?? ' '}
					{tripFourthLine && <Trips text={tripFourthLine} />}
				</span>

				{typeof valueFourthLine !== 'undefined' ?
					!isNaN(valueFourthLine) && valueFourthLine !== null ?
						currencyFourthLine ?
							<span className={styles.sum}>
								{currencyFourthLine === "%"
									?
									valueFourthLine === 0
										? `0${currencyFourthLine}`
										: `${(valueFourthLine * 100)?.toFixed(1)}${currencyFourthLine}`
									: valueFourthLine === 0
										? `0 ${currencyFourthLine}`
										: `${numberWithSpaces(valueFourthLine?.toFixed(0))} ${currencyFourthLine}`}

							</span>
							:
							<span className={styles.sum}>{numberWithSpaces(valueFourthLine?.toFixed(0))}</span>
						:
						customFormatFourthLine ?
							<span className={styles.sum}>{customFormatFourthLine}</span>
							: '—'
					: ''
				}

			</div>

			{typeof percentFourthLine !== 'undefined' ?
				<div className={styles.childPercent}>
					{!isNaN(percentFourthLine) && percentFourthLine !== null ?
						<div className={styles.aligned}>
							<span
								style={{
									color:
										(percentFourthLine * 100)?.toFixed(1) < 0
											? colorDicrement
											: percentFourthLine === 0 ||
												(percentFourthLine * 100)?.toFixed(1) === "0.0" ||
												(percentFourthLine * 100)?.toFixed(1) === "-0.0"
												|| percentFourthLine === Infinity || percentFourthLine === -Infinity || valueFourthLine === null
												? "#FFA500"
												: colorIncrement,
								}}
							>

								{percentFourthLine === Infinity || percentFourthLine === -Infinity || valueFourthLine === null
									? "-"
									: (percentFourthLine * 100)?.toFixed(1) === "0.0" ||
										(percentFourthLine * 100)?.toFixed(1) === "-0.0"
										? "0"
										: `${(percentFourthLine * 100)?.toFixed(1)}${unit}`}

							</span>

							{percentFourthLine === 0 || valueFourthLine === null ||
								(percentFourthLine * 100)?.toFixed(1) === "0.0" ||
								(percentFourthLine * 100)?.toFixed(1) === "-0.0" ||
								percentFourthLine === Infinity || percentFourthLine === -Infinity ? (
								<Square />
							) : (
								<Triangle
									result={percentFourthLine}
									colorIncrement={colorIncrement}
									colorDicrement={colorDicrement}
									transformRotateIncrement={transformRotateIncrement}
									transformRotateDicrement={transformRotateDicrement}
								/>
							)}
						</div>
						: <span>&nbsp;</span>
					}
				</div>
				: ''
			}
		</div>
	)
}

export default Card
