import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  promotions: [],
  currentShared: null,
  currentPromo: null,
  isFileUploaded: false,
};

const promotions = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    setPromotions(state, { payload: { value } }) {
      state.promotions = value;
    },
    setCurrentShared(state, { payload: { value } }) {
      state.currentShared = value;
    },
    setCurrentPromotion(state, { payload: { value } }) {
      state.currentPromo = value;
    },
    clearPromotions(state) {
      state.promotions = [];
    },
    clearCurrentShared(state) {
      state.currentShared = null;
    },
    clearCurrentPromo(state) {
      state.currentPromo = null;
    },
    setIsFileUploaded(state, { payload: { value } }) {
      state.isFileUploaded = value;
    },
  },
});

export default promotions.reducer;

export const {
  setPromotions,
  setCurrentShared,
  setCurrentPromotion,
  clearPromotions,
  clearCurrentShared,
  clearCurrentPromo,
  setIsFileUploaded,
} = promotions.actions;
