import React, { useState } from "react";

import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import classNames from "classnames";
import { Link } from "react-router-dom";

import TableImage from "../TableImage";
import Tooltip from "../Tooltip";

import ProductDrawer from "./ProductDrawer";

import styles from "./index.module.scss";

// ---------------------------------------------------------------------
// Немного запутано написанный компонент, по хорошему его бы структурировать и упростить,
// если будет время - надо сделать
// ---------------------------------------------------------------------
const ProductInTableContent = props => {
  const {
    text,
    article,
    articule = article,
    dateRange,
    isDrawerDisabled,
    image,
    brand,
    period = dateRange,
    alias,
    productLink = true,
    textWrap = false,
    link = true,
    className,
    classes = {},
    ...rest
  } = props;

  const [isProductDrawerOpen, setIsProductDrawerOpen] = useState(false);

  const useStyles = makeStyles(theme => {
    return {
      customTooltip: {
        background: `url('${image}')`, // Замените URL на свой
        backgroundSize: "100%",
        backgroundPosition: "cover",
        backgroundRepeat: "no-repeat",
        padding: theme.spacing(2),
        // width: "250px",
        // height: "max-content",
      },
    };
  });
  const tooltipClasses = image ? useStyles(image) : null;

  return (
    <>
      <Tooltip
        classes={{ tooltip: tooltipClasses ? tooltipClasses.customTooltip : null }}
        title={image !== 0 ? <img className={styles.modalImage} src={image} /> : ""}>
        <div className={classnames(styles.root, className, classes.root)} {...rest}>
          {image !== undefined && String(image) !== "0" && (
            <TableImage src={image} className={styles.image} />
          )}
          {productLink ? (
            <Link
              className={styles.text}
              target="_blank"
              to={`/${brand ? "brand" : "product"}/${
                dateRange
                  ? `${article}?dateRangeFrom=${dateRange[0]}&dateRangeTo=${dateRange[1]}`
                  : period
                  ? articule + "?dateRangeFrom=" + period[0] + "&dateRangeTo=" + period[1]
                  : articule
              }`}
              style={textWrap ? { whiteSpace: "pre-wrap" } : {}}>
              {text}
            </Link>
          ) : String(image) !== "0" && String(text) !== "0" ? (
            <span className={styles.text_black} style={textWrap ? { whiteSpace: "pre-wrap" } : {}}>
              {text}
            </span>
          ) : (
            ""
          )}
          <Box className={styles.productButtonsBox}>
            {link && String(articule) !== "0" && (
              <Tooltip
                enterDelay={101}
                enterNextDelay={101}
                title={`Нажмите, чтобы посмотреть этот ${
                  brand ? "бренд" : "товар"
                } на Wildberries`}>
                {brand ? (
                  <a
                    target="_blank"
                    href={`https://www.wildberries.ru/brands/${alias}`}
                    className={styles.wbLink}
                    rel="noopener noreferrer">
                    <LinkOutlinedIcon className={styles.icon} />
                  </a>
                ) : (
                  <a
                    target="_blank"
                    href={`https://www.wildberries.ru/catalog/${articule}/detail.aspx`}
                    className={styles.wbLink}
                    rel="noopener noreferrer">
                    <LinkOutlinedIcon className={styles.icon} />
                  </a>
                )}
              </Tooltip>
            )}
            {!brand && articule && (
              <Tooltip
                enterDelay={101}
                enterNextDelay={101}
                title={
                  isDrawerDisabled ? "Нет данных для заданного периода" : "Подробнее о товаре"
                }>
                <OpenInNewIcon
                  onClick={() => (isDrawerDisabled ? null : setIsProductDrawerOpen(true))}
                  className={classNames(
                    styles.productInNewTabIcon,
                    isDrawerDisabled ? styles.disabled : ""
                  )}
                />
              </Tooltip>
            )}
          </Box>
        </div>
      </Tooltip>
      {/* ---------------------------- TECHNICAL --------------------------------- */}
      {isProductDrawerOpen && (
        <ProductDrawer
          isOpen={isProductDrawerOpen}
          onClose={() => setIsProductDrawerOpen(false)}
          dateRange={dateRange}
          productId={articule}
        />
      )}
    </>
  );
};

export default ProductInTableContent;
