import React, { useEffect, useState } from "react";

import cn from "classnames";
import { batch, useDispatch, useSelector } from "react-redux";
import { REQUESTS_DEBOUNCE_TIME } from "root/js/constants";
import {
  AdvertDynamic,
  AdvertProductPosition,
  AdvertWidgetsCompany,
  GetHeatMapAsTable,
  MediaCircle,
} from "root/store/financialLogic/action";
import { useDebouncedCallback } from "use-debounce";

import API from "root/js/api";

import LinearLoading from "root/components/LinearLoading";

import { getLastWeek } from "../../../helpers";
import PromotionMediaDiagramDynamic from "../components/promotion-media-diagram-dynamic";
import PromotionMediaDynamicLinear from "../components/promotion-media-dynamic-linear";
import PromotionMediaHeader from "../components/promotion-media-header";
import PromotionMediaHeatMap from "../components/promotion-media-heat-map";
import PromotionMediaTable from "../components/promotion-media-table/app";
import PromotionMediaTopProducts from "../components/promotion-media-top-products";
import PromotionMediaWidgets from "../components/promotion-media-widgets";

import styles from "./index.module.scss";
const PromotionMedia = ({ setIsDisabledWeek }) => {
  const dispatch = useDispatch();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showPositionChart, setShowPositionChart] = useState(true);
  const [filterByAdvertStat, setFilterByAdvertStat] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [isLoadingMap, setIsLoadingMap] = useState(true);

  const WBAPI = new API();
  const lastWeek = getLastWeek();

  const {
    keys,
    dateFrom,
    dateTo,
    status,
    type,
    isLoadingPromotionWidgets,
    isLoadingAdvertCircle,
    isLoadingAdvertProducts,
    advertCircle,
    promotionWidgets,
    advertDynamics,
    advertProducts,
    advertProductsDynamic,
    isLoadingAdvertDynamics,
    categoryId,
    brandIds,
    heatMapAsTable,
    articulIds,
    productIds,
    barcodeIds,
    subjectsId,
    companies,
    promotionSelectedCompany,
  } = useSelector(state => state.financeLogic);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1441);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener on unmount
    };
  }, []);

  const checkWeek = async () => {
    try {
      const res = await WBAPI.advertTables({
        key: keys.join(),
        group_by: "product_id",
        dateFrom: lastWeek.dateFrom,
        dateTo: lastWeek.dateTo,
      });
      res?.count > 0 ? setIsDisabledWeek?.(false) : setIsDisabledWeek?.(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (keys?.length > 0) checkWeek();
  }, [keys, window.location.pathname]);

  const fetchDataDebounce = useDebouncedCallback(async () => {
    batch(() => {
      dispatch(
        AdvertDynamic({
          dateFrom,
          dateTo,
          status,
          type,
          keys,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
          companies:
            companies.length > 0 ? companies : promotionSelectedCompany.map(i => i.advert_id),
        })
      );

      dispatch(
        AdvertWidgetsCompany({
          dateFrom,
          dateTo,
          status,
          type,
          keys,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
          companies:
            companies.length > 0 ? companies : promotionSelectedCompany.map(i => i.advert_id),
        })
      );
      dispatch(
        MediaCircle({
          dateFrom,
          dateTo,
          keys,
          status,
          type,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
          companies:
            companies.length > 0 ? companies : promotionSelectedCompany.map(i => i.advert_id),
        })
      );
    });
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0 && dateFrom && status?.length > 0 && type?.length > 0) {
      fetchDataDebounce.callback();
    }
  }, [
    dateFrom,
    keys,
    status,
    type,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    subjectsId,
    promotionSelectedCompany,
    companies,
  ]);

  const fetchProductDebounce = useDebouncedCallback(async () => {
    dispatch(
      AdvertProductPosition({
        dateFrom,
        dateTo,
        status,
        type,
        keys,
        categoryId,
        brandIds,
        articulIds,
        barcodeIds,
        subjectsId,
        companies:
          companies.length > 0 ? companies : promotionSelectedCompany.map(i => i.advert_id),
      })
    );
    setIsLoadingProduct(false);
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(
    () => {
      if (keys.length > 0 && dateFrom && status?.length > 0 && type?.length > 0) {
        setIsLoadingProduct(true);
        fetchProductDebounce.callback();
      }
    },
    [
      dateFrom,
      keys,
      status,
      type,
      categoryId.join(),
      brandIds.join(),
      subjectsId.join(),
      barcodeIds.join(),
      productIds.join(),
    ],
    companies
  );

  const fetchMapDebounce = useDebouncedCallback(async () => {
    dispatch(
      GetHeatMapAsTable({
        dateFrom,
        dateTo,
        key: keys.join(),
        brand_id__in: brandIds.join(),
        category_id__in: categoryId.join(),
        subject_id__in: subjectsId.join(),
        barcode__in: barcodeIds.join(),
        product_id__in: productIds.join(),
        filter_by_advert_stat: filterByAdvertStat,
      })
    );
    setIsLoadingMap(false);
  }, REQUESTS_DEBOUNCE_TIME);

  useEffect(() => {
    if (keys.length > 0 && dateFrom) {
      fetchMapDebounce.callback();
      setIsLoadingMap(true);
    }
  }, [
    dateFrom,
    keys,
    categoryId.join(),
    brandIds.join(),
    articulIds.join(),
    productIds.join(),
    subjectsId.join(),
    barcodeIds.join(),
    filterByAdvertStat,
  ]);

  const noDataCondition = !(
    isLoadingPromotionWidgets ||
    isLoadingAdvertCircle ||
    isLoadingAdvertProducts ||
    isLoadingProduct ||
    isLoadingMap ||
    advertCircle?.length !== 0 ||
    advertProducts?.length !== 0 ||
    advertProductsDynamic?.length !== 0 ||
    heatMapAsTable?.length !== 0
  );

  return (
    <div className={styles.promotionMedia}>
      <PromotionMediaHeader
        noDataCondition={noDataCondition}
        setShowPositionChart={setShowPositionChart}
      />

      <div className={styles.innerBox}>
        {noDataCondition ? (
          <div className={styles.noDataMessage}>
            <div className={styles.box}>
              <div className={styles.text}>
                Для заданных параметров фильтрации затраты на продвижение отсутствуют
              </div>
            </div>
          </div>
        ) : (
          <>
            {isLoadingPromotionWidgets || !promotionWidgets ? (
              <div className={cn(styles.boxLoader, styles.widgetSize)}>
                <LinearLoading className={styles.loader} />
              </div>
            ) : (
              <PromotionMediaWidgets promotionWidgets={promotionWidgets} />
            )}
            <>
              <div className={styles.dynamicAndCircleBox}>
                <div className={styles.dynamicAndLinearBox}>
                  {isLoadingAdvertDynamics || !advertDynamics ? (
                    <div className={cn(styles.boxLoader, styles.dynamicSize)}>
                      <LinearLoading className={styles.loader} />
                    </div>
                  ) : (
                    <PromotionMediaDynamicLinear advertDynamics={advertDynamics} />
                  )}

                  {isLoadingAdvertDynamics || !advertDynamics ? (
                    <div className={cn(styles.boxLoader, styles.dynamicSize)}>
                      <LinearLoading className={styles.loader} />
                    </div>
                  ) : (
                    <PromotionMediaDiagramDynamic advertDynamics={advertDynamics} />
                  )}
                </div>

                <div className={styles.topProductsBox}>
                  {isLoadingAdvertProducts || isLoadingProduct ? (
                    <div className={cn(styles.boxLoader, styles.topSize)}>
                      <LinearLoading className={styles.loader} />
                    </div>
                  ) : (
                    <PromotionMediaTopProducts />
                  )}
                </div>
              </div>
            </>

            <PromotionMediaTable
              status={status}
              type={type}
              showPositionChart={showPositionChart}
            />

            <PromotionMediaHeatMap
              filterByAdvertStat={filterByAdvertStat}
              setFilterByAdvertStat={setFilterByAdvertStat}
              isLoadingMap={isLoadingMap}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PromotionMedia;
