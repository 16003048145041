import { createSlice } from "@reduxjs/toolkit";
import { prop } from "ramda";

import {
  calcDate,
  formatDate,
  formatHeatMapData,
  getMonthIndex,
} from "root/pages/financial-logic/helpers";
import { penaltyColors } from "root/pages/financial-logic/info";

import {
  AdvertDynamic,
  AdvertKeywordsByKeywordsTable,
  AdvertKeywordsTable,
  AdvertProductPosition,
  AdvertTables,
  AdvertTablesHints,
  AdvertTop,
  AdvertWidgetsCompany,
  CommisionDiagrams,
  CommisionTable,
  CommisionTopProducts,
  CommisionWidgets,
  CommisionWidgets2,
  CommissionDiscountByCategories,
  CommissionDiscountTopDetails,
  GetAdvertsByDates,
  GetBarcodesSearch,
  GetBarcodesSearchForPaidStorage,
  GetFinancialLogicBrand,
  GetFinancialLogicBrandForPaidStorage,
  GetFinancialLogicBrandForPromotion,
  GetFinancialLogicCategories,
  GetFinancialLogicKey,
  GetHeatMapAsTable,
  GetKeyIndicatorsWidgets,
  GetProductSearch,
  GetProductSearchForPaidStorage,
  GetProductSearchForPromotion,
  GetPromotionKeyPhrasesCatalogTable,
  GetRealizationReportArticle,
  GetRealizationReportArticleAdvert,
  GetRealizationReportArticlePaidStorage,
  GetSubjects,
  GetSubjectsForPaidStorage,
  GetSubjectsForPromotion,
  GetSupplierArticles,
  GetSupplierArticlesForPaidStorage,
  GetSupplierArticlesForPromotion,
  KeyIndicatorsDynamic,
  keyIndicatorsOtherWbStructure,
  KeyIndicatorsServiceStructrueWB,
  KeyIndicatorsTable,
  MediaCircle,
  PaidDiagram,
  PaidOfficesCircle,
  PaidProducts,
  PaidStorageCircles,
  PaidStorageWarehouseCoefsTable,
  PaidStorageWarehouseDetailsTable,
  PaidTable,
  PaidWidget,
  PenaltyAdditionalsLinesDynamic,
  PenaltyAdditionalsPenaltyTypes,
  PenaltyAdditionalsWidgets,
  PenaltyDiagramProducts,
  PenaltyOffices,
  PenaltyTables,
  PenaltyTypes,
  RiteReturnDiagrama,
  RiteReturnsWidgets,
  RiterReturnDynamicProduct,
  RiterReturnOffices,
  RiterReturnTables,
  SurchargesAdditionalsWidgets,
} from "./action";

import { getAxisDate } from "root/js/utils";

const initialState = {
  apiKeys: [],
  keys: [],
  disabledKeys: [],
  currencyName: "",

  date: [],
  mounth: [],
  datePicker: {},
  tableKey: "",
  mounthsDate: [],

  dateFrom: {},
  dateTo: {},
  financialLogicBrand: [],
  brandIds: [],
  transferValue: 0,
  wbServiceWithCharges: 0,

  financialLogicCategory: [],
  categoryId: [],

  financialLogicProductSearch: [],

  financialLogicBarcodeSearch: [],
  barcodeIds: [],

  tableArticule: [],
  tableSubject: [],
  tableMonth: [],
  tableBrand: [],
  tableProduct: [],
  tableBarcode: [],
  tableShk: [],

  keyIndicatorsTableArticle: [],
  keyIndicatorsTableSubjects: [],
  keyIndicatorsTableMounth: [],
  isLoadingKeyIndicatorsTableMounth: false,
  keyIndicatorsTableHatch: [],
  keyIndicatorsTableBrands: [],
  keyIndicatorsTablesBarcode: [],
  keyIndicatorsTablesCategory: [],

  financialLogicArticle: [],

  articulIds: [],
  productIds: [],

  subjects: [],
  subjectsId: [],

  keyIndicatorsWidgets: null,
  isLoadingKeyIndicatorsWidgets: false,

  keyWBServicesStructure: [],
  keyServiceStructureCircle: {},

  dynamicRevenue: [],

  commisionWidgets: {},
  commisionWidgets2: {},
  isLoadingCommisionWidgets: false,
  isLoadingCommisionWidgets2: false,

  commisionDiscount: [],
  isLoadingCommisionDiscount: true,

  commisionTopProducts: {},
  isLoadingCommisionTopProducts: false,

  commisionTopProductsDetails: [],
  isLoadingCommisionTopProductsDetails: false,

  commisionCircle: [],

  commisionDiagram: [],
  isLoadingCommisionDiagrams: false,

  commisionTableArticle: [],
  commisionTableSubjects: [],
  commisionTableMounth: [],
  commisionTableHatch: [],
  commisionTableBrands: [],
  commisionTablesBarcode: [],
  commisionTablesCategory: [],

  riteReturnsWidgets: {},
  isLoadingReturnWidgets: false,
  riteReturnsDiagram: null,
  isLoadingReturnsDiagram: false,
  riterReturnOffices: null,
  isLoadingReturnOffices: false,

  returnTableArticle: [],
  returnTableSubjects: [],
  returnTableMounth: [],
  returnTableHatch: [],
  returnTableBrands: [],
  returnTablesBarcode: [],
  returnTablesCategory: [],
  returnTableStock: [],

  riterReturnDynamicProduct: null,
  isLoadingRiterReturnDynamicProduct: false,

  penaltyWidgets: null,
  isLoadingPenaltyWidgets: false,
  surchargesWigets: {},
  isLoadingSurchargesWidgets: false,

  penaltyDiagramLine: null,
  isLoadingPenaltyDiagramLine: false,

  penaltyDynamic: [],
  isLoadingPenaltyDynamic: false,

  penaltyCicrle: [],
  isLoadingPenaltyCicrle: false,

  penaltyTypes: [],
  isLoadingPenaltyTypes: false,

  penaltyDiagramProducts: [],
  isLoadingPenaltyDiagramProducts: false,
  penaltyTableArticle: [],
  penaltyTableSubjects: [],
  penaltyTableMounth: [],
  penaltyTableHatch: [],
  penaltyTableBrands: [],
  penaltyTablesBarcode: [],
  penaltyTablesCategory: [],
  penaltyTableStock: [],

  penaltyReasoneImg: [],
  penaltyTypeName: [],
  singlePenaltyType: [],
  typeNames: [],

  status: [],
  type: [],
  promotionWidgets: null,
  isLoadingPromotionWidgets: false,
  advertDynamics: null,
  isLoadingAdvertDynamics: false,
  advertProducts: null,
  isLoadingAdvertProduct: false,
  advertProductsDynamic: null,
  advertTableCompany: [],
  advertTablePhrases: [],
  advertTopProducts: [],
  advertTopProductsCount: 0,
  advertTableDate: [],
  isLoadingAdvertDate: false,
  advertTableArticle: [],
  advertTableAppType: [],
  advertCircle: null,
  isLoadingAdvertCircle: false,
  advertTableMonth: [],
  advertTableMonthCount: 0,
  advertTableCompanyType: [],
  advertTableCompanyTypeCount: 0,

  advertTableHints: [],

  paidProducts: null,
  isLoadingPaidProduct: false,
  paidWigets: null,
  isLoadingPaidWigets: false,
  paidDiagram: null,
  isLoadingPaidDiagram: false,
  paidStorageCircle: null,
  isLoadingPaidStorageCircle: false,
  paidOfficesCircles: null,
  isLoadingPaidOfficesCircles: false,

  paidArticleTable: [],
  paidCategoryTable: [],
  paidSubjectTable: [],
  paidOfficeTable: [],
  paidBrandTable: [],
  paidBarcodeTable: [],
  paidCoefsTable: [],
  paidDetailsTable: [],
  paidMonthTable: [],
  paidMonthTableCount: 0,

  autocompleteListHeight: 0,
  isLoadingAdvertProducts: false,
  abcLabels: "0.8:A,0.15:B,0.05:C",
  xyzLabels: "0.1:X,0.25:Y,inf:Z",
  companies: [],
  indicators: [],
  promotionSelectedCompany: [],

  realizationReportArticle: [],
  realizationReportProducts: [],
  promotionKeyPhrasesCatalogTable: [],
  promotionKeyPhrasesCatalogTableCount: 0,
  heatMapAsTable: [],
  isLoadingHeatMapAsTable: false,
};

const _setDate = (state, dateFrom, dateTo) => {
  state.dateFrom = formatDate(dateFrom);
  state.dateTo = calcDate(formatDate(new Date(dateTo.getTime() + 1000 * 60 * 60 * 24)), 1);
};

const financialLogicSlice = createSlice({
  name: "financial/logic",
  initialState,
  reducers: {
    addKeysFinancialLogic: (state, action) => {
      state.keys = action.payload;

      const currency = state.apiKeys.filter(item => state.keys.includes(item.id));

      let elem = currency.map(elem => elem.currency_name);

      state.currencyName = elem[elem.length - 1];
    },

    addDisabledKeys: (state, action) => {
      state.disabledKeys = action.payload;
    },
    disabledCheckbox: (state, action) => {
      if (action.payload) {
        state.isSelectValuetDisabled = false;
      } else {
        state.isSelectValuetDisabled = true;
      }
    },
    getLastMounth: (state, action) => {
      const { start, end } = action.payload;

      state.dateFrom = formatDate(start);
      state.dateTo = calcDate(formatDate(end), 1);
    },

    getIdCategory: (state, action) => {
      state.categoryId = action.payload;
    },
    getSubjectId: (state, action) => {
      state.subjectsId = action.payload;
    },
    getIdBrand: (state, action) => {
      state.brandIds = action.payload;
    },
    getIdArticul: (state, action) => {
      state.articulIds = action.payload;
    },
    getIdProducts: (state, action) => {
      state.productIds = action.payload;
    },
    getIdBarcod: (state, action) => {
      state.barcodeIds = action.payload;
    },

    getMonthPeriod: (state, action) => {
      if (action.payload.length === 0) {
        return;
      }
      const startMonth = action.payload[0];
      const endMonth = action.payload[action.payload.length - 1];
      const monthYear = startMonth.match(/\d+/)[0];
      const result = startMonth.replace(/\d+/g, "").replace(/\s/g, "");
      function getEnglishMonthName(russianMonth) {
        const russianMonths = [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ];

        const englishMonths = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        const index = russianMonths.indexOf(russianMonth);

        if (index !== -1) {
          return englishMonths[index];
        } else {
          return null;
        }
      }
      const russianMonth = result;
      const englishMonth = getEnglishMonthName(russianMonth);

      if (englishMonth) {
        const startDate = new Date(`1 ${englishMonth} ${monthYear}`);
        const finishDate = new Date(
          endMonth.slice(-4),
          getMonthIndex(endMonth.split(" ")[0]) + 1,
          1
        );

        state.dateFrom = formatDate(startDate);
        state.dateTo = calcDate(formatDate(finishDate), 1);
      } else {
        console.error("Unknown month name:", russianMonth);
      }
    },

    mounthDate: (state, action) => {
      const dateList = [];
      const data = action.payload;

      data.map(elem => {
        const minDate = new Date(elem.minDate);
        const maxDate = new Date(elem.maxDate);
        while (minDate < maxDate) {
          dateList.push(new Date(minDate));

          minDate.setMonth(minDate.getMonth() + 1, 1);
        }
        return dateList;
      });

      dateList.map(elem => {
        const currentDate = new Date(elem);
        const monthName = currentDate.toLocaleString("ru", { month: "long" });
        const year = currentDate.getFullYear();
        const capitalizedMonthName = `${
          monthName.charAt(0).toLocaleUpperCase() + monthName.slice(1)
        } ${year}`;

        if (!state.mounthsDate.includes(capitalizedMonthName)) {
          state.mounthsDate.push(capitalizedMonthName);
        }
      });
    },

    getDatePicker: (state, action) => {
      _setDate(state, action.payload[0], action.payload[1]);

      state.mounth.length = 0;
      const monthsInRussian = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ];

      let currentDate = new Date(state.dateFrom);

      while (currentDate <= state.dateTo) {
        const monthName = monthsInRussian[currentDate.getMonth()];
        state.mounth.push(monthName);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    },

    prevLastWeek: (state, action) => {
      const { start, end } = action.payload;
      _setDate(state, start, end);
      state.mounth.length = 0;
    },

    clearSelectors: state => {
      state.articulIds = [];
      state.productIds = [];
      state.brandIds = [];
      state.mounth = [];

      state.barcodeIds = [];
      state.categoryId = [];
      state.subjectsId = [];
    },

    clearSelector: (state, action) => {
      if (action.payload === "categories") state.categoryId = [];
      else if (action.payload === "subject") state.subjectsId = [];
      else if (action.payload === "brand") state.brandIds = [];
      else if (action.payload === "article") {
        state.articulIds = [];
        state.productIds = [];
      } else if (action.payload === "barcode") state.barcodeIds = [];
    },
    addCheckboxTypeName: (state, action) => {
      state.penaltyTypeName = action.payload;
    },
    singlePenalty: (state, action) => {
      state.singlePenaltyType = action.payload;
    },
    clearAllDate: state => {
      state.isLoadingCommisionDiscount = true;

      state.keyIndicatorsWidgets = null;
      state.dynamicRevenue = [];
      state.keyServiceStructureCircle = {};
      state.commisionWidgets = {};
      state.commisionWidgets2 = {};
      state.commisionDiscount = [];
      state.commisionDiagram = [];
      state.penaltyWidgets = null;
      state.penaltyTableSubjects = [];
      state.penaltyTableMounth = [];
      state.penaltyTableHatch = [];
      state.penaltyTableBrands = [];
      state.penaltyTablesBarcode = [];
      state.penaltyTablesCategory = [];
      state.penaltyTableStock = [];
      state.penaltyTableArticle = [];
      (state.keyIndicatorsTableArticle = []), (state.commisionTableSubjects = []);
      state.commisionTableMounth = [];
      state.commisionTableHatch = [];
      state.commisionTableBrands = [];
      state.commisionTablesBarcode = [];
      state.commisionTablesCategory = [];
      state.commisionTableSubjects = [];
      state.commisionTableArticle = [];
      state.returnTableSubjects = [];
      state.returnTableMounth = [];
      state.returnTableHatch = [];
      state.returnTableBrands = [];
      state.returnTablesBarcode = [];
      state.returnTablesCategory = [];
      state.returnTableStock = [];
      state.keyIndicatorsTableSubjects = [];
      state.keyIndicatorsTableMounth = [];
      state.keyIndicatorsTableHatch = [];
      state.keyIndicatorsTableBrands = [];
      state.keyIndicatorsTablesBarcode = [];
      state.keyIndicatorsTablesCategory = [];
      state.returnTableArticle = [];
      state.advertTableCompany = [];
      state.advertTableCompanyType = [];
      state.advertTableDate = [];
      state.advertTableMonth = [];
      state.advertTableArticle = [];
      state.advertTableAppType = [];
      state.paidArticleTable = [];
      state.paidCategoryTable = [];
      state.paidSubjectTable = [];
      state.paidOfficeTable = [];
      state.paidBrandTable = [];
      state.paidBarcodeTable = [];
      state.promotionKeyPhrasesCatalogTable = [];
      state.advertTablePhrases = [];
      state.paidWigets = null;
      state.paidOfficesCircles = null;
      state.paidStorageCircle = null;
      state.paidDiagram = null;
      state.promotionWidgets = null;
      state.advertDynamics = null;
    },

    keyIndicatorsClearTable: (state, action) => {
      const { name } = action.payload;
      if (name === "supplier_article") {
        state.keyIndicatorsTableArticle = [];
      } else if (name === "subject_id") {
        state.keyIndicatorsTableSubjects = [];
      } else if (name === "month") {
        state.keyIndicatorsTableMounth = [];
      } else if (name === "brand_id") {
        state.keyIndicatorsTableBrands = [];
      } else if (name === "barcode") {
        state.keyIndicatorsTablesBarcode = [];
      } else if (name === "category_id") {
        state.keyIndicatorsTablesCategory = [];
      } else if (name === "shk_id") {
        state.keyIndicatorsTableHatch = [];
      }
    },
    returnClearTable: (state, action) => {
      const { name } = action.payload;
      if (name === "supplier_article") {
        state.returnTableArticle = [];
      } else if (name === "subject_id") {
        state.returnTableSubjects = [];
      } else if (name === "month") {
        state.returnTableMounth = [];
      } else if (name === "brand_id") {
        state.returnTableBrands = [];
      } else if (name === "barcode") {
        state.returnTablesBarcode = [];
      } else if (name === "category_id") {
        state.returnTablesCategory = [];
      } else if (name === "shk_id") {
        state.returnTableHatch = [];
      } else if (name === "office_name") {
        state.returnTableStock = [];
      }
    },
    penaltyClearTable: (state, action) => {
      const { name } = action.payload;
      if (name === "supplier_article") {
        state.penaltyTableArticle = [];
      } else if (name === "subject_id") {
        state.penaltyTableSubjects = [];
      } else if (name === "month") {
        state.penaltyTableMounth = [];
      } else if (name === "brand_id") {
        state.penaltyTableBrands = [];
      } else if (name === "barcode") {
        state.penaltyTablesBarcode = [];
      } else if (name === "category_id") {
        state.penaltyTablesCategory = [];
      } else if (name === "shk_id") {
        state.penaltyTableHatch = [];
      } else if (name === "office_name") {
        state.penaltyTableStock = [];
      }
    },
    commisionClearTable: (state, action) => {
      const { name } = action.payload;
      if (name === "supplier_article") {
        state.commisionTableArticle = [];
      } else if (name === "subject_id") {
        state.commisionTableSubjects = [];
      } else if (name === "month") {
        state.commisionTableMounth = [];
      } else if (name === "brand_id") {
        state.commisionTableBrands = [];
      } else if (name === "barcode") {
        state.commisionTablesBarcode = [];
      } else if (name === "category_id") {
        state.commisionTablesCategory = [];
      } else if (name === "shk_id") {
        state.commisionTableHatch = [];
      }
    },
    promotionMediaFilter: (state, action) => {
      const { name, value } = action.payload;
      if (name === "status") {
        state.status = value;
      } else if (name === "type") {
        state.type = value;
      } else if (name === "company") {
        state.companies = value;
      } else if (name === "indicator") {
        state.indicators = value;
      }
    },
    promotianMedioaClearTable: (state, action) => {
      const { name } = action.payload;
      if (name === "advert_id") {
        state.advertTableCompany = [];
      } else if (name === "date") {
        state.advertTableDate = [];
      } else if (name === "product_id") {
        state.advertTableArticle = [];
      } else if (name === "app_type") {
        state.advertTableAppType = [];
      } else if (name === "phrases") {
        state.advertTablePhrases = [];
      } else if (name === "top") {
        state.advertTopProducts = [];
      } else if (name === "phrases_catalog") {
        state.promotionKeyPhrasesCatalogTable = [];
      } else if (name === "month") {
        state.advertTableMonth = [];
      } else if (name === "advert_type") {
        state.advertTableCompanyType = [];
      }
      state.isLoadingAdvertDate = false;
    },
    paidClearTable: (state, action) => {
      const { name } = action.payload;
      if (name === "supplier_article") {
        state.paidArticleTable = [];
      } else if (name === "category_id") {
        state.paidCategoryTable = [];
      } else if (name === "subject_id") {
        state.paidSubjectTable = [];
      } else if (name === "office_name") {
        state.paidOfficeTable = [];
      } else if (name === "brand_id") {
        state.paidBrandTable = [];
      } else if (name === "barcode") {
        state.paidBarcodeTable = [];
      } else if (name === "coefs") {
        state.paidCoefsTable = [];
      } else if (name === "details") {
        state.paidDetailsTable = [];
      } else if (name === "month") {
        state.paidMonthTable = [];
      }
    },
    setAutocompleteListRef: (state, action) => {
      state.autocompleteListHeight = action.payload;
    },
    setABCLabels: (state, action) => {
      state.abcLabels = action.payload;
    },
    setXYZLabels: (state, action) => {
      state.xyzLabels = action.payload;
    },
    setKeyIndicatorsTableArticleWithHierarchy: (state, action) => {
      state.keyIndicatorsTableArticle = action.payload;
    },
    setReturnTableArticleWithHierarchy: (state, action) => {
      state.returnTableArticle = action.payload;
    },
    setPenaltyTableArticleWithHierarchy: (state, action) => {
      state.penaltyTableArticle = action.payload;
    },
    setPaidArticleTableWithHierarchy: (state, action) => {
      state.paidArticleTable = action.payload;
    },
    setComissionTableArticleWithHierarchy: (state, action) => {
      state.commisionTableArticle = action.payload;
    },
  },
  extraReducers: bulider => {
    bulider

      .addCase(GetFinancialLogicKey.fulfilled, (state, action) => {
        state.apiKeys = action.payload;
        state.date = action.payload.map(elem => elem.create_date);
      })
      .addCase(GetFinancialLogicBrand.fulfilled, (state, action) => {
        if (action.payload) {
          const { response, brand } = action.payload;
          state.financialLogicBrand = response;
          const arrBrand = brand.filter(el => response.find(item => el.id === item.id));
          if (arrBrand && brand && arrBrand.length !== brand.length) {
            state.brandIds = arrBrand?.map(el => el.id);
          }
        }
      })
      .addCase(GetFinancialLogicBrandForPromotion.fulfilled, (state, action) => {
        if (action.payload) {
          const { response, brand } = action.payload;
          state.financialLogicBrand = response;
          const arrBrand = brand.filter(el => response.find(item => el.id === item.id));
          if (arrBrand && brand && arrBrand.length !== brand.length) {
            state.brandIds = arrBrand?.map(el => el.id);
          }
        }
      })
      .addCase(GetFinancialLogicBrandForPaidStorage.fulfilled, (state, action) => {
        if (action.payload) {
          const { response, brand } = action.payload;
          state.financialLogicBrand = response;
          const arrBrand = brand.filter(el => response.find(item => el.id === item.id));
          if (arrBrand && brand && arrBrand.length !== brand.length) {
            state.brandIds = arrBrand?.map(el => el.id);
          }
        }
      })
      .addCase(keyIndicatorsOtherWbStructure.fulfilled, (state, action) => {
        const { transfer_value, wb_service_with_charges } = action.payload;

        state.transferValue = transfer_value;
        state.wbServiceWithCharges = wb_service_with_charges;
      })
      .addCase(GetFinancialLogicCategories.fulfilled, (state, action) => {
        const collator = new Intl.Collator("ru", { sensitivity: "base" });
        if (action.payload) {
          const { response, categories } = action.payload;
          state.financialLogicCategory = response;
          const arrCategories = categories.filter(el => response.find(item => el.id === item.id));
          if (arrCategories && categories && arrCategories.length !== categories.length) {
            state.categoryId = arrCategories?.map(el => el.id);
          }
        }
      })
      .addCase(GetSubjects.fulfilled, (state, action) => {
        const { response, subject } = action.payload;
        state.subjects = response;
        const arrSubjects = subject.filter(el => response.find(item => el.id === item.id));
        if (subject && arrSubjects && arrSubjects.length !== subject.length) {
          state.subjectsId = arrSubjects.map(el => el.id);
        }
      })
      .addCase(GetSubjectsForPromotion.fulfilled, (state, action) => {
        const { response, subject } = action.payload;
        state.subjects = response;
        const arrSubjects = subject.filter(el => response.find(item => el.id === item.id));
        if (subject && arrSubjects && arrSubjects.length !== subject.length) {
          state.subjectsId = arrSubjects.map(el => el.id);
        }
      })
      .addCase(GetSubjectsForPaidStorage.fulfilled, (state, action) => {
        const { response, subject } = action.payload;
        state.subjects = response;
        const arrSubjects = subject.filter(el => response.find(item => el.id === item.id));
        if (subject && arrSubjects && arrSubjects.length !== subject.length) {
          state.subjectsId = arrSubjects.map(el => el.id);
        }
      })

      .addCase(GetProductSearch.fulfilled, (state, action) => {
        if (action.payload) {
          state.financialLogicProductSearch = action.payload
            .slice()
            .sort((a, b) => a.product_id - b.product_id);
        }
      })
      .addCase(GetProductSearchForPromotion.fulfilled, (state, action) => {
        if (action.payload) {
          state.financialLogicProductSearch = action.payload
            .slice()
            .sort((a, b) => a.product_id - b.product_id);
        }
      })
      .addCase(GetProductSearchForPaidStorage.fulfilled, (state, action) => {
        if (action.payload) {
          state.financialLogicProductSearch = action.payload
            .slice()
            .sort((a, b) => a.product_id - b.product_id);
        }
      })
      .addCase(GetBarcodesSearch.fulfilled, (state, action) => {
        if (action.payload) {
          const { response, barcode } = action.payload;
          const res = response.slice().sort((a, b) => {
            return ("" + a.barcode).localeCompare(b.barcode);
          });
          state.financialLogicBarcodeSearch = res;
          const arrBarcode = barcode.filter(el => res.find(item => el.barcode === item.barcode));
          if (arrBarcode && barcode && arrBarcode.length !== barcode.length) {
            state.barcodeIds = arrBarcode.map(el => el.barcode);
          }
        }
      })
      .addCase(GetBarcodesSearchForPaidStorage.fulfilled, (state, action) => {
        if (action.payload) {
          const { response, barcode } = action.payload;
          const res = response.slice().sort((a, b) => {
            return ("" + a.barcode).localeCompare(b.barcode);
          });
          state.financialLogicBarcodeSearch = res;
          const arrBarcode = barcode.filter(el => res.find(item => el.barcode === item.barcode));
          if (arrBarcode && barcode && arrBarcode.length !== barcode.length) {
            state.barcodeIds = arrBarcode.map(el => el.barcode);
          }
        }
      })
      .addCase(GetSupplierArticles.fulfilled, (state, action) => {
        if (action.payload) {
          state.financialLogicArticle = action.payload.slice().sort((a, b) => {
            const supplierA = a.supplier_article.toLowerCase();
            const supplierB = b.supplier_article.toLowerCase();
            const articleA = a.product_id.toLowerCase();
            const articleB = b.product_id.toLowerCase();
            if (supplierA < supplierB || articleA < articleB) {
              return -1;
            }
            if (supplierA > supplierB || articleA > articleB) {
              return 1;
            }
            return 0;
          });
        }
      })
      .addCase(GetSupplierArticlesForPromotion.fulfilled, (state, action) => {
        if (action.payload) {
          state.financialLogicArticle = action.payload.slice().sort((a, b) => {
            const supplierA = a.supplier_article.toLowerCase();
            const supplierB = b.supplier_article.toLowerCase();
            const articleA = a.product_id.toLowerCase();
            const articleB = b.product_id.toLowerCase();
            if (supplierA < supplierB || articleA < articleB) {
              return -1;
            }
            if (supplierA > supplierB || articleA > articleB) {
              return 1;
            }
            return 0;
          });
        }
      })
      .addCase(GetSupplierArticlesForPaidStorage.fulfilled, (state, action) => {
        if (action.payload) {
          state.financialLogicArticle = action.payload.slice().sort((a, b) => {
            const supplierA = a.supplier_article.toLowerCase();
            const supplierB = b.supplier_article.toLowerCase();
            const articleA = a.product_id.toLowerCase();
            const articleB = b.product_id.toLowerCase();
            if (supplierA < supplierB || articleA < articleB) {
              return -1;
            }
            if (supplierA > supplierB || articleA > articleB) {
              return 1;
            }
            return 0;
          });
        }
      })

      .addCase(GetKeyIndicatorsWidgets.pending, state => {
        state.isLoadingKeyIndicatorsWidgets = true;
      })

      .addCase(GetKeyIndicatorsWidgets.fulfilled, (state, action) => {
        state.keyIndicatorsWidgets = action.payload;
        state.isLoadingKeyIndicatorsWidgets = false;
      })

      .addCase(GetKeyIndicatorsWidgets.rejected, state => {
        state.isLoadingKeyIndicatorsWidgets = false;
      })
      .addCase(KeyIndicatorsTable.pending, state => {
        state.isLoadingKeyIndicatorsTableMounth = true;
      })
      .addCase(KeyIndicatorsTable.fulfilled, (state, action) => {
        state.isLoadingKeyIndicatorsTableMounth = false;
        const { response, value } = action.payload;
        if (value === "supplier_article") {
          state.keyIndicatorsTableArticle = response.results;
          state.articleCountKey = response.count;
        } else if (value === "subject_id") {
          state.keyIndicatorsTableSubjects = response.results;
          state.subjectCountKey = response.count;
        } else if (value === "month") {
          state.keyIndicatorsTableMounth = response.results;
          state.mounthCountKey = response.count;
        } else if (value === "brand_id") {
          state.keyIndicatorsTableBrands = response.results;
          state.brandCountKey = response.count;
        } else if (value === "shk_id") {
          state.keyIndicatorsTableHatch = response.results;
          state.hatchCountKey = response.count;
        } else if (value === "category_id") {
          state.keyIndicatorsTablesCategory = response.results;
          state.categoryCountKey = response.count;
        } else if (value === "barcode") {
          state.keyIndicatorsTablesBarcode = response.results;
          state.barcodeCountKey = response.count;
        }
      })

      .addCase(KeyIndicatorsDynamic.fulfilled, (state, action) => {
        state.dynamicRevenue = action.payload;
      })

      .addCase(KeyIndicatorsServiceStructrueWB.fulfilled, (state, action) => {
        state.keyServiceStructureCircle = action.payload;
      })

      .addCase(CommisionWidgets.pending, state => {
        state.isLoadingCommisionWidgets = true;
      })

      .addCase(CommisionWidgets2.pending, state => {
        state.isLoadingCommisionWidgets2 = true;
      })

      .addCase(CommisionWidgets.fulfilled, (state, action) => {
        state.commisionWidgets = action.payload;

        const sum =
          prop("reward", prop("current", state.commisionWidgets)) +
          prop("ppvz_reward", prop("current", state.commisionWidgets)) +
          prop("acquiring_fee", prop("current", state.commisionWidgets)) +
          prop("transportation", prop("current", state.commisionWidgets)) +
          prop("nds", prop("current", state.commisionWidgets));

        state.commisionCircle = [
          {
            office_name: "Вознаграждение",
            value: prop("reward", prop("current", state.commisionWidgets)),
            precent: (prop("reward", prop("current", state.commisionWidgets)) / sum) * 100,
          },
          {
            office_name: "Поверенный",
            value: prop("ppvz_reward", prop("current", state.commisionWidgets)),
            precent: (prop("ppvz_reward", prop("current", state.commisionWidgets)) / sum) * 100,
          },
          {
            office_name: "Перевозка",
            value: prop("transportation", prop("current", state.commisionWidgets)),
            precent: (prop("transportation", prop("current", state.commisionWidgets)) / sum) * 100,
          },
          {
            office_name: "НДС",
            value: prop("nds", prop("current", state.commisionWidgets)),
            precent: (prop("nds", prop("current", state.commisionWidgets)) / sum) * 100,
          },
        ];

        let checkMonth = false;
        if (state.dateTo instanceof Date) {
          checkMonth =
            new Date(state.dateTo.getTime() - 24 * 60 * 60 * 1000) < new Date(2024, 3, 1);
          if (checkMonth) {
            state.commisionCircle.splice(2, 0, {
              office_name: "Эквайринг",
              value: prop("commission_acquiring_fee", prop("current", state.commisionWidgets)),
              precent:
                (prop("commission_acquiring_fee", prop("current", state.commisionWidgets)) / sum) *
                100,
            });
          }
        }

        state.isLoadingCommisionWidgets = false;
      })

      .addCase(CommisionWidgets2.fulfilled, (state, action) => {
        state.commisionWidgets2 = action.payload;
        state.isLoadingCommisionWidgets2 = false;
      })

      .addCase(CommissionDiscountByCategories.pending, (state, action) => {
        state.isLoadingCommisionDiscount = true;
      })

      .addCase(CommissionDiscountByCategories.fulfilled, (state, action) => {
        state.commisionDiscount = action.payload;
        state.isLoadingCommisionDiscount = false;
      })

      .addCase(CommissionDiscountByCategories.rejected, (state, action) => {
        state.isLoadingCommisionDiscount = false;
      })

      .addCase(CommisionWidgets.rejected, state => {
        state.isLoadingCommisionWidgets = false;
      })

      .addCase(CommisionWidgets2.rejected, state => {
        state.isLoadingCommisionWidgets2 = false;
      })

      .addCase(CommisionTopProducts.pending, state => {
        state.isLoadingCommisionTopProducts = true;
      })

      .addCase(CommisionTopProducts.fulfilled, (state, action) => {
        state.commisionTopProducts = action.payload;
        state.isLoadingCommisionTopProducts = false;
      })

      .addCase(CommisionTopProducts.rejected, state => {
        state.isLoadingCommisionTopProducts = false;
      })

      .addCase(CommissionDiscountTopDetails.pending, state => {
        state.isLoadingCommisionTopProductsDetails = true;
      })

      .addCase(CommissionDiscountTopDetails.fulfilled, (state, action) => {
        state.commisionTopProductsDetails = action.payload;
        state.isLoadingCommisionTopProductsDetails = false;
      })

      .addCase(CommissionDiscountTopDetails.rejected, state => {
        state.isLoadingCommisionTopProductsDetails = false;
      })

      .addCase(CommisionDiagrams.pending, state => {
        state.isLoadingCommisionDiagrams = true;
      })
      .addCase(CommisionDiagrams.fulfilled, (state, action) => {
        state.commisionDiagram = action.payload;
        state.isLoadingCommisionDiagrams = false;
      })
      .addCase(CommisionDiagrams.rejected, state => {
        state.isLoadingCommisionDiagrams = false;
      })

      .addCase(CommisionTable.fulfilled, (state, action) => {
        const { response, value } = action.payload;
        if (value === "supplier_article") {
          state.commisionTableArticle = response.results;
          state.articleCountCommision = response.count;
        } else if (value === "subject_id") {
          state.commisionTableSubjects = response.results;
          state.subjectCountCommision = response.count;
        } else if (value === "month") {
          state.commisionTableMounth = response.results;
          state.mounthCountCommision = response.count;
        } else if (value === "brand_id") {
          state.commisionTableBrands = response.results;
          state.brandCountCommision = response.count;
        } else if (value === "shk_id") {
          state.commisionTableHatch = response.results;
          state.hatchCountCommision = response.count;
        } else if (value === "category_id") {
          state.commisionTablesCategory = response.results;
          state.categoryCountCommision = response.count;
        } else if (value === "barcode") {
          state.commisionTablesBarcode = response.results;
          state.barcodeCountCommision = response.count;
        }
      })

      .addCase(RiteReturnsWidgets.pending, state => {
        state.isLoadingReturnWidgets = true;
      })
      .addCase(RiteReturnsWidgets.fulfilled, (state, action) => {
        state.riteReturnsWidgets = action.payload;
        state.isLoadingReturnWidgets = false;
      })
      .addCase(RiteReturnsWidgets.rejected, state => {
        state.isLoadingReturnWidgets = false;
      })
      .addCase(RiteReturnDiagrama.pending, state => {
        state.isLoadingReturnsDiagram = true;
      })

      .addCase(RiteReturnDiagrama.fulfilled, (state, action) => {
        state.riteReturnsDiagram = action.payload;
        state.isLoadingReturnsDiagram = false;
      })

      .addCase(RiteReturnDiagrama.rejected, state => {
        state.isLoadingReturnsDiagram = false;
        state.riteReturnsDiagram = [];
      })

      .addCase(RiterReturnOffices.pending, state => {
        state.isLoadingReturnOffices = true;
      })

      .addCase(RiterReturnOffices.fulfilled, (state, action) => {
        const REDUCE_OFFICES_TO_LENGTH = 7;
        const OTHER_OFFICES_TITLE = "Остальные склады";

        state.riterReturnOffices = action.payload.reduce((acc, { office_name, value }, index) => {
          if (index < REDUCE_OFFICES_TO_LENGTH && office_name !== null && value !== null) {
            acc.push({ office_name, value });
            return acc;
          }
          const otherOfficesItem = acc.at(-1);
          otherOfficesItem.office_name = OTHER_OFFICES_TITLE;
          otherOfficesItem.value += value;
          return acc;
        }, []);

        let sum = 0;
        for (let i = 0; i < state.riterReturnOffices.length; i++) {
          sum += state.riterReturnOffices[i].value;
        }

        state.riterReturnOffices = state.riterReturnOffices.map(elem => {
          return {
            office_name: elem.office_name,
            value: elem.value,
            precent: (elem.value / sum) * 100,
          };
        });

        state.isLoadingReturnOffices = false;
      })

      .addCase(RiterReturnOffices.rejected, state => {
        state.isLoadingReturnOffices = false;
        state.riterReturnOffices = [];
      })

      .addCase(RiterReturnTables.fulfilled, (state, action) => {
        const { response, value } = action.payload;
        if (value === "supplier_article") {
          state.returnTableArticle = response.results;
          state.articleCountReturn = response.count;
        } else if (value === "subject_id") {
          state.returnTableSubjects = response.results;
          state.subjectCountReturn = response.count;
        } else if (value === "month") {
          state.returnTableMounth = response.results;
          state.mounthCountReturn = response.count;
        } else if (value === "brand_id") {
          state.returnTableBrands = response.results;
          state.brandCountReturn = response.count;
        } else if (value === "shk_id") {
          state.returnTableHatch = response.results;
          state.hatchCountReturn = response.count;
        } else if (value === "category_id") {
          state.returnTablesCategory = response.results;
          state.categoryCountReturn = response.count;
        } else if (value === "barcode") {
          state.returnTablesBarcode = response.results;
          state.barcodeCountReturn = response.count;
        } else if (value === "office_name") {
          state.returnTableStock = response.results;
          state.stockTableCount = response.count;
        }
      })

      .addCase(RiterReturnDynamicProduct.pending, state => {
        state.isLoadingRiterReturnDynamicProduct = true;
      })
      .addCase(RiterReturnDynamicProduct.fulfilled, (state, action) => {
        state.riterReturnDynamicProduct = action.payload;
        state.isLoadingRiterReturnDynamicProduct = false;
      })
      .addCase(RiterReturnDynamicProduct.rejected, state => {
        state.isLoadingRiterReturnDynamicProduct = false;
        state.riterReturnDynamicProduct = [];
      })

      .addCase(PenaltyAdditionalsWidgets.pending, state => {
        state.isLoadingPenaltyWidgets = true;
      })
      .addCase(PenaltyAdditionalsWidgets.fulfilled, (state, action) => {
        state.penaltyWidgets = action.payload;
        state.isLoadingPenaltyWidgets = false;
      })

      .addCase(PenaltyAdditionalsWidgets.rejected, state => {
        state.isLoadingPenaltyWidgets = false;
      })

      .addCase(SurchargesAdditionalsWidgets.pending, state => {
        state.isLoadingSurchargesWidgets = true;
      })
      .addCase(SurchargesAdditionalsWidgets.fulfilled, (state, action) => {
        state.surchargesWigets = action.payload;
        state.isLoadingSurchargesWidgets = false;
      })

      .addCase(SurchargesAdditionalsWidgets.rejected, state => {
        state.isLoadingSurchargesWidgets = false;
      })

      // TODO chech unused
      .addCase(PenaltyAdditionalsPenaltyTypes.fulfilled, (state, action) => {
        state.isLoadingPenaltyDiagramLine = false;
        const data = action.payload;

        state.penaltyReasoneImg = state.penaltyDiagramLine.map((elem, index) => {
          return {
            type_name: elem.type_name,
            info: data[index],
          };
        });
      })

      .addCase(PenaltyAdditionalsPenaltyTypes.pending, state => {
        state.isLoadingPenaltyDiagramLine = true;
      })
      .addCase(PenaltyAdditionalsLinesDynamic.pending, state => {
        state.isLoadingPenaltyDynamic = true;
      })

      .addCase(PenaltyAdditionalsLinesDynamic.fulfilled, (state, action) => {
        state.penaltyTypes = action.payload;

        // const selectedPenaltyType = state.penaltyTypes.find(
        // 	item => item.type_name === type_name
        // )
        // if (!selectedPenaltyType) {
        // 	console.error(`${type_name} not found`)
        // 	return
        // }
        // selectedPenaltyType.dynamic = response
        state.isLoadingPenaltyDynamic = false;
      })

      .addCase(PenaltyAdditionalsLinesDynamic.rejected, state => {
        state.isLoadingPenaltyDynamic = false;
      })

      .addCase(PenaltyTypes.pending, state => {
        state.isLoadingPenaltyTypes = true;
      })
      .addCase(PenaltyTypes.fulfilled, (state, action) => {
        state.penaltyDiagramLine = action.payload;
        const data = action.payload;
        state.typeNames = data.map(elem => elem.type_name);
        state.singlePenaltyType = action.payload.map((elem, index) => {
          return {
            type_name: elem.type_name,
            color: penaltyColors[index] || "green",
          };
        });
        state.isLoadingPenaltyTypes = false;
      })
      .addCase(PenaltyTypes.rejected, state => {
        state.isLoadingPenaltyTypes = false;
        state.penaltyDiagramLine = [];
      })
      .addCase(PenaltyOffices.pending, state => {
        state.isLoadingPenaltyCicrle = true;
      })

      .addCase(PenaltyOffices.fulfilled, (state, action) => {
        const REDUCE_OFFICES_TO_LENGTH = 7;
        const OTHER_OFFICES_TITLE = "Остальные склады";

        state.penaltyCicrle = action.payload.reduce((acc, { office_name, value }, index) => {
          if (index < REDUCE_OFFICES_TO_LENGTH) {
            acc.push({ office_name, value });
            return acc;
          }
          const otherOfficesItem = acc.at(-1);
          otherOfficesItem.office_name = OTHER_OFFICES_TITLE;
          otherOfficesItem.value += value;
          return acc;
        }, []);

        let sum = 0;
        for (let i = 0; i < state.penaltyCicrle.length; i++) {
          sum += state.penaltyCicrle[i].value;
        }

        state.penaltyCicrle = state.penaltyCicrle.map(elem => {
          return {
            office_name: elem.office_name,
            value: elem.value,
            precent: (elem.value / sum) * 100,
          };
        });
        state.isLoadingPenaltyCicrle = false;
      })
      .addCase(PenaltyOffices.rejected, state => {
        state.isLoadingPenaltyCicrle = false;
      })

      .addCase(PenaltyDiagramProducts.pending, state => {
        state.isLoadingPenaltyDiagramProducts = true;
      })
      .addCase(PenaltyDiagramProducts.fulfilled, (state, action) => {
        state.penaltyDiagramProducts = action.payload;
        state.isLoadingPenaltyDiagramProducts = false;
      })
      .addCase(PenaltyDiagramProducts.rejected, state => {
        state.isLoadingPenaltyDiagramProducts = false;
      })

      .addCase(PenaltyTables.fulfilled, (state, action) => {
        const { response, value } = action.payload;
        if (value === "supplier_article") {
          state.penaltyTableArticle = response.results;
          state.articleCountPenalty = response.count;
        } else if (value === "subject_id") {
          state.penaltyTableSubjects = response.results;
          state.subjectCountPenalty = response.count;
        } else if (value === "month") {
          state.penaltyTableMounth = response.results;
          state.mounthCountPenalty = response.count;
        } else if (value === "brand_id") {
          state.penaltyTableBrands = response.results;
          state.brandCountPenalty = response.count;
        } else if (value === "shk_id") {
          state.penaltyTableHatch = response.results;
          state.hatchCountPenalty = response.count;
        } else if (value === "category_id") {
          state.penaltyTablesCategory = response.results;
          state.categoryCountPenalty = response.count;
        } else if (value === "barcode") {
          state.penaltyTablesBarcode = response.results;
          state.barcodeCountPenalty = response.count;
        } else if (value === "office_name") {
          state.penaltyTableStock = response.results;
          state.stockTableCountPenalty = response.count;
        }
      })
      .addCase(AdvertWidgetsCompany.fulfilled, (state, action) => {
        state.promotionWidgets = action.payload;
        state.isLoadingPromotionWidgets = false;
      })
      .addCase(AdvertWidgetsCompany.pending, state => {
        state.isLoadingPromotionWidgets = true;
      })
      .addCase(AdvertWidgetsCompany.rejected, state => {
        state.isLoadingPromotionWidgets = false;
      })
      .addCase(AdvertDynamic.pending, (state, action) => {
        state.isLoadingAdvertDynamics = true;
      })
      .addCase(AdvertDynamic.fulfilled, (state, action) => {
        state.advertDynamics = action.payload;
        state.isLoadingAdvertDynamics = false;
      })
      .addCase(AdvertDynamic.rejected, (state, action) => {
        state.isLoadingAdvertDynamics = false;
      })
      .addCase(AdvertProductPosition.pending, state => {
        state.isLoadingAdvertProducts = true;
      })
      .addCase(AdvertProductPosition.fulfilled, (state, action) => {
        state.isLoadingAdvertProducts = false;
        state.advertProducts = action.payload;
        state.advertProductsDynamic = action.payload.map(elem => elem.dynamic);
      })
      .addCase(AdvertProductPosition.rejected, state => {
        state.isLoadingAdvertProducts = false;
        state.advertProducts = [];
        state.advertProductsDynamic = [];
      })
      .addCase(MediaCircle.fulfilled, (state, action) => {
        state.advertCircle = action.payload;
        state.isLoadingAdvertCircle = false;
      })
      .addCase(MediaCircle.pending, (state, action) => {
        state.isLoadingAdvertCircle = true;
      })
      .addCase(MediaCircle.rejected, (state, action) => {
        state.advertCircle = [];
        state.isLoadingAdvertCircle = false;
      })
      .addCase(AdvertTables.fulfilled, (state, action) => {
        state.isLoadingAdvertDate = false;
        const { response, group_by } = action.payload;
        if (group_by === "advert_id") {
          state.advertTableCompany = response.results;
          state.advertTableCompanyCount = response.count;
        } else if (group_by === "date") {
          state.advertTableDate = response.results;
          state.advertTableDateCount = response.count;
        } else if (group_by === "product_id") {
          state.advertTableArticle = response.results;
          state.advertTableArticleCount = response.count;
        } else if (group_by === "app_type") {
          state.advertTableAppType = response.results;
          state.advertTableAppTypeCount = response.count;
        } else if (group_by === "month") {
          state.advertTableMonth = response.results;
          state.advertTableMonthCount = response.count;
        } else if (group_by === "advert_type") {
          state.advertTableCompanyType = response.results;
          state.advertTableCompanyTypeCount = response.count;
        }
      })
      .addCase(AdvertTablesHints.fulfilled, (state, action) => {
        state.isLoadingAdvertDate = false;
        const { response, group_by } = action.payload;
        state.advertTableHints = response;
      })
      .addCase(AdvertTop.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.advertTopProducts = response;
      })
      .addCase(AdvertKeywordsTable.fulfilled, (state, action) => {
        const { response } = action.payload;
        // console.log("RESP1", response);
        state.advertTablePhrases = response.results;
        state.advertTablePhrasesCount = response.count;
      })
      .addCase(AdvertKeywordsByKeywordsTable.fulfilled, (state, action) => {
        const { response } = action.payload;
        // console.log("RESP2", response);
        state.advertTablePhrases = response.results;
        state.advertTablePhrasesCount = response.count;
      })
      .addCase(AdvertTables.pending, state => {
        state.isLoadingAdvertDate = true;
      })
      .addCase(PaidProducts.pending, state => {
        state.isLoadingPaidProduct = true;
      })
      .addCase(PaidProducts.fulfilled, (state, action) => {
        state.paidProducts = action.payload;
        state.isLoadingPaidProduct = false;
      })

      .addCase(PaidWidget.pending, state => {
        state.isLoadingPaidWigets = true;
      })
      .addCase(PaidWidget.fulfilled, (state, action) => {
        state.paidWigets = action.payload;
        state.isLoadingPaidWigets = false;
      })
      .addCase(PaidDiagram.pending, state => {
        state.isLoadingPaidDiagram = true;
      })
      .addCase(PaidDiagram.fulfilled, (state, action) => {
        state.isLoadingPaidDiagram = false;

        if (Array.isArray(action.payload)) {
          state.paidDiagram = action.payload.map(elem => {
            return {
              date: getAxisDate(elem.date),
              volume: elem.volume !== null ? elem.volume.toFixed(0) : "-",
              barcodes_count: elem.barcodes_count !== null ? elem.barcodes_count.toFixed(0) : "-",
              warehouse_price:
                elem.warehouse_price !== null ? elem.warehouse_price.toFixed(0) : "-",
            };
          });
        }
      })
      .addCase(PaidDiagram.rejected, state => {
        state.isLoadingPaidDiagram = false;
        state.paidDiagram = [];
      })
      .addCase(PaidStorageCircles.pending, state => {
        state.isLoadingPaidStorageCircle = true;
      })
      .addCase(PaidStorageCircles.fulfilled, (state, action) => {
        state.paidStorageCircle = action.payload;
        state.isLoadingPaidStorageCircle = false;
      })
      .addCase(PaidStorageCircles.rejected, state => {
        state.isLoadingPaidStorageCircle = false;
        state.paidStorageCircle = [];
      })
      .addCase(PaidOfficesCircle.fulfilled, (state, action) => {
        state.isLoadingPaidOfficesCircles = false;

        if (Array.isArray(action.payload)) {
          const REDUCE_OFFICES_TO_LENGTH = 7;
          const OTHER_OFFICES_TITLE = "Остальные склады";

          state.paidOfficesCircles = action.payload.reduce((acc, { office_name, value }, index) => {
            if (index < REDUCE_OFFICES_TO_LENGTH && office_name !== null && value !== null) {
              acc.push({ office_name, value });
              return acc;
            }
            const otherOfficesItem = acc.at(-1);
            otherOfficesItem.office_name = OTHER_OFFICES_TITLE;
            otherOfficesItem.value += value;
            return acc;
          }, []);

          let sum = 0;
          for (let i = 0; i < state.riterReturnOffices?.length; i++) {
            sum += state.riterReturnOffices[i].value;
          }
          state.paidOfficesCircles = state.paidOfficesCircles?.map(elem => {
            return {
              office_name: elem.office_name,
              value: elem.value,
              precent: (elem.value / sum) * 100,
            };
          });
          // state.paidOfficesCircles = action.payload.map(elem => {
          //   return {
          //     office_name:elem.office_name !==null ? elem.office_name  :"нет данных",
          //     value:elem.value !==null ? elem.value.toFixed(0) :'нет данных'

          //   };
          // });
        }
      })
      .addCase(PaidOfficesCircle.pending, state => {
        state.isLoadingPaidOfficesCircles = true;
      })
      .addCase(PaidOfficesCircle.rejected, state => {
        state.isLoadingPaidOfficesCircles = false;
        state.paidOfficesCircles = [];
      })

      .addCase(PaidTable.fulfilled, (state, action) => {
        const { response, group_by } = action.payload;
        if (group_by === "supplier_article") {
          state.paidArticleTable = response.results;
          state.paidArticleTableCount = response.count;
        } else if (group_by === "category_id") {
          state.paidCategoryTable = response.results;
          state.paidCategoryTableCount = response.count;
        } else if (group_by === "subject_id") {
          state.paidSubjectTable = response.results;
          state.paidSubjectTableCount = response.count;
        } else if (group_by === "office_name") {
          state.paidOfficeTable = response.results;
          state.paidOfficeTableCount = response.count;
        } else if (group_by === "brand_id") {
          state.paidBrandTable = response.results;
          state.paidBrandTableCount = response.count;
        } else if (group_by === "barcode") {
          state.paidBarcodeTable = response.results;
          state.paidBarcodeTableCount = response.count;
        } else if (group_by === "month") {
          state.paidMonthTable = response.results;
          state.paidMonthTableCount = response.count;
        }
      })

      .addCase(PaidStorageWarehouseCoefsTable.fulfilled, (state, action) => {
        const { response, group_by } = action.payload;

        state.paidCoefsTable = response.results;
        state.paidCoefsTableCount = response.count;
      })

      .addCase(PaidStorageWarehouseDetailsTable.fulfilled, (state, action) => {
        const { response, group_by } = action.payload;

        state.paidDetailsTable = response.results;
        state.paidDetailsTableCount = response.count;
      })
      .addCase(GetAdvertsByDates.fulfilled, (state, action) => {
        state.promotionSelectedCompany = action.payload;
      })
      .addCase(GetRealizationReportArticle.fulfilled, (state, action) => {
        const { response, article } = action.payload;
        const supplierArticlesResult = response?.filter(el => el.supplier_article);
        const productIdsResult = response?.filter(el => article.includes(el.supplier_article));
        state.realizationReportArticle = supplierArticlesResult;
        state.realizationReportProducts = productIdsResult;
        const arrArticle = article.filter(el =>
          supplierArticlesResult.find(item => el === item.supplier_article)
        );
        const arrProduct = productIdsResult.map(el => el.product_id);
        if (arrProduct) {
          state.productIds = arrProduct;
        }
        if (arrArticle && article && arrArticle.length !== article.length) {
          state.articulIds = arrArticle;
        }
      })
      .addCase(GetRealizationReportArticleAdvert.fulfilled, (state, action) => {
        const { response, article } = action.payload;
        const supplierArticlesResult = response?.filter(el => el.supplier_article);
        const productIdsResult = response?.filter(el => article.includes(el.supplier_article));
        state.realizationReportArticle = supplierArticlesResult;
        const arrArticle = article.filter(el =>
          supplierArticlesResult.find(item => el === item.supplier_article)
        );
        const arrProduct = productIdsResult.map(el => el.product_id);
        if (arrProduct) {
          state.productIds = arrProduct;
        }
        if (arrArticle && article && arrArticle.length !== article.length) {
          state.articulIds = arrArticle;
        }
      })
      .addCase(GetRealizationReportArticlePaidStorage.fulfilled, (state, action) => {
        const { response, article } = action.payload;
        const supplierArticlesResult = response?.filter(el => el.supplier_article);
        const productIdsResult = response?.filter(el => article.includes(el.supplier_article));
        state.realizationReportArticle = productIdsResult;
        const arrArticle = article.filter(el =>
          supplierArticlesResult.find(item => el === item.supplier_article)
        );
        const arrProduct = productIdsResult.map(el => el.product_id);
        if (arrProduct) {
          state.productIds = arrProduct;
        }
        if (arrArticle && article && arrArticle.length !== article.length) {
          state.articulIds = arrArticle;
        }
      })
      .addCase(GetPromotionKeyPhrasesCatalogTable.pending, (state, action) => {
        state.isLoadingAdvertDate = true;
      })
      .addCase(GetPromotionKeyPhrasesCatalogTable.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        state.isLoadingAdvertDate = false;
        state.promotionKeyPhrasesCatalogTable = results;
        state.promotionKeyPhrasesCatalogTableCount = count;
      })
      .addCase(GetPromotionKeyPhrasesCatalogTable.rejected, (state, action) => {
        state.isLoadingAdvertDate = false;
      })
      .addCase(GetHeatMapAsTable.pending, (state, action) => {
        state.isLoadingHeatMapAsTable = true;
      })
      .addCase(GetHeatMapAsTable.fulfilled, (state, action) => {
        state.isLoadingHeatMapAsTable = false;
        state.heatMapAsTable = formatHeatMapData(action.payload.data);
      })
      .addCase(GetHeatMapAsTable.rejected, (state, action) => {
        state.isLoadingHeatMapAsTable = false;
      });
  },
});

export const {
  addKeysFinancialLogic,
  addDisabledKeys,
  getIdCategory,
  mounthDate,
  getLastMounth,
  getIdBrand,
  getIdArticul,
  getIdProducts,
  getIdBarcod,

  getDatePicker,
  prevLastWeek,
  getSubjectId,
  getMonthPeriod,
  clearSelectors,
  clearSelector,
  disabledCheckbox,
  addCheckboxTypeName,
  singlePenalty,
  clearAllDate,
  clearTabsReturn,
  keyIndicatorsClearTable,
  returnClearTable,
  penaltyClearTable,
  commisionClearTable,
  promotionMediaFilter,
  promotianMedioaClearTable,
  paidClearTable,
  setAutocompleteListRef,
  setABCLabels,
  setXYZLabels,
  setKeyIndicatorsTableArticleWithHierarchy,
  setReturnTableArticleWithHierarchy,
  setPenaltyTableArticleWithHierarchy,
  setPaidArticleTableWithHierarchy,
  setComissionTableArticleWithHierarchy,
} = financialLogicSlice.actions;
export const financialLogicSliceReducer = financialLogicSlice.reducer;
