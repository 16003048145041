import React from "react";

import { Close, OpenInNew } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import Tooltip_ from "root/components/Tooltip";

import styles from "./index.module.scss";

// ----------------------------------------------------------------------------------------
const CustomSelectorBox = ({
  haveItems,
  openDrawer,
  resetCheckedItems,
  selectorText,
  tooltip,
  disabled = false,
  Icon = OpenInNew,
}) => {
  return (
    <Tooltip_ title={tooltip}>
      <Box
        className={styles.selector}
        data-empty={!haveItems}
        data-disabled={disabled}
        onClick={() => !disabled && openDrawer()}>
        <Typography>{selectorText}</Typography>
        <Box display={"flex"} alignItems={"center"} gap={1}>
          {haveItems && <Close onClick={resetCheckedItems} />}
          <Icon />
        </Box>
      </Box>
    </Tooltip_>
  );
};

export default CustomSelectorBox;
