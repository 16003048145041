import React from "react";

import { MONTH_LIST } from "root/js/constants";

import { DAYS, HOURS, MILLISECONDS, MINUTES, MONTHS, SECONDS } from "./constants";

export const getLastWeek = () => {
  const date = new Date();
  const startOfWeek = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() - 6
  );
  const endOfWeek = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() + 1
  );

  return {
    dateFrom: formatDate(startOfWeek),
    dateTo: calcDate(formatDate(endOfWeek), 1)
  };
};

export const sortMonthList = list => {
  const dates = list
    .map(el => el.split(" "))
    .map(
      d =>
        new Date(
          d[1],
          MONTHS.findIndex(m => m === d[0])
        )
    )
    .sort((a, b) => a - b);

  return dates
    .map(el => `${MONTHS.find((m, ind) => ind === el.getMonth())} ${el.getFullYear()}`)
    .reverse();
};

export const formatSelectedData = (data, options) => {
  if (data?.lenght < 2 || !data) return data;
  const start = options.indexOf(data[0]);
  const end = options.indexOf(data[data.length - 1]);
  const interval = [];
  for (let i = start; i <= end; i++) {
    interval.push(options[i]);
  }
  return interval;
};

export const getMonthIndex = month => {
  return MONTHS.indexOf(month);
};

export const getStringFromDate = date => {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};

export const getOptionFromDate = date => {
  return `${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
};

export const getDataPickerLabel = selected => {
  const data = sortMonthList(selected);

  return selected.length > 1 ? (
    <>
      <span>{`${data[data.length - 1]}  `}</span>
      <span
        style={{
          width: "12px",
          height: "1px",
          background: "#000000",
          display: "inline-block",
          marginTop: "10px"
        }}></span>
      <span>{`  ${data[0]}`}</span>
    </>
  ) : (
    selected.join(", ")
  );
};

export const formatTime = s => {
  return `${Math.ceil(s / SECONDS / MINUTES / HOURS)} дней`;
};

export const formatMonth = date => {
  if (!date) return "";
  const d = new Date(date);
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const m = MONTH_LIST.find(el => el.value === month.toString())?.label;
  return `${m} ${d.getFullYear()}`;
};

export const formatHeatMapData = data => {
  if (!data) return [];
  const newData = data.map(el => ({
    ...el,
    sum: el.by_hours.reduce((s, h) => (s = s + Number(h)), 0).toFixed(1)
  }));

  const commonData = Array(24).fill(0);
  for (let i = 0; i < HOURS; i++) {
    for (let j = 0; j < DAYS; j++) {
      commonData[i] = commonData[i] + data[j]?.by_hours[i];
    }
  }

  newData.push({
    day_of_week: 8,
    _dow_label: "common",
    by_hours: commonData,
    sum: commonData.reduce((s, h) => (s = s + Number(h)), 0).toFixed(1)
  });
  return newData;
};

export const getMinMaxValues = data => {
  if (!data)
    return {
      data: { min: 0, max: 0, mid: 0 },
      byHours: { min: 0, max: 0, mid: 0 },
      common: { min: 0, max: 0, mid: 0 }
    };

  const values = data
    .slice(0, data.length - 1)
    .map(el => el.by_hours)
    .flat();
  const byHours = data.slice(0, data.length - 1).map(el => el.sum);
  const common = data[data.length - 1]?.by_hours;

  return common
    ? {
        data: {
          min: Math.min(...values),
          max: Math.max(...values),
          mid: values.reduce((sum, el) => (sum = sum + el), 0) / values.length
        },
        byHours: {
          min: Math.min(...byHours),
          max: Math.max(...byHours),
          mid: byHours.reduce((sum, el) => (sum = sum + Number(el)), 0) / byHours.length
        },
        common: {
          min: Math.min(...common),
          max: Math.max(...common),
          mid: common.reduce((sum, el) => (sum = sum + el), 0) / common.length
        }
      }
    : {
        data: { min: 0, max: 0, mid: 0 },
        byHours: { min: 0, max: 0, mid: 0 },
        common: { min: 0, max: 0, mid: 0 }
      };
};

const calcColor = (ratio, startColor, endColor) => {
  const red = Math.round(startColor[0] + (endColor[0] - startColor[0]) * ratio);
  const green = Math.round(startColor[1] + (endColor[1] - startColor[1]) * ratio);
  const blue = Math.round(startColor[2] + (endColor[2] - startColor[2]) * ratio);
  return `rgba(${red}, ${green}, ${blue}, 0.6)`;
};

export const getCellColor = (value, min, max, mid) => {
  const greenColor = [34, 200, 41];
  const yellowColor = [255, 255, 26];
  const redColor = [244, 67, 54];

  if (value < mid) {
    const ratio = (value - min) / (mid - min);
    return calcColor(ratio, redColor, yellowColor);
  } else {
    const ratio = (value - mid) / (max - mid);
    return calcColor(ratio, yellowColor, greenColor);
  }
};

export const calcDate = (date, milliseconds) => {
  date.setTime(date.getTime() - milliseconds);
  return date;
};

export const formatDate = date => {
  const d = new Date(date);
  const offset = d.getTimezoneOffset();
  return calcDate(d, offset * SECONDS * MILLISECONDS);
};

export const checkNoDataValue = (data) => {

  const ValueMap = data.map(elem => elem?.value)
  const isNoData = ValueMap.every(value => {
    if (value === 0 || value === undefined) {
      return true;
    }

    return false;
  });
  return isNoData
}
