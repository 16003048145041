import request from "root/js/request";

export const getSocialBloggersProducts = async ({
  source__in,
  item__in,
  subject_id__in = item__in,
  account_name_hash__in,
  brand_id__in,
  limit = 100,
  offset = 0,
  search,
}) => {
  const response = await request({
    url: `parsers/social/bloggers/products/`,
    method: "get",
    params: {
      source__in,
      item__in,
      subject_id__in,
      account_name_hash__in,
      brand_id__in,
      limit,
      offset,
      search,
    },
  });
  return response;
};
