import { createSlice } from "@reduxjs/toolkit";
import { eachDayOfInterval } from "date-fns";
import { clone, isNil, length, prop, propOr, uniq } from "ramda";

import {
  checkAllCheckboxes,
  checkCheckbox,
  getKeystat,
  getNewKeystatMonth,
  getPageChange,
  removeStopWords,
  removeWord,
  setWord,
  setWords,
  submitStopWords,
  submitSubject,
} from "./actions";

import { dateToTimestamp } from "root/js/utils";

const initialState = {
  isLoading: false,
  words: [],
  stopWords: [],
  data: {
    count: 0,
    results: [],
    page: 1,
  },
  columns: [],
  checkeds: [],
  subjects: [],
  error: null,
  isExporting: false,
  extra_fields: "dynamic,subject",
};

const RED = "#FDE4EB";
const GREEN = "#E2F4EC";

export const keystat = createSlice({
  name: "keystat",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // !------------getPageChange
    builder.addCase(getPageChange.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getPageChange.fulfilled, (state, { payload }) => {
      // const dates = []
      // propOr([], "results", prop("response", payload)).map(({dynamic}) =>
      // 	dynamic.map(({create_date}) => {
      // 		const isIt =
      // 			dateToTimestamp(new Date(create_date)) !==
      // 			dateToTimestamp(new Date(new Date().setDate(1)))

      // 		if (!dates.includes(create_date) && isIt) {
      // 			dates.push(create_date)
      // 		}
      // 	})
      // )
      const datesStrings = eachDayOfInterval({
        start: new Date("2022-05-01"),
        end: new Date(),
      }).map(item => dateToTimestamp(item));

      const columns = datesStrings.filter(item => item.split("-")[2] === "01");

      columns.pop();

      const newResults = propOr([], "results", prop("response", payload)).map(item => {
        const newObj = {
          ...item,
          keyword: {
            keyword: item.keyword,
            id: item.id,
          },
        };
        columns.map((column, index) => {
          const value = prop(
            "count",
            propOr([], "dynamic", item).find(({ create_date }) => column === create_date)
          );

          const preValue = prop(
            "count",
            propOr([], "dynamic", item).find(
              ({ create_date }) => prop(index - 1, columns) === create_date
            )
          );

          newObj[column] = {
            value,
            style: {
              background:
                !preValue && !value
                  ? undefined
                  : !preValue && value
                  ? GREEN
                  : preValue && !value
                  ? undefined
                  : preValue > value
                  ? RED
                  : preValue == value
                  ? undefined
                  : GREEN,
            },
          };
          if (
            !prop(
              "create_date",
              propOr([], "dynamic", item).find(
                ({ create_date }) => prop(index - 1, columns) === create_date
              )
            )
          ) {
            newObj[column] = {
              ...newObj[column],
              style: {
                background: undefined,
              },
            };
          }
          if (columns[0]) {
            newObj[columns[0]] = {
              ...newObj[columns[0]],
              style: {
                background: undefined,
              },
            };
          }
        });

        const preFrequency = prop("value", prop(prop(length(columns) - 1, columns), newObj));
        const frequencyValue = prop("frequency", item);

        newObj.frequency = {
          value: item.frequency,
          style: {
            // background: undefined,
            background:
              preFrequency && isNil(frequencyValue)
                ? undefined
                : preFrequency > frequencyValue
                ? RED
                : preFrequency < frequencyValue
                ? GREEN
                : undefined,
          },
        };

        return newObj;
      });

      state.data = {
        ...state.data,
        count: propOr(0, "count", prop("response", payload)),
        results: newResults,
        page: propOr(1, "page", payload),
      };
      state.isLoading = false;
    });
    builder.addCase(getPageChange.rejected, state => {
      state.isLoading = false;
      state.error = "Error";
    });
    // !------------getKeystat
    builder.addCase(getKeystat.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getKeystat.fulfilled, (state, { payload }) => {
      const datesStrings = eachDayOfInterval({
        start: new Date("2022-05-01"),
        end: new Date(),
      }).map(item => dateToTimestamp(item));

      const columns = datesStrings.filter(item => item.split("-")[2] === "01");

      columns.pop();

      const newResults = propOr([], "results", payload).map(item => {
        const newObj = {
          ...item,
          keyword: {
            keyword: item.keyword,
            id: item.id,
          },
        };
        columns.map((column, index) => {
          const value = prop(
            "count",
            propOr([], "dynamic", item).find(({ create_date }) => column === create_date)
          );

          const preValue = prop(
            "count",
            propOr([], "dynamic", item).find(
              ({ create_date }) => prop(index - 1, columns) === create_date
            )
          );
          newObj[column] = {
            value,
            style: {
              background:
                !preValue && !value
                  ? undefined
                  : !preValue && value
                  ? GREEN
                  : preValue && !value
                  ? undefined
                  : preValue > value
                  ? RED
                  : preValue == value
                  ? undefined
                  : GREEN,
            },
          };
          if (
            !prop(
              "create_date",
              propOr([], "dynamic", item).find(
                ({ create_date }) => prop(index - 1, columns) === create_date
              )
            )
          ) {
            newObj[column] = {
              ...newObj[column],
              style: {
                background: undefined,
              },
            };
          }
          if (columns[0]) {
            newObj[columns[0]] = {
              ...newObj[columns[0]],
              style: {
                background: undefined,
              },
            };
          }
        });

        const preFrequency = prop("value", prop(prop(length(columns) - 1, columns), newObj));
        const frequencyValue = prop("frequency", item);

        newObj.frequency = {
          value: item.frequency,
          style: {
            // background: undefined,
            background:
              preFrequency && isNil(frequencyValue)
                ? undefined
                : preFrequency > frequencyValue
                ? RED
                : preFrequency < frequencyValue
                ? GREEN
                : undefined,
            // !prop("value", newObj[columns[length(columns) - 1]]) && !item.frequency
            // 	? undefined
            // 	: !prop("value", newObj[columns[length(columns) - 1]]) && item.frequency
            // 		? GREEN
            // 		: prop("value", newObj[columns[length(columns) - 1]]) > item.frequency
            // 			? RED
            // 			: GREEN,
          },
        };

        return newObj;
      });

      state.columns = columns;
      state.data = {
        ...state.data,
        count: payload.count,
        results: newResults,
      };
      state.isLoading = false;
    });
    builder.addCase(getKeystat.rejected, state => {
      state.isLoading = false;
      state.error = "Error";
    });
    // !------------getNewKeystatMonth
    builder.addCase(getNewKeystatMonth.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getNewKeystatMonth.fulfilled, (state, { payload }) => {
      const date = [];
      propOr([], "results", payload).map(({ dynamic }) =>
        dynamic.map(({ create_date }) => {
          const isIt =
            dateToTimestamp(new Date(create_date)) !==
            dateToTimestamp(new Date(new Date().setDate(1)));

          if (!date.includes(create_date) && isIt) {
            date.push(create_date);
          }
        })
      );

      const newResults = state.data.results.map(item => {
        const newObj = propOr([], "results", payload).find(({ id }) => id === item.id);
        const value = prop("count", prop(0, prop("dynamic", newObj)));
        const oldValue = prop("value", prop(prop("date_to", payload), item));
        const lastDate = prop("date_to", payload);

        let style = { background: undefined };

        if (oldValue && !value) {
          style.background = GREEN;
        }
        if (oldValue > value) {
          style.background = GREEN;
        }
        if (oldValue < value) {
          style.background = RED;
        }

        return {
          ...item,
          [lastDate]: {
            value: item[lastDate].value,
            style,
          },
          [prop(0, date)]: {
            value: value,
            style: undefined,
          },
        };
      });
      const datesStrings = eachDayOfInterval({
        start: new Date("2022-05-01"),
        end: new Date(),
      }).map(item => dateToTimestamp(item));
      const columns = datesStrings.filter(item => item.split("-")[2] === "01");
      columns.pop();
      state.columns = columns;
      state.data = {
        ...state.data,
        results: newResults,
      };
      state.isLoading = false;
    });
    builder.addCase(getNewKeystatMonth.rejected, state => {
      state.isLoading = false;
      state.error = "Error";
    });
    // !------------setWords
    builder.addCase(setWords.fulfilled, (state, { payload }) => {
      state.words = prop("array", payload);
      state.data.page = prop("page", payload);
    });
    builder.addCase(setWord.fulfilled, (state, { payload }) => {
      state.words = uniq([...state.words, payload]);
    });
    builder.addCase(removeWord.fulfilled, (state, { payload }) => {
      state.words = payload;
    });
    // !------------checkAllCheckboxes
    builder.addCase(checkAllCheckboxes.fulfilled, (state, { payload }) => {
      if (payload) {
        state.checkeds = state.data.results.map(({ id }) => id);
      } else {
        state.checkeds = [];
      }
    });
    builder.addCase(checkCheckbox.fulfilled, (state, { payload }) => {
      if (payload) {
        if (state.checkeds.includes(payload)) {
          state.checkeds = state.checkeds.filter(item => item !== payload);
        } else {
          state.checkeds = clone([...state.checkeds, payload]);
        }
      } else {
        state.checkeds = [];
      }
    });
    // !------------submitStopWords
    builder.addCase(submitStopWords.fulfilled, (state, { payload }) => {
      state.stopWords = payload;
      state.data.page = 1;
    });
    builder.addCase(removeStopWords.fulfilled, (state, { payload }) => {
      state.stopWords = clone(propOr([], "stopWords", state)).filter(item => item !== payload);
    });
    // !------------submitSubject
    builder.addCase(submitSubject.fulfilled, (state, { payload }) => {
      state.data.page = 1;
      state.subjects = payload;
    });
  },
});

export default keystat.reducer;
