import React, { useMemo, useState } from "react";

import { Box, Typography } from "@mui/material";
import { isEmpty } from "ramda";
import { getFrontDate } from "root/utils/date";

import ColorCheckbox from "root/components/ColorCheckbox";
import DatePickerRu from "root/components/DatePickerRu";
import LinearLoading from "root/components/LinearLoading";
import { NoDataMassage } from "root/components/NoDataMessage";
import TableSort from "root/components/TableSort";

import Chart from "./Chart";
import ProductDynamicExportButton from "./ProductDynamicExportButton";
import { productDrawerAxis, productDrawerColumns } from "./utils";

import { numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

// ----------------------------------------------------------------------------------------
const ProductDrawerContent = ({
  params = {},
  data,
  isLoading,
  socialNetworks = [],
  productId,
  dateRange,
  setDateRange,
  maxDate,
}) => {
  const [checkBox, setCheckBox] = useState(
    productDrawerAxis.filter(({ default: defaultP }) => defaultP).map(({ id }) => id)
  );
  const [ordering, setOrdering] = useState("-date");
  const tableData = useMemo(() => data?.dynamic ?? [], [data]);

  const chartData = useMemo(() => {
    const arr = data?.dynamic ?? [];
    if (!isEmpty(arr)) {
      return arr.map(({ date, product_ads, ...rest }) => ({
        ...rest,
        date: getFrontDate(date),
        count: product_ads,
      }));
    }
    return [];
  }, [data, dateRange, isLoading]);

  const sortedTableData = useMemo(() => {
    const isReverse = ordering.includes("-");
    const orderName = ordering.replace("-", "");
    const sortedData = [...tableData];
    if (orderName === "date") sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
    else sortedData.sort((a, b) => a[orderName] - b[orderName]);
    return isReverse ? sortedData.reverse() : sortedData;
  }, [tableData, ordering]);

  if (isLoading) return <LinearLoading />;

  return (
    <Box p={2} sx={{ background: "#fff" }}>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        gap={"14px 30px"}
        pr={6}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={2}>
        <Box className={styles?.titleBox}>
          <Box display={"flex"} gap={2} alignItems={"center"} flexWrap={"wrap"}>
            <Typography className={styles.title}>
              Динамика внешки по артикулу
              <Typography display={"inline"} className={styles.subTitle}>
                «{productId}»
              </Typography>
            </Typography>
            {!!data?.product_ads && (
              <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Typography className={styles.title}>Частотность за период: </Typography>
                <Typography variant="h5">{numberWithSpaces(data.product_ads)}</Typography>
              </Box>
            )}
          </Box>
        </Box>
        {window?.location?.pathname.includes("/dashboard/bloggers-data") && (
          <Box display={"flex"} justifyContent={"flex-end"}>
            <DatePickerRu
              value={dateRange}
              onChange={setDateRange}
              maxDate={maxDate}
              // periodListProp={periodsList}
            />
          </Box>
        )}
      </Box>
      <Box mb={2}>
        <Box mt={3} mb={1} className={styles.colorCheckboxWrapper}>
          {productDrawerAxis.map(({ id, name, color }) => (
            <ColorCheckbox
              key={id}
              name={name}
              color={color}
              onChange={() => {
                setCheckBox(state => {
                  if (state.includes(id)) {
                    return state.filter(item => item !== id);
                  }
                  return [...state, id];
                });
              }}
              checked={checkBox.includes(id)}
              disabled={checkBox.length && checkBox.length == 1 && checkBox.includes(id)}
            />
          ))}
        </Box>
        {!data || data.length === 0 ? (
          <NoDataMassage title={"Нет данных"} height={380} sx={{ boxShadow: "none !important" }} />
        ) : (
          <Chart
            {...{
              dynamic: data?.dynamic,
              lastReportDate: data?.last_advert_run_date,
              data: chartData,
              socialNetworks,
              axis: productDrawerAxis,
              checkBox,
            }}
          />
        )}
      </Box>
      <Box display={"flex"} flexDirection={"row-reverse"} mb={2}>
        <ProductDynamicExportButton
          {...{ params, isLoading, disabled: isEmpty(sortedTableData), productId }}
        />
      </Box>
      <Box className={styles.scrollableTableBox}>
        <TableSort
          columns={productDrawerColumns}
          data={sortedTableData}
          ordering={ordering}
          onSort={setOrdering}
        />
      </Box>
    </Box>
  );
};

export default ProductDrawerContent;
