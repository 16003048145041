import React, { Fragment, useMemo } from "react";

import { Box, Typography } from "@mui/material";
import { keys, propOr, uniq } from "ramda";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ScrollableChartWrapper from "root/components/Chart/hocs/ScrollableChartWrapper";
import ColorInTooltip from "root/components/ColorInTooltip";
import { socialNetworkReferenceLines } from "root/components/SocialNetworksTraffic/SocialNetworkReferenceLines";
import SocialNetworksCheckBoxes from "root/components/SocialNetworksTraffic/SocialNetworksCheckBoxes";
import { CustomizedAxisTick } from "root/pages/monitoring/components/FormedTable/BrandFormedTable/utils";

import useActiveDots from "root/hooks/useActiveDots";

import ExternalInfluenceIndicator from "./ExternalInfluenceIndicator";

import { defaultToArr, numberWithSpaces, tickFormatter } from "root/js/utils";

import styles from "./index.module.scss";

const getCorrectValue = value => {
  const withoutZeroBefore = +String(value).slice(2);
  const roundValue = Math.round(withoutZeroBefore);
  return numberWithSpaces(roundValue);
};

const Chart = ({ data, axis, socialNetworks = [], checkBox, dynamic, lastReportDate }) => {
  const { rechartsRef, currentActiveDots, activeDotFactory } = useActiveDots();

  const [hoverMark, setHoverMark] = React.useState();
  const [socialNetworksCheckBox, setSocialNetworksCheckBox] = React.useState(true);
  const uniqTitles = uniq(socialNetworks.map(({ value }) => keys(value)).flat());
  const yAxisIdRL = useMemo(() => checkBox?.[0] ?? "proceeds", [checkBox]);
  return (
    <Fragment>
      <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} gap={2}>
        <SocialNetworksCheckBoxes
          showSocialNetworks={socialNetworksCheckBox}
          setShowSocialNetworks={setSocialNetworksCheckBox}
          existSocialNetworks={uniqTitles}
          lastReportDate={lastReportDate}
        />
        {/* <Tooltip_ title="Показатель корреляции ключевых метрик артикула с результатами продвижения через внешний трафик от специально обученной модели ИИ.">
          <Box pr={2} sx={{ cursor: "pointer" }}>
            <Typography>
              <b>Влияние внешки на выручку за период</b> - {isMatterOuterTraffic(dynamic)}
            </Typography>
          </Box>
        </Tooltip_> */}
        <ExternalInfluenceIndicator dynamic={dynamic} />
      </Box>
      <ScrollableChartWrapper>
        <ResponsiveContainer width="100%" minWidth={1000} height={400} ref={rechartsRef}>
          <ComposedChart
            data={data}
            margin={{
              top: 10,
              right: 10,
              bottom: 0,
              left: 0,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              axisLine={true}
              tickLine={true}
              dataKey={"date"}
              tickSize={5}
              height={70}
              angle={30}
              dx={20}
              tick={<CustomizedAxisTick height={30} />}
            />

            {axis
              .filter(item => checkBox.includes(item?.id))
              .map(({ id, color, orientation }) => (
                <YAxis
                  key={id}
                  yAxisId={id}
                  orientation={orientation}
                  axisLine={true}
                  tickLine={false}
                  width={50}
                  fontSize={14}
                  tickSize={5}
                  stroke={color}
                  strokeWidth={1}
                  shapeRendering="crispEdges"
                  style={{
                    fill: color,
                    fontSize: "12px",
                    color: "#798097",
                  }}
                  tickFormatter={value => tickFormatter(value)}
                />
              ))}

            <Tooltip
              offset={10}
              content={({ active, label }) => {
                if (active && currentActiveDots.length > 0) {
                  return (
                    <div className={styles.tooltip}>
                      <div className={styles.date}>
                        Дата: <span className={styles.dateMonth}>{label}</span>
                      </div>
                      {defaultToArr(currentActiveDots).map(item => {
                        const cursorOn = axis.find(({ id }) => id == item.dataKey);
                        return (
                          <ColorInTooltip
                            key={item.key}
                            color={item.fill}
                            name={cursorOn.name}
                            subtitle={`${getCorrectValue(item.value)} ${cursorOn.afix}`}
                            className={styles.colorInTooltip}
                          />
                        );
                      })}
                      {hoverMark && (
                        <Typography variant="h6">
                          {propOr("—", "item", hoverMark)} - {propOr("—", "count", hoverMark)}
                        </Typography>
                      )}
                    </div>
                  );
                } else if (hoverMark) {
                  return (
                    <div style={{ padding: "14px" }} className={styles.tooltip}>
                      <div style={{ marginBottom: 0 }} className={styles.date}>
                        Дата: {hoverMark?.date ?? ""}
                      </div>

                      <Typography variant="h6">
                        {propOr("—", "item", hoverMark) === "instagram"
                          ? "inst*"
                          : propOr("—", "item", hoverMark)}{" "}
                        - {propOr("—", "count", hoverMark)}
                      </Typography>
                    </div>
                  );
                } else {
                  return null;
                }
              }}
            />

            {axis
              .filter(item => checkBox.includes(item?.id))
              .map(({ id, color }) => (
                <Area
                  key={id}
                  animationDuration={900}
                  isAnimationActive={true}
                  type="monotone"
                  activeDot={activeDotFactory}
                  yAxisId={id}
                  dataKey={id}
                  strokeWidth={2}
                  stroke={color}
                  strokeDasharray={undefined}
                  dot={{ strokeDasharray: undefined, r: 2 }}
                  fillOpacity={0.1}
                  fill={color}
                />
              ))}
            {/*  */}
            {socialNetworksCheckBox &&
              socialNetworkReferenceLines(socialNetworks, setHoverMark, styles, yAxisIdRL)}
            {/*  */}
          </ComposedChart>
        </ResponsiveContainer>
      </ScrollableChartWrapper>
    </Fragment>
  );
};

export default Chart;
