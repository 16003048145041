import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Drawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { NoDataMassage } from "root/components/NoDataMessage";

import DiagramHint from "../../../../../../components/global/diagrama/diagram-hint";
import ProductInTable from "../../../../../../components/ProductInTable";
import TableSort from "../../../../../../components/TableSort";
import XlsIcon from "../../../../../../icons/Xls";
import API from "../../../../../../js/api";
import { CommissionDiscountTopDetails } from "../../../../../../store/financialLogic/action";

import { downloadFile, getAxisDate, isMobile, numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

const emptyData = [
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: 0,
  },
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: 0.05,
  },
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: 0.1,
  },
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: 0.15,
  },
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: 0.2,
  },
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: 0.25,
  },
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: 0.3,
  },
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: 0.35,
  },
  {
    product_quantity: null,
    realization_quantity: null,
    spp_prc_discount_group: null,
  },
];

const CommisionTopProductsChart = props => {
  const {
    keys,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,
    subjectsId,
    dateFrom,
    dateTo,
    commisionTopProductsDetails,
    isLoadingCommisionTopProductsDetails,
    currencyName,
  } = useSelector(state => state.financeLogic);

  const WBAPI = new API();
  const dispatch = useDispatch();
  const [discountGroup, setDiscountGroup] = useState("null");
  const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { commisionTopProducts, tab } = props;
  let formatedData = [];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const {
        payload: { product_quantity, realization_quantity },
      } = payload[0];

      return (
        <DiagramHint
          titleValue="Количество артикулов"
          value={
            product_quantity ? `${Number(numberWithSpaces(product_quantity)).toFixed(0)} шт` : ""
          }
          valueColor={"#00D25F"}
          secondTitleValue="Количество реализованного товара"
          secondValue={
            realization_quantity
              ? `${Number(numberWithSpaces(realization_quantity)).toFixed(0)}`
              : ""
          }
          secondValueColor={"#00D25F"}
          secondValueCurrency="шт"
        />
      );
    }

    return null;
  };

  const getCustomizedYTick = value => {
    if (value === 0) return "=0%";
    else if (value === 0.05) return ">0% <=5%";
    else if (value === 0.1) return ">5% <=10%";
    else if (value === 0.15) return ">10% <=15%";
    else if (value === 0.2) return ">15% <=20%";
    else if (value === 0.25) return ">20% <=25%";
    else if (value === 0.3) return ">25% <=30%";
    else if (value === 0.35) return ">30% <=35%";
    else if (value === "") return ">35%";
  };

  const title =
    tab === 0
      ? "Количество артикулов в разрезе групп значений скидки WB"
      : `Количество реализованного товара в разрезе групп значений скидки WB`;

  if (commisionTopProducts.length > 0) {
    formatedData = emptyData.map(item => {
      return (
        commisionTopProducts.filter(
          i => i.spp_prc_discount_group === item.spp_prc_discount_group
        )[0] || item
      );
    });
  }

  const handleOpenDetails = event => {
    setIsDetailPopupOpen(true);
    setDiscountGroup(event.payload.spp_prc_discount_group);
  };

  const exportToExcel = async () => {
    try {
      setIsExporting(true);
      const res = await WBAPI.commissionDiscountTopDetailsDownload({
        key: keys.join(),
        dateFrom,
        dateTo,
        ids: categoryId.join(),
        brandsId: brandIds.join(),
        aritucl: JSON.stringify(articulIds),
        barcod: barcodeIds.join(),
        group_by: "details",
        subject: subjectsId.join(),
        discountGroup,
      });

      downloadFile(res.url, `Автореклама`);
    } catch (err) {
      console.error(err);
    }
    setIsExporting(false);
  };

  const columns = [
    {
      dataIndex: "title",
      title: "Товар",
      sticky: !isMobile,
      render: ({ name, image, id }) => <ProductInTable image={image} text={name} articule={id} />,
    },
    {
      dataIndex: "supplier_article",
      title: "Артикул",
      render: value => value,
    },
    {
      dataIndex: "product_id",
      title: "Код WB",
      render: value => value,
    },
    {
      dataIndex: "date",
      title: "Дата",
      sticky: !isMobile,
      render: value => getAxisDate(value),
    },
    {
      dataIndex: "spp_prc_discount",
      title: "Скидка WB",
      render: value => value,
    },
    {
      title: `Сумма реализации, ${currencyName}`,
      dataIndex: "realization_value",
      render: value => value,
    },
    {
      title: `Сумма скидки, ${currencyName}`,
      dataIndex: "spp_prc_discount_value",
      render: value => value,
    },
  ];

  useEffect(() => {
    if (keys.length > 0 && discountGroup) {
      dispatch(
        CommissionDiscountTopDetails({
          keys,
          dateFrom,
          dateTo,
          categoryId,
          brandIds,
          articulIds,
          barcodeIds,
          subjectsId,
          discountGroup,
        })
      );
    }
  }, [discountGroup]);
  console.log("commisionTopProductsDetails", commisionTopProductsDetails);
  return (
    <div className={styles.container}>
      <div className={styles.title}></div>
      <div className={styles.commissionScrollableBlock}>
        {formatedData.length ? (
          <>
            <h4>{title}</h4>
            <ResponsiveContainer
              width="100%"
              minWidth={500}
              height={470}
              debounce={650}
              className={styles.wrap}>
              <BarChart
                layout="vertical"
                width={500}
                height={670}
                barGap={-15}
                barSize={16}
                data={formatedData}
                margin={{
                  top: 15,
                  right: 40,
                  left: 10,
                  bottom: 10,
                }}>
                <CartesianGrid vertical horizontal={false} strokeDasharray="3 3" />
                <YAxis
                  dataKey="spp_prc_discount_group"
                  type="category"
                  tick={{ whiteSpace: "nowrap" }}
                  width={100}
                  interval={0}
                  tickFormatter={value => getCustomizedYTick(value)}
                />
                <XAxis type="number" orientation="top" />

                <Bar
                  dataKey={tab === 0 ? "product_quantity" : "realization_quantity"}
                  fill="#00D25F"
                  style={{ cursor: "pointer" }}
                  onClick={e => handleOpenDetails(e)}
                />

                <Tooltip cursor={{ fill: "transparent" }} content={CustomTooltip} />
              </BarChart>
            </ResponsiveContainer>
          </>
        ) : (
          <NoDataMassage title={"Нет данных"} height={300} />
        )}
      </div>

      <Drawer
        anchor="right"
        open={isDetailPopupOpen}
        onClose={() => setIsDetailPopupOpen(false)}
        className={styles.drawerWrap}>
        <Box px={1} py={1} display="flex" justifyContent="end" className={styles.closeIconBlock}>
          <IconButton
            onClick={() => setIsDetailPopupOpen(false)}
            sx={{
              zIndex: 12,
            }}>
            <CloseIcon fill="gray" />
          </IconButton>
        </Box>

        <Button
          variant="outlined"
          iconAfter={XlsIcon}
          isLoading={isExporting}
          disabled={isExporting}
          className={styles.btnExport}
          loadingText="Отчет формируется..."
          onClick={exportToExcel}>
          Выгрузить в Excel
        </Button>

        <Box className={styles.tableWrap}>
          {commisionTopProductsDetails && (
            <TableSort
              columns={columns}
              containerMaxHeight={820}
              data={commisionTopProductsDetails.map(item => ({
                ...item,
                title: {
                  image: item.product_thumbnail,
                  name: item.product_name,
                  id: item.product_id,
                },
                spp_prc_discount:
                  item.spp_prc_discount !== null
                    ? `${(item.spp_prc_discount * 100).toFixed(1)} %`
                    : "-",
                realization_value:
                  item.realization_value !== null
                    ? `${
                        item.realization_value &&
                        numberWithSpaces(item.realization_value.toFixed(0))
                      } ${currencyName}`
                    : "-",
                spp_prc_discount_value:
                  item.spp_prc_discount_value !== null
                    ? `${
                        item.spp_prc_discount_value &&
                        numberWithSpaces(item.spp_prc_discount_value.toFixed(0))
                      } ${currencyName}`
                    : "-",
                button: "",
              }))}
              isLoading={isLoadingCommisionTopProductsDetails}
              name="details"
              showPerPageWhenSwitching
              hideCount
            />
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default CommisionTopProductsChart;
