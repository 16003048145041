import React, { Suspense } from "react";
import { useState } from "react";

import Paper from "@mui/material/Paper";

import TabPanel from "root/components/TabPanel";
import { paidTablesPage } from "root/pages/financial-logic/info";

const PaidTables = () => {
  const [table, setTable] = useState(4);

  const onChageTable = table => {
    setTable(table);
  };

  return (
    <Paper elevation={0}>
      {paidTablesPage.map((item, index) => (
        <Suspense key={item.keyIndex} fallback={<></>}>
          <TabPanel key={item.keyIndex} value={table} index={index}>
            <item.component onTabChange={onChageTable} tabValue={table} />
          </TabPanel>
        </Suspense>
      ))}
    </Paper>
  );
};

export default PaidTables;
