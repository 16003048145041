import request from "root/js/request";

export const getProductsDynamicById = async ({
  product_id,
  date_from,
  date_to,
  limit,
  offset,
  ordering,
  extra_fields,
  period,
}) => {
  if (!+product_id) throw new Error("Некорректное значение артикула");
  const response = await request({
    url: `/wb_dynamic/products/${product_id}/`,
    method: "get",
    params: {
      period,
      date_from,
      date_to,
      limit,
      offset,
      ordering,
      extra_fields,
    },
  });
  return response.data;
};
