import request from "root/js/request";

export const getMarksSellers = async ({
  seller_id,
  end_date__gte,
  end_date__lte,
  start_date__gte,
  start_date__lte,
  limit = 1000,
  offset = 0,
}) => {
  const response = await request({
    url: `/monitoring/marks/sellers/`,
    method: "get",
    params: {
      seller_id,
      end_date__gte,
      end_date__lte,
      start_date__gte,
      start_date__lte,
      limit,
      offset,
    },
  });
  return response.data;
};
