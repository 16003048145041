import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import { prop } from "ramda";

import IALineChartLocf from "root/components/Charts/IALineChartLocf";

import useNavigationMemory from "root/hooks/useNavigationMemory";

import PagesTab from "../../../../../../components/global/pages-tabs";

import { getAxisDate } from "root/js/utils";

const PromotionMediaDynamicLinear = ({ advertDynamics }) => {
  const [isCheckOne, setIsCheckOne] = useState(true);
  const [isCheckTwo, setIsCheckTwo] = useState(true);
  const [isCheckThree, setIsCheckThree] = useState(true);
  const [isCheckFour, setIsCheckFour] = useState(true);

  const [diagramTab, setDiagramTab] = useState(0);

  const { changeParams } = useNavigationMemory();

  const onChangeTab = tab => {
    setDiagramTab(tab);
  };

  useEffect(() => {
    changeParams("diagramTabLinear", diagramTab);
  }, [diagramTab]);

  const formattedData = (elem, field1, field2, field3, field4 = null) => ({
    date: getAxisDate(elem.currentDate),
    dateTick: getAxisDate(elem.currentDate),
    checkOnePress: prop(field1, prop("current", elem)),
    checkTwoPress: prop(field2, prop("current", elem)),
    checkThreePress: prop(field3, prop("current", elem)),
    checkFourPress: prop(field4, prop("current", elem)),
  });

  const diagrams = [
    {
      keyIndex: 0,
      title: "Воронка",
      component: () => {
        const funnelData = advertDynamics?.map(elem =>
          formattedData(elem, "clicks", "views", "atbs", "orders")
        );
        return generateComponentDiagram(funnelData);
      },
    },
    {
      keyIndex: 1,
      title: "Метрики",
      component: () => {
        const metricsData = advertDynamics?.map(elem => formattedData(elem, "romi", "roas", "crr"));
        return generateComponentDiagram(metricsData);
      },
    },
    {
      keyIndex: 2,
      title: "Статистика",
      component: () => {
        const statisticData = advertDynamics?.map(elem =>
          formattedData(elem, "sum_price", "advert_quantity", "product_quantity")
        );
        return generateComponentDiagram(statisticData, "руб", "", "");
      },
    },
  ];

  const labelsMap = {
    0: ["Просмотры", "Клики", "Корзина", "Заказы"],
    1: ["ROMI", "ROAS", "ДРР"],
    2: ["Сумма заказов", "Количество кампаний", "Количество товаров"],
  };

  const labels = labelsMap[diagramTab] || ["CR в текущем периоде", "CR в предыдущем периоде"];
  const generateComponentDiagram = (
    data,
    currencyName,
    currencyNameTwo,
    currencyNameThree,
    currencyNameFour
  ) => {
    return (
      <IALineChartLocf
        data={data}
        currencyName={currencyName}
        currencyNameTwo={currencyNameTwo}
        currencyNameThree={currencyNameThree}
        currencyNameFour={currencyNameFour}
        isCheckOne={isCheckOne}
        isCheckTwo={isCheckTwo}
        isCheckThree={isCheckThree}
        isCheckFour={diagramTab === 2 ? false : isCheckFour}
        setIsCheckOne={setIsCheckOne}
        setIsCheckTwo={setIsCheckTwo}
        setIsCheckThree={setIsCheckThree}
        setIsCheckFour={setIsCheckFour}
        labelOne={labels[0]}
        labelTwo={labels[1]}
        labelThree={labels[2]}
        labelFour={labels[3]}
        checkOne={true}
        checkTwo={true}
        checkThree={true}
        checkFour={diagramTab === 0 ? true : false}
        colorOne={diagramTab === 2 ? "#33CAA6" : "#68B818"}
        colorTwo={"#1E85FF"}
        colorThree={"#FCBC00"}
        colorFour={"#CF3AA0"}
        isBarOne={diagramTab === 2 ? true : false}
        YAxisLeftColor={true}
        isShowYAxisTwoLeft={true}
        isShowYAxisRight={true}
        isShowYAxisTwoRight={true}
        isHideTwoLeftLineY={false}
      />
    );
  };

  return (
    <Paper
      elevation={0}
      style={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}>
      <PagesTab tab={diagramTab} pages={diagrams} onChange={onChangeTab} />
    </Paper>
  );
};

export default PromotionMediaDynamicLinear;
