import React from "react";

import { Box, Paper, Typography } from "@mui/material";

import Image from "root/components/Image";

import contactUs from "./contact-us.jpg";
import DirectorBar from "./DirectorBar";

import styles from "./index.module.scss";

// -----------------------------------------------------------------
const BlockBanner = ({ data }) => {
  return (
    <Box p={2} className={styles.blockBannerBox}>
      {/* <Box> */}
      <Box>
        <Typography sx={{ fontSize: 24, fontWeight: 500 }} mb={2}>
          {data.subject}
        </Typography>
        <Typography variant="subtitle2">{data.description}</Typography>
      </Box>
      <Paper elevation={0} className={styles.directorBox}>
        <Box p={2}>
          <Typography mb={1}>{data.description2}</Typography>
          <DirectorBar />
        </Box>
      </Paper>
      {/* </Box> */}
      <Paper elevation={0} className={styles.contactUsBox}>
        <Box p={2}>
          <Typography sx={{ fontSize: 36, fontWeight: 500 }} mb={1}>
            Остались вопросы?
          </Typography>
          <Typography variant="subtitle2" mb={1}>
            Напишите нам в чат поддержки на сайте
          </Typography>
          <Image src={contactUs} />
        </Box>
      </Paper>
    </Box>
  );
};

export default BlockBanner;
