import React from "react";

import { isNil } from "ramda";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { NoDataMassage } from "root/components/NoDataMessage";

import DiagramHint from "../../../../../../components/global/diagrama/diagram-hint";
import Trips from "../../../../../../components/global/tips";

import styles from "./index.module.scss";

const CommisionStacked = props => {
  const { title, data } = props;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const {
        payload: { name, kvw_prc_commission, spp_prc_discount, kvv },
      } = payload[0];

      return (
        <DiagramHint
          date={name}
          titleValue="Скидка WB"
          value={spp_prc_discount ? `${Number(spp_prc_discount).toFixed(1)} %` : ""}
          valueColor={"#00D25F"}
          secondTitleValue="Комиссия WB"
          secondValue={kvw_prc_commission ? `${Number(kvw_prc_commission).toFixed(1)}` : ""}
          secondValueColor={"#5B9BD5"}
          secondValueCurrency="%"
          thirdTitleValue="кВВ в случае возмещения"
          thirdValue={kvv ? `${Number(kvv).toFixed(1)}` : ""}
          thirdValueColor={"#FFC000"}
          thirdValueCurrency="%"
          fourthTitleValue="Скидка WB (итого)"
          fourthValue={
            kvw_prc_commission && kvv
              ? `${(Number(kvv) + Number(kvw_prc_commission)).toFixed(1)}`
              : ""
          }
          fourthValueColor={"#00D25F"}
          fourthValueCurrency="%"
        />
      );
    }

    return null;
  };

  const formatedData = data
    ?.map(item => {
      return {
        name: item.category_name,
        kvw_prc_commission:
          item.kvw_prc_commission >= item.spp_prc_discount
            ? 0
            : (item.kvw_prc_commission * 100).toFixed(1),
        spp_prc_discount:
          item.kvw_prc_commission >= item.spp_prc_discount
            ? (item.spp_prc_discount * 100).toFixed(1)
            : 0,
        kvv:
          item.kvw_prc_commission >= item.spp_prc_discount
            ? 0
            : ((item.spp_prc_discount - item.kvw_prc_commission) * 100).toFixed(1),
      };
    })
    .filter(
      item =>
        !isNil(item.name) &&
        !isNil(item.kvw_prc_commission) &&
        !isNil(item.spp_prc_discount) &&
        !isNil(item.kvv)
    );

  const CustomXAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={10} transform="rotate(-20)" fontSize={14} textAnchor="end" fill="#262626">
          {payload.value.length > 20 ? `${payload.value.slice(0, 20)}...` : payload.value}
        </text>
      </g>
    );
  };

  return (
    <div className={styles.cicleBox}>
      <div className={styles.title}>
        <h4>{title}</h4>
      </div>
      <div className={styles.descriptionBox}>
        <div className={styles.flex}>
          <div className={styles.cube} style={{ backgroundColor: "#68B818" }}></div>
          <span>Скидка WB = </span>
        </div>
        <div className={styles.flex}>
          <div className={styles.cube} style={{ backgroundColor: "#1E85FF" }}></div>
          <span>Комиссия WB + </span>
        </div>
        <div className={styles.flex}>
          <div className={styles.cube} style={{ backgroundColor: "#FCBC00" }}></div>
          <span>
            кВВ в случае возмещения
            <Trips text="Абсолютное значение так называемого отрицательного кВВ, образуемого в случае предоставления маркетплейсом скидки постоянного покупателя, превышающей комиссию по тарифу" />
          </span>
        </div>
      </div>
      <div className={styles.scrollableBlock}>
        {formatedData.length !== 0 ? (
          <ResponsiveContainer
            width="100%"
            // minWidth={1000}
            height={240}
            debounce={350}
            className={styles.wrap}>
            <BarChart
              height={350}
              barGap={-30}
              barSize={30}
              data={formatedData}
              margin={{
                top: 0,
                right: 0,
                left: 60,
                bottom: 45,
              }}>
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <XAxis dataKey="name" interval={0} tick={CustomXAxisTick} />
              <YAxis fill="#798097" tickFormatter={value => `${Number(value).toFixed(1)}%`} />
              <Bar dataKey={"kvw_prc_commission"} fill="#1E85FF" stackId="a" />
              <Bar dataKey={"kvv"} fill="#FCBC00" stackId="a" />
              <Bar dataKey={"spp_prc_discount"} fill="#68B818" />
              <Tooltip cursor={{ fill: "transparent" }} content={CustomTooltip} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <NoDataMassage title={"Нет данных"} />
        )}
      </div>
    </div>
  );
};

export default CommisionStacked;
