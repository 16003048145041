import request from "root/js/request";

const getTop10Products = async ({ cabinet_id__in, order_by }) => {
  const response = await request({
    url: `analytics/top_ten_products/`,
    method: "get",
    params: {
      cabinet_id__in,
      order_by,
    },
  });
  return response.data;
};

export { getTop10Products };
