import React from "react";

import {
  BOARD_RADIUS_QUANTITY,
  FBO_COORDINATES,
  MAX_RADUIS,
  MIN_RADUIS,
  RADIUS_COEFFICIENT_MAX,
  RADIUS_COEFFICIENT_MIN
} from "./constants";

export const renderCustomDot = (payload, cx, cy, stroke, value) => {
  if (payload?.[value] !== undefined && payload?.[value] !== null) {
    return <circle r="4" cx={cx} cy={cy} fill={stroke} stroke="#fff" strokeWidth="1.5" />;
  }
};

export const getTotalQuantity = (data, key) => {
  if (!data || data?.length === 0) return 0;
  return data.reduce((sum, el) => (sum = sum + el[key]), 0);
};

export const calcMarckerRadius = (quantity, totalQuantity) => {
  if (!quantity || !totalQuantity) return 0;
  const radius =
    totalQuantity > BOARD_RADIUS_QUANTITY
      ? (quantity / totalQuantity) * RADIUS_COEFFICIENT_MAX
      : (quantity / totalQuantity) * RADIUS_COEFFICIENT_MIN;

  if (radius < MIN_RADUIS) {
    return MIN_RADUIS;
  } else if (radius > MAX_RADUIS) {
    return MAX_RADUIS;
  } else return radius;
};

export const formatRemainsFbo = (data, checked) => {
  if (checked.length === 3) {
    const totalQuantity = getTotalQuantity(data, "total_quantity");
    return data.map(el => {
      const coordinates = FBO_COORDINATES.find(
        item => item.warehouse__name === el.warehouse__name
      )?.coordinates;
      const radius = calcMarckerRadius(el.total_quantity, totalQuantity);
      return { ...el, coordinates, radius, mapValue: el.total_quantity };
    });
  } else if (checked.length === 0) {
    return data.map(el => ({
      ...el,
      coordinates: FBO_COORDINATES.find(item => item.warehouse__name === el.warehouse__name)
        ?.coordinates,
      mapValue: 0
    }));
  } else {
    const newData = data.map(el => ({
      ...el,
      coordinates: FBO_COORDINATES.find(item => item.warehouse__name === el.warehouse__name)
        ?.coordinates,
      mapValue: 0
    }));
    checked.forEach(el => {
      newData.forEach(item => {
        item.mapValue = item.mapValue + item[el];
      });
    });
    const totalQuantity = getTotalQuantity(newData, "mapValue");

    return newData.map(el => ({
      ...el,
      radius: calcMarckerRadius(el.mapValue, totalQuantity)
    }));
  }
};

export const getColorCountry = postal => {
  return postal === "BY" || postal === "KZ" ? "#d3deed" : "#EAEAEC";
};

export const getColorsAndValues = (el, checked) => {
  const data = {
    sum_quantity: { color: "#68B818", value: 0 },
    sum_to_client: { color: "#1E85FF", value: 0 },
    sum_from_client: { color: "#E59700", value: 0 }
  };

  if (checked.includes("sum_quantity") && el.sum_quantity > 0) {
    data.sum_quantity.value = el.sum_quantity;
  }
  if (checked.includes("sum_to_client") && el.sum_to_client > 0) {
    data.sum_to_client.value = el.sum_to_client;
  }
  if (checked.includes("sum_from_client") && el.sum_from_client > 0) {
    data.sum_from_client.value = el.sum_from_client;
  }

  return data;
};

export const createSegmentPaths = (data, radius) => {
  const paths = [];
  let accumulatedAngle = 0;
  const totalValue = Object.values(data).reduce((acc, item) => acc + item.value, 0);

  Object.keys(data).forEach((key, index) => {
    const { color, value } = data[key];
    if (value > 0) {
      const anglePerValue = (value / totalValue) * 360;
      const startAngle = accumulatedAngle;
      const endAngle = startAngle + anglePerValue;
      accumulatedAngle = endAngle;

      const largeArcFlag = anglePerValue > 180 ? 1 : 0;
      const startX = radius * Math.cos((startAngle * Math.PI) / 180);
      const startY = radius * Math.sin((startAngle * Math.PI) / 180);
      const endX = radius * Math.cos((endAngle * Math.PI) / 180);
      const endY = radius * Math.sin((endAngle * Math.PI) / 180);

      paths.push(
        <path
          key={index}
          d={`
            M 0 0
            L ${startX} ${startY}
            A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
            Z
          `}
          fill={color}
        />
      );
    }
  });

  return paths;
};

export const formatRemainsFboTableData = data => {
  if (!data) return [];
  const stocks = [];

  for (let key in data.warehouses) {
    if (key !== "null") {
      stocks.push({ warehouse__id: key, warehouse__name: data.warehouses[key] });
    }
  }
  return data.results.map(el => {
    for (let key in el) {
      if (el[key] === 0) {
        el[key] = "-";
      }
    }
    return {
      ...el,
      stocks
    };
  });
};

export const getTableHead = data => {
  if (!data || !data[0]) return [];
  const stocks = data[0].stocks;
  const head = [];

  stocks.forEach(el => {
    head.push({
      dataIndex: `${el.warehouse__name}, ${el.warehouse__id}_quantity`,
      title: "Доступно для продажи",
      render: value => value,
      sortable: true,
      style: { minWidth: "135px" }
    });
    head.push({
      dataIndex: `${el.warehouse__name}, ${el.warehouse__id}_in_way_to_client`,
      title: `В пути к клиенту`,
      render: value => value,
      sortable: true,
      style: { minWidth: "100px" }
    });
    head.push({
      dataIndex: `${el.warehouse__name}, ${el.warehouse__id}_in_way_from_client`,
      title: `В пути от клиента`,
      render: value => value,
      sortable: true,
      style: { minWidth: "110px" }
    });
  });
  return head;
};

export const getParentHeader = data => {
  if (!data || !data[0]) return [];
  const stocks = data[0].stocks;
  return stocks.map(el => el.warehouse__name);
};

export const formatData = data => {
  if (!data || !data[0]) return [];
  const stocks = data[0].stocks;

  return data.map(el => {
    const item = {};
    for (let key in el) {
      if (
        key.includes("_quantity") ||
        key.includes("_in_way_to_client") ||
        key.includes("_in_way_from_client")
      ) {
        const id = key.split("_")[0];
        const stock = stocks.find(s => s.warehouse__id === id)?.warehouse__name;
        item[`${stock}, ${key}`] = el[key] === 0 ? "-" : el[key];
      } else {
        item[key] = el[key];
      }
    }
    return item;
  });
};

export const isValidJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const formatShowingDrowerColumns = data => {
  return data.map(el => ({
    title: el,
    dataIndex: el
  }));
};

export const filterColumns = (columns, showingDrowerColumns, count) => {
  return columns
    .slice(0, count)
    .concat(columns.filter(item => showingDrowerColumns.includes(item.dataIndex.split(",")[0])));
};
