import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M12 3C7.02528 3 3 7.02564 3 12C3 16.9747 7.02564 21 12 21C16.9747 21 21 16.9744 21 12C21 7.02528 16.9743 3 12 3ZM12 19.5938C7.80259 19.5938 4.40625 16.1971 4.40625 12C4.40625 7.80259 7.80287 4.40625 12 4.40625C16.1974 4.40625 19.5938 7.80287 19.5938 12C19.5938 16.1974 16.1971 19.5938 12 19.5938Z" />
		<path d="M12 10.535C11.6117 10.535 11.2969 10.8498 11.2969 11.2382V15.766C11.2969 16.1544 11.6117 16.4692 12 16.4692C12.3883 16.4692 12.7031 16.1543 12.7031 15.766V11.2382C12.7031 10.8498 12.3883 10.535 12 10.535Z" />
		<path d="M12 9.67406C12.5242 9.67406 12.9492 9.24908 12.9492 8.72484C12.9492 8.2006 12.5242 7.77562 12 7.77562C11.4758 7.77562 11.0508 8.2006 11.0508 8.72484C11.0508 9.24908 11.4758 9.67406 12 9.67406Z" />
	</Template>
))
