import React from "react"

import classNames from "classnames"

import Square from "../../icons/square"
import Triangle from "../../icons/triangle"
import Trips from "../../tips"

import { numberWithSpaces } from "root/js/utils"

import styles from "./index.module.scss"

const ChildCard = props => {
	const {
		title,
		trip,
		valuet,
		value,
		precent,
		borderIncrement,
		borderDicrement,
		colorIncrement,
		colorDicrement,
		transformRotateIncrement,
		transformRotateDicrement,
		secondValue,
		customStyles
	} = props

	return (
		<div
			className={classNames(styles.childCardContainer, customStyles ? styles.noMarginTop : '')}
			style={{
				borderTop:
				(precent * 100).toFixed(1) < 0
						? borderDicrement
						: (precent * 100).toFixed(1) === "0.0" || (precent * 100).toFixed(1) === "-0.0" || precent===0 || precent === Infinity || precent === - Infinity
						? "4px solid #FFA500"
						: borderIncrement,
			}}


		// (precent * 100).toFixed(1) < 0
		// ? colorDicrement
		// : precent === 0 ||
		//   (precent * 100).toFixed(1) === "0.0" ||
		//   (precent * 100).toFixed(1) === "-0.0"
		// ? "#FFA500"
		// : colorIncrement,
		>
			<div className={styles.data}>
				<div className={classNames("d-flex", styles.alignItemsCenter)}>
					<h5> {title}</h5>
					{trip && <Trips text={trip} />}
				</div>
				<span className={styles.sum}>
					{valuet === "%"
						? `${(value * 100).toFixed(1)}${valuet}`
					 	: `${numberWithSpaces(value) } ${valuet}`}
					
				</span>
			</div>

			<div className={styles.childPercent}>
				<span
					style={{
						color:
							(precent * 100).toFixed(1) < 0
								? colorDicrement
								: precent === 0 ||
									(precent * 100).toFixed(1) === "0.0" ||
									(precent * 100).toFixed(1) === "-0.0"
									|| precent === Infinity || precent === -Infinity
									? "#FFA500"
									: colorIncrement,
					}}
				>
					
					{precent === Infinity || precent === -Infinity
						? "-"
						: (precent * 100).toFixed(1) === "0.0" ||
						  (precent * 100).toFixed(1) === "-0.0"
						? "0"
						: `${(precent * 100).toFixed(1)}${secondValue}`}
				    
				</span>

				{precent === 0 ||
					(precent * 100).toFixed(1) === "0.0" ||
					(precent * 100).toFixed(1) === "-0.0" || precent === Infinity || precent === -Infinity ? (
					<Square />
				) : (
					<Triangle
						result={precent}
						colorIncrement={colorIncrement}
						colorDicrement={colorDicrement}
						transformRotateIncrement={transformRotateIncrement}
						transformRotateDicrement={transformRotateDicrement}
					/>
				)}
			</div>
		</div>
	)
}

export default ChildCard
