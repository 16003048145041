import React, { useEffect, useMemo, useState } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { isEmpty } from "ramda";
import PhoneInput from "react-phone-input-2";
import { WBapi } from "root/services/WBapi";

import API from "root/js/api";

import Button_ from "../Button";

import SuccessArrow from "root/icons/SuccessArrow";

import "react-phone-input-2/lib/style.css";
// variables
const redMark = "❌";
const WBAPI = new API();

// --------------------------------------------------------------------------
const CallbackOrders = ({ fulfilledFunc = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  //
  const [canOrderCallback, setCanOrderCallback] = useState(true);
  const savedExistPhones = JSON.parse(localStorage.getItem("existPhones"));
  const [existPhones, setExistPhones] = useState(savedExistPhones || []);
  const [phoneNumber, setPhoneNumber] = useState("");
  const onChangePhoneNumber = newPhoneNum => {
    const numberWithoutSpaces = newPhoneNum.split(" ").join("");
    if (numberWithoutSpaces.length >= 13 && numberWithoutSpaces.includes("+79")) return null;
    if (numberWithoutSpaces.length >= 16) return null;
    setPhoneNumber(newPhoneNum);
  };
  // --------------------------- FETCH
  const {
    data = {},
    isLoading: isCallbackCountLoading,
    isFetching: isCallbackCountFetching,
    refetch,
  } = WBapi.useCheckCallbackOrdersQuery({ params: {} });
  useEffect(() => {
    if (!isEmpty(data)) {
      setCanOrderCallback(!!data?.user_demo_limits?.exists_usage_count);
    }
  }, [data]);
  //
  const [isOrderCallbackLoading, setIsOrderCallbackLoading] = useState(false);
  const isLoading = useMemo(
    () => isCallbackCountLoading || isCallbackCountFetching || isOrderCallbackLoading,
    [isCallbackCountLoading, isCallbackCountFetching, isOrderCallbackLoading]
  );
  const onOrderCall = async () => {
    try {
      setIsOrderCallbackLoading(true);
      const response = await WBAPI.orderCallBack({
        phone: phoneNumber.split(" ").join(""),
        message: "Обратный звонок от Демо",
      });
      if (response.run_id) {
        fulfilledFunc();
      }
    } catch (error) {
      const errorData = error?.response?.data;
      if (errorData?.non_field_errors) {
        switch (errorData?.non_field_errors[0]?.code) {
          case "exist":
            enqueueSnackbar({
              title: "Ошибка!",
              message: "Вы уже оставили заявку на данный номер",
              type: "error",
            });
            break;
          case "limit_reached":
            enqueueSnackbar({
              title: "Ошибка!",
              message: "Вы уже оставили 5 заявок, ожидайте звонка менеджера",
              type: "error",
            });
            break;
          default:
            enqueueSnackbar({
              title: "Ошибка!",
              message:
                "При отправке запроса произошла ошибка, пожалуйста повторите попытку позднее или обратитесь в нашу поддержку, в правом нижнем углу экрана",
              type: "error",
            });
        }
      }
      if (errorData?.phone) {
        enqueueSnackbar({
          title: `${redMark} Не удалось заказать звонок`,
          message: `На данный номер уже оставлена заявка, пожалуйста, ожидайте звонка от менеджера. 
          
          Если остались вопросы напишите нам в чат поддержки, в правом нижнем углу. `,
          type: "error",
        });
        localStorage.setItem("existPhones", JSON.stringify([...existPhones, phoneNumber]));
        setExistPhones(prev => [...prev, phoneNumber]);
      }
    } finally {
      setIsOrderCallbackLoading(false);
      refetch();
    }
  };

  if (isLoading) return <CircularProgress />;
  return (
    <>
      {canOrderCallback ? (
        <>
          <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"} gap={1}>
            <Box>
              <PhoneInput
                disableDropdown
                containerStyle={{
                  width: "100%",
                  height: "50px",
                }}
                inputStyle={{
                  width: "100%",
                  height: "50px",
                }}
                country={"ru"}
                value={phoneNumber}
                onChange={onChangePhoneNumber}
                inputProps={{
                  required: true,
                  autoFocus: true,
                }}
              />
            </Box>
            <Box>
              <Button_
                disabled={existPhones.includes(phoneNumber) || isLoading || !phoneNumber.trim()}
                variant="filled"
                onClick={onOrderCall}>
                Заказать звонок
              </Button_>
            </Box>
          </Box>
          {existPhones.includes(phoneNumber) && (
            <Box mt={0.5}>
              <Typography color={"red"} variant="subtitle1">
                На этот номер уже заказан обратный звонок
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Box display={"flex"} alignItems={"center"} gap={1} my={1}>
            <SuccessArrow />
            <Typography>Вы уже оставили 5 заявок, ожидайте звонка менеджера</Typography>
          </Box>
          <Typography variant="subtitle1">
            Если у вас остались вопросы, можете задать их в нашем чате поддержки, в правом нижнем
            углу экрана.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CallbackOrders;
