import request from "root/js/request";

const getPostingEconomyWidgetsOzon = async ({ wb_api_key_ids, date_from, date_to }) => {
  try {
    const response = await request({
      url: "/ozon_analytics/posting_economy_widgets/",
      method: "get",
      params: {
        wb_api_key_ids,
        date_from,
        date_to,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in getPostingEconomyWidgets:", error);
  }
};

export { getPostingEconomyWidgetsOzon };
