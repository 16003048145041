import React, { useEffect, useMemo } from "react";

import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { useSelector } from "react-redux";

import ColorCheckbox from "../ColorCheckbox";
import Tooltip_ from "../Tooltip";

import { socialNetworksImages } from "./utils";

// import styles from "./index.module.scss";

// -----------------------------------------------------------------
const SocialNetworksCheckBoxes = ({
  showSocialNetworks = false,
  setShowSocialNetworks = () => {},
  existSocialNetworks = [],
  lastReportDate,
  disabledForCurrentTariff = false,
}) => {
  //
  const disabled = useMemo(
    () => !existSocialNetworks.length || disabledForCurrentTariff,
    [existSocialNetworks, disabledForCurrentTariff]
  );
  //
  const { id: tariffId } = useSelector(state => state.userTariff);
  const showForCurrentTariff = useMemo(() => [4, 14, 16, 17].includes(tariffId), [tariffId]);
  useEffect(() => {
    if (!showForCurrentTariff) setShowSocialNetworks(false);
  }, [showForCurrentTariff]);
  if (!showForCurrentTariff) return null;
  return (
    <Box mb={1}>
      <Box display={"flex"} flexWrap={"wrap"} gap={"1rem"} alignItems={"center"}>
        <Typography variant="h5">Социальные сети</Typography>
        <Box display={"flex"} flexWrap={"wrap"} gap={".5rem"} alignItems={"center"}>
          <Tooltip_
            title={
              disabledForCurrentTariff
                ? "Доступно только на тарифах «Профессиональный» и «Корпоративный»"
                : disabled &&
                  "Нет информации о публикациях в соц сетях по данному артикулу за выбранный период"
            }>
            <Box>
              <ColorCheckbox
                color="#cf3aa0"
                checked={!disabled && showSocialNetworks}
                disabled={disabled}
                onChange={e => setShowSocialNetworks(e.target.checked)}
              />
            </Box>
          </Tooltip_>
          {Object.keys(socialNetworksImages).map(nameSN => {
            const currentImage = existSocialNetworks.includes(nameSN)
              ? socialNetworksImages[nameSN].default
              : socialNetworksImages[nameSN].disabled;
            return (
              <Tooltip_ key={nameSN} title={socialNetworksImages[nameSN].tooltip}>
                <Box height={"16px"} sx={{ cursor: "pointer" }}>
                  <img src={currentImage} style={{ width: 16, height: 16 }} alt={nameSN} />
                </Box>
              </Tooltip_>
            );
          })}
        </Box>
      </Box>
      {lastReportDate && (
        <Typography variant="subtitle2">
          дата последнего обновления соц. сетей: {format(new Date(lastReportDate), "dd.MM.yyyy")}
        </Typography>
      )}
    </Box>
  );
};

export default SocialNetworksCheckBoxes;
