import request from "root/js/request";

export const getAnalyticsProductsJoint = async ({
  cabinet_id__in,
  product_id,
  imt_id,
  size_id,
  brand_id__in,
  category_id__in,
  subject_id__in,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/products_joint/",
    method: "get",
    params: {
      cabinet_id__in,
      product_id,
      imt_id,
      size_id,
      brand_id__in,
      category_id__in,
      subject_id__in,
      ...rest,
    },
  });
  return response.data;
};
