import request from "root/js/request";

export const getSocialBloggersCount = async params => {
  const response = await request({
    url: `parsers/social/bloggers/count/`,
    method: "get",
    params: {
      ...params,
      subject_id__in: params.item__in,
    },
  });
  return response;
};
