import React from "react";

import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { CircleDescription } from "root/components/global/circle/circle-description";
import Circle from "root/components/global/circle/circle-diagram";
import Trips from "root/components/global/tips";
import { checkNoDataValue } from "root/pages/financial-logic/helpers";

import CircleHint from "../global/circle/circle-hint";
import DiagramBarChart from "../global/diagrama/diagram-Bar-Chart";
import LinearLoading from "../LinearLoading";
import DataNotLoaded from "../Plugs/DataNotLoaded";

import { numberWithSpaces } from "root/js/utils";

import styles from "./index.module.scss";

const CustomPieChart = ({
  title,
  data,
  colors,
  tab,
  currencyName,
  isNegativeVariant,
  titleTrips,
  precentInHint = false,
  isLoading,
  isDataNotLoaded,
  noDataText = "данные",
}) => {
  let names = data?.map(elem => [elem.office_name, elem?.text]);

  const description = names?.map((element, index) => {
    return { name: element[0], color: colors[index], text: element[1] ? element[1] : "" };
  });

  const CustomizedCursor = ({ active, payload, coordinate }) => {
    if (active && payload && payload.length) {
      const {
        payload: { office_name, value, precent },
      } = payload[0];

      if (coordinate.y < 50) {
        coordinate.y += 50;
      }

      const result =
        value !== null && value !== "Нет данных"
          ? tab === "warehouse_price_value" || tab === "ride" || tab === "penalty" || tab === "none"
            ? `${numberWithSpaces(value.toFixed(1))} ${currencyName}`
            : `${numberWithSpaces(value.toFixed(0))} шт`
          : "Нет данных";

      if (value) {
        return (
          <CircleHint
            circleColor={colors[payload[0].name]}
            name={office_name}
            value={value !== null && value !== "Нет данных" ? result : "Нет данных"}
            precent={precentInHint ? (precent ? ` (${precent.toFixed(1)}%)` : "") : ""}
          />
        );
      }
    }

    return null;
  };

  let temporaryFormatedData = data?.map(elem => {
    if (elem.office_name?.length > 0) return elem;
  });

  //   for bar chart
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const {
        payload: { office_name, value, precent },
      } = payload[0];

      return (
        <div className={styles.diagramDecription}>
          <div className={styles.legendsBox}>
            <div>
              <div className={styles.legends}>
                <div
                  style={{ backgroundColor: value > 0 ? "#00C85A" : "red" }}
                  className={styles.circle}></div>
                <span>{office_name || "Нет данных"}:</span>
              </div>
              <div className={styles.value}>
                {value !== null && value !== "Нет данных"
                  ? `${numberWithSpaces(value?.toFixed(0))} ${currencyName}`
                  : "Нет данных"}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };
  function getMinMaxValues() {
    return [
      dataMin => dataMin !== Infinity && Math.round(dataMin - dataMin * 0.1),
      dataMax => (dataMax < 4 ? 4 : Math.round(dataMax + dataMax * 0.1)),
    ];
  }

  const CustomYAxisTick = props => {
    const { x, y, payload } = props;

    const tickColor = "#666666";

    return (
      <g transform={`translate(${x},${y})`} width={140}>
        <text width={140} x={0} y={-12} dy={16} textAnchor="end" fill={tickColor}>
          {payload.value}
        </text>
      </g>
    );
  };
  const CustomXAxisTick = props => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={50} y={0} dy={16} textAnchor="end" fill="#262626">
          {`${numberWithSpaces(payload.value)} ₽`}
        </text>
      </g>
    );
  };

  function hasNegativeValue(data) {
    return data.reduce((hasNegative, item) => hasNegative || item.precent < 0, false);
  }

  function countDigits(number) {
    const numberString = Math.abs(number).toString();
    return numberString.length;
  }

  function getDivider(number) {
    let divider = "1";
    for (let i = number - 1; i > 0; i--) {
      divider = divider + "0";
    }
    return Number(divider);
  }

  function maxMin(data) {
    let max, min;

    if (data) {
      max = data.reduce((max, obj) => (obj.value > max.value ? obj : max), data[0]);
      min = data.reduce((min, obj) => (obj.value < min.value ? obj : min), data[0]);
    }

    if (min?.value && max?.value) {
      let minValDigitsCount = countDigits(min.value.toFixed(0));
      let maxValDigitsCount = countDigits(max.value.toFixed(0));
      let dividerMin = getDivider(minValDigitsCount);
      let dividerMax = getDivider(maxValDigitsCount);

      return [
        Math.floor(min.value / dividerMin).toFixed(0) * dividerMin,
        min.value.toFixed(0) < 0 && Math.ceil(max.value / dividerMax).toFixed(0) * dividerMax,
      ];
    }
  }

  let ticksValue = maxMin(data);
  const hasNegative = hasNegativeValue(data);

  const isNoValueMap = checkNoDataValue(temporaryFormatedData);

  return (
    <div className={classNames(styles.cicleBox, hasNegative ? styles.negativeChartProps : "")}>
      <div className={styles.title}>
        <Typography sx={{ fontSize: 18, fontWeight: 500, color: "#43455C" }}>{title}</Typography>
        {titleTrips && <Trips text={titleTrips} />}
      </div>
      <div className={styles.scrollableBlock}>
        {isLoading ? (
          <LinearLoading />
        ) : (
          !isDataNotLoaded &&
          isNoValueMap &&
          !temporaryFormatedData.length && <DataNotLoaded text={noDataText} />
        )}
        {hasNegative && isNegativeVariant ? (
          <DiagramBarChart
            data={temporaryFormatedData}
            ticksValue={ticksValue}
            width={360}
            height={320}
            customStyle={true}
            barKey="value"
            xAxisKey="office_name"
            CustomTooltip={CustomTooltip}
            yAxisTickFormater={value => `${numberWithSpaces(value.toFixed(0))} ${currencyName}`}
            getMinMaxValues={getMinMaxValues}
            CustomYAxisTick={CustomYAxisTick}
            CustomXAxisTick={CustomXAxisTick}
            hasMinBarHeight
          />
        ) : (
          <div className={styles.descriptionCicrleBox}>
            <Circle
              width={340}
              height={340}
              cx={175}
              cy={150}
              data={temporaryFormatedData}
              colors={colors}
              CustomizedCursor={CustomizedCursor}
            />
            <div className={styles.cicleDescription}>
              {!isNegativeVariant && <Typography variant={"h5"}>Склад</Typography>}
              {description?.map((elem, index) => (
                <div key={index} className={styles.legenditem}>
                  <CircleDescription
                    name={elem.name.length > 13 ? `${elem.name.slice(0, 13)}...` : elem.name}
                    color={elem.color}
                  />
                  {elem.text && <Trips text={elem.text} />}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomPieChart;
