import React, { useState } from "react";

import { Paper } from "@mui/material";
import { isNil, prop } from "ramda";

import IALineChartLocf from "root/components/Charts/IALineChartLocf";
import { propFields } from "root/components/Charts/IALineChartLocf/helpers";

import { getAxisDate } from "root/js/utils";

const CommissionDiagram = ({ commisionDiagram }) => {
  const [isCheckOne, setIsCheckOne] = useState(true);
  const [isCheckTwo, setIsCheckTwo] = useState(true);
  const [isCheckThree, setIsCheckThree] = useState(false);
  const [isCheckFour, setIsCheckFour] = useState(false);

  const prepareDataDiagramWBCommission = (elem, fields1, fields2) => ({
    date: `${new Date(prop("currentDate", elem)).getDate()} ${new Date(
      prop("currentDate", elem)
    ).toLocaleString("ru-RU", { month: "short" })}`,
    dateTick: getAxisDate(elem.currentDate),
    checkOnePress:
      !isNil(prop(fields1, prop("current", elem))) && prop(fields1, prop("current", elem)),
    checkTwoPress: !isNil(prop(fields1, prop("prev", elem))) && prop(fields1, prop("prev", elem)),
    checkThreePress:
      !isNil(prop(fields2, prop("current", elem))) && prop(fields2, prop("current", elem)),
    checkFourPress: !isNil(prop(fields2, prop("prev", elem))) && prop(fields2, prop("prev", elem)),
  });

  const formattedData = commisionDiagram.map(item => {
    return prepareDataDiagramWBCommission(
      item,
      propFields.spp_prc_discount,
      propFields.kvw_prc_commission
    );
  });

  const generateComponentDiagram = (data, currencyName) => {
    return (
      <IALineChartLocf
        title={"Средние значения скидки WB в динамике в заданном периоде"}
        data={data}
        currencyName={currencyName}
        // currencyNameTwo={currencyName}
        isCheckOne={isCheckOne}
        isCheckTwo={isCheckTwo}
        isCheckThree={isCheckThree}
        isCheckFour={isCheckFour}
        setIsCheckOne={setIsCheckOne}
        setIsCheckTwo={setIsCheckTwo}
        setIsCheckThree={setIsCheckThree}
        setIsCheckFour={setIsCheckFour}
        labelOne={"Скидка WB - текущий период"}
        labelTwo={"Скидка WB - предыдущий период"}
        labelThree={"Комиссия WB - текущий период"}
        labelFour={"Комиссия WB - предыдущий период"}
        checkTwo={true}
        checkOne={true}
        checkThree={true}
        checkFour={true}
        colorOne={"#FCBC00"}
        colorTwo={"#08ADEB"}
        colorThree={"#CF3AA0"}
        colorFour={"#05E0AB"}
      />
    );
  };

  return (
    <>
      <Paper
        elevation={0}
        style={{
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}>
        {generateComponentDiagram(formattedData, "%")}
      </Paper>
    </>
  );
};
export default CommissionDiagram;
