import React from "react";

import classNames from "classnames";

import { calcConversion } from "root/js/utils";

import styles from "./index.module.scss";

const DiagramLineHint = props => {
  const {
    date,
    isCheckOne,
    checkOneValue,
    checkOnePeriodText,
    checkTwoPeriodText,
    checkThreePeriodText,
    checkFourPeriodText,
    checkTwoValue,
    checkThreeValue,
    checkFourValue,
    isCheckTwo,
    isCheckThree,
    isCheckFour,
    isTrend,
    isOrders,
    isEvents,
    trendText,
    trendValue,
    ordersText,
    ordersValue,
    eventsText,
    eventsValue,
    isPromotionPage,
    isForecastPage,
    isConversion,
    colorOne,
    colorTwo,
    colorThree,
    colorFour,
  } = props;
  return (
    <div className={styles.diagramDescription}>
      {date && <p className={styles.hintTitle}>Текущая дата: {date}</p>}

      <div className={styles.periodContainer}>
        {isCheckOne && (
          <div className={styles.legends}>
            <div className={styles.period}>
              <div
                className={classNames(
                  styles.circle,
                  isPromotionPage
                    ? styles.circleCurrentPromotion
                    : isForecastPage
                    ? styles.circleCurrentForecast
                    : styles.circleCurrent
                )}
                style={{ backgroundColor: colorOne }}></div>
              <span className={styles.textSize}>{checkOnePeriodText}</span>
            </div>
            <div className={styles.valueContainer}>
              {checkOneValue || checkOneValue === 0 ? checkOneValue : "Нет данных"}
            </div>
          </div>
        )}

        {isCheckTwo && (
          <div className={styles.legends}>
            <div className={styles.period}>
              <div
                className={classNames(
                  styles.circle,
                  isPromotionPage
                    ? styles.circlePreventPromotion
                    : isForecastPage
                    ? styles.circlePreventForecast
                    : styles.circlePrevent
                )}
                style={{ backgroundColor: colorTwo }}></div>
              <span className={styles.textSize}>{checkTwoPeriodText}</span>
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.valueSize}>
                {checkTwoValue || checkTwoValue === 0 ? checkTwoValue : "Нет данных"}
              </span>
              {isConversion ? (
                <span className={styles.conversion}>{`(${calcConversion(
                  checkOneValue,
                  checkTwoValue
                )}%)`}</span>
              ) : null}
            </div>
          </div>
        )}
        {isCheckThree && (
          <div className={styles.legends}>
            <div className={styles.period}>
              <div
                className={classNames(
                  styles.circle,
                  isPromotionPage
                    ? styles.circlePreventPromotion
                    : isForecastPage
                    ? styles.circlePreventForecast
                    : styles.circlePrevent
                )}
                style={{ backgroundColor: colorThree }}></div>
              <span className={styles.textSize}>{checkThreePeriodText}</span>
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.valueSize}>
                {checkThreeValue || checkThreeValue === 0 ? checkThreeValue : "Нет данных"}
              </span>
            </div>
          </div>
        )}
        {isCheckFour && (
          <div className={styles.legends}>
            <div className={styles.period}>
              <div
                className={classNames(
                  styles.circle,
                  isPromotionPage
                    ? styles.circlePreventPromotion
                    : isForecastPage
                    ? styles.circlePreventForecast
                    : styles.circlePrevent
                )}
                style={{ backgroundColor: colorFour }}></div>
              <span className={styles.textSize}>{checkFourPeriodText}</span>
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.valueSize}>
                {checkFourValue || checkFourValue === 0 ? checkFourValue : "Нет данных"}
              </span>
            </div>
          </div>
        )}

        {isTrend && (
          <div className={styles.legends}>
            <div className={styles.period}>
              <div
                className={classNames(
                  styles.circle,
                  isPromotionPage
                    ? styles.circleTrendPromotion
                    : isForecastPage
                    ? styles.circleTrendForecast
                    : styles.circleTrend
                )}></div>
              <span className={styles.textSize}>{trendText}</span>
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.valueSize}>{trendValue || "Нет данных"}</span>
              {isConversion ? (
                <span className={styles.conversion}>{`(${calcConversion(
                  checkTwoValue,
                  trendValue
                )}%)`}</span>
              ) : null}
            </div>
          </div>
        )}

        {isOrders && (
          <div className={styles.legends}>
            <div className={styles.period}>
              <div
                className={classNames(
                  styles.circle,
                  isPromotionPage
                    ? styles.circleOrdersPromotion
                    : isForecastPage
                    ? styles.circleOrdersForecast
                    : styles.circleOrders
                )}></div>
              <span className={styles.textSize}>{ordersText}</span>
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.valueSize}>{ordersValue || "Нет данных"}</span>
              {isConversion ? (
                <span className={styles.conversion}>{`(${ordersValue}%)`}</span>
              ) : null}
            </div>
          </div>
        )}

        {isEvents && eventsValue && (
          <div className={styles.legends}>
            <div className={styles.period}>
              <div className={classNames(styles.circle, styles.circleEvents)}> </div>
              <span className={styles.textSize}>{eventsText}</span>
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.valueSize}>{eventsValue || "Нет данных"}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiagramLineHint;
