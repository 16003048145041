import request from "root/js/request";

// Список подписок
const getNotificationSubscription = async ({ cabinet_id }) => {
  const response = await request({
    url: `/analytics/notification-subscriptions/`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response.data;
};

// Создание подписки
const postNotificationSubscription = async notificationData => {
  const { ...data } = notificationData;

  const response = await request({
    url: `/analytics/notification-subscriptions/?cabinet_id=${data.cabinet_id}`,
    method: "post",
    data: data,
  });

  return response;
};

// Подписка по группе
const getNotificationSubscriptionByGroup = async ({ cabinet_id, group_id }) => {
  const response = await request({
    url: `/analytics/notification-subscriptions/?group_id=${group_id}`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response.data;
};

// Редактировать подписку
const editNotificationSubscription = async ({ cabinet_id, subscription_id, data }) => {
  try {
    const response = await request({
      url: `/api/analytics/notification-subscriptions/${subscription_id}/`,
      method: "put",
      params: {
        cabinet_id,
      },
      data,
    });

    return response;
  } catch (error) {
    console.error(`Error editing subscription with ID ${subscription_id}:`, error);
    throw error;
  }
};

// Удаление подписки
const deleteNotificationSubscription = async ({ cabinet_id, subscription_id }) => {
  try {
    const response = await request({
      url: `/api/analytics/notification-subscriptions/${subscription_id}/`,
      method: "delete",
      params: { cabinet_id },
    });
    return response;
  } catch (error) {
    console.error(`Error deleting subscription with ID ${subscription_id}:`, error);
    throw error;
  }
};

// Тестирование
const testNotificationSubscription = async ({ cabinet_id, subscription_id }) => {
  const response = await request({
    url: `/api/analytics/notification-subscriptions/${subscription_id}/test/`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response.data;
};
export {
  getNotificationSubscription,
  postNotificationSubscription,
  getNotificationSubscriptionByGroup,
  editNotificationSubscription,
  deleteNotificationSubscription,
  testNotificationSubscription,
};
