import { createSlice } from "@reduxjs/toolkit";
import { prop } from "ramda";

import {
  ABCSoldCircle,
  ABCSoldDiagram,
  ABCSoldProduct,
  ABCSoldTables,
  ABCSoldWidgets,
  Getaccounts,
  GetTableEconomic,
  TreateTablesEconomic,
  TreateWidgetsEconomic,
  TredeEconomyDiagram,
} from "./action";

const economicLogicSlice = createSlice({
  name: "economic/logic",
  initialState: {
    unitTableAricle: [],
    unitTableBarcode: [],
    isShowAll: true,
    isProductCost: false,
    isGrossMargin: false,
    isRealization: false,
    grosMarrgin: "",
    grossMarrginAbc: "",
    realizationMargin: "",
    less: "",
    more: "",
    precent: 0,

    currencyName: "руб",
    tradeWidgets: {},
    isLoadingWidgets: false,
    treadeDinamic: null,
    isLoadingDiagram: false,
    treateCircle: [],
    treatMonthTable: [],
    treateSubjectTable: [],
    treateCategoryTable: [],
    treateHatchTable: [],
    treateArticleTable: [],
    treateBrandTable: [],
    treateBarcodeTable: [],
    // abcCircleFilterApplied,
    // abcCheckboxesDisabled,
    // xyzCheckboxesDisabled,
    abcFilterType: "synthetic_abc_group_label",
    abcFilterTypeABC: "A,B,C",
    abcFilterTypeXYZ: "X,Y,Z",
    abcSoldGoodWidgets: null,
    abcCircleFilterApplied: false,
    abcCheckboxesDisabled: false,
    xyzCheckboxesDisabled: false,
    abcSoldCircleArticle: [],
    xyzSoldCircleArticle: [],
    abcSoldCircleBrand: [],
    xyzSoldCircleBrand: [],
    abcSoldCircleCategory: [],
    xyzSoldCircleCategory: [],
    abcSoldCircleSubject: [],
    xyzSoldCircleSubjec: [],
    abcSoldGootPlot: null,
    abcSoldGoodProduct: null,
    abcSoldOutsiderProduct: null,
    productCost: 0,

    abcTableArticle: [],
    abcTableCategory: [],
    abcTableSubject: [],
    abcTableBrand: [],
    abcTableBarcode: [],

    quantity: 40,
    revenue: 40,
    grossMargin: 20,

    abcProccedsWidgets: null,
    abcProccedsCircleArticle: [],
    xyzProccedsCircleArticle: [],
    abcProccedsCircleBrand: [],
    xyzProccedsCircleBrand: [],
    abcProccedsCircleCategory: [],
    xyzProccedsCircleCategory: [],
    abcProccedsCircleSubject: [],
    xyzProccedsCircleSubject: [],
    abcProccedsProduct: null,
    abcProccedsDiagramPlot: null,

    abcGrossMarginWidgets: null,

    abcGrossMarginCircleArticle: [],
    xyzGrossMarginCircleArticle: [],
    abcGrossMarginCircleBrand: [],
    xyzGrossMarginCircleBrand: [],
    abcGrossMarginCircleCategory: [],
    xyzGrossMarginCircleCategory: [],
    abcGrossMarginCircleSubject: [],
    xyzGrossMarginCircleSubject: [],
    abcGrossMarginProduct: null,
    abcGrossMarginDiagramPlot: null,

    isLoadingAbcWidgets: false,
    isLoadingABCSoldCircle: false,
    isLoadingABCSoldDiagram: false,
    isLoadingABCSoldProduct: false,
  },
  reducers: {
    addKeys: (state, action) => {
      state.keys = action.payload;

      const currency = state.apiKeys.filter(item => state.keys.includes(item.id));

      let elem = currency.map(elem => elem.currency_name);

      state.currencyName = elem[elem.length - 1];
    },
    unitEconomicaFilters: (state, action) => {
      const { name } = action.payload;
      if (name === "showAll") {
        state.isProductCost = false;
        state.isGrossMargin = false;
        state.isRealization = false;
        state.isShowAll = true;

        state.grosMarrgin = "";
        state.realizationMargin = "";
        state.more = "";
        state.less = "";
      } else if (name === "productCost") {
        state.isShowAll = false;
        state.isRealization = false;
        state.isGrossMargin = false;
        state.isProductCost = true;

        state.grosMarrgin = "";
        state.realizationMargin = "";
        state.more = "";
        state.less = "";
      } else if (name === "grossMargin") {
        state.isShowAll = false;
        state.isRealization = false;
        state.isProductCost = false;
        state.isGrossMargin = true;

        state.grosMarrgin = "";
        state.realizationMargin = "";
        state.more = "";
        state.less = "";
      } else if (name === "realization") {
        state.isShowAll = false;
        state.isProductCost = false;
        state.isGrossMargin = false;
        state.isRealization = !state.isRealization;
      }
    },
    unitEconmicaSelectFilters: (state, action) => {
      const { value } = action.payload;

      if (value === "к себестоимости") {
        state.grosMarrgin = value;
        state.realizationMargin = "";
      } else if (value === "к сумме реализации") {
        state.realizationMargin = value;
        state.grosMarrgin = "";
      }
    },
    unitEconomicaSelectMoreFilters: (state, action) => {
      const { value } = action.payload;
      if (value === "Больше") {
        state.more = value;
        state.less = "";
      } else if (value === "Меньше") {
        state.less = value;
        state.more = "";
      }
    },
    unitEconomicaFiltersPrecent: (state, action) => {
      state.precent = action.payload;
    },
    clearDataTable: (state, action) => {
      const { name } = action.payload;

      if (name === "subject_id") {
        state.treateSubjectTable = [];
      } else if (name === "month") {
        state.treatMonthTable = [];
      } else if (name === "category_id") {
        state.treateCategoryTable = [];
      } else if (name === "shk_id") {
        state.treateHatchTable = [];
      } else if (name === "supplier_article") {
        state.treateArticleTable = [];
      } else if (name === "brand_id") {
        state.treateBrandTable = [];
      } else if (name === "barcode") {
        state.treateBarcodeTable = [];
      } else if (name === "all") {
        state.treateSubjectTable = [];
        state.treatMonthTable = [];
        state.treateCategoryTable = [];
        state.treateHatchTable = [];
        state.treateArticleTable = [];
        state.treateBrandTable = [];
        state.treateBarcodeTable = [];
      }
    },
    clearUnitTables: (state, action) => {
      const { name } = action.payload;

      if (name === "supplier_article") {
        state.unitTableAricle = [];
      } else if (name === "barcode") {
        state.unitTableBarcode = [];
      } else if (name === "all") {
        state.unitTableBarcode = [];
        state.unitTableAricle = [];
      }
    },
    clearTreateDate: state => {},
    clearAbcSoldGood: (state, action) => {
      const { name } = action.payload;

      if (name === "supplier_article") {
        state.abcTableArticle = [];
      } else if (name === "category_id") {
        state.abcTableCategory = [];
      } else if (name === "subject_id") {
        state.abcTableSubject = [];
      } else if (name === "brand_id") {
        state.abcTableBrand = [];
      } else if (name === "barcode") {
        state.abcTableBarcode = [];
      }
    },
    clearAllDateEconomic: state => {
      state.unitTableBarcode = [];
      state.unitTableAricle = [];
      state.tradeWidgets = {};
      (state.treatMonthTable = []),
        (state.treateSubjectTable = []),
        (state.treateCategoryTable = []),
        (state.treateHatchTable = []),
        (state.treateArticleTable = []),
        (state.treateBrandTable = []),
        (state.treateBarcodeTable = []);
    },
    clearDateAbcAnalyz: state => {
      (state.abcSoldGoodWidgets = []),
        (state.abcSoldCircleArticle = []),
        (state.xyzSoldCircleArticle = []),
        (state.abcSoldCircleBrand = []),
        (state.xyzSoldCircleBrand = []),
        (state.abcSoldCircleCategory = []),
        (state.xyzSoldCircleCategory = []),
        (state.abcSoldCircleSubject = []),
        (state.xyzSoldCircleSubjec = []),
        (state.abcSoldGootPlot = []),
        (state.abcSoldGoodProduct = []),
        (state.abcSoldOutsiderProduct = []),
        (state.abcTableArticle = []),
        (state.abcTableCategory = []),
        (state.abcTableSubject = []),
        (state.abcTableBrand = []),
        (state.abcTableBarcode = []),
        (state.abcProccedsWidgets = []),
        (state.abcProccedsCircleArticle = []),
        (state.xyzProccedsCircleArticle = []),
        (state.abcProccedsCircleBrand = []),
        (state.xyzProccedsCircleBrand = []),
        (state.abcProccedsCircleCategory = []),
        (state.xyzProccedsCircleCategory = []),
        (state.abcProccedsCircleSubject = []),
        (state.xyzProccedsCircleSubject = []),
        (state.abcProccedsProduct = []),
        (state.abcProccedsDiagramPlot = []);

      (state.abcGrossMarginCircleArticle = []),
        (state.xyzGrossMarginCircleArticle = []),
        (state.abcGrossMarginCircleBrand = []),
        (state.xyzGrossMarginCircleBrand = []),
        (state.abcGrossMarginCircleCategory = []),
        (state.xyzGrossMarginCircleCategory = []),
        (state.abcGrossMarginCircleSubject = []),
        (state.xyzGrossMarginCircleSubject = []),
        (state.abcGrossMarginProduct = []);
      state.abcGrossMarginDiagramPlot = [];
    },
    clearDateEconomicSection: state => {
      (state.abcSoldGoodWidgets = []),
        (state.abcSoldCircleArticle = []),
        (state.xyzSoldCircleArticle = []),
        (state.abcSoldCircleBrand = []),
        (state.xyzSoldCircleBrand = []),
        (state.abcSoldCircleCategory = []),
        (state.xyzSoldCircleCategory = []),
        (state.abcSoldCircleSubject = []),
        (state.xyzSoldCircleSubjec = []),
        (state.abcSoldGootPlot = []),
        (state.abcSoldGoodProduct = []),
        (state.abcSoldOutsiderProduct = []),
        (state.abcTableArticle = []),
        (state.abcTableCategory = []),
        (state.abcTableSubject = []),
        (state.abcTableBrand = []),
        (state.abcTableBarcode = []),
        (state.abcProccedsWidgets = []),
        (state.abcProccedsCircleArticle = []),
        (state.xyzProccedsCircleArticle = []),
        (state.abcProccedsCircleBrand = []),
        (state.xyzProccedsCircleBrand = []),
        (state.abcProccedsCircleCategory = []),
        (state.xyzProccedsCircleCategory = []),
        (state.abcProccedsCircleSubject = []),
        (state.xyzProccedsCircleSubject = []),
        (state.abcProccedsProduct = []),
        (state.abcProccedsDiagramPlot = []),
        (state.abcGrossMarginCircleArticle = []),
        (state.xyzGrossMarginCircleArticle = []),
        (state.abcGrossMarginCircleBrand = []),
        (state.xyzGrossMarginCircleBrand = []),
        (state.abcGrossMarginCircleCategory = []),
        (state.xyzGrossMarginCircleCategory = []),
        (state.abcGrossMarginCircleSubject = []),
        (state.xyzGrossMarginCircleSubject = []),
        (state.abcGrossMarginProduct = []),
        (state.abcGrossMarginDiagramPlot = []),
        (state.unitTableBarcode = []),
        (state.unitTableAricle = []),
        (state.tradeWidgets = {}),
        (state.treadeDinamic = null),
        (state.treatMonthTable = []),
        (state.treateSubjectTable = []),
        (state.treateCategoryTable = []),
        (state.treateHatchTable = []),
        (state.treateArticleTable = []),
        (state.treateBrandTable = []),
        (state.treateBarcodeTable = []);
    },
    clearAbcProduct: state => {
      state.abcSoldGoodProduct = [];
      state.abcSoldOutsiderProduct = [];
    },
    syntheticAbc: (state, action) => {
      const { grossMarginValue, revenueValue, quantityValue } = action.payload;

      state.abcTableArticle = [];
      state.abcTableCategory = [];
      state.abcTableSubject = [];
      state.abcTableBrand = [];
      state.abcTableBarcode = [];

      state.revenue = revenueValue;
      state.grossMarrginAbc = grossMarginValue;
      state.quantity = quantityValue;
    },
    setTreateArticleTableWithHierarchy: (state, action) => {
      state.treateArticleTable = action.payload;
    },
    setUnitTableAricleWithHierarchy: (state, action) => {
      state.unitTableAricle = action.payload;
    },
    setAbcTableArticleWithHierarchy: (state, action) => {
      state.abcTableArticle = action.payload;
    },
    setAbcTableFilterType: (state, action) => {
      state.abcFilterType = action.payload.name;
    },
    setAbcTableFilterABC: (state, action) => {
      state.abcFilterTypeABC = action.payload.name;
    },
    setAbcTableFilterXYZ: (state, action) => {
      state.abcFilterTypeXYZ = action.payload.name;
    },

    setAbcCircleFilterApplied: (state, action) => {
      state.abcCircleFilterApplied = action.payload.name;
    },
    setAbcCheckboxesDisabled: (state, action) => {
      state.abcCheckboxesDisabled = action.payload.name;
    },
    setXyzCheckboxesDisabled: (state, action) => {
      state.xyzCheckboxesDisabled = action.payload.name;
    },
  },
  extraReducers: bulider => {
    bulider
      .addCase(Getaccounts.fulfilled, (state, action) => {
        state.apiKeys = action.payload;
      })

      .addCase(GetTableEconomic.fulfilled, (state, action) => {
        const { response, group_by } = action.payload;
        if (group_by === "supplier_article") {
          state.unitTableAricle = response.results;
          state.unitCountArticle = response.count;
        } else if (group_by === "barcode") {
          state.unitTableBarcode = response.results;
          state.unitCountBarcode = response.count;
        }
      })

      .addCase(TreateWidgetsEconomic.pending, state => {
        state.isLoadingWidgets = true;
      })
      .addCase(TreateWidgetsEconomic.fulfilled, (state, action) => {
        state.tradeWidgets = action.payload;
        state.productCost = prop("product_cost", prop("current", state.tradeWidgets));
        const sum =
          prop("gross_margin", prop("current", state.tradeWidgets)) +
          prop("product_cost", prop("current", state.tradeWidgets)) +
          prop("preparation_cost", prop("current", state.tradeWidgets)) +
          prop("wb_service", prop("current", state.tradeWidgets));
        state.treateCircle = [
          {
            office_name: "Валовая маржа",
            value: prop("gross_margin", prop("current", state.tradeWidgets)) || 0,
            precent: (prop("gross_margin", prop("current", state.tradeWidgets)) / sum) * 100,
            isNull: prop("gross_margin", prop("current", state.tradeWidgets)) === null,
          },
          {
            office_name: "Себестоимость",
            value: prop("product_cost", prop("current", state.tradeWidgets)) || 0,
            precent: (prop("product_cost", prop("current", state.tradeWidgets)) / sum) * 100,
            isNull: prop("product_cost", prop("current", state.tradeWidgets)) === null,
          },
          {
            office_name: "Предподготовка",
            value: prop("preparation_cost", prop("current", state.tradeWidgets)) || 0,
            precent: (prop("preparation_cost", prop("current", state.tradeWidgets)) / sum) * 100,
            isNull: prop("preparation_cost", prop("current", state.tradeWidgets)) === null,
          },
          {
            office_name: "Комиссия WB",
            value: prop("commission", prop("current", state.tradeWidgets)) || 0,
            precent: (prop("commission", prop("current", state.tradeWidgets)) / sum) * 100,
            isNull: prop("commission", prop("current", state.tradeWidgets)) === null,
          },
          {
            office_name: "Услуги WB",
            value: prop("wb_service", prop("current", state.tradeWidgets)) || 0,
            precent: (prop("wb_service", prop("current", state.tradeWidgets)) / sum) * 100,
            isNull: prop("wb_service", prop("current", state.tradeWidgets)) === null,
          },
        ];
        state.isLoadingWidgets = false;
      })

      .addCase(TreateWidgetsEconomic.rejected, state => {
        state.isLoadingWidgets = true;
      })

      .addCase(TredeEconomyDiagram.pending, state => {
        state.isLoadingDiagram = true;
      })

      .addCase(TredeEconomyDiagram.fulfilled, (state, action) => {
        state.treadeDinamic = action.payload;
        state.isLoadingDiagram = false;
      })
      .addCase(TredeEconomyDiagram.rejected, state => {
        state.isLoadingDiagram = false;
        state.treadeDinamic = [];
      })
      .addCase(TreateTablesEconomic.fulfilled, (state, action) => {
        const { group_by, response } = action.payload;
        if (group_by === "month") {
          state.treatMonthTable = response.results;
          state.monthCount = response.count;
        } else if (group_by === "subject_id") {
          state.treateSubjectTable = response.results;
          state.subjectCount = response.count;
        } else if (group_by === "category_id") {
          state.treateCategoryTable = response.results;
          state.categoryCount = response.count;
        } else if (group_by === "shk_id") {
          state.treateHatchTable = response.results;
          state.hatchCount = response.count;
        } else if (group_by === "supplier_article") {
          state.treateArticleTable = response.results;
          state.articleCount = response.count;
        } else if (group_by === "brand_id") {
          state.treateBrandTable = response.results;
          state.brandCount = response.count;
        } else if (group_by === "barcode") {
          state.treateBarcodeTable = response.results;
          state.barcodeCount = response.count;
        }
      })
      .addCase(ABCSoldWidgets.pending, (state, action) => {
        state.isLoadingAbcWidgets = true;
      })
      .addCase(ABCSoldWidgets.fulfilled, (state, action) => {
        const { analyze_by, response } = action.payload;
        const { abc, xyz } = response;
        if (analyze_by === "realization_quantity") {
          state.abcSoldGoodWidgets = abc.groups.map(elem => {
            return {
              label: elem.label,
              value: elem.realization_quantity,
              quantity: elem.quantity,
              proceeds: elem.proceeds,
            };
          });
        } else if (analyze_by === "proceeds") {
          const { abc } = response;
          state.abcProccedsWidgets = abc.groups.map(elem => {
            return {
              label: elem.label,
              value: elem.realization_quantity,
              quantity: elem.quantity,
              proceeds: elem.proceeds,
            };
          });
        } else if (analyze_by === "gross_margin") {
          const { abc } = response;
          state.abcGrossMarginWidgets = abc.groups.map(elem => {
            return {
              label: elem.label,
              value: elem.realization_quantity,
              quantity: elem.quantity,
              proceeds: elem.gross_margin,
            };
          });
        }
        state.isLoadingAbcWidgets = false;
      })
      .addCase(ABCSoldWidgets.rejected, (state, action) => {
        state.isLoadingAbcWidgets = false;
        state.abcGrossMarginWidgets = [];
        state.abcSoldGoodWidgets = [];
        state.abcProccedsWidgets = [];
      })
      .addCase(ABCSoldCircle.pending, (state, action) => {
        state.isLoadingABCSoldCircle = true;
      })
      .addCase(ABCSoldCircle.fulfilled, (state, action) => {
        const { analyze_by, response, group_by } = action.payload;
        if (analyze_by === "realization_quantity") {
          if (group_by === "supplier_article") {
            const { abc, xyz } = response;

            state.abcSoldCircleArticle = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                realization_quantity: elem.realization_quantity,
                label: elem.label,
              };
            });
            state.xyzSoldCircleArticle = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "brand_id") {
            const { abc, xyz } = response;

            state.abcSoldCircleBrand = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                realization_quantity: elem.realization_quantity,
                label: elem.label,
              };
            });
            state.xyzSoldCircleBrand = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "category_id") {
            const { abc, xyz } = response;

            state.abcSoldCircleCategory = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                realization_quantity: elem.realization_quantity,
                label: elem.label,
              };
            });
            state.xyzSoldCircleCategory = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "subject_id") {
            const { abc, xyz } = response;

            state.abcSoldCircleSubject = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                realization_quantity: elem.realization_quantity,
                label: elem.label,
              };
            });
            state.xyzSoldCircleSubjec = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          }
        } else if (analyze_by === "proceeds") {
          if (group_by === "supplier_article") {
            const { abc, xyz } = response;

            state.abcProccedsCircleArticle = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                proceeds: elem.proceeds,
                label: elem.label,
              };
            });
            state.xyzProccedsCircleArticle = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "brand_id") {
            const { abc, xyz } = response;

            state.abcProccedsCircleBrand = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                proceeds: elem.proceeds,
                label: elem.label,
              };
            });
            state.xyzProccedsCircleBrand = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "subject_id") {
            const { abc, xyz } = response;

            state.abcProccedsCircleSubject = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                proceeds: elem.proceeds,
                label: elem.label,
              };
            });
            state.xyzProccedsCircleSubject = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "category_id") {
            const { abc, xyz } = response;

            state.abcProccedsCircleCategory = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                proceeds: elem.proceeds,
                label: elem.label,
              };
            });
            state.xyzProccedsCircleCategory = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          }
        } else if (analyze_by === "gross_margin") {
          if (group_by === "supplier_article") {
            const { abc, xyz } = response;
            state.abcGrossMarginCircleArticle = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                gross_margin: elem.gross_margin,
                label: elem.label,
              };
            });
            state.xyzGrossMarginCircleArticle = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "brand_id") {
            const { abc, xyz } = response;

            state.abcGrossMarginCircleBrand = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                gross_margin: elem.gross_margin,
                label: elem.label,
              };
            });
            state.xyzGrossMarginCircleBrand = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "subject_id") {
            const { abc, xyz } = response;

            state.abcGrossMarginCircleSubject = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                gross_margin: elem.gross_margin,
                label: elem.label,
              };
            });
            state.xyzGrossMarginCircleSubject = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          } else if (group_by === "category_id") {
            const { abc, xyz } = response;

            state.abcGrossMarginCircleCategory = abc.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                gross_margin: elem.gross_margin,
                label: elem.label,
              };
            });
            state.xyzGrossMarginCircleCategory = xyz.groups.map(elem => {
              return {
                quantity: elem.quantity,
                quantity_share: elem.quantity_share,
                label: elem.label,
              };
            });
          }
        }
        state.isLoadingABCSoldCircle = false;
      })
      .addCase(ABCSoldCircle.rejected, (state, action) => {
        state.isLoadingABCSoldCircle = false;
      })
      .addCase(ABCSoldDiagram.pending, (state, action) => {
        state.isLoadingABCSoldDiagram = true;
      })
      .addCase(ABCSoldDiagram.fulfilled, (state, action) => {
        if (action.payload) {
          const { analyze_by, response } = action.payload;
          const { group_end_indexes, plot } = response;
          if (analyze_by === "realization_quantity") {
            if (plot) {
              state.abcSoldGootPlot = Object.keys(plot).map(key => ({
                valueA: plot[key],
                valueB: group_end_indexes[key] ? group_end_indexes[key].index : null,
                label: group_end_indexes[key] ? group_end_indexes[key].label : null,
              }));
            }
          } else if (analyze_by === "proceeds") {
            if (plot) {
              state.abcProccedsDiagramPlot = Object.keys(plot).map(key => ({
                valueA: plot[key],
                valueB: group_end_indexes[key] ? group_end_indexes[key].index : null,
                label: group_end_indexes[key] ? group_end_indexes[key].label : null,
              }));
            }
          } else if (analyze_by === "gross_margin") {
            if (plot) {
              state.abcGrossMarginDiagramPlot = Object.keys(plot).map(key => ({
                valueA: plot[key],
                valueB: group_end_indexes[key] ? group_end_indexes[key].index : null,
                label: group_end_indexes[key] ? group_end_indexes[key].label : null,
              }));
            }
          }
        }
        state.isLoadingABCSoldDiagram = false;
      })
      .addCase(ABCSoldDiagram.rejected, (state, action) => {
        state.isLoadingABCSoldDiagram = false;
        state.abcGrossMarginDiagramPlot = [];
        state.abcSoldGootPlot = [];
        state.abcProccedsDiagramPlot = [];
      })
      .addCase(ABCSoldProduct.pending, (state, action) => {
        state.isLoadingABCSoldProduct = true;
      })
      .addCase(ABCSoldProduct.fulfilled, (state, action) => {
        const { analyze_by, response, reverse } = action.payload;
        if (analyze_by === "realization_quantity") {
          if (reverse === 0) state.abcSoldGoodProduct = response;
          if (reverse === 1) state.abcSoldOutsiderProduct = response;
        } else if (analyze_by === "proceeds") {
          state.abcProccedsProduct = response;
        } else if (analyze_by === "gross_margin") {
          state.abcGrossMarginProduct = response;
        }
        state.isLoadingABCSoldProduct = false;
      })
      .addCase(ABCSoldProduct.rejected, (state, action) => {
        state.isLoadingABCSoldProduct = false;
        state.abcGrossMarginProduct = [];
        state.abcProccedsProduct = [];
        state.abcSoldGoodProduct = [];
        state.abcSoldOutsiderProduct = [];
      })

      .addCase(ABCSoldTables.fulfilled, (state, action) => {
        if (action.payload) {
          const { response, group_by } = action.payload;
          if (group_by === "supplier_article") {
            state.abcTableArticle = response.results;
            state.abcSoldGoodTableArticleCount = response.count;
          } else if (group_by === "category_id") {
            state.abcTableCategory = response.results;
            state.abcCategoryCount = response.count;
          } else if (group_by === "subject_id") {
            state.abcTableSubject = response.results;
            state.abcSubjectCount = response.count;
          } else if (group_by === "brand_id") {
            state.abcTableBrand = response.results;
            state.abcBrandCount = response.count;
          } else if (group_by === "barcode") {
            state.abcTableBarcode = response.results;
            state.abcBarcodeCount = response.count;
          }
        }
      });
  },
});
export const {
  addKeys,
  clearDataTable,
  clearDateAbcAnalyz,
  clearUnitTables,
  clearTreateDate,
  clearAllDateEconomic,
  unitEconomicaFilters,
  unitEconmicaSelectFilters,
  unitEconomicaFiltersPrecent,
  unitEconomicaSelectMoreFilters,
  clearAbcSoldGood,
  clearAbcProduct,
  syntheticAbc,
  clearDateEconomicSection,
  setTreateArticleTableWithHierarchy,
  setUnitTableAricleWithHierarchy,
  setAbcTableArticleWithHierarchy,
  setAbcTableFilterType,
  setAbcTableFilterABC,
  setAbcTableFilterXYZ,
  setAbcCircleFilterApplied,
  setAbcCheckboxesDisabled,
  setXyzCheckboxesDisabled,
} = economicLogicSlice.actions;
export const economicLogicSliceReducer = economicLogicSlice.reducer;
