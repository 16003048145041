import React from "react"

import ClearIcon from "@mui/icons-material/Clear"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import classnames from "classnames"


import styles from "./index.module.scss"

const Dialog_ = props => {
	const {
		onClose,
		padding = true,
		classes,
		children,
		className,
		...rest
	} = props

	return (
		<Dialog
			classes={{
				paper: classnames(styles.paper, className),
				root: styles.root,
				...classes,
			}}
			maxWidth="md"
			onClose={onClose}
			{...rest}
		>
			<div
				className={styles.close}
				onClick={onClose}
			>
				<ClearIcon />
			</div>
			{padding ? <Box p={2.5}>{children}</Box> : children}
		</Dialog>
	)
}

export default Dialog_
