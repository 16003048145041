import { createSlice } from "@reduxjs/toolkit";
import { prop } from "ramda";

import { CategorySalesPlan, GetCompanyWBCabinets, GetEvents, GetGlobalEvents, GetTMCSettings, SalesPlanCompany, SalesPlanSettings } from "./action";

const initialState = {
  apiKeyName: [],
  cabinetId: 0,
  errorMessage: "",
  salesPlanSettings: {},
  salesPlanTable: [],
  salesMounths: [],
  checkedMounths: [],
  categories: [],
  marketplace: ["Wildberries"],
  enter: false,
  supply: false,
  barcode: false,
  article: false,
  code: false,
  arrival_document_type: "enter",
  item_identifier: "barcode",
  TMCSettings: '',
  eventList: [],
  globalEventList: []
};

const settingsDebriefingSlice = createSlice({
  name: "settings/debriefing",
  initialState,
  reducers: {
    getCabinetId: (state, action) => {
      const { id } = action.payload;

      state.cabinetId = id;
    },
    errorMessages: (state, action) => {
      state.errorMessage = action.payload;
    },
    edidChangeSalesCompany: (state, action) => {
      const { value, id, name } = action.payload;

      const rowToUpdate = state.salesPlanTable.find(row => row.id === id);
      if (rowToUpdate) {
        rowToUpdate[name] = value;
      }
    },
    salesPlanMounths: (state, action) => {

      function getRussianMonthName(monthIndex) {
        const russianMonthNames = [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь"
        ];

        return russianMonthNames[monthIndex];
      }

      let month = []
      let currentDate = new Date();
      let currentMonth = currentDate.getMonth();
      let currentYear = currentDate.getFullYear();

      for (let i = 0; i < 12; i++) {
        month.push(`${getRussianMonthName(currentMonth)} ${currentYear}`);

        currentMonth = (currentMonth + 1) % 12;
        if (currentMonth === 0) {
          currentYear++;
        }
      }

      state.salesMounths = month.map((elem) => {
        if (elem) {
          return {
            month: elem
          }
        }
      })
    },



    checkedMonth: state => {
      const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ];

      state.checkedMounths = state.salesPlanTable.map(elem => {
        const mounth = elem.month;
        const [year, month] = mounth.split("-");
        const russianMonth = months[parseInt(month, 10) - 1];

        return `${russianMonth} ${year}`;
      });
    },
    onChangeBussinesStock: (state, action) => {
      const { name } = action.payload;
      if (name === "enter") {
        state.enter = true;
        state.supply = false;
      }
      else if (name === "supply") {
        state.supply = true;
        state.enter = false;
      }

      state.arrival_document_type = name;
    },
    onChangeProductIdentification: (state, action) => {
      const { name } = action.payload
      if (name === "barcode") {
        state.barcode = true;
        state.article = false;
        state.code = false;
      } else if (name === "article") {
        state.article = true;
        state.barcode = false;
        state.code = false;
      } else if (name === "code") {
        state.code = true;
        state.article = false;
        state.barcode = false;
      }

      state.item_identifier = name;
    },
    setGoogleSheetsRunId: (state, action) => {
      const data = state.apiKeyName.map(el => {
        if (el.id === action.payload.id) {
          return { ...el, run_id: action.payload.run_id }
        } else {
          return el
        }
      })
      state.apiKeyName = data
    },
    setGoogleSheetsMessage: (state, action) => {
      const data = state.apiKeyName.map(el => {
        if (el.id === action.payload.id) {
          return { ...el, message: action.payload.message }
        } else {
          return el
        }
      })
      state.apiKeyName = data
    },
    setGoogleSheetsLink: (state, action) => {
      const data = state.apiKeyName.map(el => {
        if (el.id === action.payload.id) {
          return { ...el, googleSheetsLink: action.payload.link }
        } else {
          return el
        }
      })
      state.apiKeyName = data
    }
  },
  extraReducers: bulider => {
    bulider
      .addCase(GetCompanyWBCabinets.fulfilled, (state, action) => {
        state.apiKeyName = action.payload;
      })
      .addCase(SalesPlanSettings.fulfilled, (state, action) => {
        state.salesPlanSettings = {
          income_plan_rapid: prop("income_plan_rapid", action.payload),
          income_plan_soon: prop("income_plan_soon", action.payload),

          income_plan_ok: prop("income_plan_ok", action.payload)
        };
      })
      .addCase(SalesPlanCompany.fulfilled, (state, action) => {
        // state.salesPlanTable = action.payload.results;
        // state.salesPlanCount = action.payload.count;
        state.salesPlanTable = action.payload;
      })
      .addCase(CategorySalesPlan.fulfilled, (state, action) => {
        // state.categories = action.payload.map((elem)=>elem.name)
        state.categories = action.payload
      })
      .addCase(GetTMCSettings.fulfilled, (state, action) => {
        state.TMCSettings = action.payload
      })
      .addCase(GetEvents.fulfilled, (state, action) => {
        state.eventList = action.payload
      })
      .addCase(GetGlobalEvents.fulfilled, (state, action) => {
        state.globalEventList = action.payload
      })
  }
});

export const {
  getCabinetId,
  errorMessages,
  edidChangeSalesCompany,
  salesPlanMounths,
  checkedMonth,
  onChangeBussinesStock,
  onChangeProductIdentification,
  setGoogleSheetsRunId,
  setGoogleSheetsMessage,
  setGoogleSheetsLink
} = settingsDebriefingSlice.actions;
export const settingsDebriefingReducer = settingsDebriefingSlice.reducer;
