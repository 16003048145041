import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<rect
			x="3.75"
			y="3.75"
			width="16.5"
			height="16.5"
			rx="1.25"
			stroke={props.stroke}
			strokeWidth="1.5"
			strokeLinejoin="round"
			strokeDasharray="2 2"
			fill="white"
		/>
	</Template>
))
