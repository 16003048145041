import request from "root/js/request";

export const getPromotionsProducts = async ({ promotion_id, cabinet_id }) => {
  const response = await request({
    url: `/analytics/promotion/${promotion_id}/promotion_product/?cabinet_id=${cabinet_id}`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response;
};
