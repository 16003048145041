const loadStatuses = {
  fulfilled: "fulfilled",
  pending: "pending",
  rejected: "rejected"
};

const axisColors = {
  reviews_points: "#0088c2"
}

export { loadStatuses, axisColors };
