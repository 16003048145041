import columnsJSON from "./columns.json"
import customChips from "./custom-chips.js"
import {numberWithSpaces, pluralize} from "./utils"

const formatTitle = (str, postfix) => {
	if (typeof str !== "string") {
		return str
	}
	const spaces = str.match(/ /g)?.length
	if (!spaces || spaces < 2) return `${str}${postfix ? `, ${postfix}` : ""}`
	if (spaces === 2) return `${str.replace(/ /, " ")}${postfix ? `, ${postfix}` : ""}`
	if (spaces > 2) {
		const divideOn = Math.floor(spaces / 2)
		let result = ""
		let spaceCounter = 0
		str.split("").forEach(symbol => {
			if (symbol === " ") {
				result += spaceCounter === divideOn ? symbol : " "
				spaceCounter++
			} else {
				result += symbol
			}
		})
		return `${result}${postfix ? `, ${postfix}` : ""}`
	}
}

const isEmptyValue = value => [null, undefined, ""].includes(value)
const emptyValuePlaceholder = "—"

class Column {
	constructor(columnData_, hintFor) {
		const columnData = {...columnData_}
		const isComplexPostfix = typeof columnData_.postfix === "object"

		columnData.rawPostfix = isComplexPostfix
			? columnData_.postfix.indefinite
			: columnData_.postfix
		columnData.postfix = columnData.rawPostfix.replace(" ", "")
		columnData.renderPostfix = value =>
			(isComplexPostfix
				? ` ${pluralize(
						value,
						columnData_.postfix.singular,
						columnData_.postfix.plural,
						columnData_.postfix.accusative
				  )}`
				: columnData_.postfix
			).replace(" ", " ")
		columnData.title = formatTitle(columnData_.title, columnData.postfix)
		columnData.rawTitle = columnData_.title
		columnData.dataIndex = columnData.key = columnData_.key || columnData_.dataIndex
		columnData.params = [
			`${columnData_.key}__gt`,
			`${columnData_.key}__gte`,
			`${columnData_.key}__lt`,
			`${columnData_.key}__lte`,
		]
		columnData.hint = hintFor ? columnData.hint[hintFor] : null
		columnData.getMinMaxChip = ({min, max, direction}) => {
			if (direction === "down" && min < 0) {
				return `${columnData.rawTitle.replace(
					/^Изменение/i,
					{up: "Рост", down: "Падение"}[direction]
				)} ${
					min !== undefined
						? `от ${min}${columnData.renderPostfix(direction === "down" ? max : min)}`
						: ""
				} ${
					max !== undefined
						? `до ${max}${columnData.renderPostfix(direction === "down" ? min : max)}`
						: ""
				}`.trim()
			}
			return `${columnData.rawTitle.replace(
				/^Изменение/i,
				{up: "Рост", down: "Падение"}[direction]
			)} ${
				min !== undefined
					? `от ${direction === "down" ? -max : min}${columnData.renderPostfix(
							direction === "down" ? max : min
					  )}`
					: ""
			} ${
				max !== undefined
					? `до ${direction === "down" ? -min : max}${columnData.renderPostfix(
							direction === "down" ? min : max
					  )}`
					: ""
			}`.trim()
		}
		columnData.render = columnData_.render
			? value =>
					isEmptyValue(columnData_.render(value, columnData))
						? emptyValuePlaceholder
						: columnData_.render(value, columnData)
			: value => {
					const typeOfValue = typeof value
					if (isEmptyValue(value)) {
						return emptyValuePlaceholder
					} else if (typeOfValue === "number") {
						return `${numberWithSpaces(Math.round(value))}${columnData.renderPostfix(
							value
						)}`
					} /* if (typeOfValue === "string")*/ else {
						return value
					}
			  }

		Object.assign(this, columnData)
	}
}

class Columns {
	constructor(params = {}) {
		this.hintFor = params.hintFor
	}

	getColumnsByKeys(keys) {
		return keys.map((key, index) => {
			const isSmUp = window.innerWidth > 600
			if (typeof key === "object") {
				const modifiedColumn = key
				try {
					return new Column(
						Object.assign(
							isSmUp
								? {
										sticky: index === 0,
										...columnsJSON.find(item =>
											[modifiedColumn.key, modifiedColumn.dataIndex].includes(
												item.key
											)
										),
								  }
								: {
										...columnsJSON.find(item =>
											[modifiedColumn.key, modifiedColumn.dataIndex].includes(
												item.key
											)
										),
										sticky: false,
								  },
							modifiedColumn
						),
						this.hintFor
					)
				} catch (err) {
					return console.log(key, err)
				}
			} else {
				return isSmUp
					? {
							sticky: index === 0,
							...this.getColumnByKey(key),
					  }
					: {
							...this.getColumnByKey(key),
							sticky: false,
					  }
			}
		})
	}

	getColumnByKey(key) {
		const found = columnsJSON.find(item => item.key === key)
		if (!found) throw new Error(`Can't find column with key "${key}"`)
		return new Column(found, this.hintFor)
	}

	getColumnTitleByKey(key) {
		return this.getColumnByKey(key).title
	}

	exist(key) {
		return Boolean(columnsJSON.find(item => item.key === key))
	}

	getFilterChips(params) {
		const paramPairs = {}
		for (const key in params) {
			const ltegteRegex = /(__lte|__gte|__lt|__gt|__direction)$/g
			if (ltegteRegex.test(key)) {
				const pureKey = key.replace(ltegteRegex, "")
				!paramPairs[pureKey] &&
					(paramPairs[pureKey] = {
						min: params[`${pureKey}__gte`],
						max: params[`${pureKey}__lte`],
						direction: params[`${pureKey}__direction`],
					})

				if (paramPairs[pureKey].max < 0) {
					const max = paramPairs[pureKey].max
					const min = paramPairs[pureKey].min
					paramPairs[pureKey].max = min
					paramPairs[pureKey].min = max
				}
			} else if (["date_from", "date_to"].includes(key)) {
				!paramPairs.date && (paramPairs.date = {min: null, max: null})
				paramPairs.date[
					{
						date_from: "min",
						date_to: "max",
					}[key]
				] = params[key]
			} else {
				paramPairs[key] = params[key]
			}
		}

		const chips = []
		for (const key in paramPairs) {
			if (paramPairs[key].direction && !paramPairs[key].min && !paramPairs[key].max) continue
			const customChipFound = customChips.find(item => item.key === key)

			if (customChipFound && customChipFound.isValid(paramPairs[key])) {
				const label = customChipFound.label(paramPairs[key])

				Array.isArray(label) ? chips.push(...label) : chips.push(label)
			} else {
				try {
					this.exist(key) &&
						chips.push(this.getColumnByKey(key).getMinMaxChip(paramPairs[key]))
					// console.log("---", key, paramPairs[key])
				} catch (err) {
					console.log(err)
				}
			}
		}

		return chips.map(item => item.toUpperCase())
	}
}

export {Columns, Column}
