import React from "react"

const Square = () => {
	return(
		<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		fill="currentColor"
		className="bi bi-app"
		viewBox="0 0 16 16"
		style={{
			color: "#FFA500",
			backgroundColor: "#FFA500",
			width: "10px",
			height: "10px",
		}}
	>
		<path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z" />
	</svg>
	)

}


export default Square
