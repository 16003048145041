import request from "root/js/request";

export const getSharesTable = async ({
  // Поисковые параметры
  supplier_article__icontains,
  product_id__icontains,

  // Параметры с in/out суффиксами и gte/lte
  gross_margin_in__gte,
  gross_margin_in__lte,
  gross_margin_out__gte,
  gross_margin_out__lte,

  marginality_in__gte,
  marginality_in__lte,
  marginality_out__gte,
  marginality_out__lte,

  pred_quantity_in__gte,
  pred_quantity_in__lte,
  pred_quantity_out__gte,
  pred_quantity_out__lte,

  days_of_stock_in__gte,
  days_of_stock_in__lte,
  days_of_stock_out__gte,
  days_of_stock_out__lte,

  sales_amount_in__gte,
  sales_amount_in__lte,
  sales_amount_out__gte,
  sales_amount_out__lte,

  // Параметры с gte/lte
  turnover__gte,
  turnover__lte,
  current_retail_price__gte,
  current_retail_price__lte,
  date_on_site__gte,
  date_on_site__lte,
  cost__gte,
  cost__lte,

  // Параметры с in
  synthetic_abc_group_label__in,
  proceeds_group_label__in,
  subject_id__in,
  product_id__in,
  category_id__in,

  // Булевы параметры
  in_shares,
  is_level,

  // Новые параметры stocks
  stocks_loss_in,
  stocks_loss_out,
  stocks_end_in,
  stocks_end_out,

  // ID параметры
  cabinet_id,
  shares_id,
}) => {
  return request({
    url: "/analytics/shares_table/",
    method: "get",
    params: {
      supplier_article__icontains,
      product_id__icontains,

      gross_margin_in__gte,
      gross_margin_in__lte,
      gross_margin_out__gte,
      gross_margin_out__lte,

      marginality_in__gte,
      marginality_in__lte,
      marginality_out__gte,
      marginality_out__lte,

      pred_quantity_in__gte,
      pred_quantity_in__lte,
      pred_quantity_out__gte,
      pred_quantity_out__lte,

      days_of_stock_in__gte,
      days_of_stock_in__lte,
      days_of_stock_out__gte,
      days_of_stock_out__lte,

      sales_amount_in__gte,
      sales_amount_in__lte,
      sales_amount_out__gte,
      sales_amount_out__lte,

      turnover__gte,
      turnover__lte,
      current_retail_price__gte,
      current_retail_price__lte,
      date_on_site__gte,
      date_on_site__lte,
      cost__gte,
      cost__lte,

      synthetic_abc_group_label__in,
      proceeds_group_label__in,
      subject_id__in,
      product_id__in,
      category_id__in,

      in_shares,
      is_level,
      stocks_loss_in,
      stocks_loss_out,
      stocks_end_in,
      stocks_end_out,

      cabinet_id,
      shares_id,
    },
  });
};
