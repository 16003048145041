import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./index.module.scss";

export const NoDataMassage = ({ title, height = 200, sx }) => {
  return (
    <Box className={styles.hiddenMessage} height={height} >
      <Box className={styles.boxMessage} sx={sx}>
        <Typography>{title || "Нет данных для заданных фильтров"}</Typography>
      </Box>
    </Box>
  );
};
