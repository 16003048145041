import React from "react"

import CallMadeIcon from "@mui/icons-material/CallMade"
import classnames from "classnames"


import styles from "./index.module.scss"

const LinkArrow = props => {
	const {className, classes = {}, ...rest} = props

	return (
		<div
			className={classnames(styles.root, className, classes.root)}
			{...rest}
		>
			<CallMadeIcon className={styles.icon} />
		</div>
	)
}

export default LinkArrow
