import React from "react";

import { useHistory } from "react-router-dom";

import VideoDialog from ".";

// Компонент находит данные по нужному видео по адресу страницы, на
// которой находится
// --------------------------------------------------------------------
const VideoDialogAuto = () => {
  const history = useHistory();
  const dataKey = history.location.pathname.slice(1);
  const videoProps = data[dataKey];
  if (!videoProps) return null;
  return <VideoDialog {...videoProps} />;
};

// В этой переменной описаны все страницы где используется компонент
// Добавляйте ОБЯЗАТЕЛЬНО в такое же порядке, как в меню
// или в раздел others в алфавитном порядке
// --------------------------------------------------------------------
const data = {
  // -------------- PAGES
  // -------------------------------- SECTION // АНАЛИЗ КОНКУРЕНТОВ
  // Анализ по предметам
  ["analysis-by-subject"]: {
    videoIds: {
      youtube: "x7wUC3pHfv8",
      vimeo: "1009771357",
      vk: "205972442_456239114",
    },
    title: "Анализ по предметам",
  },
  // Анализ характеристик
  ["analysis-by-characteristics"]: {
    videoIds: {
      youtube: "5rAOQCZpnFE",
      vimeo: "1009775886",
      vk: "205972442_456239115",
    },
    title: "Анализ характеристик",
  },
  // Сравнительный анализ
  ["articles-comparison"]: {
    videoIds: {
      youtube: "k1R8JBoNo7Q",
      vimeo: "1009783937",
      vk: "205972442_456239116",
    },
    title: "Сравнительный анализ",
  },
  // -------------------------------- SECTION // ЛИДЕРЫ РОСТА
  // Лидеры роста  по товарам
  ["growth_leaders/products"]: {
    videoIds: {
      vimeo: "1017463787",
      youtube: "pWo1iKSVSpI",
      vk: "205972442_456239138",
    },
    title: "Лидеры роста  по товарам",
  },
  // Лидеры роста по брендам
  ["growth_leaders/brands"]: {
    videoIds: {
      vimeo: "1017466625",
      youtube: "bKELxBQg5vM",
      vk: "205972442_456239139",
    },
    title: "Лидеры роста по брендам",
  },
  // Лидеры роста по поставщикам
  ["growth_leaders/sellers"]: {
    videoIds: {
      vimeo: "1017468616",
      youtube: "_dyM_P_rY34",
      vk: "205972442_456239140",
    },
    title: "Лидеры роста по поставщикам",
  },
  // Лидеры роста по категориям
  ["growth_leaders/categories"]: {
    videoIds: {
      vimeo: "1017470470",
      youtube: "CpSZRo2WDrE",
      vk: "205972442_456239141",
    },
    title: "Лидеры роста по категориям",
  },
  // -------------------------------- SECTION // НИШИ И ТРЕНДЫ
  // Трендовые запросы
  ["trend-requests-old"]: {
    videoIds: {
      vimeo: "1000743213",
      youtube: "kd-g7PMhq2M",
      vk: "205972442_456239079",
    },
    title: "Анализ трендовых запросов",
  },
  // Оракул запросов
  ["keywords-summary-v2"]: {
    videoIds: {
      vimeo: "1026538933",
      youtube: "5ylpnlZKACQ",
      vk: "205972442_456239143",
    },
    title: "Оракул запросов",
  },
  // По товарам
  ["product-analyzer"]: {
    videoIds: {
      vimeo: "1001211080",
      youtube: "wXKsVKPIrkg",
      vk: "205972442_456239091",
    },
    title: "Ниши и тренды по товарам",
  },
  // По запросам
  ["request-analyzer"]: {
    videoIds: {
      vimeo: "1009786210",
      youtube: "0cxyWkf0RcM",
      vk: "205972442_456239117",
    },
    title: "Ниши и тренды по запросам",
  },
  // -------------------------------- SECTION // АНАЛИЗ ВНЕШКИ
  // Поиск трафика другие МП
  ["all-bloggers-search"]: {
    videoIds: {
      vk: "205972442_456239129",
      vimeo: "1009893208",
      youtube: "PS89q9ku1VM",
    },
    title: "Поиск трафика другие МП",
  },
  // Отчёты по внешке
  ["bloggers-data"]: {
    videoIds: {
      vk: "205972442_456239118",
      vimeo: "1009789749",
      youtube: "4TkJ4LrUu48",
    },
    title: "Отчёты по внешке",
  },
  // Подбор публикаций
  ["bloggers-posts-selection"]: {
    videoIds: {
      vk: "205972442_456239131",
      vimeo: "1015747480",
      youtube: "p0QEZykne-Y",
    },
    title: "Подбор публикаций",
  },
  // Поиск блогеров
  ["bloggers-profiles-selection"]: {
    videoIds: {
      vk: "205972442_456239132",
      vimeo: "1015774707",
      youtube: "YFdA3sCRQDQ",
    },
    title: "Поиск блогеров",
  },
  // Анализ блогеров
  ["blogger-card"]: {
    videoIds: {
      vk: "205972442_456239133",
      vimeo: "1015787943",
      youtube: "n-OLq8MeBas",
    },
    title: "Анализ блогеров",
  },
  // -------------------------------- SECTION // ПРОДВИЖЕНИЕ
  // Реклама в поиске
  ["advertising-optimization"]: {
    videoIds: {
      vk: "205972442_456239119",
      vimeo: "1009794961",
      youtube: "w5Z8PljrVPE",
    },
    title: "Реклама в поиске",
  },
  // Реклама в каталоге
  ["category-optimization"]: {
    videoIds: {
      vk: "205972442_456239120",
      vimeo: "1009797102",
      youtube: "B70RJPDbh30",
    },
    title: "Реклама в поиске",
  },
  // Рекомендации
  ["top-find"]: {
    videoIds: {
      vk: "205972442_456239121",
      vimeo: "1009799392",
      youtube: "Z0v5hOlLN6o",
    },
    title: "Рекомендации",
  },
  // Проверка кластера
  ["clusterer"]: {
    videoIds: {
      vk: "205972442_456239122",
      vimeo: "1009802867",
      youtube: "D2mslkv1pqo",
    },
    title: "Проверка кластера",
  },
  // -------------------------------- SECTION // SEO
  // SEO-тексты
  ["chat-gpt"]: {
    videoIds: {
      vk: "205972442_456239124",
      vimeo: "1009807195",
      youtube: "XFaNZkwXLbI",
    },
    title: "SEO-тексты",
  },
  // Индексация запросов
  ["keywords-position"]: {
    videoIds: {
      vk: "205972442_456239142",
      vimeo: "1019793578",
      youtube: "mH5zKXGM1Lk",
    },
    title: "Индексация запросов",
  },
  // Пылесос запросов
  ["sellers-keys"]: {
    videoIds: {
      vimeo: "1001563994",
      youtube: "zrE985hbfpo",
      vk: "205972442_456239103",
    },
    title: "Пылесос запросов",
  },
  // Генератор UTM-меток
  ["utm-links"]: {
    videoIds: {
      vk: "205972442_456239123",
      vimeo: "1009805287",
      youtube: "MFJ5DKKkfo4",
    },
    title: "Генератор UTM-меток",
  },
  // -------------------------------- SECTION // ВНУТРЕННЯЯ АНАЛИТИКА
  // Внутренняя аналитика
  ["decoding-detail"]: {
    videoIds: {
      vimeo: "1001233122",
      youtube: "yZGBrbxAXc0",
      vk: "205972442_456239111",
    },
    title: "Внутренняя аналитика",
  },
  // ------------ OTHERS
};

export default VideoDialogAuto;
