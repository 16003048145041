import React from "react";

import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import { Columns } from "root/js/columns";

import BarGraph from "root/components/BarGraph";
import ProductInTable from "root/components/ProductInTable";
import SellerLink from "root/components/SellerLink";

import AllCheckBoxes from "./AllCheckBoxes";
import CheckBox_ from "./CheckBox";

import { isMobile, roundRating } from "root/js/utils";

const ColumnsBuilder = new Columns();

export const columns = ColumnsBuilder.getColumnsByKeys([
  {
    title: <AllCheckBoxes />,
    dataIndex: "id",
    render: value => <CheckBox_ value={value} />,
    width: "74px",
    sticky: !isMobile,
  },
  {
    title: "Товар",
    dataIndex: "product",
    render: ({ image, name, articule }) => (
      <ProductInTable image={image} text={name} articule={articule} />
    ),
    width: 300,
    sticky: !isMobile,
    sortable: true,
  },
  {
    title: "Тип",
    dataIndex: "product",
    render: ({ type }) => (type == "b" ? "Автореклама" : "Реклама в поиске + каталог"),
  },
  {
    dataIndex: "position",
    title: "Текущая позиция",
    sortable: true,
  },
  {
    dataIndex: "expected_position",
    title: "Буст с позиции",
    sortable: true,
  },

  {
    dataIndex: "cpm",
    sortable: true,
  },
  // {
  // 	dataIndex: "book_place",
  // 	sortable: true,
  // },
  {
    dataIndex: "subject",
    sortable: true,
  },
  // {
  // 	dataIndex: "cpm",
  // 	sortable: true,
  // },
  {
    dataIndex: "time",
    sortable: true,
  },
  {
    dataIndex: "id",
    render: value => value,
    default: true,
  },
  {
    dataIndex: "brand",
    render: value => (
      <Link underline="hover" to={`/brand/${value.id}`}>
        {value.name}
      </Link>
    ),
    default: true,
  },
  {
    dataIndex: "seller",
    width: 250,
    render: value => <SellerLink {...value} />,
    default: true,
  },
  {
    dataIndex: "proceeds",
    default: true,
  },
  {
    dataIndex: "proceeds_dynamic",
    dynamicIndex: "proceeds",
    render: (value, column) => (
      <BarGraph dataKey="y" data={value} tooltipPostfix={column.rawPostfix} />
    ),
    default: true,
  },
  {
    dataIndex: "price",
    default: true,
  },
  {
    dataIndex: "weighted_price",
    sortable: true,
  },
  {
    dataIndex: "damping_coefficient",
    sortable: true,
  },
  {
    dataIndex: "price_dynamic",
    title: "Динамика средней цены без СПП",
    dynamicIndex: "price",
    render: (value, column) => (
      <BarGraph dataKey="y" data={value} tooltipPostfix={column.rawPostfix} />
    ),
  },
  {
    dataIndex: "discount",
    default: true,
  },
  {
    dataIndex: "discount_dynamic",
    dynamicIndex: "discount",
    render: (value, column) => (
      <BarGraph dataKey="y" data={value} tooltipPostfix={column.rawPostfix} />
    ),
  },

  {
    dataIndex: "orders",
    default: true,
  },
  {
    dataIndex: "orders_dynamic",
    dynamicIndex: "orders",
    render: (value, column) => (
      <BarGraph dataKey="y" data={value} tooltipPostfix={column.rawPostfix} />
    ),
  },
  // {
  // 	dataIndex: "sales_proceeds",
  // 	default: false,
  // },
  // {
  // 	dataIndex: "sales_percent",
  // 	default: true,
  // },
  // {
  // 	dataIndex: "sales_proceeds_dynamic",
  // 	dynamicIndex: "sales_proceeds",
  // 	render: (value, column) => (
  // 		<BarGraph
  // 			dataKey="y"
  // 			data={value}
  // 			tooltipPostfix={column.rawPostfix}
  // 		/>
  // 	),
  // },
  // "sales",
  // {
  // 	dataIndex: "sales_dynamic",
  // 	dynamicIndex: "sales",
  // 	render: (value, column) => (
  // 		<BarGraph
  // 			dataKey="y"
  // 			data={value}
  // 			tooltipPostfix={column.rawPostfix}
  // 		/>
  // 	),
  // },
  {
    dataIndex: "quantity",
    default: true,
  },
  {
    dataIndex: "quantity_dynamic",
    dynamicIndex: "quantity",
    render: (value, column) => (
      <BarGraph dataKey="y" data={value} tooltipPostfix={column.rawPostfix} />
    ),
  },
  {
    dataIndex: "lost_proceeds",
    default: true,
  },
  {
    dataIndex: "lost_orders",
    default: true,
  },
  "in_stock_percent",
  "in_stock_days",
  "out_of_stock_days",
  "in_stock_orders_avg",
  "in_stock_proceeds",
  "reviews",
  {
    dataIndex: "reviews_dynamic",
    dynamicIndex: "reviews",
    render: (value, column) => (
      <BarGraph dataKey="y" data={value} tooltipPostfix={column.rawPostfix} />
    ),
  },
  {
    dataIndex: "rating",
    render: roundRating,
  },
  {
    dataIndex: "rating_dynamic",
    dynamicIndex: "reviews",
    render: (value, column) => (
      <BarGraph dataKey="y" data={value} tooltipPostfix={column.rawPostfix} />
    ),
  },
]);
export const title = "Реклама в каталоге";
export const localStorageColumnSettings = "category-optimization";
export const afixes = {
  Москва: "ЦФО (Центральном федеральном округе)",
  ["Санкт-Петербург"]: "СЗФО (Северо-Западном федеральном округе)",
  Новосибирск: "СФО (Сибирском федеральном округе)",
  Хабаровск: "ДФО (Дальневосточном федеральном округе)",
  Екатеринбург: "УФО (Уральском федеральном округе)",
  Казань: "ПФО (Приволжском федеральном округе)",
  Краснодар: "ЮФО (Южном федеральном округе)",
};
