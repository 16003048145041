import request from "root/js/request";

export const getWBDynamicKeywordsStat = async ({
  keyword_ids,
  // Фильтр по конкретным ключевым словам, можно передавать несколько
  // ключевых слов разделяя точкой с запятой (;)
  keywords, // <Платье>;<Платье2>
  stop_words,
  date_from,
  date_to,
  search,
  ordering,
  period,
  limit,
  offset,
  extra_fields,
  collection_id,
  predict_trend,
  predict_trand = predict_trend,
  ...rest
}) => {
  const response = await request({
    url: `/wb_dynamic/keywords_stat/`,
    method: "get",
    params: {
      collection_id,
      stop_words,
      keyword_ids,
      keywords,
      date_from,
      date_to,
      search,
      ordering,
      period,
      limit,
      offset,
      extra_fields,
      predict_trand,
      ...rest,
    },
  });
  return response.data;
};
