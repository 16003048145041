import React from "react"

import styles from "./index.module.scss"
export const CircleDescription = (props) => {
	const { name, color } = props
	return (
		<div className={styles.cicrleDescription}>
			<div style={{color:color, background:color, minWidth:"22px", height:"22px", borderRadius: "3px" }}> </div>
			<span>{name}</span>
		</div>
	)
}

