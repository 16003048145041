import React, { Suspense, useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Button, OutlinedInput } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import { indexOf } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { promotionMediaFilter } from "root/store/financialLogic/financialLogicSlice";

import TabPanel from "root/components/TabPanel";
import {
  keyPhrasesTables,
  promotionIndicators,
  promotionMediaTableTabe,
} from "root/pages/financial-logic/info";

import { calcOffset } from "../../../../../../../js/utils";
import { AdvertTablesHints } from "../../../../../../../store/financialLogic/action";

import styles from "./index.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getRandomNumber(min = 1, max = 1000) {
  return Math.round(min - 0.5 + Math.random() * (max - min + 1));
}

const PromotionMediaTable = ({ showPositionChart }) => {
  const {
    keys,
    categoryId,
    brandIds,
    dateFrom,
    dateTo,
    status,
    type,
    isLoadingAdvertDate,
    companies,
    promotionSelectedCompany,
    indicators,
    advertTableHints,
  } = useSelector(state => state.financeLogic);
  const [noFilterRow, setNoFilterRow] = useState(true);
  const [initialState, setInitialState] = useState(true);
  const [tableChanged, setTableChanged] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [elements, setElements] = useState([
    {
      index: 0,
      data: "",
      from: "",
      to: "",
      title: "",
    },
  ]);
  const [table, setTable] = useState(2);
  const [tableKeyPhrases, setTableKeyPhrases] = useState(0);
  const [value, setValue] = useState("");
  const [indicatorFilter, setIndicatorFilter] = useState([
    {
      index: 0,
      data: "",
      from: "",
      to: "",
      title: "",
    },
  ]);
  const dispatch = useDispatch();
  const [sort, setSort] = useState("-views");
  const [tablePerPage, setTablePerPage] = useState(100);
  const [offset, setOffset] = useState(null);
  const [tablePage, setTablePage] = useState(1);
  const [textAdvertId, setTextAdvertId] = useState("");
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(isLoadingAdvertDate);
  const [isDeleteRow, setIsDeleteRow] = useState(false);
  const isEmptyFilter = indicatorFilter.some(
    item => item.data === "" || item.from === "" || item.to === "" || item.title === ""
  );

  useEffect(() => {
    try {
      setIsLoading(true);
      if (keys.length > 0 && status?.length > 0 && type?.length) {
        if (offset === 0) setTablePage(1);
        dispatch(
          AdvertTablesHints({
            dateFrom,
            dateTo,
            brands: brandIds,
            categories: categoryId,

            group_by: promotionMediaTableTabe[table]["tabIndex"],
            keys,
            sort,
            q: text,
            limit: tablePerPage,
            offset: offset !== null ? offset : calcOffset(tablePage, tablePerPage),
            status,
            type,
            companies:
              companies.length > 0 ? companies : promotionSelectedCompany.map(i => i.advert_id),
            advert_id: textAdvertId,
            indicators,
          })
        )
          .unwrap()
          .then(res => {
            if (res) {
              setIsLoading(false);
              setOffset(null);
            }
          });
      }
    } catch (e) {
      console.log(e.message);
      setIsLoading(false);
    }
  }, [
    dateFrom,
    brandIds,
    categoryId,
    sort,
    keys,
    tablePerPage,
    tablePage,
    calcOffset,
    companies,
    promotionSelectedCompany,
    status,
    type,
    table,
  ]);

  useEffect(() => {
    if (!isEmptyFilter) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (isEmptyFilter) {
      if (initialState) setNoFilterRow(true);
      else setNoFilterRow(false);
    } else {
      setNoFilterRow(false);
    }
    // handleFilter();
  }, [indicatorFilter]);

  useEffect(() => {
    if (isDeleteRow) {
      handleFilter();
      setIsDeleteRow(false);
    }
  }, [isDeleteRow]);

  const onChageTable = table => {
    setTable(table);
  };

  const onChageTableKeyPhrases = table => {
    setTableKeyPhrases(table);
  };

  const handleFilter = () => {
    dispatch(
      promotionMediaFilter({
        name: "indicator",
        value: indicatorFilter,
      })
    );
    // setInitialState(false);
  };

  useEffect(() => {
    if (indicatorFilter.length > 0) {
      indicatorFilter.map(elem => {
        let from =
          elem.data === "views"
            ? advertTableHints.min_views
            : elem.data === "clicks"
            ? advertTableHints.min_clicks
            : elem.data === "atbs"
            ? advertTableHints.min_atbs
            : elem.data === "sum"
            ? advertTableHints.min_sum
            : elem.data === "sum_price"
            ? advertTableHints.min_sum_price
            : elem.data === "orders"
            ? advertTableHints.min_orders
            : elem.data === "ctr"
            ? advertTableHints.min_ctr
            : elem.data === "cpc"
            ? advertTableHints.min_cpc
            : elem.data === "frq"
            ? advertTableHints.min_frq
            : elem.data === "cr"
            ? advertTableHints.min_cr
            : elem.data === "cpm"
            ? advertTableHints.min_cpm
            : elem.data === "cpa"
            ? advertTableHints.min_cpa
            : elem.data === "romi"
            ? advertTableHints.min_romi
            : elem.data === "roas"
            ? advertTableHints.min_roas
            : elem.data === "crr"
            ? advertTableHints.min_crr
            : elem.data === "car"
            ? advertTableHints.min_car
            : elem.data === "gross_margin"
            ? advertTableHints.min_gross_margin
            : null;

        let to =
          elem.data === "views"
            ? advertTableHints.max_views
            : elem.data === "clicks"
            ? advertTableHints.max_clicks
            : elem.data === "atbs"
            ? advertTableHints.max_atbs
            : elem.data === "sum"
            ? advertTableHints.max_sum
            : elem.data === "sum_price"
            ? advertTableHints.max_sum_price
            : elem.data === "orders"
            ? advertTableHints.max_orders
            : elem.data === "ctr"
            ? advertTableHints.max_ctr
            : elem.data === "cpc"
            ? advertTableHints.max_cpc
            : elem.data === "frq"
            ? advertTableHints.max_frq
            : elem.data === "cr"
            ? advertTableHints.max_cr
            : elem.data === "cpm"
            ? advertTableHints.max_cpm
            : elem.data === "cpa"
            ? advertTableHints.max_cpa
            : elem.data === "romi"
            ? advertTableHints.max_romi
            : elem.data === "roas"
            ? advertTableHints.max_roas
            : elem.data === "crr"
            ? advertTableHints.max_crr
            : elem.data === "car"
            ? advertTableHints.max_car
            : elem.data === "gross_margin"
            ? advertTableHints.max_gross_margin
            : null;

        setIndicatorFilter(prevIndicatorFrom => {
          const newIndicatorFrom = prevIndicatorFrom.filter(i => i.index !== elem.index);

          if (elem !== "") {
            newIndicatorFrom.push({
              index: elem.index,
              data: elem.data,
              from: from,
              to: to,
              title: elem.title,
            });
          }

          // *********************Draw filter raws*********************
          const newElements = elements.map(el =>
            el.index === elem.index
              ? { index: elem.index, data: elem.data, from: from, to: to, title: elem.title }
              : el
          );
          setElements(newElements);
          // *********************Draw filter raws*********************

          return newIndicatorFrom;
        });
      });

      setTableChanged(true);
    }
  }, [advertTableHints]);

  const handleChange = (e, elem, index, title) => {
    setTableChanged(false);

    let from =
      elem === "views"
        ? advertTableHints.min_views
        : elem === "clicks"
        ? advertTableHints.min_clicks
        : elem === "atbs"
        ? advertTableHints.min_atbs
        : elem === "sum"
        ? advertTableHints.min_sum
        : elem === "sum_price"
        ? advertTableHints.min_sum_price
        : elem === "orders"
        ? advertTableHints.min_orders
        : elem === "ctr"
        ? advertTableHints.min_ctr
        : elem === "cpc"
        ? advertTableHints.min_cpc
        : elem === "frq"
        ? advertTableHints.min_frq
        : elem === "cr"
        ? advertTableHints.min_cr
        : elem === "cpm"
        ? advertTableHints.min_cpm
        : elem === "cpa"
        ? advertTableHints.min_cpa
        : elem === "romi"
        ? advertTableHints.min_romi
        : elem === "roas"
        ? advertTableHints.min_roas
        : elem === "crr"
        ? advertTableHints.min_crr
        : elem === "car"
        ? advertTableHints.min_car
        : elem === "gross_margin"
        ? advertTableHints.min_gross_margin
        : null;

    let to =
      elem === "views"
        ? advertTableHints.max_views
        : elem === "clicks"
        ? advertTableHints.max_clicks
        : elem === "atbs"
        ? advertTableHints.max_atbs
        : elem === "sum"
        ? advertTableHints.max_sum
        : elem === "sum_price"
        ? advertTableHints.max_sum_price
        : elem === "orders"
        ? advertTableHints.max_orders
        : elem === "ctr"
        ? advertTableHints.max_ctr
        : elem === "cpc"
        ? advertTableHints.max_cpc
        : elem === "frq"
        ? advertTableHints.max_frq
        : elem === "cr"
        ? advertTableHints.max_cr
        : elem === "cpm"
        ? advertTableHints.max_cpm
        : elem === "cpa"
        ? advertTableHints.max_cpa
        : elem === "romi"
        ? advertTableHints.max_romi
        : elem === "roas"
        ? advertTableHints.max_roas
        : elem === "crr"
        ? advertTableHints.max_crr
        : elem === "car"
        ? advertTableHints.max_car
        : elem === "gross_margin"
        ? advertTableHints.max_gross_margin
        : null;

    setIndicatorFilter(prevIndicatorFrom => {
      const newIndicatorFrom = prevIndicatorFrom.filter(i => i.index !== index);

      if (elem !== "") {
        newIndicatorFrom.push({ index: index, data: elem, from: from, to: to, title: title });
      }

      // *********************Draw filter raws*********************
      const newElements = elements.map(el =>
        el.index === index ? { index: index, data: elem, from: from, to: to, title: title } : el
      );
      setElements(newElements);
      // *********************Draw filter raws*********************
      return newIndicatorFrom;
    });
  };

  const handleChangeFrom = (e, index, title) => {
    setTableChanged(false);

    setIndicatorFilter(prevIndicatorFrom => {
      const tempIndicatorFrom = prevIndicatorFrom.filter(i => i.index === index)[0];
      const newIndicatorFrom = prevIndicatorFrom.filter(i => i.index !== index);

      if (e !== "") {
        newIndicatorFrom.push({
          index: index,
          data: tempIndicatorFrom?.data,
          from: e,
          to: tempIndicatorFrom?.to,
          title: title,
        });
      } else {
        newIndicatorFrom.push({
          index: index,
          data: tempIndicatorFrom?.data,
          from: 0,
          to: tempIndicatorFrom?.to,
          title: title,
        });
      }

      // *********************Draw filter raws*********************
      const newElements = elements.map(el =>
        el.index === index
          ? {
              index: index,
              data: tempIndicatorFrom?.data,
              from: e,
              to: tempIndicatorFrom?.to,
              title: title,
            }
          : el
      );
      setElements(newElements);
      // *********************Draw filter raws*********************

      return newIndicatorFrom;
    });
  };

  const handleChangeTo = (e, index, title) => {
    setTableChanged(false);

    setIndicatorFilter(prevIndicatorFrom => {
      const tempIndicatorFrom = prevIndicatorFrom.filter(i => i.index === index)[0];
      const newIndicatorFrom = prevIndicatorFrom.filter(i => i.index !== index);

      if (e !== "") {
        newIndicatorFrom.push({
          index: index,
          data: tempIndicatorFrom?.data,
          from: tempIndicatorFrom?.from,
          to: e,
          title: title,
        });
      } else {
        newIndicatorFrom.push({
          index: index,
          data: tempIndicatorFrom?.data,
          from: tempIndicatorFrom?.from,
          to: 0,
          title: title,
        });
      }

      // ********************Draw filter raws**********************
      const newElements = elements.map(el =>
        el.index === index
          ? {
              index: index,
              data: tempIndicatorFrom.data,
              from: tempIndicatorFrom.from,
              to: e,
              title: title,
            }
          : el
      );
      setElements(newElements);
      // *********************Draw filter raws*********************

      return newIndicatorFrom;
    });
  };

  const handleAddNewFilterRow = () => {
    setIsDisabled(true);
    setNoFilterRow(true);

    const newElement = {
      index: getRandomNumber(),
      data: "",
      from: "",
      to: "",
      title: "",
    };
    setElements([...elements, newElement]);
    setIndicatorFilter([...elements, newElement]);
  };

  const handleOpenDelete = e => {
    setTableChanged(false);

    if (indicatorFilter.length === 1) {
      setIndicatorFilter([
        {
          index: 0,
          data: "",
          from: "",
          to: "",
          title: "",
        },
      ]);
      setElements([
        {
          index: 0,
          data: "",
          from: "",
          to: "",
          title: "",
        },
      ]);
    } else {
      setIndicatorFilter(prevIndicatorFilter => {
        const newIndicatorFilter = prevIndicatorFilter.filter(i => i.index !== e);
        return newIndicatorFilter;
      });
      setElements(elements.filter(el => el.index !== e));
      setIsDeleteRow(true);
    }

    handleFilter();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.advertTablesSettings}>
        <div className={styles.filtersBlock}>
          <div className={styles.box} id="filtersBox">
            {elements.map((element, index) => (
              <div
                className={classNames(styles.line, "filterLine")}
                key={element.index}
                data-id={element.index}>
                <FormControl sx={{ m: 1, width: 300 }} size="small" className={styles.customHeight}>
                  <InputLabel>Показатель</InputLabel>
                  <Select
                    className={styles.customHeight}
                    value={element.title || ""}
                    input={<OutlinedInput label="Показатель" />}
                    MenuProps={MenuProps}
                    required={true}>
                    {promotionIndicators.map(elem => (
                      <MenuItem
                        key={elem.keyIndex}
                        value={elem.title}
                        data-id={element.index}
                        onClick={e => handleChange(e, elem.keyIndex, element.index, elem.title)}
                        disabled={indicatorFilter?.some(item => item.data === elem.keyIndex)}>
                        <ListItemText primary={elem.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <span>в диапазоне</span>
                <div className={styles.inputWrapper}>
                  <TextField
                    type="number"
                    name="from"
                    label="от"
                    variant="outlined"
                    size="small"
                    className={styles.textInput}
                    data-id={element.index}
                    value={element.from}
                    onChange={e => handleChangeFrom(e.target.value, element.index, element.title)}
                  />
                  <TextField
                    type="number"
                    name="to"
                    label="до"
                    variant="outlined"
                    size="small"
                    className={styles.textInput}
                    required={true}
                    value={element.to}
                    onChange={e => handleChangeTo(e.target.value, element.index, element.title)}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    className={styles.bttnSize}
                    disabled={element.data === "" && indicatorFilter.length === 1}
                    onClick={() => {
                      handleOpenDelete(element.index);
                    }}>
                    <DeleteIcon />
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <span
            className={classNames(
              styles.addFilterLink,
              noFilterRow === true ? styles.disabledButton : ""
            )}
            onClick={handleAddNewFilterRow}>
            Добавить еще условие
          </span>
        </div>

        <Button color="primary" variant="contained" onClick={handleFilter} disabled={isDisabled}>
          Фильтровать таблицу
        </Button>
      </div>
      <Paper elevation={0} className={styles.tableWrap}>
        {promotionMediaTableTabe.map((item, index) => (
          <Suspense key={item.keyIndex} fallback={<></>}>
            <TabPanel key={item.keyIndex} value={table} index={index}>
              <item.component
                onTabChange={onChageTable}
                tabValue={table}
                showPositionChart={showPositionChart}
              />
            </TabPanel>
          </Suspense>
        ))}
      </Paper>

      <Paper elevation={0} className={classNames(styles.marginTop, styles.tableWrap)}>
        {keyPhrasesTables.map((item, index) => (
          <Suspense key={item.keyIndex} fallback={<></>}>
            <TabPanel key={item.keyIndex} value={tableKeyPhrases} index={index}>
              <item.component
                onTabChange={onChageTableKeyPhrases}
                tabValue={tableKeyPhrases}
                showPositionChart={showPositionChart}
              />
            </TabPanel>
          </Suspense>
        ))}
      </Paper>
    </div>
  );
};

export default PromotionMediaTable;
