import React, { forwardRef } from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import classnames from "classnames";

import styles from "./index.module.scss";

const IconButton_ = forwardRef((props, ref) => {
  let { children, variant, isLoading, className, ...rest } = props;

  return (
    <Button
      ref={ref}
      className={classnames(styles.root, className)}
      data-variant={variant}
      {...rest}>
      {isLoading ? (
        <CircularProgress className={styles.preloader} size={20} thickness={4.5} />
      ) : (
        <span className={styles.icon}>{children}</span>
      )}
    </Button>
  );
});

export default IconButton_;
