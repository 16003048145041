import request from "root/js/request";

export const getParsersSupplierArticleAdvertsUsageHistory = async ({
  supplier_article,
  product_id,
  supplier_article_ids,
  product_ids,
  marketplace,
  offset = 0,
  limit = 1000,
  ordering,
  ...rest
}) => {
  const response = await request({
    url: "parsers/supplier_article_adverts/usage_history/",
    method: "get",
    params: {
      supplier_article,
      product_id,
      supplier_article_ids,
      product_ids,
      marketplace,
      offset,
      limit,
      ordering,
      ...rest,
    },
  });
  return response.data;
};
