import request from "root/js/request";

const getCommissionsAndDiscountsWidgetsV2 = async ({
  key,
  date_from,
  date_to,
  category_id__in,
  brand_id__in,
  supplier_article__in,
  product_id__in,
  barcode__in,
  subject_id__in,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/commission_widgets_v2/",

    method: "get",
    params: {
      wb_api_key_ids: key,
      date_from,
      date_to,
      category_id__in,
      brand_id__in,
      supplier_article__in,
      product_id__in,
      barcode__in,
      subject_id__in,
      ...rest,
    },
  });
  return response.data;
};

export { getCommissionsAndDiscountsWidgetsV2 };
