import request from "root/js/request";

export const getGlobalEvents = async params => {
  const response = await request({
    url: "/analytics/global_events/",
    method: "get",
    params,
  });
  return response.data;
};
