import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "root/js/api";

import { calcDate } from "root/pages/financial-logic/helpers";

const WBAPI = new API();

export const Getaccounts = createAsyncThunk("get/account/ecconomic", async () => {
  try {
    const response = await WBAPI.getCompanyApiKey();
    return response;
  } catch (e) {
    console.log(e.message);
  }
});

export const GetTableEconomic = createAsyncThunk(
  "table/ecconomic",
  async ({
    dateTo,
    dateFrom,
    keys,
    group_by,
    sort,
    q,
    limit,
    offset,
    product_cost__isnull,
    gross_margin__lt,
    gross_margin_to_realization_price_share__gt,
    gross_margin_to_realization_price_share__lt,
    gross_margin_to_cost_share__gt,
    gross_margin_to_cost_share__lt,
    categoryId,
    subjectsId,
    brandIds,
    product_id__icontains
  }) => {
    const joinKeys = keys.join();
    const categories = categoryId.join();
    const subjects = subjectsId.join();
    const brands = brandIds.join();
    try {
      const response = await WBAPI.tableEconomic({
        dateTo,
        dateFrom,
        joinKeys,
        group_by,
        sort,
        q,
        limit,
        offset,
        product_id__icontains,
        product_cost__isnull,
        gross_margin__lt,
        gross_margin_to_realization_price_share__gt,
        gross_margin_to_realization_price_share__lt,
        gross_margin_to_cost_share__gt,
        gross_margin_to_cost_share__lt
      });

      return { group_by, response };
    } catch (e) {
      console.log(e.message);
    }
  }
);

export const TreateWidgetsEconomic = createAsyncThunk(
  "treade/widgets",
  async ({ dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId, keys }) => {
    const wb_api_key_ids = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const article = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();
    const subject = subjectsId.join();
    try {
      const response = await WBAPI.treateWidgetsEconomic({
        dateFrom,
        dateTo,
        ids,
        brandsId,
        article,
        barcod,

        subject,
        wb_api_key_ids
      });

      return response;
    } catch (e) {
      return e.message;
    }
  }
);

export const TreateTablesEconomic = createAsyncThunk(
  "treade/table/economic",
  async ({
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    articulIds,
    barcodeIds,

    subjectsId,
    keys,
    group_by,
    limit,
    offset,
    q,
    sort,
    product_id__icontains
  }) => {
    const wb_api_key_ids = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const article = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    const response = await WBAPI.treateTableEconomic({
      dateFrom,
      dateTo,
      ids,
      brandsId,
      article,
      barcod,

      subject,
      wb_api_key_ids,
      group_by,
      limit,
      offset,
      q,
      sort,
      product_id__icontains
    });

    return { response, group_by };
  }
);

export const TredeEconomyDiagram = createAsyncThunk(
  "get/dynamic",
  async (
    { dateFrom, dateTo, categoryId, brandIds, articulIds, barcodeIds, subjectsId, keys },
    thunkAPI
  ) => {
    const wb_api_key_ids = keys.join();
    const ids = categoryId.join();
    const brandsId = brandIds.join();
    const article = JSON.stringify(articulIds);
    const barcod = barcodeIds.join();

    const subject = subjectsId.join();

    try {
      const ONE_DAY = 24 * 60 * 60 * 1000;
      const prevDateTo = calcDate(new Date(dateFrom), 1);
      const prevDateFrom = new Date(prevDateTo - (dateTo - dateFrom));

      const currentRequest = WBAPI.treadeEconomyDiagram({
        wb_api_key_ids,
        date_from: dateFrom,
        date_to: dateTo,
        ids,
        brandsId,
        article,
        barcod,

        subject
      });
      const prevRequest = WBAPI.treadeEconomyDiagram({
        wb_api_key_ids,
        date_from: prevDateFrom,
        date_to: prevDateTo,
        ids,
        brandsId,
        article,
        barcod,

        subject
      });
      const [currentResponse, prevResponse] = await Promise.all([currentRequest, prevRequest]);
      const respToRespByDates = resp =>
        resp.reduce((acc, i) => {
          acc[i.date] = i;
          return acc;
        }, {});
      const curRespByDates = respToRespByDates(currentResponse);

      const prevRespByDates = respToRespByDates(prevResponse);
      const dateToDateKey = dt =>
        `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(
          -2
        )}`;
      const daysCount = (dateTo - dateFrom) / ONE_DAY;
      const data = Array.from({ length: daysCount }).map((_, i) => {
        const curDate = new Date(dateFrom.getTime() + ONE_DAY * i);
        const prevDate = new Date(prevDateFrom.getTime() + ONE_DAY * i);

        const curDateKey = dateToDateKey(curDate);
        const prevDateKey = dateToDateKey(prevDate);

        return {
          currentDate: curDateKey,
          // prevDate:prevDateKey,
          current: curRespByDates[curDateKey],
          prev: prevRespByDates[prevDateKey]
        };
      });
      return data;
    } catch (e) {
      return e.message;
    }
  }
);

export const ABCSoldWidgets = createAsyncThunk(
  "abc/sold/widgets",
  async ({
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    keys,
    subjectsId,
    analyze_by,
    abcLabels,
    xyzLabels
  }) => {
    try {
      const wb_api_key_ids = keys.join();
      const ids = categoryId.join();
      const brandsId = brandIds.join();

      const subject = subjectsId.join();
      const response = await WBAPI.abcSoldWidgets({
        dateFrom,
        dateTo,
        ids,
        brandsId,
        wb_api_key_ids,
        subject,
        analyze_by,
        abcLabels,
        xyzLabels
      });

      return { response, analyze_by };
    } catch (e) {
      console.log(e.message);
      throw e;
    }
  }
);
export const ABCSoldCircle = createAsyncThunk(
  "abc/sold/circle",
  async ({
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    keys,
    subjectsId,
    analyze_by,
    group_by,
    abcLabels,
    xyzLabels
  }) => {
    try {
      const wb_api_key_ids = keys.join();
      const ids = categoryId.join();
      const brandsId = brandIds.join();

      const subject = subjectsId.join();
      const response = await WBAPI.abcSoldCircle({
        dateFrom,
        dateTo,
        ids,
        brandsId,
        wb_api_key_ids,
        subject,
        analyze_by,
        group_by,
        abcLabels,
        xyzLabels
      });

      return { response, analyze_by, group_by };
    } catch (e) {
      console.log(e.message);
      throw e;
    }
  }
);

export const ABCSoldDiagram = createAsyncThunk(
  "abc/sold/diagram",
  async ({
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    keys,
    subjectsId,
    analyze_by,
    abcLabels,
    xyzLabels
  }) => {
    try {
      const wb_api_key_ids = keys.join();
      const ids = categoryId.join();
      const brandsId = brandIds.join();

      const subject = subjectsId.join();
      const response = await WBAPI.abcSoldDiagram({
        dateFrom,
        dateTo,
        ids,
        brandsId,
        wb_api_key_ids,
        subject,
        analyze_by,
        abcLabels,
        xyzLabels
      });

      return { analyze_by, response };
    } catch (e) {
      console.log(e.message);
      throw e;
    }
  }
);

export const ABCSoldProduct = createAsyncThunk(
  "abc/sold/product",
  async ({
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    keys,
    subjectsId,
    analyze_by,
    reverse,
    abcLabels,
    xyzLabels
  }) => {
    try {
      const wb_api_key_ids = keys.join();
      const ids = categoryId.join();
      const brandsId = brandIds.join();

      const subject = subjectsId.join();
      const response = await WBAPI.abcSoldProduct({
        dateFrom,
        dateTo,
        ids,
        brandsId,
        wb_api_key_ids,
        subject,
        analyze_by,
        reverse,
        abcLabels,
        xyzLabels
      });

      return { response, analyze_by, reverse };
    } catch (e) {
      console.log(e.message);
      throw e;
    }
  }
);

export const ABCSoldTables = createAsyncThunk(
  "abc/sold/table",
  async ({
    dateFrom,
    dateTo,
    categoryId,
    brandIds,
    keys,
    subjectsId,
    group_by,
    sort,
    q,
    limit,
    offset,
    synthetic_abc_realization_quantity_share_weight,
    synthetic_abc_proceeds_share_weight,
    synthetic_abc_gross_margin_share_weight,
    abcLabels,
    xyzLabels,
    abcFilterTypeABC,
    abcFilterTypeXYZ,
    product_id__icontains,
    abcFilterType
  }) => {
    try {
      const wb_api_key_ids = keys.join();
      const ids = categoryId.join();
      const brandsId = brandIds.join();
      const subject = subjectsId.join();

      const response = await WBAPI.abcSoldTables({
        dateFrom,
        dateTo,
        ids,
        brandsId,
        wb_api_key_ids,
        subject,
        product_id__icontains,
        group_by,
        sort,
        q,
        limit,
        offset,
        synthetic_abc_realization_quantity_share_weight,
        synthetic_abc_proceeds_share_weight,
        synthetic_abc_gross_margin_share_weight,
        abcLabels,
        xyzLabels,
        abcFilterTypeABC,
        abcFilterTypeXYZ,
        abcFilterType
      });
      return { response, group_by };
    } catch (e) {
      console.log(e.message);
    }
  }
);
