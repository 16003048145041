import React from "react";

import СompetitorsMenuIcon from "root/icons/СompetitorsMenu";

// -------------------------------------------
const nichesAndTrendsSection = {
  type: "section",
  id: "niches_and_trends",
  title: "Ниши и тренды",
  icon: СompetitorsMenuIcon,
  children: [
    {
      url: "/trend-requests-old",
      component: React.lazy(() => import("root/pages/trend-requests-old/App")),
      title: "Трендовые запросы",
      exact: false,
    },
    {
      url: "/keywords-summary-v2",
      component: React.lazy(() => import("root/pages/trend-requests/App")),
      title: "Оракул запросов",
      exact: false,
    },
    {
      url: "/product-analyzer",
      component: React.lazy(() => import("root/pages/product-analyzer/App")),
      title: "По товарам",
      exact: false,
    },
    {
      url: "/brand-analyzer",
      component: React.lazy(() => import("root/pages/brand-analyzer/App")),
      title: "По брендам",
      exact: false,
    },
    {
      url: "/seller-analyzer",
      component: React.lazy(() => import("root/pages/seller-analyzer/App")),
      title: "По поставщикам",
      exact: false,
    },
    {
      url: "/category-analyzer",
      component: React.lazy(() => import("root/pages/category-analyzer/App")),
      title: "По категориям",
      exact: false,
    },
    {
      url: "/request-analyzer",
      component: React.lazy(() => import("root/pages/request-analyzer/App")),
      title: "По запросам",
      exact: false,
    },
  ],
};

export default nichesAndTrendsSection;
