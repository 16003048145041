import React from "react"

const Triangle = props => {
	const {
		result,
		colorIncrement,
		colorDicrement,
		transformRotateDicrement,
		transformRotateIncrement,
	} = props

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="currentColor"
			className="bi bi-caret-right-fill"
			viewBox="0 0 16 16"
			style={{
				transform: result > 0 ? transformRotateIncrement : transformRotateDicrement,
				color: result > 0 ? colorIncrement : colorDicrement,
			}}
		>
			<path d="M4.01415 2.65793H7.97906L0.75 9.9009L2.0965 11.25L9.34574 3.98675V7.99983H11.25V0.75H4.01415V2.65793Z" />
		</svg>
	)
}

export default Triangle
