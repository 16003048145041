import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
// import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist"
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { queryAPI } from "root/api/queryAPI";
import { WBapi } from "root/services/WBapi";

import abcXyzAnalyzeSlice from "./abcXyzAnalyzeSlice";
import advertisingOptimization from "./advertisingOptimization/advertisingOptimizationSlice";
import analysisBySubjectSlice from "./analysisBySubject/analysisBySubjectSlice";
import analyticsSlice from "./analyticsSlice/analyticsSlice";
import articlesComparisonReducer from "./articlesComparison/articlesComparisonSlice";
import { articuleCompasitionReducer } from "./articlesComparisonOld/reducer";
import autoAdvertising from "./autoAdvertising/autoAdvertisingSlice";
import autoFeedbackSlice from "./autoFeedbacks/autoFeedbackSlice";
import bloggerSlice from "./blogger/bloggerSlice";
import bloggersPostsSelection from "./bloggersPostsSelection";
import bloggersProfilesSelection from "./bloggersProfilesSelection";
import bloggersSearchSlice from "./bloggersSearchSlice/bloggersSearchSlice";
import brandAnalyzer from "./brandAnalyzer/brandAnalyzerSlice";
import { brandCardReducer } from "./brandCard/reducer";
import { bussinessAnaltyticsSliceReducer } from "./businessAnalytics/businessAnalyticsSlice";
import caregoryAnalyzer from "./categoryAnalyzer/categoryAnalyzerSlice";
import { categoryCardReducer } from "./categoryCard/reducer";
import categoryOptimizationSlice from "./categoryOptimization/categoryOptimization";
import categorySlice from "./categoryPage/categorySlice";
import { chatGpt } from "./chatGpt/reducer";
import { economicLogicSliceReducer } from "./economicalLogic/economicLogicSlice";
import { financialLogicSliceReducer } from "./financialLogic/financialLogicSlice";
import pvzSlice from "./geoPVZ/geoPVZSlice";
import groupingReducer from "./grouping/groupingSlice";
import { grouping } from "./groupingOld/reducer";
import growthLeaders from "./growthLeaders";
import growthLeadersSlice from "./growthLeadersOld/growthLeadersSlice";
import innerAnalyticsGlobal from "./innerAnalyticsGlobal";
import innerEconomicGlobal from "./innerEconomicGlobal";
import { inventoryManagmentSliceReducer } from "./inventoryManagment/inventoryManagmentSlice";
import { keyCompasitionReducer } from "./keyComposition/reducer";
import keysCheckProducts from "./keysCheckProducts/keysCheckProducts";
import keystatReducer from "./keystatSlice/keystatSlice";
import searchQueryGraphicSlice from "./keystatSlice/searchQueryGraphicSlice";
import { keyWordTable } from "./keyWordTable/reducer";
import loadFilter from "./loadFilter/loadFilter";
import marksReducer from "./marks/marksSlice";
import mergeAnalysis from "./mergeAnalysis";
import brandsSlice from "./monitoring/brands/brandsSlice";
import categoriesSlice from "./monitoring/category/categoriesSlice";
import keywordsSlice from "./monitoring/keywords/keywordsSlice";
import monitoringSlice from "./monitoring/monitoringSlice";
import productsSlice from "./monitoring/products/productsSlice";
import sellersSlice from "./monitoring/sellers/sellersSlice";
import sppReducer from "./monitoring/spp/sppSlice";
import notificationIA from "./notificationIA";
import partialPurchaseSlice from "./partialPurchase/partialPurchaseSlice";
import paymentHistoryReducer from "./paymentHistory/paymentHistorySlice";
import { priceSegmentation } from "./priceSegmentation/reducer";
import productAdvertsSlice from "./productAdvertsSlice/productAdvertsSlice";
import productAnalyzer from "./productAnalyzer/productAnalyzerSlice";
import { productCardReducer } from "./productCard/reducer";
import productDynamicsReducer from "./productDynamics/productDynamicsSlice";
import productOptimizationSlice from "./productOptimization/productOptimization";
import profileSlice from "./profile/profileSlice";
import promotions from "./promotions";
import { salesAnalyticsSliceReducer } from "./salesAnalytics/salesAnalyticsSlice";
import { searchAnalyticsReducer } from "./searchAnalytics/reducer";
import searchAnalytics from "./searchAnalyticsSlice/searchAnalyticsSlice";
import searchTopsAnalysisSlice from "./searchTopsAnalysis/searchTopsAnalysisSlice";
import sellerAnalyzer from "./sellerAnalyzer/sellerAnalyzerSlice";
import { sellerCardReducer } from "./sellerCard/reducer";
// import sellerKeysSlice from "./sellerKeys/sellerKeysSlice";
import seoAISlice from "./seoAI/seoAISlice";
import settingsColumns from "./settingsColumns/settingsColumnsSlice";
import { settingsDebriefingReducer } from "./settingsDebriefing/settingsDebriefingSlice";
import stocks from "./stocksSlice/keystatSlice";
import tariffsReducer from "./tariffs/tariffsSlice";
import top10ProductsSlice from "./top10products/top10ProductsSlice";
import monitoringTrafficsSlice from "./traffics/tariffsSlice";
import trendRequests from "./trendRequests";
import trendRequestsOld from "./trendRequestsOld";
import userReducer from "./user/userSlice";
import userTariffReducer from "./userTariff/userTariff";

// import bloggersSearchSlice from "./bloggersSearchSlice/bloggersSearchSlice"

const rootReducer = combineReducers({
  user: userReducer,
  keystatSearchQueryGraphic: searchQueryGraphicSlice,
  analysisBySubject: analysisBySubjectSlice,
  autoFeedbacks: autoFeedbackSlice,
  abcXyzAnalyze: abcXyzAnalyzeSlice,
  bloggersPostsSelection,
  bloggersProfilesSelection,
  category: categorySlice,
  productAdverts: productAdvertsSlice,
  partialPurchase: partialPurchaseSlice,
  // keystat,
  profile: profileSlice,
  keystatSlice: keystatReducer,
  seoAI: seoAISlice,
  trendRequests,
  trendRequestsOld,
  analyticsSlice,
  searchAnalytics,
  bloggersSearchSlice,
  bloggerSlice,
  growthLeaders,
  growthLeadersSlice,
  // keystatSubjects,
  categoryOptimization: categoryOptimizationSlice,
  productOptimization: productOptimizationSlice,
  stocks,
  chatGpt,
  grouping,
  productCard: productCardReducer,
  articuleCompasition: articuleCompasitionReducer,
  priceSegmentation,
  userTariff: userTariffReducer,
  tariffs: tariffsReducer,
  productAnalyzer,
  keyWordTable,
  searchAnalyticsReducer,
  keyCompasitionReducer,
  brandAnalyzer,
  sellerAnalyzer,
  caregoryAnalyzer,
  autoAdvertising,
  advertisingOptimization,
  sellerCard: sellerCardReducer,
  categoryCard: categoryCardReducer,
  brandCard: brandCardReducer,
  productDynamics: productDynamicsReducer,
  promotions,
  loadFilter,
  settingsColumns,
  keysCheckProducts,
  spp: sppReducer,
  marks: marksReducer,
  monitoring: monitoringSlice,
  monitoringProducts: productsSlice,
  monitoringTraffics: monitoringTrafficsSlice,
  monitoringBrands: brandsSlice,
  monitoringSellers: sellersSlice,
  monitoringCategories: categoriesSlice,
  monitoringKeywords: keywordsSlice,
  groupingSlice: groupingReducer,
  articlesComparison: articlesComparisonReducer,
  paymentHistory: paymentHistoryReducer,
  financeLogic: financialLogicSliceReducer,
  settingsDebriefing: settingsDebriefingReducer,
  economicLogic: economicLogicSliceReducer,
  pvz: pvzSlice,
  searchTopsAnalysis: searchTopsAnalysisSlice,
  innerAnalyticsGlobal,
  innerEconomicGlobal,
  notificationIA,
  inventoryManagment: inventoryManagmentSliceReducer,
  bussinesAnalytics: bussinessAnaltyticsSliceReducer,
  top10Products: top10ProductsSlice,
  // sellerKeys: sellerKeysSlice,
  // ----------------------------------------------
  [queryAPI.reducerPath]: queryAPI.reducer,
  [WBapi.reducerPath]: WBapi.reducer,
  salesAnalytics: salesAnalyticsSliceReducer,
  mergeAnalysis,
});

const persistConfig = {
  key: "WILDBOX",
  storage,
  whitelist: ["settingsColumns"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(WBapi.middleware)
        .concat(queryAPI.middleware),
  });
};
