import request from "root/js/request";

export const getKeywordsSummaryV2Count = async params => {
  const response = await request({
    url: `/wb_dynamic/keywords_summary_v2/count/`,
    method: "GET",
    params:{
      ...params,
      is_seasonality: params.is_seasonality__exact,
    },
  });
  return response.data;
};
