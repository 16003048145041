import React from "react"


import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import classnames from "classnames"

import styles from "./index.module.scss"

const Button_ = props => {
	const {
		fullWidth,
		loadingText,
		children,
		iconAfter: IconAfter,
		iconBefore: IconBefore,
		variant,
		isLoading,
		className,
		onClick,
		...rest
	} = props

	return (
		<Button
			onClick={onClick}
			{...rest}
			data-variant={variant}
			className={classnames(styles.root, className)}
			data-loading={isLoading}
			data-fullwidth={fullWidth}
		>
			{IconBefore && <IconBefore className={styles.iconBefore} />}
			{!loadingText && (
				<CircularProgress
					className={styles.preloader}
					size={20}
					thickness={4.5}
				/>
			)}
			<span
				className={styles.label}
				data-pulse={loadingText && isLoading}
			>
				{loadingText ? (isLoading ? loadingText : children) : children}
			</span>
			{!(loadingText && isLoading) && IconAfter && <IconAfter className={styles.iconAfter} />}
		</Button>
	)
}

export default Button_
