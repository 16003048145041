import { createAsyncThunk } from "@reduxjs/toolkit"
import { trim } from "ramda"

import API from "root/js/api"

// import { getCurrentStock } from "./keystatSlice"

import { dateToTimestamp, defaultToArr } from "root/js/utils"

const WBAPI = new API()


export const createAnalyticsShares = createAsyncThunk(
	"/createAnalyticsShares",
	async (
		{
			title,
			cabinet_id,
			start_date,
			duration_days,
			ranking_influence,
		},
		thunkAPI
	) => {
		try {
			const response = await WBAPI.createAnalyticsShares({
				title,
				cabinet_id,
				start_date: start_date ? dateToTimestamp(new Date(start_date)) : undefined,
				duration_days: duration_days ? duration_days : undefined,
				ranking_influence: ranking_influence ? ranking_influence : undefined
			})

			return response
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)



export const getStocks = createAsyncThunk("/getStocks", async ({ cabinet_id, dispatch }, thunkAPI) => {
	try {
		const response = await WBAPI.getAnalyticsShares({ cabinet_id })

		if (response?.count == 0) {
			dispatch(createAnalyticsShares({
				title: "Акция",
				cabinet_id,
				start_date: dateToTimestamp(new Date()),
				duration_days: 7,
				ranking_influence: undefined,
			}))
		}
		return response
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})

export const getSharesTable = createAsyncThunk("/getSharesTable", async ({
	cabinet_id,
	shares_id,
	in_shares,
	offset,
	// date_from,
	// date_to,
	limit,
	filters,
	product_id__icontains,
	supplier_article__icontains
}, thunkAPI) => {
	try {
		const response = await WBAPI.getSharesTable({
			cabinet_id,
			shares_id,
			in_shares,
			...filters,
			days_on_site__gte: filters?.days_on_site_gte,
			days_on_site__lte: filters?.days_on_site_lte,
			days_on_site_gte: undefined,
			days_on_site_lte: undefined,
			// date_from,
			// date_to,
			product_id__icontains: trim(product_id__icontains) ? trim(product_id__icontains) : undefined,
			supplier_article__icontains: trim(supplier_article__icontains) ? trim(supplier_article__icontains) : undefined,
			synthetic_abc_group__in: filters?.synthetic_abc_group_label__in,
			offset,
			limit
		})
		const report_in_shares = await WBAPI.getReportOfSharesTable({
			cabinet_id,
			shares_id,
			in_shares: true
		})
		const report_is_not_in_shares = await WBAPI.getReportOfSharesTable({
			cabinet_id,
			shares_id,
			in_shares: false
		})
		const report = {
			0: report_in_shares?.[0],
			1: report_is_not_in_shares?.[0]
		}
		return {
			...response,
			results: (response?.results ?? [])?.map(item => ({
				...item,
				product: {
					image: item.product_image,
					name: item.product_name,
					articule: item.product_id
				}
			})),
			report
		}
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})

export const switchDataFromTable = createAsyncThunk("/switchDataFromTable", async ({ cabinet_id, shares_id, product_ids, in_shares, page }, thunkAPI) => {
	try {
		await WBAPI.deleteProductFromTable({
			cabinet_id,
			shares_id,
			in_shares: !in_shares,
			product_ids: product_ids.join(",")
		})
		return page
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})

export const patchAnalyticsShares = createAsyncThunk(
	"/patchAnalyticsShares",
	async ({
		cabinet_id,
		shares_id,
		start_date,
		duration_days,
		ranking_influence
	},
		thunkAPI
	) => {
		try {
			const resp = await WBAPI.patchAnalyticsShares({
				shares_id,
				start_date,
				cabinet_id,
				duration_days,
				ranking_influence
			})
			return resp
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	})



export const getSharesDynamicByProduct = createAsyncThunk(
	"/getSharesDynamicByProduct",
	async ({ shares_id, product_ids, cabinet_id }, thunkAPI) => {
		try {
			const response = await WBAPI.fetchSharesDynamicByProduct({
				shares_id,
				product_ids,
				cabinet_id
			})

			return {
				...response,
				promos: (response?.promos ?? []).map(item => ({
					...item,
					start_date: new Date(item?.start_date) < new Date(response?.dynamic?.[0]?.dates ?? null) ? response?.dynamic?.[0]?.dates : item?.start_date,
					end_date: new Date(item?.end_date) > new Date(response?.dynamic?.[(response?.dynamic ?? []).length - 1]?.dates ?? null)
						? response?.dynamic?.[(response?.dynamic ?? []).length - 1]?.dates
						: item?.end_date,
					stroke: "#" + Math.floor(Math.random() * 16777215).toString(16)
				}))
			}
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	})