import React from "react";

import Drawer from "@mui/material/Drawer";
import classnames from "classnames";

import styles from "./index.module.scss";

import CloseIcon from "root/icons/Close";

const RightDrawer = props => {
  const { children, styleProp, onClose, isOpen, classes = {}, zIndex = 1000, ...params } = props;

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      onClose={onClose}
      style={{
        ...styleProp,
        zIndex,
      }}
      PaperProps={{
        classes: {
          root: classnames(styles.paper, classes.paper),
        },
      }}
      {...params}>
      {onClose && (
        <div className={styles.closeIconWrapper} onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </div>
      )}

      <div className={classnames(styles.inner, classes.inner)}>{children}</div>
    </Drawer>
  );
};

export default RightDrawer;
