import request from "root/js/request";

// Список настроек НДС
const getSettingsNds = async ({ cabinet_id }) => {
  const response = await request({
    url: `/analytics/settings_nds/`,
    method: "get",
    params: {
      cabinet_id,
    },
  });
  return response.data;
};
// Добавить ставку НДС
const addSettingsNds = async ({ cabinet_id, start_date, nds_rate }) => {
  const response = await request({
    url: `/analytics/settings_nds/`,
    method: "post",
    data: {
      cabinet_id,
      start_date,
      nds_rate,
    },
  });
  return response.data;
};
// Изменить ставку НДС
const editSettingsNds = async ({ cabinet_id, id, start_date, nds_rate }) => {
  const response = await request({
    url: `/analytics/settings_nds/${id}/`,
    method: "put",
    data: {
      cabinet_id,
      start_date,
      nds_rate,
    },
  });
  return response.data;
};
// Удалить ставку НДС
const deleteSettingsNds = async ({ cabinet_id, id }) => {
  const response = await request({
    url: `/analytics/settings_nds/${id}/`,
    method: "delete",
    params: {
      cabinet_id,
    },
  });
  return response.data;
};

export { getSettingsNds, addSettingsNds, editSettingsNds, deleteSettingsNds };
