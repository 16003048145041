import React from "react"

import Square from "../../icons/square"
import Triangle from "../../icons/triangle"
import Trips from "../../tips"

import { numberWithSpaces } from "root/js/utils"

import styles from "./index.module.scss"
const CardTwoTitle = props => {
	const {
		title,
		titleDescription,
		commisionValue,
		commisionValuePrev,
		estimadetPrecent,
		summary,
		borderIncrement,
		borderDicrement,
		transformRotateIncrement,
		transformRotateDicrement,
		colorIncrement,
		colorDicrement,
		tripText,
		tripBottomText,
		valuet,
	} = props

	const valuePrecent = (commisionValue - commisionValuePrev) / commisionValuePrev || "—";

	return (
		<div
			className={styles.cardTwoTitleContainer}
			style={{
				borderTop:
					valuePrecent === 0 || valuePrecent === -Infinity || valuePrecent === Infinity
						? "4px solid #FFA500"
						: valuePrecent < 0
							? borderIncrement
							: borderDicrement,
			}}
		>
			<div className={styles.titleContainer}>
				<div className={styles.widthTrip}>
					<h4>{title}</h4>
					<span>{titleDescription}</span>
					{tripText && <Trips text={tripText} />}
				</div>
			</div>

			<div className={styles.commisionValueContainer}>
				{commisionValue ?
					<span className={styles.sum}>
						{numberWithSpaces(valuet === '%' ? commisionValue.toFixed(1) : commisionValue.toFixed(0))} {valuet}
					</span>
					: '—'
				}
			</div>

			{/*<div className={styles.summaryContainer}>*/}
			{/*	<span> {summary}</span>*/}
			{/*</div>*/}

			<div className={styles.precentWithIconContainer}>
				<span
					style={{
						color:
							valuePrecent === Infinity ||
								valuePrecent === -Infinity ||
								valuePrecent === 0
								? "#FFA500"
								: valuePrecent < 0
								? colorIncrement
								: colorDicrement,
						fontSize: "12px",
					}}
				>
					{valuePrecent === "—" ? '' : valuePrecent === Infinity || valuePrecent === -Infinity
						? "—"
						: `${(valuePrecent * 100).toFixed(1)}%`}
				</span>
				{valuePrecent === "—" || valuePrecent === 0 || valuePrecent === Infinity || valuePrecent === -Infinity ? (
					<Square />
				) : (
					<Triangle
						result={valuePrecent}
						colorIncrement={colorDicrement}
						colorDicrement={colorIncrement}
						transformRotateIncrement={transformRotateDicrement}
						transformRotateDicrement={transformRotateIncrement}
					/>
				)}
			</div>

			{estimadetPrecent &&
				<div className={styles.widthTrip}>
					<span>{(estimadetPrecent * 100).toFixed(1)}%</span>
					{tripBottomText && <Trips text={tripBottomText} />}
				</div>
			}
		</div>
	)
}

export default CardTwoTitle
