import request from "root/js/request";

const GetMoySkladTokenById = async () => {
  const response = await request({
    url: `/moysklad/tokens/`,
    method: "get",
  });
  return response;
};

const AddMoySkladTokens = async ({ cabinet_id, token, arrival_document_type, item_identifier }) => {
  const response = await request({
    url: `/moysklad/tokens/`,
    method: "post",
    data: {
      cabinet_id,
      token,
      arrival_document_type,
      item_identifier,
    },
  });
  return response;
};

const EditMoySkladTokens = async ({
  cabinet_id,
  token,
  arrival_document_type,
  item_identifier,
}) => {
  const response = await request({
    url: `/moysklad/tokens/${cabinet_id}`,
    method: "put",
    data: {
      token,
      arrival_document_type,
      item_identifier,
    },
  });
  return response;
};

const DeleteMoySkladTokens = async ({ cabinet_id }) => {
  const response = await request({
    url: `/moysklad/tokens/${cabinet_id}`,
    method: "delete",
  });
  return response;
};

export { GetMoySkladTokenById, AddMoySkladTokens, EditMoySkladTokens, DeleteMoySkladTokens };
