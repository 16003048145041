import React from "react";

import MySalesMenuIcon from "root/icons/MySalesMenu";

// --------------------------------------------------------------
const internalAnalyticsSection = {
  type: "section",
  title: "Внутренняя аналитика",
  icon: MySalesMenuIcon,
  disabled: false,
  children: [
    // {
    //   url: "/stocks",
    //   component: React.lazy(() => import("root/pages/stocks/App")),
    //   title: "Акции",
    //   tariffIds: [1, 5, 3, 4, 14, 15, 16, 17],
    //   openIt: true,
    //   exact: false
    // },
    {
      title: "Аналитика склеек",
      url: "/slices-analytics",
      component: React.lazy(() => import("root/pages/slices-analytics/App")),
      noProd: true,
      exact: false,
    },
    {
      title: "Убийца корзин",
      url: "/basket-killer",
      component: React.lazy(() => import("root/pages/cart-killer/App")),
      noProd: true,
    },
    {
      title: "Акции",
      url: "/promotions",
      component: React.lazy(() => import("root/pages/promotions/App")),
      noProd: true,
      exact: false,
    },
    {
      title: "Автопополнение",
      url: "/auto-replenishment",
      component: React.lazy(() => import("root/pages/auto-replenishment/App")),
      noProd: true,
      exact: false,
    },
    {
      url: "/setting",
      component: React.lazy(() => import("root/pages/setting/App")),
      title: "Настройки",
      tariffIds: [1, 5, 3, 4, 14, 15, 16, 17],
      openIt: true,
      exact: false,
    },
    {
      url: "/decoding-detail?tab=decoding-detail",
      component: React.lazy(() => import("root/pages/decoding-detail/App")),
      title: "Расшифровка детализаций",
      exact: false,
      tariffIds: [1, 5, 3, 4, 14, 15, 16, 17],
      openIt: true,
    },
    {
      url: "/decoding-detail?tab=abc_analysis",
      component: React.lazy(() => import("root/pages/decoding-detail/App")),
      title: "АВС-анализ",
      exact: false,
    },
    {
      url: "/decoding-detail?tab=unit_economy",
      component: React.lazy(() => import("root/pages/decoding-detail/App")),
      title: "Юнит-экономика",
      exact: false,
    },
    {
      url: "/decoding-detail?tab=supply_plan",
      component: React.lazy(() => import("root/pages/decoding-detail/App")),
      title: "План поставок",
      exact: false,
    },
  ],
};

export default internalAnalyticsSection;
