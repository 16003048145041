import React, { useMemo, useState } from "react";

import Box from "@mui/material/Box";
import classnames from "classnames";
import { Link } from "react-router-dom";

import Dialog from "root/components/Dialog";


// import {dateToTimestampByPointRevers} from "root/js/utils"

import linkStyles from "../Link/index.module.scss";
import Tooltip_ from "../Tooltip";

import styles from "./index.module.scss";

import TooltipExclamationPointIcon from "root/icons/TooltipExclamationPoint";

const SellerLink = props => {
  const { id, name: fullName, dateRange, period = dateRange, className, ...rest } = props;
  const name = useMemo(() =>
    fullName && fullName.length > 20 ? fullName.slice(0, 18) + "..." : fullName
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!id || !name) {
    return (
      <>
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="xs">
          <Box mt={1}>
            <span className={styles.dialogTitle}>
              <TooltipExclamationPointIcon className={styles.icon} />
              Нельзя перейти в аналитику по ООО «Вайлдберриз»
            </span>
            <br />
            <br />
            Мы не видим практического смысла в анализе продаж ООО «Вайлдберриз», поэтому карточка
            этого поставщика скрыта для анализа.
          </Box>
        </Dialog>
        <span className={styles.name} onClick={() => setIsDialogOpen(true)}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          ООО "ВАЙЛДБЕРРИЗ"
        </span>
      </>
    );
  }
  if (Number(id) === 32477) {
    return name;
  } else
    return (
      <Tooltip_ title={fullName > 20 && fullName}>
        <Box>
          <Link
            to={`/seller/${
              // id
              period ? id + "?dateRangeFrom=" + period[0] + "&dateRangeTo=" + period[1] : id
            }`}
            data-underline="hover"
            className={classnames(linkStyles.root, className)}
            {...rest}>
            {name}
          </Link>
        </Box>
      </Tooltip_>
    );
};

export default SellerLink;
