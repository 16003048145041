import request from "root/js/request";

const getAnalyticsCategoriesByRRDates = async ({
  wb_api_key_ids,
  date_from,
  date_to,
  limit = 100,
  offset = 0,
  ...rest
}) => {
  const response = await request({
    url: "/analytics/categories/by-rr-dates/",
    params: {
      ...rest,
      wb_api_key_ids,
      date_from,
      date_to,
      limit,
      offset,
    },
  });
  return response.data;
};

export { getAnalyticsCategoriesByRRDates };
