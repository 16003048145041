import request from "root/js/request";

const getAnalyticsCategories = async ({ wb_api_key_ids, ...rest }) => {
  const response = await request({
    url: "/analytics/categories/",
    method: "get",
    params: {
      wb_api_key_ids,
      limit: 5000,
      ...rest,
    },
  });
  return response.data;
};

export { getAnalyticsCategories };
